import React from "react";
import { func, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Link, withRouter } from "react-router-dom";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { showSuccessMsg } from '../../utils/notification';


/**
 *  Import other dependencies
 */
import { registerUser, getEducationInstitute, getPractices } from '../../modules/auth/actions';
import { getEducationInstituteList, getPracticesList } from '../../modules/auth/selectors';

const studnetSignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  confirmEmail: Yup.string()
    .email('Invalid email')
    .required('Required')
    .oneOf([Yup.ref('email'), null], "Email must match"),
  student: Yup.string()
    .required('Required'),
  place: Yup.string()
    .required('Required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], "Password must match"),
});

const staffSignupSchema = Yup.lazy(values => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required('Required'),
    lastName: Yup.string()
      .required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    confirmEmail: Yup.string()
      .email('Invalid email')
      .required('Required')
      .oneOf([Yup.ref('email'), null], "Email must match"),
    practices: Yup.string()
      .required('Required'),
    jobTitle: Yup.string()
      .required('Required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Required'),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], "Password must match"),
  })
});

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item.attributes.name,
      value: item.id
    };
  });
};

const customStyles = {
  container: () => ({
    padding: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    ':hover': {
      borderColor: 'transparent',
    },
  }),
  control: styles => ({
    ...styles,
    minHeight: 20,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ':hover': {
      borderColor: 'transparent',
    },
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 20,
    padding: '0',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#000',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: () => ({
    color: '#000',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

const formSelect = props => {
  const { form, options, field, placeholder } = props;
  return (
    <Select
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      className="form-control font-14"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value.value)
      }} 
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class Register extends React.Component {
  componentWillMount() {
    const { type } = this.props.match.params;
  
    if (type && type === 'student') {
      this.props.getEducationInstitute();
    }

    if (type && type === 'staff') {
      this.props.getPractices();
    }
  }

  getRegisterSchema = (type) => {
    return type && type === 'staff' ? staffSignupSchema : studnetSignupSchema;
  };

  getFormikInitVal = () => {
    const { type } = this.props.match.params;
    if (type === 'staff') {
      return {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        practices: '',
        jobTitle: '',
      }
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      student: '',
      place: '',
      password: '',
      confirmPassword: '',
    }
  };

  signupUser = (values, { setSubmitting }) => {
    const { type } = this.props.match.params;
    const { registerUser, history } = this.props;
    registerUser(values, type).then(res => {
      const { message } = res;
      showSuccessMsg(message);
      setSubmitting(false);
      if (history) {
        history.push('/auth/login');
      }
    }, err => {
      setSubmitting(false);
    });
  };

  render() {
    const { instituteList, practices } = this.props;
    const { type } = this.props.match.params;
    return (
      <>
        <div className="d-flex justify-content-center ls-height-100 align-items-center">
          <div className="row ls-register-form-content my-4 w-100">
            <div className="col-md-12 text-center mb-3">
              <h2 className="family-poppins-semibold"> Register </h2>
            </div>
            {type && (
              <div className="col-md-12 mt-3">
              <Formik
                initialValues={this.getFormikInitVal()}
                validationSchema={this.getRegisterSchema(type)}
                onSubmit={this.signupUser}
              >
                {({ isSubmitting }) => (
                <Form>
                  <div className="row mx-n2">
                    <div className="col-lg-6 col-md-12 px-2">
                      <div className="form-group ls-login-form">
                      <label htmlFor="firstName" className="mb-0 text-muted font-10">First Name<span className="text-danger">*</span> </label>
                      <Field type="text" name="firstName" className="form-control font-14" placeholder="First Name" />
                      </div>
                      <ErrorMessage name="firstName" component="div" className="error-msg" />
                    </div>
                    <div className="col-lg-6 col-md-12 px-2">
                      <div className="form-group ls-login-form">
                      <label htmlFor="lastName" className="mb-0 text-muted font-10">Last Name<span className="text-danger">*</span> </label>
                      <Field type="text" name="lastName" className="form-control font-14" placeholder="Last Name" />
                      </div>
                      <ErrorMessage name="lastName" component="div" className="error-msg" />
                    </div>
                    <div className="col-lg-6 col-md-12 px-2">
                      <div className="form-group ls-login-form">
                      <label htmlFor="email" className="mb-0 text-muted font-10">Email<span className="text-danger">*</span> </label>
                      <Field type="email" name="email" className="form-control font-14" placeholder="Email" />
                      </div>
                      <ErrorMessage name="email" component="div" className="error-msg" />
                    </div>
                    <div className="col-lg-6 col-md-12 px-2">
                      <div className="form-group ls-login-form">
                      <label htmlFor="confirmEmail" className="mb-0 text-muted font-10">Confirm Email<span className="text-danger">*</span> </label>
                      <Field type="email" name="confirmEmail" className="form-control font-14" placeholder="Confirm Email" />
                      </div>
                      <ErrorMessage name="confirmEmail" component="div" className="error-msg" />
                    </div>
                    {type === 'student' && (
                      <>
                        <div className="col-lg-6 col-md-12 px-2">
                          <div className="form-group ls-login-form">
                          <label htmlFor="student" className="mb-0 text-muted font-10">Student<span className="text-danger">*</span> </label>
                          <Field type="text" name="student" className="form-control font-14" placeholder="Student" />
                          </div>
                          <ErrorMessage name="student" component="div" className="error-msg" />
                        </div>
                        <div className="col-lg-6 col-md-12 px-2">
                          <div className="form-group ls-login-form">
                          <label htmlFor="placeEducation" className="mb-0 text-muted font-10">Place of Education<span className="text-danger">*</span> </label>
                          <Field as="select" name="place" component={formSelect} options={instituteList} placeholder="Place of Education" />
                          </div>
                          <ErrorMessage name="place" component="div" className="error-msg" />
                        </div>
                      </>
                    )}
                    {type === 'staff' && (
                      <>
                        <div className="col-lg-6 col-md-12 px-2">
                          <div className="form-group ls-login-form">
                          <label htmlFor="practices" className="mb-0 text-muted font-10">Practices<span className="text-danger">*</span> </label>
                          <Field as="select" name="practices" component={formSelect} options={practices} placeholder="Practices" />
                          </div>
                          <ErrorMessage name="practices" component="div" className="error-msg" />
                        </div>
                        <div className="col-lg-6 col-md-12 px-2">
                          <div className="form-group ls-login-form">
                          <label htmlFor="jobTitle" className="mb-0 text-muted font-10">Job Title<span className="text-danger">*</span> </label>
                          <Field type="text" name="jobTitle" className="form-control font-14" placeholder="Job Title" />
                          </div>
                          <ErrorMessage name="jobTitle" component="div" className="error-msg" />
                        </div>
                      </>
                    )}
                    <div className="col-lg-6 col-md-12 px-2">
                      <div className="form-group ls-login-form">
                      <label htmlFor="password" className="mb-0 text-muted font-10">Password<span className="text-danger">*</span></label>
                      <Field type="password" name="password" className="form-control font-14" placeholder="Password" />
                      </div>
                      <ErrorMessage name="password" component="div" className="error-msg" />
                    </div>
                    <div className="col-lg-6 col-md-12 px-2">
                      <div className="form-group ls-login-form">
                      <label htmlFor="confirmPassword" className="mb-0 text-muted font-10">Confirm Password<span className="text-danger">*</span></label>
                      <Field type="password" name="confirmPassword" className="form-control font-14" placeholder="Confirm Password" />
                      </div>
                      <ErrorMessage name="confirmPassword" component="div" className="error-msg" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 px-1">
                      <p className="font-12 text-muted"> 
                      By clicking on signup you agreed to linkStaff user agreement. Privacy and Cookies Policy. 
                      </p>
                    </div>
                    <button type="submit" className="btn btn-primary ls-login-button w-100 py-3 my-3 mb-2 m-1" disabled={isSubmitting}>
                      { 
                      isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Sign Up
                    </button>
                  </div>
                </Form>
                )}
              </Formik>
              </div>
            )}
            {!type && (
              <div className="col-md-12 my-lg-5 my-2">
                <div className="row mx-lg-n2 mx-xl-n3">
                  <div className="col-sm-6 mb-4 px-lg-2 px-xl-3">
                    <Link to={`/auth/register/student`}>
                      <div className="card signup-option radius-10 lS-card-shadow">
                        <div className="card-body px-2 d-flex flex-column align-items-center py-4">
                          <span className="option-icon mt-2 rounded-circle d-flex align-items-center justify-content-center">
                            <i className="fas fa-user-graduate" />
                          </span>
                          <h5 className="mt-4 mb-0">As Student</h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6 mb-4 px-lg-2 px-xl-3">
                    <Link to={`/auth/register/staff`}>
                      <div className="card signup-option radius-10 lS-card-shadow">
                        <div className="card-body px-2 d-flex flex-column align-items-center py-4">
                          <span className="option-icon mt-2 rounded-circle d-flex align-items-center justify-content-center">
                            <i className="fas fa-users" />
                          </span>
                          <h5 className="mt-4 mb-0">As Staff</h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>			  
            )}
            <div className="d-flex justify-content-center flex-wrap w-100 mt-2">
              <p className="font-14"> 
                Already have an account?{' '}
                <span><Link to="/auth/login">Login</Link></span> 
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Register.propTypes = {
  getPractices: func.isRequired,
  getEducationInstitute: func.isRequired,
  instituteList: array.isRequired,
  practices: array.isRequired,
  registerUser: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  instituteList: getEducationInstituteList(),
  practices: getPracticesList(),
});

export default connect(
  mapStateToProps,
  {
    registerUser,
    getEducationInstitute,
    getPractices,
  }
)(withRouter(Register));
