import React from "react";
import Modal from 'react-bootstrap/Modal';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { func, bool, string } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import StarRating from 'components/starRating';
import { showSuccessMsg } from '../../utils/notification';
import * as Yup from 'yup';

import {
  postfeedBackTraining
} from '../../modules/trainings/actions';

const feedBackSchema = Yup.object().shape({
  comment: Yup.string()
    .required('Required'),
  rating: Yup.string()
		.required('Required'),
});

class FeedbackMyBooking extends React.Component {

  state = {
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
  }

  getFormikInitVal = () => {
		return {
      comment: "",
      rating: ""
		}
  };

  postFeedback = (values, { setSubmitting, resetForm }) => {
    const { postfeedBackTraining, id, onClose } = this.props;
    var feedbackParams = {
      training_id: id,
      feedback: values.comment,
    }

    var ratingParams = {
      rating_value: values.rating,
    }
    postfeedBackTraining(feedbackParams, ratingParams, id).then(res => {
			const { message } = res;
			showSuccessMsg(message);
			setSubmitting(false);
			onClose();
		}, err => {
			setSubmitting(false);
		});;
  };

  render() {
    const { isVisible, onClose } = this.props;    
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header className="background-primary justify-content-center">
            <Modal.Title className="color-white">Feedback</Modal.Title>
          </Modal.Header>
          <Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={feedBackSchema}
						onSubmit={this.postFeedback}
					>
						{({ isSubmitting, setFieldValue, values }) => (
              <Form className="ls-form pt-2">
                <Modal.Body>
                  <div className="form-group">
                    <label className="color-grey pl-3">Rate Training</label>
                    <div className="pl-3">
                      <StarRating 
                        initialRating={values.rating ? Number(values.rating) : 0}
                        readOnly={isSubmitting}
                        onRate={(rate) => {
                          setFieldValue('rating', rate);
                        }}
                      />
                    </div>
                    <ErrorMessage name="rating" component="div" className="error-msg pl-3 mt-2" />
                  </div>
                  <div className="form-group">
                    <label className="color-grey pl-3">Comment</label>
										<Field component="textarea" rows="2" name="comment" className="form-control" placeholder="Type Here..." />
                    <ErrorMessage name="comment" component="div" className="error-msg pl-3 mt-2" />
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                  <div className="row pb-3 w-100">
                    <div className="col-md-6">
                      <button
                        disabled={isSubmitting}
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button type="submit" 
                        disabled={isSubmitting} 
                        className="btn btn-primary lS-btn-blue-style w-100"
                      >
                        {
                          isSubmitting && (
                            <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                        )}
                        Save
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
						)}
          </Formik>
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
FeedbackMyBooking.propTypes = {
  isVisible: bool.isRequired,
  id: string.isRequired,
  onClose: func.isRequired,
  postfeedBackTraining: func.isRequired,
};

/**
*  Map redux state to component props
*/
const mapStateToProps = createStructuredSelector({});

export default connect(
  mapStateToProps,
  {
    postfeedBackTraining
  }
)(withRouter(FeedbackMyBooking));