/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const forums = () => state => state.get('forums');

/**
 *  Select the forums posts
 */
export const getPostsList = () =>
    createSelector(forums(), state => {
        const listing = state.get('posts').toJS()
        return listing.length > 0 ? listing : []
    });

/**
 *  Select Single post
 */
export const getPostDetail = () =>
    createSelector(forums(), state => {
        const singlePlacement = state.get('post')
        return singlePlacement && Object.keys(singlePlacement).length > 0 ? singlePlacement.toJS() : {}
    });

/**
 *  Select Single post
 */
export const getPostReplyList = () =>
    createSelector(forums(), state => {
        const postReplies = state.get('replies')
        return postReplies && Object.keys(postReplies).length > 0 ? postReplies.toJS() : {}
    });

/**
 *  Select is fetching single post
 */
export const getIsFetchingPost = () =>
    createSelector(forums(), state => {
        return state.get('isFetchingPost')
    })

/**
 *  Select the forums categories
 */
export const getCategoriesList = () =>
    createSelector(forums(), state => {
        const listing = state.get('categories').toJS()
        return listing.length > 0 ? listing : []
    });

/**
 *  Select is fetching categories
 */
export const getIsFetchingCategories = () =>
    createSelector(forums(), state => {
        return state.get('isFetchingCategories')
    })

/**
 *  Select is fetching posts
 */
export const getIsFetchingPosts = () =>
    createSelector(forums(), state => {
        return state.get('isFetchingPosts')
    })

/**
 *  Select total number of posts pages
 */
export const getTotalPages = () =>
    createSelector(forums(), state => {
        return state.get('totalPage')
    })

/**
 *  Select current post page
 */
export const getPage = () =>
    createSelector(forums(), state => {
        return state.get('page')
    })

/**
 *  Select the forums posts
 */
export const getMyPostsList = () =>
    createSelector(forums(), state => {
        const listing = state.get('myPosts').toJS()
        return listing.length > 0 ? listing : []
    });

/**
 *  Select is fetching my posts
 */
export const getIsFetchingMyPosts = () =>
    createSelector(forums(), state => {
        return state.get('isFetchingMyPosts')
    });

/**
 *  Select =is Uploading
 */
export const getIsFetchingPollingList = () =>
  createSelector(forums(), state => {
    return state.get('isFetchingPollingList')
  })

/**
 *  Select =is Get Practice
 */
export const getPollList = () =>
  createSelector(forums(), state => {
    const listing = state.get('pollList').toJS()
    return listing.length > 0 ? listing : []
  });
