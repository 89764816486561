export const GET_LIST_OF_PLACEMENTS =
  'src/modules/postPlacements/GET_LIST_OF_PLACEMENTS';
export const ADD_PLACEMENTS =
  'src/modules/postPlacements/ADD_PLACEMENTS';
export const UPDATE_PLACEMENTS =
  'src/modules/postPlacements/UPDATE_PLACEMENTS';
export const UPLOAD_IMAGE =
  'src/modules/postPlacements/UPLOAD_IMAGE';
export const DELETE_PLACEMENTS =
  'src/modules/postPlacements/DELETE_PLACEMENTS';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/postPlacements/CANCEL_ALL_API_REQUESTS';