import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import { func, bool, object, number } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
import Select from 'react-select';
// import { selfAssessment, evidence } from './taskFilters';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import {
	addFeedbackLog,
	updateFeedbackLog,
	getFeedbackLog,
	// uploadImage,
	// updateSing	leTask,	
	// getSingleBooklets,	
} from '../../modules/revalidation/feedbackLog/actions';
import {
//   getIsUploadingImage,
} from '../../modules/revalidation/feedbackLog/selectors';

const editFeedbackSchema = Yup.object().shape({
  feedbackDate: Yup.string()
	.required('Required'),
	feedbackSource: Yup.string()
	.required('Required'),
	feedbackType: Yup.string()
	.required('Required'),
	feedbackContent: Yup.string()
    .required('Required'),
});

// const formSelect = props => {
// 	const { form, options, placeholder, field } = props;
// 	if (options.length === 0) return;
// 	return (
// 	  <Select
// 		components={{ IndicatorSeparator:() => null }}
// 		className="form-control p-0 mb-2"
// 		isSearchable={true}
// 		placeholder={placeholder}
// 		onChange={value => {
// 		  if(field.name === 'self_assessment'){
// 			form.setFieldValue('self_assessment', value.value)
// 		  }
// 		  else{
// 			form.setFieldValue('evidence', value.value)
// 		  }
// 		}}
// 		value={field.value ? {label: field.value, value: field.value} : ''}
// 		styles={customStyles}
// 		options={formattedArray(options)}
// 	  />
// 	)
//   };

// const formattedArray = array => {
// 	return array.map(item => {
// 	  return {
// 		label: `${item}`,
// 		value: `${item}`
// 	  };
// 	});
//   };

// const customStyles = {
// 	control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
// 	placeholder: () => ({
// 		color: 'inherit',
// 	}),
// 	singleValue: () => ({
// 		color: 'inherit',
// 	}),
// };

class EditFeedbackLog extends React.Component {
	getFormikInitVal = () => {
		const { singleFeedback } = this.props;
		const {
			feedback_date,
			feedback_source,
			feedback_type,
			feedback_content,
		} = singleFeedback || {};
    return {
	  feedbackDate: feedback_date ? feedback_date : "",
      feedbackSource: feedback_source ? feedback_source : "",
      feedbackType: feedback_type ? feedback_type : "",
	  feedbackContent: feedback_content ? feedback_content : "",
    }
};

	// handleFileUpload = (event, setFieldValue) => {
    // const { uploadImage, isUploadingImage } = this.props;
    // if (isUploadingImage) return;
    // const file = event.target.files[0];
    // var formdata = new FormData();
    // formdata.append("file", file);
    // uploadImage(formdata).then(res => {
    //   const { url } = res.data;
    //   setFieldValue('evidenceAttachment', url);
    // });
	// };

	updateSingleTask = (values, { setSubmitting, resetForm }) => {
		const { feedbackId, addFeedbackLog, singleFeedback, getFeedbackLog, updateFeedbackLog } = this.props;

		const params = {
			feedback_type: values.feedbackType,
			feedback_content: values.feedbackContent,
			feedback_source: values.feedbackSource,
			feedback_date: values.feedbackDate ? moment(values.feedbackDate).format("YYYY-MM-DD HH:mm:ss") : null,
		};
		
		if(feedbackId === 0) {
			addFeedbackLog(params).then(res => {
				const { message } = res;
				getFeedbackLog();
				showSuccessMsg(message);
				setSubmitting(false);
				this.props.onClose();
			}, err => {
				setSubmitting(false);
			});
			return ;
		}

		updateFeedbackLog(params, feedbackId).then(res => {
			const { message } = res;
			getFeedbackLog();
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};

	// fileUpload = props => {
    // const { form, field } = props;
    // if (field.value) {
    //   return (
    //     <>
    //       <div className="fileUploadCol">
    //         <label>Attachment</label>
    //       </div>
    //       <div className="position-relative fileUploadCol w-100">
    //         <span>1 Attachment attached</span>
    //         <div className="selectImgCross" onClick={() => form.setFieldValue('evidenceAttachment', "")}>
    //           <i className="fas fa-times"></i>
    //         </div>
    //       </div>
    //     </>
    //   )
    // }

//     return (
//       <>
// 		<div className="fileUploadCol position-relative">
// 			<label>Attachment</label>
// 			<input
// 				type="file"
// 				onChange={event => this.handleFileUpload(event, form.setFieldValue)}
// 			/>
// 			<span>Upload File</span>
// 		</div>
//       </>
//     )
//   };

	render() {
		const {
			isVisible,
			isUploadingImage,
			onClose,
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center py-4">
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={editFeedbackSchema}
						onSubmit={this.updateSingleTask}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form px-md-4 px-3 py-4">
								<Modal.Body>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Feedback Date</label>
											<Datetime
											inputProps={{placeholder: 'Select Date'}}
											renderInput={ this.renderCustomInput }
											closeOnSelect={true}
											//  isValidDate={ valid }
											onChange={feedbackDate => {
												if (typeof feedbackDate !== "object") return;
												setFieldValue('feedbackDate', feedbackDate)
											}}
											value={values['feedbackDate']}
											/>
											<ErrorMessage name="feedbackDate" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Feedback Source</label>
											<Field component="textarea" rows="2" name="feedbackSource" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="feedbackSource" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Feedback Type</label>
											<Field component="textarea" rows="2" name="feedbackType" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="feedbackType" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Feedback Content</label>
											<Field component="textarea" rows="2" name="feedbackContent" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="feedbackContent" component="div" className="error-msg pl-3 mt-2" />
										</div>
								</Modal.Body>
								<Modal.Footer className="border-0">
									<div className="row pb-3 w-100">
										<div className="col-md-6">
											<button
												disabled={isSubmitting || isUploadingImage}
												className="btn btn-secondary w-100 mb-3 py-3"
												onClick={() => {
													onClose();
												}}
											>
												Cancel
											</button>
										</div>
										<div className="col-md-6">
											<button type="submit" disabled={isSubmitting || isUploadingImage} className="btn btn-primary lS-btn-blue-style w-100 py-3">
												{
													isSubmitting && (
														<span className="spinner-border align-middle spinner-border-sm mr-2"></span>
												)}
												Save
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
EditFeedbackLog.propTypes = {
	feedbackId: number.isRequired,
	// bookLetIndexID: number.isRequired,
	// getSingleBooklets: func.isRequired,
	isVisible: bool.isRequired,
	// isUploadingImage: bool.isRequired,
	onClose: func.isRequired,
	singleFeedback: object.isRequired,
};

const mapStateToProps = createStructuredSelector({
//   isUploadingImage: getIsUploadingImage(),
});

export default connect(
  mapStateToProps,
  {
		// uploadImage,
		updateFeedbackLog,
		getFeedbackLog,
		addFeedbackLog,
  }
)(withRouter(EditFeedbackLog));
