/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_PCN,
  GET_LIST_OF_FEDERATION,
  GET_LIST_OF_SINGLE_PCN,
  CHANGE_SINGLE_MAP,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 100000,
  page: 1,
  myPcn: [],
  federations: [],
  selectedMap: null,
  networksAndPractices: [],
  selectedFederation: null,
  isFetchingMyPcn: true,
  isFetchingNetworks: false,
});

/**
 *  Define the reducer with actions
 */
function pcnPracticesReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SINGLE_MAP:
      return fromJS({
        ...state.toJS(),
        selectedMap: action.data,
        networksAndPractices: [],
        selectedFederation: null,
      })
    
    case GET_LIST_OF_PCN:
      return fromJS({
        ...state.toJS(),
        myPcn: [],
        selectedMap: null,
        isFetchingMyPcn: true,
      })

    case success(GET_LIST_OF_PCN):
      let finialResult=[];
      const data = action.payload.data.data;
      const includes = action.payload.data.included;
      data.map(indexes => {
        finialResult = filterData(indexes.relationships.practices.data, includes);
        indexes.myPcnData = finialResult;
        delete indexes.relationships;
        return indexes;
      });

      return fromJS({
        ...state.toJS(),
        myPcn: data && data.length > 0 ? data : [],
        selectedMap: data && data.length > 0 ? data[0] : null, // init state of selected map
        isFetchingMyPcn: false,
      })

    case failure(GET_LIST_OF_PCN):
      return fromJS({
        ...state.toJS(),
        isFetchingMyPcn: false,
      })
    
    case GET_LIST_OF_FEDERATION:
      return fromJS({
        ...state.toJS(),
      })

    case success(GET_LIST_OF_FEDERATION):
      var stp = action.payload.data.data.filter((item) => item.attributes.region === 'North West London');
      stp.map(indexes => {
        let finialResult = filterData(indexes.relationships.federations.data, action.payload.data.included);
        indexes.federations = finialResult;
        delete indexes.relationships;
        return indexes;
      });
      return fromJS({
        ...state.toJS(),
        federations: stp.length > 0 && stp[0].federations ? stp[0].federations : [],
      })

    case failure(GET_LIST_OF_FEDERATION):
      return fromJS({
        ...state.toJS(),
        isFetchingMyPcn: false,
      })
    
    case GET_LIST_OF_SINGLE_PCN:
      return fromJS({
        ...state.toJS(),
        networksAndPractices: [],
        selectedFederation: action.federation,
        isFetchingNetworks: true,
      })

    case success(GET_LIST_OF_SINGLE_PCN):
      const singleData = action.payload.data.data;
      const singleIncludes = action.payload.data.included;

      // Step: 1 - filter all indexes
      singleData.map(indexes => {
        indexes.practices = [];
        if (
          indexes.relationships && 
          indexes.relationships.practices && 
          indexes.relationships.practices.data
        )
          indexes.practices = filterData(indexes.relationships.practices.data, singleIncludes);

        delete indexes.relationships;
        return indexes;
      });
      return fromJS({
        ...state.toJS(),
        networksAndPractices: singleData && singleData.length > 0 ? singleData : [],
        isFetchingNetworks: false,
      })

    case failure(GET_LIST_OF_SINGLE_PCN):
      return fromJS({
        ...state.toJS(),
        isFetchingNetworks: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

const filterData = (data, includes) => {
  return data.map( data => includes.find(value => value.type === data.type && value.id === data.id))
};

/**
 *  Export the reducer
 */
export default pcnPracticesReducer