import React from "react";
import { func, array, bool, object} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';

/**
 *  Import other dependencies
 */
import { addPostAppraisals, getSupervisor, getSingleAppraisal  } from '../../../modules/appraisals/actions';
import { getSupervisorsListing, getIsFetchingSupervisors, getIsFetchingSingleAppraisal, getSingleAppraisalItem  } from '../../../modules/appraisals/selectors';

const PostAppraisalsStaffSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  jobTitle: Yup.string()
    .required('Required'),
  dateTime: Yup.string()
    .required('Required'),
  supervisor: Yup.string()
    .required('Required'),
  pleasedArea: Yup.string(),
  improveArea: Yup.string(),
  potentialArea: Yup.string(),
  developmentActivitiesBrief: Yup.string(),
  skills: Yup.string(),
  comments: Yup.string(),
  lineManager: Yup.string(),
  colleagues: Yup.string(),
  serviceUsers: Yup.string(),
  objectives: Yup.string(),
  nextObjective: Yup.string(),
  appraisalTopics: Yup.string(),
});

const PostAppraisalsSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  jobTitle: Yup.string()
    .required('Required'),
  dateTime: Yup.string()
    .required('Required'),
  pleasedArea: Yup.string(),
  improveArea: Yup.string(),
  potentialArea: Yup.string(),
  developmentActivitiesBrief: Yup.string(),
  skills: Yup.string(),
  comments: Yup.string(),
  lineManager: Yup.string(),
  colleagues: Yup.string(),
  serviceUsers: Yup.string(),
  objectives: Yup.string(),
  nextObjective: Yup.string(),
  appraisalTopics: Yup.string(),
});

const DateTimeSelect = props => {
  const { form, disabled, placeholder, field } = props;
  return(
    <Datetime 
      inputProps={{placeholder, disabled}}
      closeOnSelect={true}
      onChange={value => {
        form.setFieldValue(field.name, value) 
      }}
      value={field.value}
    />
  )
}

const formattedArray = array => {
  return array.map(item => {
    return {
      label: `${item.attributes.name}`,
      value: `${item.id}`
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, disabled, placeholder, field } = props;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0 mb-2"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if(field.name === 'supervisor'){
          form.setFieldValue('supervisor', value.value)
        }
      }}
      value={field.value ? formattedArray(options.filter(option => Number(option.id) === Number(field.value))) : ''}
      styles={customStyles}
      isDisabled={disabled}
      options={formattedArray(options)}
    />
  )
};

class PostAppraisals extends React.Component {
	state = {
    isSupervisor: (this.props.match.path === '/user/post-appraisals/:id/supervisor') ? true : false,
    isDisplayAppraisal: (this.props.match.path === '/user/post-appraisals/:id' || this.props.match.path === '/user/post-appraisals/:id/supervisor') ? true : false,
    role: this.props.match.path === '/user/post-appraisals/:id/supervisor' ? 'supervisor' : 'staff',
  };
  
  componentWillMount() {
    const { history } = this.props;
    const { isSupervisor } = this.state;
    if(this.props.match.path === '/user/post-appraisals/:id' || this.props.match.path === '/user/post-appraisals/:id/supervisor') {
      if(isNaN(this.props.match.params.id)) {
        history.push('/user/index');
        return;
      }
      this.props.getSingleAppraisal(this.props.match.params.id, isSupervisor);
    }
    this.props.getSupervisor();
  }

  getFormikInitVal = () => {
    const { singleAppraisal } = this.props;
    const { role, isSupervisor } = this.state;
    if((this.props.match.path === '/user/post-appraisals/:id' || this.props.match.path === '/user/post-appraisals/:id/supervisor') && role === 'staff' && singleAppraisal && Object.keys(singleAppraisal).length > 0) {
      const { user_submission, supervisor_id } = singleAppraisal.attributes;
      return {
        name: user_submission.name || '',
        jobTitle: user_submission.job_title || '',
        dateTime: moment(user_submission.date_time) || '',
        pleasedArea: user_submission.pleased_area || '',
        improveArea: user_submission.improve_area || '',
        potentialArea: user_submission.potential_area || '',
        developmentActivitiesBrief: user_submission.development_activities_brief || '',
        skills: user_submission.skills || '',
        comments: user_submission.comments || '',
        lineManager: user_submission.line_manager || '',
        colleagues: user_submission.colleagues || '',
        serviceUsers: user_submission.service_users || '',
        objectives: user_submission.objectives || '',
        nextObjective: user_submission.next_objective || '',
        appraisalTopics: user_submission.appraisal_topics || '',
        supervisor: supervisor_id || '',
      };
    }

    if((this.props.match.path === '/user/post-appraisals/:id' || this.props.match.path === '/user/post-appraisals/:id/supervisor') && role === 'supervisor' && singleAppraisal && Object.keys(singleAppraisal).length > 0 && singleAppraisal.attributes.supervisor_submission && Object.keys(singleAppraisal.attributes.supervisor_submission).length > 0) {
      const { supervisor_submission } = singleAppraisal.attributes;
      console.log(supervisor_submission);
      return {
        name: supervisor_submission.name || '',
        jobTitle: supervisor_submission.job_title || '',
        dateTime: moment(supervisor_submission.date_time) || '',
        pleasedArea: supervisor_submission.pleased_area || '',
        improveArea: supervisor_submission.improve_area || '',
        potentialArea: supervisor_submission.potential_area || '',
        developmentActivitiesBrief: supervisor_submission.development_activities_brief || '',
        skills: supervisor_submission.skills || '',
        comments: supervisor_submission.comments || '',
        lineManager: supervisor_submission.line_manager || '',
        colleagues: supervisor_submission.colleagues || '',
        serviceUsers: supervisor_submission.service_users || '',
        objectives: supervisor_submission.objectives || '',
        nextObjective: supervisor_submission.next_objective || '',
        appraisalTopics: supervisor_submission.appraisal_topics || '',
      };
    }

    return {
      name: '',
      jobTitle: '',
      dateTime: '',
      pleasedArea: '',
      improveArea: '',
      potentialArea: '',
      developmentActivitiesBrief: '',
      skills: '',
      comments: '',
      lineManager: '',
      colleagues: '',
      serviceUsers: '',
      objectives: '',
      nextObjective: '',
      appraisalTopics: '',
      supervisor: '',
    };
  };

  postAppraisals = (values, { setSubmitting, resetForm }) => {
    const { addPostAppraisals, history } = this.props;
    const { isSupervisor }= this.state;
    var value = {
      name: values.name,
      job_title: values.jobTitle,
      date_time: moment(values.dateTime).format("YYYY-MM-DD HH:mm:ss"),
      pleased_area: values.pleasedArea,
      improve_area: values.improveArea,
      potential_area: values.potentialArea,
      development_activities_brief: values.developmentActivitiesBrief,
      skills: values.skills,
      comments: values.comments,
      line_manager: values.lineManager,
      colleagues: values.colleagues,
      service_users: values.serviceUsers,
      objectives: values.objectives,
      next_objective: values.nextObjective,
      appraisal_topics: values.appraisalTopics,
    };

    var params = null;
    if(isSupervisor) {
      params = {
        supervisor_submission: value,
      };
    }else {
      params = {
        supervisor_id: values.supervisor,
        user_submission: value,
      };
    }
    const { id } = this.props.match.params || {};
    addPostAppraisals(params, isSupervisor, id).then(() => {
      resetForm(this.getFormikInitVal);
      setSubmitting(false);
      if (history) {
        history.push(isSupervisor ? '/user/appraisals/supervisor' : '/user/appraisals');
      }
    }, () => {
      setSubmitting(false);
    });
  };

  goToTab = (role) => {
    this.setState({
      role,
    });
  };

  render() {
    const { supervisors, isFetchingSupervisors, isFetchingSingleAppraisal, singleAppraisal, match } = this.props;
    const { role, isSupervisor, isDisplayAppraisal } = this.state;
    const isDisabledStaffFields = (isSupervisor || match.path === '/user/post-appraisals/:id' || this.props.match.path === '/user/post-appraisals/:id/supervisor');
    const isDisabledSupervisorFields = 
      (match.path === '/user/post-appraisals/:id' || 
        (singleAppraisal && 
          Object.keys(singleAppraisal).length > 0 &&
          singleAppraisal.attributes.supervisor_submission
          && Object.keys(singleAppraisal.attributes.supervisor_submission)
        )
      );
    const isDisplayTabs = (isDisplayAppraisal || isSupervisor);
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 py-5">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h4 className="family-poppins-semibold">Appraisal Preparation Form</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            {(isFetchingSupervisors || isFetchingSingleAppraisal) && (
              <div className="mt-md-5 mt-4">
                <Loader />
              </div>
            )}
            {/* Appraisal Tabs */}
            {!isFetchingSupervisors && !isFetchingSingleAppraisal && isDisplayTabs && (  
              <ul className="nav ls-nav-tabs-pill mt-4 mt-md-5 mb-4 d-flex align-items-center font-16">
                <li className="nav-item cursor-pointer mb-0" onClick={() => this.goToTab('staff')}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`px-4 rounded-pill mr-3 ${role === 'staff' ? 'active-nav' : ''}`}> Staff Appraisal </a>
                </li>
                <li className="nav-item cursor-pointer mb-0" onClick={() => this.goToTab('supervisor')}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`px-4 rounded-pill ${role === 'supervisor' ? 'active-nav' : ''}`}> Supervisor Appraisal </a>
                </li>
              </ul>
            )}
            
            {/* Role Staff */}
            {!isFetchingSupervisors && !isFetchingSingleAppraisal && role === 'staff' && (
              <div className={`card lS-card-shadow lS-card-radius border-0 ${!isDisplayTabs ? 'mt-5' : ''}`}>
                <div className="card-body">
                  <Formik
                    initialValues={this.getFormikInitVal()}
                    validationSchema={PostAppraisalsStaffSchema}
                    onSubmit={this.postAppraisals}
                  >
                    {({ isSubmitting, setFieldValue, values }) => (
                      <Form className="ls-form pt-2 mt-4">
                        {/* about your employment */}
                        <section className="employment-section">
                          <h4 className="family-poppins-semibold mb-5">About Your Employment</h4>
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="comments" className="color-grey pl-3">Select Supervisor</label>
                                <Field as="select" name="supervisor" placeholder="Select Supervisor" disabled={isDisabledStaffFields} component={formSelect} options={supervisors} />
                                <ErrorMessage name="supervisor" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 mb-5">
                              <div className="form-group">
                                <label htmlFor="name" className="color-grey pl-3">Name</label>
                                <Field type="text" name="name" className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter Name" />
                                <ErrorMessage name="name" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                              <div className="form-group">
                                <label htmlFor="job-title" className="color-grey pl-3">Job Title</label>
                                <Field type="text" name="jobTitle" className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter Job Title" />
                                <ErrorMessage name="jobTitle" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                              <div className="form-group">
                                <label htmlFor="date-time" className="color-grey pl-3">Date & Time of Appraisal</label>
                                <Field type="text" name="dateTime" component={DateTimeSelect} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Select Date & Time" />
                                <ErrorMessage name="dateTime" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="pleased-area" className="color-grey pl-3">Identify the areas of your work that you are pleased with and state why?</label>
                                <Field as="textarea" name="pleasedArea" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="pleasedArea" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="improve-area" className="color-grey pl-3">Identify the areas of your work you would like to improve and state why?</label>
                                <Field as="textarea" name="improveArea" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="improveArea" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="potential-area" className="color-grey pl-3">Identify any reasons which have prevented you from performing to your full potential</label>
                                <Field as="textarea" name="potentialArea" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="potentialArea" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="color-grey">Please give brief details of any personal development activities you have undertaken in the last year, and what impact it has had on your ability to carry out your job</p>
                            <div className="row color-grey mb-3">
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Levels of skills, knowledge and understanding</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Changes within the workplace</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Resources/equipment</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Workload</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Absence level</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Work environment - health and safety etc.</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Work life balance</span>
                                </p>
                              </div>
                            </div>
                            <div className="form-group mb-5">
                              <Field as="textarea" name="developmentActivitiesBrief" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                              <ErrorMessage name="developmentActivitiesBrief" component="div" className="error-msg pl-3 mt-2" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100">
                                <label htmlFor="skills" className="color-grey pl-3">How would you like to develop your skills in the coming year?</label>
                                <Field as="textarea" name="skills" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="skills" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="comments" className="color-grey pl-3">Do you have any comments on your current job description</label>
                                <Field as="textarea" name="comments" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="comments" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            
                          </div>
                        </section>
                        {/* working relationship */}
                        <section className="work-relationship-section">
                          <h4 className="family-poppins-semibold mb-5">Working relationships</h4>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12">
                              <h5 className="family-poppins-semibold">Job Holder</h5>
                              <p className="color-grey mt-4">What works well and what would you like to change in the working relationship between?</p>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100">
                                <label htmlFor="lineManager" className="color-grey pl-3">You and Line Manager</label>
                                <Field as="textarea" name="lineManager" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="lineManager" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="next-objective" className="color-grey pl-3">You and your colleagues</label>
                                <Field as="textarea" name="colleagues" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="colleagues" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="appraisal-topics" className="color-grey pl-3">You and service users (patients etc)</label>
                                <Field as="textarea" name="serviceUsers" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="serviceUsers" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100">
                                <label htmlFor="objectives" className="color-grey pl-3">Review of objectives. Please list below your objectives. Make reference to objectives set at your last appraisal or to the attached job description if none set.</label>
                                <Field as="textarea" name="objectives" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="objectives" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="nextObjective" className="color-grey pl-3">What do you think your objectives should be for next year?</label>
                                <Field as="textarea" name="nextObjective" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="nextObjective" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="appraisalTopics" className="color-grey pl-3">Are there any topics you would particularly like to discuss during your appraisal meeting?</label>
                                <Field as="textarea" name="appraisalTopics" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledStaffFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="appraisalTopics" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                        </section>
                        {!isDisabledStaffFields && (
                          <div className="text-md-right text-center">
                            <button className="btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</button>
                            <button type="submit" disabled={isSubmitting} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                            {                     
                                isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}Submit
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}

            {/* Role Supervisor */}
            {!isFetchingSupervisors && !isFetchingSingleAppraisal && role === 'supervisor' && (
              <div className="card lS-card-shadow lS-card-radius border-0 mt-5">
                <div className="card-body">
                  <Formik
                    initialValues={this.getFormikInitVal()}
                    validationSchema={PostAppraisalsSchema}
                    onSubmit={this.postAppraisals}
                  >
                    {({ isSubmitting, setFieldValue, values }) => (
                      <Form className="ls-form pt-2 mt-4">
                        {/* about your employment */}
                        <section className="employment-section">
                          <h4 className="family-poppins-semibold mb-5">About Your Employment</h4>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 mb-5">
                              <div className="form-group">
                                <label htmlFor="name" className="color-grey pl-3">Name</label>
                                <Field type="text" name="name" className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter Name" />
                                <ErrorMessage name="name" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                              <div className="form-group">
                                <label htmlFor="job-title" className="color-grey pl-3">Job Title</label>
                                <Field type="text" name="jobTitle" className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter Job Title" />
                                <ErrorMessage name="jobTitle" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                              <div className="form-group">
                                <label htmlFor="date-time" className="color-grey pl-3">Date & Time of Appraisal</label>
                                <Field type="text" name="dateTime" component={DateTimeSelect} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Select Date & Time" />
                                <ErrorMessage name="dateTime" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="pleased-area" className="color-grey pl-3">Identify the areas of their work that you are pleased with and state why?</label>
                                <Field as="textarea" name="pleasedArea" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="pleasedArea" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="improve-area" className="color-grey pl-3">Identify the areas of their work you would like to improve and state why?</label>
                                <Field as="textarea" name="improveArea" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="improveArea" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="potential-area" className="color-grey pl-3">Identify any reasons which have prevented employee from performing to their full potential</label>
                                <Field as="textarea" name="potentialArea" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="potentialArea" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="color-grey">Please give brief details of any personal development activities employee have undertaken in the last year, and what impact it has had on their ability to carry out their job</p>
                            <div className="row color-grey mb-3">
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Levels of skills, knowledge and understanding</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Changes within the workplace</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Resources/equipment</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Workload</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Absence level</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Work environment - health and safety etc.</span>
                                </p>
                              </div>
                              <div className="col-md-6 col-lg-4">
                                <p className="d-flex mb-2 pr-lg-4">
                                  <span className="mr-3">-</span> <span>Work life balance</span>
                                </p>
                              </div>
                            </div>
                            <div className="form-group mb-5">
                              <Field as="textarea" name="developmentActivitiesBrief" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                              <ErrorMessage name="developmentActivitiesBrief" component="div" className="error-msg pl-3 mt-2" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100">
                                <label htmlFor="skills" className="color-grey pl-3">How would you like to develop their skills in the coming year?</label>
                                <Field as="textarea" name="skills" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="skills" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="comments" className="color-grey pl-3">Do you have any comments on their current job description</label>
                                <Field as="textarea" name="comments" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="comments" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            
                          </div>
                        </section>
                        {/* working relationship */}
                        <section className="work-relationship-section">
                          <h4 className="family-poppins-semibold mb-5">Working relationships</h4>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12">
                              <h5 className="family-poppins-semibold">Line Manager</h5>
                              <p className="color-grey mt-4">How successful working relationships been developed and maintained during the last year? Consider the relationship between the jon holder and:</p>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100">
                                <label htmlFor="lineManager" className="color-grey pl-3">You as the Line Manager</label>
                                <Field as="textarea" name="lineManager" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="lineManager" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="next-objective" className="color-grey pl-3">The Team</label>
                                <Field as="textarea" name="colleagues" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="colleagues" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="appraisal-topics" className="color-grey pl-3">Service users (patients etc)</label>
                                <Field as="textarea" name="serviceUsers" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="serviceUsers" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100">
                                <label htmlFor="objectives" className="color-grey pl-3">Review of objectives. Please list below their objectives. Make reference to objectives set at their last appraisal or to the attached job description if none set.</label>
                                <Field as="textarea" name="objectives" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="objectives" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="nextObjective" className="color-grey pl-3">What do you think their objectives should be for next year?</label>
                                <Field as="textarea" name="nextObjective" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="nextObjective" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mb-5">
                              <div className="form-group mb-0 ls-height-100 d-flex justify-content-between flex-column">
                                <label htmlFor="appraisalTopics" className="color-grey pl-3">Are there any topics you would particularly like to discuss during the appraisal meeting?</label>
                                <Field as="textarea" name="appraisalTopics" rows={5} className="form-control form-control-lg rounded-lg" disabled={isDisabledSupervisorFields} placeholder="Enter your text" defaultValue={""} />
                                <ErrorMessage name="appraisalTopics" component="div" className="error-msg pl-3 mt-2" />
                              </div>
                            </div>
                          </div>
                        </section>
                        {!isDisabledSupervisorFields && (
                          <div className="text-md-right text-center">
                            <button className="btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</button>
                            <button type="submit" disabled={isSubmitting} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                            {                     
                                isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                              )}Submit
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PostAppraisals.propTypes = {
  addPostAppraisals: func.isRequired,
  getSingleAppraisal: func.isRequired,
  getSupervisor: func.isRequired,
  isFetchingSingleAppraisal: bool.isRequired,
  isFetchingSupervisors: bool.isRequired,
  singleAppraisal: object.isRequired,
  supervisors: array.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
	supervisors: getSupervisorsListing(),
  isFetchingSupervisors: getIsFetchingSupervisors(),
  isFetchingSingleAppraisal: getIsFetchingSingleAppraisal(),
  singleAppraisal: getSingleAppraisalItem(),
});

export default connect(
  mapStateToProps,
  {
    addPostAppraisals,
    getSingleAppraisal,
    getSupervisor
  }
)(withRouter(PostAppraisals));
