/**
 * Micro Services Prefix
 */
const PREFIX = {
    LINK_STAFF: process.env.REACT_APP_BASE_URL
};

/**
 * URLS
 */
export const API_URLS = {
    AUTH: {
        LOGIN: PREFIX.LINK_STAFF + '/auth/login',
        SIGNUP: PREFIX.LINK_STAFF + '/auth/signup',
        FORGOT: PREFIX.LINK_STAFF + '/auth/forget-password',
        RESET_PASSWORD: PREFIX.LINK_STAFF + '/reset-password/',
        VERIFY_EMAIL: PREFIX.LINK_STAFF + '/verify-email/',
        LIST_EDUCATION_INSTITUTE: PREFIX.LINK_STAFF + '/institutes',
        LIST_PRACTICES: PREFIX.LINK_STAFF + '/guest/practices',
        PUBLIC_PASSPORT: PREFIX.LINK_STAFF + '/passport/',
    },
    JOBS: {
        GET_JOBS: PREFIX.LINK_STAFF + '/jobs',
        GET_JOBS_OCCUPATIONS: PREFIX.LINK_STAFF + '/jobs/type',
        GET_SINGLE_JOB: PREFIX.LINK_STAFF + '/jobs/',
        APPLY_JOB: PREFIX.LINK_STAFF + '/job/applicants',
        GET_JOB_APPLICANTS: PREFIX.LINK_STAFF + '/job/applicants',
        UPDATE_JOB_APPLICANT_STATUS: PREFIX.LINK_STAFF + '/job/applicants/status',
    },
    FORUMS: {
        GET_CATEGORIES: PREFIX.LINK_STAFF + '/forum/categories',
        GET_POSTS: PREFIX.LINK_STAFF + '/forum/posts',
        GET_POST: PREFIX.LINK_STAFF + '/forum/post/',
        UPDATE_POST: PREFIX.LINK_STAFF + '/forum/post/',
        GET_MY_POSTS: PREFIX.LINK_STAFF + '/forum/my-posts',
        CREATE_POSTS: PREFIX.LINK_STAFF + '/forum/posts'
    },
    TEAM: {
        GET_USERS_LIST: PREFIX.LINK_STAFF + '/me/federation/users',
    },
    POST_JOBS: {
        GET_JOBS: PREFIX.LINK_STAFF + '/jobs/',
        ADD_JOBS: PREFIX.LINK_STAFF + '/jobs',
        DELETE_JOBS: PREFIX.LINK_STAFF + '/jobs/',
        UPDATE_JOBS: PREFIX.LINK_STAFF + '/org/jobs/',
    },
    POST_TRAININGS: {
        GET_TRAININGS: PREFIX.LINK_STAFF + '/trainings/',
        ADD_TRAININGS: PREFIX.LINK_STAFF + '/org/trainings',
        DELETE_TRAININGS: PREFIX.LINK_STAFF + '/trainings/',
        UPDATE_TRAININGS: PREFIX.LINK_STAFF + '/org/trainings/',
    },
    TRAININGS: {
        UPDATE_TRAINING_STATUS: PREFIX.LINK_STAFF + '/org/trainings/status/',
        GET_TRAININGS: PREFIX.LINK_STAFF + '/trainings',
        APPLY_TRAININGS: PREFIX.LINK_STAFF + '/training/applicants',
        GET_TRAININGS_APPLICANTS: PREFIX.LINK_STAFF + '/training/applicants',
        GET_TRAININGS_APPLIED: PREFIX.LINK_STAFF + '/training/applied',
        ADD_TRAINING_FEEDBACK: PREFIX.LINK_STAFF + '/training/feedback',
        UPDATE_TRAININGS_STATUS: PREFIX.LINK_STAFF + '/training/applicants/status',
		UPDATE_TRAINING_APPLIED_STATUS: PREFIX.LINK_STAFF + '/training/applied/status',
		POST_FEEDBACK_SINGLE_TRAINING: PREFIX.LINK_STAFF + '/training/feedback',
		POST_RATING_SINGLE_TRAINING_PREFIX: PREFIX.LINK_STAFF + '/trainings/',
		POST_RATING_SINGLE_TRAINING_POSTFIX: '/ratings',
    },
    TRAINING_ANALYSIS:{
        ADD_TRAINING_FORM: PREFIX.LINK_STAFF + '/training-anlysis',
        GET_TRAINING_ANALYSIS_REPORT: PREFIX.LINK_STAFF + '/training-anlysis/report',
        TRAINING_ANALYSIS_REPORT_DOWNLOAD: PREFIX.LINK_STAFF + '/training-anlysis/report/download',
    },
    PLACEMENTS: {
        GET_PLACEMENTS: PREFIX.LINK_STAFF + '/placements',
        // GET_PLACEMENTS_OCCUPATIONS: PREFIX.LINK_STAFF + '/jobs/type',
        GET_SINGLE_PLACEMENTS: PREFIX.LINK_STAFF + '/placements/',
        APPLY_PLACEMENTS: PREFIX.LINK_STAFF + '/placement/applicants',
        UPDATE_PLACEMENT_STATUS: PREFIX.LINK_STAFF + '/placements/status/',
        GET_PLACEMENTS_APPLICANTS: PREFIX.LINK_STAFF + '/placement/applicants',
        UPDATE_PLACEMENT_APPLICANT_STATUS: PREFIX.LINK_STAFF + '/placement/applicants/status',

        MENTOR_PLACEMENTS: PREFIX.LINK_STAFF + '/placement/mentors',
        GET_PLACEMENTS_MENTORS: PREFIX.LINK_STAFF + '/placement/mentors',
		UPDATE_PLACEMENT_MENTORS_STATUS: PREFIX.LINK_STAFF + '/placement/mentors/status',
		LIKE_DISLIKE: PREFIX.LINK_STAFF + '/placements/',
    },
    POST_PLACEMENTS: {
        GET_PLACEMENTS: PREFIX.LINK_STAFF + '/placements/',
        ADD_PLACEMENTS: PREFIX.LINK_STAFF + '/placements',
        DELETE_PLACEMENTS: PREFIX.LINK_STAFF + '/placements/',
        UPDATE_PLACEMENTS: PREFIX.LINK_STAFF + '/placements/',
    },
    RESOURCES: {
        GET_RESOURCES: PREFIX.LINK_STAFF + '/me/booklets',
        GET_USERS_LISTING: PREFIX.LINK_STAFF + '/me/org/users',
        GET_SINGLE_RESOURCE: PREFIX.LINK_STAFF + '/me/booklet',
        GET_SUPERVISION_RESOURCE: PREFIX.LINK_STAFF + '/me/resources-supervision',
        GET_ASSESSOR_RESOURCE: PREFIX.LINK_STAFF + '/me/resources-assessment',
        UPDATE_TASK_EVIDENCE: PREFIX.LINK_STAFF + '/me/booklet/task/evidence',
        UPDATE_SUPERVISION_RESOURCE: PREFIX.LINK_STAFF + '/me/assessment-supervision/',
        UPDATE_INDEX_ACCSUP: PREFIX.LINK_STAFF + '/me/booklet/index/assessment',
        UPDATE_INDEX_INTERVIEW: PREFIX.LINK_STAFF + '/me/booklet/index/interview',
        LIST_SUBMITTED_BOOKLETS_USERS: PREFIX.LINK_STAFF + '/me/user-submitted-resources',
        LIST_SUBMITTED_BOOKLETS_BY_USER: PREFIX.LINK_STAFF + '/me/user-submitted-resources/',
        SUPERVISOR_BOOKLET_DETAIL: PREFIX.LINK_STAFF + '/me/supervisor-booklet',
        SUBMIT_FOR_CERTIFICATION: PREFIX.LINK_STAFF + '/me/booklet/certification/',
    },
    POST_RESOURCES: {
        GET_RESOURCES: PREFIX.LINK_STAFF + '/booklets',
        ADD_POST_RESOURCE: PREFIX.LINK_STAFF + '/user/booklet',
    },
    POST_INVOICE: {
        GET_CLIENT_NAME: PREFIX.LINK_STAFF + '/users',
        ADD_POST_INVOICE: PREFIX.LINK_STAFF + '/me/invoices',
        GET_MY_INVOICE_LIST: PREFIX.LINK_STAFF + '/me/invoices',
        GET_CLIENT_INVOICE_LIST: PREFIX.LINK_STAFF + '/me/client-invoices',
        GET_CLIENT_INVOICE_BY_ID: PREFIX.LINK_STAFF + '/me/client-invoices/',
        DELETE_MY_INVOICE_LIST: PREFIX.LINK_STAFF + '/me/invoices/',
    },
    PCN: {
        GET_PCN: PREFIX.LINK_STAFF + '/me/org/stp',
        MY_PCN: PREFIX.LINK_STAFF + '/me/org/pcn',
        GET_SINGLE_PCN: PREFIX.LINK_STAFF + '/me/org/pcn/federation/'
    },
    SIDEBAR: {
        GET_CONTACTS_CHATS: PREFIX.LINK_STAFF + '/chat-users',
    },
    NEWS: {
        GET_NEWS_LISTING: PREFIX.LINK_STAFF + '/news',
        GET_SINGLE_NEWS: PREFIX.LINK_STAFF + '/news/'
    },
    EVENTS: {
        GET_EVENTS_LISTING: PREFIX.LINK_STAFF + '/events',
        APPLY_EVENTS: PREFIX.LINK_STAFF + '/event/applicants',
        GET_EVENTS_APPLICANTS: PREFIX.LINK_STAFF + '/event/applicants',
        DELETE_EVENTS: PREFIX.LINK_STAFF + '/events/',
    },
    POLICY_DOC: {
        CREATE: PREFIX.LINK_STAFF + '/policies',
        LIST: PREFIX.LINK_STAFF + '/policies',
        SHOW: PREFIX.LINK_STAFF + '/policies/',
        UPDATE: PREFIX.LINK_STAFF + '/policies/',
        DELETE: PREFIX.LINK_STAFF + '/policies/',
    },
    WELL_BEING: {
        CREATE: PREFIX.LINK_STAFF + '/well-beings',
        LIST: PREFIX.LINK_STAFF + '/well-beings',
        SHOW: PREFIX.LINK_STAFF + '/well-beings/',
        UPDATE: PREFIX.LINK_STAFF + '/well-beings/',
        DELETE: PREFIX.LINK_STAFF + '/well-beings/',
        APPLY: PREFIX.LINK_STAFF + '/well-being/applicants',
        GET_APPLICANTS: PREFIX.LINK_STAFF + '/well-being/applicants',
        UPDATE_APPLICANT_STATUS: PREFIX.LINK_STAFF + '/well-being/applicants/status',
        LIKE_DISLIKE: PREFIX.LINK_STAFF + '/well-beings/',
    },
    REVALIDATION: {
        GET_REFLECTIVE_ACCOUNT: PREFIX.LINK_STAFF + '/reflective-account',
        POST_REFLECTIVE_ACCOUNT: PREFIX.LINK_STAFF + '/reflective-account',
        GET_REFLECTIVE_DISCUSSION: PREFIX.LINK_STAFF + '/reflective-discussion',
        POST_REFLECTIVE_DISCUSSION: PREFIX.LINK_STAFF + '/reflective-discussion',

        GET_FEEDBACK_LIST: PREFIX.LINK_STAFF + '/feedback-logs',
        POST_FEEDBACK: PREFIX.LINK_STAFF + '/feedback-logs',
        SHOW_FEEDBACK: PREFIX.LINK_STAFF + '/feedback-log/',
        DELETE_FEEDBACK: PREFIX.LINK_STAFF + '/feedback-log/',
        UPDATE_FEEDBACK: PREFIX.LINK_STAFF + '/feedback-log/',

        GET_CPD_LIST: PREFIX.LINK_STAFF + '/cpd-logs',
        POST_CPD: PREFIX.LINK_STAFF + '/cpd-logs',
        SHOW_CPD: PREFIX.LINK_STAFF + '/cpd-log/',
        DELETE_CPD: PREFIX.LINK_STAFF + '/cpd-log/',
        UPDATE_CPD: PREFIX.LINK_STAFF + '/cpd-log/',


        GET_PRACTICE_LIST: PREFIX.LINK_STAFF + '/practice-logs',
        POST_PRACTICE: PREFIX.LINK_STAFF + '/practice-logs',
        SHOW_PRACTICE: PREFIX.LINK_STAFF + '/practice-log/',
        DELETE_PRACTICE: PREFIX.LINK_STAFF + '/practice-log/',
        UPDATE_PRACTICE: PREFIX.LINK_STAFF + '/practice-log/',
    },

    COMPETENCIES: {
        GET_CATEGORIES: PREFIX.LINK_STAFF + '/booklet-category',
    },
    POST_EVENTS: {
        ADD_EVENTS: PREFIX.LINK_STAFF + '/events',
        GET_EVENTS: PREFIX.LINK_STAFF + '/events/',
        UPDATE_EVENTS: PREFIX.LINK_STAFF + '/events/',
    },
    UPDATE_PERSONAL_INFO: {
        FETCH_PRACTICE_LIST: PREFIX.LINK_STAFF + '/users',
        GET_PERSONAL_INFO: PREFIX.LINK_STAFF + '/practices/',
    },
    CALENDER: {
        GET_CALENDER_LISTING: PREFIX.LINK_STAFF + '/calender',
    },
    POST_NEWS: {
        GET_NEWS: PREFIX.LINK_STAFF + '/news/',
        ADD_NEWS: PREFIX.LINK_STAFF + '/news',
        DELETE_NEWS: PREFIX.LINK_STAFF + '/news/',
        UPDATE_NEWS: PREFIX.LINK_STAFF + '/news/',
        PUBLISH_NEWS: PREFIX.LINK_STAFF + '/news/publish/',
    },
    PROFILE: {
        UPDATE_PROFILE: PREFIX.LINK_STAFF + '/auth/me'
    },
    USER_PROFILE: {
        GET_PRACTICE: PREFIX.LINK_STAFF + '/practices',
        GET_INSTITUTION_NAME: PREFIX.LINK_STAFF + '/institutes',
        GET_EMPLOYMENTS: PREFIX.LINK_STAFF + '/me/employment',
        GET_EDUCATIONS: PREFIX.LINK_STAFF + '/me/education',
        ADD_PRACTICE: PREFIX.LINK_STAFF + '/me/employment',
        DELETE_EMPLOYMENT: PREFIX.LINK_STAFF + '/me/employment/',
        DELETE_EDUCATION: PREFIX.LINK_STAFF + '/me/education/'
    },
    POLLING: {
        GET_LIST_POLLING: PREFIX.LINK_STAFF + '/polls',
        ADD_POLLING: PREFIX.LINK_STAFF + '/polls/',
        VOTE_A_POLL_PREFIX: PREFIX.LINK_STAFF + '/poll/',
        VOTE_A_POLL_POSTFIX: '/vote',
    },
    ADMIN: {
        USERS: {
            GET_USERS_LISTING: PREFIX.LINK_STAFF + '/users',
            APPROVE_USERS: PREFIX.LINK_STAFF + '/users/account/approve/',
            GET_ORGANISATION_LISTING: PREFIX.LINK_STAFF + '/organisation',
            ADD_USER: PREFIX.LINK_STAFF + '/users'
        }
    },
    REPORTS: {
        GET_REPORTS_DATA: PREFIX.LINK_STAFF + '/report',
    },
    COMMENTS: {
        PLACEMENTS: PREFIX.LINK_STAFF + '/comments/placements/',
        PRACTICES: PREFIX.LINK_STAFF + '/comments/practices/',
        WELLBEING: PREFIX.LINK_STAFF + '/comments/well-being/',
	},
	APPRAISALS: {
		GET_APPRAISALS: PREFIX.LINK_STAFF + '/appraisals',
		POST_APPRAISALS: PREFIX.LINK_STAFF + '/appraisals',
		GET_SUPERVISOR: PREFIX.LINK_STAFF + '/me/org/users',
    },
    AUDIT: {
		GET_AUDIT: PREFIX.LINK_STAFF + '/audit-tool',
		POST_AUDIT: PREFIX.LINK_STAFF + '/audit-tool',
	},
    IMAGE_UPLOAD: PREFIX.LINK_STAFF + '/files'
};