import React, { Fragment } from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import Share from "../../../components/share";
import { isRouteAllow } from 'utils/helper';

/**
 *  Import other dependencies
 */
import { getTrainings, applyTraining, updateTrainingStatus, applyFilters, checkUnCheckFilters, applyPagination, resetFilters } from '../../../modules/policyDocs/actions';
import { getTrainingsListing, getIsFetchingTrainings, getTotalTrainingPages, getTrainingsPage } from '../../../modules/policyDocs/selectors';
import {deletePostTraining}  from '../../../modules/policyDocPost/actions';
import Loader from 'components/Loader';

// import { trainingType, trainingLevels } from './trainingFilters';


const dateFormat = 'DD-MMM-YYYY';

const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

class PolicyDocs extends React.Component {
  state={
    search: '',
    disableStatus: true,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    policydoc:null,
  }

  openShareModal(policydoc) {
    this.setState({
        isVisibleShare: true,
        policydoc: policydoc,
    });
  }

  handleQueryChange = query => {
    this.setState({
        search: query
    });
  };

  componentWillMount() {
    // this.props.resetFilters();
    this.props.getTrainings();
  }

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  getFormattedDateTime = date => {
    if (!date) return;
    return moment(date).format(dateTimeFormat);
  };

  searchTrainings = () => {
    const {search} = this.state;
    this.props.applyFilters(search);
  };

  resetTrainings = () => {
    this.props.resetFilters();
  };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  deletePolicyDoc = (id) => {
    this.props.deletePostTraining(id).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Deleted.');
    })
  }

  getSinglePolicyDoc = (policydoc, index, id) => (
    <div className="col-xl-4 col-sm-6 px-xl-4 mb-4">
      <div className="card border-0 lS-card-shadow lS-card-radius ls-height-100">
        <div className="card-body d-flex">
          <div className="thumb-wrap thumb-wrap-sq border ls-border-color">
            <img src={require("assets/images/guidelines/pdf.png")} alt="..." className="img-fluid" />
          </div>
          <div className="min-width0 flex-fill pl-3">
            <div className="d-flex justify-content-between flex-column ls-height-100">
              <div className="flex-fill">
                <h5 className="mt-0 font-normal color-black-heading text-truncate family-poppins-medium">{policydoc.title}</h5>
                <p className="color-primary font-15 mb-1 text-capitalize">{policydoc.federation}</p>
                <p className="color-grey mb-1 font-14">{ policydoc.last_updated ? this.getFormattedDate(policydoc.last_updated) : `--`}</p>
                { policydoc.org_name && (
                  <p className="color-grey m-0 font-14"><span className="color-black">Credit Organisation:</span> {policydoc.org_name}</p>
                )}
              </div>
              <div className="action-buttons d-flex align-items-center flex-wrap mt-1">
                { policydoc.can_edit && (
                  <Fragment>
                    <Link to={`/user/post-guidelines/${id}`} 
                        className="btn btn-primary lS-btn-icon lS-btn-blue-style rounded-lg mt-2 mr-2">
                        <i className="fas fa-edit"></i>
                    </Link>
                  
                    <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2"
                            onClick={() => this.deletePolicyDoc(id)}>
                      <i className="fas fa-trash"></i>
                    </button>
                  </Fragment>
                )}
  
                { policydoc.doc_url && (
                    <a className="btn btn-primary lS-btn-icon lS-btn-yellow-style rounded-lg mt-2 mr-2"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        href={policydoc.doc_url}>
                        <i className="fas fa-download"/>
                    </a>
                )}

                { policydoc.link_url && (
                    <a className="btn btn-primary lS-btn-icon lS-btn-blue-style rounded-lg mt-2 mr-2"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        href={policydoc.link_url}>
                        <i className="fas fa-eye" />
                    </a>
                )}

                <button className="btn btn-primary lS-btn-icon lS-btn-gold-style rounded-lg mt-2 mr-2"
                        onClick={() => this.openShareModal(policydoc)} >
                  <i className="fas fa-share-alt" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  render() {
    const { trainings, isFetchingTrainings, totalPages, page } = this.props;
    const { search, isVisibleShare, policydoc } = this.state;
    return (
    <>
    <UserHeader />
      {/* Page content */}
      <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
        {/* bread crum */}
        <div className="card border-0 page-title-bar">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <h4 className="family-poppins-semibold">Guidelines</h4>
                <p className="mb-0 font-14 color-grey">Find advice, guidance and information for your clinical area of practice.</p>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-lg-end justify-content-center">
                  {isRouteAllow('/post-guidelines') && (
                    <Link to={`/user/post-guidelines`}>
                      <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Guidelines</button>
                    </Link>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search bar */}
        <div className="ls-filters-bar my-md-5 my-4">
          <div className="input-group ls-search-field filter-only-search">
            <div className="input-group-prepend">
              <span className="input-group-text bg-white pr-0 font-12 color-grey">
                <i className="fas fa-search" />
              </span>
            </div>
            <input type="text" className="form-control border-left-0" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>
        {/* GuideLine Page */}
        <div className="row mx-xl-n4">
          {!isFetchingTrainings && trainings && trainings.length > 0 && trainings.map((job, index) => this.getSinglePolicyDoc(job.attributes, index, job.id))}
          {!isFetchingTrainings && trainings && trainings.length === 0 && (
            <div className="col-12">
              <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                No guidelines found
              </div>
            </div>
          )}
          {isFetchingTrainings && (
            <div className="col-12"><Loader /></div>
          )}
        </div>
        {/* Pagination */}
        <div aria-label="Page navigation example" className="ls-pagination">
          <ReactPaginate
            containerClassName={'pagination justify-content-end mt-5'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            forcePage={page - 1}
            onPageChange={selectedItem => {
              if (isFetchingTrainings) return;
              this.loadPage(selectedItem.selected + 1);
            }}
            subContainerClassName={'pages pagination'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </main>
      {/* <main>
        <div className="container-fluid">
        <div className="row programm_list filter">
          <div className="col-lg-8 offset-lg-2 col-md-12">
            <div className="box">                  
              <div className="row col-md-5">                    
                  <input type="text" name="search" className="form-control" onChange={event => this.handleQueryChange(event.target.value) } value={search} placeholder="Search " />
                  <button onClick={this.searchTrainings} className="btn btn-info mt-2 mr-2">Search</button>
                  <button onClick={this.resetTrainings} className="btn btn-info mt-2 mr-2">Clear</button>                    
              </div>
            </div>
          </div>
        </div>
      </div>
    </main> */}
    {/* User Share Popup */}
    {isVisibleShare && (
        <Share
            isVisible={isVisibleShare}
            // endpoint={"/user/trainings/"+ trainingId}
            externalEndpoint={policydoc.doc_url || policydoc.link_url}
            onClose={() => {
                this.setState({
                  isVisibleShare: false,
                });
            }}
            onCompleted={res => this.onCompleteImageCrop(res)}
        />
        )}
    <UserFooter />
    </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PolicyDocs.propTypes = {
    applyFilters: func.isRequired,
    checkUnCheckFilters: func.isRequired,
    getTrainings: func.isRequired,
    isFetchingTrainings: bool.isRequired,
    trainings: array.isRequired,
    page: number.isRequired,
    totalPages: number.isRequired,
    applyTraining: func.isRequired,
    updateTrainingStatus: func.isRequired,
  };

  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({
    trainings: getTrainingsListing(),
    isFetchingTrainings: getIsFetchingTrainings(),
    page: getTrainingsPage(),
    totalPages: getTotalTrainingPages()
  });

  export default connect(
    mapStateToProps,
    {
      getTrainings,
      deletePostTraining,
      checkUnCheckFilters,
      applyFilters,
      applyPagination,
      resetFilters,
      applyTraining,
      updateTrainingStatus,
    }
  )(withRouter(PolicyDocs));