/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_PRACTICE,
  GET_PRACTICE_SELECT_DATA,
  UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  page:1,
  limit:100000,
  practiceList: {},
  isFetchingPractice: false,
  practiceListSelect: [],
  isFetchingPracticeSelect: false,
  isUploadingImage: false,
  isDeletingEvent: false,
});

/**
 *  Define the reducer with actions
 */
function updatePracticeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_PRACTICE:
      return fromJS({
        ...state.toJS(),
        isFetchingPractice: true,
      })

    case success(GET_LIST_OF_PRACTICE):
      let practiceResult=[];
      const { data } = action.payload.data;
      const includesValue = action.payload.data.included;

      // Step: 1 - filter all indexes
      practiceResult = filterData(data.relationships.ambassadors.data, includesValue);
      data.ambassadors = practiceResult;
      delete data.relationships;

      return fromJS({
        ...state.toJS(),
        practiceList: data ? data : {},
        isFetchingPractice: false,
      })

    case failure(GET_LIST_OF_PRACTICE):
      return fromJS({
        ...state.toJS(),
        isFetchingPractice: false,
      })
      
    case GET_PRACTICE_SELECT_DATA:
      return fromJS({
        ...state.toJS(),
        isFetchingPracticeSelect: true,
      })

    case success(GET_PRACTICE_SELECT_DATA):
      const selectData = action.payload.data.data;
      return fromJS({
        ...state.toJS(),
        practiceListSelect: selectData.length > 0 ? selectData  : [],
        isFetchingPracticeSelect: false,
      })

    case failure(GET_PRACTICE_SELECT_DATA):
      return fromJS({
        ...state.toJS(),
        isFetchingPracticeSelect: false,
      })

    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })
    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

const filterData = (data, includes) => {
  return data.map( data => includes.find(value => value.type === data.type && value.id === data.id))
};

/**
 *  Export the reducer
 */
export default updatePracticeReducer
