import React from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { occupations, jobType, duration, location, group } from './placementFilters';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  uploadImage,
  addPostPlacement,
  updatePostPlacement,
  getPlacement,
} from '../../modules/placementPost/actions';

import {
  getIsUploadingImage,
  getIsFetchingPlacement,
  getPlacementsListing,
} from '../../modules/placementPost/selectors';
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const dateFormat = 'DD-MMM-YYYY';

const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

const addEditPlacementSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  // description: Yup.string()
  //   .required('Required'),
  organisation: Yup.string()
    .required('Required'),
  occupation: Yup.string()
    .required('Required'),
  address: Yup.string()
    .required('Required'),
  // region: Yup.string()
  //   .required('Required'),
  duration: Yup.string()
    .required('Required'),
  // type: Yup.string()
  //   .required('Required'),
  // group: Yup.string()
  //   .required('Required'),
  // start_date: Yup.string()
  //   .required('Required'),
  // end_date: Yup.string()
  //   .required('Required'),
  orgURL: Yup.string()
    .nullable()
    .url("Please add a valid url."),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        field.name === 'type' ?
          form.setFieldValue('type', value.value) :
            field.name === 'occupation' ?
            form.setFieldValue('occupation', value.value) :
              field.name === 'duration' ?
              form.setFieldValue('duration', value.value) :
                field.name === 'region' ?
                  form.setFieldValue('region', value.value) :
                    form.setFieldValue('group', value.value)
      }}
      value={field.value ? formattedArray([field.value]) : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class PlacementPost extends React.Component {

  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getPlacement(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/placements');
        }
      });
    }
  }

  getFormikInitVal = () => {
    const { placement } = this.props;
    const { mode } = this.state;

    if (mode === 'edit') {
      const {
        title,
        description,
        organisation_name,
        occupation_type,
        type,
        address,
        region,
        duration,
        group,
        image_url,
        start_date,
        end_date,
        org_url,
      } = placement.attributes || {};

      return {
        title,
        organisation:organisation_name,
        occupation:occupation_type,
        description,
        type,
        address,
        duration,
        group,
        imageUrl:image_url,
        region,
        start_date: start_date ? moment(start_date) : start_date,
        end_date:  end_date ? moment(end_date) : end_date,
        orgURL: org_url,
        // closingDate: moment(closing_date)
      }
    }

    return {
      title: "",
      organisation: "",
      occupation: "",
      description: "",
      type: "",
      address: "",
      imageUrl: "",
      duration:"",
      group:"",
      region: '',
      start_date: '',
      end_date: '',
      orgURL: '',
    }
  };

  handlePlacementImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("image", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('imageUrl', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <div>
            <label className="color-grey pl-3">Image Preview</label>
          </div>
          <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <label htmlFor="placements-upload" className="color-grey pl-3">Upload File</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="placements-upload" onChange={event => this.handlePlacementImage(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="placements-upload">Choose File</label>
				</div>
      </>
    )
  };

  postPlacement = (values, { setSubmitting, resetForm }) => {
    const { addPostPlacement, updatePostPlacement, history } = this.props;
    const { mode } = this.state;
    var params = {
      title: values.title,
      organisation_name: values.organisation,
      occupation_type: values.occupation,
      type: values.type,
      group: values.group,
      duration: values.duration,
      description: values.description,
      address: values.address,
      region: values.region,
      org_url: values.orgURL,
      start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : null,
      end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : null,
    };

    if (values.imageUrl) {
      params.image_url = values.imageUrl;
    }

    if (mode === 'edit') {
      updatePostPlacement(params, this.props.match.params.id).then(res => {
        showSuccessMsg("Placement successfully Updated");
        setSubmitting(false);
        if (history) {
          history.push('/user/placements');
        }
      }, err => {
        setSubmitting(false);
      });
      return;
    }

    if (mode === 'add') {
      addPostPlacement(params).then(res => {
        showSuccessMsg("Placement successfully added");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/placements');
        }
      }, err => {
        setSubmitting(false);
      });
    }
  };
  render() {
    const {
      isUploadingImage,
      isFetchingPlacement,
    } = this.props;
    const { mode } = this.state;
    return (
      <>
        <UserHeader />        
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 pb-5 mt-md-5 mt-4">
            {/* bread crum */}
            <div className="card page-title-bar border-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="family-poppins-semibold">{mode === 'edit' ? 'Edit' : 'Post'} Placement </h4>
                    <p className="mb-0 font-14 color-grey"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            <div className="card mt-md-5 mt-4 lS-card-shadow border-0 lS-card-radius">
              {isFetchingPlacement && (
                <Loader />
              )}
              
              <div className="card-body">
              {!isFetchingPlacement && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditPlacementSchema}
                  onSubmit={this.postPlacement}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="ls-form pt-2">
                {/* <form className="ls-form pt-2" action encType="multipart/form-data" method="post"> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="title" className="color-grey pl-3">Title</label>
                        <Field type="text" name="title" className="form-control form-control-lg rounded-lg" id="title" placeholder="Enter title" />
                        <ErrorMessage name="title" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="organisation" className="color-grey pl-3">Organisation</label>
                        <Field type="text" name="organisation" className="form-control form-control-lg rounded-lg" id="organisation" placeholder="Enter Organisation" />
                        <ErrorMessage name="organisation" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="occupation" className="color-grey pl-3">Occupation</label>
                        <Field as="select" name="occupation" className="form-control form-control-lg rounded-lg" id="occupation" component={formSelect} options={occupations} placeholder="Select Occupation" />
                        <ErrorMessage name="occupation" component="div" className="error-msg" />
                      </div>
                    </div>  
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="type" className="color-grey pl-3">Category</label>
                        <Field as="select" name="type" className="form-control form-control-lg rounded-lg" id="type" placeholder="Select Category" component={formSelect} options={jobType} />
                        <ErrorMessage name="type" component="div" className="error-msg" />
                      </div>
                    </div>  
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="region" className="color-grey pl-3">Region</label>
                        <Field as="select" name="region" className="form-control form-control-lg rounded-lg" id="region" placeholder="Select Region" component={formSelect} options={location} />
                        <ErrorMessage name="region" component="div" className="error-msg" />
                      </div>
                    </div>  
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="group" className="color-grey pl-3">Group</label>
                        <Field as="select" name="group" className="form-control form-control-lg rounded-lg" id="group" placeholder="Select Group" component={formSelect} options={group}  />
                        <ErrorMessage name="group" component="div" className="error-msg" />
                      </div>
                    </div>      
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group mb-5">
                        <label htmlFor="start-date" className="color-grey pl-3">Start Date</label>
                        <Datetime
                          inputProps={{placeholder: 'Select Start Date'}}
                          renderInput={ this.renderCustomInput }
                          closeOnSelect={true}
                          isValidDate={ valid }
                          onChange={start_date => {
                            if (typeof start_date !== "object") return;
                            setFieldValue('start_date', start_date)
                          }}
                          value={values['start_date']}
                        />
                        <ErrorMessage name="start_date" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group mb-5">
                        <label htmlFor="end-date" className="color-grey pl-3">End Date</label>
                        <Datetime
                          inputProps={{placeholder: 'Select End Date'}}
                          renderInput={ this.renderCustomInput }
                          closeOnSelect={true}
                          isValidDate={ valid }
                          onChange={end_date => {
                            if (typeof end_date !== "object") return;
                            setFieldValue('end_date', end_date)
                          }}
                          value={values['end_date']}
                        />
                        <ErrorMessage name="end_date" component="div" className="error-msg" />
                      </div>
                    </div>   
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group mb-5">
                        <label htmlFor="duration" className="color-grey pl-3">Duration</label>
                        <Field as="select" name="duration" className="form-control form-control-lg input-with-icon rounded-lg" id="duration" 
                              placeholder="Enter Duration" component={formSelect} options={duration} />
                        <ErrorMessage name="duration" component="div" className="error-msg" />
                      </div>
                    </div>           
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="address" className="color-grey pl-3">Location</label>
                        <Field type="text" name="address" className="form-control form-control-lg rounded-lg" id="address" placeholder="Enter Location" />
                        <ErrorMessage name="address" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="org_url" className="color-grey pl-3">Organization URL</label>
                        <Field type="text"  name="orgURL" className="form-control form-control-lg rounded-lg" id="org_url" placeholder="Enter URL" />
                        <ErrorMessage name="orgURL" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        {!isUploadingImage && (
                          <Field type="file" component={this.fileUpload} name="imageUrl" className="custom-file-input" id="file" />
                        )}
                        {isUploadingImage && (
                          <Loader />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group d-flex flex-column ls-height-100 pb-5">
                        <label htmlFor="description" className="color-grey pl-3">Description</label>
                        <Field as="textarea" name="description" rows={3} className="flex-fill form-control form-control-lg rounded-lg" id="description" placeholder="Enter Description" defaultValue={""} />
                        <ErrorMessage name="description" component="div" className="error-msg" />
                      </div>
                    </div>
                  </div>
                  <div className="text-md-right text-center">
                    <button type="button" className="btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</button>
                    <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                      {
                        isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Submit
                    </button>
                  </div>
                {/* </form> */}
                 </Form>
                 )}
               </Formik>
               )}
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}
/**
 *  Define component PropTypes
 */
PlacementPost.propTypes = {
  addPostPlacement: func.isRequired,
  isFetchingPlacement: bool.isRequired,
  isUploadingImage: bool.isRequired,
  placement: object.isRequired,
  updatePostPlacement: func.isRequired,
  uploadImage: func.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
  isFetchingPlacement: getIsFetchingPlacement(),
  placement: getPlacementsListing(),
});

export default connect(
    mapStateToProps,
     {
      uploadImage,
      addPostPlacement,
      updatePostPlacement,
      getPlacement,
    }
  )(withRouter(PlacementPost));