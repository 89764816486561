/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const auditTool = () => state => state.get('auditTool');

/**
 *  Select the correct portion of the root reducer
 */
export const getAuditTool = () =>
  createSelector(auditTool(), state => {
    return state.get('audit') ? state.get('audit').toJS() : null;
  });

/**
 *  Select is fetching auditTool
 */
export const getIsFetchingAuditTool = () =>
  createSelector(auditTool(), state => {
    return state.get('isFetchingauditTool')
  })
