/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const polling = () => state => state.get('polling');

/**
 *  Select =is Uploading
 */
export const getIsUpdatingProfile = () =>
  createSelector(polling(), state => {
    return state.get('isUpdatingProfile')
  })

/**
 *  Select =is Uploading
 */
export const getIsFetchingPollingList = () =>
  createSelector(polling(), state => {
    return state.get('isFetchingPollingList')
  })

/**
 *  Select =is Get Practice
 */
export const getIsPollingList = () =>
  createSelector(polling(), state => {
    const listing = state.get('isPollingList').toJS()
    return listing.length > 0 ? listing : []
  })

/**
 *  Select total number of adminUserListing pages
 */
export const getTotalPages = () =>
  createSelector(polling(), state => {
    return state.get('totalPage')
  })

