import React from "react";
import { func, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import { getFormattedDate } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  getTrainingApplicants,
  updateTraining
} from '../../modules/trainings/actions';
import {
  getTrainingApplicantsListing,
  getIsFetchingTrainingApplicants,
} from '../../modules/trainings/selectors';

import Loader from 'components/Loader';

class TrainingApplicants extends React.Component {
    componentWillMount() {
        const { trainingId } = this.props.match.params;
        if (trainingId && !isNaN(trainingId)) {
            var params = {
            training_id:trainingId,
            };
            this.props.getTrainingApplicants(params);
        }
    }
    markCompleted = (applicantId, status) =>{
        if(this.props.match.params.trainingId){
            let params={
                training_id: this.props.match.params.trainingId,
                applicant_id: applicantId,
                status: status
            }
            this.props.updateTraining(params).then(res => {
                const { message } = res;
                var innerParams = {
                    'training_id': this.props.match.params.trainingId,
                };
                this.props.getTrainingApplicants(innerParams);
                showSuccessMsg(message);
            });
        }
    }

    render() {
        const {
            isFetchingTrainingApplicants,
            trainingApplicants,
          } = this.props;

        return (
            <>
            <UserHeader />
            {/* Page content */}
            <main class="m-0 px-3 px-md-5 mt-md-5 mt-4 mb-5">
                <div class="card border-0 page-title-bar">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h4 class="family-poppins-semibold my-2">Training Applicants</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border-0 lS-card-shadow lS-card-radius mt-md-5 mt-4" id="myTabContent">
                    <div className="card-body" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <div className="table-responsive ls-table">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                    <th scope="col" className="pb-4 text-left" width="100"> Image </th>
                                    <th scope="col" className="pb-4" width="200"> Name </th>
                                    <th scope="col" className="pb-4" width="300"> E-mail </th>
                                    {/* <th scope="col" className="pb-4"> Organizer </th> */}
                                    <th scope="col" className="pb-4" width="120"> Status </th>
                                    <th scope="col" className="pb-4" width="150"> Date booked </th>
                                    <th scope="col" className="pb-4" width="200"> Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isFetchingTrainingApplicants && trainingApplicants && trainingApplicants.length > 0 && trainingApplicants.map((trainingApplicant, index) => {
                                        const {profile_image_url, name, status, email, created_at, profile_identifier} = trainingApplicant.attributes;
                                        return(
                                        <tr key={index}>
                                            <td className="py-4 text-left"> 
                                                <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                                                    <img src={profile_image_url} alt="..." className="img-object-fit" />
                                                </div>
                                            </td>
                                            <td className="py-4">{name} </td>
                                            <td className="py-4"> {email} </td>
                                            {/* <td className="py-4"> CLH </td> */}
                                            <td className="py-4"> {status} </td>
                                            <td className="py-4"> {getFormattedDate(created_at)} </td>
                                            <td className="py-4">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <button className="btn btn-primary lS-btn-sm lS-btn-green-style mr-2 font-13"
                                                            onClick={() => this.markCompleted(trainingApplicant.id, 'accepted')}
                                                            disabled={status === 'accepted' || status === 'cancelled' || status === 'completed' }>
                                                        Accept
                                                    </button>
                                                    <button className="btn btn-primary lS-btn-sm lS-btn-red-style  mr-2 font-13"
                                                            disabled={status === 'rejected'  || status === 'cancelled' || status === 'completed'}
                                                            onClick={() => this.markCompleted(trainingApplicant.id, 'rejected')}>
                                                        Reject
                                                    </button>
                                                    <button className="btn btn-primary lS-btn-sm lS-btn-red-style font-13"
                                                            disabled={status !== 'accepted' }
                                                            onClick={() => this.markCompleted(trainingApplicant.id, 'completed')}>
                                                        Completed
                                                    </button>
                                                    {/* <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-green-style rounded-lg">
                                                        <i className="fas fa-share-alt" />
                                                    </button> */}
                                                </div>
                                            </td>
                                        </tr>
                                        )}
                                    )}
                                    {!isFetchingTrainingApplicants && trainingApplicants && trainingApplicants.length === 0 && (
                                        <tr>
                                            <td colspan="6">
                                                <div className="text-center bg-white py-5 px-3 radius-10 family-poppins-medium">
                                                    No application found for this training
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {isFetchingTrainingApplicants && (
                                        <tr>
                                            <td colspan="6">
                                                <Loader />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            <UserFooter/>

            </>
        );
    }
}

/**
 *  Define component PropTypes
 */
TrainingApplicants.propTypes = {
    trainingApplicants: array.isRequired,
    isFetchingTrainingApplicants: bool.isRequired,
    getTrainingApplicants: func.isRequired,
  };

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    trainingApplicants: getTrainingApplicantsListing(),
    isFetchingTrainingApplicants: getIsFetchingTrainingApplicants(),
  });

export default connect(
    mapStateToProps,
    {
        getTrainingApplicants,
        updateTraining
    }
  )(withRouter(TrainingApplicants));