import React from "react";

import { func, bool } from 'prop-types';
import './styles.css';

class NextPrevBtn extends React.Component {
  getClassForStep = step => {
    const { id } = step;
    const { activeStep } = this.props;

    if (id < activeStep) {
      return 'progtrckr-done';
    }

    if (id === activeStep) {
      return 'progtrckr-doing';
    }

    return "progtrckr-todo";
  };

  render() {
    const { isDisabledNext, isDisabledPrev, isLoading, isLast, onPrev } = this.props;
    return (
      <>
        <button type="button" className="ls-btn-link btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3" onClick={onPrev} disabled={isDisabledPrev}>
          Previous
        </button>
        <button type="submit" className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3" disabled={isDisabledNext}>
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          {isLast ? 'Save' : 'Next'}
        </button>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
NextPrevBtn.propTypes = {
  isLoading: bool,
  onPrev: func,
  isLast: bool,
  isDisabledNext: bool,
  isDisabledPrev: bool,
};

// Specifies the default values for props
NextPrevBtn.defaultProps = {
  isLoading: false,
  onPrev: () => {},
  isLast: false,
  isDisabledNext: false,
  isDisabledPrev: false,
};

export default NextPrevBtn;
