/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_PCN,
  GET_LIST_OF_FEDERATION,
  GET_LIST_OF_SINGLE_PCN,
  CHANGE_SINGLE_MAP,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of pcn
 */
export function getPcn(id) {
  return async dispatch => {
    const params ={
    // getMyPcnListing,
    include: ["practices"]
    };
  
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
    const response = await dispatch(
      getPcnBegin(
      API_URLS.PCN.GET_SINGLE_PCN + id,
      params,
      source
      )
    );
    if (response.payload) {
      const { data } = response.payload;
      return data;
    }
  
    throw response;
    } catch (error) {
    throw error.response;
    }
  };
};
  
export const getPcnBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_PCN,
  payload: {
    request: {
    url: API_URL,
    method: 'get',
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    },
    cancelToken: source.token
    }
  }
});

/**
 *  Get List of federation
 */
export function getFederations() {
	return async dispatch => {
	  const params ={
		// getMyPcnListing,
		include: ["federations"]
	  };
	  const source = axios.CancelToken.source();
	  cancelApiRequests.push(source);
	  try {
		const response = await dispatch(
		  getFederationsBegin(
			API_URLS.PCN.GET_PCN,
			params,
			source
		  )
		);
		if (response.payload) {
		  const { data } = response.payload;
		  return data;
		}
	  
		throw response;
	  } catch (error) {
	  throw error.response;
	  }
	};
  };
	
  export const getFederationsBegin = (API_URL, params, source) => ({
	type: GET_LIST_OF_FEDERATION,
	payload: {
	  request: {
	  url: API_URL,
	  method: 'get',
	  params: params,
	  paramsSerializer: function(params) {
		return qs.stringify(params, { arrayFormat: 'brackets' })
	  },
	  cancelToken: source.token
	  }
	}
  });
  
/**
 *  Get List of Single pcn
 */
export function getSingleMyPcn(data) {
  return async dispatch => {
    const params ={
    include: ['practices'],
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
    const response = await dispatch(
      getSingleMyPcnBegin(
      API_URLS.PCN.GET_SINGLE_PCN + data.id,
      params,
      source,
      data
      )
    );
    if (response.payload) {
      const { data } = response.payload;
      return data;
    }
  
    throw response;
    } catch (error) {
    throw error.response;
    }
  };
};
  
export const getSingleMyPcnBegin = (API_URL, params, source, federation) => ({
  type: GET_LIST_OF_SINGLE_PCN,
  payload: {
    request: {
    url: API_URL,
    method: 'get',
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    },
    cancelToken: source.token
    }
  },
  federation
});
  
export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
    apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};
  
export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});

export const changeSingleMap = data => ({
  type: CHANGE_SINGLE_MAP,
  data
});

  