/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const appraisals = () => state => state.get('appraisals');

/**
 *  Select the correct portion of the root reducer
 */
export const getAppraisalsListing = () =>
  createSelector(appraisals(), state => {
    const listing = state.get('appraisals').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching Appraisals
 */
export const getIsFetchingAppraisals = () =>
  createSelector(appraisals(), state => {
    return state.get('isFetchingAppraisals')
  })

/**
 *  Select the correct portion of the root reducer
 */
export const getSupervisorsListing = () =>
	createSelector(appraisals(), state => {
	const listing = state.get('supervisors').toJS()
	return listing.length > 0 ? listing : []
	});

/**
*  Select is fetching Supervisors
*/
export const getIsFetchingSupervisors = () =>
	createSelector(appraisals(), state => {
	return state.get('isFetchingSupervisors')
  })

/**
 *  Select the correct portion of the root reducer
 */
export const getSingleAppraisalItem = () =>
	createSelector(appraisals(), state => {
	const listing = state.get('singleAppraisal') ? state.get('singleAppraisal').toJS() : null;
	return listing ? listing : {}
	});
  
/**
*  Select is fetching Single Appraisal
*/
export const getIsFetchingSingleAppraisal = () =>
	createSelector(appraisals(), state => {
	return state.get('isFetchingSingleAppraisal')
	})

/**
 *  Select total number of Appraisals pages
 */
export const getTotalPages = () =>
  createSelector(appraisals(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current Appraisals pages
 */
export const getAppraisalsPage = () =>
  createSelector(appraisals(), state => {
    return state.get('page')
  })
