export const GET_LIST_OF_NEWS =
  'src/modules/postNews/GET_LIST_OF_NEWS';
export const ADD_NEWS =
  'src/modules/postNews/ADD_NEWS';
export const UPDATE_NEWS =
  'src/modules/postNews/UPDATE_NEWS';
export const UPLOAD_IMAGE =
  'src/modules/postNews/UPLOAD_IMAGE';
export const DELETE_NEWS =
  'src/modules/postNews/DELETE_NEWS';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/postNews/CANCEL_ALL_API_REQUESTS';
