import React from "react";
import Modal from 'react-bootstrap/Modal';
import { func, bool, array } from "prop-types";

class TrainingRequirements extends React.Component {
  render() {
    const {
      isVisible,
      onClose,
      requirements,
      isProcessing
    } = this.props;
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header className="background-primary justify-content-center">
            <Modal.Title className="color-white">Training Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive ls-table">
                <table className="table text-center">
                    <thead>
                        <tr>
                        <th scope="col" className="pb-4" width="50">Course</th>
                        <th scope="col" className="pb-4" width="50"> Level </th>
                        <th scope="col" className="pb-4" width="50"> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {requirements && requirements.length > 0 && requirements.map((requirement, index) => {
                            const {
                              action,
                              level,
                              course
                            } = requirement;
                            return(
                            <tr key={index}>
                                <td className="py-4"> {course || '-'} </td>
                                <td className="py-4"> {level || '-'} </td>
                                <td className="py-4">{action || '-'} </td>
                            </tr>
                            )}
                        )}
                    </tbody>
                </table>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div className="row pb-3">
              <div className="col-md-12 text-center">    
                <button
                  disabled={isProcessing}
                  className="btn btn-secondary w-100"
                  onClick={() => {
                    if (isProcessing) return;
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
TrainingRequirements.propTypes = {
  isVisible: bool.isRequired,
  requirements: array.isRequired,
  onClose: func.isRequired,
};

export default TrainingRequirements;