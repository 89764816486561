import React from "react";
import { func, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import { getFormattedDate } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  getJobApplicants,
  updateJobApplicantStatus
} from '../../modules/jobs/actions';
import {
  getJobApplicantsListing,
  getIsFetchingJobApplicants,
} from '../../modules/jobs/selectors';

import Loader from 'components/Loader';

class JobApplicants extends React.Component {
  componentWillMount() {
    const { jobId } = this.props.match.params;
    if (jobId && !isNaN(jobId)) {
      var params = {
        job_opening_id:jobId,
      };
      this.props.getJobApplicants(params);
    }    
  }

  // deleteEducation = (value) => {
  //   this.props.deleteInvoiceList(value.id).then(res =>{
  //     this.props.getMyInvoiceList()
  //     showSuccessMsg('Invoice Delete successfully!');
  //   })
  // }

  updateApplicantStatus = (applicantId, status) =>{
    if(this.props.match.params.jobId){
        let params={
            job_opening_id: this.props.match.params.jobId,
            applicant_id: applicantId,
            status: status
        }
        this.props.updateJobApplicantStatus(params).then(res => {
            const { message } = res;
            var innerParams = {
                'job_opening_id': this.props.match.params.jobId,
            };
            this.props.getJobApplicants(innerParams);
            showSuccessMsg(message);
        });
    }
}

  render() {
    const {
      isFetchingJobApplicants,
      jobApplicants,
    } = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main class="m-0 px-3 px-md-5 mt-md-5 mt-4 mb-5">
            <div class="card border-0 page-title-bar">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <h4 class="family-poppins-semibold my-2">Job Applicants</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card border-0 lS-card-shadow lS-card-radius mt-md-5 mt-4" id="myTabContent">
                <div className="card-body" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                    <div className="table-responsive ls-table">
                      <table className="table text-center">
                          <thead>
                              <tr>
                                  <th scope="col" className="pb-4 text-left" width="100"> Image </th>
                                  <th scope="col" className="pb-4" width="160"> Name </th>
                                  <th scope="col" className="pb-4" width="300"> E-mail </th>
                                  {/* <th scope="col" className="pb-4"> Organizer </th> */}
                                  <th scope="col" className="pb-4" width='120'> Status </th>
                                  <th scope="col" className="pb-4" width="150"> Date booked </th>
                                  <th scope="col" className="pb-4" width="260"> Action </th>
                              </tr>
                          </thead>
                          <tbody>
                              {!isFetchingJobApplicants && jobApplicants && jobApplicants.length > 0 && jobApplicants.map((jobApplicant, index) => {
                                  const {profile_image_url, name, status, email, created_at, profile_identifier} = jobApplicant.attributes;
                                  return(
                                  <tr key={index}>
                          
                                      <td className="py-4 text-left"> 
                                          <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                                              <img src={profile_image_url} alt="..." className="img-object-fit" />
                                          </div>
                                      </td>
                                      <td className="py-4">{name} </td>
                                      <td className="py-4"> {email} </td>
                                      {/* <td className="py-4"> CLH </td> */}
                                      <td className="py-4 text-capitalize"> {status} </td>
                                      <td className="py-4"> {getFormattedDate(created_at)} </td>
                                      <td className="color-grey">
                                          <div className="d-flex justify-content-center align-items-center">
                                              <a rel="noopener noreferrer" 
                                                  className="btn btn-primary font-13 lS-btn-sm lS-btn-blue-style mr-2"
                                                  href= {process.env.REACT_APP_WEB_BASE_URL+"/public/passport/" + profile_identifier}
                                                  target="_blank">
                                                      View
                                              </a>
                                              <button
                                                  disabled={status === 'accepted' || status === 'cancelled' }
                                                  className="btn btn-primary font-13 lS-btn-sm lS-btn-green-style mr-2"
                                                  onClick={() => this.updateApplicantStatus(jobApplicant.id, 'accepted')}>
                                                  Accept
                                              </button>
                                              <button
                                                  disabled={status === 'rejected'  || status === 'cancelled'}
                                                  className="btn btn-primary font-13 lS-btn-sm lS-btn-red-style mr-2"
                                                  onClick={() => this.updateApplicantStatus(jobApplicant.id, 'rejected')}>
                                                  Reject
                                              </button>
                                              {/* <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-green-style rounded-lg">
                                                  <i className="fas fa-share-alt" />
                                              </button> */}
                                          </div>
                                      </td>
                                  </tr>
                                )
                                }
                              )}
                          </tbody>
                      </table>
                      {!isFetchingJobApplicants && jobApplicants && jobApplicants.length === 0 && (
                          <div className="text-center bg-white py-5 px-3 radius-10 family-poppins-medium">
                            No application found for this job
                        </div>
                      )}
                      {isFetchingJobApplicants && (
                          <Loader />
                      )}
                  </div>
              </div>
          </div>
      </main>
      <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
JobApplicants.propTypes = {
  jobApplicants: array.isRequired,
  isFetchingJobApplicants: bool.isRequired,
  getJobApplicants: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  jobApplicants: getJobApplicantsListing(),
  isFetchingJobApplicants: getIsFetchingJobApplicants(),
});

export default connect(
  mapStateToProps,
  {
    getJobApplicants,
    updateJobApplicantStatus,
  }
)(withRouter(JobApplicants));
