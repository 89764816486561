import React from "react";
import { func, object, bool } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';

/**
 *  Import other dependencies
 */
import { getSingleNews } from '../../modules/news/actions';
import { getSingleNewsDetail, getIsFetchingSingleNews } from '../../modules/news/selectors';
import { getFormattedDate } from "../../utils/helper";

class NewsDetail extends React.Component {
  componentWillMount() { 
    const { newsID } = this.props.match.params;

    if (newsID && !isNaN(newsID)) {
      this.props.getSingleNews(newsID);
    }
  }

  render() {
    const { singleNews, isFetchingSingleNews } = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-md-5 px-3 mt-md-5 mt-4 mb-5">
          {/* bread crum */}
          <div className="card page-title-bar border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold my-2">News</h4>
                </div>
              </div>
            </div>
          </div>
          {/* News Detail */}
          <div className="card lS-card-shadow lS-card-radius border-0 mt-md-5 mt-4">
            {!isFetchingSingleNews && singleNews && Object.keys(singleNews).length > 0 && (
              <div className="card-body">
              
                <h4 className="family-poppins-semibold">
                  {singleNews.title}
                </h4>
                <p className="color-primary font-15 text-uppercase mb-2">{singleNews.federation_name}</p>
                <p className="color-grey">
                  {singleNews.content}
                </p>
                <div className="d-flex align-items-center flex-wrap mb-2">
                  <i className="fas fa-calendar-alt color-primary mr-2" />
                  <span>{singleNews.created_at ? getFormattedDate(singleNews.created_at) : ``}</span>
                </div>
              </div>
            )}
            {!isFetchingSingleNews && singleNews && Object.keys(singleNews).length === 0 && (
              <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                No news found
              </div>
            )}
            {isFetchingSingleNews && (
              <Loader />
            )}
          </div>
        </main>
        <UserFooter />

        
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
NewsDetail.propTypes = {
  getSingleNews: func.isRequired,
  isFetchingSingleNews: bool.isRequired,
  singleNews: object.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  singleNews: getSingleNewsDetail(),
  isFetchingSingleNews: getIsFetchingSingleNews()
});

export default connect(
  mapStateToProps,
  {
    getSingleNews,
  }
)(withRouter(NewsDetail));
