import React from "react";
import { func, bool, number, object, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import * as Yup from 'yup';
import { Form, Field, ErrorMessage, Formik } from "formik";
import Select from 'react-select';
import { showSuccessMsg } from 'utils/notification';
import * as moment from 'moment';

import {getCategories, getPost, getMyPosts,addForumPost, updateForumPost} from '../../../modules/forums/actions';
import {getCategoriesList, getPostDetail, getIsFetchingPost, getMyPostsList, getIsFetchingMyPosts, getIsFetchingCategories} from '../../../modules/forums/selectors';
import { uploadImage,} from '../../../modules/policyDocPost/actions';
import { getIsUploadingImage } from '../../../modules/policyDocPost/selectors';

const addEditSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  description: Yup.string()
    .required('Required'),
  forumCategory:Yup.string()
    .required('Required'),
});

const formattedCategoryArray = array => {

  return array.map(item => {
    return {
      label: item.attributes.name,
      value: item.id
    };
  });
};

const customStyles = {
  control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelectCategory = props => {
  const { form, options, placeholder, field } = props;
  // if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue('forumCategory', value.value);
      }}
      value={field.value ? formattedCategoryArray(options.filter(option => Number(option.id) === Number(field.value))) : ''}
      styles={customStyles}
      options={formattedCategoryArray(options)}
    />
  )
};

class LSForumPost extends React.Component {
  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    this.props.getMyPosts();
    this.props.getCategories();

    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getPost(this.props.match.params.postId).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/ls-forums');
        }
      });
    }
  }
  
  getSingleCategory = (category, index, id) => (   
    <React.Fragment key={index}>      
      <li>
        <Link to={`/user/ls-forums/cat/${id}`}>
          {category.name}
          <span className="badge pull-right">
            {category.posts_count ? category.posts_count : 0 }
          </span>
        </Link>
      </li>
    </React.Fragment>
  );
  
  getFormikInitVal = () => {
    const { post } = this.props;
    const { mode } = this.state;
    
    if (mode === 'edit') {
      const {
        title,
        description,
        forum_category_id,
        attachment_url,
      } = post || {};

      return {
        title,
        description,
        forumCategory:forum_category_id,
        docURL: attachment_url,
      }
    }

    return {
      title: "",
      description: "",
      forumCategory: "",
    }
  };

  postJob = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    const { addForumPost,updateForumPost, history } = this.props;
    const { mode } = this.state;
    var params = {
      title: values.title,
      description: values.description,
      forum_category_id: values.forumCategory
    };

    if (values.docURL) {
      params.attachment_url = values.docURL;
    }

    if( mode === 'edit' ){
      updateForumPost(params, this.props.match.params.postId).then(res => {
        showSuccessMsg("Successfully updated");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/ls-forums');
        }
      }, err => {
        setSubmitting(false);
      });
    }

    if( mode === 'add' ){
      addForumPost(params).then(res => {
        showSuccessMsg("Success.");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/ls-forums');
        }
      }, err => {
        setSubmitting(false);
      });
    }
  };

   handleJobImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("file", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      console.log(url);
      setFieldValue('docURL', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
            <div className="fileUploadCol">
                {/* <label>Guideline</label> */}
            </div>
            <div className="position-relative fileUploadCol w-100">
                <span>1 File attached</span>
                <div className="selectImgCross" onClick={() => form.setFieldValue('docURL', "")}>
                <i className="fas fa-times"></i>
                </div>
            </div>
          {/* <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div> */}
        </>
      )
    }

    return (
      <>
        <input
          type="file"
          onChange={event => this.handleJobImage(event, form.setFieldValue)}
        />
        <span>Upload File</span>
      </>
    )
  };


  getSingleMyPost = (post, index, id) => (
    <React.Fragment key={index}>
      <div className="divline" />
      <div className="blocktxt">
      <Link to={`/user/ls-forums/post/${id}`}>
        {post.title}
      </Link>
      </div>
    </React.Fragment>
  );

  render() {
    const{ categories, myPosts, isUploadingImage, isFetchingCategories,isFetchingPost, isFetchingMyPosts } = this.props;
    const { mode } = this.state;
	  return (
      <>
        <UserHeader />
        {/* Page content */}
        <main><section className="content">
        <div className="container">
  <div className="row">
    <div className="col-lg-8 col-xs-12 col-md-8">
      <div className="headernav">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 search hidden-xs hidden-sm col-md-8">
              <div className="wrap">
                <form action="#" method="post" className="form">
                  <div className="pull-left txt"><input type="text" className="form-control" placeholder="Search Topics" /></div>
                  <div className="pull-right"><button className="btn btn-default" type="button"><i className="fa fa-search" /></button></div>
                  <div className="clearfix" />
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-xs-12 col-sm-5 col-md-4 avt">
              <div className="stnt pull-left">
                <button className="btn btn-primary">
                  <Link to="/user/ls-forums/question" style={{color: 'white'}}>Start New Topic</Link>
                </button>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />
    </div>
  </div>
</div>

<div className="container">
  <div className="row">
    <div className="col-lg-8 col-md-8">
      <div className="row">
          <div className="col-lg-12">
          <div className="row job-post">
            <div className="col-md-12">
              <div className="box">
                <div className="b-head">
                  <h3 className="b-heading">{mode === 'edit' ? 'Edit' : 'Create'} Topic</h3>
                </div>
                <div className="b-body">
                {isFetchingPost && (
                  <div className="text-center">  
                    <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {!isFetchingPost && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditSchema}
                  onSubmit={this.postJob}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor>Title</label>
                            <Field type="text" name="title" className="form-control" placeholder="Type Here..." />
                            <ErrorMessage name="title" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor>Category</label>
                            <Field as="select" name="forumCategory" placeholder="Select Category" component={formSelectCategory} options={categories} />
                            <ErrorMessage name="forumCategory" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor></label>
                          {!isUploadingImage && (
                            <div className="upload-file mt-2">
                              <Field type="file" component={this.fileUpload} name="docURL" className="form-control" />
                            </div>
                          )}
                          {isUploadingImage && (
                            <div className="text-center">
                              <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor>Description</label>
                            <Field as="textarea" name="description" rows={3} className="form-control" placeholder="Type Here..." />
                            <ErrorMessage name="description" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" disabled={isSubmitting || isUploadingImage} className="post-btn">
                            {
                              isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}
                            Post
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-4">
      {/* */}
      <div className="sidebarblock">
        <h3>Categories</h3>
        <div className="divline" />
        <div className="blocktxt">
          <ul className="cats">
            {categories && categories.length > 0 && categories.map((category, index) => this.getSingleCategory(category.attributes, index, category.id))}
          </ul>

          {!isFetchingCategories && categories && categories.length === 0 && (
            <p className="text-center">
              No Categories found
            </p>
          )}

          {isFetchingCategories && (
            <div className="text-center">
              <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* */}
      <div className="sidebarblock">
          <h3>Poll of the Week</h3>
          <div className="divline" />
          <div className="blocktxt">
            {/* <p className="text-center">Coming Soon</p> */}
            <p>What has been your struggle during COVID-19 pandemic?</p>
            <form action="#" method="post" className="form">
              <table className="poll">
                <tbody>
                  <tr>
                    <td>
                      <div className="progress">
                        <div className="progress-bar color1" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '90%'}}>
                          PPE
                        </div>
                      </div>
                    </td>
                    <td className="chbox">
                      <input id="opt1" type="radio" name="opt" defaultValue={1} />
                      <label htmlFor="opt1" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="progress">
                        <div className="progress-bar color2" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '63%'}}>
                          Remote Consulation
                        </div>
                      </div>
                    </td>
                    <td className="chbox">
                      <input id="opt2" type="radio" name="opt" defaultValue={2} defaultChecked />
                      <label htmlFor="opt2" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="progress">
                        <div className="progress-bar color3" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '75%'}}>
                          Health & Well Being
                        </div>
                      </div>
                    </td>
                    <td className="chbox">
                      <input id="opt3" type="radio" name="opt" defaultValue={3} />
                      <label htmlFor="opt3" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="progress">
                        <div className="progress-bar color1" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '90%'}}>
                          Isolation
                        </div>
                      </div>
                    </td>
                    <td className="chbox">
                      <input id="opt1" type="radio" name="opt" defaultValue={1} />
                      <label htmlFor="opt1" />
                    </td>
                  </tr>
                </tbody></table>
            </form>
            {/* <p className="smal">Voting ends on 30th of October</p> */}
          </div>
        </div>
      {/* */}
          <div className="sidebarblock">
            <h3>My Active Threads</h3>
            {myPosts && myPosts.length > 0 && myPosts.map((myPost, index) => this.getSingleMyPost(myPost.attributes, index, myPost.id))}
              
            {!isFetchingMyPosts && myPosts && myPosts.length === 0 && (
                <p className="text-center">
                  No Posts found
                </p>
              )}

              {isFetchingMyPosts && (
                <div className="text-center">
                  <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>


        </section>
        </main>
</>
    );
  }
}

/**
 *  Define component PropTypes
 */
LSForumPost.propTypes = {
  post: Object.isRequired,
  posts: array.isRequired,
  myPosts: array.isRequired,
  categories: array.isRequired,
  getCategories: func.isRequired,
  addForumPost:func.isRequired,
  uploadImage: func.isRequired,
  isUploadingImage: Boolean.isRequired,
  isFetchingCategories: Boolean.isRequired,
  isFetchingPost: Boolean.isRequired,
  isFetchingMyPosts: Boolean.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  post: getPostDetail(),
  isFetchingPost: getIsFetchingPost(),
  categories: getCategoriesList(),
  isUploadingImage: getIsUploadingImage(),
  isFetchingCategories: getIsFetchingCategories(),
  isFetchingMyPosts: getIsFetchingMyPosts(),

  myPosts: getMyPostsList(),

});

export default connect(
  mapStateToProps,
  {
    getPost,
    addForumPost,
    getCategories,
    getMyPosts,
    uploadImage,
    updateForumPost
  }
)(withRouter(LSForumPost));

