export const GET_LIST_OF_JOBS =
  'src/modules/postJobs/GET_LIST_OF_JOBS';
export const ADD_JOBS =
  'src/modules/postJobs/ADD_JOBS';
export const UPDATE_JOBS =
  'src/modules/postJobs/UPDATE_JOBS';
export const UPLOAD_IMAGE =
  'src/modules/postJobs/UPLOAD_IMAGE';
export const DELETE_JOBS =
  'src/modules/postJobs/DELETE_JOBS';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/postJobs/CANCEL_ALL_API_REQUESTS';
