/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const postNews = () => state => state.get('postNews');

/**
 *  Select the correct portion of the root reducer
 */
export const getNewsListing = () =>
  createSelector(postNews(), state => {
    const listing = state.get('news').toJS()
    return listing ? listing : {}
  });

/**
 *  Select is fetching jobs
 */
export const getIsFetchingNews = () =>
  createSelector(postNews(), state => {
    return state.get('isFetchingNews')
  })

/**
 *  Select is fetching jobs
 */
export const getIsDeletingNews = () =>
  createSelector(postNews(), state => {
    return state.get('isDeletingNews')
  })
