/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const reports = () => state => state.get('reports');

/**
 *  Select the correct portion of the root reducer
 */
export const getReports = () =>
  createSelector(reports(), state => {
    const listing = state.get('reports').toJS()
    return Object.keys(listing).length > 0 ? listing : {}
  });

/**
 *  Select is fetching Reports
 */
export const getIsFetchingReports = () =>
  createSelector(reports(), state => {
    return state.get('isFetchingReports')
  })
