/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_USERS,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_FILTERS,
  RESET_FILTERS,
  APPLY_PAGINATION,
  APPROVE_USERS,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 10,
  page: 1,
  totalPage: 0,
  users: [],
  search: '',
  isFetchingUsers: true,
  isApprovingUser: false,
});

/**
 *  Define the reducer with actions
 */
function adminUserListingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_USERS:
      return fromJS({
        ...state.toJS(),
        isFetchingUsers: true,
      })

    case success(GET_LIST_OF_USERS):
      const data = action.payload.data.data.map(user => {
        const { attributes, relationships} = user;
        const res = action.payload.data.included.find(
          include =>
            relationships.user_profile.data.id === include.id
        );
        user.attributes = {...attributes, ...res.attributes};
        return user;
      });
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        users: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingUsers: false,
      })

    case failure(GET_LIST_OF_USERS):
      return fromJS({
        ...state.toJS(),
        isFetchingUsers: false,
      })

    case APPROVE_USERS:
      return fromJS({
        ...state.toJS(),
        isApprovingUser: true,
      })

    case success(APPROVE_USERS):
      const { users } = state.toJS();
      users.map(user => {
        if(Number(user.id) === Number(action.meta.previousAction.userId)) {
          user.attributes.is_approved = true;
        }
        return user;
      })
      return fromJS({
        ...state.toJS(),
        users,
        isApprovingUser: false,
      })

    case failure(APPROVE_USERS):
      return fromJS({
        ...state.toJS(),
        isApprovingUser: false,
      })

    case SELECT_CHECKBOX:
      return selectCheckBox(action.arrayKeyName, action.value, state);

    case UNSELECT_CHECKBOX:
      return unselectCheckBox(action.arrayKeyName, action.value, state);

    case APPLY_FILTERS:
      return fromJS({
        ...state.toJS(),
        search: action.search,
        page: 1
      })

    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })

    case RESET_FILTERS:
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

const selectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  switch (arrayType) {
    case 'selectedTypes':
      modifyArr = state.get('selectedTypes').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedTypes: modifyArr,
      })
    case 'selectedLocations':
      modifyArr = state.get('selectedLocations').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedLocations: modifyArr,
      })
    case 'selectedOccupations':
      modifyArr = state.get('selectedOccupations').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedOccupations: modifyArr,
      })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

const unselectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  let index = 0;
  switch (arrayType) {
    case 'selectedTypes':
      modifyArr = state.get('selectedTypes').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedTypes: modifyArr,
      })
    case 'selectedLocations':
      modifyArr = state.get('selectedLocations').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedLocations: modifyArr,
      })
    case 'selectedOccupations':
      modifyArr = state.get('selectedOccupations').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedOccupations: modifyArr,
      })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

/**
 *  Export the reducer
 */
export default adminUserListingReducer
