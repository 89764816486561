export const GET_LIST_OF_NEWS =
  'src/modules/news/GET_LIST_OF_NEWS';
export const GET_SINGLE_NEWS =
  'src/modules/news/GET_SINGLE_NEWS';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/news/CANCEL_ALL_API_REQUESTS';
export const APPLY_PAGINATION =
  'src/modules/jobs/APPLY_PAGINATION';
export const PUBLISH_NEWS =
  'src/modules/postNews/PUBLISH_NEWS';
