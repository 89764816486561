/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_APPRAISALS,
  GET_SINGLE_APPRAISAL,
  GET_SUPERVISOR,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { act } from 'react-dom/test-utils';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 9,
  page: 1,
  totalPage: 0,
  appraisals: [],
  supervisors: [],
  singleAppraisal: null,
  isFetchingAppraisals: false,
  isFetchingSingleAppraisal: false,
  isFetchingSupervisors: false,
});

/**
 *  Define the reducer with actions
 */
function appraisalsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_APPRAISALS:
      return fromJS({
        ...state.toJS(),
        isFetchingAppraisals: true,
      })

    case success(GET_LIST_OF_APPRAISALS):
      const { data } = action.payload.data;
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        appraisals: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingAppraisals: false,
      })

    case failure(GET_LIST_OF_APPRAISALS):
      return fromJS({
        ...state.toJS(),
        isFetchingAppraisals: false,
      })
    
    case GET_SINGLE_APPRAISAL:
      return fromJS({
        ...state.toJS(),
        isFetchingSingleAppraisal: true,
      })

    case success(GET_SINGLE_APPRAISAL):
      return fromJS({
        ...state.toJS(),
        singleAppraisal: action.payload.data.data,
        isFetchingSingleAppraisal: false,
      })

    case failure(GET_SINGLE_APPRAISAL):
      return fromJS({
        ...state.toJS(),
        isFetchingSingleAppraisal: false,
      })

		case GET_SUPERVISOR:
			return fromJS({
				...state.toJS(),
				isFetchingSupervisors: true,
			})

		case success(GET_SUPERVISOR):
			const list = action.payload.data.data;
			return fromJS({
				...state.toJS(),
				supervisors: list && list.length > 0 ? list : [],
				isFetchingSupervisors: false,
			})

		case failure(GET_SUPERVISOR):
			return fromJS({
				...state.toJS(),
				isFetchingSupervisors: false,
			})
	  
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    case APPLY_PAGINATION:
        return fromJS({
          ...state.toJS(),
          page: action.page
        })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default appraisalsReducer
