export const GET_LIST_OF_TRAININGS =
  'src/modules/trainings/GET_LIST_OF_TRAININGS';
export const APPLY_FILTERS =
  'src/modules/trainings/APPLY_FILTERS';
export const RESET_FILTERS =
  'src/modules/trainings/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/trainings/APPLY_PAGINATION';
export const SELECT_CHECKBOX =
  'src/modules/trainings/SELECT_CHECKBOX';
export const UNSELECT_CHECKBOX =
  'src/modules/trainings/UNSELECT_CHECKBOX';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/trainings/CANCEL_ALL_API_REQUESTS';
export const APPLY_TRAININGS =
  'src/modules/trainings/APPLY_TRAININGS';
export const GET_LIST_OF_TRAININGS_APPLICANTS =
'src/modules/trainings/GET_LIST_OF_TRAININGS_APPLICANTS';
export const ADD_TRANINING_FEEDBACK =
'src/modules/trainings/ADD_TRANINING_FEEDBACK';
export const UPDATE_TRAINING_STATUS =
'src/modules/trainings/UPDATE_TRAINING_STATUS';
export const UPDATE_TRAINING_APPLIED_STATUS =
'src/modules/trainings/UPDATE_TRAINING_APPLIED_STATUS';
export const UPDATE_TRAINING_STATUS_FROM_CREATOR =
'src/modules/trainings/UPDATE_TRAINING_STATUS_FROM_CREATOR';
export const GET_LIST_OF_TRAININGS_APPLIED =
'src/modules/trainings/GET_LIST_OF_TRAININGS_APPLIED';

