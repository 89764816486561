import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import EditSingleResource from "components/editSingleResource";
import AssignAssorSuper from "components/assignAssorSuper";
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
/**
 *  Import other dependencies
 */
import { getSingleBooklets, getSupervisorSingleBooklet, getUsersListing } from '../../../modules/resources/actions';
import { getSingleBookLetsListing, getIsFetchingResources , getSingleBookLetDetails} from '../../../modules/resources/selectors';

const dateFormat = 'DD-MMM-YYYY';

class SingleResourcesIndex extends React.Component {

  state={
    isVisibleEditTask: false,
    isVisibleAssignAssor: false,
    selectedTask: null,
    bookLetIndexID: null,
    bookLetID: null,
    colors:[
      'red',
      'blue',
      'orange',
      'yellow',
      'green',
      'mehrun',
      'skyblue',
      'purpal',
    ],
  }

  componentWillMount() {
    const bookletId  = this.props.match.params;
    const indexId  = this.props.match.params;
    
    const userId = this.props.match.params;

    const params = {
      booklet_user_id: userId,
      examinerType: 'supervisor',
    };
    if (bookletId.bookletId && !isNaN(bookletId.bookletId)) {
      this.props.getSupervisorSingleBooklet(bookletId.bookletId, indexId.indexId, params);
      // this.props.getUsersListing();
    }
  }

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  handleVisibleEditTask = (task, bookletIndex) => {
    this.setState({
      isVisibleEditTask: true,
      selectedTask: task,
      bookLetIndexID: bookletIndex,
      bookLetID: this.props.match.params.bookLetID,
    });
  }

  handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      bookLetIndexID: bookletIndex,
      bookLetID: this.props.match.params.bookLetID,
    });
  }

  isAllTaskFilled = tasksArray => {
    for (let i = 0; i < tasksArray.length; i++) {
      if(!tasksArray[i].attributes.evidence) return true;
    }

    return false;
  };

  render() {
    const {
      book,
      singleBookLets,
      isFetchingBooklets
    } = this.props;
    const {
      isVisibleEditTask,
      isVisibleAssignAssor,
      selectedTask,
      bookLetIndexID,
      bookLetID,
    } = this.state;
    return (

      <>
        <UserHeader />
        {/* Page content */}
        <main>
          <div className="container-fluid">
          <div className="col-lg-8 offset-lg-2 col-md-12">
            <div className="row box breadcrumb">
              <div className="col-md-12">
                <ul>
                {!isFetchingBooklets && (
                  <li><a href="#">{book.booklet_name}</a> &gt; <span>Index</span></li>
                )}
                </ul>
              </div>
            </div>
            {!isFetchingBooklets && singleBookLets && singleBookLets.length > 0 && singleBookLets.map((book,bookLetIndex) => (
              <div className="row prog_l box">
                <div className="col-md-2" />
                <div className="col-md-8">
                  <div className="align-center">
                    <div className="circle_sec">
                      <a href="programme-list.html">{bookLetIndex+1}</a>
                    </div>
                    <div className="long_sec">
                    <Link to={`/user/supervisor/user-listing/83/booklet/${book.attributes.user_booklet_id}`+
                              `/index/`+book.id}>{book.attributes.resource_booklet_index_name}</Link>
                    </div>
                    {/* <div className="long_sec complete">
                <a href="programme-list.html"> Advance Clinical Practitioner</a> <i className="fas fa-check" />
              </div> */}
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            )
            )}
            {!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
              <div className="text-center w-100">No Single BookLets found</div>
            )}
            {isFetchingBooklets && (
              <div className="text-center">
                <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>

            {/* <div className="w-100 d-flex justify-content-center"> */}
              {/* {isFetchingBooklets && (
                <div className="text-center">
                  <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )} */}
            {/* </div> */}
       </div>
          {/* <div className="row resources"> */}
          {/* <div class="container-fluid"> */}

          {/* {!isFetchingBooklets && singleBookLets && singleBookLets.length > 0 && singleBookLets.map((book,bookLetIndex) => (

               <div class={`row prog_l ` + colors[Math.floor(Math.random() * colors.length)] + ` justify-content-center`}>
                 <div class="col-md-2 col-sm-1"></div>
                 <div class="col-md-9 col-sm-10">
                   <div class="align-center">
                   <div class="circle_sec">
                     <a href="programme-list.">{bookLetIndex+1}</a>
                   </div>
                   <div class="long_sec">

                      <a href={`/user/resources/`+book.attributes.user_booklet_id+
                              `/index/`+book.id}>{book.attributes.resource_booklet_index_name}</a>
                   </div>
                   </div>
                 </div>
                 <div class="col-md-1"></div>
               </div>
               )
              )} */}
            {/* </div> */}
            {/* {!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
              <div className="text-center w-100">No Single BookLets found</div>
            )} */}
            {/* <div className="w-100 d-flex justify-content-center">
              {isFetchingBooklets && (
                <div className="text-center">
                  <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div> */}
          {/* </div> */}
          {/* </div> */}
        </main>
        {isVisibleEditTask && (
          <EditSingleResource
            isVisible={isVisibleEditTask}
            onClose={() => {
              this.setState({
                isVisibleEditTask: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
            singleTask={selectedTask}
          />
        )}

        {isVisibleAssignAssor && (
          <AssignAssorSuper
            isVisible={isVisibleAssignAssor}
            onClose={() => {
              this.setState({
                isVisibleAssignAssor: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
SingleResourcesIndex.propTypes = {
  booklet: array.isRequired,
  singleBookLets: array.isRequired,
  getSupervisorSingleBooklet: func.isRequired,
  getUsersListing: func.isRequired,
  isFetchingBooklets: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  book:getSingleBookLetDetails(),
  singleBookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
    getSingleBooklets,
    getSupervisorSingleBooklet,
    getUsersListing,
  }
)(withRouter(SingleResourcesIndex));
