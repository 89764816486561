import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import EditSingleResource from "components/editSingleResource";
import AssignAssorSuper from "components/assignAssorSuper";
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
/**
 *  Import other dependencies
 */
import { getSingleBooklets, getSupervisorSingleBooklet, getUsersListing } from '../../../modules/resources/actions';
import { getSingleBookLetsListing, getIsFetchingResources , getSingleBookLetDetails} from '../../../modules/resources/selectors';

const dateFormat = 'DD-MMM-YYYY';

class SingleResourcesIndex extends React.Component {

  state={
    isVisibleEditTask: false,
    isVisibleAssignAssor: false,
    selectedTask: null,
    bookLetIndexID: null,
    bookLetID: null,
    colors:[
      'red',
      'blue',
      'orange',
      'yellow',
      'green',
      'mehrun',
      'skyblue',
      'purpal',
    ],
  }

  componentWillMount() {
    const bookletId  = this.props.match.params;
    const indexId  = this.props.match.params;
    
    const userId = this.props.match.params;

    const params = {
      booklet_user_id: userId,
      examinerType: 'assessor',
    };
    if (bookletId.bookletId && !isNaN(bookletId.bookletId)) {
      this.props.getSupervisorSingleBooklet(bookletId.bookletId, indexId.indexId, params);
      // this.props.getUsersListing();
    }
  }

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  handleVisibleEditTask = (task, bookletIndex) => {
    this.setState({
      isVisibleEditTask: true,
      selectedTask: task,
      bookLetIndexID: bookletIndex,
      bookLetID: this.props.match.params.bookLetID,
    });
  }

  handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      bookLetIndexID: bookletIndex,
      bookLetID: this.props.match.params.bookLetID,
    });
  }

  isAllTaskFilled = tasksArray => {
    for (let i = 0; i < tasksArray.length; i++) {
      if(!tasksArray[i].attributes.evidence) return true;
    }

    return false;
  };

  render() {
    const {
      book,
      singleBookLets,
      isFetchingBooklets
    } = this.props;
    const {
      isVisibleEditTask,
      isVisibleAssignAssor,
      selectedTask,
      bookLetIndexID,
      bookLetID,
    } = this.state;
    return (

      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5">
          <div className="container-fluid mt-5 p-0">
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-6">
                    <h4 className="family-poppins-semibold">
                      Competencies
                    </h4>
                    <p className="mb-lg-0 mb-sm-3 font-14 color-grey"></p>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <form action="#" method="get" id="search-form" className="input-group">
                      <div className="input-group search-bar">
                        <span className="fa fa-search fa-xs serach-icon" />
                        <input type="text" className="form-control search-input" placeholder="Search here..." />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* detail tiles */}
            <div className="container-fluid p-0">
              <div className="row mt-5 mx-lg-n4">
                {!isFetchingBooklets && singleBookLets && singleBookLets.length > 0 && singleBookLets.map((book,bookLetIndex) => (
                <div className="col-xl-4 col-lg-6 px-lg-4">
                  <div className="card detail-tile lS-card-radius border-0 lS-card-shadow mb-5">
                    <div className="card-body d-flex align-items-center">
                      <div className="tile-icon tile-icon-sm radius-10 d-inline-flex align-items-center justify-content-center">
                        <span className="family-bebas-nueue tile-count">{bookLetIndex+1}</span>
                      </div>
                      <div className="tile-text pl-4 py-3 min-width0">
                        <Link to={`/user/assessor/user-listing/83/booklet/${book.attributes.user_booklet_id}`+
                              `/index/`+book.id} className="link-hover-text">
                          <h4 className="font-weight-normal family-poppins-medium text-truncate" data-toggle="tooltip" 
                              title={book.attributes.resource_booklet_index_name}>
                                {book.attributes.resource_booklet_index_name}
                          </h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                )
                )}
                {!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
                  <div className="col-12 px-lg-4">
                    <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No Index found</div>
                  </div>
                )}
                {isFetchingBooklets && (
                  <div className="col-12 px-lg-4"><Loader /></div>
                )}         
               </div>
            </div>
          </div>
        </main>
        <UserFooter />
       
        {isVisibleEditTask && (
          <EditSingleResource
            isVisible={isVisibleEditTask}
            onClose={() => {
              this.setState({
                isVisibleEditTask: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
            singleTask={selectedTask}
          />
        )}

        {isVisibleAssignAssor && (
          <AssignAssorSuper
            isVisible={isVisibleAssignAssor}
            onClose={() => {
              this.setState({
                isVisibleAssignAssor: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
SingleResourcesIndex.propTypes = {
  booklet: array.isRequired,
  singleBookLets: array.isRequired,
  getSupervisorSingleBooklet: func.isRequired,
  getUsersListing: func.isRequired,
  isFetchingBooklets: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  book:getSingleBookLetDetails(),
  singleBookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
    getSingleBooklets,
    getSupervisorSingleBooklet,
    getUsersListing,
  }
)(withRouter(SingleResourcesIndex));
