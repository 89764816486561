/**
 *  Import action creator constants & dependencies
 */
import {
    GET_CATEGORIES,
    GET_POSTS,
    CREATE_POSTS,
    GET_POST,
    GET_MY_POSTS,
    UPDATE_POST,
    // GET_SINGLE_JOB,
    // GET_LIST_OF_JOB_OCCUPATIONS,
    APPLY_FILTERS,
    // SELECT_CHECKBOX,
    // UNSELECT_CHECKBOX,
    APPLY_PAGINATION,
    RESET_FILTERS,
    GET_POLLS,
    VOTE_A_POLL,
    CANCEL_ALL_API_REQUESTS,
    // APPLY_JOB,
    // GET_LIST_OF_JOB_APPLICANTS,
    // UPDATE_JOB_APPLICANT_STATUS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';

const cancelApiRequests = [];

/**
 *  Get Single Post
 */
export function getPost(postId) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPostBegin(
                    API_URLS.FORUMS.GET_POST + postId,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPostBegin = (API_URL, source) => ({
    type: GET_POST,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            cancelToken: source.token
        }
    }
});

/**
 *  Get List of posts
 */
export function getPosts(categoryId) {
    return async(dispatch, getState) => {
        const state = getState();
        const { limit, page, search } = state.get('forums').toJS();
        const source = axios.CancelToken.source();
        const params = {
            page,
            limit,
            filters: {}
        };

        // Apply when search has any value
        if (search) {
            params.filters.search = search;
        }

        if (categoryId) {
            params.filters.forum_category_id = categoryId;
        }

        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPostsBegin(
                    API_URLS.FORUMS.GET_POSTS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPostsBegin = (API_URL, params, source) => ({
    type: GET_POSTS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});


/**
 *  Get List of posts
 */
export function getMyPosts(categoryId) {
    return async(dispatch, getState) => {
        const state = getState();
        const { limit, page } = state.get('forums').toJS();
        const source = axios.CancelToken.source();
        const params = {
            page,
            limit,
            filters: {}
        };

        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getMyPostsBegin(
                    API_URLS.FORUMS.GET_MY_POSTS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getMyPostsBegin = (API_URL, params, source) => ({
    type: GET_MY_POSTS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Get List of categories
 */
export function getCategories() {
    return async(dispatch, getState) => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getCategoriesBegin(
                    API_URLS.FORUMS.GET_CATEGORIES,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getCategoriesBegin = (API_URL, source) => ({
    type: GET_CATEGORIES,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Get List of categories
 */
export function getPolls() {
    return async(dispatch) => {
        const params = {
            include: ['options']
        };
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPollsBegin(
                    API_URLS.POLLING.GET_LIST_POLLING,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPollsBegin = (API_URL, params, source) => ({
    type: GET_POLLS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Add vote to poll
 */
export function addVoteToPoll(params, pollId, index, optionIndex) {
    return async dispatch => {
        var URL = API_URLS.POLLING.VOTE_A_POLL_PREFIX + pollId + API_URLS.POLLING.VOTE_A_POLL_POSTFIX;
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                addVoteToPollBegin(
                    URL,
                    params,
                    index,
                    optionIndex,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const addVoteToPollBegin = (API_URL, params, index, optionIndex, source) => ({
    type: VOTE_A_POLL,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            cancelToken: source.token
        }
    },
    index,
    optionIndex,
});

/**
 *  Add Forum Post
 */
export function addForumPost(params) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                addForumPostBegin(
                    API_URLS.FORUMS.CREATE_POSTS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const addForumPostBegin = (API_URL, params, source) => ({
    type: CREATE_POSTS,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            cancelToken: source.token
        }
    }
});

/**
 *  update post
 */
export function updateForumPost(params, id) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                updateForumPostBegin(
                    API_URLS.FORUMS.UPDATE_POST + id,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const updateForumPostBegin = (API_URL, params, source) => ({
    type: UPDATE_POST,
    payload: {
        request: {
            url: API_URL,
            method: 'PUT',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            cancelToken: source.token
        }
    }
});


/**
 *  Apply pagination
 */
export function applyPagination(page) {
    return async dispatch => {
        dispatch(applyPaginationBegin(page));
        dispatch(getPosts());
    };
};

export const applyPaginationBegin = page => ({
    type: APPLY_PAGINATION,
    page
});

/**
 *  Apply filters
 */
export function applyFilters(search) {
    return async dispatch => {
        dispatch(applyFiltersBegin(search));
        dispatch(getPosts());
    };
};

export const applyFiltersBegin = (search) => ({
    type: APPLY_FILTERS,
    search
});

/**
 *  Reset filters
 */
export function resetFilters() {
    return async dispatch => {
        dispatch(resetFiltersBegin());
        dispatch(getPosts());
    };
};

export const resetFiltersBegin = () => ({
    type: RESET_FILTERS
});