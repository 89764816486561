import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import EditSingleResource from "components/editSingleResource";
import AssignAssorSuper from "components/assignAssorSuper";
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import UserFooter from 'components/Footers/UserFooter';
/**
 *  Import other dependencies
 */
import { getSingleBooklets, getUsersListing } from '../../modules/resources/actions';
import { getSingleBookLetsListing, getIsFetchingResources , getSingleBookLetDetails} from '../../modules/resources/selectors';
import Loader from 'components/Loader';

const dateFormat = 'DD-MMM-YYYY';

class SingleResourcesIndex extends React.Component {

  state={
    isVisibleEditTask: false,
    isVisibleAssignAssor: false,
    selectedTask: null,
    bookLetIndexID: null,
    bookLetID: null,
    colors:[
      'red',
      'blue',
      'orange',
      'yellow',
      'green',
      'mehrun',
      'skyblue',
      'purpal',
    ],
  }

  componentWillMount() {
    const bookLetID  = this.props.match.params;
    if (bookLetID.bookLetID && !isNaN(bookLetID.bookLetID)) {
      this.props.getSingleBooklets(bookLetID.bookLetID);
      this.props.getUsersListing();
    }
  }

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  handleVisibleEditTask = (task, bookletIndex) => {
    this.setState({
      isVisibleEditTask: true,
      selectedTask: task,
      bookLetIndexID: bookletIndex,
      bookLetID: this.props.match.params.bookLetID,
    });
  }

  handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      bookLetIndexID: bookletIndex,
      bookLetID: this.props.match.params.bookLetID,
    });
  }

  isAllTaskFilled = tasksArray => {
    for (let i = 0; i < tasksArray.length; i++) {
      if(!tasksArray[i].attributes.evidence) return true;
    }

    return false;
  };

  render() {
    const {
      book,
      singleBookLets,
      isFetchingBooklets
    } = this.props;
    const {
      isVisibleEditTask,
      isVisibleAssignAssor,
      selectedTask,
      bookLetIndexID,
      bookLetID,
    } = this.state;
    return (

      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 mb-5">
          <div className="container-fluid mt-md-5 mt-4 p-0">
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-6">
                    <h4 className="family-poppins-semibold m-0">Competencies</h4>
                  </div>
                  <div className="ls-filters-bar col-lg-4 col-md-6 mt-3 mt-md-0">
                    <form className="input-group ls-search-field" id="search-form">
                      <div className="input-group-prepend">
                        <span className="input-group-text bg-transparent pr-0 font-12 color-grey">
                          <i className="fas fa-search" />
                        </span>
                      </div>
                      <input type="text" className="form-control border-left-0" placeholder="Search here..." />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* detail tiles */}
            <div className="container-fluid p-0">
              <div className="row mt-md-5 mt-4 mx-lg-n4">
                {!isFetchingBooklets && singleBookLets && singleBookLets.length > 0 && singleBookLets.map((book,bookLetIndex) => (
                <div className="col-xl-4 col-lg-6 px-lg-4">
                  <div className="card detail-tile lS-card-radius border-0 lS-card-shadow mb-md-5 mb-4">
                    <div className="card-body d-flex align-items-center">
                      <div className="tile-icon tile-icon-sm radius-10 d-inline-flex align-items-center justify-content-center">
                        <span className="family-bebas-nueue tile-count">{bookLetIndex+1}</span>
                      </div>
                      <div className="tile-text pl-4 py-3 min-width0">
                        <Link to={`/user/resources/`+book.attributes.user_booklet_id+
                                `/index/`+book.id} className="link-hover-text">
                          <h4 className="font-weight-normal family-poppins-medium text-truncate" data-toggle="tooltip" 
                              title={book.attributes.resource_booklet_index_name}>
                                {book.attributes.resource_booklet_index_name}
                          </h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                )
                )}
                {!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
                  <div className="col-12 px-lg-4">
                    <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No Index found</div>
                  </div>
                )}
                {isFetchingBooklets && (
                  <div className="col-12 px-lg-4"><Loader /></div>
                )}         
               </div>
            </div>
          </div>
        </main>
        <UserFooter />
       
        {isVisibleEditTask && (
          <EditSingleResource
            isVisible={isVisibleEditTask}
            onClose={() => {
              this.setState({
                isVisibleEditTask: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
            singleTask={selectedTask}
          />
        )}

        {isVisibleAssignAssor && (
          <AssignAssorSuper
            isVisible={isVisibleAssignAssor}
            onClose={() => {
              this.setState({
                isVisibleAssignAssor: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
SingleResourcesIndex.propTypes = {
  booklet: array.isRequired,
  singleBookLets: array.isRequired,
  getSingleBooklets: func.isRequired,
  getUsersListing: func.isRequired,
  isFetchingBooklets: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  book:getSingleBookLetDetails(),
  singleBookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
    getSingleBooklets,
    getUsersListing,
  }
)(withRouter(SingleResourcesIndex));
