/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_PLACEMENTS,
  GET_SINGLE_PLACEMENT,
  GET_LIST_OF_PLACEMENT_OCCUPATIONS,
  GET_LIST_OF_PLACEMENTS_APPLICANTS,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_FILTERS,
  RESET_FILTERS,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
  MENTOR_PLACEMENTS,
  GET_LIST_OF_PLACEMENTS_MENTORS,
  LIKE_DISLIKE_PLACEMENT,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 6,
  page: 1,
  totalPage: 0,
  placements: [],
  placementOccupations: [],
  placementApplicants: [],
  placementMentors: [],
  search: '',
  range: 1000,
  duration: [],
  region: [],
  type: [],
  group: [],
  selectedSearch:[],
  isFetchingPlacements: true,
  isFetchingSinglePlacement: true,
  isFetchingPlacementApplicants: true,
  isFetchingPlacementMentors: true,
  singlePlacement: null,
  occupationType: []
});

/**
 *  Define the reducer with actions
 */
function placementsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_PLACEMENTS:
      return fromJS({
        ...state.toJS(),
        isFetchingPlacements: true,
      })

    case success(GET_LIST_OF_PLACEMENTS):
      const { data } = action.payload.data;
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        placements: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingPlacements: false,
      })

    case failure(GET_LIST_OF_PLACEMENTS):
      return fromJS({
        ...state.toJS(),
        isFetchingPlacements: false,
      })

    case GET_LIST_OF_PLACEMENTS_APPLICANTS:
      return fromJS({
        ...state.toJS(),
        isFetchingPlacementApplicants: true,
      })

    case success(GET_LIST_OF_PLACEMENTS_APPLICANTS):
      const  placementApplicantsData  = action.payload.data.data;
      const  placement_applicants_total_pages  = action.payload.data.meta.pagination;      
      return fromJS({
        ...state.toJS(),
        placementApplicants: placementApplicantsData && placementApplicantsData.length > 0 ? placementApplicantsData : [],
        totalPage: placement_applicants_total_pages,
        isFetchingPlacementApplicants: false,
      })

    case failure(GET_LIST_OF_PLACEMENTS_APPLICANTS):
      return fromJS({
        ...state.toJS(),
        isFetchingPlacementApplicants: false,
      })
    
    case GET_LIST_OF_PLACEMENTS_MENTORS:
      return fromJS({
        ...state.toJS(),
        isFetchingPlacementMentors: true,
      })

    case success(GET_LIST_OF_PLACEMENTS_MENTORS):
      const  placementMentorsData  = action.payload.data.data;
      const  placement_mentors_total_pages  = action.payload.data.meta.pagination;      
      return fromJS({
        ...state.toJS(),
        placementMentors: placementMentorsData && placementMentorsData.length > 0 ? placementMentorsData : [],
        totalPage: placement_mentors_total_pages,
        isFetchingPlacementMentors: false,
      })

    case failure(GET_LIST_OF_PLACEMENTS_MENTORS):
      return fromJS({
        ...state.toJS(),
        isFetchingPlacementMentors: false,
      })

    case GET_SINGLE_PLACEMENT:
      return fromJS({
        ...state.toJS(),
        singlePlacement: null,
        isFetchingSinglePlacement: true,
      })

    case success(GET_SINGLE_PLACEMENT):
      return fromJS({
        ...state.toJS(),
        singlePlacement: action.payload.data && action.payload.data.data ? action.payload.data.data.attributes : null,
        isFetchingSinglePlacement: false,
      })

    case failure(GET_SINGLE_PLACEMENT):
      return fromJS({
        ...state.toJS(),
        isFetchingSinglePlacement: false,
      })
    
    case success(GET_LIST_OF_PLACEMENT_OCCUPATIONS):
      return fromJS({
        ...state.toJS(),
        placementOccupations: action.payload.data.data.type,
      })

    case SELECT_CHECKBOX:
      return selectCheckBox(action.arrayKeyName, action.value, state);

    case UNSELECT_CHECKBOX:
      return unselectCheckBox(action.arrayKeyName, action.value, state);
    
    case LIKE_DISLIKE_PLACEMENT:
      const { id, params } = action;
      const { placements } = state.toJS();
      const tempIndex = placements.findIndex(placement => placement.id === id);
      if(params.likes_dislike_value === 1) {
        placements[tempIndex].attributes.likes = placements[tempIndex].attributes.likes + 1;
        if(placements[tempIndex].attributes.like_dislikes === -1) {
          placements[tempIndex].attributes.dislikes = placements[tempIndex].attributes.dislikes - 1;
        }
      }
      if(params.likes_dislike_value === -1) {
        placements[tempIndex].attributes.dislikes = placements[tempIndex].attributes.dislikes + 1;
        if(placements[tempIndex].attributes.like_dislikes === 1) {
          placements[tempIndex].attributes.likes = placements[tempIndex].attributes.likes - 1;
        }
      }
      // Minus
      if(params.likes_dislike_value === 0) {
        if(placements[tempIndex].attributes.like_dislikes === 1) {
          placements[tempIndex].attributes.likes = placements[tempIndex].attributes.likes - 1;
        }
        
        if(placements[tempIndex].attributes.like_dislikes === -1) {
          placements[tempIndex].attributes.dislikes = placements[tempIndex].attributes.dislikes - 1;
        }
      }
      placements[tempIndex].attributes.like_dislikes = params.likes_dislike_value;
      return fromJS({
        ...state.toJS(),
        placements,
      })

    case APPLY_FILTERS:
      return fromJS({
        ...state.toJS(),
        search: action.params.search,
        duration: filterOnlyValues(action.params.duration),
        region: filterOnlyValues(action.params.location),
        type: filterOnlyValues(action.params.category),
        group: filterOnlyValues(action.params.group),
        occupationType: filterOnlyValues(action.params.occupations),
        
        page: 1
      })

    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })

    case RESET_FILTERS:
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

const filterOnlyValues = arr => {
  return arr.map(item => item.value);
};

const selectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  switch (arrayType) {
    case 'occupationType':
      modifyArr = state.get('occupationType').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        occupationType: modifyArr,
      })
    case 'region':
      modifyArr = state.get('region').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        region: modifyArr,
      })
    case 'duration':
      modifyArr = state.get('duration').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        duration: modifyArr,
      })
    case 'type':
        modifyArr = state.get('type').toJS();
        modifyArr.push(value);
        return fromJS({
          ...state.toJS(),
          type: modifyArr,
        })  
    case 'group':
          modifyArr = state.get('group').toJS();
          modifyArr.push(value);
          return fromJS({
            ...state.toJS(),
            group: modifyArr,
          })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

const unselectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  let index = 0;
  switch (arrayType) {
    case 'occupationType':
      modifyArr = state.get('occupationType').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        occupationType: modifyArr,
      })
    case 'region':
      modifyArr = state.get('region').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        region: modifyArr,
      })
    case 'duration':
      modifyArr = state.get('duration').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        duration: modifyArr,
      })
    case 'group':
        modifyArr = state.get('group').toJS();
        index = modifyArr.indexOf(value);
        modifyArr.splice(index, 1);
        return fromJS({
          ...state.toJS(),
          group: modifyArr,
        })
    case 'type':
          modifyArr = state.get('type').toJS();
          index = modifyArr.indexOf(value);
          modifyArr.splice(index, 1);
          return fromJS({
            ...state.toJS(),
            type: modifyArr,
          })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

/**
 *  Export the reducer
 */
export default placementsReducer
