import React, { Profiler } from "react";
import { func, bool, number, object, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import Swiper from 'react-id-swiper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**
 *  Import other dependencies
 */
import Comments from 'components/Comments'
import { getUpdatePersonalInfo } from '../../modules/updatePersonalInfo/actions';
import {
  getIsFetchingPractice,
  getPracticeListing,
} from '../../modules/updatePersonalInfo/selectors';
import Loader from 'components/Loader';

const ambassadorSliderParams = {
  slidesPerView: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.swiper-nav-next',
    prevEl: '.swiper-nav-prev'
  },
  breakpoints: {
    992: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    576: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  }
}

class NetworkDetailsComponent extends React.Component {
  state = {
    ambassadorSwiper: null,
  }

  componentWillMount() {
    // Get all maps on page load
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getUpdatePersonalInfo(this.props.match.params.id);
    }
  }

  getSingleAmbassador = (ambassadorObj, index, id) => (
    <div>
      <div className="position-relative pb-5 mb-4" key={index}>
        <div className="thumb-wrap thumb-wrap-full radius-10 overflow-hidden mx-auto">
          <img src={ambassadorObj.attributes.image_url} className="img-fluid" alt="Ambassador" />
        </div>
        <div className="card lS-card-radius lS-card-shadow border-0 img-overlayed-text mx-3">
          <div className="card-body text-center position-relative">
            <div className="text-animate">
              <h5 className="family-poppins-semibold mb-2">{ambassadorObj.attributes.name}</h5>
              <p className="color-grey mb-0">{ambassadorObj.type}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  updateSlide = btnType => {
    const { swiper } = this.state;
    if (swiper !== null) {
      if (btnType === 'prev') {
        swiper.slidePrev();
        return;
      }

      swiper.slideNext();
    }
  };

  eventsUpdateSlide = btnType => {
    const { ambassadorSwiper } = this.state;
    if (ambassadorSwiper !== null) {
      if (btnType === 'prev') {
        ambassadorSwiper.slidePrev();
        return;
      }

      ambassadorSwiper.slideNext();
    }
  };

  getSelectedFederations = (data) => {
	const { isFetchingNetworks } = this.props;
	if (isFetchingNetworks) return;

	// Get selected Federation data
	this.props.getSingleMyPcn(data)
  };

  changeMap = mapObj => {
	if (!mapObj) return;
	this.props.changeSingleMap(mapObj);
  };

  render() {
  const { practice, isFetchingPractice } = this.props;
  let { address, name, details, city, post_code, website_url, profile_image_url } = practice.attributes || {};
    return (
      <>
        <UserHeader />
        {/* Page content */}

        {!isFetchingPractice && (
          <main className="m-0">
            <div className="container-fluid pt-5 px-3 px-md-5">

              <div className="card border-0 page-title-bar">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <h4 className="family-poppins-semibold my-2">Practice</h4>
                      {/* <p className="mb-0 font-14 color-grey"></p> */}
                    </div>
                  </div>
                </div>
              </div>     

              <div className="row pt-5 mb-5 mx-lg-n4">
                <div className="col-lg-8 px-xl-4 custom-flex-xl">
                  <div className="ls-height-100 d-flex flex-column">
                    <h4 className="family-poppins-semibold mb-4">Practice Information</h4>
                    <div className="card border-0 lS-card-shadow lS-card-radius detail-card flex-fill">
                      <div className="card-body d-flex flex-column flex-md-row">
                        <div>
                          <div className="thumb-wrap network-thumb thumb-wrap-xl ls-height-100 radius-10 overflow-hidden mb-4 mb-lg-0">
                            <img src={profile_image_url ? profile_image_url : `https://prod-linkstaff-uploads.s3.eu-west-2.amazonaws.com/building.png`} alt="..." />
                          </div>
                        </div>
                        <div className="min-width0 flex-fill pl-md-4">
                          <div>
                            <h4 className="mt-0 mb-2 font-weight-normal color-black-heading family-poppins-medium"> {name} </h4>
                          </div>
                          <p className="mb-3 font-18 color-primary">{details && details.email ? details.email : '--'}</p>
                          
                          <div className="font-14 mt-3">
                            <div className="mb-4 text-nowrap">
                              <i className="fas fa-phone color-primary mr-2"></i>
                              <span>{details && details.phone ? details.phone : '--'}</span>
                            </div>
                            <div className="mb-4 text-nowrap">
                              <i className="fas fa-fax color-primary mr-2"></i>
                              <span>{details && details.fax ? details.fax : '--'}</span>
                            </div>
                            <div className="mb-4 text-nowrap">
                              <i className="fas fa-globe color-primary mr-2"></i>
                              <span>{website_url ? website_url : '--'}</span>
                            </div>
                            <div>
                              <i className="fas fa-globe-americas color-primary mr-2"></i>
                              <span>{city}, {post_code}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 px-xl-4 custom-flex-sm">
                  <div className="ls-height-100 d-flex flex-column">
                    <h4 className="family-poppins-semibold mb-4 mt-5 mt-lg-0">Timings:</h4>
                    <div className="card lS-card-shadow border-0 lS-card-radius flex-fill">
                      <div className="card-body">
                        {!details && (
                          <p className="d-flex align-items-center justify-content-center ls-height-100 color-grey w-100"> No Timings Found. </p>
                        )}
                        {details && (
                          <>
                            <div className="d-flex timing-chart mb-4">
                              <label className="m-0 color-grey">Monday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium">
                                {details && details.day_schedule && details.day_schedule[0] ? details.day_schedule[0].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[0] ? details.day_schedule[0].end_time : '--'}
                              </p>
                            </div>
                            <div className="d-flex timing-chart mb-4">
                              <label className="m-0 color-grey">Tuesday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium">
                                {details && details.day_schedule && details.day_schedule[1] ? details.day_schedule[1].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[1] ? details.day_schedule[1].end_time : '--'}
                              </p>
                            </div>
                            <div className="d-flex timing-chart mb-4">
                              <label className="m-0 color-grey">Wednesday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium">
                                {details && details.day_schedule && details.day_schedule[2] ? details.day_schedule[2].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[2] ? details.day_schedule[2].end_time : '--'}
                              </p>
                            </div>
                            <div className="d-flex timing-chart mb-4">
                              <label className="m-0 color-grey">Thursday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium">
                                {details && details.day_schedule && details.day_schedule[3] ? details.day_schedule[3].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[3] ? details.day_schedule[3].end_time : '--'}
                              </p>
                            </div>
                            <div className="d-flex timing-chart mb-4">
                              <label className="m-0 color-grey">Friday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium">
                                {details && details.day_schedule && details.day_schedule[4] ? details.day_schedule[4].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[4] ? details.day_schedule[4].end_time : '--'}
                              </p>
                            </div>
                            <div className="d-flex timing-chart mb-4">
                              <label className="m-0 color-grey">Saturday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium">
                                {details && details.day_schedule && details.day_schedule[5] ? details.day_schedule[5].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[5] ? details.day_schedule[5].end_time : '--'}
                              </p>
                            </div>
                            <div className="d-flex timing-chart font-16">
                              <label className="m-0 color-red">Sunday: &nbsp;</label>
                              <p className="m-0 family-poppins-medium color-red">
                                {details && details.day_schedule && details.day_schedule[6] ? details.day_schedule[6].start_time : '--'} -
                                {details && details.day_schedule && details.day_schedule[6] ? details.day_schedule[6].end_time : '--'}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row mx-lg-n4">
                <div className="col-xl-8 px-xl-4 custom-flex-xl">
                  <Comments id={this.props.match.params.id} type="PRACTICES" />
                </div>

                <div className="col-lg-4 px-xl-4 custom-flex-sm">
                  <div className="card lS-card-shadow lS-card-radius border-0 mb-5">
                    <div className="card-body">
                      <div className="d-flex flex-fill justify-content-between mb-4">
                        <h4 className="family-poppins-semibold m-0">
                          Ambassadors
                        </h4>

                        <div className="news-nav">
                          <button className="btn p-0 rounded-circle border-0 swiper-nav-prev mr-2 mr-md-3" onClick={() => {this.eventsUpdateSlide('prev')}}>
                            <i className="fas fa-chevron-left" />
                          </button>
                          <button className="btn p-0 rounded-circle border-0 swiper-nav-next" onClick={() => {this.eventsUpdateSlide('next')}}>
                            <i className="fas fa-chevron-right" />
                          </button>
                        </div>
                      </div>
                      {practice.ambassadors && practice.ambassadors.length > 0 && (
                        <Swiper getSwiper={(swiper) => this.setState({ ambassadorSwiper: swiper })} {...ambassadorSliderParams}>
                          {practice.ambassadors.map((eventObj, index) => this.getSingleAmbassador(eventObj, index, eventObj.id))}
                        </Swiper>
                      )}

                      {practice.ambassadors && practice.ambassadors.length === 0 && (
                        <div className="col-md-12 py-4">
                          <p className="text-muted text-center"> No Ambassadors Found </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      
      {isFetchingPractice && (
        <Loader />
      )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
NetworkDetailsComponent.propTypes = {
  practice: object.isRequired,
  isFetchingPractice: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  practice: getPracticeListing(),
  isFetchingPractice: getIsFetchingPractice(),
  // selectedFederation: getSelectedFederation(),
});

export default connect(
  mapStateToProps,
  {
    getUpdatePersonalInfo
  }
)(withRouter(NetworkDetailsComponent));
