import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import { func, bool, number, array } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
import { jobTitles, bands } from './jobFilters';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import {
	getSingleBooklets,
} from '../../modules/resources/actions';
import {
	getEmployments,
	addPracticeEmployment,
} from '../../modules/userProfile/actions';
import {
  getIsPractice,
} from '../../modules/userProfile/selectors';
// import { jobTitle } from "../../views/userProfile/jobFilters";
var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const formattedArray = array => {
  return array.map(item => {
    return {
      label: `${item}`,
      value: `${item}`
    };
  });
};

const practiceFormattedArray = array => {
  return array.map(item => {
    return {
      id: `${item.id}`,
      label: `${item.name}`,
      value: `${item.name}`
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0 mb-2"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if(field.name === 'jobTitle'){
          form.setFieldValue('jobTitle', value.value)
        }
        else{
          form.setFieldValue('band', value.value)
        }
      }}
      value={field.value ? {label: field.value, value: field.value} : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

const practiceFormSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0 mb-2"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if(field.name === 'practiceId'){
          form.setFieldValue('practiceId', value.value)
        }
      }}
      value={field.value ? {id: field.id, label: field.value, value: field.value} : ''}
      styles={customStyles}
      options={practiceFormattedArray(options)}
    />
  )
};

const AddEmploymentSchema = Yup.object().shape({
	jobTitle: Yup.string()
    .required('Required'),
	band: Yup.string()
		.required('Required'),
	practiceId: Yup.string()
		.required('Required'),
	start_date: Yup.string()
		.required('Required'),
	//end_date: Yup.string()
	//	.required('Required'),
});

class AddEmployment extends React.Component {
	getFormikInitVal = () => {
    return {
	    job_title: "",
      band: "",
      practice_id: "",
      start_date:"",
      end_date: "",
    }
  };

  getUserID = value => {
    const { users } = this.props;
    const user = users.filter(user => `${user.name}` == value)
    if (user && user.length > 0) {
      return user[0].id;
    }

    return "";
  };

	// addPracticeEmployment = (values, { setSubmitting, resetForm }) => {
	addPracticeEmployment = (values, { setSubmitting, resetForm }) => {
    const { addPracticeEmployment, getEmployments } = this.props;
		const params = {
      		practice_id: Number(this.getUserID(values.practiceId)),
			band: Number(values.band),
			job_title: values.jobTitle,
			start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : null,
			end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : null,
    };
		addPracticeEmployment(params).then(res => {
			const { message } = res;
			getEmployments();
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};

	render() {
		const {
			isVisible,
      onClose,
      users,
  } = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white">Add Employment</Modal.Title>
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={AddEmploymentSchema}
						onSubmit={this.addPracticeEmployment}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form pt-2">
								<Modal.Body>
									<div className="form-group">
										<label className="color-grey pl-3">Job Title</label>
										<Field as="select" name="jobTitle" placeholder="Search Job Title" component={formSelect} options={jobTitles} />
										<ErrorMessage name="jobTitle" component="div" className="error-msg pl-3 mt-2" />
									</div>
									<div className="form-group">
										<label className="color-grey pl-3">Band</label>
										<Field as="select" name="band" placeholder="Band" component={formSelect} options={bands} />
										<ErrorMessage name="band" component="div" className="error-msg pl-3 mt-2" />
									</div>
									<div className="form-group">
										<label className="color-grey pl-3">Practice Name</label>
										<Field as="select" name="practiceId" placeholder="Search Practice Name" component={practiceFormSelect} options={users} />
										<ErrorMessage name="practiceId" component="div" className="error-msg pl-3 mt-2" />
									</div>
									<div className="form-group">
										<label className="color-grey pl-3">Start Date</label>
										<Datetime
	                                      inputProps={{placeholder: 'Select Date'}}
	                                      renderInput={ this.renderCustomInput }
	                                      closeOnSelect={true}
	                                    //  isValidDate={ valid }
	                                      onChange={start_date => {
	                                        if (typeof start_date !== "object") return;
	                                        setFieldValue('start_date', start_date)
	                                      }}
	                                      value={values['start_date']}
	                                    />
	                                    <ErrorMessage name="start_date" component="div" className="error-msg pl-3 mt-2" />
									</div>
									<div className="form-group">
										<label className="color-grey pl-3">End Date</label>
										<Datetime
	                                      inputProps={{placeholder: 'Select Date'}}
	                                      renderInput={ this.renderCustomInput }
	                                      closeOnSelect={true}
	                                     // isValidDate={ valid }
	                                      onChange={end_date => {
	                                        if (typeof end_date !== "object") return;
	                                        setFieldValue('end_date', end_date)
	                                      }}
	                                      value={values['end_date']}
	                                    />
	                                    <ErrorMessage name="end_date" component="div" className="error-msg pl-3 mt-2" />
									</div>
								</Modal.Body>
								<Modal.Footer className="border-0">
                                    <div className="row pb-3 w-100">
                                        <div className="col-md-6">
                                            <button
                                                disabled={isSubmitting}
                                                className="btn btn-secondary w-100"
                                                onClick={() => {
                                                    onClose();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary lS-btn-blue-style w-100">
                                                {
                                                    isSubmitting && (
                                                        <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                                                )}
                                                Save
                                            </button>
                                        </div>
                                    </div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
AddEmployment.propTypes = {
	getSingleBooklets: func.isRequired,
	getEmployments: func.isRequired,
	addPracticeEmployment: func.isRequired,
	isVisible: bool.isRequired,
  onClose: func.isRequired,
  users: array.isRequired
};

const mapStateToProps = createStructuredSelector({
  users: getIsPractice(),
});

export default connect(
  mapStateToProps,
  {
    getSingleBooklets,
		addPracticeEmployment,
    getEmployments
  }
)(withRouter(AddEmployment));
