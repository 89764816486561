import React from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ImageCroppingModal from 'components/imageCroppingModal';
import ReactPaginate from 'react-paginate';
import { func, bool, array, number } from "prop-types";
import { getLocalStorage, getToken, getChatToken, setLocalStorage } from '../../utils/helper';
import { showSuccessMsg } from '../../utils/notification';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import EditProfile from 'components/editProfile';
import Loader from 'components/Loader';
import PollingView from 'components/PollingView';
import * as moment from 'moment';
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

/**
 *  Import other dependencies
 */
import { 
  // updateProfile, 
  getListPolling,
  applyPaginationPolling,
  deletePolling,
} from '../../modules/polling/actions';
import { 
  // getIsUpdatingProfile, 
  getTotalPages, 
  getIsPollingList,
  getIsFetchingPollingList 
} from '../../modules/polling/selectors';
// import { getEducationInstitute } from '../../modules/auth/actions';
// import { getEducationInstituteList } from '../../modules/auth/selectors';
import ShareProfile from "../../components/shareProfile";
import AddEmployment from "../../components/addEmployment";
import AddEducation from "../../components/addEducation";
import CovidProfile from "components/covidProfile";
import AddEditPolling from "../../components/AddEditPolling";


const dateFormat = 'DD-MMM-YYYY';

class PollingPage extends React.Component {
  state = {
    userProfile: JSON.parse(getLocalStorage()),
    tempSelectedFile: null,
    imageType: '',
    isVisibleCropperPopup: false,
    isVisibleCovidProfilePopup: false,
    isVisibleEditProfile: false,
    isVisibleShareProfile: false,
    isVisibleAddEmployment: false,
    isVisibleAddPolling: false,
    imagePopup: '',
    mode: '',
    pollValue:'',
    isImagePopupOpen: false,
    tempSelectedPoll: null,
    isVisibleViewPoll: true,
  };

  componentWillMount() {
    // this.props.getEducationInstitute();
    this.props.getListPolling();
    // this.props.getEmployments();
    // this.props.getEducation();
    // this.props.getInstitutionName();
  };

  handleProfileImage = event => {
    this.setState({
      tempSelectedFile: event.target.files[0],
      imageType: 'profile',
      isVisibleCropperPopup: true
    });
  };

  handleSignatureImage = event => {
    this.setState({
      tempSelectedFile: event.target.files[0],
      imageType: 'signature',
      isVisibleCropperPopup: true
    });
  };

  imageCroppingModalClose() {
    this.setState({
      isVisibleCropperPopup: false,
    });
  };
  
  onCompleteImageCrop = res => {
    const { data, included } = res;
    const includeProfile = 
      included.find(
        include =>
          data.relationships.user_profile.data.id === include.id
      );
    let combineObj = {...data.attributes, ...includeProfile.attributes};
    combineObj.token = getToken();
    combineObj.chat_token = getChatToken();
    setLocalStorage(combineObj);
    this.setState({
      userProfile: combineObj
    });
    showSuccessMsg('Profile updated successfully');
  };

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  openEditProfile() {
    this.setState({
      isVisibleEditProfile: true,
    });
  };
  openShareModal(){
    this.setState({
      isVisibleShareProfile: true,
    });
  }
  openAddEmployment(){
    this.setState({
      isVisibleAddEmployment: true,
    });
  }
  openAddPolling(){
    this.setState({
      mode: 'Add',
      isVisibleAddPolling: true,
    });
  }
  openCovidProfile(){
    this.setState({
      isVisibleCovidProfilePopup: true,
    });
  }

  viewPolling(value){
    this.setState({
      tempSelectedPoll: value,
      isVisibleViewPoll: true,
    });
  }

  editPolling(value){
    let fetchOptions= [];

    value.option.map(item => {
      let obj = {
        option: item.attributes.option
      };
      return fetchOptions.push(obj)
      // fetchOptions.push(item.attributes.option)
    })

    let data = {
      attributes: value.attributes,
      id: value.id,
      option: fetchOptions
    }

    this.setState({
      mode: 'Edit',
      pollValue: data,
      isVisibleAddPolling: true,
    });
  }

  deletePolling(value){
    this.props.deletePolling(value.id).then(res =>{
      this.props.getListPolling()
      showSuccessMsg('Polling Delete successfully!');
    })
  }

  loadPage = page => {
    this.props.applyPaginationPolling(page);
  }

  render() {
    const { 
      userProfile, 
      isVisibleCropperPopup,
      isVisibleEditProfile,
      isVisibleShareProfile,
      isVisibleAddEmployment,
      isVisibleAddPolling,
      isVisibleCovidProfilePopup,
      tempSelectedFile,
      imageType,
      isUpdatingProfile,
      imagePopup,
      isImagePopupOpen,
    } = this.state;
    const { 
      updateProfile,
      instituteList,
      isPolling,
      isInstitutionName,
      isEmploymentList, 
      isEducationList, 
      isFetchingPollingList,
      isFetchingEducation,
      totalPages,
      page
    } = this.props;
    return (
      <>
        <UserHeader userProfile={userProfile}/>
        {/* Page content */}
        <main className="m-0 px-3 px-md-5">
         
          {/* Education Table Added Here */}
          <div className="card mt-md-5 mt-4 mb-5 shadow-sm border-0 rounded">
            <div className="card-body">
              <div className="d-flex align-items-sm-center flex-column flex-sm-row align-items-start justify-content-between">
                <h4 className="family-poppins-semibold"> Poll </h4>
                <button type="button" className="btn btn-primary lS-btn-blue-large" onClick={() => this.openAddPolling()}> Add Poll </button>
              </div>
              {/* Table For Education */}
              <div className="row mt-4">
                <div className="col-xl-12 table-responsive">
                  <table className="table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col" className="text-left" width="100"> Questions </th>
                        <th scope="col" className="text-right" width="80"> Actions </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isFetchingPollingList && isPolling && isPolling.length > 0 && isPolling.map((polling, index) => {
                        const {question} = polling.attributes
                        return(
                          <tr key={index}>
                            <td className="color-grey text-left align-middle">{question}</td>
                            {/* <td className="color-grey text-center align-middle">{this.getFormattedDate(completion_date)}</td> */}
                            <td className="color-grey align-middle">
                              <div className="d-flex justify-content-end align-items-center">
                                <button className="btn btn-primary lS-btn-icon-sm font-12 lS-btn-blue-style rounded-lg mr-2" onClick={() => this.viewPolling(polling)}>
                                  <i className="fas fa-eye" />
                                </button>
                                <button className="btn btn-primary lS-btn-icon-sm font-12 lS-btn-green-style rounded-lg mr-2" onClick={() => this.editPolling(polling)}>
                                  <i className="fas fa-edit" />
                                </button>
                                <button className="btn btn-primary lS-btn-icon-sm font-12 lS-btn-red-style rounded-lg mr-2" onClick={() => this.deletePolling(polling)}>
                                  <i className="fas fa-trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!isFetchingPollingList && isPolling && isPolling.length === 0 && (
                    <div className="text-center font-12">No Polling found</div>
                  )}
                  {isFetchingPollingList && (
                    <Loader />
                  )}
                </div>

                <div aria-label="Page navigation example" className="ls-pagination mr-4 w-100">
                  <ReactPaginate
                    containerClassName={'pagination justify-content-end mt-5'}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    // forcePage={page - 1}
                    onPageChange={selectedItem => {
                      if (isFetchingPollingList) return;
                      this.loadPage(selectedItem.selected + 1);                      
                    }}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <UserFooter />

        {/* Add Education Popup */}
        {isVisibleAddPolling && (
          <AddEditPolling
            isVisible={isVisibleAddPolling}
            userProfile={userProfile}
            updateProfile={updateProfile}
            users={isInstitutionName}
            mode={this.state.mode}
            polling= {this.state.pollValue}
            onClose={() => {
              if (isUpdatingProfile) return;
              this.setState({
                isVisibleAddPolling: false,
              });
            }}
            instituteList={instituteList}
          />
        )}

        {this.state.isVisibleViewPoll && this.state.tempSelectedPoll && (
          <PollingView
            isVisible={this.state.isVisibleViewPoll}
            onClose={() => {
              this.setState({
                isVisibleViewPoll: false,
              });
            }}
            singlePoll={this.state.tempSelectedPoll}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PollingPage.propTypes = {
	getListPolling: func.isRequired,
	isPolling: array.isRequired,
  isFetchingPollingList: bool.isRequired,
  totalPages: number.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  // isUpdatingProfile: getIsUpdatingProfile(),
  isPolling: getIsPollingList(),
  isFetchingPollingList: getIsFetchingPollingList(),
  totalPages: getTotalPages(),
});

export default connect(
  mapStateToProps,
  {
    deletePolling,
    getListPolling,
    applyPaginationPolling,
  }
)(PollingPage);
