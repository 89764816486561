/**
 *  Import action creator constants & dependencies
 */
import {
  GET_REFLECTIVE_DISCUSSION,
  POST_REFLECTIVE_DISCUSSION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];


/**
   *  Get List of placements
   */
  export function getReflectiveDiscussion() {
    return async (dispatch) => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          getReflectiveDiscussionBegin(
            API_URLS.REVALIDATION.GET_REFLECTIVE_DISCUSSION,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const getReflectiveDiscussionBegin = (API_URL, source) => ({
    type: GET_REFLECTIVE_DISCUSSION,
    payload: {
      request: {
        url: API_URL,
        method: 'get',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

  /**
   *  Add reflective discussion
   */
  export function addReflectiveDiscussion(params) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          addReflectiveDiscussionBegin(
            API_URLS.REVALIDATION.POST_REFLECTIVE_DISCUSSION,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const addReflectiveDiscussionBegin = (API_URL, params, source) => ({
    type: POST_REFLECTIVE_DISCUSSION,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


