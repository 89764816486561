import React from "react";
import { bool, string, number, func, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";
import Loader from 'components/Loader';
import { getLocalStorage, getFormattedDateForComments, getFormattedTime } from '../../utils/helper';

/**
 *  Import other dependencies
 */
import {
  getComments,
  postComment,
  applyPagination,
  cancelAllApiRequests,
} from '../../modules/comments/actions';

import {
  getCommentsListing,
  getIsFetchingComments,
  getIsPostingComments,
  getTotalPage,
  getPage,
  getTotalComments
} from '../../modules/comments/selectors';

class Comments extends React.Component {
  state = {
    userProfile: JSON.parse(getLocalStorage()),
    userComment: '',
  }

  componentWillMount() {
    const { type, id, getComments } = this.props;
    if (type && id) {
      getComments(type, id);
    }
  }

  componentWillUnmount() {
    const { cancelAllApiRequests } = this.props;
    cancelAllApiRequests();
  }

  onChangeComment = (value) => {
    this.setState({
      userComment: value,
    });
  };

  postComment = () => {
    const { postComment, type, id } = this.props;
    const { userComment } = this.state;
    postComment(type, id, userComment).then(() => {
      this.setState({
        userComment: '',
      });
    });
  };

  loadMore = () => {
    const { applyPagination, type, id } = this.props;
    applyPagination(type, id);
  };

  render() {
    const { comments, isPostingComments, isFetchingComments, totalPage, page, totalComments } = this.props;
    const { userProfile, userComment } = this.state;
    return (
      <>
        <div className="card lS-card-shadow lS-card-radius border-0 mb-5">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6 className="font-17 font-weight-normal family-poppins-medium m-0">Comments</h6>
              {/* <span className="color-grey font-14">{totalComments} Comments</span> */}
            </div>
            {isFetchingComments && comments && comments.length === 0 && (
              <Loader />
            )}
            {!isFetchingComments && comments && comments.length === 0 && (
              <div className="text-muted text-center py-4"> 
                No record found
              </div>
            )}

            <div>
              {comments && comments.length > 0 && comments.map((comment, index) => (
                <div className="single-comment d-flex mb-4" key={index}>
                  <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                    <img src={comment.attributes.profile_image_url} alt="user" />
                  </div>
                  <div className="flex-fill px-3">
                    <div className="border-bottom ls-border-color pb-3">
                      <p className="family-poppins-medium font-14 mb-1">{comment.attributes.user_name}</p>
                      <span className="color-grey font-12 d-block">{getFormattedDateForComments(comment.attributes.comment_time)} <span className="mx-2">|</span> {getFormattedTime(comment.attributes.comment_time)}</span>
                      <p className="mt-2 font-14">{comment.attributes.message}</p>
                    </div>
                  </div>
                </div>
              ))}
              {!isFetchingComments && totalPage && page && page < totalPage && (
                <div className="w-100 text-center mb-3">
                  <button
                    type="submit"
                    disabled={isFetchingComments}
                    onClick={() => this.loadMore()}
                    className="btn btn-sm rounded-pill font-12 btn-load-more px-3"
                  >
                    Load More
                  </button>
                </div>
              )}
              {isFetchingComments && (
                <div className="w-100 text-center mb-3">
                  <button
                    type="submit"
                    disabled={isFetchingComments}
                    className="btn btn-sm rounded-pill font-12 btn-load-more px-3"
                  >
                    Load More
                    { 
                      isFetchingComments && (
                        <span className="spinner-border spinner-border-sm mx-2"></span>
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className="comment-box d-flex mb-3 mt-3">
              <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                <img src={userProfile.profile_image_url} alt="user profile pic" />
              </div>
              <div className="pl-3 flex-fill position-relative">
                <input
                  type="text"
                  className="font-12 px-4 form-control border ls-border-color"
                  value={userComment}
                  onChange={(commentInput) => this.onChangeComment(commentInput.target.value)}
                  placeholder="Write your reply here..." />
              </div>
              <button
                type="submit"
                disabled={isPostingComments || userComment.length === 0}
                onClick={() => this.postComment()}
                className="btn btn-primary lS-btn-blue-style rounded-circle p-0 lS-btn-icon-lg ml-3 d-flex align-items-center justify-content-center"
              >
                <i className="fas fa-paper-plane font-20" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Comments.propTypes = {
  comments: array.isRequired,
  id: string.isRequired,
  isFetchingComments: bool.isRequired,
  isPostingComments: bool.isRequired,
  getComments: func.isRequired,
  page: number.isRequired,
  postComment: func.isRequired,
  cancelAllApiRequests: func.isRequired,
  type: string.isRequired,
  totalComments: number.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  comments: getCommentsListing(),
  isFetchingComments: getIsFetchingComments(),
  isPostingComments: getIsPostingComments(),
  totalPage: getTotalPage(),
  page: getPage(),
  totalComments: getTotalComments(),
});

export default connect(
  mapStateToProps,
  {
    getComments,
    postComment,
    applyPagination,
    cancelAllApiRequests,
  }
)(Comments);
