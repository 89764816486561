import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import { func, bool, object, number } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
// import { selfAssessment, evidence } from './taskFilters';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import {
	addPracticeLog,
	updatePracticeLog,
	getPracticeLog,
} from '../../modules/revalidation/practiceLog/actions';

const addEditSchema = Yup.object().shape({
	feedbackDate: Yup.string()
	  .required('Required'),
  	orgNameAddr: Yup.string()
	  .required('Required'),
  	workSetting: Yup.string()
	  .required('Required'),
  	practiceScope: Yup.string()
	  .required('Required'),
  	hrs: Yup.string()
	  .required('Required'),
  	registrationType: Yup.string()
	  .required('Required'),
	workDescription: Yup.string()
	  .required('Required'),
});

// const formSelect = props => {
// 	const { form, options, placeholder, field } = props;
// 	if (options.length === 0) return;
// 	return (
// 	  <Select
// 		components={{ IndicatorSeparator:() => null }}
// 		className="form-control p-0 mb-2"
// 		isSearchable={true}
// 		placeholder={placeholder}
// 		onChange={value => {
// 		  if(field.name === 'self_assessment'){
// 			form.setFieldValue('self_assessment', value.value)
// 		  }
// 		  else{
// 			form.setFieldValue('evidence', value.value)
// 		  }
// 		}}
// 		value={field.value ? {label: field.value, value: field.value} : ''}
// 		styles={customStyles}
// 		options={formattedArray(options)}
// 	  />
// 	)
//   };

// const formattedArray = array => {
// 	return array.map(item => {
// 	  return {
// 		label: `${item}`,
// 		value: `${item}`
// 	  };
// 	});
//   };

// const customStyles = {
// 	control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
// 	placeholder: () => ({
// 		color: 'inherit',
// 	}),
// 	singleValue: () => ({
// 		color: 'inherit',
// 	}),
// };

class EditPracticeLog extends React.Component {
	getFormikInitVal = () => {
		const { singleLog } = this.props;
		const {
			user_id,
			log_date,
			org_name_addr,
			work_setting,
			practice_scope,
			hrs,
			registration_type,
			work_description,
		} = singleLog || {};
    return {
      feedbackDate: log_date ? log_date : "",
      orgNameAddr: org_name_addr ? org_name_addr : "",
	  workSetting: work_setting ? work_setting : "",
	  practiceScope: practice_scope ? practice_scope : "",
	  hrs: hrs ? hrs : "",
	  registrationType: registration_type ? registration_type : "",
	  workDescription: work_description ? work_description : "",
    }
};

	// handleFileUpload = (event, setFieldValue) => {
    // const { uploadImage, isUploadingImage } = this.props;
    // if (isUploadingImage) return;
    // const file = event.target.files[0];
    // var formdata = new FormData();
    // formdata.append("file", file);
    // uploadImage(formdata).then(res => {
    //   const { url } = res.data;
    //   setFieldValue('evidenceAttachment', url);
    // });
	// };

	updateSingleTask = (values, { setSubmitting, resetForm }) => {
		const { logId, addPracticeLog, singleLog, getPracticeLog, updatePracticeLog } = this.props;

		const params = {
			hrs: values.hrs,
			work_description: values.workDescription,
			registration_type: values.registrationType,
			practice_scope: values.practiceScope,
			work_setting: values.workSetting,
			org_name_addr: values.orgNameAddr,
			log_date: values.feedbackDate ? moment(values.feedbackDate).format("YYYY-MM-DD HH:mm:ss") : null,
		};
		
		if(logId === 0) {
			addPracticeLog(params).then(res => {
				const { message } = res;
				getPracticeLog();
				showSuccessMsg(message);
				setSubmitting(false);
				this.props.onClose();
			}, err => {
				setSubmitting(false);
			});
			return ;
		}

		updatePracticeLog(params, logId).then(res => {
			const { message } = res;
			getPracticeLog();
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};

	// fileUpload = props => {
    // const { form, field } = props;
    // if (field.value) {
    //   return (
    //     <>
    //       <div className="fileUploadCol">
    //         <label>Attachment</label>
    //       </div>
    //       <div className="position-relative fileUploadCol w-100">
    //         <span>1 Attachment attached</span>
    //         <div className="selectImgCross" onClick={() => form.setFieldValue('evidenceAttachment', "")}>
    //           <i className="fas fa-times"></i>
    //         </div>
    //       </div>
    //     </>
    //   )
    // }

//     return (
//       <>
// 		<div className="fileUploadCol position-relative">
// 			<label>Attachment</label>
// 			<input
// 				type="file"
// 				onChange={event => this.handleFileUpload(event, form.setFieldValue)}
// 			/>
// 			<span>Upload File</span>
// 		</div>
//       </>
//     )
//   };

	render() {
		const {
			isVisible,
			isUploadingImage,
			onClose,
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center py-4">
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={addEditSchema}
						onSubmit={this.updateSingleTask}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form px-md-4 px-3 py-4">
								<Modal.Body>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Feedback Date</label>
											<Datetime
											inputProps={{placeholder: 'Select Date'}}
											renderInput={ this.renderCustomInput }
											closeOnSelect={true}
											//  isValidDate={ valid }
											onChange={feedbackDate => {
												if (typeof feedbackDate !== "object") return;
												setFieldValue('feedbackDate', feedbackDate)
											}}
											value={values['feedbackDate']}
											/>
											<ErrorMessage name="feedbackDate" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Name and address of organisation</label>
											<Field component="textarea" rows="2" name="orgNameAddr" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="orgNameAddr" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Your work setting</label>
											<Field component="textarea" rows="2" name="workSetting" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="workSetting" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Your scope of practice</label>
											<Field component="textarea" rows="2" name="practiceScope" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="practiceScope" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Number of hours</label>
											<Field type="text" name="hrs" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="hrs" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Your registration</label>
											<Field type="text" name="registrationType" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="registrationType" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group mb-4">
											<label className="color-grey pl-3">Brief description of your work</label>
											<Field component="textarea" rows="2" name="workDescription" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="workDescription" component="div" className="error-msg pl-3 mt-2" />
										</div>
								</Modal.Body>
								<Modal.Footer className="border-0">
									<div className="row pb-3 w-100">
										<div className="col-md-6">
											<button
												disabled={isSubmitting || isUploadingImage}
												className="btn btn-secondary w-100 mb-3 py-3"
												onClick={() => {
													onClose();
												}}
											>
												Cancel
											</button>
										</div>
										<div className="col-md-6">
											<button type="submit" disabled={isSubmitting || isUploadingImage} className="btn btn-primary lS-btn-blue-style w-100 py-3">
												{
													isSubmitting && (
														<span className="spinner-border align-middle spinner-border-sm mr-2"></span>
												)}
												Save
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
EditPracticeLog.propTypes = {
	logId: number.isRequired,
	// bookLetIndexID: number.isRequired,
	// getSingleBooklets: func.isRequired,
	isVisible: bool.isRequired,
	// isUploadingImage: bool.isRequired,
	onClose: func.isRequired,
	singleLog: object.isRequired,
};

const mapStateToProps = createStructuredSelector({
//   isUploadingImage: getIsUploadingImage(),
});

export default connect(
  mapStateToProps,
  {
		// uploadImage,
		updatePracticeLog,
		getPracticeLog,
		addPracticeLog,
  }
)(withRouter(EditPracticeLog));
