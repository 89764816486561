import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import EditSingleResource from "components/editSingleResource";
import UpdatedIndexInterview from "components/updateIndexInterview";
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import ReadMoreAndLess from 'react-read-more-less';
import Select from 'react-select';
import EditSupervisorResource from "components/editSupervisorResource";

/**
 *  Import other dependencies
 */
import { getSupervisorSingleBooklet, getSingleBooklets, getUsersListing } from '../../../modules/resources/actions';
import { getSingleBookLetsListing, getIsFetchingResources } from '../../../modules/resources/selectors';

const dateFormat = 'DD-MMM-YYYY';
const formSelect = props => {
	const { form, options, placeholder, field } = props;
	if (options.length === 0) return;
	return (
	  <Select
		components={{ IndicatorSeparator:() => null }}
		className="form-control p-0 mb-2"
		isSearchable={true}
		placeholder={placeholder}
		onChange={value => {
		  if(field.name === 'self_assessment'){
			form.setFieldValue('self_assessment', value.value)
		  }
		  else{
			form.setFieldValue('evidence', value.value)
		  }
		}}
		value={field.value ? {label: field.value, value: field.value} : ''}
		styles={customStyles}
		options={formattedArray(options)}
	  />
	)
	};

const formattedArray = array => {
	return array.map(item => {
		return {
		label: `${item}`,
		value: `${item}`
		};
	});
	};

const customStyles = {
	control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
	placeholder: () => ({
		color: 'inherit',
	}),
	singleValue: () => ({
		color: 'inherit',
	}),
};

class SingleResources extends React.Component {
	getFormikInitVal = () => {
		// const { singleTask } = this.props;
		// const {
		// 	evidence,
		// 	reflection,
		// 	evidence_attachment_url,
		// 	self_assessment
		// } = singleTask.attributes || {};
    return {
      self_assessment: "Novice",
    }
	};

  state={
    isVisibleEditTask: false,
    isVisibleAssignAssor: false,
    selectedTask: null,
    bookLetIndexID: null,
    bookletId: null,
	indexID: null,
	assessment: null,
  }

  componentWillMount() {
    const bookletId  = this.props.match.params;
	const indexId  = this.props.match.params;
	const userId  = this.props.match.params;
	const params = {
		booklet_user_id: userId,
		examinerType: 'supervisor',
	  };
	console.log(indexId.indexId);
    if (bookletId.bookletId && !isNaN(bookletId.bookletId)) {
      this.props.getSupervisorSingleBooklet(bookletId.bookletId, indexId.indexId, params);
      this.props.getUsersListing();
    }
  }

  updateSingleTask = (values, { setSubmitting, resetForm }) => {
	// const { bookLetID, bookLetIndexID, singleTask, updateSingleTask, getSingleBooklets } = this.props;
	// const params = {
	// 	booklet_id: bookLetID,
	// 	booklet_index_id: bookLetIndexID,
	// 	resource_task_id: Number(singleTask.id),
	// 	evidence: values.evidence,
	// 	evidence_url: values.evidenceAttachment,
	// 	reflection: values.reflection,
	// 	self_assessment: values.self_assessment
	// };
	// updateSingleTask(params).then(res => {
	// 	const { message } = res;
	// 	getSingleBooklets(bookLetID, bookLetIndexID);
	// 	showSuccessMsg(message);
	// 	setSubmitting(false);
	// 	this.props.onClose();
	// }, err => {
	// 	setSubmitting(false);
	// });
};

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      bookLetIndexID: bookletIndex,
      bookletId: this.props.match.params.bookletId,
    });
  }

  isAllTaskFilled = tasksArray => {
    for (let i = 0; i < tasksArray.length; i++) {
      if(!tasksArray[i].attributes.evidence) return true;
    }

    return false;
  };

  handleVisibleEditTask = (bookletId, bookletIndexId, assessment) => {
    this.setState({
      isVisibleEditTask: true,
    //   selectedTask: task,
		bookletIndexId: bookletIndexId,
		bookletId: bookletId,
		assessment: assessment,
	});
  }

  render() {
    const {
      singleBookLets,
      isFetchingBooklets
    } = this.props;
    const {
      isVisibleEditTask,
      isVisibleAssignAssor,
      selectedTask,
	  bookLetIndexID,
	  bookletIndexId,
      bookletId,
	  indexID,
	  assessment,
    } = this.state;
    // const decoratedOnClick = useAccordionToggle(eventKey, onClick);
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="cpd_log feedback_log">
					<div className="container-fluid">
						<div className="row">
							{!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
								<div className="col-lg-12 col-md-12">
									<div className="box">
										<div className="text-center w-100">No BookLet found</div>
									</div>
								</div>
							)}
							{isFetchingBooklets && (
								<div className="col-lg-12 col-md-12">
									<div className="box">
										<div className="text-center">
											<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
											<span className="sr-only">Loading...</span>
											</div>
										</div>
									</div>
								</div>
							)}
						{!isFetchingBooklets && singleBookLets && singleBookLets.length > 0 && singleBookLets.map((book,bookLetIndex) => (
							<div className="col-lg-12 col-md-12">
								<div className="box">
									<div className="row first_row">
										<div className="col-md-10">
											<h3 className="b-heading">{book.attributes.resource_booklet_index_name}</h3>
										</div>
										<div className="col-md-2">
											<img src={require("assets/images/clh-125.png")} alt="FEDERATIONLOGO" />
										</div>
										</div>
									<div className="row second_row">
										<div className="col-md-12"></div>
										<div className="col-md-4">
											<h4>The Initial Interview</h4>

											<div className="form-group">
												<label className="form-label">{book.attributes.self_assessment_initial || `--` }</label>
											</div>
										</div>
										<div className="col-md-4">
											<h4>The Mid-Point interview</h4>
											<div className="form-group">
												<label className="form-label">{book.attributes.self_assessment_mid || `--` }</label>
											</div>
										</div><div className="col-md-4">
											<h4>The End Point Interview </h4>
											<div className="form-group">
												<label className="form-label">{book.attributes.self_assessment_end || `--` }</label>
											</div>
										</div>
										<div className="new_row">
										{/* <button
												disabled={book.attributes.is_submitted || this.isAllTaskFilled(book.bookletTasks)}
												className="btn new_row"
												onClick={() => this.handleVisibleAssign(book.id)}
											>
												Update Interview
											</button> */}
										</div>
									</div>
									<div className="col-md-12">
										<p>
											Please provide the following information for each of your competency completed including your evidence and reflection. You should not record any information that might identify an individual, whether that individual is alive or deceased.
											<br /> <br />

										</p>
									</div>
									{/* {!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
									<div className="text-center w-100">No Single BookLets found</div>
									)} */}
									{/* <div className="w-100 d-flex justify-content-center">
									{isFetchingBooklets && (
										<div className="text-center">
										<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
											<span className="sr-only">Loading...</span>
										</div>
										</div>
									)}
									</div> */}

									<div className="row third_row table-responsive">
									
																		
										{/* <button className="btn export">Export</button> */}
										<table className="tablesorter">
											<thead>
												<tr>
													{(!book.attributes.metafields || book.attributes.metafields.is_code_visible) && (
														<th scope="col" width="5%">Code</th>
													)}
													{(!book.attributes.metafields || book.attributes.metafields.is_assessment_visible) && (
														<th scope="col" width="15%">Assessment / Learning Outcome<span></span></th>
													)}
													<th scope="col" width="15%">Competency<span></span></th>
													<th scope="col" width="10%">Evidence <span></span></th>
													<th scope="col" width="25%">Reflection</th>
													{/* <th scope="col" width="5%">Edit Action</th> */}
												</tr>
											</thead>
											<tbody>
												{
													book.bookletTasks.map((bookletValue, index) => (
														<tr key={index}>
															{(!book.attributes.metafields || book.attributes.metafields.is_code_visible) && (
																<td valign="top">{bookletValue.attributes.code}</td>
															)}
															{(!book.attributes.metafields || book.attributes.metafields.is_assessment_visible) && (
																<td valign="top">{bookletValue.attributes.learning_outcome}</td>
															)}
															<td valign="top">{bookletValue.attributes.competency}</td>
															<td valign="top" className="text-center">{bookletValue.attributes.evidence ? bookletValue.attributes.evidence : '--'}</td>
															{/* <td className="text-center">
																{bookletValue.attributes.self_assessment ? bookletValue.attributes.self_assessment : '--'}
															</td> */}
															<td valign="top" className="text-center">
																<ReadMoreAndLess
																	ref={this.ReadMore}
																	className="read-more-content"
																		charLimit={200}
																		readMoreText="Read more"
																		readLessText="Read less">
																				{bookletValue.attributes.reflection ? bookletValue.attributes.reflection : '--'}
																		</ReadMoreAndLess>
																</td>
															{/* <td valign="top">
																<button type="button" disabled={book.attributes.is_submitted} onClick={() => this.handleVisibleEditTask(bookletValue, book.id)} className="btn btn-sm btn-primary"> <i className="fas fa-pencil-alt"></i> </button>
															</td> */}
														</tr>
													))
												}
											</tbody>
										</table>
										<div className="row second_row">
											<div className="col-md-12"></div>
											<div className="col-md-4">
												<h4>Student Info </h4>

												<div className="form-group">
													<label className="form-label">{book.attributes.user_name }</label>
												</div>
											</div>
											<div className="col-md-4">
												<h4>Supervisor Comments - </h4>
												<div className="form-group">
													<label className="form-label">{book.attributes.assessment ? book.attributes.assessment.supervisor_comments: `--` }</label>
												</div>
												<div className="col-12 form-group image-signature">
												{ book.attributes.supervisor && book.attributes.supervisor.image_url && (
													<img src={book.attributes.supervisor.image_url} alt="Supervisor Signature"/>
												)}
												</div>
												<div className="col-12 form-group float-right">
													<label className="form-label">{book.attributes.supervisor ? book.attributes.supervisor.name: `--` }</label>
												</div>												
											</div>
											<div className="col-md-4">
												<h4>Assessor Comments - </h4>
												<div className="form-group">
													<label className="form-label">{book.attributes.assessment ? book.attributes.assessment.assessor_comments: `--` }</label>
												</div>
												<div className="col-12 form-group image-signature">
												{ book.attributes.assessor && book.attributes.assessor.image_url && (
													<img className="image-signature" src={book.attributes.assessor.image_url} alt="Assessor Signature"/>
												)}
												</div>
												<div className="col-12 form-group float-right">
													<label className="form-label">{book.attributes.assessor ? book.attributes.assessor.name: `--` }</label>
												</div>
											</div>
										</div>
										<div className="new_row">
										<button
												// disabled={book.attributes.is_submitted || this.isAllTaskFilled(book.bookletTasks)}
												className="btn new_row"
												onClick={() => this.handleVisibleEditTask(this.props.match.params.bookletId, 
													this.props.match.params.indexId,book.attributes.assessment)}
											>
												Update Comments
											</button>
										</div>
										{/* Modal */}
										<div id="myModal" className="modal fade" role="dialog">
											<div className="modal-dialog">
												{/* Modal content*/}
												<div className="modal-content">
													<div className="modal-header">
														<button type="button" className="close" data-dismiss="modal">×</button>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
						</div>
					</div>
				</main>

        {/* {isVisibleEditTask && (
          <EditSingleResource
            isVisible={isVisibleEditTask}
            onClose={() => {
              this.setState({
                isVisibleEditTask: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
            singleTask={selectedTask}
          />
        )} */}

        {/* {isVisibleAssignAssor && (
          <UpdatedIndexInterview
            isVisible={isVisibleAssignAssor}
            onClose={() => {
              this.setState({
                isVisibleAssignAssor: false
              });
            }}
            bookLetID={Number(bookLetID)}
            bookLetIndexID={Number(bookLetIndexID)}
          />
        )} */}
		{isVisibleEditTask && (
          <EditSupervisorResource 
            isVisible={isVisibleEditTask}
            onClose={() => {
              this.setState({
                isVisibleEditTask: false
              });
			}}
			bookLetIndexID={Number(bookletIndexId)}
			bookletId={Number(bookletId)}
			assessment={assessment}
            singleTask={selectedTask}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
SingleResources.propTypes = {
  singleBookLets: array.isRequired,
  getSingleBooklets: func.isRequired,
  getSupervisorSingleBooklet: func.isRequired,
  getUsersListing: func.isRequired,
  isFetchingBooklets: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  singleBookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
	getSingleBooklets,
	getSupervisorSingleBooklet,
    getUsersListing,
  }
)(withRouter(SingleResources));
