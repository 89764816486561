import React from "react";
import { func, object, bool, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import { getCalender  } from '../../modules/calender/actions';
import { getCalenderListing, getIsFetchingCalender } from '../../modules/calender/selectors';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { getFormattedDateTime } from 'utils/helper';


/**
 *  Import other dependencies
 */
import { applyJob, getSingleJob } from '../../modules/jobs/actions';
import { getSingleJobDetail, getIsFetchingSingleJob } from '../../modules/jobs/selectors';


class JobDetail extends React.Component {
  componentWillMount() {
    const { jobID } = this.props.match.params;
    if (jobID && !isNaN(jobID)) {
      this.props.getSingleJob(jobID);
    }
  }
  applyJob = (id) => {
    var params = {
      job_opening_id:id,
    };

    this.props.applyJob(params).then(res => {
      var message = res.message;
      console.log(res);
      this.props.getSingleJob(this.props.match.params)
      showSuccessMsg(message ? message : 'Applied successfully.');
    })
  }

  render() {
    const { singleJob, isFetchingSingleJob, isFetchingCalender, calender} = this.props;
    return (
      <>
      <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-3 px-md-5 pt-md-5 pt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <h4 className="family-poppins-semibold my-2">Job</h4>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                      {singleJob.can_edit && (
                        < Link type="button" to={`/user/job-post`}className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Job</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            {/* Filter Code */}
            {/* details */}
            <div className="row mt-md-5 mt-4 mb-5">
              <div className="col-xl-8">
                <div className="card border-0 lS-card-shadow lS-card-radius detail-card ls-height-100">
                  <div className="card-body">
                    {!isFetchingSingleJob && singleJob && Object.keys(singleJob).length > 0 && (
                      <div className="d-flex flex-column flex-md-row">
                        <div>
                          <div className="thumb-wrap thumb-wrap-xl border ls-border-color radius-10 mb-4 mb-md-0">
                            <img src={singleJob.job_image_url} alt="..." className="img-object-fit" />
                          </div>
                        </div>
                        <div className="min-width0 flex-fill pl-md-4">
                          <div className="d-flex ls-height-100 flex-column justify-content-between">
                            <div className="flex-fill">
                              <div>
                                <div className="float-sm-right ml-0 ml-sm-3 text-center">
                                  {!singleJob.can_edit && !singleJob.has_already_applied && (
                                    <button className="btn btn-primary px-4 lS-btn-sm lS-btn-blue-style rounded-pill font-12 mb-3" disabled={singleJob.has_already_applied} 
                                      onClick={() => this.applyTraining(this.props.match.params)}>Apply Now<i className="fas fa-arrow-right ml-1" /></button>
                                  )}

                                  {!singleJob.can_edit && singleJob.has_already_applied && (
                                    <button className="btn btn-primary px-4 lS-btn-sm lS-btn-blue-style rounded-pill font-12 mb-3" disabled>Applied</button>
                                  )}
                                </div>
                                <h4 className="mt-0 mb-2 font-normal color-black-heading family-poppins-medium">{singleJob.title}</h4>
                              </div> 
                              
                              <p className="mb-3 font-18 color-primary">{singleJob.occupation ? singleJob.occupation : `TBC` }</p>
                              <p className="color-grey mb-2">
                                {singleJob.organisation ? singleJob.organisation : `TBC` }
                              </p>
                              <p className="color-grey">{singleJob.description}</p>
                              
                              <div className="font-14 mt-3">
                                <div className="mb-3 d-flex">
                                  <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i>
                                  <span>{singleJob.closing_date ? getFormattedDateTime(singleJob.closing_date) : `TBC`}</span>
                                </div>
                                <div className="mb-3 d-flex">
                                  <i className="fas fa-map-marker-alt color-primary mr-2 line-height-common"></i>
                                  <span>{singleJob.location ? singleJob.location  : `TBC`}</span>
                                </div>
                                <div className="mb-3 d-flex">
                                  <i className="fa fa-gbp line-height-common color-primary mr-2">&pound;</i>
                                  <span>{parseInt(singleJob.min_salary)} - {parseInt(singleJob.max_salary)}</span>
                                </div>
                                <div className="mb-2 d-flex">
                                  <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i>
                                  <span>{singleJob.reference}</span>
                                </div>
                                
                              </div>
                            </div>
                            <div className="pt-2 d-flex action-buttons color-white">
                              {singleJob.can_edit && (
                                <>
                                  <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                                    <i className="fas fa-eye" /> Views (4)
                                  </button>
                                  <button className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                                    <i className="fas fa-pencil-alt" />
                                  </button>
                                  <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                </>
                              )}
                              {!singleJob.can_edit && !singleJob.has_already_applied && (
                                <a href={singleJob.link_url}  
                                    className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 color-white" >
                                    <i className="fas fa-globe"/>&nbsp;Visit Website
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isFetchingSingleJob && singleJob && Object.keys(singleJob).length === 0 && (
                      <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No training found</div>
                    )}
                    {isFetchingSingleJob && (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 m-xl-0 mt-3">
                {/* Calendar code */}
                <div className="card event-calendar-sm calendar-no-event-list lS-card-shadow ls-height-100">
                  <div id="calendar" />
                  {isFetchingCalender && (
                    <Loader />
                  )}
                  {!isFetchingCalender && (
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[ dayGridPlugin ]}
                      header={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                      }}
                      events={calender}
                      eventClick={this.handleDateClick}
                    />
                  )}
                </div>
              </div>
              {/* End Of List  */}
            </div>
          </div>
          
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
JobDetail.propTypes = {
  getSingleJob: func.isRequired,
  isFetchingSingleJob: bool.isRequired,
  singleJob: object.isRequired,
  applyJob: func.isRequired,
  calender: array.isRequired,
  getCalender: func.isRequired,
  isFetchingCalender: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  singleJob: getSingleJobDetail(),
  isFetchingSingleJob: getIsFetchingSingleJob(),
  calender: getCalenderListing(),
  isFetchingCalender: getIsFetchingCalender(),
});

export default connect(
  mapStateToProps,
  {
    getSingleJob,
    applyJob,
    getCalender,
  }
)(withRouter(JobDetail));
