import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import EditSingleResource from "components/editSingleResource";
import UpdatedIndexInterview from "components/updateIndexInterview";
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import ReadMoreAndLess from 'react-read-more-less';
import Select from 'react-select';
import EditAssessorResource from "components/editAssessorResource";
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';

/**
 *  Import other dependencies
 */
import { getSupervisorSingleBooklet, getSingleBooklets, getUsersListing } from '../../../modules/resources/actions';
import { getSingleBookLetsListing, getIsFetchingResources } from '../../../modules/resources/selectors';

const dateFormat = 'DD-MMM-YYYY';
const formSelect = props => {
	const { form, options, placeholder, field } = props;
	if (options.length === 0) return;
	return (
	  <Select
		components={{ IndicatorSeparator:() => null }}
		className="form-control p-0 mb-2"
		isSearchable={true}
		placeholder={placeholder}
		onChange={value => {
		  if(field.name === 'self_assessment'){
			form.setFieldValue('self_assessment', value.value)
		  }
		  else{
			form.setFieldValue('evidence', value.value)
		  }
		}}
		value={field.value ? {label: field.value, value: field.value} : ''}
		styles={customStyles}
		options={formattedArray(options)}
	  />
	)
	};

const formattedArray = array => {
	return array.map(item => {
		return {
		label: `${item}`,
		value: `${item}`
		};
	});
	};

const customStyles = {
	control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
	placeholder: () => ({
		color: 'inherit',
	}),
	singleValue: () => ({
		color: 'inherit',
	}),
};

class SingleResources extends React.Component {
	getFormikInitVal = () => {
		// const { singleTask } = this.props;
		// const {
		// 	evidence,
		// 	reflection,
		// 	evidence_attachment_url,
		// 	self_assessment
		// } = singleTask.attributes || {};
    return {
      self_assessment: "Novice",
    }
	};

  state={
    isVisibleEditTask: false,
    isVisibleAssignAssor: false,
    selectedTask: null,
    bookletIndexId: null,
    bookletId: null,
	indexID: null,
	assessment: null,
  }

  componentWillMount() {
    const bookletId  = this.props.match.params;
	const indexId  = this.props.match.params;
	const userId  = this.props.match.params;
	const params = {
		booklet_user_id: userId,
		examinerType: 'assessor',
	  };

    if (bookletId.bookletId && !isNaN(bookletId.bookletId)) {
      this.props.getSupervisorSingleBooklet(bookletId.bookletId, indexId.indexId, params);
      this.props.getUsersListing();
    }
  }

  handleVisibleEditTask = (bookletId, bookletIndexId, assessment) => {
    this.setState({
      isVisibleEditTask: true,
    //   selectedTask: task,
		bookletIndexId: bookletIndexId,
		bookletId: bookletId,
		assessment: assessment,
	});
  }


  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      bookLetIndexID: bookletIndex,
      bookletId: this.props.match.params.bookletId,
    });
  }

  isAllTaskFilled = tasksArray => {
    for (let i = 0; i < tasksArray.length; i++) {
      if(!tasksArray[i].attributes.evidence) return true;
    }

    return false;
  };

  render() {
    const {
      singleBookLets,
      isFetchingBooklets
    } = this.props;
    const {
      isVisibleEditTask,
      isVisibleAssignAssor,
      selectedTask,
      bookletIndexId,
	  bookLetID,
	  assessment,
	  bookletId,
      indexID
    } = this.state;
    // const decoratedOnClick = useAccordionToggle(eventKey, onClick);
    return (
      <>
        <UserHeader />
        {/* Page content */}
		<main className="m-0 px-3 px-md-5">
			{!isFetchingBooklets && singleBookLets && singleBookLets.length === 0 && (
				<div className="text-center bg-white my-5 py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No Booklet found</div>
			)}
			{isFetchingBooklets && (
				<div className="my-5">
					<Loader />
				</div>
			)}
			{!isFetchingBooklets && singleBookLets && singleBookLets.length > 0 && singleBookLets.map((book,bookLetIndex) => (
			<div className="container-fluid mt-5 p-0">
				<div className="card border-0 page-title-bar">
				<div className="card-body">
					<div className="row align-items-center">
						<div className="col-8">
							<h4 className="family-poppins-semibold mb-0">{book.attributes.resource_booklet_index_name}
							</h4>	
						</div>
						<div className="col-4 text-right">
							<div className="thumb-wrap thumb-wrap-100 ml-auto rounded-lg border ls-border-color">
								<img src={require("assets/images/clh-125.png")} alt="FEDERATIONLOGO" className="img-object-fit" />
							</div>
						</div>
					</div>
				</div>
				</div>
				{/* detail tiles */}
				<div>
					<div className="row mt-5 mx-lg-n4">
						<div className="col-lg-4 col-md-6 px-lg-4 mb-5">
							<div className="ls-height-100 card detail-tile-dotted interview-tile lS-card-radius lS-card-shadow">
								<div className="card-body">
									<label className="color-grey text-capitalize">The initial interview</label>
									<p className="family-poppins-medium font-19 mb-0">
										{book.attributes.self_assessment_initial || `--` }
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 px-lg-4 mb-5">
							<div className="ls-height-100 card detail-tile-dotted interview-tile lS-card-radius lS-card-shadow">
								<div className="card-body">
									<label className="color-grey text-capitalize">The mid-point interview</label>
									<p className="family-poppins-medium font-19 mb-0">
										{book.attributes.self_assessment_mid || `--` }
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 px-lg-4 mb-5">
							<div className="ls-height-100 card detail-tile-dotted interview-tile lS-card-radius lS-card-shadow">
								<div className="card-body">
									<label className="color-grey text-capitalize">The end point interview</label>
									<p className="family-poppins-medium font-19 mb-0">
										{book.attributes.self_assessment_end || `--` }
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="text-md-right text-center">
						
					</div>
				</div>
				{/* table info */}
				<div>
				<p className="family-poppins-medium">Please provide the following information for each of your competency completed including your evidence and reflection. You should not record any information that might identify an individual, whether that individual is alive or deceased.
				</p>
				<div className="card border-0 lS-card-shadow lS-card-radius mt-4">
					<div className="card-body">
					<div className="table-responsive ls-table">
						<table className="table text-center">
						<thead>
							<tr>
							{(!book.attributes.metafields || book.attributes.metafields.is_code_visible) && (
								<th scope="col" className="pb-4 text-left" width={80}>Code</th>
							)}
							{(!book.attributes.metafields || book.attributes.metafields.is_assessment_visible) && (
								<th scope="col" className="pb-4" width={280}>Assessment / Learning Outcome</th>
							)}
							<th scope="col" className="pb-4" width={300}>Competency</th>
							<th scope="col" className="pb-4" width={150}>Evidence</th>
							<th scope="col" className="pb-4" width={150}>Reflection</th>
							{/* <th scope="col" className="pb-4 text-right" width={100}>Action</th> */}
							</tr>
						</thead>
						<tbody>
							{book.bookletTasks.map((bookletValue, index) => (
								<tr>
									{(!book.attributes.metafields || book.attributes.metafields.is_code_visible) && (
										<td className="py-4 text-left pl-4">{bookletValue.attributes.code}</td>
									)}
									{(!book.attributes.metafields || book.attributes.metafields.is_assessment_visible) && (
										<td className="py-4">{bookletValue.attributes.learning_outcome}</td>
									)}
									<td className="py-4">{bookletValue.attributes.competency}</td>
									<td className="py-4">{bookletValue.attributes.evidence ? bookletValue.attributes.evidence : '--'}</td>
									<td className="py-4">
										<ReadMoreAndLess
											ref={this.ReadMore}
											className="read-more-content"
												charLimit={200}
												readMoreText="Read more"
												readLessText="Read less">
														{bookletValue.attributes.reflection ? bookletValue.attributes.reflection : '--'}
												</ReadMoreAndLess>
										</td>
								</tr>
							))
							}
						</tbody>
						</table>
					</div>
					</div>
				</div>
				</div>
				<div className="row my-5 mx-lg-n4">
					<div className="col-lg-4 col-md-6 px-lg-4 mb-4">
						<div className="ls-height-100 card detail-tile-dotted lS-card-radius lS-card-shadow">
							<div className="card-body">
								<label className="color-grey text-capitalize">Student info</label>
								<p className="family-poppins-medium font-19 mb-0">{book.attributes.user_name }</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 px-lg-4 mb-4">
						<div className="ls-height-100 card detail-tile-dotted lS-card-radius lS-card-shadow">
							<div className="card-body">
								<label className="color-grey text-capitalize">Supervisor comments</label>
								<p className="family-poppins-medium font-16 mb-0">
									{book.attributes.assessment ? book.attributes.assessment.supervisor_comments: `--` }
								</p>
								<p className="family-poppins-medium font-14 mb-0">
									{book.attributes.supervisor ? book.attributes.supervisor.name: `--` }
								</p>
								<div className="row mt-3 tile-thumbs">
									<div className="col-4">
										{ book.attributes.supervisor && book.attributes.supervisor.image_url && (
											<div className="thumb-wrap thumb-wrap-sm w-100 border ls-border-color rounded-lg">
												<img src={book.attributes.supervisor.image_url} className="mb-2" alt="Supervisor Signature"/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-12 px-lg-4 mb-4">
						<div className="ls-height-100 card detail-tile-dotted lS-card-radius lS-card-shadow">
							<div className="card-body">
								<label className="color-grey text-capitalize">Assessor comments</label>
								<p className="family-poppins-medium font-16 mb-0">
									{book.attributes.assessment ? book.attributes.assessment.assessor_comments: `--` }
								</p>
								<p className="family-poppins-medium font-14 mb-0">
									{ book.attributes.assessor && (									
										<label className="form-label">{book.attributes.assessor ? book.attributes.assessor.name: `--` }</label>							
									)}
								</p>
								<div className="row mt-3 tile-thumbs">
									<div className="col-4">																
										{ book.attributes.assessor && book.attributes.assessor.image_url && (
											<div className="thumb-wrap thumb-wrap-sm w-100 border ls-border-color rounded-lg">
												<img className="image-signature" src={book.attributes.assessor.image_url} className="mb-2" alt="Assessor Signature"/>
											</div>
										)}								
									</div>							
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="text-md-right text-center">
					<button type="button" 
						onClick={() => this.handleVisibleEditTask(this.props.match.params.bookletId, 
							this.props.match.params.indexId,book.attributes.assessment)}
						className="btn lS-btn-blue-style lS-btn-blue-large mb-5">
							Update Comments
					</button>
				</div>
			</div>	
			
			))}
			</main>
		<UserFooter />
        {isVisibleEditTask && (
          <EditAssessorResource 
            isVisible={isVisibleEditTask}
            onClose={() => {
              this.setState({
                isVisibleEditTask: false
              });
			}}
			bookLetIndexID={Number(bookletIndexId)}
			bookletId={Number(bookletId)}
			assessment={assessment}
            singleTask={selectedTask}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
SingleResources.propTypes = {
  singleBookLets: array.isRequired,
  getSingleBooklets: func.isRequired,
  getSupervisorSingleBooklet: func.isRequired,
  getUsersListing: func.isRequired,
  isFetchingBooklets: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  singleBookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
	getSingleBooklets,
	getSupervisorSingleBooklet,
    getUsersListing,
  }
)(withRouter(SingleResources));
