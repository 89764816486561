/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_NEWS,
  GET_SINGLE_NEWS,
  APPLY_PAGINATION,
  PUBLISH_NEWS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of news
 */
export function getNews() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, getNewsListing } = state.get('news').toJS();
    const params ={
      page,
      limit,
      getNewsListing,
    };

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getNewsListingBegin(
          API_URLS.NEWS.GET_NEWS_LISTING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getNewsListingBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_NEWS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get Single News
 */
export function getSingleNews(newsID) {
  return async dispatch => {

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSingleNewsBegin(
          API_URLS.NEWS.GET_SINGLE_NEWS + newsID,
          source
        )
      );
      if (response.payload) {
        
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSingleNewsBegin = (API_URL, source) => ({
  type: GET_SINGLE_NEWS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      cancelToken: source.token
    }
  }
});


/**
 *  update List of Jobs
 */
export function publishPostNews(id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        publishPostNewsBegin(
          API_URLS.POST_NEWS.PUBLISH_NEWS + id,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const publishPostNewsBegin = (API_URL, source) => ({
  type: PUBLISH_NEWS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getNews());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


