import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import ApproveConfirmation from 'components/approveConfirmation';
import Loader from 'components/Loader';
import { getLocalStorage } from '../../utils/helper';
import { isRouteAllow } from 'utils/helper';

/**
 *  Import other dependencies
 */
import { getUsers, applyFilters, applyPagination, resetFilters, approveUser } from '../../modules/adminUserListing/actions';
import { getUsersListing, getIsFetchingUsers, getTotalPages, getUserPage, getIsApprovingUser } from '../../modules/adminUserListing/selectors';
import { showSuccessMsg } from "../../utils/notification";

class AdminUserListing extends React.Component {
  state={
    userProfile: JSON.parse(getLocalStorage()),
    search: '',
    selectedItem: null,
    isVisibleAprroval: false,
    isVisibleAdduser: false,
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    const userProfile = JSON.parse(getLocalStorage());
    this.props.getUsers(userProfile.org_id);
  }

  componentWillUnmount() {
    this.resetUsers();
  }

  checkUncheckbox = (name, event, string) => {
    this.props.checkUnCheckFilters(name, event.target.checked, string);
  };

  searchJobs = () => {
    const {search, range} = this.state;
    this.props.applyFilters(search, range);
  };

  resetUsers = () => {
    this.props.resetFilters();
  };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  approveSelectedUser = user => {
    this.setState({
      selectedItem: user,
      isVisibleAprroval: true,
    });
  };

  openAddUser = () => {
    this.setState({
      isVisibleAdduser: true,
    });
  };

  render() {
    const { users, isFetchingUsers, totalPages, page, isApprovingUser } = this.props;
    const { search, isVisibleAprroval, selectedItem , userProfile} = this.state;
    console.log(this.props)
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold">Users</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-lg-end justify-content-center">
                    <Link to={`/user/add-user`}>
                      <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Create User</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table listing */}
          <div className="card my-5 shadow-sm border-0 rounded">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table ls-booking-table">
                  <thead>
                    <tr>
                      <th scope="col" className="font-12 text-left">Profile Image</th>
                      <th scope="col" className="font-12 text-center">Name</th>
                      <th scope="col" className="font-12 text-center">Email</th>
                      <th scope="col" className="font-12 text-center">Role</th>
                      <th scope="col" className="font-12 text-center">Occupation</th>
                      <th scope="col" className="font-12 text-center">Account Active</th>
                      {(userProfile.role === 'admin' || userProfile.role === 'staff') && (
                        <th scope="col" className="font-12 text-center">Action</th>
                      )}
                      
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetchingUsers && users && users.length > 0 && users.map((user, index) => {
                      const {
                        profile_image_url,
                        name,
                        email,
                        role,
                        is_approved,
                        occupation,
                        email_verified_at
                      } = user.attributes
                      return(
                        <tr key={index}>
                          <td>
                            <img src={profile_image_url} alt="" width={40} height={40} className="img-fluid ls-object-cover rounded-circle ls-table-image" />
                          </td>
                          <td className="color-grey text-center">{name}</td>
                          <td className="color-grey text-center">{email}</td>
                          <td className="color-grey text-center"><span>{role}</span></td>
                          <td className="color-grey text-center"><span>{occupation}</span></td>
                          <td className="color-grey text-center">
                            <span>
                              { email_verified_at ? 'Yes' : 'No' }
                            </span>
                          </td>
                          {(userProfile.role === 'admin' || userProfile.role === 'staff') && (
                            <td className="color-grey">
                                <div className="d-flex justify-content-center align-items-center">
                                  <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-blue-style rounded-lg mr-2" disabled={is_approved} onClick={() => this.approveSelectedUser(user)}>
                                      {is_approved ? `Approved` : `Approve`}
                                  </button>
                                </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {!isFetchingUsers && users && users.length === 0 && (
                <div className="text-center color-grey font-16">No Users found</div>
              )}
              {isFetchingUsers && (
                <Loader />
              )}
            </div>
            <div aria-label="Page navigation example" className="ls-pagination mr-4">
              <ReactPaginate
                containerClassName={'pagination justify-content-end mt-5'}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                forcePage={page - 1}
                onPageChange={selectedItem => {
                  if (isFetchingUsers) return;
                  this.loadPage(selectedItem.selected + 1);                      
                }}
                subContainerClassName={'pages pagination'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </main>
        <UserFooter />
        <ApproveConfirmation
          isVisible={isVisibleAprroval}
          onClose={()=> {
            this.setState({
              isVisibleAprroval: false,
            });
          }}
          onApprove={()=> {
            if (isApprovingUser) return;
            this.props.approveUser(selectedItem.id).then(res => {
              const { message } = res;
              showSuccessMsg(message);
              this.setState({
                isVisibleAprroval: false,
              });
            })
          }}
          title={'Approve User'}
          isProcessing={isApprovingUser}
        />
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
AdminUserListing.propTypes = {
  applyFilters: func.isRequired,
  applyPagination: func.isRequired,
  approveUser: func.isRequired,
  getUsers: func.isRequired,
  isApprovingUser: bool.isRequired,
  isFetchingUsers: bool.isRequired,
  users: array.isRequired,
  page: number.isRequired,
  resetFilters: func.isRequired,
  totalPages: number.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  users: getUsersListing(),
  isFetchingUsers: getIsFetchingUsers(),
  isApprovingUser: getIsApprovingUser(),
  totalPages: getTotalPages(),
  page: getUserPage(),
});

export default connect(
  mapStateToProps,
  {
    getUsers,
    applyPagination,
    applyFilters,
    resetFilters,
    approveUser,
  }
)(withRouter(AdminUserListing));
