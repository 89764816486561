/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const placements = () => state => state.get('placements');

/**
 *  Select the correct portion of the root reducer
 */
export const getPlacementsListing = () =>
  createSelector(placements(), state => {
    const listing = state.get('placements').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select Single placement page object
 */
export const getSinglePlacementDetail = () =>
  createSelector(placements(), state => {
    const singlePlacement = state.get('singlePlacement')
    return singlePlacement && Object.keys(singlePlacement).length > 0 ? singlePlacement.toJS() : {}
  });

/**
 *  Select the placement Occupations list
 */
export const getPlacementsOccupationsList = () =>
  createSelector(placements(), state => {
    const listing = state.get('placementOccupations').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getPlacementApplicantsListing = () =>
createSelector(placements(), state => {
  const listing = state.get('placementApplicants').toJS()
  return listing.length > 0 ? listing : []
});

/**
 *  Select the correct portion of the root reducer
 */
export const getPlacementMentorsListing = () =>
createSelector(placements(), state => {
  const listing = state.get('placementMentors').toJS()
  return listing.length > 0 ? listing : []
});

/**
 *  Select is fetching placements applicants Name
*/
export const getIsFetchingPlacementMentors = () =>
createSelector(placements(), state => {
  return state.get('isFetchingPlacementMentors')
})

/**
 *  Select is fetching placements applicants Name
*/
export const getIsFetchingPlacementApplicants = () =>
createSelector(placements(), state => {
  return state.get('isFetchingPlacementApplicants')
})

/**
 *  Select is fetching placements
 */
export const getIsFetchingPlacements = () =>
  createSelector(placements(), state => {
    return state.get('isFetchingPlacements')
  })

/**
 *  Select is fetching placements
 */
export const getIsFetchingSinglePlacement = () =>
  createSelector(placements(), state => {
    return state.get('isFetchingSinglePlacement')
  })

/**
 *  Select total number of placements pages
 */
export const getTotalPages = () =>
  createSelector(placements(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current placement pages
 */
export const getPlacementPage = () =>
  createSelector(placements(), state => {
    return state.get('page')
  })
