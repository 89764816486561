import React from "react";
import ReactDOM from "react-dom";
import LinkStaff from './link-staff';
import "assets/css/bootstrap.min.css";
import "assets/css/fontawesome.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-datetime/css/react-datetime.css';
import 'swiper/css/swiper.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "assets/scss/styles.scss";
import 'animate.css/animate.css';
import "assets/css/custom.css";

ReactDOM.render(
  <LinkStaff />,
  document.getElementById("root")
);