/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const practiceLogs = () => state => state.get('practiceLog');

/**
 *  Select the correct portion of the root reducer
 */
export const getPracticeLogListing = () =>
  createSelector(practiceLogs(), state => {
    const listing = state.get('practiceLog').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select Single news page object
 */
export const getPracticeLogDetail= () =>
  createSelector(practiceLogs(), state => {
    const practiceLog = state.get('practiceLog')
    return practiceLog && Object.keys(practiceLog).length > 0 ? practiceLog.toJS() : {}
  });

/**
 *  Select is fetching
 */
export const getIsFetchingPracticeLog = () =>
  createSelector(practiceLogs(), state => {
    return state.get('isFetchingPracticeLog')
  })

/**
 *  Select is fetching news
 */
// export const getIsFetchingSingleNews = () =>
//   createSelector(news(), state => {
//     return state.get('isFetchingSingleNews')
//   })

/**
 *  Select total number of news pages
 */
// export const getTotalPages = () =>
//   createSelector(news(), state => {
//     return state.get('totalPage')
//   })

/**
 *  Select current news pages
 */
// export const getNewsPage = () =>
//   createSelector(news(), state => {
//     return state.get('page')
//   })
