/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const userProfile = () => state => state.get('userProfile');

/**
 *  Select =is Uploading
 */
export const getIsUpdatingProfile = () =>
  createSelector(userProfile(), state => {
    return state.get('isUpdatingProfile')
  })

/**
 *  Select =is Uploading
 */
export const getIsFetchingEmployment = () =>
  createSelector(userProfile(), state => {
    return state.get('isFetchingEmployment')
  })

/**
 *  Select =is Uploading
 */
export const getIsFetchingEducation = () =>
  createSelector(userProfile(), state => {
    return state.get('isFetchingEducation')
  })

/**
 *  Select =is Get Practice
 */
export const getIsPractice = () =>
  createSelector(userProfile(), state => {
    const listing = state.get('isPractice').toJS()
    return listing.length > 0 ? listing : []
  })

/**
 *  Select =is Get Institution Name
 */
export const getIsInstitutionName = () =>
  createSelector(userProfile(), state => {
    const listing = state.get('isInstitutionName').toJS()
    return listing.length > 0 ? listing : []
  })

  /**
 *  Select =is Get Practice
 */
export const getIsEducationList = () =>
  createSelector(userProfile(), state => {
    const listing = state.get('isEducationList').toJS()
    return listing.length > 0 ? listing : []
  })

/**
 *  Select =is Get Practice
 */
export const getIsEmploymentList = () =>
  createSelector(userProfile(), state => {
    const listing = state.get('isEmploymentList').toJS()
    return listing.length > 0 ? listing : []
  })

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(userProfile(), state => {
    return state.get('isUploadingImage')
  })