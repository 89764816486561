import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import NewsPost from 'components/newsPost';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import ApproveConfirmation from 'components/approveConfirmation';
import { isRouteAllow } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import { getNews, publishPostNews, applyPagination  } from '../../modules/news/actions';
import { deletePostNews  } from '../../modules/newsPost/actions';

import { getNewsListing, getIsFetchingNews, getTotalPages, getNewsPage } from '../../modules/news/selectors';
import Loader from 'components/Loader';

class News extends React.Component {

  state={
    selectedItem: null,
    isVisibleAprroval: false,
    isVisiblePostNews: false,
  }

//   handleQueryChange = query => {
//     this.setState({
//       search: query
//     });
//   };

  componentWillMount() {
    this.props.getNews();
  }

  publishNews = (id) => {
    this.props.publishPostNews(id).then(res => {
      var message = res.message;
      this.props.getNews()
      showSuccessMsg(message ? message : 'Published successfully.');
    })
  }

  deleteNews = (id) => {
    this.setState({
      selectedItem: id,
      isVisibleAprroval: true,
    });
  }

  editNews = (id) => {
    this.setState({
      selectedItem: id,
      isVisiblePostNews: true,
    });
  }

  getSingleNews = (newsObj, index, id) => (
    <div className="col-lg-4 col-sm-6 mb-4" key={index}>
      <div className="card shadow-sm border-0 rounded training-item">
        <div className="card-body">
          <h5 className="mt-0 color-black-heading text-truncate family-poppins-semibold">
            {newsObj.title} 
          </h5>
          <span className="text-uppercase font-14 color-primary d-block my-2 text-truncate">
            {newsObj.federation_name}
          </span>
          <p className="color-grey mb-0 text-truncate position-relative pr-5">
            {newsObj.content.substring(0,250)} 
            <Link to={`/user/news/${id}`} className="more-text"> More <i className="fas fa-angle-double-right"></i></Link>
          </p>
          <div className="color-black-heading mt-2 font-14">
            <span className="d-lg-inline d-inline-block">
              <i className="fas fa-calendar-alt mr-2 color-primary"></i>
              {newsObj.created_at ? getFormattedDate(newsObj.created_at) : ``}
            </span>
          </div>
          {newsObj.can_edit && (
            <div className="d-flex flex-wrap action-buttons">
              <button 
                onClick={() => this.publishNews(id)}
                disabled={newsObj.status === "published"}
                className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                <i className="fas fa-newspaper mr-1" /> {newsObj.status === "published" ? `Published` : `Publish` } 
              </button>

              <button onClick={e => this.editNews(id)}
                className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                  <i className="fas fa-pen" />
              </button>

              <button onClick={e => this.deleteNews(id)}
                className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2">
                  <i className="fas fa-trash-alt" />
              </button>
            </div>
          )} 
        </div>
      </div>
    </div>
  );

//   handleRange = event => {
//     this.setState({
//       range: event.target.value
//     });
//   };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  render() {
    const { news, isFetchingNews, totalPages, page  } = this.props;
    const { isVisibleAprroval, selectedItem, isVisiblePostNews } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        {/* body of page */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold my-2">News</h4>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-lg-end justify-content-center">
                    {isRouteAllow('/post-news') && (
                      <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large" onClick={() => this.setState({ isVisiblePostNews: true })}>
                        Post News
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 pb-2 mt-md-5 mt-4">
              <h4 className="family-poppins-semibold"> Latest News </h4>
            </div>
          </div>
          {/* News Listing */}
            <div className="row mt-md-4 mt-2">          
              {!isFetchingNews && news && news.length > 0 && news.map((newsObj, index) => this.getSingleNews(newsObj.attributes, index, newsObj.id))}
              
              {!isFetchingNews && news && Object.keys(news).length === 0 && (
                <div className="col-12">
                  <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                    No news found
                  </div>
                </div>
              )}
              {isFetchingNews && (
                <div className="col-12"><Loader /></div>
              )}
            </div>
          {/* <div className="row">
            <div className="col-md-12 pb-2 mt-1">
              <h4 className="family-poppins-semibold"> Previous News </h4>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4 col-md-6 col-sm-12 pb-2 mb-4">
              <div className="card shadow-sm border-0 rounded training-item">
                <div className="card-body">
                  <h5 className="mb-1 family-poppins-semibold">
                    Free GP Cancer Update Course
                  </h5>
                  <small>
                    <i className="fas fa-calendar-alt color-primary mr-2" />
                    <span>Week&nbsp;</span>
                    <span>25/05/2020</span>
                  </small>
                  <p className="color-primary font-10 text-uppercase mt-1 mb-2">centeral LondonHealthcare cic.</p>
                  <p className="color-grey font-12">
                    RM Partners is hosting two further events on 8 February 2020, and 18 March 2020. these are free for GPs as well as other clinicians working..
                  </p>
                  <div className="d-flex justify-content-between flex-wrap font-11 pb-3">
                    <div className="d-flex justify-content-between flex-column flex-wrap font-11">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-map-marker-alt color-primary mr-2" />
                        <span className="font-10">Cavendish Conference Centre</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pb-2 mb-4">
              <div className="card shadow-sm border-0 rounded training-item">
                <div className="card-body">
                  <h5 className="mb-1 family-poppins-semibold">
                    Free GP Cancer Update Course
                  </h5>
                  <small>
                    <i className="fas fa-calendar-alt color-primary mr-2" />
                    <span>Week&nbsp;</span>
                    <span>25/05/2020</span>
                  </small>
                  <p className="color-primary font-10 text-uppercase mt-1 mb-2">centeral LondonHealthcare cic.</p>
                  <p className="color-grey font-12">
                    RM Partners is hosting two further events on 8 February 2020, and 18 March 2020. these are free for GPs as well as other clinicians working..
                  </p>
                  <div className="d-flex justify-content-between flex-wrap font-11 pb-3">
                    <div className="d-flex justify-content-between flex-column flex-wrap font-11">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-map-marker-alt color-primary mr-2" />
                        <span className="font-10">Cavendish Conference Centre</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pb-2 mb-4">
              <div className="card shadow-sm border-0 rounded training-item">
                <div className="card-body">
                  <h5 className="mb-1 family-poppins-semibold">
                    Free GP Cancer Update Course
                  </h5>
                  <small>
                    <i className="fas fa-calendar-alt color-primary mr-2" />
                    <span>Week&nbsp;</span>
                    <span>25/05/2020</span>
                  </small>
                  <p className="color-primary font-10 text-uppercase mt-1 mb-2">centeral LondonHealthcare cic.</p>
                  <p className="color-grey font-12">
                    RM Partners is hosting two further events on 8 February 2020, and 18 March 2020. these are free for GPs as well as other clinicians working..
                  </p>
                  <div className="d-flex justify-content-between flex-wrap font-11 pb-3">
                    <div className="d-flex justify-content-between flex-column flex-wrap font-11">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-map-marker-alt color-primary mr-2" />
                        <span className="font-10">Cavendish Conference Centre</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Modal */}
          <div className="modal fade" id="newsModal" tabIndex={-1} role="dialog" aria-labelledby="newsModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content ls-modal-curve ls-overflow-hidden border-0">
                <div className="modal-header background-primary justify-content-center">
                  <h5 className="modal-title color-white" id="newsModalLabel">Post News</h5>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <form>
                        <div className="form-group">
                          <label htmlFor="title" className="font-12 pl-2 color-grey">Title</label>
                          <input type="text" className="form-control" id="title" placeholder="Enter news title" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="content" className="font-12 pl-2 color-grey">Content</label>
                          <textarea className="form-control" id="content" rows={3} placeholder="Enter news content" defaultValue={""} />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row mt-3 pb-3">
                    <div className="col-md-6">
                      <button type="button" className="btn btn-secondary w-100" data-dismiss="modal">Cancel</button>
                    </div>
                    <div className="col-md-6">
                      <button type="button" className="btn btn-primary lS-btn-blue-style w-100">Submit</button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div aria-label="Page navigation example" className="ls-pagination">
                  <ReactPaginate
                    containerClassName={'pagination justify-content-end mt-5'}
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    forcePage={page - 1}
                    onPageChange={selectedItem => {
                      if (isFetchingNews) return;
                      this.loadPage(selectedItem.selected + 1);
                    }}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
        </main>
        <UserFooter />
        <ApproveConfirmation
          isVisible={isVisibleAprroval}
          onClose={()=> {
            this.setState({
              isVisibleAprroval: false,
            });
          }}
          onApprove={()=> {
            // if (isApprovingUser) return;

            this.props.deletePostNews(selectedItem).then(res => {
              var message = res.message;
              this.props.getNews()
              showSuccessMsg(message ? message : 'Deleted successfully.');
              this.setState({
                isVisibleAprroval: false,
              });
            })
          }}
          title={'Delete News'}
        />
        {isVisiblePostNews && (
          <NewsPost 
            isVisible={isVisiblePostNews}
            onClose={mode => {
              this.setState({
                isVisiblePostNews: false,
                selectedItem: null,
              });
              if(mode === 'edit') {window.location.reload();}
            }}
            newsId={selectedItem}
            mode={selectedItem ? 'edit' : 'add'}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
News.propTypes = {
    news: array.isRequired,
    getNews: func.isRequired,
    isFetchingNews: bool.isRequired,
    page: number.isRequired,
    totalPages: number.isRequired,
    publishPostNews:func.isRequired,
    deletePostNews:func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    news: getNewsListing(),
    isFetchingNews: getIsFetchingNews(),
    page: getNewsPage(),
    totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getNews,
    publishPostNews,
    deletePostNews,
    applyPagination,
  }
)(withRouter(News));
