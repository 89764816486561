/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const adminUserListing = () => state => state.get('adminUserListing');

/**
 *  Select the correct portion of the root reducer
 */
export const getUsersListing = () =>
  createSelector(adminUserListing(), state => {
    const listing = state.get('users').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching users
 */
export const getIsFetchingUsers = () =>
  createSelector(adminUserListing(), state => {
    return state.get('isFetchingUsers')
  })

/**
 *  Select is Approving user
 */
export const getIsApprovingUser = () =>
  createSelector(adminUserListing(), state => {
    return state.get('isApprovingUser')
  })
  
/**
 *  Select total number of adminUserListing pages
 */
export const getTotalPages = () =>
  createSelector(adminUserListing(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current user pages
 */
export const getUserPage = () =>
  createSelector(adminUserListing(), state => {
    return state.get('page')
  })
