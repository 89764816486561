/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const comments = () => state => state.get('comments');

/**
 *  Select the correct portion of the root reducer
 */
export const getCommentsListing = () =>
  createSelector(comments(), state => {
    const listing = state.get('comments').toJS();
    return listing && listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching Comments
 */
export const getIsFetchingComments = () =>
  createSelector(comments(), state => {
    return state.get('isFetchingComments');
  });

/**
 *  Select is fetching Comments
 */
export const getIsPostingComments = () =>
  createSelector(comments(), state => {
    return state.get('isPostingComments')
  });

/**
 *  Select total pages
 */
export const getTotalPage = () =>
  createSelector(comments(), state => {
    return state.get('totalPage')
  });

/**
 *  Select pages
 */
export const getPage = () =>
  createSelector(comments(), state => {
    return state.get('page')
  });

/**
 *  Select pages
 */
export const getTotalComments = () =>
  createSelector(comments(), state => {
    return state.get('totalComments') ? state.get('totalComments') : 0;
  });
