import React from "react";
import { func, bool, number, object, array } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import { getPcn, getFederations, getSingleMyPcn, changeSingleMap } from '../../../modules/myPcn/actions';
import {
	getMyPcnListing,
	getIsFetchingMyPcn,
	getSingleSelectedMap,
	getNetworksAndPractices,
  getIsFetchingNetworks,
  getFederationsList,
	getSelectedFederation,
} from '../../../modules/myPcn/selectors';
import Loader from 'components/Loader';

const customStyles = {
	container: styles => ({
		...styles,
		height: 44,
		backgroundColor: '#FFFFFF',
		borderColor: '#f3f3f3',
		borderWidth: 2,
		boxShadow: 'none',
	}),
	control: styles => ({
		...styles,
		height: 44,
		backgroundColor: '#FFFFFF',
		borderColor: '#f3f3f3',
		borderWidth: 2,
		boxShadow: 'none',
	}),
	control: (base, state) => ({
		...base,
			borderColor: '#f3f3f3',
			borderWidth: 2,
			boxShadow: 'none',
		'&:hover': {
			borderColor: '#f3f3f3',
		}
	}),
	valueContainer: styles => ({
		...styles,
		height: 42,
		padding: '0 20px',
		fontSize: 14,
	}),
	indicatorsContainer: styles => ({
		...styles,
		padding: '0 8px',
		maxHeight: 44,
		alignSelf: 'auto',
	}),
	placeholder: () => ({
		color: '#BABBC2',
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		lineHeight: '42px',
		textOverflow: 'ellipsis',
	}),
	singleValue: () => ({
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: 'inherit',
		lineHeight: '42px',
	}),
};


const formattedArray = array => {
  return array.map(item => {
    return {
      label: item.attributes.name,
      value: item.id
    };
  });
};

const formSelect = props => {
  const { options, thisView } = props;
  const { selectedFederation } = thisView.state;
  const { isFetchingMyPcn, getPcn } = thisView.props;
	return (
	<Select
		components={{ IndicatorSeparator:() => null }}
		className="p-0"
    isSearchable={false}
    isDisabled={isFetchingMyPcn}
    placeholder="Search by Federation"
    onChange={value => {
      if(value && value.value) {
        thisView.setState({
          selectedFederation: value.value,
        });
        getPcn(value.value);
      }
    }}
		value={selectedFederation ? formattedArray(options.filter((option) => Number(option.id) === Number(selectedFederation))) : ''}
    styles={customStyles}
    options={formattedArray(options)}
	/>
)};

class MyPcnList extends React.Component {
  state = {
    selectedFederation: null,
  }

  componentWillMount() {
  // Get all maps on page load
    this.props.getFederations().then((res) => {
      const { federations } = this.props;
      if(federations && federations.length > 0) {
        this.props.getPcn(federations[0].id);
        this.setState({
          selectedFederation: federations[0].id,
        });
      }
    });
  }

  getSelectedFederations = (data) => {
	const { isFetchingNetworks } = this.props;
	if (isFetchingNetworks) return;

	// Get selected Federation data
	this.props.getSingleMyPcn(data)
  };

  changeMap = mapObj => {
	if (!mapObj) return;
	this.props.changeSingleMap(mapObj);
  };

  render() {
	const { pcn, selectedMap, selectedFederation, isFetchingMyPcn, federations, isFetchingNetworks, networksAndPractices } = this.props;
  return (
      <>
        <UserHeader />
        {/* Page content */}
		<main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
			<div className="container-fluid p-0">
				<div className="card border-0 page-title-bar">
					<div className="card-body">
						<div className="row align-items-center">
							<div className="col-lg-8 col-md-6">
								<h4 className="family-poppins-semibold">Networks</h4>
								<p className="mb-0 color-grey font-14">Know your Primary Care Networks and view their profiles and performances.</p>
							</div>
							<div className="col-lg-4 col-md-6 mt-3 mt-md-0">
								<Formik>							
									<Form className="ls-form">
										<div className="form-group mb-0">
											<Field as="select" name="federations" placeholder="Search by Federation" component={formSelect} thisView={this} options={federations} />
										</div>
									</Form>
								</Formik>
							</div>
						</div>
					</div>
				</div>
				
				<div className="row mt-md-5 mt-4">
					{!isFetchingMyPcn && pcn && pcn.length > 0 && pcn.map((pcn, index) => (
						<div className="col-lg-3 col-md-4 mb-4" key={index} onClick={() => this.changeMap(pcn)}>
							<div className={`box network-maps ${ selectedMap && selectedMap.id === pcn.id ? 'active-map' : '' }`}>
								<div className="rcorners3 cursor-pointer">
									<img className="img-fluid" style={{ height: '185px' }} alt="map" 
												src={pcn.attributes.map_url ? pcn.attributes.map_url :  `https://prod-linkstaff-uploads.s3.eu-west-2.amazonaws.com/building.png`} />
									<h5 className="m-0 family-poppins-semibold text-truncate">{pcn.attributes.name}</h5>
								</div>
							</div>
						</div>
						))
					}
					{!isFetchingMyPcn && pcn && pcn.length === 0 && (
						<div className="col-12 mb-5">
							<div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No Map found</div>
						</div>
					)}
					{isFetchingMyPcn && (
						<div className="col-12 mb-5"><Loader /></div>
					)}
				</div>
			</div>
			
			{selectedMap && Object.keys(selectedMap).length > 0 && (
				<div className="d-flex my-5 justify-content-center">
					<div className="container-fluid p-0">
						<h4 className="text-center family-poppins-semibold">{selectedMap.attributes.name}</h4>
						<div className="section-3">
							<div className="row my-md-5 my-4">	
								{selectedMap.myPcnData.map((data, index) => (
									<div className="col-lg-3 col-md-4 mb-4" key={index} onClick={() => this.getSelectedFederations(data)}>
											<Link
												to={`/user/network-details/${data.id}`}
												className="link-hover-text"
											>
												<p className={
														`${
															selectedFederation &&
															Object.keys(selectedFederation).length > 0 &&
															selectedFederation.id === data.id ? 'active-col' : ''
														} mb-0 rcorners3 font-18 family-poppins-semibold d-flex align-items-center justify-content-center cursor-pointer`
													}>
													{data.attributes.name}
												</p>
											</Link>
									</div>
								))}
                {selectedMap && selectedMap.myPcnData && selectedMap.myPcnData.length === 0 && (
                  <div className="col-12">
                    <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No practice found</div>
                  </div>
                )}
							</div>
						</div>
					</div>
				</div>
			)}

		</main>
		<UserFooter />
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
MyPcnList.propTypes = {
//   changeSingleMap: func.isRequired,
  federations: array.isRequired,
  getFederations: func.isRequired,
  getPcn: func.isRequired,
  getSingleMyPcn: func.isRequired,
  networksAndPractices: array.isRequired,
  pcn: func.isRequired,
  pcnIndexsAndTasks: func.isRequired,
  isFetchingMyPcn: bool.isRequired,
  isFetchingNetworks: bool.isRequired,
  page: number.isRequired,
  selectedFederation: object.isRequired,
  selectedMap: object.isRequired,
  totalPages: number.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  pcn: getMyPcnListing(),
  networksAndPractices: getNetworksAndPractices(),
  selectedMap: getSingleSelectedMap(),
  isFetchingMyPcn: getIsFetchingMyPcn(),
  isFetchingNetworks: getIsFetchingNetworks(),
  selectedFederation: getSelectedFederation(),
  federations: getFederationsList(),
});

export default connect(
  mapStateToProps,
  {
	getPcn,
	getFederations,
	getSingleMyPcn,
	changeSingleMap,
  }
)(withRouter(MyPcnList));
