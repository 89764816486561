import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import { func, bool, object, number } from "prop-types";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { showSuccessMsg, showErrorMsg } from 'utils/notification';
import * as Yup from 'yup';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import { 
  addListPolling, 
  getListPolling,
  editPollingList,
} from '../../modules/polling/actions';

// import { } from '../../modules/polling/selectors';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const AddEditPollingSchema = Yup.object().shape({
  question: Yup.string()
    .required('Required'),
  optionValue: Yup.array()
    .of(
      Yup.object().shape({
        option: Yup.string()
        .required('Required.')
      }),
    )
    .required('Required'),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      id: `${item.id}`,
      label: `${item.name}`,
      value: `${item.name}`
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0 mb-2"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if(field.name === 'eduInstId'){
          form.setFieldValue('eduInstId', value.value)
        }
      }}
      value={field.value ? {id: field.id, label: field.value, value: field.value} : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class AddEditPolling extends React.Component {
	getFormikInitVal = () => {
    const { polling, mode } = this.props;
    if(mode === 'Edit'){
      const {
        attributes,
        option        
      } = polling || {};
      return {
        question: attributes.question,
        optionValue: option
      }
    }
    else{
      return {
        question: "",
        optionValue: [
          {option: ""},
          {option: ""},
        ]
      }
    }
  };

  getUserID = value => {
    const { users } = this.props;
    const user = users.filter(user => `${user.name}` == value)
    if (user && user.length > 0) {
      return user[0].id;
    }

    return "";
  };

	addEditPollingList = (values, { setSubmitting, resetForm }) => {
    const { addListPolling, editPollingList, getListPolling, mode, polling } = this.props;
    if(values.optionValue.length < 2){
      showErrorMsg("Min Length is 2")
      setSubmitting(false);
    }
    else if(values.optionValue.length > 4){
      showErrorMsg("Max Length is 4")
      setSubmitting(false);
    }
    else if(values.optionValue.length >= 2 && values.optionValue.length <= 4){
      let params = {
        question: values.question,
        options: values.optionValue
      };
      if(mode === 'Edit'){
        editPollingList(params, polling.id).then(res => {
          const { message } = res;
          getListPolling();
          showSuccessMsg(message);
          setSubmitting(false);
          this.props.onClose();
        }, err => {
          setSubmitting(false);
        });
      }
      else{
        addListPolling(params).then(res => {
          const { message } = res;
          getListPolling();
          showSuccessMsg(message);
          setSubmitting(false);
          this.props.onClose();
        }, err => {
          setSubmitting(false);
        });
      }
    }
  };

	render() {
		const {
			isVisible,
			isUploadingImage,
      onClose,
      users,
      mode
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white"> {mode} Polling</Modal.Title>
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={AddEditPollingSchema}
						onSubmit={this.addEditPollingList}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form pt-2">
								<Modal.Body>
										<div className="form-group">
											<label className="color-grey pl-3">Question</label>
											<Field type="text" name="question" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="question" component="div" className="error-msg pl-3 mt-2" />
										</div>

                    <FieldArray
                      name="optionValue"
                      render={arrayHelpers => (
                        values.optionValue && values.optionValue.length > 0 ? (
                          values.optionValue.map((optionValue, index) => (
                            <div className="row" key={index}>
                              <div className="col-lg-9">
                                <div className="row">
                                  <div className="col-lg-12 mb-3">
                                    <div className="form-group">
                                      <label htmlFor="academic-level" className="color-grey pl-3">Option</label>
                                      <Field type="text" name={`optionValue[${index}].option`} className="form-control" placeholder="Option" />
                                    </div>
                                      <ErrorMessage name={`optionValue[${index}].option`} component="div" className="error-msg mt-3 ml-3" />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 text-right pt-md-4 mt-md-3 mb-5">
                                <span className="mr-2">
                                  <button type="button" className="btn btn-danger lS-btn-red-style btn-sm px-2" onClick={() => arrayHelpers.remove(index)} >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                </span>
                                <span className="ml-1">
                                  <button type="button" className="btn btn-primary lS-btn-blue-style btn-sm mr-1 px-2" onClick={() => arrayHelpers.push({
                                    option: ''
                                  }) } >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </span>
                              </div>
                            </div>
                          ))) : (
                            <div className="d-flex flex-fill w-100 justify-content-center">
                              <button type="button" className="btn btn-primary lS-btn-blue font-20 ml-3" 
                              onClick={() => arrayHelpers.push({
                                option: ''
                              })}
                              >
                                Add Option
                              </button>
                            </div>
                          ))}
                    />

								</Modal.Body>
								<Modal.Footer className="border-0">
									<div className="row pb-3 w-100">
										<div className="col-md-6">
											<button
												disabled={isSubmitting || isUploadingImage}
												className="btn btn-secondary w-100"
												onClick={() => {
													onClose();
												}}
											>
												Cancel
											</button>
										</div>
										<div className="col-md-6">
											<button type="submit" disabled={isSubmitting || isUploadingImage} className="btn btn-primary lS-btn-blue-style w-100">
												{
													isSubmitting && (
														<span className="spinner-border align-middle spinner-border-sm mr-2"></span>
												)}
												Save
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
AddEditPolling.propTypes = {
	// bookLetID: number.isRequired,
	// bookLetIndexID: number.isRequired,
	// addListPolling: func.isRequired,
	// isVisible: bool.isRequired,
	// onClose: func.isRequired,
	// singleTask: object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  
});

export default connect(
  mapStateToProps,
  {
    editPollingList,
		addListPolling,
		getListPolling,
  }
)(AddEditPolling);
