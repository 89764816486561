export const ADD_WELL_BEING =
  'src/modules/wellBeingPost/ADD_WELL_BEING';
export const GET_LIST_OF_TRAININGS =
  'src/modules/wellBeingPost/GET_LIST_OF_TRAININGS';
export const UPDATE_TRAININGS =
  'src/modules/wellBeingPost/UPDATE_TRAININGS';
export const UPLOAD_IMAGE =
  'src/modules/wellBeingPost/UPLOAD_IMAGE';
export const RESET_FILTERS =
  'src/modules/wellBeingPost/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/wellBeingPost/APPLY_PAGINATION';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/wellBeingPost/CANCEL_ALL_API_REQUESTS';
