import React from "react";
import { func, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import { getFormattedDate } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
    getPlacementMentors,
    updatePlacementMentorStatus,
} from '../../modules/placements/actions';
import {
    getPlacementMentorsListing,
    getIsFetchingPlacementMentors,
} from '../../modules/placements/selectors';

import Loader from 'components/Loader';

class PlacementApplicants extends React.Component {
    componentWillMount() {
        const { placementId } = this.props.match.params;
        if (placementId && !isNaN(placementId)) {
            var params = {
                placement_id:placementId,
            };
            this.props.getPlacementMentors(params);
        }
    }

    updateApplicantStatus = (applicantId, status) =>{
        if(this.props.match.params.placementId){
            let params={
                placement_id: this.props.match.params.placementId,
                applicant_id: applicantId,
                status: status
            }
            this.props.updatePlacementMentorStatus(params).then(res => {
                const { message } = res;
                var innerParams = {
                    'placement_id': this.props.match.params.placementId,
                };
                this.props.getPlacementMentors(innerParams);
                showSuccessMsg(message);
            });
        }
    }

    render() {
        const {
            isFetchingPlacementApplicants,
            placementApplicants,
          } = this.props;
        return (
            <>
            <UserHeader />
            {/* Page content */}
            
            <main class="m-0 px-3 px-md-5 mt-md-5 mt-4 mb-5">
                <div class="card border-0 page-title-bar">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h4 class="family-poppins-semibold my-2">Placement Mentors</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border-0 lS-card-shadow lS-card-radius mt-md-5 mt-4" id="myTabContent">
                    <div className="card-body" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <div className="table-responsive ls-table">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" className="pb-4 text-left" width="100"> Image </th>
                                        <th scope="col" className="pb-4" width="160"> Name </th>
                                        <th scope="col" className="pb-4" width="300"> E-mail </th>
                                        {/* <th scope="col" className="pb-4"> Organizer </th> */}
                                        <th scope="col" className="pb-4" width='120'> Status </th>
                                        <th scope="col" className="pb-4" width="150"> Date booked </th>
                                        <th scope="col" className="pb-4" width="260"> Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!isFetchingPlacementApplicants && placementApplicants && placementApplicants.length > 0 && placementApplicants.map((placementApplicant, index) => {
                                    const {profile_image_url, name, status, email, created_at, profile_identifier} = placementApplicant.attributes;
                                    return(
                                    <tr key={index}>
                                    
                                        <td className="py-4 text-left"> 
                                            <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                                                <img src={profile_image_url} alt="..." className="img-object-fit" />
                                            </div>
                                        </td>
                                        <td className="py-4">{name} </td>
                                        <td className="py-4"> {email} </td>
                                        {/* <td className="py-4"> CLH </td> */}
                                        <td className="py-4 text-capitalize"> {status} </td>
                                        <td className="py-4"> {getFormattedDate(created_at)} </td>
                                        <td className="py-4">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <a rel="noopener noreferrer" 
                                                    className="btn btn-primary font-13 lS-btn-sm lS-btn-blue-style mr-2" 
                                                    href= {process.env.REACT_APP_WEB_BASE_URL+"/public/passport/"  + profile_identifier}
                                                    target="_blank">
                                                        View
                                                </a>
                                                <button
                                                    disabled={status === 'accepted' || status === 'cancelled' }
                                                    className="btn btn-primary font-13 lS-btn-sm lS-btn-green-style mr-2"
                                                    onClick={() => this.updateApplicantStatus(placementApplicant.id, 'accepted')}>
                                                    Accept
                                                </button>
                                                <button
                                                    disabled={status === 'rejected'  || status === 'cancelled'}
                                                    className="btn btn-primary font-13 lS-btn-sm lS-btn-red-style"
                                                    onClick={() => this.updateApplicantStatus(placementApplicant.id, 'rejected')}>
                                                    Reject
                                                </button>                                        
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                }
                                )}                                
                                </tbody>                                
                            </table>
                            {!isFetchingPlacementApplicants && placementApplicants && placementApplicants.length === 0 && (
                                <div className="text-center bg-white py-5 px-3 radius-10 family-poppins-medium">
                                    No mentor found for this placement
                                </div>
                            )}
                            {isFetchingPlacementApplicants && (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <UserFooter />
            </>
        );
    }
}

/**
 *  Define component PropTypes
 */
PlacementApplicants.propTypes = {
    placementApplicants: array.isRequired,
    isFetchingPlacementApplicants: bool.isRequired,
    getPlacementMentors: func.isRequired,
  };

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    placementApplicants: getPlacementMentorsListing(),
    isFetchingPlacementApplicants: getIsFetchingPlacementMentors(),
  });

export default connect(
    mapStateToProps,
    {
        getPlacementMentors,
        updatePlacementMentorStatus
    }
  )(withRouter(PlacementApplicants));