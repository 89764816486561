import React from "react";
import { func, array, bool, number} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import ReactPaginate from "react-paginate";
import AssignAssorSuper from "components/assignAssorSuper";
import Loader from 'components/Loader';
import UserFooter from 'components/Footers/UserFooter';
/**
 *  Import other dependencies
 */
import { getSubmittedBooklets, getBooklets , applyPagination, applyFilters, applyFiltersSubmittedBooklets, getUsersListing  } from '../../../modules/resources/actions';
import { getSingleBookLetsListing, getBookLetsListing, getIsFetchingResources, getTotalPages, getResourcesPage  } from '../../../modules/resources/selectors';


class Resources extends React.Component {
  state={
    search: '',
    examinerType: 'assessor',
    isVisibleAssignAssor: false,
  }

  componentWillMount() {
    const id  = this.props.match.params.id;
    this.props.getSubmittedBooklets('assessor', id)
    // this.props.getUsersListing();
  }

   handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      // bookLetIndexID: bookletIndex,
      bookLetID: bookletIndex,
    });
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  searchBooklets = () => {
    const {search} = this.state;
    this.props.applyFiltersSubmittedBooklets(search, 'assessor', this.props.match.params.id);
  };

  loadPage = page => {
    this.props.applyPagination(page, this.props.match.params.categoryId);
  }

  render() {
    const { bookLets, isFetchingBooklets, totalPages, page } = this.props;
    const { search, isVisibleAssignAssor, bookLetID } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5">
          <div className="container-fluid mt-5 p-0">
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-6">
                    <h4 className="family-poppins-semibold mb-0">Competencies</h4>
                  </div>
                  <div className="col-lg-4 col-md-6 input-group mt-3 mt-md-0" id="search-form">
                    <div className="input-group search-bar" >
                      <span className="fa fa-search fa-xs serach-icon" />
                      <input type="text" name="com_field_search" className="form-control search-input"
                        onChange={event => this.handleQueryChange(event.target.value) }
                        value={search} placeholder="Search here..."/>
                      <button type="submit" onClick={this.searchBooklets}
                              className="com_searhc_btn btn btn-primary lS-btn-blue-style"
                              defaultValue="Search" >
                        Search </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* detail tiles */}
            <div className="container-fluid p-0">
              <div className="row mt-5 mx-lg-n4">
                {!isFetchingBooklets && bookLets && bookLets.length > 0 && bookLets.map(book => (
                <div className="col-xl-4 col-lg-6 px-lg-4">
                  <div className="card detail-tile lS-card-radius border-0 lS-card-shadow mb-5">
                    <div className="card-body d-flex">
                      <div className="tile-icon radius-10 d-inline-flex align-items-center justify-content-center">
                        <img src={require("assets/images/competencies/pdf-icon.png")} alt="icon" />
                      </div>
                      <div className="tile-text d-flex flex-column justify-content-between pl-4 pt-3 pb-2 min-width0">
                        <h4 className="font-normal family-poppins-medium text-truncate" data-toggle="tooltip" 
                          title={book.attributes.booklet_name}>{book.attributes.booklet_name}</h4>
                        <div className="d-flex">
                        
                          <Link to={`/user/assessor/user-listing/${book.attributes.user_id}/booklet/${book.id}`} 
                            className="btn btn-primary lS-btn-icon lS-btn-green-style mr-2">
                            <i className="fas fa-eye" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
                {!isFetchingBooklets && bookLets && bookLets.length === 0 && (
                  <div className="col-12 px-lg-4">
                    <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No booklets found</div>
                  </div>
                )}
                {isFetchingBooklets && (
                  <div className="col-12 px-lg-4"><Loader /></div>
                )}
              </div>
            </div>
          </div>
          <div aria-label="Page navigation example">
                  <ReactPaginate
                    containerClassName={'pagination justify-content-end mt-5'}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    forcePage={page - 1}
                    onPageChange={selectedItem => {
                      if (isFetchingBooklets) return;
                      this.loadPage(selectedItem.selected + 1);
                    }}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
        </main>
        <UserFooter />
        {isVisibleAssignAssor && (
          <AssignAssorSuper
            isVisible={isVisibleAssignAssor}
            onClose={() => {
              this.setState({
                isVisibleAssignAssor: false
              });
            }}
            bookLetID={Number(bookLetID)}
            // bookLetIndexID={Number(bookLetIndexID)}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Resources.propTypes = {
  applyFilters: func.isRequired,
  applyFiltersSubmittedBooklets: func.isRequired,
  applyPagination: func.isRequired,
  bookLets: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  getBooklets: func.isRequired,
  getSubmittedBooklets: func.isRequired,
  isFetchingBooklets: bool.isRequired,
    getUsersListing: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  bookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
  page: getResourcesPage(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getBooklets,
    getSubmittedBooklets,
    applyPagination,
    applyFilters,
    applyFiltersSubmittedBooklets,
    getUsersListing
  }
)(withRouter(Resources));
