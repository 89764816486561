import React from "react";
import UserHeader from 'components/Headers/UserHeader';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { groups, regions } from './filters';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  uploadImage,
  addPostEvent,
  updatePostEvent,
  getEvents
} from '../../modules/eventPost/actions';
import {
  getIsUploadingImage,
  getIsFetchingEvents,
  getEventsListing
} from '../../modules/eventPost/selectors';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const addEditEventSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  // organisation: Yup.string()
  //   .required('Required'),
  // location: Yup.string()
  //   .required('Required'),
  // // region: Yup.string()
  // //   .required('Required'),
  // // group: Yup.string()
  // //   .required('Required'),
  // website_url: Yup.string()
  //   .required('Required'),
  // start_date: Yup.string()
  //   .required('Required'),
  // end_date: Yup.string()
  //   .required('Required'),
  // event_fee: Yup.string()
  //   .required('Required'),
  // description: Yup.string()
  //   .required('Required'),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        field.name === 'region' ?
          form.setFieldValue('region', value.value) :
          form.setFieldValue('group', value.value)
      }}
      value={field.value ? formattedArray([field.value]) : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class PostEvents extends React.Component {
  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getEvents(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/events');
        }
      });
    }
  }

  getFormikInitVal = () => {
    const { event } = this.props;
    const { mode } = this.state;
    console.log(1);
    if (mode === 'edit') {
      const {
        title,
        organisation,
        location,
        region,
        group,
        website_url,
        start_date,
        end_date,
        fee,
        description,
        image_url,
      } = event.attributes || {};

      return {
        title: title,
        organisation: organisation,
        location: location,
        region: region,
        group: group,
        website_url: website_url,
        event_fee: fee,
        description: description,
        imageUrl: image_url ? image_url : '',
        start_date: start_date ? moment(start_date): null,
        end_date: end_date ? moment(end_date): null,
      }
    }
    return {
      title: "",
      organisation: null,
      location: null,
      region: null,
      group: null,
      website_url: null,
      start_date: null,
      end_date: null,
      event_fee: null,
      description: null,
      image_url: null,
    }
  };

  handleJobImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("image", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('imageUrl', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <div>
            <label className="color-grey pl-3">Image Preview</label>
          </div>
          <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <label htmlFor="events-upload" className="color-grey pl-3">Upload File</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="events-upload" onChange={event => this.handleJobImage(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="events-upload">Choose File</label>
				</div>
      </>
    )
  };

  postEvent = (values, { setSubmitting, resetForm }) => {
    console.log(1);
    const { addPostEvent, updatePostEvent, history } = this.props;
    const { mode } = this.state;
    var params = {
      title: values.title,
      organisation: values.organisation,
      location: values.location,
      region: values.region ? values.region: null,
      group: values.group ? values.group: null,
      website_url: values.website_url,
      start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : null,
      end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : null,
      fee: values.event_fee,
      description: values.description,
      chargeable: values.event_fee ? 1 : 0,
    };

    if (values.imageUrl) {
      params.image_url = values.imageUrl;
    }

    if( mode === 'edit' ){
      updatePostEvent(params, this.props.match.params.id).then(res => {
        showSuccessMsg("Event successfully updated");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/events');
        }
      }, err => {
        setSubmitting(false);
      });
    }
    if( mode === 'add' ){
      console.log(2);
      addPostEvent(params).then(res => {
        showSuccessMsg("Event successfully added");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/events');
        }
      }, err => {
        setSubmitting(false);
      });
    }
  };

  render() {
    const {
      isUploadingImage,
      isFetchingEvent
    } = this.props;

    const { mode } = this.state;

    return (
      <>
        <UserHeader />        
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 pb-5 mt-md-5 mt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="family-poppins-semibold">{mode === 'edit' ? 'Edit' : 'Post'} Event </h5>
                    <p className="mb-0 font-14 color-grey"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded-lg">
              {isFetchingEvent && (
                <Loader />
              )}
              
              <div className="card-body">
              {!isFetchingEvent && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditEventSchema}
                  onSubmit={this.postEvent}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="ls-form pt-2">
                {/* <form className="ls-form pt-2" action encType="multipart/form-data" method="post"> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="title" className="color-grey pl-3">Title</label>
                        <Field type="text" name="title" className="form-control form-control-lg rounded-lg" id="title" placeholder="Enter Title" />
                        <ErrorMessage name="title" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="organisation" className="color-grey pl-3">Organisation</label>
                        <Field type="text" name="organisation" className="form-control form-control-lg rounded-lg" id="organisation" placeholder="Enter Organisation" />
                        <ErrorMessage name="organisation" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="location" className="color-grey pl-3">Location</label>
                        <Field type="text" name="location" className="form-control form-control-lg rounded-lg" id="location" placeholder="Enter Location" />
                        <ErrorMessage name="location" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="website_url" className="color-grey pl-3">Website URL</label>
                        <Field type="text" name="website_url" className="form-control form-control-lg rounded-lg" id="website_url" placeholder="Enter Link URL" />
                        <ErrorMessage name="website_url" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="region" className="color-grey pl-3">STP</label>
                        <Field as="select" name="region" className="form-control form-control-lg rounded-lg" id="occupation" component={formSelect} options={regions} placeholder="Select STP" />
                        <ErrorMessage name="region" component="div" className="error-msg" />
                      </div>
                    </div>  
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="group" className="color-grey pl-3">Group</label>
                        <Field as="select" name="group" className="form-control form-control-lg rounded-lg" id="group" placeholder="Select Group" component={formSelect} options={groups} />
                        <ErrorMessage name="group" component="div" className="error-msg" />
                      </div>
                    </div>  
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            <label htmlFor="start-date" className="color-grey pl-3">Start Date</label>
                            <Datetime
                              inputProps={{placeholder: 'Select Start Date'}}
                              renderInput={ this.renderCustomInput }
                              closeOnSelect={true}
                              isValidDate={ valid }
                              onChange={start_date => {
                                if (typeof start_date !== "object") return;
                                setFieldValue('start_date', start_date)
                              }}
                              value={values['start_date']}
                            />
                            <ErrorMessage name="start_date" component="div" className="error-msg" />
                          </div>
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                          <label htmlFor="event_fee" className="color-grey pl-3">Event Fee</label>
                            <Field type="text"  name="event_fee" className="form-control form-control-lg rounded-lg" id="event_fee" placeholder="Enter URL" />
                            <ErrorMessage name="event_fee" component="div" className="error-msg" />
                          
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            <label htmlFor="end-date" className="color-grey pl-3">End Date</label>
                              <Datetime
                                inputProps={{placeholder: 'Select End Date'}}
                                renderInput={ this.renderCustomInput }
                                closeOnSelect={true}
                                isValidDate={ valid }
                                onChange={end_date => {
                                  if (typeof end_date !== "object") return;
                                  setFieldValue('end_date', end_date)
                                }}
                                value={values['end_date']}
                              />
                              <ErrorMessage name="end_date" component="div" className="error-msg" />
                          </div>
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            {!isUploadingImage && (
                              <Field type="file" component={this.fileUpload} name="imageUrl" className="custom-file-input" id="file" />
                            )}
                            {isUploadingImage && (
                              <Loader />
                            )}
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="form-group d-flex flex-column ls-height-100 pb-5">
                          <label htmlFor="description" className="color-grey pl-3">Description</label>
                          <Field as="textarea" name="description" rows={3} className="flex-fill form-control form-control-lg rounded-lg" id="description" placeholder="Enter Description" defaultValue={""} />
                          <ErrorMessage name="description" component="div" className="error-msg" />
                        </div>
                      </div>
                  </div>
                  <div className="text-md-right text-center">
                    <Link to='/user/events' className="ls-btn-link btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</Link>
                    <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                      {
                        isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Submit
                    </button>
                  </div>
                {/* </form> */}
                 </Form>
                 )}
               </Formik>
               )}
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>
      </>
    );
  };
};

/**
 *  Define component PropTypes
 */
PostEvents.propTypes = {
  addPostEvent: func.isRequired,
  isFetchingEvent: bool.isRequired,
  isUploadingImage: bool.isRequired,
  event: object.isRequired,
  updatePostEvent: func.isRequired,
  uploadImage: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
  isFetchingEvent: getIsFetchingEvents(),
  event: getEventsListing()
});

export default connect(
  mapStateToProps,
  {
    uploadImage,
    addPostEvent,
    updatePostEvent,
    getEvents,
  }
)(withRouter(PostEvents));