/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import { 
  ADD_LIST_POLLING,  
  GET_LIST_POLLING,
  APPLY_PAGINATION,
  GET_INSTITUTION_NAME,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 10,
  page:1,
  totalPage:0,
  isPollingList: [],
  isInstitutionName: [],
  isEmploymentList: [],
  isEducationList: [],
  isUpdatingProfile: false,
  isFetchingPollingList: false,
  isFetchingInsitutionName: false,
  isFetchingEmployment: false,
  isFetchingEducation: false,
  isUploadingImage: false,
});

/**
 *  Define the reducer with actions
 */
function pollingReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_LIST_POLLING:
      return fromJS({
        ...state.toJS(),
        isUpdatingProfile: true,
      })

    case success(ADD_LIST_POLLING):
    case failure(ADD_LIST_POLLING):
      return fromJS({
        ...state.toJS(),
        isUpdatingProfile: false,
      })
    
    case GET_LIST_POLLING:
      return fromJS({
        ...state.toJS(),
        isPollingList: [],
        isFetchingPollingList: true,
      })

    case success(GET_LIST_POLLING):
      let finialResult=[];
      const data = action.payload.data.data;
      const includes = action.payload.data.included;
       // Step: 1 - filter all indexes
       data.map(indexes => {
        finialResult = filterData(indexes.relationships.options.data, includes);
        indexes.option = finialResult;
        delete indexes.relationships;
        return indexes;
      });

      const { total_pages } = action.payload.data.meta.pagination;
      
      return fromJS({
        ...state.toJS(),
        isPollingList: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingPollingList: false,
      })
    case failure(GET_LIST_POLLING):
      return fromJS({
        ...state.toJS(),
        isPollingList: [],
        isFetchingPollingList: false,
      })

    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })
  
    
    default:
      return state
  }
}

const filterData = (data, includes) => {
  return data.map( data => includes.find(value => value.type === data.type && value.id === data.id))
};


/**
 *  Export the reducer
 */
export default pollingReducer
