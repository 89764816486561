/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_APPRAISALS,
  GET_SINGLE_APPRAISAL,
  ADD_APPRAISALS,
  GET_SUPERVISOR,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Appraisals
 */
export function getAppraisals(isSupervisor) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('appraisals').toJS();
    const params ={
      page,
      limit,
	};
	if(isSupervisor) {
		params.listing_type = 'supervisor';
	}
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getAppraisalsBegin(
          API_URLS.APPRAISALS.GET_APPRAISALS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getAppraisalsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_APPRAISALS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Appraisals
 */
export function getSingleAppraisal(id, isSupervisor) {
	return async (dispatch, getState) => {
    var params = {};
    if(isSupervisor) {
      params =  {
        listing_type: 'supervisor',
      }
    }
	  const source = axios.CancelToken.source();
	  cancelApiRequests.push(source);
	  try {
      const response = await dispatch(
        getSingleAppraisalBegin(
          API_URLS.APPRAISALS.GET_APPRAISALS + `/${id}`,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }
    
      throw response;
	  } catch (error) {
		  throw error.response;
	  }
	};
};
  
export const getSingleAppraisalBegin = (API_URL, params, source) => ({
	type: GET_SINGLE_APPRAISAL,
	payload: {
	  request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
	  }
	}
});

/**
 *  Get List of supervisor
 */
export function getSupervisor() {
	return async (dispatch, getState) => {
    const params ={
      limit: 100000
    };
	  const source = axios.CancelToken.source();
	  cancelApiRequests.push(source);
	  try {
		const response = await dispatch(
		  getSupervisorBegin(
      API_URLS.APPRAISALS.GET_SUPERVISOR,
      params,
			source
		  )
		);
		if (response.payload) {
		  const { data } = response.payload;
		  return data;
		}
  
		throw response;
	  } catch (error) {
		throw error.response;
	  }
	};
};
  
export const getSupervisorBegin = (API_URL, params, source) => ({
	type: GET_SUPERVISOR,
	payload: {
	  request: {
    url: API_URL,
    params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    },
		method: 'get',
		cancelToken: source.token
	  }
	}
});

/**
 *  Add List of Users
 */
export function addPostAppraisals(params, isSupervisor, id) {
	return async dispatch => {
	  const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    var data = {
      listing_type: 'supervisor',
    };
	  try {
      const response = await dispatch(
        isSupervisor ?
        updatePostAppraisalsBegin(
          API_URLS.APPRAISALS.POST_APPRAISALS + `/${id}`,
          params,
          data,
          source
        ):
        addPostAppraisalsBegin(
          API_URLS.APPRAISALS.POST_APPRAISALS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }
    
      throw response;
	  } catch (error) {
		  throw error.response;
	  }
	};
};

export const updatePostAppraisalsBegin = (API_URL, params, data, source) => ({
  type: ADD_APPRAISALS,
  payload: {
    request: {
      url: API_URL,
      method: 'put',
      params: data,
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

export const addPostAppraisalsBegin = (API_URL, params, source) => ({
  type: ADD_APPRAISALS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getAppraisals());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


