const location = [
  'East London',
  'North West London',
  'South West London',
  'North London',
  'South East London',
];

const jobType = [
  'Part Time',
  'Full Time',
  'Job share',
  'Secondment',
  'Deployment',
  'Voluntary',
  'Apprenticeship',
  'Self-employed',
  'Locum',
  'Bank',
];

const minMaxSalary = [
  "£10,000 per annum",
  "£20,000 per annum",
  "£30,000 per annum",
  "£40,000 per annum",
  "£50,000 per annum",
  "£60,000 per annum",
  "£70,000 per annum",
  "£80,000 per annum",
  "£90,000 per annum",
  "£100,000 per annum",
  "£5 per hour",
  "£10 per hour",
  "£20 per hour",
  "£30 per hour",
  "£40 per hour",
  "£50 per hour",
  "£60 per hour",
  "£70 per hour",
  "£80 per hour",
];

export {
  location,
  jobType,
  minMaxSalary,
};
