/**
 *  Import action creator constants & dependencies
 */
import {
  GET_PRACTICE_LOG,
  SHOW_PRACTICE_LOG,
  DELETE_PRACTICE_LOG,
  UPDATE_PRACTICE_LOG,
  POST_PRACTICE_LOG,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];


/**
   *  Get List of feedback
   */
  export function getPracticeLog() {
    return async (dispatch) => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          getPracticeLogBegin(
            API_URLS.REVALIDATION.GET_PRACTICE_LIST,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }

        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };

  export const getPracticeLogBegin = (API_URL, source) => ({
    type: GET_PRACTICE_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'get',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

  /**
   *  Add reflective discussion
   */
  export function addPracticeLog(params) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          addPracticeLogBegin(
            API_URLS.REVALIDATION.POST_PRACTICE,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }

        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };

  export const addPracticeLogBegin = (API_URL, params, source) => ({
    type: POST_PRACTICE_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

/**
   *  Delete
   */
  export function deletePracticeLog(id) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          deletePracticeLogBegin(
            API_URLS.REVALIDATION.DELETE_PRACTICE + id,
            id,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }

        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };

  export const deletePracticeLogBegin = (API_URL, id, source) => ({
    type: DELETE_PRACTICE_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'delete',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    },
    id
  });

  /**
   *  update List of placements
   */
  export function updatePracticeLog(params, id) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          updatePracticeLogBegin(
            API_URLS.REVALIDATION.UPDATE_PRACTICE + id,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }

        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };

  export const updatePracticeLogBegin = (API_URL, params, source) => ({
    type: UPDATE_PRACTICE_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


