import React from "react";
import { func, object, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PieChart, Pie, Sector, AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

/**
 *  Import other dependencies
 */
import { getReportsData  } from '../../modules/reports/actions';
import { getReports, getIsFetchingReports  } from '../../modules/reports/selectors';

class Reports extends React.Component {
  state = {
    activeIndex: 0,
  };

  componentWillMount() {
    this.props.getReportsData();
  }

  renderActiveShape = (props) => {
    const {
      cx, cy, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload
    } = props;
  
    return (
      <g>
        <text x={cx} y={cy - 10} dy={6} textAnchor="middle" fill={'#000000'}>{payload.name}</text>
        <text x={cx} y={cy + 25} dy={8} fontSize="2rem" textAnchor="middle" fill={'#000000'}>{payload.value} %</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          paddingAngle={0}
          fill={fill}
        />
        {/* <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        /> */}
      </g>
    );
  };
  
  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { isFetchingReports } = this.props;
    const { 
      student,
      staff,
      trainings,
      placements,
      graph,
      reports,
    } = this.props.reports;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-5 mb-5">
          {/* Account Counts */}
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-3 mb-lg-0">
              <div className="card lS-card-shadow lS-card-radius border-0">
                <div className="card-body">
                  <div className="d-flex mb-3">
                    <div className="d-flex course-icon-col align-items-center justify-content-center lS-btn-blue-style rounded mr-3">
                      <i class="fas fa-graduation-cap"></i>
                    </div>
                    <div>
                      <h3 className="mb-0 family-poppins-semibold">{student || '0'}</h3>
                      <p className="mb-0 color-grey font-12">Students</p>
                    </div>
                  </div>
                  <ProgressBar now={60} className="custom-bar bar-blue" />
                  <div className="d-flex justify-content-between mt-2">
                    <p className="mb-0 color-grey font-12 mb-0">Previous Period</p>
                    <p className="mb-0 color-grey font-12 mb-0">75%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3 mb-lg-0">
              <div className="card lS-card-shadow lS-card-radius border-0">
                <div className="card-body">
                  <div className="d-flex mb-3">
                    <div className="d-flex course-icon-col align-items-center justify-content-center lS-btn-yellow-style color-white rounded mr-3">
                      <i class="fas fa-users"></i>
                    </div>
                    <div>
                      <h3 className="mb-0 family-poppins-semibold">{staff || '0'}</h3>
                      <p className="mb-0 color-grey font-12">staff</p>
                    </div>
                  </div>
                  <ProgressBar now={60} className="custom-bar bar-yellow" />
                  <div className="d-flex justify-content-between mt-2">
                    <p className="mb-0 color-grey font-12 mb-0">Previous Period</p>
                    <p className="mb-0 color-grey font-12 mb-0">75%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3 mb-md-0">
              <div className="card lS-card-shadow lS-card-radius border-0">
                <div className="card-body">
                  <div className="d-flex mb-3">
                    <div className="d-flex course-icon-col align-items-center justify-content-center lS-btn-green-style color-white rounded mr-3">
                      <i class="fas fa-book"></i>
                    </div>
                    <div>
                      <h3 className="mb-0 family-poppins-semibold">{trainings || '0'}</h3>
                      <p className="mb-0 color-grey font-12">Courses</p>
                    </div>
                  </div>
                  <ProgressBar now={60} className="custom-bar bar-green" />
                  <div className="d-flex justify-content-between mt-2">
                    <p className="mb-0 color-grey font-12 mb-0">Previous Period</p>
                    <p className="mb-0 color-grey font-12 mb-0">75%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3 mb-md-0">
              <div className="card lS-card-shadow lS-card-radius border-0">
                <div className="card-body">
                  <div className="d-flex mb-3">
                    <div className="d-flex course-icon-col align-items-center justify-content-center lS-btn-red-style color-white rounded mr-3">
                      <i class="fas fa-sitemap"></i>
                    </div>
                    <div>
                      <h3 className="mb-0 family-poppins-semibold">{placements || '0'}</h3>
                      <p className="mb-0 color-grey font-12">Placements</p>
                    </div>
                  </div>
                  <ProgressBar now={60} className="custom-bar bar-red" />
                  <div className="d-flex justify-content-between mt-2">
                    <p className="mb-0 color-grey font-12 mb-0">Previous Period</p>
                    <p className="mb-0 color-grey font-12 mb-0">75%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Graphs Col */}
          <div className="row mt-md-5 mt-4">
            <div className="col-lg-8 col-md-6">
              <div className="card lS-card-shadow lS-card-radius border-0 mb-md-5 mb-4 ls-height-100">
                <div className="card-body px-0 d-flex flex-column">
                  <div className="px-4 pt-2 pb-4 border-bottom ls-border-color">
                    <h5 className="m-0 family-poppins-semibold">Graph</h5>
                  </div>
                  {isFetchingReports && (
                    <Loader />
                  )}
                  <div className="pt-4 flex-fill d-flex align-items-center">
                    <div className="graph-col w-100">
                      {!isFetchingReports && graph && graph.length > 0 && (
                        <ResponsiveContainer height="100%">
                          <AreaChart
                            data={graph}
                            margin={{
                              top: 10, right: 30, left: 0, bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient id="staff" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#f7c339" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="rgb(247, 195, 57)" stopOpacity={0}/>
                              </linearGradient>

                              <linearGradient id="student" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#4ccafc" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="rgb(76, 202, 252)" stopOpacity={0}/>
                              </linearGradient>

                              <linearGradient id="courses" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#7ccc1f" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="rgb(124, 204, 31)" stopOpacity={0}/>
                              </linearGradient>

                              <linearGradient id="placements" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#fe434c" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="rgb(254, 67, 76)" stopOpacity={0}/>
                              </linearGradient>
                            </defs>

                            <XAxis dataKey="year" tickLine={false} axisLine={false} />
                            <YAxis tickLine={false} axisLine={false} />
                            <Tooltip />
                            <Area type="monotone" dataKey="staff" stackId="1" strokeWidth="5" strokeLinecap="round" stroke="#f8c339" fill="url(#staff)" />
                            <Area type="monotone" dataKey="student" stackId="1" strokeWidth="5" strokeLinecap="round" stroke="#4ccafc" fill="url(#student)" />
                            <Area type="monotone" dataKey="courses" stackId="1" strokeWidth="5" strokeLinecap="round" stroke="#7ccc1f" fill="url(#courses)" />
                            <Area type="monotone" dataKey="placements" stackId="1" strokeWidth="5" strokeLinecap="round" stroke="#fe434c" fill="url(#placements)" />
                          </AreaChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card lS-card-shadow lS-card-radius border-0 ls-height-100">
                <div className="card-body px-0">
                  <div className="px-4 border-bottom ls-border-color pt-2 pb-4">
                    <h5 className="m-0 family-poppins-semibold">Reports</h5>
                  </div>
                  {isFetchingReports && (
                    <Loader />
                  )}
                  {!isFetchingReports && (
                    <>
                      <div className="d-flex px-4 flex-fill flex-wrap justify-content-between align-items-center">
                        <PieChart width={300} height={300}>
                          <Pie
                            activeIndex={this.state.activeIndex}
                            activeShape={this.renderActiveShape}
                            data={reports || []}
                            cx={150}
                            cy={150}
                            innerRadius={90}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={this.onPieEnter}
                          />
                        </PieChart>

                        <div className="d-flex flex-fill flex-column justify-content-center">
                          <div className="d-flex flex-fill align-items-center justify-content-start">
                            <svg width="20" height="20">
                              <rect x="0" y="0" rx="5" ry="5" width="20" height="20"
                              style={{
                                fill:'#4ccafc',
                              }} />
                            </svg>
                            <h6 className="ml-3 family-poppins-semibold font-16"> Students </h6>
                          </div>

                          <div className="d-flex flex-fill justify-content-start align-items-center mt-3">
                            <svg width="20" height="20">
                              <rect x="0" y="0" rx="5" ry="5" width="20" height="20"
                              style={{
                                fill:'#f6d925',
                              }} />
                            </svg>
                            <h6 className="ml-3 family-poppins-semibold font-16"> Staff </h6>
                          </div>

                          <div className="d-flex flex-fill justify-content-start align-items-center mt-3">
                            <svg width="20" height="20">
                              <rect x="0" y="0" rx="5" ry="5" width="20" height="20"
                              style={{
                                fill:'#7ccc1f',
                              }} />
                            </svg>
                            <h6 className="ml-3 family-poppins-semibold font-16"> Courses </h6>
                          </div>

                          <div className="d-flex flex-fill justify-content-start align-items-center mt-3">
                            <svg width="20" height="20">
                              <rect x="0" y="0" rx="5" ry="5" width="20" height="20"
                              style={{
                                fill:'#fe434d',
                              }} />
                            </svg>
                            <h6 className="ml-3 family-poppins-semibold font-16"> Placements </h6>
                          </div>

                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <UserFooter />
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Reports.propTypes = {
  reports: object.isRequired,
  getReportsData: func.isRequired,
  isFetchingReports: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  reports: getReports(),
  isFetchingReports: getIsFetchingReports(),
});

export default connect(
  mapStateToProps,
  {
    getReportsData
  }
)(withRouter(Reports));
