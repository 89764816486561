/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_TRAININGS,
  GET_LIST_OF_TRAININGS_APPLICANTS,
  GET_LIST_OF_TRAININGS_APPLIED,
  POST_TRANINING_FEEDBACK,
  POST_TRANINING_RATING,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_FILTERS,
  RESET_FILTERS,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 9,
  page: 1,
  totalPage: 0,
  trainings: [],
  trainingApplicants: [],
  trainingsApplied: [],
  totalTrainingsAppliedPage:0,
  trainingAppliedPage:1,
  search: '',
  selectedOccupations: [],
  selectedTrainingLevels: [],
  selectedUniversity:[],
  isFetchingTrainings: true,
  isFetchingTrainingApplicants: true,
  isFetchingTrainingApplied: true,
  selectedTypes: []
});

/**
 *  Define the reducer with actions
 */
function trainingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_TRAININGS:
      return fromJS({
        ...state.toJS(),
        isFetchingTrainings: true,
      })

    case success(GET_LIST_OF_TRAININGS):
      const { data } = action.payload.data;
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        trainings: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingTrainings: false,
      })

    case failure(GET_LIST_OF_TRAININGS):
      return fromJS({
        ...state.toJS(),
        isFetchingTrainings: false,
      })
    
    case GET_LIST_OF_TRAININGS_APPLIED:
      return fromJS({
        ...state.toJS(),
        isFetchingTrainingApplied: true,
      })

    case success(GET_LIST_OF_TRAININGS_APPLIED):
      const  trainingAppliedData  = action.payload.data.data;
      const  training_applied_total_pages  = action.payload.data.meta.pagination.total_pages;      
  
      return fromJS({
        ...state.toJS(),
        trainingsApplied: trainingAppliedData && trainingAppliedData.length > 0 ? trainingAppliedData : [],
        totalTrainingsAppliedPage: training_applied_total_pages,
        isFetchingTrainingApplied: false,
      })

    case failure(GET_LIST_OF_TRAININGS_APPLIED):
      return fromJS({
        ...state.toJS(),
        isFetchingTrainingApplied: false,
      })

    case GET_LIST_OF_TRAININGS_APPLICANTS:
      return fromJS({
        ...state.toJS(),
        isFetchingTrainingApplicants: true,
      })

    case success(GET_LIST_OF_TRAININGS_APPLICANTS):
      const  trainingApplicantsData  = action.payload.data.data;
      const  training_applicants_total_pages  = action.payload.data.meta.pagination;      
      return fromJS({
        ...state.toJS(),
        trainingApplicants: trainingApplicantsData && trainingApplicantsData.length > 0 ? trainingApplicantsData : [],
        totalPage: training_applicants_total_pages,
        isFetchingTrainingApplicants: false,
      })

    case failure(GET_LIST_OF_TRAININGS_APPLICANTS):
      return fromJS({
        ...state.toJS(),
        isFetchingTrainingApplicants: false,
      })
    
    case success(POST_TRANINING_FEEDBACK):
      const trainingsFeed = state.get('trainingsApplied').toJS();
      var indexFeedback = -1;
      trainingsFeed.filter(
        (training, currentIndex) => {
          if(Number(training.id) === Number(action.meta.previousAction.params.training_id)) {
            indexFeedback = currentIndex;
          }
          return training;
        }
      );
      trainingsFeed[indexFeedback].attributes.feedback = action.meta.previousAction.params.feedback;
      trainingsFeed[indexFeedback].attributes.my_rating = action.meta.previousAction.ratingParam.rating_value;
      trainingsFeed[indexFeedback].attributes.average_rating = action.meta.previousAction.ratingParam.rating_value;
      trainingsFeed[indexFeedback].attributes.rating_count = trainingsFeed[indexFeedback].attributes.rating_count + 1;
      return fromJS({
        ...state.toJS(),
        trainingsApplied: trainingsFeed,
      })

    case SELECT_CHECKBOX:
      return selectCheckBox(action.arrayKeyName, action.value, state);

    case UNSELECT_CHECKBOX:
      return unselectCheckBox(action.arrayKeyName, action.value, state);

    case APPLY_FILTERS:
      return fromJS({
        ...state.toJS(),
        search: action.params.search,
        selectedTypes: filterOnlyValues(action.params.category),
        selectedTrainingLevels: filterOnlyValues(action.params.levels),
        selectedUniversity: filterOnlyValues(action.params.university),
        page: 1
      })

    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })

    case RESET_FILTERS:
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

const filterOnlyValues = arr => {
  return arr.map(item => item.value);
};

const selectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  switch (arrayType) {
    case 'selectedTypes':
      modifyArr = state.get('selectedTypes').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedTypes: modifyArr,
      })
    case 'selectedTrainingLevels':
      modifyArr = state.get('selectedTrainingLevels').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedTrainingLevels: modifyArr,
      })
    case 'selectedUniversity':
        modifyArr = state.get('selectedUniversity').toJS();
        modifyArr.push(value);
        return fromJS({
          ...state.toJS(),
          selectedUniversity: modifyArr,
        })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

const unselectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  let index = 0;
  switch (arrayType) {
    case 'selectedTypes':
      modifyArr = state.get('selectedTypes').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedTypes: modifyArr,
      })
    case 'selectedTrainingLevels':
      modifyArr = state.get('selectedTrainingLevels').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedTrainingLevels: modifyArr,
      })
    case 'selectedUniversity':
        modifyArr = state.get('selectedUniversity').toJS();
        index = modifyArr.indexOf(value);
        modifyArr.splice(index, 1);
        return fromJS({
          ...state.toJS(),
          selectedUniversity: modifyArr,
        })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

/**
 *  Export the reducer
 */
export default trainingsReducer
