import React from "react";
import { func, array, bool, number} from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import ReactPaginate from 'react-paginate';
import Loader from 'components/Loader';
import { getFormattedDateForComments } from 'utils/helper';

/**
 *  Import other dependencies
 */
import { getAppraisals, applyPagination  } from '../../modules/appraisals/actions';
import { getAppraisalsListing, getIsFetchingAppraisals, getTotalPages, getAppraisalsPage  } from '../../modules/appraisals/selectors';

class Appraisals extends React.Component {
  state = {
    isSupervisor: this.props.match.path === '/user/appraisals/supervisor' ? true : false,
  };

  componentWillMount() {
    const { isSupervisor } = this.state;
    this.props.getAppraisals(isSupervisor);
  }

  redirectTo = link => {
    if(!link) return;

    const { history } = this.props;
    history.push(link);
  };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  getSingleAppraisalDoc = (appraisal, index, isSupervisor) => (
    <div className="col-lg-4 col-sm-6 px-xl-4 mb-4" key={index}>
      <div className="card border-0 lS-card-shadow lS-card-radius ls-height-100">
        <div className="card-body d-flex align-items-center">
          <div className="thumb-wrap thumb-wrap-100 radius-10 border ls-border-color">
            <img src={require("assets/images/guidelines/pdf.png")} alt="..." className="img-fluid w-75" />
          </div>
          <div className="min-width0 flex-fill pl-3">
            <div className="d-flex justify-content-between flex-column ls-height-100">
              <h5 className="mt-0 font-normal color-black-heading text-truncate family-poppins-medium">{appraisal.attributes.user_submission.name}</h5>
              <p>{getFormattedDateForComments(appraisal.attributes.user_submission.date_time)}</p>
              <div className="action-buttons d-flex align-items-center flex-wrap mt-1">
                <Link to={`/user/post-appraisals/${appraisal.id}${isSupervisor ? '/supervisor' : ''}`} className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                  <i className="fas fa-eye"></i>
                </Link>
              
                <button className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2" disabled>
                  <i className="fas fa-download"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const { appraisals, isFetchingAppraisals, totalPages, page } = this.props;
    const { isSupervisor } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold">Appraisals</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
                {!isSupervisor && (
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                      <Link to={`/user/post-appraisals`}>
                        <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Appraisal</button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* GuideLine Page */}
          <div className="row mx-xl-n4 pt-md-5 pt-4">
		        {isFetchingAppraisals && (
              <Loader />
            )}
            {!isFetchingAppraisals && appraisals && appraisals.length > 0 && appraisals.map((appraisal, index) => this.getSingleAppraisalDoc(appraisal, index, isSupervisor))}
            {!isFetchingAppraisals && appraisals && appraisals.length === 0 && (
              <div className="col-md-12">  
                <div className="card lS-card-shadow lS-card-radius border-0">
                  <div className="card-body text-center">
                    No record found
                  </div>
                </div>
              </div>
            )}
          </div>
          <div aria-label="Page navigation example" className="ls-pagination">
            <ReactPaginate
              containerClassName={'pagination justify-content-end mt-5'}
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              forcePage={page - 1}
              onPageChange={selectedItem => {
                if (isFetchingAppraisals) return;
                this.loadPage(selectedItem.selected + 1);
              }}
              subContainerClassName={'pages pagination'}
              pageClassName={'page-item'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Appraisals.propTypes = {
  applyPagination: func.isRequired,
  appraisals: array.isRequired,
  getAppraisals: func.isRequired,
  isFetchingAppraisals: bool.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
	appraisals: getAppraisalsListing(),
  isFetchingAppraisals: getIsFetchingAppraisals(),
  totalPages: getTotalPages(),
  page: getAppraisalsPage(),
});

export default connect(
  mapStateToProps,
  {
    getAppraisals,
    applyPagination
  }
)(withRouter(Appraisals));
