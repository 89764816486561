export const GET_PRACTICE_LOG =
  'src/modules/revalidation/practiceLog/GET_PRACTICE_LOG';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/revalidation/practiceLog/CANCEL_ALL_API_REQUESTS';
export const SHOW_PRACTICE_LOG =
  'src/modules/revalidation/practiceLog/SHOW_PRACTICE_LOG';
export const POST_PRACTICE_LOG =
  'src/modules/revalidation/practiceLog/POST_PRACTICE_LOG';
export const UPDATE_PRACTICE_LOG =
  'src/modules/revalidation/practiceLog/UPDATE_PRACTICE_LOG';
export const DELETE_PRACTICE_LOG =
  'src/modules/revalidation/practiceLog/DELETE_PRACTICE_LOG';



