import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import Share from "../../components/share";
import { getFormattedDate } from 'utils/helper';
import { isRouteAllow } from 'utils/helper';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import { updatePlacementStatus, applyPlacement, mentorPlacement, getPlacements, getPlacementsOccupations, applyFilters, checkUnCheckFilters, applyPagination, resetFilters, postLikeDislikePlacement } from '../../modules/placements/actions';
import { getPlacementsListing, getPlacementsOccupationsList, getIsFetchingPlacements, getTotalPages, getPlacementPage } from '../../modules/placements/selectors';
import { jobTypes, locations, occupations, durations, groups} from './placementsFilters';
import Loader from 'components/Loader';

const placementSchema = Yup.object().shape({
  occupations: Yup.array(),
  duration: Yup.array(),
  group: Yup.array(),
  location: Yup.array(),
  category: Yup.array(),
  search: Yup.string()
});

const formattedArray = array => {
  return array.map(item => {
    let value = '';
    if (item.includes('Level')) {
      const res = item.split(' ');
      value = res[1];
    } else {
      value = item;
    }
    return {
      label: item,
      value: value
    };
  });
};

const customStyles = {
  control: styles => ({
    ...styles,
    minHeight: 34,
    borderColor: '#ebebeb',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 34,
    padding: '2px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
    backgroundColor: '#FFF',
    cursor: 'pointer',
  }),
  clearIndicator: styles => ({
    position: 'absolute',
    right: '16px',
    backgroundColor: '#FFF',

  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  return (
    <Select
      isMulti
      components={{ IndicatorSeparator:() => null }}
      className="ls-select-dropdown"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value);
      }} 
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};


class Placements extends React.Component {
  state = {
    range: 1000,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    placementId:0,
  }

  getFormikInitVal = () => {
		return { 
      search: '',
      occupations: [],
      duration: [],
      group: [],
      location: [],
      category: [],
    };
  };

  openShareModal(id) {
      this.setState({
          isVisibleShare: true,
          placementId: id,
      });
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    this.props.getPlacements();
    this.props.getPlacementsOccupations();
  }

  handleRange = event => {
    this.setState({
      range: event.target.value
    });
  };

  checkUncheckbox = (name, event, string) => {
    this.props.checkUnCheckFilters(name, event.target.checked, string);
  };

  applyPlacementFilters = (values, { setSubmitting }) => {
    const params = {
      search: values.search,
      occupations: values.occupations,
      duration: values.duration,
      group: values.group,
      location: values.location,
      category: values.category
    };
    
    this.props.applyFilters(params).then(res => {
			setSubmitting(false);
		}, err => {
			setSubmitting(false);
		});
  };

  resetPlacements = (resetForm) => {
    resetForm();
    this.props.resetFilters();
  };


  loadPage = page => {
    this.props.applyPagination(page);
  }

  mentorPlacement = (id) => {
    var params = {
      placement_id:id,
    };

    this.props.mentorPlacement(params).then(res => {
      var message = res.message;
      this.props.getPlacements()
      showSuccessMsg(message ? message : 'Applied successfully.');
    })
  }

  applyPlacement = (id) => {
    var params = {
      placement_id:id,
    };

    this.props.applyPlacement(params).then(res => {
      var message = res.message;
      this.props.getPlacements()
      showSuccessMsg(message ? message : 'Applied successfully.');
    })
  }

  cancelPlacement = (id) => {
    var params = {
      status:'cancelled',
    };

    this.props.updatePlacementStatus(id, params).then(res => {
      var message = res.message;
      this.props.getPlacements();
      showSuccessMsg(message ? message : 'Cancelled.');
    })
  }

  restorePlacement = (id) => {
    var params = {
      status:'active',
    };

    this.props.updatePlacementStatus(id, params).then(res => {
      var message = res.message;
      this.props.getPlacements()
      showSuccessMsg(message ? message : 'Updated status.');
    })
  }

  likeDislikePlacement = (id, value) => {
    var params = {
		likes_dislike_value: value,
    };
    
    this.props.postLikeDislikePlacement(id, params);
  }

  getSinglePlacement = (placement, index, id) => (
    <div className="col-lg-4 col-sm-6 mb-5">
      <div className="card lS-card-shadow border-0 lS-card-radius placement-item ls-height-100">
        <div className="card-body d-flex justify-content-between flex-column">
          <div className="flex-fill">
            <div className="thumb-wrap thumb-wrap-lg border radius-10 ls-border-color mb-3">
              <img src={placement.attributes.image_url} alt="placement-thumb" />
            </div>
            <div className="d-flex justify-content-between">
              <div className="flex-fill min-width0">
                <h4 className="family-poppins-semibold text-truncate">
                  <Link to={`/user/placements/${id}`} className="link-hover-text">
                    {placement.attributes.title ? placement.attributes.title : `TBC`}
                  </Link>
                </h4>
                <a href={placement.attributes.org_url} className="link-text font-15 d-inline-block">{placement.attributes.address ? placement.attributes.address : `TBC`}</a>
              </div>
              <div className="d-flex pl-3">
                {placement.attributes.can_edit && (
                  <Link to={`/user/placement-post/${id}`} className="btn btn-primary lS-btn-icon lS-btn-blue-style rounded-lg mr-2">                   
                    <i className="fas fa-pen" />                      
                  </Link>
                )}
                {placement.attributes.can_edit && (
                  <button onClick={() => this.cancelPlacement(id)} className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg">
                    <i className="fas fa-trash-alt" />
                  </button>
                )}
                  <button 
                    onClick={() => this.likeDislikePlacement(id, placement.attributes.like_dislikes === 1 ? 0 : 1)} 
                    // disabled = {placement && placement.attributes && placement.attributes.like_dislikes === 1}
                    className={`btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mx-2 ${placement && placement.attributes && placement.attributes.like_dislikes === 1 ? '' : 'btn-unlike'}`}
                  >                   
                  <i className="fas fa-thumbs-up line-height-normal" />  
                  <span className="ml-1 font-weight-bold align-middle">
                    {placement.attributes.can_edit  && (  
                      placement.attributes.likes
                    )}
                  </span>                    
                </button>
                <button
                    onClick={() => this.likeDislikePlacement(id, placement.attributes.like_dislikes === -1 ? 0 : -1)}
                    // disabled = {placement && placement.attributes && placement.attributes.like_dislikes === -1}
                    className={`btn btn-primary lS-btn-sm lS-btn-red-style rounded-lg ${placement && placement.attributes && placement.attributes.like_dislikes === - 1 ? '' : 'btn-unlike'}`}
                  >
                  <i className="fas fa-thumbs-down" />
                  <span className="ml-1 font-weight-bold align-middle">
                    {placement.attributes.can_edit  && (  
                      placement.attributes.dislikes
                    )}
                  </span>
                </button>
              </div>
            </div>
            <div className="font-13 pb-2 d-flex justify-content-between flex-wrap mt-2 mb-3">
              <div className="mb-2 pr-xl-1 pr-2">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span className="color-grey mr-1 text-nowrap">Start Date:</span>
                <span>{placement.attributes.start_date ? getFormattedDate(placement.attributes.start_date) : `TBC` }</span>
              </div>
              <div className="mb-2 pr-xl-1 pr-2">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span className="color-grey mr-1 text-nowrap">End Date:</span>
                <span>{placement.attributes.end_date ? getFormattedDate(placement.attributes.end_date) : `TBC` }</span>
              </div>
              <div className="mb-2">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span className="color-grey mr-1 text-nowrap">Duration:</span>
                <span>{placement.attributes.duration ? placement.attributes.duration : `TBC`}</span>
              </div>
            </div>
          </div>
          <div className="pt-2 d-flex border-top ls-border-color action-buttons">
            {/** Mentor */}
            {placement.attributes.can_edit  && placement.attributes.status !== `cancelled` &&  (                  
              <Link to={`/user/placement-mentors/${id}`} className="btn py-2 flex-equal-width btn-primary lS-btn-sm height-auto lS-btn-blue-style rounded-lg mt-2 mr-2">
                Mentor
              </Link>                  
            )}
            {(!placement.attributes.can_edit) && (                 
              <button className="btn py-2 flex-equal-width btn-primary lS-btn-sm height-auto lS-btn-blue-style rounded-lg mt-2 mr-2"  
                      disabled = { placement.attributes.has_already_applied === 1 ? true: false }
                      onClick={() => this.mentorPlacement(id)}>
                {placement.attributes.has_already_applied_mentor === 0 ? `Mentor Here` : `Applied as Mentor`}
              </button>                  
            )}

            {/** Train Here */}
            {placement.attributes.can_edit  && placement.attributes.status !== `cancelled` &&  (                
              <Link to={`/user/placement-applicants/${id}`} className="btn py-2 flex-equal-width btn-primary lS-btn-sm height-auto lS-btn-gold-style rounded-lg mt-2 mr-2">                 
                Train ({ placement.attributes.applicants_count ? placement.attributes.applicants_count : 0 })
              </Link>               
            )}

            {(!placement.attributes.can_edit) && (                  
              <button className="btn py-2 flex-equal-width btn-primary lS-btn-sm height-auto lS-btn-gold-style rounded-lg mt-2 mr-2"  
                disabled = { placement.attributes.has_already_applied_mentor === 1 ? true: false }
                onClick={() => this.applyPlacement(id)}>
                {placement.attributes.has_already_applied === 0 ? `Train` : `EOI Noted`}
              </button>                  
            )}

            <button disabled="true" className="btn py-2 flex-equal-width btn-primary lS-btn-sm height-auto lS-btn-green-style rounded-lg mt-2">
              Volunteer Here
            </button>
          </div>
        </div>
      </div>
    </div>    
  );
  render() {
    const { placements, isFetchingPlacements, totalPages, page } = this.props;
    const { isVisibleShare, placementId } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold my-2">Placements</h4>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-lg-end justify-content-center">
                      {isRouteAllow('/placement-post') && (
                        <Link to={`/user/placement-post`}>
                          <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Placement</button>
                        </Link>
                      )}                          
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* search bar */}
          <div className="card border-0 mt-md-5 mt-4 lS-card-shadow ls-filters-bar">
            <div className="pt-0 px-2 pb-3 card-body">
              <Formik
                initialValues={this.getFormikInitVal()}
                validationSchema={placementSchema}
                onSubmit={this.applyPlacementFilters}
              >
                {({ isSubmitting, resetForm }) => (
                  <>
                    <Form className="d-flex flex-wrap justify-content-between px-1">
                      <div className="px-2 flex-grow-1 mt-3">
                        <div className="input-group ls-search-field">
                          <div className="input-group-prepend">
                            <span className="input-group-text bg-transparent pr-0 font-12 color-grey">
                              <i className="fas fa-search" />
                            </span>
                          </div>
                          <Field type="text" name="search" className="form-control border-left-0" placeholder="Search here..." />
                        </div>
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="occupations" component={formSelect} options={occupations} placeholder="Occupation" />
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="duration" component={formSelect} options={durations} placeholder="Duration" />
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="group" component={formSelect} options={groups} placeholder="Group" />
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="location" component={formSelect} options={locations} placeholder="Location" />
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="category" component={formSelect} options={jobTypes} placeholder="Category" />                                  
                      </div>
                      <div className="d-flex align-items-center mt-3 ml-auto px-2">
                        <button type="submit" className="btn btn-primary mr-3 lS-btn-blue-style" disabled={isSubmitting}>
                          { 
                            isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-2"></span>
                          )}
                          Search
                        </button>
                        <button type="button" onClick={() => this.resetPlacements(resetForm)} className="btn btn-secondary lS-btn-red-style" disabled={isSubmitting}>Reset</button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
          
          {/* placements Listing */}
          <div className="row mt-md-5 mt-4">
            {!isFetchingPlacements && placements && placements.length > 0 && placements.map((placement, index) => this.getSinglePlacement(placement, index, placement.id))}
            {!isFetchingPlacements && placements && placements.length === 0 && (
              <div className="col-12">
                <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                  No placement found
                </div>
              </div>
            )}
            {isFetchingPlacements && (
              <div className="col-12"><Loader /></div>
            )}
          </div>
          {/* Pagination */}
          <div aria-label="Page navigation example" className="ls-pagination">
            <ReactPaginate
              containerClassName={'pagination justify-content-end mt-5'}
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              forcePage={page - 1}
              onPageChange={selectedItem => {
                if (isFetchingPlacements) return;
                this.loadPage(selectedItem.selected + 1);
              }}
              subContainerClassName={'pages pagination'}
              pageClassName={'page-item'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </main>
        <UserFooter />
        {/* User Share Popup */}
        {isVisibleShare && (
            <Share
                isVisible={isVisibleShare}
                endpoint={"/user/placements/"+ placementId}
                onClose={() => {
                    this.setState({
                      isVisibleShare: false,
                    });
                }}
                onCompleted={res => this.onCompleteImageCrop(res)}
            />
            )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Placements.propTypes = {
  applyFilters: func.isRequired,
  checkUnCheckFilters: func.isRequired,
  getPlacements: func.isRequired,
  isFetchingPlacements: bool.isRequired,
  placements: array.isRequired,
  placementsOccupations: array.isRequired,
  postLikeDislikePlacement: func.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  applyPlacement: func.isRequired,
  mentorPlacement: func.isRequired,
  updatePlacementStatus: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  placements: getPlacementsListing(),
  placementsOccupations: getPlacementsOccupationsList(),
  isFetchingPlacements: getIsFetchingPlacements(),
  page: getPlacementPage(),
  totalPages: getTotalPages(),
});

export default connect(
  mapStateToProps,
  {
    getPlacements,
    getPlacementsOccupations,
    checkUnCheckFilters,
    applyFilters,
    applyPagination,
    resetFilters,
    applyPlacement,
    mentorPlacement,
	updatePlacementStatus,
	postLikeDislikePlacement,
  }
)(withRouter(Placements));
