/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_JOBS,
  GET_LIST_OF_JOB_APPLICANTS,
  GET_SINGLE_JOB,
  GET_LIST_OF_JOB_OCCUPATIONS,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_FILTERS,
  RESET_FILTERS,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 6,
  page: 1,
  totalPage: 0,
  jobs: [],
  jobOccupations: [],
  jobApplicants: [],
  search: '',
  minSalary: '',
  maxSalary: '',
  selectedOccupations: [],
  selectedLocations: [],
  isFetchingJobs: true,
  isFetchingJobApplicants: true,
  isFetchingSingleJob: true,
  singleJob: null,
  selectedTypes: []
});

/**
 *  Define the reducer with actions
 */
function jobsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_JOBS:
      return fromJS({
        ...state.toJS(),
        isFetchingJobs: true,
      })

    case success(GET_LIST_OF_JOBS):
      const { data } = action.payload.data;
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        jobs: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingJobs: false,
      })

    case failure(GET_LIST_OF_JOBS):
      return fromJS({
        ...state.toJS(),
        isFetchingJobs: false,
      })
    
    case GET_LIST_OF_JOB_APPLICANTS:
      return fromJS({
        ...state.toJS(),
        isFetchingJobApplicants: true,
      })

    case success(GET_LIST_OF_JOB_APPLICANTS):
      const  jobApplicantsData  = action.payload.data.data;
      const  job_applicants_total_pages  = action.payload.data.meta.pagination;      
      return fromJS({
        ...state.toJS(),
        jobApplicants: jobApplicantsData && jobApplicantsData.length > 0 ? jobApplicantsData : [],
        totalPage: job_applicants_total_pages,
        isFetchingJobApplicants: false,
      })

    case failure(GET_LIST_OF_JOB_APPLICANTS):
      return fromJS({
        ...state.toJS(),
        isFetchingJobApplicants: false,
      })

    case GET_SINGLE_JOB:
      return fromJS({
        ...state.toJS(),
        singleJob: null,
        isFetchingSingleJob: true,
      })

    case success(GET_SINGLE_JOB):
      return fromJS({
        ...state.toJS(),
        singleJob: action.payload.data && action.payload.data.data ? action.payload.data.data.attributes : null,
        isFetchingSingleJob: false,
      })

    case failure(GET_SINGLE_JOB):
      return fromJS({
        ...state.toJS(),
        isFetchingSingleJob: false,
      })
    
    case success(GET_LIST_OF_JOB_OCCUPATIONS):
      return fromJS({
        ...state.toJS(),
        jobOccupations: action.payload.data.data.type,
      })

    case SELECT_CHECKBOX:
      return selectCheckBox(action.arrayKeyName, action.value, state);

    case UNSELECT_CHECKBOX:
      return unselectCheckBox(action.arrayKeyName, action.value, state);

    case APPLY_FILTERS:
     
      return fromJS({
        ...state.toJS(),
        search: action.search,
        minSalary: action.minSalary,
        maxSalary: action.maxSalary,
        selectedOccupations: filterOnlyValues(action.params.occ),
        selectedTypes: filterOnlyValues(action.params.type),
        selectedLocations: filterOnlyValues(action.params.location),
        page: 1
      })

    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })

    case RESET_FILTERS:
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...state.toJS(),
        limit: 6,
        page: 1,
        totalPage: 0,
        jobs: [],
        search: '',
        minSalary: '',
        maxSalary: '',
        selectedOccupations: [],
        selectedLocations: [],
        selectedTypes: []
      })

    default:
      return state
  }
}

const selectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  switch (arrayType) {
    case 'selectedTypes':
      modifyArr = state.get('selectedTypes').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedTypes: modifyArr,
      })
    case 'selectedLocations':
      modifyArr = state.get('selectedLocations').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedLocations: modifyArr,
      })
    case 'selectedOccupations':
      modifyArr = state.get('selectedOccupations').toJS();
      modifyArr.push(value);
      return fromJS({
        ...state.toJS(),
        selectedOccupations: modifyArr,
      })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

const unselectCheckBox = (arrayType, value, state) => {
  let modifyArr = [];
  let index = 0;
  switch (arrayType) {
    case 'selectedTypes':
      modifyArr = state.get('selectedTypes').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedTypes: modifyArr,
      })
    case 'selectedLocations':
      modifyArr = state.get('selectedLocations').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedLocations: modifyArr,
      })
    case 'selectedOccupations':
      modifyArr = state.get('selectedOccupations').toJS();
      index = modifyArr.indexOf(value);
      modifyArr.splice(index, 1);
      return fromJS({
        ...state.toJS(),
        selectedOccupations: modifyArr,
      })
    default:
      return fromJS({
        ...state.toJS()
      })
  };
};

const filterOnlyValues = arr => {
  return arr.map(item => item.value);
};

/**
 *  Export the reducer
 */
export default jobsReducer
