/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const sidebar = () => state => state.get('sidebar');

/**
 *  Select the correct portion of the root reducer
 */
export const getJobsContacts = () =>
  createSelector(sidebar(), state => {
    const listing = state.get('contacts').toJS()
    return listing ? listing : []
  });

/**
 *  Select is fetching Contacts
 */
export const getIsFetchingContacts = () =>
  createSelector(sidebar(), state => {
    return state.get('isFetchingContacts')
  })

/**
 *  Select is More contacts Available
 */
export const getIsMoreContactsAvailable = () =>
  createSelector(sidebar(), state => {
    return state.get('isMoreContactsAvailable')
  })

  
