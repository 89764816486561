/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_BOOKLETS,
  UPLOAD_IMAGE,
  GET_USERS_LIST,
  UPDATE_SINGLE_TASK,
  APPLY_PAGINATION,
  APPLY_FILTERS,
  GET_LIST_OF_ASSESSOR_BOOKLETS,
  GET_LIST_OF_SUPERVISOR_BOOKLETS,
  GET_LIST_OF_SINGLE_BOOKLETS,
  CANCEL_ALL_API_REQUESTS,
  GET_LIST_OF_USER_SUBMITTED_BOOKLETS,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 10,
  page: 1,
  totalPage: 0,
  booklet: {},
  bookLets: [],
  users: [],
  search: '',
  bookLetIndexsAndTasks: [],
  isUploadingImage: false,
  isFetchingResources: false,
});

/**
 *  Define the reducer with actions
 */
function resourcesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_USER_SUBMITTED_BOOKLETS:
      return fromJS({
        ...state.toJS(),
        isFetchingResources: true,
      })

    case success(GET_LIST_OF_USER_SUBMITTED_BOOKLETS):
      const userData = action.payload.data.data;
      const user_pages_count  = action.payload.data.meta.pagination.total_pages;
      return fromJS({
        ...state.toJS(),
        users: userData && userData.length > 0 ? userData : [],
        totalPage: user_pages_count,
        isFetchingResources: false,
      })

    case failure(GET_LIST_OF_USER_SUBMITTED_BOOKLETS):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: false,
      })


    case GET_LIST_OF_BOOKLETS:
      return fromJS({
        ...state.toJS(),
        isFetchingResources: true,
      })

    case success(GET_LIST_OF_BOOKLETS):
      const { data } = action.payload.data;
      const booklet_pages_count  = action.payload.data.meta.pagination.total_pages;
      return fromJS({
        ...state.toJS(),
        bookLets: data && data.length > 0 ? data : [],
        totalPage: booklet_pages_count,
        isFetchingResources: false,
      })

    case failure(GET_LIST_OF_BOOKLETS):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: false,
      })
    
    case GET_LIST_OF_SINGLE_BOOKLETS:
      return fromJS({
        ...state.toJS(),
        booklet:{},
        bookLetIndexsAndTasks: [],
        isFetchingResources: true,
      })

    case success(GET_LIST_OF_SINGLE_BOOKLETS):
      let bookLetIndexData = {};
      let finialResult=[];
      
      action.payload.data.data.relationships.user_booklet_indexes.data.map(element => {
        let bookLetMiddleData = [];
        action.payload.data.included.find(item => {
          if(item.type === element.type) {
            if(item.id === element.id){
              item.relationships.user_resource_tasks.data.map( resources => {
                let data = action.payload.data.included.find(value => {
                  if(value.type === resources.type){
                    if(value.id === resources.id){
                      // Return bookletTasks Values of Particular Id
                      return value
                    }
                  }
                })
                bookLetMiddleData.push(data)
                // return Resource Values
                return bookLetMiddleData
              })
              // Assign The custom Value
              bookLetIndexData ={
                type: item.type,
                id: item.id,
                attributes: item.attributes,
                bookletTasks: bookLetMiddleData,
              }
              return bookLetIndexData
            }
          }
        })
        finialResult.push(bookLetIndexData)
        // Get Finial Result
        return finialResult;
      });
      return fromJS({
        ...state.toJS(),
        bookLetIndexsAndTasks: finialResult && finialResult.length > 0 ? finialResult : [],
        booklet:action.payload.data.data.attributes,
        isFetchingResources: false,
      })

    case failure(GET_LIST_OF_SINGLE_BOOKLETS):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: false,
      })

    case GET_LIST_OF_SUPERVISOR_BOOKLETS:
      return fromJS({
        ...state.toJS(),
        bookLetIndexsAndTasks: [],
        isFetchingResources: true,
      })

    case success(GET_LIST_OF_SUPERVISOR_BOOKLETS):
      
      const supervisorData = action.payload.data.data;
      const supervisorIncludes = action.payload.data.included;
      const { total_pages } = action.payload.data.meta.pagination;
      
      // // Step: 1 - filter all indexes
      // supervisorData.map(indexes => {
      //   indexes.supervisorTasks = filterData(indexes.relationships.user_resource_tasks.data, supervisorIncludes)
      //   delete indexes.relationships;
      //   return indexes;
      // })
      // // Step: 2 - Filter data second Relationship
      // supervisorData.map(indexes => {
      //   indexes.supervisorTasks.map(tasks => {
      //     const UserResourceAssessment = filterData([tasks.relationships.user_resource_assessment.data], supervisorIncludes)
      //     tasks.assessment = UserResourceAssessment[0]
      //     delete tasks.relationships;
      //     return tasks
      //   })
      // })

      return fromJS({
        ...state.toJS(),
        bookLetIndexsAndTasks: supervisorData && supervisorData.length > 0 ? supervisorData : [],
        totalPage: total_pages,
        isFetchingResources: false,
      })

    case failure(GET_LIST_OF_SUPERVISOR_BOOKLETS):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: false,
      })
    
    case GET_LIST_OF_ASSESSOR_BOOKLETS:
      return fromJS({
        ...state.toJS(),
        bookLetIndexsAndTasks: [],
        isFetchingResources: true,
      })

    case success(GET_LIST_OF_ASSESSOR_BOOKLETS):
      const assessorData = action.payload.data.data;
      const assessorIncludes = action.payload.data.included;
      const assessor_total_pages  = action.payload.data.meta.pagination.total_pages;
      
      // // Step: 1 - filter all indexes
      // assessorData.map(indexes => {
      //   indexes.supervisorTasks = filterData(indexes.relationships.user_resource_tasks.data, assessorIncludes)
      //   delete indexes.relationships;
      //   return indexes;
      // })
      // // Step: 2 - Filter data second Relationship
      // assessorData.map(indexes => {
      //   indexes.supervisorTasks.map(tasks => {
      //     const UserResourceAssessment = filterData([tasks.relationships.user_resource_assessment.data], assessorIncludes)
      //     tasks.assessment = UserResourceAssessment[0]
      //     delete tasks.relationships;
      //     return tasks
      //   })
      // })

      return fromJS({
        ...state.toJS(),
        bookLetIndexsAndTasks: assessorData && assessorData.length > 0 ? assessorData : [],
        totalPage: assessor_total_pages,
        isFetchingResources: false,
      })

    case failure(GET_LIST_OF_ASSESSOR_BOOKLETS):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: false,
      })
      
    case success(GET_USERS_LIST):
      return fromJS({
        ...state.toJS(),
        users: action.payload.data.data,
      })

    case success(UPDATE_SINGLE_TASK):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: true,
      })
    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })
    case APPLY_FILTERS:
        return fromJS({
          ...state.toJS(),
          search: action.search,
          page: 1
        })

    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })

    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

const filterData = (data, includes) => {
  return data.map( data => includes.find(value => value.type === data.type && value.id === data.id))
};

/**
 *  Export the reducer
 */
export default resourcesReducer
