import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import { func, bool, number, array } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
import { selfAssessment, evidence } from './filters';

import { 
	updateIndexAccSup,
	getSingleBooklets,
} from '../../modules/resources/actions';
import {
  getUsersListing,
} from '../../modules/resources/selectors';

const formattedArray = array => {
  return array.map(item => {
    return {
      label: `${item.attributes.name} (${item.attributes.email})`,
      value: `${item.attributes.name} (${item.attributes.email})`
    };
  });
};

const filterformattedArray = array => {
	return array.map(item => {
	  return {
		label: `${item}`,
		value: `${item}`
	  };
	});
  };

const selfAssessSelect = props => {
	const { form, options, placeholder, field } = props;
	if (options.length === 0) return;
	return (
	  <Select
		components={{ IndicatorSeparator:() => null }}
		className="form-control p-0 mb-2"
		isSearchable={true}
		placeholder={placeholder}
		onChange={value => {
		  if(field.name === 'self_assessment_initial'){
			form.setFieldValue('self_assessment_initial', value.value)
		  } else if (field.name === 'self_assessment_mid'){
			form.setFieldValue('self_assessment_mid', value.value)
		  } else if (field.name === 'self_assessment_end'){
			form.setFieldValue('self_assessment_end', value.value)
		  }
		}}
		value={field.value ? {label: field.value, value: field.value} : ''}
		styles={customStyles}
		options={filterformattedArray(options)}
	  />
	)
  };

const customStyles = {
  control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
//   if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        field.name === 'supervisor' ?
          form.setFieldValue('supervisor', value.value) :
          form.setFieldValue('accessor', value.value)
      }} 
      value={field.value ? {label: field.value, value: field.value} : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

const assignAssorSuperSchema = Yup.object().shape({
	// self_assessment_initial: Yup.string()
	// 	.required('Required'),
	// self_assessment_mid: Yup.string()
	// 	.required('Required'),
	// self_assessment_end: Yup.string()
	// 	.required('Required'),
	supervisor: Yup.string()
		.required('Required'),
	accessor: Yup.string()
		.required('Required'),
});

class AssignAssorSuper extends React.Component {
	getFormikInitVal = () => {
    return {
      supervisor: "",
	  accessor: "",
	  self_assessment_initial:"",
	  self_assessment_mid:"",
	  self_assessment_end:"",

    }
  };
  
  getUserID = value => {
    const { users } = this.props;
    const user = users.filter(user => `${user.attributes.name} (${user.attributes.email})` === value)
    if (user && user.length > 0) {
      return user[0].id;
    }
    return "";
  };
	
	updateIndexAccSup = (values, { setSubmitting, resetForm }) => {
		const { bookLetID, bookLetIndexID, updateIndexAccSup, getSingleBooklets } = this.props;
		const params = {
			booklet_id: bookLetID,
			booklet_index_id: bookLetIndexID,
			supervisor_id: this.getUserID(values.supervisor),
			assessor_id: this.getUserID(values.accessor),
			self_assessment_initial: values.self_assessment_initial,
			self_assessment_mid: values.self_assessment_mid,
			self_assessment_end: values.self_assessment_end
    };
		updateIndexAccSup(params).then(res => {
			const { message } = res;
			getSingleBooklets(bookLetID, bookLetIndexID);
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};
	
	render() {
		const {
			isVisible,
      onClose,
      users
    } = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>Assign a Supervisor/Assessor</Modal.Title>
					</Modal.Header>
					
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={assignAssorSuperSchema}
						onSubmit={this.updateIndexAccSup}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form>							
								<Modal.Body>
									{/* <div className="form-group">
										<label className="form-label">Initial Interview</label>
										<Field as="select" name="self_assessment_initial" placeholder="Select Self Assesment" component={selfAssessSelect} options={selfAssessment} />
										<ErrorMessage name="self_assessment_initial" component="div" className="error-msg" />
									</div>
									<div className="form-group">
										<label className="form-label">Mid-Point Interview</label>
										<Field as="select" name="self_assessment_mid" placeholder="Select Self Assessment" component={selfAssessSelect} options={selfAssessment} />
										<ErrorMessage name="self_assessment_mid" component="div" className="error-msg" />
									</div>
									<div className="form-group">
										<label className="form-label">End-Point Interview</label>
										<Field as="select" name="self_assessment_end" placeholder="Select Self Assesment" component={selfAssessSelect} options={selfAssessment} />
										<ErrorMessage name="self_assessment_end" component="div" className="error-msg" />
									</div> */}
									<div className="form-group">
									<label>Supervisor</label>
									<Field as="select" name="supervisor" placeholder="Select Supervisor" component={formSelect} options={users} />                                  
									<ErrorMessage name="supervisor" component="div" className="error-msg" />
									</div>
														<div className="form-group">
									<label>Accessor</label>
									<Field as="select" name="accessor" placeholder="Select Accessor" component={formSelect} options={users} />                                  
									<ErrorMessage name="accessor" component="div" className="error-msg" />
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button
										disabled={isSubmitting}
										className="btn btn-outline-dark"
										onClick={() => {
											onClose();
										}}
									>
										Cancel
									</button>
									<button type="submit" disabled={isSubmitting} className="btn btn-primary">
										{ 
											isSubmitting && (
												<span className="spinner-border spinner-border-sm mr-2"></span>
										)}
										Save
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
AssignAssorSuper.propTypes = {
	bookLetID: number.isRequired,
	bookLetIndexID: number.isRequired,
	getSingleBooklets: func.isRequired,
	isVisible: bool.isRequired,
  onClose: func.isRequired,
  users: array.isRequired
};

const mapStateToProps = createStructuredSelector({
  users: getUsersListing(),
});

export default connect(
  mapStateToProps,
  {
		updateIndexAccSup,
		getSingleBooklets,
  }
)(withRouter(AssignAssorSuper));
