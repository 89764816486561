import React from "react";
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { getBookletCategories  } from '../../../modules/competencies/actions';
import { getCategoriesListing, getIsFetchingCategories  } from '../../../modules/competencies/selectors';
import { array, func, bool } from "prop-types";
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';

class categoryListing extends React.Component {
  componentWillMount() {
    this.props.getBookletCategories();
  }

  getSinglePlacement = (category, index, id) => (
    <React.Fragment key={index}>
      
    </React.Fragment>
  );

  render() {
    const { categories, isFetchingCategories } = this.props;
      return (
        <>
        <UserHeader />
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold my-2">Competencies</h4>
                </div>
              </div>
            </div>
          </div>
          {/* detail tiles */}
          <div className="container-fluid p-0">
            <div className="row row-col-5 mt-md-5 mt-4">
              {!isFetchingCategories && categories && categories.length > 0 && categories.map(book => (                     
                <div className="col-12 col-md-4 col-sm-6 custom-col-width border-0">
                  <div className="card border-0 detail-tile lS-card-radius lS-card-shadow mb-4">
                    <Link to={`/user/resources/category/${book.id}`} className="link-hover-text">
                      <div className="card-body text-center">                          
                          <div className="tile-icon rounded-circle d-inline-flex align-items-center justify-content-center">
                            <img src={require("assets/images/book_icon.jpg")} alt="icon" />
                          </div>
                          <h5 className="font-17 family-poppins-semibold mb-0 mt-3 text-truncate" 
                          data-toggle="tooltip" title={book.attributes.name}>{book.attributes.name}</h5>                        
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {!isFetchingCategories && categories && categories.length === 0 && (
              <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                No categories found
              </div>
            )}
            <div className="w-100 d-flex justify-content-center">
              {isFetchingCategories && (
                <Loader />
              )}
            </div>
          </div>
        </main>
        <UserFooter />
        </> 
      );
    }
}

categoryListing.propTypes = {
    categories: array.isRequired,
    getCategoriesListing: func.isRequired,
    isFetchingCategories: bool.isRequired,
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({
    categories: getCategoriesListing(),
    isFetchingCategories: getIsFetchingCategories(),
  });
  
  export default connect(
    mapStateToProps,
    {
      getBookletCategories
    }
  )(withRouter(categoryListing));
  