import React from "react";
import { func, object, bool, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { getFormattedDateTime, getFormattedDate, getFormattedTime } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import { getCalender  } from '../../modules/calender/actions';
import { getCalenderListing, getIsFetchingCalender } from '../../modules/calender/selectors';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import Share from "../../components/share";

/**
 *  Import other dependencies
 */
import { 
  getEvents
} from '../../modules/eventPost/actions';

import { applyEvent } from '../../modules/events/actions';

import {
  getIsFetchingEvents,
  getEventsListing
} from '../../modules/eventPost/selectors';

class EventDetail extends React.Component {
  state={    
    isVisibleShare: false,
  }

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getEvents(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/events');
        }
      });
    }
  }
  
  applyEvent = (id) => {
    var params = {
      event_id:id,
    };

    this.props.applyEvent(params).then(res => {
      var message = res.message;
      this.props.getEvents(this.props.match.params.id)
      showSuccessMsg(message ? message : 'Booked successfully.');
    })
  }

  openShareModal(id) {
    this.setState({
        isVisibleShare: true,
        eventId: id,
    });
  }

  render() {
    const { event, isFetchingEvents, isFetchingCalender, calender} = this.props;
    const {isVisibleShare, eventId} = this.state;
    return (
      <>
       <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-3 px-md-5 pt-md-5 pt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <h4 className="family-poppins-semibold my-2">Event</h4>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                      {event.can_edit && (
                        < Link type="button" to={`/user/job-post`}className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Job</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            {/* Filter Code */}
            {/* details */}
            <div className="row mt-md-5 mt-4 mb-5">
              <div className="col-xl-8">
                <div className="card border-0 lS-card-shadow lS-card-radius detail-card ls-height-100">
                  <div className="card-body">
                    {!isFetchingEvents && event && Object.keys(event).length > 0 && (
                      <div className="d-flex flex-column flex-md-row">
                        <div>
                          <div className="thumb-wrap thumb-wrap-xl border ls-border-color radius-10 mb-4 mb-md-0">
                            <img src={event.attributes.image_url} alt="..." className="img-object-fit" />
                          </div>
                        </div>
                        <div className="min-width0 flex-fill pl-md-4">
                          <div className="d-flex ls-height-100 flex-column justify-content-between">
                            <div className="flex-fill">
                              <h4 className="mt-0 mb-2 font-normal color-black-heading family-poppins-medium">{event.attributes.title}</h4>
                                                              
                              <p className="mb-3 mt-0 font-18 color-primary">{event.attributes.organisation ? event.attributes.organisation : `TBC` }</p>
                              {/* <p className="color-grey mb-2">
                                {event.occupation ? event.occupation : `TBC` }
                              </p> */}
                              <p className="color-grey">{event.attributes.description}</p>
                              
                              <div className="font-14 mt-3">
                                <div className="mb-3 d-flex">
                                  {/* <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i> */}
                                  <span>{event.attributes.start_date ? getFormattedDate(event.attributes.start_date) : `TBC`}</span>
                                </div>
                                {/* <div className="mb-4 d-flex">
                                  <i className="fas fa-map-marker-alt color-primary mr-2 line-height-common"></i>
                                  <span>{event.location ? event.location  : `TBC`}</span>
                                </div> */}
                                <div className="mb-4 d-flex">                                    
                                  <span>{getFormattedTime(event.attributes.start_date)} - {getFormattedTime(event.attributes.end_date)}</span>
                                </div>
                              </div>
                            </div>
                            {/* {event.can_edit && (
                            <div className="pt-2 d-flex action-buttons">
                              
                                <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                                  <i className="fas fa-eye" /> Views (4)
                                </button>
                                <button className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                                  <i className="fas fa-pencil-alt" />
                                </button>
                                <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                                  <i className="fas fa-trash-alt" />
                                </button>
                            </div>
                            )} */}
                            <div className="pt-2 d-flex action-buttons">
                              {!event.attributes.can_edit && !event.attributes.has_already_applied && (
                                <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 color-white mr-2" 
                                        disabled={event.attributes.has_already_applied} 
                                        onClick={() => this.applyPlacement(this.props.match.params)}>
                                          Book<i className="fas fa-arrow-right ml-1" />
                                </button>
                              )}
                              {!event.attributes.can_edit && event.attributes.has_already_applied && (
                                <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 color-white mr-2" disabled>
                                  Booked
                                </button>
                              )}
                              {!event.attributes.can_edit && event.attributes.has_already_applied && (
                                <>
                                  <a href={event.link_url} className="btn btn-primary lS-btn-icon lS-btn-gold-style rounded-lg mt-2 mr-2">
                                    <i className="fas fas fa-globe color-white" />
                                  </a>
                                  <button className="btn btn-primary lS-btn-icon lS-btn-yellow-style rounded-lg mt-2"
                                      onClick={() => this.openShareModal(event.id)}>
                                    <i className="fas fa-share-alt" />
                                  </button>
                                </>
                              )}
                              {/* {!event.can_edit && !event.has_already_applied && (
                                <a href={event.link_url}  
                                    className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 color-white" >
                                    <i className="fas fa-globe"/>&nbsp;Visit Website
                                </a>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isFetchingEvents && event && Object.keys(event).length === 0 && (
                      <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">Event not found</div>
                    )}
                    {isFetchingEvents && (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 m-xl-0 mt-3">
                {/* Calendar code */}
                <div className="card event-calendar-sm calendar-no-event-list lS-card-shadow ls-height-100">
                  <div id="calendar" />
                  {isFetchingCalender && (
                    <Loader />
                  )}
                  {!isFetchingCalender && (
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[ dayGridPlugin ]}
                      header={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                      }}
                      events={calender}
                      eventClick={this.handleDateClick}
                    />
                  )}
                </div>
              </div>
              {/* End Of List  */}
            </div>
          </div>
          
        </main>
        <UserFooter/>  
        {isVisibleShare && (
          <Share
              isVisible={isVisibleShare}
              endpoint={"/user/events/"+ eventId}
              onClose={() => {
                  this.setState({
                    isVisibleShare: false,
                  });
              }}
              onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}      
       </>
    );
  }
}

/**
 *  Define component PropTypes
 */
EventDetail.propTypes = {
  isFetchingEvents: bool.isRequired,
  event: object.isRequired,
  applyEvent: func.isRequired,
  calender: array.isRequired,
  getCalender: func.isRequired,
  isFetchingCalender: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isFetchingEvents: getIsFetchingEvents(),
  event: getEventsListing(),
  calender: getCalenderListing(),
  isFetchingCalender: getIsFetchingCalender(),
});

export default connect(
  mapStateToProps,
  {
    getEvents,
    applyEvent,
    getCalender,
  }
)(withRouter(EventDetail));
