import React from "react";
import Modal from 'react-bootstrap/Modal';
import { func, bool, string } from "prop-types";

class ApproveConfirmation extends React.Component {
  render() {
    const {
      isVisible,
      onClose,
      onApprove,
      title,
      isProcessing
    } = this.props;
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header className="background-primary justify-content-center">
            <Modal.Title className="color-white">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="color-grey text-center">
              Are you sure?
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div className="row pb-3 w-100">
              <div className="col-md-6">    
                <button
                  disabled={isProcessing}
                  className="btn btn-secondary w-100"
                  onClick={() => {
                    if (isProcessing) return;
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">    
                <button
                  disabled={isProcessing}
                  className="btn btn-primary lS-btn-blue-style w-100"
                  onClick={() => {
                    if (isProcessing) return;
                    onApprove();
                  }}
                >
                  { 
                    isProcessing && (
                      <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                  )}
                  Approve
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
ApproveConfirmation.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  onApprove: func.isRequired,
  title: string.isRequired,
  isProcessing: bool.isRequired
};

export default ApproveConfirmation;