/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_TRAINING_FORM,
  CANCEL_ALL_API_REQUESTS
} from './constants';
import { getState } from 'expect/build/jestMatchersObject';

/**
 *  Set intial state
 */
const initialState = fromJS({
  trainingsListForm: {},
  isFetchingTrainingsForm: false,
});

/**
 *  Define the reducer with actions
 */
function trainingsAnylasisReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_TRAINING_FORM:
      return fromJS({
        ...state.toJS(),
        isFetchingTrainingsForm: true,
      })

    case success(GET_LIST_OF_TRAINING_FORM):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        trainingsListForm: data,
        isFetchingTrainingsForm: false,
      })

    case failure(GET_LIST_OF_TRAINING_FORM):
      return fromJS({
        ...state.toJS(),
        isFetchingTrainingsForm: false,
      })
    
  
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}


/**
 *  Export the reducer
 */
export default trainingsAnylasisReducer
