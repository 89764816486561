/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_NEWS,
  // UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  news: {},
  isFetchingNews: false,
  isUploadingImage: false,
  isDeletingNews: false,
});

/**
 *  Define the reducer with actions
 */
function postNewsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_NEWS:
      return fromJS({
        ...state.toJS(),
        isFetchingNews: true,
      })

    case success(GET_LIST_OF_NEWS):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        news: data ? data : {},
        isFetchingNews: false,
      })

    case failure(GET_LIST_OF_NEWS):
      return fromJS({
        ...state.toJS(),
        isFetchingNews: false,
      })
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default postNewsReducer
