import React from "react";
import { func, object, bool, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import Comments from 'components/Comments';
import { getCalender  } from '../../modules/calender/actions';
import { getCalenderListing, getIsFetchingCalender } from '../../modules/calender/selectors';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { getFormattedDateTime } from 'utils/helper';

/**
 *  Import other dependencies
 */
import { 
  getPlacement
} from '../../modules/placementPost/actions';

import { applyPlacement } from '../../modules/placements/actions';

import {
  getIsFetchingPlacement,
  getPlacementsListing
} from '../../modules/placementPost/selectors';

class PlacementDetail extends React.Component {
  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getPlacement(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/placements');
        }
      });
    }
  }
  
  applyPlacement = (id) => {
    var params = {
      placement_id:id,
    };

    this.props.applyPlacement(params).then(res => {
      var message = res.message;
      this.props.getPlacement(this.props.match.params.id)
      showSuccessMsg(message ? message : 'Applied successfully.');
    })
  }

  render() {
    const { placement, isFetchingPlacement, isFetchingCalender, calender} = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-3 px-md-5 pt-md-5 pt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h4 className="family-poppins-semibold my-2">Placement</h4>
                  </div>
                </div>
              </div>
            </div>  
            {/* details */}
            <div className="row mt-md-5 mt-4 mb-5">
              <div className="col-xl-8 custom-flex-xl">
                <div className="card border-0 lS-card-shadow lS-card-radius detail-card ls-height-100">
                  <div className="card-body">
                    {!isFetchingPlacement && placement && Object.keys(placement).length > 0 && (
                      <div className="d-flex flex-column flex-md-row">
                        <div>
                          <div className="thumb-wrap thumb-wrap-xl border ls-border-color radius-10 mb-4 mb-md-0">
                            <img src={placement.attributes.image_url} alt="..." className="img-object-fit" />
                          </div>
                        </div>
                        <div className="min-width0 flex-fill pl-md-4">
                          <div className="d-flex ls-height-100 flex-column justify-content-between">
                            <div className="flex-fill">
                              <div>
                                <div className="float-sm-right ml-0 ml-sm-3 text-center">
                                  {!placement.attributes.can_edit && placement.attributes.has_already_applied === 0 && (
                                    <button className="btn btn-primary px-4 lS-btn-sm lS-btn-blue-style rounded-pill font-12 mb-3" 
                                      disabled={placement.attributes.has_already_applied === 1} 
                                        onClick={() => this.applyPlacement(this.props.match.params)}>Book<i className="fas fa-arrow-right ml-1" /></button>
                                  )}

                                  {!placement.attributes.can_edit && placement.attributes.has_already_applied === 1  && (
                                    <button className="btn btn-primary px-4 lS-btn-sm lS-btn-blue-style rounded-pill font-12 mb-3" disabled>Booked</button>
                                  )}
                                </div>
                                <h4 className="mt-0 mb-2 font-normal color-black-heading family-poppins-medium">{placement.attributes.title}</h4>
                              </div>                                
                              <p className="mb-3 font-18 color-primary">{placement.attributes.org_name ? placement.attributes.org_name : `TBC` }</p>
                              <p className="color-grey mb-2">
                                {placement.attributes.organisation}
                              </p>
                              <p className="color-grey">{placement.attributes.description}</p>
                              
                              <div className="font-14 mt-4">
                                <div className="mb-3 d-flex">
                                  <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i>
                                  <span>{placement.attributes.start_date ? getFormattedDateTime(placement.attributes.start_date) : `TBC`}</span>
                                </div>
                                <div className="mb-3 d-flex">
                                  <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i>
                                  <span>{placement.attributes.end_date ? getFormattedDateTime(placement.attributes.end_date) : `TBC`}</span>
                                </div>
                                <div className="mb-2 d-flex">
                                  <i className="fas fa-map-marker-alt color-primary mr-2 line-height-common"></i>
                                  <span>{placement.attributes.location ? placement.attributes.location  : `TBC`}</span>
                                </div>
                              </div>
                            </div>
                            <div className="pt-2 d-flex action-buttons color-white">
                              {placement.attributes.can_edit && (
                                <>
                                  <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                                    <i className="fas fa-eye" /> Views (4)
                                  </button>
                                  <button className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                                    <i className="fas fa-pencil-alt" />
                                  </button>
                                  <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                </>
                              )}
                              {!placement.attributes.can_edit && !placement.attributes.has_already_applied && (
                                <a href={placement.attributes.link_url}  
                                    className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 " >
                                    <i className="fas fa-globe"/>&nbsp;Visit Website
                                </a>
                              )}
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    )}
                    {!isFetchingPlacement && placement && Object.keys(placement).length === 0 && (
                      <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No training found</div>
                    )}
                    {isFetchingPlacement && (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 m-xl-0 mt-3 custom-flex-sm">
                {/* Calendar code */}
                <div className="card event-calendar-sm calendar-no-event-list lS-card-shadow ls-height-100">
                  <div id="calendar" />
                  {isFetchingCalender && (
                    <Loader />
                  )}
                  {!isFetchingCalender && (
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[ dayGridPlugin ]}
                      header={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                      }}
                      events={calender}
                      eventClick={this.handleDateClick}
                    />
                  )}
                </div>
              </div>
              {/* End Of List  */}
            </div>

            {/* Comments */}
            <div class="row mx-lg-n4">
              <div class="col-xl-12 px-xl-4 custom-flex-xl">
                <Comments 
                  id={this.props.match.params.id}
                  type="PLACEMENTS"
                />
              </div>
            </div>
          </div>
          
        </main>
        <UserFooter/>
       </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PlacementDetail.propTypes = {
  isFetchingPlacement: bool.isRequired,
  placement: object.isRequired,
  applyPlacement: func.isRequired,
  calender: array.isRequired,
  getCalender: func.isRequired,
  isFetchingCalender: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isFetchingPlacement: getIsFetchingPlacement(),
  placement: getPlacementsListing(),
  calender: getCalenderListing(),
  isFetchingCalender: getIsFetchingCalender(),
});

export default connect(
  mapStateToProps,
  {
    getPlacement,
    applyPlacement,
    getCalender,
  }
)(withRouter(PlacementDetail));
