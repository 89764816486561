/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const postJobs = () => state => state.get('postJobs');

/**
 *  Select the correct portion of the root reducer
 */
export const getJobsListing = () =>
  createSelector(postJobs(), state => {
    const listing = state.get('job').toJS()
    return listing ? listing : {}
  });

/**
 *  Select is fetching jobs
 */
export const getIsFetchingJob = () =>
  createSelector(postJobs(), state => {
    return state.get('isFetchingJob')
  })

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(postJobs(), state => {
    return state.get('isUploadingImage')
  })

/**
 *  Select is fetching jobs
 */
export const getIsDeletingJob = () =>
  createSelector(postJobs(), state => {
    return state.get('isDeletingJob')
  })
