/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const cpdLogs = () => state => state.get('cpdLog');

/**
 *  Select the correct portion of the root reducer
 */
export const getCpdLogListing = () =>
  createSelector(cpdLogs(), state => {
    const listing = state.get('cpdLog').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select Single news page object
 */
export const getCpdLogDetail= () =>
  createSelector(cpdLogs(), state => {
    const cpdLog = state.get('cpdLog')
    return cpdLog && Object.keys(cpdLog).length > 0 ? cpdLog.toJS() : {}
  });

/**
 *  Select is fetching
 */
export const getIsFetchingCpdLog = () =>
  createSelector(cpdLogs(), state => {
    return state.get('isFetchingCpdLog')
  })

/**
 *  Select is fetching news
 */
// export const getIsFetchingSingleNews = () =>
//   createSelector(news(), state => {
//     return state.get('isFetchingSingleNews')
//   })

/**
 *  Select total number of news pages
 */
// export const getTotalPages = () =>
//   createSelector(news(), state => {
//     return state.get('totalPage')
//   })

/**
 *  Select current news pages
 */
// export const getNewsPage = () =>
//   createSelector(news(), state => {
//     return state.get('page')
//   })
