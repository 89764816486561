import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import Share from "../../../components/share";
import { isRouteAllow } from 'utils/helper';

/**
 *  Import other dependencies
 */
import { getSupervisorBookletsUser, applyPagination } from '../../../modules/resources/actions';
import { getTotalPages, getIsFetchingResources, getResourcesPage, getUsersListing } from '../../../modules/resources/selectors';
// import { trainingType, trainingLevels, locations } from './filters';

// const dateFormat = 'DD-MMM-YYYY';
// const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

class assessorUserListing extends React.Component {
  state={
    search: '',
    disableStatus: true,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    trainingId:0,
    examiner_type:'supervisor' 
  }

//   openShareModal(id) {
//     this.setState({
//         isVisibleShare: true,
//         trainingId: id,
//     });
//   }

//   handleQueryChange = query => {
//     this.setState({
//       search: query
//     });
//   };

  componentWillMount() {
    this.props.getSupervisorBookletsUser(this.state.examiner_type);
  }

//   getFormattedDate = date => {
//     if (!date) return;
//     return moment(date).format(dateFormat);
//   };

//   getFormattedDateTime = date => {
//     if (!date) return;
//     return moment(date).format(dateTimeFormat);
//   };

 getSingleTraining = (user, index, id) => (
  <div className="col-md-3">
    <Link to={`/user/supervisor/user-listing/${id}`} >
      <img src={require('assets/images/avatar-1.png')} alt="UserProfileImage"/>
      <div className="team_con">
        <h4>{user.name}</h4>
        <p>{user.occupation}</p>
      </div>
    </Link>
  </div>

//       <div className="job-card" key={index}>
//         <img className="thumb" src={training.image_url} alt="Img" />
//         <div className="text-area">
//           <div className="d-flex align-items-center">              
//                 <h3>{training.title}</h3>
//           </div>
//           <div className="d-flex align-items-center mb-2">
//             <span className="meta mr-3"> <i className="fas fa-angle-right mr-1"></i>Category: {training.type ? training.type : `TBC`} </span>
//             <span className="meta mr-3"> <i className="fas fa-user-tie mr-1"></i>Profession:  {training.profession ? training.profession : `TBC`} </span>
//             <span className="meta"><i className="fas fa-map-marker-alt mr-1"></i>Location: {training.location ? training.location : `TBC`}</span>
//           </div>
//           <p>{training.description}</p>
//           <div className="action-btn">
            
//             {training.can_edit && training.status !== `cancelled` && (
//               <Link to={ `/user/post-well-being/${id}` } >
//                 Edit
//               </Link>
//             )}

//             {training.can_edit && training.status !== `cancelled` && (
//               <Link to={`#`} onClick={() => this.cancelTraining(id)} className=" ml-2">
//                 Cancel
//               </Link>
//             )}

//             <a className="book-btn ml-2" target="_blank"
//               href= {training.link_url}>
//               Visit Website
//             </a>

//             {!training.can_edit && (
//               // eslint-disable-next-line jsx-a11y/anchor-is-valid
//               <a className="book-btn ml-2" 
//                       onClick={() => this.openShareModal(id)} >
//                 <i className="fas fa-share-alt" /> Share
//               </a>
//             )}
//           </div>
//         </div>
//       </div>
  );

//   checkUncheckbox = (name, event, string) => {
//     this.props.checkUnCheckFilters(name, event.target.checked, string);
//   };

//   searchTrainings = () => {
//     const {search} = this.state;
//     this.props.applyFilters(search);
//   };

//   resetTrainings = () => {
//     this.props.resetFilters();
//   };

//   loadPage = page => {
//     this.props.applyPagination(page);
//   }

//   applyTraining = (id) => {
//     var params = {
//       wellbeing_id:id,
//     };

//     this.props.applyTraining(params).then(res => {
//       var message = res.message;
//       this.props.getTrainings()
//       showSuccessMsg(message ? message : 'Applied successfully.');
//     })
//   }

//   cancelTraining = (id) => {
//     var params = {
//       status:'cancelled',
//     };

//     this.props.updateTrainingStatus(id, params).then(res => {
//       var message = res.message;
//       this.props.getTrainings()
//       showSuccessMsg(message ? message : 'Cancelled.');
//     })
//   }

//   restoreTraining = (id) => {
//     var params = {
//       status:'active',
//     };

//     this.props.updateTrainingStatus(id, params).then(res => {
//       var message = res.message;
//       this.props.getTrainings()
//       showSuccessMsg(message ? message : 'Updated status.');
//     })
//   }

//   showWarning = (message) => {
//     showSuccessMsg(message);
//   }
  render() {
    const { users, isFetchingUsers, totalPages, page } = this.props;
    // const { search, isVisibleShare, trainingId } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main>
          <div className="container-fluid">
            <div className="row team_list">
              <div className="col-md-12"><h2>Trainees & Students</h2></div>
              <div className="col-md-12">
                <form className="search_form">
                  <input type="text" name="search_team" placeholder="Name, PCN, Practice" ></input>
                  <input type="submit" defaultValue="Search" ></input>
                </form>
              </div>
              {!isFetchingUsers && users && users.length > 0 && 
                users.map((user, index) => this.getSingleTraining(user.attributes, index, user.attributes.user_id)
              )}
              {!isFetchingUsers && users && users.length === 0 && (
                <div className="text-center">No users found.</div>
              )}
              {isFetchingUsers && (
                <div className="text-center">
                  <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              
            </div>
            <div aria-label="Page navigation example">
            <ul className="pagination justify-content-center mt-5">
              <li className="page-item"><a className="page-link" href="#">Previous</a></li>
              <li className="page-item active"><a className="page-link" href="#">1</a></li>
              <li className="page-item"><a className="page-link" href="#">2</a></li>
              <li className="page-item"><a className="page-link" href="#">3</a></li>
              <li className="page-item"><a className="page-link" href="#">Next</a></li>
            </ul>
          </div>
        </div>

        </main>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
assessorUserListing.propTypes = {
  getSupervisorBookletsUser:func.isRequired,
//   applyFilters: func.isRequired,
//   checkUnCheckFilters: func.isRequired,
//   getTrainings: func.isRequired,
//   isFetchingUsers: bool.isRequired,
  users: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
//   applyTraining: func.isRequired,
//   updateTrainingStatus: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  users: getUsersListing(),
  isFetchingUsers: getIsFetchingResources(),
  page: getResourcesPage(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getSupervisorBookletsUser,
    // getTrainings,
    // checkUnCheckFilters,
    // applyFilters,
    applyPagination,
    // resetFilters,
    // applyTraining,
    // updateTrainingStatus,
  }
)(withRouter(assessorUserListing));
