const student = [
    '/index',
    '/user-profile',
    '/jobs',
    '/jobs/:jobID',
    '/trainings',
    '/trainings/:id',
    '/placements',
    '/placements/:id',
    '/resources/category',
    '/resources',
    '/resources/category/:categoryId',
    '/resources/:bookLetID',
    '/assessor-resource',
    '/supervisor-resource',
    '/pathway',
    '/news',
    '/news/:newsID',
    '/forums',
    '/training-applied',
    '/previous-booking-training',
    '/events',
    '/events/:id',
    '/resources/:bookLetID/index/:indexID',
    '/well-being',
    '/well-being/:id',
    "/guidelines",
    '/network-details/:id',
    "/pathway",
];
const staff = [
    '/index',
    '/user-profile',
    '/jobs',
    '/jobs/:jobID',
    '/placements/:id',
    '/trainings',
    '/placements',
    '/trainings/:id',
    '/my-pcn',
    '/resources/category',
    '/resources/category/:categoryId',
    '/resources',
    '/assessor-resource',
    '/supervisor-resource',
    '/resources/:bookLetID',
    '/pathway',
    '/news',
    '/news/:newsID',
    '/forums',
    '/training-applied',
    '/previous-booking-training',
    '/events',
    '/events/:id',
    '/resources/:bookLetID/index/:indexID',
    '/revalidation',
    '/revalidation/cpd-log',
    '/revalidation/feedback-log',
    '/revalidation/ref-acc-form',
    '/revalidation/ref-disc-form',
    '/revalidation/practice-hours-log',
    '/revalidation/confirmation-form',
    '/well-being',
    '/well-being/:id',
    "/guidelines",
    '/update-practice-info/:id',
    '/network-details/:id',
    '/users',
    '/add-user',
    '/supervisor/user-listing',
    '/supervisor/user-listing/:id',
    '/supervisor/user-listing/:userId/booklet/:bookletId',
    '/supervisor/user-listing/:userId/booklet/:bookletId/index/:indexId',
    '/assessor/user-listing',
    '/assessor/user-listing/:id',
    '/assessor/user-listing/:userId/booklet/:bookletId',
    '/assessor/user-listing/:userId/booklet/:bookletId/index/:indexId',
    '/ls-forums',
    '/ls-forums/post/:postId',
    '/ls-forums/cat/:catId',
    '/ls-forums/question',
    '/ls-forums/question/:postId',
    // '/teams',
    '/training-analysis',
	'/pathway',
	'/appraisals',
	'/post-appraisals',
	'/appraisals/supervisor',
	'/post-appraisals/:id',
    '/post-appraisals/:id/supervisor',
    "/audit-tool",
];
const educator = [
    '/index',
    '/jobs',
    '/jobs/:jobID',
    '/job-post',
    '/job-post/:id',
    '/job-applicants/:jobId',
    '/training-applicants/:trainingId',
    '/trainings',
    '/training-post',
    '/training-post:id',
    '/post-resource',
    '/news',
    '/news/:newsID',
    // '/forums',
    '/reports',
    '/pathway',
];
const federation = [
    '/index',
    '/jobs',
    '/my-pcn',
    '/polling',
    '/network-details/:id',
    '/jobs/:jobID',
    '/job-post',
    '/job-post/:id',
    '/placements',
    '/placements/:id',
    '/placement-post',
    '/placement-post/:id',
    '/placement-applicants/:placementId',
    '/placement-mentors/:placementId',
    '/job-applicants/:jobId',
    '/training-applicants/:trainingId',
    '/trainings/:id',
    '/trainings',
    '/training-post',
    '/training-post/:id',
    // '/post-resource',
    '/news',
    '/news/:newsID',
    '/post-news/:newsId',
    '/post-news',
    '/assessor-resource',
    '/supervisor-resource',
    // '/post-invoice',
    // '/client-invoices',  // change this to client-invoices
    // '/client-invoices/:id',
    '/forums',
    '/reports',
    '/events',
    '/events/:id',
    '/event-applicants/:eventId',
    '/post-event',
    '/post-event/:id',
    '/post-well-being',
    '/post-well-being/:id',
    '/well-being',
    '/well-being/:id',
    '/well-being-applicants/:trainingId',
    "/post-guidelines",
    "/post-guidelines/:id",
    "/guidelines",
    '/ls-forums',
    '/ls-forums/post/:postId',
    '/ls-forums/cat/:catId',
    '/ls-forums/question',
    '/ls-forums/question/:postId',
    '/teams',
    '/pathway',
	'/training-analysis-report',
	'/appraisals',
];
const manager = [
    '/index',
    '/jobs',
    '/update-practice-info/:id',
    '/network-details/:id',
    '/jobs/:jobID',
    '/trainings',
    '/news',
    '/news/:newsID',
    '/assessor-resource',
    '/supervisor-resource',
    // '/forums',
    '/add-user',
    '/users',
    '/pathway',
    "/audit-tool",
];
const admin = [
    '/index',
    '/update-practice-info/:id',
    '/network-details/:id',
    '/users',
    '/add-user',
    '/my-invoices',
    '/post-invoice',
    '/post-resource',
];
//TODO: Get supervisor removed from federation login

const routesToSkip = [
    '/events',
    '/news',
    "/guidelines",
    // '/index',
    '/user-profile',
    '/update-practice-info/:id',
    '/network-details/:id',
    '/assessor-resource',
    '/supervisor-resource',
    '/post-invoice',
    '/training-applied',
    '/training-applicants/:trainingId',
    '/placement-mentors/:placementId',
    '/trainings/:id',
    '/placements/:id',
    '/previous-booking-training',
    '/post-event',
    '/training-post',
    '/job-post',
    '/placement-post',
    '/post-news',
    '/revalidation/cpd-log',
    '/revalidation/feedback-log',
    '/revalidation/ref-acc-form',
    '/revalidation/ref-disc-form',
    '/revalidation/practice-hours-log',
    '/revalidation/confirmation-form',
    '/post-well-being',
    '/well-being/:id',
    "/post-guidelines",
    '/users',
    '/add-user',
    '/supervisor/user-listing',
    '/supervisor/user-listing/:id',
    '/supervisor/user-listing/:userId/booklet/:bookletId',
    '/supervisor/user-listing/:userId/booklet/:bookletId/index/:indexId',
    '/assessor/user-listing',
    '/assessor/user-listing/:id',
    '/assessor/user-listing/:userId/booklet/:bookletId',
    '/assessor/user-listing/:userId/booklet/:bookletId/index/:indexId',
    // '/resources/category',
    // '/ls-forums',
    '/forums',
    '/ls-forums/question',
	'/training-analysis',
	'/appraisals',
	'/post-appraisals',
	'/appraisals/supervisor',
	'/post-appraisals/:id/supervisor',
    '/post-appraisals/:id',
    '/audit-tool',
];

export {
    student,
    staff,
    educator,
    federation,
    admin,
    manager,
    routesToSkip
}