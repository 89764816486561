import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

// core components
// import AuthFooter from "components/Footers/AuthFooter.jsx";
import { getToken, getLocalStorage } from "utils/helper";

import routes from "routes.js";

class Auth extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    const token = getToken();
    const storage = getLocalStorage();
    if (history && token && storage) {
      const { role } = JSON.parse(storage);
      if (role === 'student' || role === 'staff') {
        history.push('/user/user-profile');
        return;
      }

      history.push('/user/index');
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
			exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="ls-login-wrapper">
          <div className="ls-login-background d-flex align-items-center py-md-5 py-4">
            <section className="row ls-login">
              <div className="col-lg-6 col-md-12 order-lg-1 order-md-2 order-2 mt-2">
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-2 order-md-1 order-1 my-2">
                <Carousel className="ls-height-100 ls-slider-outer" controls={false}>
                  {/* <Carousel.Item className="ls-height-100">
                    <img className="d-block w-100 ls-height-100 ls-object-cover" src={require("assets/images/login/slider-1.jpeg")} alt="First slide" />
                    <Carousel.Caption>
                      <img src={require("assets/images/header-logo.png")} className="img-fluid ls-slider-logo" alt="" />
                      <h5 className="family-poppins-semibold">Ready to Dare?</h5>
                      <p>Advance your career. Pursue your <br /> passion. Keep learning</p>
                    </Carousel.Caption>
                  </Carousel.Item> */}
                  <Carousel.Item className="ls-height-100">
                    <img className="d-block w-100 ls-height-100 ls-object-cover" src={require("assets/images/login/slider-2.jpg")} alt="Second slide" />
                    <Carousel.Caption>
                      {/* <img src={require("assets/images/header-logo.png")} className="img-fluid ls-slider-logo" alt="" /> */}
                      <h5 className="family-poppins-semibold">Ready to Dare?</h5>
                      <p>Advance your career. Pursue your <br /> passion. Keep learning</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Auth);
