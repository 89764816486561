import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import EditFeedbackLog from "components/editFeedback";
import ApproveConfirmation from 'components/approveConfirmation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { filterSalaryByType, filterGreaterValue } from 'utils/helper';
import Loader from 'components/Loader';
import Select from 'react-select';
import * as Yup from 'yup';

/**
 *  Import other dependencies
 */
import { sourcesFeedback, typesFeedback } from './fliters';
import {  getFeedbackLog, deleteFeedbackLog } from '../../../modules/revalidation/feedbackLog/actions';
import {  getFeedbackLogListing, getIsFetchingFeedbackLog } from '../../../modules/revalidation/feedbackLog/selectors';

const addEditJobSchema = Yup.object().shape({
  sourcesFeedback: Yup.string()
    .required('Required'),
  topic: Yup.string()
    .required('Required'),
  typesFeedback: Yup.string()
    .required('Required'),
});

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#FFFFFF',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formattedArray = (array, fieldName, form) => {
  if (fieldName === 'maxSalary') {
    const { values } = form;
    if(values && values.minSalary) {
      const result =  values.minSalary.split(" ");
      const salaryType =  result[result.length - 1];
      const filterData = filterGreaterValue(values.minSalary, filterSalaryByType(salaryType, array));
      array = filterData;
    }
  }
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if (field.name === 'minSalary') {
          form.setFieldValue("maxSalary", ''); // Reset max salary field
        }
        form.setFieldValue(field.name, value.value)
      }}
      value={field.value ? formattedArray([field.value], field.name, form) : ''}
      styles={customStyles}
      options={formattedArray(options, field.name, form)}
    />
  )
};

class FeedbackLog extends React.Component {
  state={
    isVisibleEditFeedbackLog: false,
    // isVisibleAssignAssor: false,
    selectedFeedback: null,
    feedbackId: null,
    isVisibleAprroval: false,
    selectedItem: null,
  }

  deleteFeedback = (id) => {
    this.setState({
      selectedItem: id,
      isVisibleAprroval: true,
    });
  }

  getFormikInitVal = () => {
    return {
      sourcesFeedback: '',
      topic: '',
    }
  }

  componentWillMount() {
    this.props.getFeedbackLog();
  }

  getSinglePlacement = (feedback, index, id) => (
    <React.Fragment key={index}>
      <tr>
        <td className="py-4">{getFormattedDate(feedback.feedback_date)}</td>
        <td className="py-4">{feedback.feedback_source}</td>
        <td className="py-4">{feedback.feedback_type}</td>
        <td className="py-4">{feedback.feedback_content}</td>

        <td className="text-right px-2">
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary lS-btn-icon lS-btn-green-style mr-2" onClick={() => this.handleVisibleFeedbackLog(feedback, id)}>
              <i className="fas fa-edit" />
            </button>
            <button className="btn btn-secondary lS-btn-icon lS-btn-red-style mr-2" onClick={() => this.deleteFeedback(id)}>
              <i className="fas fa-trash" />
            </button>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );

  handleVisibleFeedbackLog = (feedback, id) => {
    this.setState({
      isVisibleEditFeedbackLog: true,
      selectedFeedback: feedback,
      feedbackId: id,
      // bookLetID: this.props.match.params.bookLetID,
    });
  }

  render() {
    const {
      isVisibleEditFeedbackLog,
      selectedFeedback,
      feedbackId,
      isVisibleAprroval,
      selectedItem,
    } = this.state;
    const { feedbackLog, isFetchingFeedbackLog } = this.props;
    return (
      <>
        <UserHeader />
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold mt-2">Feedback log</h4>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-md-5 mt-4 family-poppins-medium">Guide to completing a feedback log</h5>
          <Formik
            initialValues={this.getFormikInitVal()}
            validationSchema={addEditJobSchema}
            onSubmit={() => {}}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="ls-form">
                <div className="row mt-lg-5 mt-4">
                  <div className="col-md-6 col-lg-4 mb-md-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="sourcesFeedback" className="color-grey font-14 mb-3">Examples of sources of feedback</label>
                      <Field as="select" 
                        name="sourcesFeedback" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={sourcesFeedback}
                        id="sourcesFeedback" 
                        placeholder="Select sources of feedback" />
                      <ErrorMessage name="sourcesFeedback" component="div" className="error-msg" />                                  
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-md-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="typesFeedback" className="color-grey font-14 mb-3">Examples of types of feedback</label>
                      <Field as="select" 
                        name="typesFeedback" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={typesFeedback}
                        id="typesFeedback" 
                        placeholder="Select type of feedback" />
                      <ErrorMessage name="typesFeedback" component="div" className="error-msg" />                                  
                    </div>
                  </div>
                  <div className="col-12 mb-3 mt-3 mt-lg-0">
                    <p className="mb-4">
                      Please provide the following information for each of your five pieces of feedback. You should not record any information that might identify an individual, whether that individual is alive or deceased. The section on non-identifiable information in How to revalidate with the NMC provides guidance on how to make sure that your notes do not contain any information that might identify an individual.
                      <br /> <br />
                      You might want to think about how your feedback relates to the Code, and how it could be used in your reflective accounts.
                    </p>
                    <button type="submit" class="btn lS-btn-blue-style lS-btn-blue-large mb-3">Export</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="card border-0 lS-card-shadow lS-card-radius mt-md-4 mt-3 mb-4">
            <div className="card-body">
              <div className="table-responsive ls-table">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col" className="pb-4 text-center" width="120">Dates</th>
                      <th scope="col" className="pb-4" width="330">Source of feedback
                        <span className="d-block color-grey font-11 text-center font-weight-normal">Where did this feedback come from?</span>
                      </th>
                      <th scope="col" className="pb-4" width="330">Type of feedback
                        <span className="d-block color-grey font-11 text-center font-weight-normal">How was the feedback received?</span>
                      </th>
                      <th scope="col" className="pb-4" width="330">Content of feedback
                        <span className="d-block color-grey font-11 text-center font-weight-normal">What was the feedback about and how has it influenced your practice?</span>
                      </th>
                      <th scope="col" className="pb-4 text-right" width="100">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetchingFeedbackLog && feedbackLog && feedbackLog.length > 0 && feedbackLog.map((placement, index) => this.getSinglePlacement(placement.attributes, index, placement.id))}
                    {!isFetchingFeedbackLog && feedbackLog && feedbackLog.length === 0 && (
                      <tr>
                        <td colspan="5">
                          <div className="text-center px-3 radius-10 family-poppins-medium">No Record found</div>
                        </td>
                      </tr>
                    )}
                    {isFetchingFeedbackLog && (
                      <tr>
                        <td colspan="5">
                          <Loader />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="text-right mb-5">
            <button className="btn lS-btn-blue-style lS-btn-blue-large" onClick={() => this.handleVisibleFeedbackLog(null, null)} >
              Add Log
            </button>
          </div>
        </main>
        <UserFooter/>
        <ApproveConfirmation
          isVisible={isVisibleAprroval}
          onClose={()=> {
            this.setState({
              isVisibleAprroval: false,
            });
          }}
          onApprove={()=> {
            this.props.deleteFeedbackLog(selectedItem).then(res => {
              var message = res.message;
              this.props.getFeedbackLog()
              showSuccessMsg(message ? message : 'Deleted successfully.');
              this.setState({
                isVisibleAprroval: false,
              });
            })
          }}
          title={'Delete feedback log'}
        />
        {isVisibleEditFeedbackLog && (
          <EditFeedbackLog
            isVisible={isVisibleEditFeedbackLog}
            onClose={() => {
              this.setState({
                isVisibleEditFeedbackLog: false
              });
            }}
            // /bookLetID={Number(bookLetID)}
            feedbackId={Number(feedbackId)}
            singleFeedback={selectedFeedback}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
FeedbackLog.propTypes = {
  feedbackLog: array.isRequired,
  getFeedbackLog: func.isRequired,
  isFetchingFeedbackLog: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  feedbackLog: getFeedbackLogListing(),
  isFetchingFeedbackLog: getIsFetchingFeedbackLog(),
});

export default connect(
  mapStateToProps,
  {
    getFeedbackLog,
    deleteFeedbackLog
  }
)(withRouter(FeedbackLog));
