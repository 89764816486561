import React from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { func, bool, array, object } from "prop-types";
import { getLocalStorage, getToken, getChatToken, setLocalStorage } from '../../utils/helper';
import { showSuccessMsg } from '../../utils/notification';
import Loader from 'components/Loader';
import * as moment from 'moment';

/**
 *  Import other dependencies
 */
import { 
  updateProfile, 
  getPractice, 
  getEmployments, 
  getEducation, 
  getInstitutionName, 
  deleteEmployment, 
  deleteEducation, 
} from '../../modules/userProfile/actions';
import { getPublicPassport } from '../../modules/auth/actions';
import { getIsfetchingPubProfile, getPublicProfile } from '../../modules/auth/selectors';
import { 
  getIsUpdatingProfile, 
  getIsPractice, 
  getIsEmploymentList, 
  getIsEducationList,
  getIsInstitutionName, 
  getIsFetchingEmployment, 
  getIsFetchingEducation, 
} from '../../modules/userProfile/selectors';
import { getEducationInstitute } from '../../modules/auth/actions';
import { getEducationInstituteList } from '../../modules/auth/selectors';

const dateFormat = 'DD-MMM-YYYY';

class PublicPassport extends React.Component {

  componentWillMount() {
    const { token } = this.props.match.params;
    if (token) {
      const { getPublicPassport } = this.props;
      getPublicPassport(token); // Get public passport
    }
  };

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };


  render() {
    const { 
      publicProfile,
      isfetchingPubProfile
    } = this.props;
    return (
      <>
        {/* Page content */}
        <main className="m-0 px-3 px-md-5">
          {/* bread crum */}
          <div class="pt-md-5">
            <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded">
              <div className="card-body py-0">
                <div className="row">
                  {isfetchingPubProfile && (
                    <Loader />
                  )}

                  {!isfetchingPubProfile && publicProfile && publicProfile.attributes && Object.keys(publicProfile.attributes).length > 0 && (
                    <>
                    <div className="py-4 pt-lg-0 col-xl-3 col-md-6 col-sm-12 order-xl-1 order-md-1 text-center pl-lg-5">
                      <div className="ls-profile-image-container img-hover-wrap d-inline-block">
                        <img src={publicProfile.attributes.profile_image_url} className="img-object-fit radius-10 ls-profile-img d-block mx-auto" alt="User Profile" />
                      </div>
                    </div>
                    <div className="py-md-4 py-0 col-xl-3 col-md-6 order-xl-2 order-md-3 font-20">
                      <div className="d-flex mb-3">
                        <i className="fas fa-user-circle color-primary line-height-common mr-3"></i>
                        <span className="color-grey mr-1">Surname:</span>
                        <span>{publicProfile.attributes.last_name || '-'}</span>
                      </div>
                      <div className="d-flex mb-3">
                        <i className="fas fa-user-circle color-primary line-height-common mr-3"></i>
                        <span className="color-grey mr-1">Given Name:</span>
                        <span>{publicProfile.attributes.first_name || '-'}</span>
                      </div>
                      <div className="d-flex mb-3">
                        <i className="fas fa-mars-stroke color-primary line-height-common mr-3"></i>
                        <span className="color-grey mr-1">Sex:</span>
                        <span>{publicProfile.attributes.gender || '-'}</span>
                      </div>
                      <div className="d-flex">
                        <i className="fas fa-briefcase color-primary line-height-common mr-3"></i>
                        <span className="color-grey mr-1">Occupation:</span>
                        <span>{publicProfile.attributes.occupation  || '-'}</span>
                      </div>
                    </div>
                    <div className="py-4 col-xl-3 col-md-6 order-xl-3 order-md-4 font-20">
                      {publicProfile.has_pin && (
                        <>
                          <div className="d-flex mb-3">
                            <i className="fas fa-calendar-alt color-primary line-height-common mr-3"></i>
                            <span className="color-grey mr-1">Registration Date:</span>
                            <span>{this.getFormattedDate(publicProfile.attributes.date_of_med_registration) || '-'}</span>
                          </div>
                          <div className="d-flex mb-3">
                            <i className="fas fa-calendar-alt color-primary line-height-common mr-3"></i>
                            <span className="color-grey mr-1">Revalidation Date:</span>
                            <span>{this.getFormattedDate(publicProfile.attributes.date_of_revalidation) || '-'}</span>
                          </div>
                          <div className="d-flex mb-3">
                            <i className="fas fa-key color-primary line-height-common mr-3"></i>
                            <span className="color-grey mr-1">PIN Number:</span>
                            <span className="color-light-red">{publicProfile.attributes.pin || '-'}</span>
                          </div>
                        </>                    
                      )}
                    </div>
                    <div className="py-md-4 pt-0 pb-4 col-xl-3 col-md-6 order-xl-4 order-md-2 col-sm-12 ls-profile-form">
                      <div className="upload-file img-hover-wrap ls-height-100">
                        <label className="mb-0 dotted-label-field" htmlFor="signature-image">
                          {publicProfile.attributes.signature_image_url && (
                            <img className="img-object-fit" src={publicProfile.attributes.signature_image_url} alt='signature_image' />
                          )}
                        </label>
                      </div>
                    </div>
                    </>
                  )}
                  {!isfetchingPubProfile && Object.keys(publicProfile).length === 0 && (
                    <div className="text-center w-100 py-4 font-20">No Passport Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Employment Table Added Here */}
          <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded">
            <div className="card-body">
              <div className="d-flex align-items-sm-center flex-column flex-sm-row align-items-start justify-content-between">
                <h4 className="family-poppins-semibold"> Employment </h4>
              </div>
              {/* Table For Employment */}
              <div className="row mt-4">
                <div className="col-xl-12 table-responsive">
                  <table className="table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center" width="200"> Job Title </th>
                        <th scope="col" className="text-center" width="60"> Band </th>
                        <th scope="col" className="text-center" width="200"> Practice Name </th>
                        <th scope="col" className="text-center" width="150"> Start Date </th>
                        <th scope="col" className="text-center" width="150"> End Date </th>
                        <th scope="col" className="text-center" width="150"> Duration </th>
                      </tr>
                    </thead>
                    <tbody>
                    {!isfetchingPubProfile && publicProfile && publicProfile.userEmployments && publicProfile.userEmployments.length > 0 && publicProfile.userEmployments.map((employ, index) => {
                        const {job_title, band, practice_name, start_date, end_date, duration } = employ.attributes
                        return(
                          <tr key={index}>
                            <td className="color-grey text-center align-middle">{job_title}</td>
                            <td className="color-grey text-center align-middle"><span>{band}</span></td>
                            <td className="color-grey text-center align-middle">{practice_name}</td>
                            <td className="color-grey text-center align-middle">{this.getFormattedDate(start_date)}</td>
                            <td className="color-grey text-center align-middle">{this.getFormattedDate(end_date) || `Current Employment.` }</td>
                            <td className="color-grey text-center align-middle">{duration}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!isfetchingPubProfile && publicProfile && publicProfile.userEmployments && publicProfile.userEmployments.length === 0 && (
                    <div className="text-center font-12">No Employments found</div>
                  )}
                  {isfetchingPubProfile && (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>

      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PublicPassport.propTypes = {
  isfetchingPubProfile: bool.isRequired,
  publicProfile: object.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isfetchingPubProfile: getIsfetchingPubProfile(),
  publicProfile: getPublicProfile(),
});

export default connect(
  mapStateToProps,
  {
    getPublicPassport,
  }
)(PublicPassport);
