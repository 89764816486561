import React from "react";
import { func, bool } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";

import { showSuccessMsg } from '../../utils/notification';
/**
 *  Import other dependencies
 */
import { verifyEmail } from '../../modules/auth/actions';
import { getIsVerifyingEmail } from '../../modules/auth/selectors';

class EmailVerify extends React.Component {
  componentWillMount () {
    const { token } = this.props.match.params;
    if (token) {
      this.props.verifyEmail(token).then(res => {
        const { history } = this.props;
        const { message } = res;
        showSuccessMsg(message);
        if (history) {
          history.push('/auth/login');
        }
      });
    }
  }

  render() {
    const { isVerifyingEmail } = this.props;
    return (
      <>
        <div className="d-flex justify-content-center flex-column ls-height-100 align-items-center">
          <div className="row ls-form-content">
            <h2 className="family-poppins-semibold">Verify <br />your Account!</h2>
          </div>
          <span className="border-line">&nbsp;</span>
          <p className="w-75">To stay in touch with us. Please wait until we verifying your account.</p>
          {isVerifyingEmail && (
            <div className="text-center w-75">  
              <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!isVerifyingEmail && (
            <p className="w-75 color-red">Something went wrong!</p>
          )}
        </div>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
EmailVerify.propTypes = {
  isVerifyingEmail: bool.isRequired,
  verifyEmail: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isVerifyingEmail: getIsVerifyingEmail(),
});

export default connect(
  mapStateToProps,
  {
    verifyEmail
  }
)(withRouter(EmailVerify));
