import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import { func, bool, object, number } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';

import { 
	uploadImage,
	updateSingleSupervisor,
	getAssessorBooklets,
	getSupervisorSingleBooklet
} from '../../modules/resources/actions';
import {
  getIsUploadingImage,
} from '../../modules/resources/selectors';

const EditAssessorResourceSchema = Yup.object().shape({
	goalAchieved: Yup.string()
	.required('Required'),
	assessorComments: Yup.string()
	.required('Required'),
});

class EditAssessorResource extends React.Component {
	getFormikInitVal = () => {
		const { assessment } = this.props;
		const {
			goal_achieved,
			assessor_comments,
		} = assessment || {};
		return {
			goalAchieved: goal_achieved === 0 ? false : true,
			assessorComments: assessor_comments ? assessor_comments : "",
			// goalAchieved: false,
			// assessorComments: "",
		}
	};
	
	handleFileUpload = (event, setFieldValue) => {
		const { uploadImage, isUploadingImage } = this.props;
			if (isUploadingImage) return;
			const file = event.target.files[0];
			var formdata = new FormData();
			formdata.append("file", file);
			uploadImage(formdata).then(res => {
			const { url } = res.data;
			setFieldValue('evidenceAttachment', url);
		});
	};
	
	updateSingleSupervisor = (values, { setSubmitting, resetForm }) => {
		const { bookLetIndexID, bookletId, singleTask, updateSingleSupervisor, getAssessorBooklets,getSupervisorSingleBooklet,  assessment } = this.props;
		const assessorCompleted = values.goalAchieved ? true : false 
		const params = {
			assessor_comments: values.assessorComments,
			goal_achieved: Number(assessorCompleted),
		};
		updateSingleSupervisor(params, bookLetIndexID).then(res => {
			const { message } = res;
			this.props.getSupervisorSingleBooklet(bookletId, bookLetIndexID, {
				// booklet_user_id: userId,
				examinerType: 'assessor',
			  });
      		// this.props.getUsersListing();
			// getAssessorBooklets();
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};

	fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <div className="fileUploadCol">
            <label>Attachment</label>
          </div>
          <div className="position-relative fileUploadCol w-100">
            <span>1 Attachment attached</span>
            <div className="selectImgCross" onClick={() => form.setFieldValue('evidenceAttachment', "")}>
              <i class="fas fa-times"></i>
            </div>
          </div>
        </>
      )  
    }

    return (
      <>
		<div className="fileUploadCol position-relative">
			<label>Attachment</label>
			<input 
				type="file"
				onChange={event => this.handleFileUpload(event, form.setFieldValue)}
			/>
			<span>Upload File</span>
		</div>
      </>
    )
  };
	
	render() {
		const {
			isVisible,
			isUploadingImage,
			onClose,
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>Assessor Editor</Modal.Title>
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={EditAssessorResourceSchema}
						onSubmit={this.updateSingleSupervisor}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form>							
								<Modal.Body>
										<div className="form-group">
											<label className="form-label">Comments</label>
											<Field as="textarea" name="assessorComments" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="assessorComments" component="div" className="error-msg" />
										</div>

										<div className="form-check">
											<Field type="checkbox" name="goalAchieved" className="form-check-input" placeholder="Type Here..." />
											<label className="form-label">
												Achieved
											</label>
											<ErrorMessage name="goalAchieved" component="div" className="error-msg" />
										</div>
								</Modal.Body>
								<Modal.Footer>
									<button
										disabled={isSubmitting || isUploadingImage}
										className="btn btn-outline-dark"
										onClick={() => {
											onClose();
										}}
									>
										Cancel
									</button>
									<button type="submit" disabled={isSubmitting || isUploadingImage} className="btn btn-primary">
										{ 
											isSubmitting && (
												<span className="spinner-border spinner-border-sm mr-2"></span>
										)}
										Save
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
EditAssessorResource.propTypes = {
	bookLetIndexID: number.isRequired,
	getAssessorBooklets: func.isRequired,
	getSupervisorSingleBooklet: func.isRequired,
	isVisible: bool.isRequired,
	isUploadingImage: bool.isRequired,
	onClose: func.isRequired,
	singleTask: object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
});

export default connect(
  mapStateToProps,
  {
		uploadImage,
		updateSingleSupervisor,
		getAssessorBooklets,
		getSupervisorSingleBooklet,
  }
)(withRouter(EditAssessorResource));
