const bands = [
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
];
	
const occupations = [
	'Healthcare Assistant' ,
	'Nursing Associate ',
	'General Practice Nurse', 
	'Senior General Practice Nurse', 
	'Nurse Practitioner', 
	'Advance Clinical Practitioner', 
	'Advance Nurse Practitioner',
	'Nurse Consultant',
	'Practice Educator', 
	'Pharmacy Technician', 
	'General Practitioner',
	'Clinical Pharmacist',
	'Community Pharmacist',
	'Community Nurse',
	'District Nurse', 
	'Health Visitor',
	'Midwife',
	'School Nurse', 
	'Corporate team', 
	'Administrator', 
	'Receptionist', 
	'Business Manager', 
	'Practice Manager',
	'Assistant Manager',
	'Clinical Director',
	'Clinical Nurse Specialist',
	'Student',
	'Housekeeper',
	'Carer',
	'Social Care Nurse',
	'Maintainance',
	'Director',
	'Secretary',
];

const userRoles = [
	'federation',
	'educator',
	'manager',
	'staff',
];

const managerUserRoles = [
	'staff',
];
	
export {
	bands,
	occupations,
	userRoles,
	managerUserRoles
};
	