import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import { func, bool, object, number } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import {
	uploadImage,
	addInstituteName,
	getEducation,
} from '../../modules/userProfile/actions';
import {
  getIsUploadingImage,
} from '../../modules/userProfile/selectors';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const AddEducationSchema = Yup.object().shape({
  degreeName: Yup.string()
    .required('Required'),
  degreeImageUrl: Yup.string()
		.required('Required'),
  eduInstId: Yup.string()
	.required('Required'),
  result: Yup.string()
    .required('Required'),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      id: `${item.id}`,
      label: `${item.name}`,
      value: `${item.name}`
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0 mb-2"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if(field.name === 'eduInstId'){
          form.setFieldValue('eduInstId', value.value)
        }
      }}
      value={field.value ? {id: field.id, label: field.value, value: field.value} : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class AddEducation extends React.Component {
	getFormikInitVal = () => {
		return {
			degreeName: "",
			degreeImageUrl: "",
			eduInstId: "",
			result: ""
		}
  	};

  getUserID = value => {
    const { users } = this.props;
    const user = users.filter(user => `${user.name}` == value)
    if (user && user.length > 0) {
      return user[0].id;
    }

    return "";
  };

handleFileUpload = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("file", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('degreeImageUrl', url);
    });
};

	addInstituteName = (values, { setSubmitting, resetForm }) => {
		const { addInstituteName, getEducation } = this.props;
		const params = {
			edu_inst_id: Number(this.getUserID(values.eduInstId)),
			degree_name: values.degreeName,
			degree_image_url: values.degreeImageUrl,
			result: values.result,
			completion_date: values.completion_date ? moment(values.completion_date).format("YYYY-MM-DD HH:mm:ss") : null,
    	};
		addInstituteName(params).then(res => {
			const { message } = res;
			getEducation();
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};

	fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <label className="color-grey pl-3">Certificate</label>
          <div className="upload-attach-col">
						<div className="d-flex justify-content-between">
							<span>1 Attachment attached</span>
							<span className="cursor-pointer" onClick={() => form.setFieldValue('degreeImageUrl', "")}>
								<i className="fas fa-times"></i>
							</span>
						</div>
          </div>
        </>
      )
    }

    return (
      <>
				<label htmlFor="eduction-upload" className="color-grey pl-3">Certificate</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="eduction-upload" onChange={event => this.handleFileUpload(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="file">Choose File</label>
				</div>
      </>
    )
  };

	render() {
		const {
			isVisible,
			isUploadingImage,
      onClose,
      users,
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white">Add Education</Modal.Title>
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={AddEducationSchema}
						onSubmit={this.addInstituteName}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form pt-2">
								<Modal.Body>
										<div className="form-group">
											<label className="color-grey pl-3">Qualification</label>
											<Field type="text" name="degreeName" className="form-control" placeholder="Type Here..." />
											<ErrorMessage name="degreeName" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group">
											{!isUploadingImage && (
												<>
													<Field type="file" component={this.fileUpload} name="degreeImageUrl" className="form-control" />
													<ErrorMessage name="degreeImageUrl" component="div" className="error-msg pl-3 mt-2" />
												</>
											)}
											{isUploadingImage && (
												<div className="text-center">
													<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
														<span className="sr-only">Loading...</span>
													</div>
												</div>
											)}
										</div>
										<div className="form-group">
											<label className="color-grey pl-3 mt-2">Institution</label>
											<Field as="select" name="eduInstId" placeholder="Search Institution" component={formSelect} options={users} />
										  <ErrorMessage name="eduInstId" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group">
											<label className="color-grey pl-3">Result</label>
											<Field type="text" name="result" className="form-control" placeholder="Result" />
											<ErrorMessage name="result" component="div" className="error-msg pl-3 mt-2" />
										</div>
										<div className="form-group">
											<label className="color-grey pl-3">Completion Date</label>
											<Datetime
											inputProps={{placeholder: 'Select Date'}}
											renderInput={ this.renderCustomInput }
											closeOnSelect={true}
											//  isValidDate={ valid }
											onChange={completion_date => {
												if (typeof completion_date !== "object") return;
												setFieldValue('completion_date', completion_date)
											}}
											value={values['completion_date']}
											/>
											<ErrorMessage name="completion_date" component="div" className="error-msg pl-3 mt-2" />
										</div>
								</Modal.Body>
								<Modal.Footer className="border-0">
									<div className="row pb-3 w-100">
										<div className="col-md-6">
											<button
												disabled={isSubmitting || isUploadingImage}
												className="btn btn-secondary w-100"
												onClick={() => {
													onClose();
												}}
											>
												Cancel
											</button>
										</div>
										<div className="col-md-6">
											<button type="submit" disabled={isSubmitting || isUploadingImage} className="btn btn-primary lS-btn-blue-style w-100">
												{
													isSubmitting && (
														<span className="spinner-border align-middle spinner-border-sm mr-2"></span>
												)}
												Save
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
AddEducation.propTypes = {
	bookLetID: number.isRequired,
	bookLetIndexID: number.isRequired,
	getEducation: func.isRequired,
	isVisible: bool.isRequired,
	isUploadingImage: bool.isRequired,
	onClose: func.isRequired,
	singleTask: object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
});

export default connect(
  mapStateToProps,
  {
		uploadImage,
		addInstituteName,
		getEducation,
  }
)(withRouter(AddEducation));
