import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import { func, bool, array, string } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import {getCategories, getPost, getMyPosts,addForumPost, updateForumPost} from '../../modules/forums/actions';
import {getCategoriesList, getPostDetail, getIsFetchingPost, getMyPostsList, getIsFetchingMyPosts, getIsFetchingCategories} from '../../modules/forums/selectors';
import { uploadImage,} from '../../modules/policyDocPost/actions';
import { getIsUploadingImage } from '../../modules/policyDocPost/selectors';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const addEditSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  description: Yup.string()
    .required('Required'),
  forumCategory:Yup.string()
    .required('Required'),
});

const formattedCategoryArray = array => {

  return array.map(item => {
    return {
      label: item.attributes.name,
      value: item.id
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelectCategory = props => {
  const { form, options, placeholder, field } = props;
  // if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue('forumCategory', value.value);
      }}
      value={field.value ? formattedCategoryArray(options.filter(option => Number(option.id) === Number(field.value))) : ''}
      styles={customStyles}
      options={formattedCategoryArray(options)}
    />
  )
};

class PostForums extends React.Component {
	componentWillMount() {
    const { mode, postId } = this.props;
    if (mode === 'edit' && postId) {
      this.props.getPost(this.props.match.params.postId).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/ls-forums');
        }
      });
    }
	}
	
	getFormikInitVal = () => {
		const { post, mode } = this.props;
    
    if (mode === 'edit') {
      const {
        title,
        description,
        forum_category_id,
        attachment_url,
      } = post || {};

      return {
        title,
        description,
        forumCategory:forum_category_id,
        docURL: attachment_url,
      }
    }

    return {
      title: "",
      description: "",
      forumCategory: "",
    }
  };

	handleFileUpload = (event, setFieldValue) => {
		const { uploadImage, isUploadingImage } = this.props;
		if (isUploadingImage) return;
		const file = event.target.files[0];
		var formdata = new FormData();
		formdata.append("file", file);
		uploadImage(formdata).then(res => {
			const { url } = res.data;
			setFieldValue('docURL', url);
		});
	};

  postJob = (values, { setSubmitting, resetForm }) => {
    const { addForumPost,updateForumPost, history, mode, onClose } = this.props;
    var params = {
      title: values.title,
      description: values.description,
      forum_category_id: values.forumCategory
    };

    if (values.docURL) {
      params.attachment_url = values.docURL;
    }

    if( mode === 'edit' ){
      updateForumPost(params, this.props.match.params.postId).then(res => {
        showSuccessMsg("Successfully updated");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
				onClose(true);
      }, err => {
        setSubmitting(false);
      });
    }

    if( mode === 'add' ){
      addForumPost(params).then(res => {
        showSuccessMsg("Success.");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        onClose(true);
      }, err => {
        setSubmitting(false);
      });
    }
	};

	fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <label className="color-grey pl-3">Certificate</label>
          <div className="upload-attach-col">
						<div className="d-flex justify-content-between">
							<span>1 Attachment attached</span>
							<span className="cursor-pointer" onClick={() => form.setFieldValue('docURL', "")}>
								<i className="fas fa-times"></i>
							</span>
						</div>
          </div>
        </>
      )
    }

    return (
      <>
				<label htmlFor="eduction-upload" className="color-grey pl-3">Upload File</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="eduction-upload" onChange={event => this.handleFileUpload(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="file">Choose File</label>
				</div>
      </>
    )
  };

	render() {
		const{ categories, myPosts, isVisible, isUploadingImage, isFetchingCategories,isFetchingPost, isFetchingMyPosts, mode, onClose } = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white">Start New Topic</Modal.Title>
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={addEditSchema}
						onSubmit={this.postJob}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form pt-2">
								<Modal.Body>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group mb-4">
												<label htmlFor className="color-grey pl-3">Title</label>
												<Field type="text" name="title" className="form-control" placeholder="Type Here..." />
												<ErrorMessage name="title" component="div" className="error-msg pl-3 mt-2" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group mb-4">
												<label htmlFor className="color-grey pl-3">Category</label>
												<Field as="select" name="forumCategory" placeholder="Select Category" component={formSelectCategory} options={categories} />
												<ErrorMessage name="forumCategory" component="div" className="error-msg pl-3 mt-2" />
											</div>
										</div>
										<div className="col-md-12">
                      <div className="form-group mb-4">
                        {!isUploadingImage && (
                          <div className="upload-file mt-2">
                            <Field type="file" component={this.fileUpload} name="docURL" className="form-control" />
                          </div>
                        )}
                        {isUploadingImage && (
                          <div className="text-center">
                            <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label htmlFor className="color-grey pl-3">Description</label>
												<Field as="textarea" name="description" rows={3} className="form-control" placeholder="Type Here..." />
												<ErrorMessage name="description" component="div" className="error-msg pl-3 mt-2" />
											</div>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer className="border-0">
									<div className="row pb-3 w-100">
										<div className="col-md-6">
											<button
												disabled={isSubmitting || isUploadingImage}
												className="btn btn-secondary w-100 py-2 mb-3"
												onClick={() => {
													onClose();
												}}
											>
												Cancel
											</button>
										</div>
										<div className="col-md-6">
											<button type="submit" disabled={isSubmitting || isUploadingImage} className="py-2 btn btn-primary lS-btn-blue-style w-100">
												{
													isSubmitting && (
														<span className="spinner-border align-middle spinner-border-sm mr-2"></span>
												)}
												Save
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
PostForums.propTypes = {
	isVisible: bool.isRequired,
	onClose: func.isRequired,
	post: Object.isRequired,
	posts: array.isRequired,
	postId: string.isRequired,
  myPosts: array.isRequired,
  categories: array.isRequired,
  getCategories: func.isRequired,
	addForumPost:func.isRequired,
	mode: string.isRequired,
  uploadImage: func.isRequired,
  isUploadingImage: Boolean.isRequired,
  isFetchingCategories: Boolean.isRequired,
  isFetchingPost: Boolean.isRequired,
  isFetchingMyPosts: Boolean.isRequired,
};

const mapStateToProps = createStructuredSelector({
	post: getPostDetail(),
  isFetchingPost: getIsFetchingPost(),
  categories: getCategoriesList(),
  isUploadingImage: getIsUploadingImage(),
  isFetchingCategories: getIsFetchingCategories(),
  isFetchingMyPosts: getIsFetchingMyPosts(),
  myPosts: getMyPostsList(),
});

export default connect(
  mapStateToProps,
  {
		getPost,
    addForumPost,
    getCategories,
    getMyPosts,
    uploadImage,
    updateForumPost
  }
)(withRouter(PostForums));
