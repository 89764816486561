/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
    GET_USERS,
    //   GET_LIST_OF_JOBS,
    //   GET_LIST_OF_JOB_APPLICANTS,
    //   GET_SINGLE_JOB,
    //   GET_LIST_OF_JOB_OCCUPATIONS,
    //   SELECT_CHECKBOX,
    //   UNSELECT_CHECKBOX,
    APPLY_FILTERS,
    RESET_FILTERS,
    APPLY_PAGINATION,
    CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
    users: [],
    isFetchingUsers: true,
    post: null,
    isFetchingPost: true,
    posts: [],
    isFetchingPosts: true,
    limit: 10,
    page: 1,
    totalPage: 0,
    jobs: [],
    jobOccupations: [],
    jobApplicants: [],
    search: '',
    range: 150000,
    selectedOccupations: [],
    selectedLocations: [],
    isFetchingJobs: true,
    isFetchingJobApplicants: true,
    isFetchingSingleJob: true,
    singleJob: null,
    selectedTypes: []
});

/**
 *  Define the reducer with actions
 */
function teamReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            return fromJS({
                ...state.toJS(),
                isFetchingUsers: true,
            })

        case success(GET_USERS):
            const { data } = action.payload.data;
            const { total_pages } = action.payload.data.meta.pagination;
            return fromJS({
                ...state.toJS(),
                users: data && data.length > 0 ? data : [],
                totalPage: total_pages,
                isFetchingUsers: false,
            })

        case failure(GET_USERS):
            return fromJS({
                ...state.toJS(),
                isFetchingUsers: false,
            })

        case APPLY_FILTERS:
            return fromJS({
                ...state.toJS(),
                search: action.search,
                page: 1
            })

        case APPLY_PAGINATION:
            return fromJS({
                ...state.toJS(),
                page: action.page
            })

        case RESET_FILTERS:
        case CANCEL_ALL_API_REQUESTS:
            return fromJS({
                ...initialState.toJS()
            })

        default:
            return state
    }
};

/**
 *  Export the reducer
 */
export default teamReducer