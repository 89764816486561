/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_AUDIT,
  SAVE_AUDIT,
  SET_AUDIT,
  CANCEL_ALL_API_REQUESTS,
} from './constants';

const defaultAudit = {
  step1: null,
  step2: null,
  step3: null,
  step4: null,
  step5: null,
};

/**
 *  Set intial state
 */
const initialState = fromJS({
  audit: defaultAudit,
  isFetchingAudit: true,
  isSavingAudit: false,
});

/**
 *  Define the reducer with actions
 */
function auditToolReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AUDIT:
      return fromJS({
        ...state.toJS(),
        isFetchingAudit: true,
      })

    case success(GET_AUDIT):
      const { data } = action.payload.data;

      return fromJS({
        ...state.toJS(),
        audit: data && data.attributes && data.attributes.user_data ? data.attributes.user_data : defaultAudit,
        isFetchingAudit: false,
      })

    case failure(GET_AUDIT):
      return fromJS({
        ...state.toJS(),
        isFetchingAudit: false,
      })

    case SAVE_AUDIT:
      return fromJS({
        ...state.toJS(),
        isSavingAudit: true,
      })

    case success(SAVE_AUDIT):
      return fromJS({
        ...state.toJS(),
        isSavingAudit: false,
      })

    case failure(SAVE_AUDIT):
      return fromJS({
        ...state.toJS(),
        isSavingAudit: false,
      })

    case SET_AUDIT:
      return fromJS({
        ...state.toJS(),
        audit: action.audit,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default auditToolReducer
