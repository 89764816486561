/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  LIST_EDUCATION_INSTITUTE,
  LIST_PRACTICES,
  VERIFY_EMAIL,
  PUBLIC_PASSPORT,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  isVerifyingEmail: true,
  educationInstitute: [],
  practices: [],
  isfetchingPubProfile: true,
  publicProfile: {},
});

/**
 *  Define the reducer with actions
 */
function authReducer(state = initialState, action) {
  switch (action.type) {
    case success(LIST_EDUCATION_INSTITUTE):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        educationInstitute: data && data.length > 0 ? data : [],
    })
    
    case success(LIST_PRACTICES):
      const list = action.payload.data.data;
      return fromJS({
        ...state.toJS(),
        practices: list && list.length > 0 ? list : [],
	  })

    case VERIFY_EMAIL:
      return fromJS({
        ...state.toJS(),
        isVerifyingEmail: true,
      })

    case success(VERIFY_EMAIL):
    case failure(VERIFY_EMAIL):
      return fromJS({
        ...state.toJS(),
        isVerifyingEmail: false,
      })
    
    case PUBLIC_PASSPORT:
      return fromJS({
        ...state.toJS(),
        isfetchingPubProfile: true,
      })

    case success(PUBLIC_PASSPORT):
      //filter data
      action.payload.data.data.userEmployments = []; // declear empty keys
      action.payload.data.data.userEducations = [];
      action.payload.data.data.relationships.user_employments.data.map(employment => {
        const { included } = action.payload.data;
        const res = included.find(
          include =>
          employment.id === include.id && include.type === 'UserEmployment'
        );
        action.payload.data.data.userEmployments = [...action.payload.data.data.userEmployments, ...[res]];
        return employment;
      });
      action.payload.data.data.relationships.user_educations.data.map(education => {
        const { included } = action.payload.data;
        const res = included.find(
          include =>
          education.id === include.id && include.type === 'UserEducation'
        );
        action.payload.data.data.userEducations = [...action.payload.data.data.userEducations, ...[res]];
        return education;
      });
      delete action.payload.data.data.relationships;
      return fromJS({
        ...state.toJS(),
        publicProfile: action.payload.data.data,
        isfetchingPubProfile: false,
      })

    case failure(PUBLIC_PASSPORT):
      return fromJS({
        ...state.toJS(),
        isfetchingPubProfile: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default authReducer
