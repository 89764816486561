/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_CLIENTS_NAME,
  GET_LIST_OF_MY_INVOICE_LIST,
  GET_LIST_OF_CLIENT_INVOICE_LIST,
  GET_LIST_OF_CLIENT_INVOICE_BY_ID,
  UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 100000,
  page: 1,
  clientsName: [],
  myInvoiceLists: [],
  clientInvoiceLists: [],
  clientInvoiceById: {},
  isFetchingClientsName: true,
  isFetchingMyInvoiceLists: true,
  isUploadingImage: true,
});

/**
 *  Define the reducer with actions
 */
function postInvoicesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_CLIENTS_NAME:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
        isFetchingClientsName: true,
      })

    case success(GET_LIST_OF_CLIENTS_NAME):
      const invoiceData  = action.payload.data.data;
      return fromJS({
        ...state.toJS(),
        clientsName: invoiceData && invoiceData.length > 0 ? invoiceData : [],
        isUploadingImage: false,
        isFetchingClientsName: false,
      })

    case failure(GET_LIST_OF_CLIENTS_NAME):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
        isFetchingClientsName: false,
      })
    
    case GET_LIST_OF_MY_INVOICE_LIST:
      return fromJS({
        ...state.toJS(),
        isFetchingMyInvoiceLists: true,
      })

    case success(GET_LIST_OF_MY_INVOICE_LIST):
      const myInvoiceList = action.payload.data.data;
      return fromJS({
        ...state.toJS(),
        myInvoiceLists: myInvoiceList && myInvoiceList.length > 0 ? myInvoiceList : [],
        isFetchingMyInvoiceLists: false,
      })

    case failure(GET_LIST_OF_MY_INVOICE_LIST):
      return fromJS({
        ...state.toJS(),
        isFetchingMyInvoiceLists: false,
      })

    case GET_LIST_OF_CLIENT_INVOICE_LIST:
      return fromJS({
        ...state.toJS(),
        isFetchingMyInvoiceLists: true,
      })

    case success(GET_LIST_OF_CLIENT_INVOICE_LIST):
      const clientInvoiceList = action.payload.data.data;
      return fromJS({
        ...state.toJS(),
        clientInvoiceLists: clientInvoiceList && clientInvoiceList.length > 0 ? clientInvoiceList : [],
        isFetchingMyInvoiceLists: false,
      })

    case failure(GET_LIST_OF_CLIENT_INVOICE_LIST):
      return fromJS({
        ...state.toJS(),
        isFetchingMyInvoiceLists: false,
      })

    case GET_LIST_OF_CLIENT_INVOICE_BY_ID:
      return fromJS({
        ...state.toJS(),
        isFetchingMyInvoiceLists: true,
      })

    case success(GET_LIST_OF_CLIENT_INVOICE_BY_ID):
      const clientInvoiceById = action.payload.data.data;
      return fromJS({
        ...state.toJS(),
        clientInvoiceById: clientInvoiceById ? clientInvoiceById : {},
        isFetchingMyInvoiceLists: false,
      })

    case failure(GET_LIST_OF_CLIENT_INVOICE_BY_ID):
      return fromJS({
        ...state.toJS(),
        isFetchingMyInvoiceLists: false,
      })
    
    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })
    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default postInvoicesReducer
