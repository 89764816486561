/**
 *  Import action creator constants & dependencies
 */
import {
  GET_REPORTS_DATA,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
const cancelApiRequests = [];

/**
 *  Get List of Jobs
 */
export function getReportsData() {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getReportsDataBegin(
          API_URLS.REPORTS.GET_REPORTS_DATA,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getReportsDataBegin = (API_URL, source) => ({
  type: GET_REPORTS_DATA,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      cancelToken: source.token
    }
  }
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
