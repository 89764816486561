import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import { getBooklets  } from '../../../modules/resources/actions';
import { getBookLetsListing, getIsFetchingResources  } from '../../../modules/resources/selectors';

const totalValidations= [
  {
    title: 'CPD LOG',
    link: '/user/revalidation/cpd-log',
  },
  {
    title: 'FEEDBACK LOG',
    link: '/user/revalidation/feedback-log',
  },
  {
    title: 'REFLECTIVE ACCOUNT FORM',
    link: '/user/revalidation/ref-acc-form',
  },
  {
    title: 'REFLECTIVE DISCUSSION FORM',
    link: '/user/revalidation/ref-disc-form',
  },
  {
    title: 'PRACTICE HOURS LOG',
    link: '/user/revalidation/practice-hours-log',
  },
  {
    title: 'CONFIRMATION FORM',
    link: '/user/revalidation/confirmation-form',
  }
];

class Revalidation extends React.Component {
  componentWillMount() {
    // this.props.getBooklets()
  }

  redirectTo = link => {
    if(!link) return;

    const { history } = this.props;
    history.push(link);
  };

  getSingleRevalidationDoc = (policydoc, index) => (
    <div className="col-lg-4 col-sm-6 px-xl-4 mb-4" key={index}>
      <div className="card border-0 lS-card-shadow lS-card-radius ls-height-100">
        <div className="card-body d-flex align-items-center">
          <div className="thumb-wrap thumb-wrap-100 radius-10 border ls-border-color">
            <img src={require("assets/images/guidelines/pdf.png")} alt="..." className="img-fluid w-75" />
          </div>
          <div className="min-width0 flex-fill pl-3">
            <div className="d-flex justify-content-between flex-column ls-height-100">
              <h5 className="mt-0 font-normal color-black-heading text-truncate family-poppins-medium">{policydoc.title}</h5>
              <div className="action-buttons d-flex align-items-center flex-wrap mt-1">
                <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2" onClick={() => this.redirectTo(policydoc.link)}>
                  <i className="fas fa-eye"></i>
                </button>
              
                <button className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2" disabled>
                  <i className="fas fa-download"></i>
                </button>
  
                <button className="btn btn-primary lS-btn-icon lS-btn-blue-style color-white rounded-lg mt-2 mr-2" disabled>
                  <i className="fas fa-user"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const { bookLets, isFetchingBooklets } = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold">Revalidation</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
              </div>
            </div>
          </div>
          {/* GuideLine Page */}
          <div className="row mx-xl-n4 pt-md-5 pt-4">
            {totalValidations && totalValidations.length > 0 && totalValidations.map((job, index) => this.getSingleRevalidationDoc(job, index))}
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Revalidation.propTypes = {
  bookLets: array.isRequired,
  getBooklets: func.isRequired,
  isFetchingBooklets: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  bookLets: getBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
    getBooklets
  }
)(withRouter(Revalidation));
