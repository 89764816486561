/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_EVENTS,
  UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  events: {},
  isFetchingEvents: false,
  isUploadingImage: false,
  isDeletingEvent: false,
});

/**
 *  Define the reducer with actions
 */
function postEventsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_EVENTS:
      return fromJS({
        ...state.toJS(),
        isFetchingEvents: true,
      })

    case success(GET_LIST_OF_EVENTS):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        events: data ? data : {},
        isFetchingEvents: false,
      })

    case failure(GET_LIST_OF_EVENTS):
      return fromJS({
        ...state.toJS(),
        isFetchingEvents: false,
      })
    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })
    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default postEventsReducer
