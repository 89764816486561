export const GET_CATEGORIES =
    'src/modules/forums/GET_CATEGORIES';
export const GET_POSTS =
    'src/modules/forums/GET_POSTS';
export const GET_POLLS =
    'src/modules/forums/GET_POLLS';
export const VOTE_A_POLL =
    'src/modules/forums/VOTE_A_POLL';
export const CREATE_POSTS =
    'src/modules/forums/CREATE_POSTS';
export const GET_POST =
    'src/modules/forums/GET_POST';
export const UPDATE_POST =
    'src/modules/forums/UPDATE_POST';
export const GET_MY_POSTS =
    'src/modules/forums/GET_MY_POSTS';
export const CANCEL_ALL_API_REQUESTS =
    'src/modules/forums/CANCEL_ALL_API_REQUESTS';
export const APPLY_PAGINATION =
    'src/modules/forums/APPLY_PAGINATION';
export const APPLY_FILTERS =
    'src/modules/forums/APPLY_FILTERS';
export const RESET_FILTERS =
    'src/modules/forums/RESET_FILTERS';