import React from "react";
import { func, bool, object } from "prop-types";
import Modal from 'react-bootstrap/Modal';

class CovidProfile extends React.Component {
	render() {
		const {
			isVisible,
			userProfile,
      onClose,
    } = this.props;
    console.log(userProfile);
    const {
      profile_image_url,
      first_name,
      last_name,
      occupation,
    } = userProfile || {};
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static" dialogClassName="modal-90w">
					<Modal.Header closeButton>
						<Modal.Title>Covid 19 Profile</Modal.Title>
					</Modal.Header>
          <Modal.Body>
            <main className="pb-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="practice_detail col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <img src={profile_image_url} className="img-fluid" alt="user-profile"/>
                        <div className="team_detail_bottom">
                          <h5>ASK ME ABOUT</h5>
                          <ul>
                            <li>What is Lorem Ipsum?</li>
                            <li><span>Yes</span></li>
                            <li><span>Web Design</span></li>
                            <li><span>Usability</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 top_list_detail">
                        <h3>{first_name || '-'}{' '}{last_name || '-'}</h3>
                        <p>PRODUCT MANAGER</p>
                        <p><span>{occupation} </span></p>
                        <p><i className="fas fa-map-marker-alt" /> Currently in London</p>
                        <div className="list-icons">
                          <ul>
                            <li><span><i className="fas fa-envelope-open" /> </span> Demo@demo.com</li>
                            <li><span><i className="fas fa-fax" /> </span> AB789ER</li>
                            <li><span><i className="fas fa-fax" /> </span> Test</li>
                            <li><span><i className="fas fa-fax" /> </span> Test</li>
                            <li><span><i className="fas fa-fax" /> </span> Test</li>
                            <li><span><i className="fas fa-fax" /> </span> Test</li>
                          </ul>
                        </div>
                        <p><button className="message_btn">Message</button></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Modal.Footer>
              <button
                className="btn btn-outline-dark"
                onClick={() => {
                  onClose();
                }}
                >
                Cancel
              </button>
            </Modal.Footer>
          </Modal.Body>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
CovidProfile.propTypes = {
	isVisible: bool.isRequired,
  onClose: func.isRequired,
  userProfile: object.isRequired,
};

export default CovidProfile;
