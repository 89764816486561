/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_EVENTS,
  APPLY_PAGINATION,
  RESET_FILTERS,
  ADD_EVENTS,
  UPLOAD_IMAGE,
  UPDATE_EVENTS,
  DELETE_EVENTS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib';
const cancelApiRequests = [];

/**
 *  Get List of Events
 */
export function getEvents(id) {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getEventsBegin(
          API_URLS.POST_EVENTS.GET_EVENTS + id,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getEventsBegin = (API_URL, source) => ({
  type: GET_LIST_OF_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});


/**
 *  Add List of Event
 */
export function addPostEvent(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addPostEventBegin(
          API_URLS.POST_EVENTS.ADD_EVENTS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addPostEventBegin = (API_URL, params, source) => ({
  type: ADD_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Delete List of Events
 */
export function deletePostEvent(eventId) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        deletePostEventBegin(
          API_URLS.POST_EVENTS.DELETE_EVENTS + eventId,
          eventId,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const deletePostEventBegin = (API_URL, eventId, source) => ({
  type: DELETE_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'delete',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  eventId
});

/**
 *  update List of event
 */
export function updatePostEvent(params, id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updatePostEventBegin(
          API_URLS.POST_EVENTS.UPDATE_EVENTS + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updatePostEventBegin = (API_URL, params, source) => ({
  type: UPDATE_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getEvents());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Reset filters
 */
export function resetFilters() {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getEvents());
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});

/**
 *  Upload Image
 */
export function uploadImage(formData) {
  return async dispatch => {
    try {
      const response = await dispatch(
        uploadImageBegin(
          API_URLS.IMAGE_UPLOAD,
          formData
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const uploadImageBegin = (API_URL, params) => ({
  type: UPLOAD_IMAGE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  }
});
