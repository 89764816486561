import React from "react";
import Modal from 'react-bootstrap/Modal';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { func, bool, object, string } from "prop-types";

/**
 *  Import other dependencies
 */
import { uploadImage, setLoader } from '../../modules/imageCroppingModal/actions';
import { getIsUploadingImage } from '../../modules/imageCroppingModal/selectors';

class ImageCroppingModal extends React.Component {
  state = {
    image: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 16 / 9,
    },
    croppedFile: null
  }

  componentWillMount () {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({image: e.target.result});
    };
    reader.readAsDataURL(this.props.file);
  }

  uploadImage = () => {
    const { croppedFile } = this.state;
    const { uploadImage, setLoader, callBackFunc, onClose, onCompleted, imageType } = this.props;
    if (!croppedFile) return;
    var formdata = new FormData();
    formdata.append("image", croppedFile);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      const params = {};
      if (imageType === 'profile') {
        params.profile_image_url = url;
      }

      if (imageType === 'signature') {
        params.signature_image_url = url;
      }
      callBackFunc(params).then(res => {
        setLoader(false);
        onCompleted(res);
        onClose();
      }, err => {
        setLoader(false);
      });
    });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    const { file } = this.props;
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        file.name,
        file.type
      );
      this.setState({ croppedFile: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName, fileType) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, fileType);
    });
  }

  render() {
    const { isVisible, onClose, isUploading } = this.props;
    const { image, crop } = this.state;
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header className="background-primary justify-content-center">
            <Modal.Title className="color-white">Image Cropping</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactCrop
              src={image}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div class="row pb-3 w-100">
              <div class="col-md-6">
                <button disabled={isUploading} className="btn btn-secondary w-100" onClick={onClose}>
                  Cancel
                </button>
              </div>
              <div class="col-md-6">
                <button disabled={isUploading} className="btn btn-primary lS-btn-blue-style w-100" onClick={this.uploadImage}>
                  {isUploading && (
                    <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                  )}
                  <span>
                    Save Image
                  </span>
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
ImageCroppingModal.propTypes = {
  callBackFunc:func.isRequired,
  imageType: string.isRequired,
  isUploading: bool.isRequired,
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  file: object.isRequired,
  onCompleted: func.isRequired,
  uploadImage: func.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUploading: getIsUploadingImage()
});

export default connect(
  mapStateToProps,
  {
    uploadImage,
    setLoader
  }
)(ImageCroppingModal);
