/**
 * Import Node Modules
 */
import React, { Component } from 'react';
import { func, bool, array, number } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import ReactPaginate from 'react-paginate';

/**
 * Import Other Dependencies
 */
import Share from "components/share";
import FeedbackMyBooking from "components/feedbackMyBooking";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import StarRating from 'components/starRating';
import { showSuccessMsg } from '../../utils/notification';
import { getFormattedDate } from '../../utils/helper';
import {
  getTrainingApplied,
  applyTrainingAppliedPagination,
  updateTrainingAppliedStatus,
} from '../../modules/trainings/actions';
import {
  getTrainingAppliedListing,
  getTotalTrainingAppliedPages,
  getTrainingAppliedPage,
  getIsFetchingTrainingApplied,
} from '../../modules/trainings/selectors';

class MyBooking extends Component {
  state = {
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShareProfile: false,
    isVisibleFeedbackProfile: false,
    trainingId: 0,
    toggleValue: 'upcoming'
  }
  openShareModal(id) {
    this.setState({
      isVisibleShareProfile: true,
      trainingId: id,
    });
  }
  openFeedbackModal(id) {
    this.setState({
      isVisibleFeedbackProfile: true,
      trainingId: id,
    });
  }
  componentWillMount() {
    let params = [ 'pending', 'accepted' ];
    this.props.getTrainingApplied(params);
  }
  loadPage = page => {
    this.props.applyTrainingAppliedPagination(page);
  }
  updateAppliedStatus = (trainingId, status) => {
    let params = {
      training_id: trainingId,
      status: status
    }
    this.props.updateTrainingAppliedStatus(params).then(res => {
      const { message } = res;
      if(this.state.toggleValue === 'completed'){
        let params = [ 'completed' ];
        this.props.getTrainingApplied(params);
      }else{
        let params = [ 'pending', 'accepted' ];
        this.props.getTrainingApplied(params);
      }
      showSuccessMsg(message);
    });
  }
  onCompleteImageCrop = res => {
    const { data, included } = res;
    const includeProfile =
      included.find(
        include =>
          data.relationships.user_profile.data.id === include.id
      );
    let combineObj = { ...data.attributes, ...includeProfile.attributes };
    this.setState({
      userProfile: combineObj
    });
    showSuccessMsg('Profile updated successfully');
  };

  changeTab = selectedValue => {
    this.setState({toggleValue: selectedValue})
    if(selectedValue === 'completed'){
      let params = [ 'completed' ];
      this.props.getTrainingApplied(params);
    }else{
      let params = [ 'pending', 'approved' ];
      this.props.getTrainingApplied(params);
    }
  }

  render() {
    const { dummyBaseURl, isVisibleShareProfile, isVisibleFeedbackProfile, trainingId, toggleValue } = this.state;
    const {
      isFetchingTrainingApplied,
      trainingsApplied,
      totalTrainingsAppliedPage,
      page
    } = this.props;
    return (
      <>
        <UserHeader />
        {/* body of page */}
        <main className="m-0 px-md-5 px-3 py-5">
          {/* bread crum */}
          <div className="card page-title-bar border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="family-poppins-semibold">My Booking</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
              </div>
            </div>
          </div>

          {/* Booking Tabs */}
          <ul className="nav nav-tabs ls-nav-tabs mt-4" id="myTab" role="tablist">
            <li className="nav-item cursor-pointer" onClick={() => this.changeTab('upcoming')}>
              <a className={`nav-link ${toggleValue === 'upcoming' && 'active'}`}> Upcoming </a>
            </li>
            <li className="nav-item cursor-pointer" onClick={() => this.changeTab('completed')}>
              <a className={`nav-link ${toggleValue === 'completed' && 'active'}`}> Completed </a>
            </li>
          </ul>

          <div className="tab-content my-4 ls-tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
              <div className="table-responsive ls-table">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col" className="pb-4 text-left" width="100"> Image </th>
                      <th scope="col" className="pb-4" width="300"> Title </th>
                      <th scope="col" className="pb-4" width="150"> CPD Hours </th>
                      <th scope="col" className="pb-4" width="150"> Organizer </th>
                      <th scope="col" className="pb-4" width="150"> Status </th>
                      <th scope="col" className="pb-4" width="150"> Date booked </th>
					            {toggleValue === 'completed' && (
                      	<th scope="col" className="pb-4" width="230"> Rating </th>
					            )}
                      <th scope="col" className="pb-4" width="150"> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetchingTrainingApplied && trainingsApplied && trainingsApplied.length > 0 && trainingsApplied.map((training, index) => {
                      const {image_url, title, cpd_hours, feedback, org_name, application_status, created_at, average_rating, my_rating, rating_count} = training.attributes;
                      return(
                        <tr>
                          <td className="py-4 text-left"> 
                            <img src={image_url} alt="..." width="40" height="40" className="img-fluid ls-object-cover rounded-circle ls-table-image" />
                          </td>
                          <td className="py-4"> {title} </td>
                          <td className="py-4"> {cpd_hours || '--'} </td>
                          <td className="py-4"> {org_name} </td>
                          <td className="py-4"> {application_status} </td>
                          <td className="py-4"> {getFormattedDate(created_at)} </td>
                          {toggleValue === 'completed' && (
                            <td className="py-4 text-left">
                              <div className="d-flex justify-content-center">
                                <div>
                                  <div className="d-flex align-items-center">
                                    <div className="text-center">
                                      <span className="rating-count table-rating d-block radius-10 py-1 px-2 color-black">{rating_count || 0}</span>
                                      <span class="d-block text-center font-13 mt-1 color-grey">users</span>
                                    </div>
                                    <div className="flex-fill pl-3">
                                      <div className="font-12 d-flex align-items-center">
                                        <StarRating 
                                          readOnly={true}
                                          initialRating={average_rating ? Number(average_rating) : 0}
                                          onRate={()=> {}}
                                          />
                                        <span className="ml-2">{average_rating || 0}</span>
                                      </div>
                                      <div className="d-flex align-items-center mt-2">
                                        <span className="font-13 mr-2 color-grey">My rating:</span>&nbsp;
                                        <span>{my_rating || '-'}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          )}
                          <td className="py-4">
                            <div className="d-flex justify-content-center align-items-center">
                              <a 
                                rel="noopener noreferrer" 
                                className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-blue-style rounded-lg mr-2"
                                href= { dummyBaseURl + "/user/trainings/" + training.id}
                                target="_blank"
                              >
                                <i className="fas fa-eye"  data-toggle="tooltip" title="View"></i>
                              </a>
                              { application_status !== 'completed' && (
                              <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-red-style rounded-lg mr-2" onClick={() => this.updateAppliedStatus(training.id, 'cancelled')} disabled={application_status === 'cancelled' || application_status === 'rejected'}>
                                <i className="fas fa-trash"  data-toggle="tooltip" title="Delete"></i>
                              </button>
                              )}
                              <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-green-style rounded-lg  mr-2" onClick={() => this.openShareModal(training.id)}>
                                <i className="fas fa-share-alt"  data-toggle="tooltip" title="Share"></i>
                              </button>
                              {/* Open model and add feedback */}
                              { application_status === 'completed' && (
                                  <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-yellow-style rounded-lg" onClick={() => this.openFeedbackModal(training.id)} disabled={feedback}>
                                    <i className="fas fa-clipboard-list" data-toggle="tooltip" title="Feedback"></i>
                                  </button>
                              )}
                              
                              {/* {toggleValue === 'completed' && (
                                <button className="btn btn-primary ls-btn-icon-styling-sm font-12 lS-btn-gold-style rounded-lg ml-2">
                                  <i className="fas fa-clipboard-list"></i>
                                </button>
                              )} */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {!isFetchingTrainingApplied && trainingsApplied && trainingsApplied.length === 0 && (
                <div className="text-center">No trainings booked</div>
              )}
              {isFetchingTrainingApplied && (
                <Loader />
              )}
            </div>
          </div>
          {/* Pagination */}
          <div aria-label="Page navigation example" className="ls-pagination">
            <ReactPaginate
              containerClassName={'pagination justify-content-end mt-5'}
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalTrainingsAppliedPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              forcePage={page - 1}
              onPageChange={selectedItem => {
                if (isFetchingTrainingApplied) return;
                this.loadPage(selectedItem.selected + 1);
              }}
              subContainerClassName={'pages pagination'}
              pageClassName={'page-item'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </main>
        <UserFooter />
        {isVisibleShareProfile && (
          <Share
              isVisible={isVisibleShareProfile}
              endpoint={"/user/trainings/"+ trainingId}
              onClose={() => {
                  this.setState({
                    isVisibleShareProfile: false,
                  });
              }}
              onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}

        {isVisibleFeedbackProfile && (
          <FeedbackMyBooking
            isVisible={isVisibleFeedbackProfile}
            id={trainingId}
            onClose={() => {
              this.setState({
                isVisibleFeedbackProfile: false,
              });
            }}
          />
        )}
      </>
    );
  }

}

/**
 *  Define component PropTypes
 */
MyBooking.propTypes = {
  trainingsApplied: array.isRequired,
  isFetchingTrainingApplied: bool.isRequired,
  getTrainingApplied: func.isRequired,
  updateTrainingAppliedStatus: func.isRequired,
  totalTrainingsAppliedPage: number.isRequired,
  page: number.isRequired,
};

/**
*  Map redux state to component props
*/
const mapStateToProps = createStructuredSelector({
  trainingsApplied: getTrainingAppliedListing(),
  isFetchingTrainingApplied: getIsFetchingTrainingApplied(),
  totalTrainingsAppliedPage: getTotalTrainingAppliedPages(),
  page: getTrainingAppliedPage(),
});

export default connect(
  mapStateToProps,
  {
    getTrainingApplied,
    applyTrainingAppliedPagination,
    updateTrainingAppliedStatus,
  }
)(withRouter(MyBooking));