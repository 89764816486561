/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const wellBeing = () => state => state.get('wellBeing');

/**
 *  Select the correct portion of the root reducer
 */
export const getTrainingsListing = () =>
  createSelector(wellBeing(), state => {
    const listing = state.get('trainings').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getTrainingApplicantsListing = () =>
createSelector(wellBeing(), state => {
  const listing = state.get('trainingApplicants').toJS()
  return listing.length > 0 ? listing : []
});

/**
 *  Select the correct portion of the root reducer
 */
export const getTrainingAppliedListing = () =>
createSelector(wellBeing(), state => {
  const listing = state.get('trainingsApplied').toJS()
  return listing.length > 0 ? listing : []
});


/**
 *  Select is fetching trainings
 */
export const getIsFetchingTrainings = () =>
  createSelector(wellBeing(), state => {
    return state.get('isFetchingTrainings')
  })

/**
 *  Select is fetching trainings applicants Name
*/
export const getIsFetchingTrainingApplicants = () =>
createSelector(wellBeing(), state => {
  return state.get('isFetchingTrainingApplicants')
})

/**
 *  Select is fetching trainings applicants Name
*/
export const getIsFetchingTrainingApplied = () =>
createSelector(wellBeing(), state => {
  return state.get('isFetchingTrainingApplied')
})


/**
 *  Select total number of trainings pages
 */
export const getTotalTrainingPages = () =>
  createSelector(wellBeing(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current training pages
 */
export const getTrainingsPage = () =>
  createSelector(wellBeing(), state => {
    return state.get('page')
  })

  
/**
 *  Select total number of trainings pages
 */
export const getTotalTrainingAppliedPages = () =>
  createSelector(wellBeing(), state => {
    return state.get('totalTrainingsAppliedPage')
  })

/**
 *  Select current training pages
 */
export const getTrainingAppliedPage = () =>
  createSelector(wellBeing(), state => {
    return state.get('trainingAppliedPage')
  })
