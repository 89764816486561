import React, { Fragment } from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import Share from "../../../components/share";
import { isRouteAllow } from 'utils/helper';
import Loader from 'components/Loader';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import { getSupervisorBookletsUser, applyPagination } from '../../../modules/resources/actions';
import { getTotalPages, getIsFetchingResources, getResourcesPage, getUsersListing } from '../../../modules/resources/selectors';
// import { trainingType, trainingLevels, locations } from './filters';

// const dateFormat = 'DD-MMM-YYYY';
// const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

class assessorUserListing extends React.Component {
  state={
    search: '',
    disableStatus: true,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    trainingId:0,
    examiner_type:'assessor' 
  }

//   openShareModal(id) {
//     this.setState({
//         isVisibleShare: true,
//         trainingId: id,
//     });
//   }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    this.props.getSupervisorBookletsUser(this.state.examiner_type);
  }

  getSingleTraining = (user, index, id) => (
    <div className="col-12 col-md-4 col-sm-6 custom-col-width border-0 mb-4">
    <div className="w-100 ls-height-100 team-img-cont radius-10 overflow-hidden card border-0">
      <img src={ user.image_url ? user.image_url : require('assets/images/avatar.png')} alt="..." className="img-object-fit" />
    </div>
    <div className="px-2">
      <Link to={`/user/assessor/user-listing/${id}`} >
        <div className="team-text-block lS-card-shadow bg-white radius-10 py-2 px-3 d-flex align-items-center
        flex-column justify-content-center min-width0 position-relative">
          <div className="w-100 text-center">
            <h5 className="family-poppins-semibold text-truncate mb-2">
              {user.name}
            </h5>
            <span className="font-15 text-truncate d-block color-grey font-weight-500">
              {user.occupation}
            </span>
          </div>
          <div className="comment radius-10 align-items-center text-center justify-content-center">
            <h5 className="family-poppins-semibold color-white">
              {user.name}
            </h5>          
          </div>
        </div>
      </Link>
    </div>
    {/* <div className="ls-team-image-text-container">
      <div className="replies">
        <h5 className="family-poppins-semibold">
          {user.name}
          <br />
          <span className="font-12 color-grey font-weight-500">
          {user.occupation}
          </span>
        </h5>
      </div>
      <div className="comment">
        <h5 className="family-poppins-semibold">
          {user.practice ? user.practice.name : ''}
          </h5>          
      </div>
    </div> */}
  </div>
  );

  render() {
    const { users, isFetchingUsers, totalPages, page } = this.props;
    const { search } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5">
          {/* Filter Code */}
          <div className="d-flex mt-5 flex-sm-nowrap flex-wrap">
            {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-md-center text-left mt-3">
            </div> */}
            <button className="btn btn-primary lS-btn-blue-style rounded-lg mr-2 font-12 ls-booking-filter-icon">
              <i className="fas fa-filter" />
            </button>
            <form className="flex-fill px-4">
              <div className="form-row team-search-field">
                <div className="form-group has-search mb-0 w-100 ls-border-color">
                  <span className="fa fa-search form-control-feedback" />
                  <input type="text" value = {search}  className= "form-control" onChange={event => this.handleQueryChange(event.target.value)}
                      placeholder="Search..." />
                </div>
              </div>
            </form>
            <Fragment>
              <div className="d-flex mt-sm-0 mt-3">
                <button className="btn btn-primary lS-btn-blue-style px-5 mr-3" disabled={isFetchingUsers} onClick={this.searchTrainings}
                        type="button"
                        title="Search"
                            id="button-addon2">
                    Search
                </button>
                <button className="btn btn-primary lS-btn-red-style px-5" disabled={isFetchingUsers} onClick={this.resetTrainings}
                  type="button"
                  id="button-addon2" 
                  title="Clear Search"
                  >
                    Clear
                </button>
              </div>
            </Fragment>
            {/* <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 mt-3">
            </div> */}
            {/* <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 text-md-right mt-3"> */}
              
              {/* <button type="button" className="btn btn-primary lS-btn-blue ls-btn-booking font-14">
                Search
              </button> */}            
          {/* </div> */}
          </div>
          {/* Team Listing */}
          <div className="row row-col-5 mt-5">
            {!isFetchingUsers && users && users.length > 0 &&
              users.map((user, index) => this.getSingleTraining(user.attributes, index, user.attributes.user_id)
            )}
            {!isFetchingUsers && users && users.length === 0 && (
              <div className="col-12">
                <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No users found.</div>
              </div>
            )}
            {isFetchingUsers && (
              <div className="col-12"><Loader /></div>
            )}
          </div>
          {/* Pagination */}
          <div aria-label="Page navigation example" className="ls-pagination">
                <ReactPaginate
                containerClassName={'pagination justify-content-end mt-5'}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                forcePage={page - 1}
                onPageChange={selectedItem => {
                    if (isFetchingUsers) return;
                    this.loadPage(selectedItem.selected + 1);
                }}
                subContainerClassName={'pages pagination'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                />
            </div>          
        </main>
        <UserFooter />
        
        {/* <main>
          <div className="container-fluid">
            <div className="row team_list">
              <div className="col-md-12"><h2>Trainees & Students</h2></div>
              <div className="col-md-12">
                <form className="search_form">
                  <input type="text" name="search_team" placeholder="Name, PCN, Practice" ></input>
                  <input type="submit" defaultValue="Search" ></input>
                </form>
              </div>
              {!isFetchingUsers && users && users.length > 0 && 
                users.map((user, index) => this.getSingleTraining(user.attributes, index, user.attributes.user_id)
              )}
              {!isFetchingUsers && users && users.length === 0 && (
                <div className="text-center">No users found.</div>
              )}
              {isFetchingUsers && (
                <div className="text-center">
                  <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}              
            </div>
            
        </div>

        </main> */}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
assessorUserListing.propTypes = {
  getSupervisorBookletsUser:func.isRequired,
//   applyFilters: func.isRequired,
//   checkUnCheckFilters: func.isRequired,
//   getTrainings: func.isRequired,
//   isFetchingUsers: bool.isRequired,
  users: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
//   applyTraining: func.isRequired,
//   updateTrainingStatus: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  users: getUsersListing(),
  isFetchingUsers: getIsFetchingResources(),
  page: getResourcesPage(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getSupervisorBookletsUser,
    // getTrainings,
    // checkUnCheckFilters,
    // applyFilters,
    applyPagination,
    // resetFilters,
    // applyTraining,
    // updateTrainingStatus,
  }
)(withRouter(assessorUserListing));
