const steps = [
  { id: 0, name: 'Step One' },
  { id: 1, name: 'Step Two' },
  { id: 2, name: 'Step Three' },
  { id: 3, name: 'Step Four' },
  { id: 4, name: 'Step Five' },
];

const questionsStaff = [
  {
    title: 'An environment which is welcoming, supportive of their learning and in which they feel part of the team',
    subTitle: 'Evidence exists that learning, and learners are welcomed, valued and have a positive learning experience'
  },
  {
    title: 'Access to a range of learning and teaching opportunities',
    subTitle: 'Evidence of learning opportunities exists which meet leaners’ learning needs e.g. practice placement profile'
  },
  {
    title: 'A team approach to their support',
    subTitle: 'Experience gained with, from and about the wider team takes place where it supports the leaners’ learning outcomes.'
  },
  {
    title: 'Fair, timely and objective assessment',
    subTitle: 'Assessment documentation is completed in line with education institution requirements'
  },
  {
    title: 'Access to support from the education institution when required',
    subTitle: 'Lines of communication between the education institution and leaner during practice placement are clear'
  },
  {
    title: 'Supporting learners',
    subTitle: 'Access relevant pastoral support and educational preparation and development'
  },
  {
    title: 'Support, teach, facilitate, and supervise leaners according to the requirements of the programme',
    subTitle: 'Leaners are supported to achieve learning outcomes'
  },
  {
    title: 'Provide guidance and constructive feedback to the leaner',
    subTitle: 'Constructive feedback is evident within documentation'
  },
  {
    title: 'Conduct a fair, objective and timely assessment of leaner performance, where assessment is required',
    subTitle: 'Assessment documentation is completed in line with education institution requirements'
  },
  {
    title: 'Raise concerns, in a timely manner, when a leaner is not achieving agreed learning outcomes',
    subTitle: 'Agreed processes for raising concerns are in place and followed'
  },
  {
    title: 'Contribute to the review of the learning environment and leaner experience',
    subTitle: 'Joint education institution/service quality process includes evaluation from individuals supporting leaners in the workplace'
  },
  {
    title: 'Participate in trainer self and peer evaluation to facilitate personal development and contribute to the development of others',
    subTitle: 'Support network is available and accessed by individuals supporting leaners'
  },
  {
    title: 'Feedback on trainer performance and progress from learners',
    subTitle: 'Feedback and review of teaching methods and performance'
  },
  {
    title: 'To be educationally prepared to fulfil their role in supporting leaners',
    subTitle: 'Relevant educational and continuing professional development needs are incorporated into Personal Development Plans aligned to the Knowledge and Skills Framework'
  },
  {
    title: 'To receive information about the leaners and their learning needs provided by the education institution',
    subTitle: 'Information about the leaners and their learning needs is available'
  },
  {
    title: 'Time to support leaners to achieve their learning outcomes',
    subTitle: 'Workload expectations take account of leaners’ support'
  },
  {
    title: 'A support network inclusive of service and education colleagues, managers and peers',
    subTitle: 'Support networks exist and are accessible to individuals supporting leaners'
  },
  {
    title: 'Work with service and education colleagues to ensure processes are in place for continuous quality improvement of practice placements',
    subTitle: 'Evidence of partnership between service and education in relation to the quality of placement experience'
  },
  {
    title: 'Ensure leaners within their area have a named, prepared individual to support their learning',
    subTitle: 'Evidence exists that every leaner has a named individual supporting them'
  },
  {
    title: 'Ensure individuals who support leaners in the workplace are able to raise concerns',
    subTitle: 'Evidence of support networks where concerns can be raised in relation to leaners and placements'
  },
  {
    title: 'A network of support to share and develop practice',
    subTitle: 'Networks exist which can be accessed by managers and facilitators supporting education in practice'
  },
];

export {
  steps,
  questionsStaff,
};
  