/**
 *  Import action creator constants & dependencies
 */
import {
  ADD_LIST_POLLING,
  GET_LIST_POLLING,
  UPDATE_LIST_POLLING,
  APPLY_PAGINATION,
  DELETE_EMPLOYMENTS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Add Polling
 */
export function editPollingList(params, paramID) {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        editPollingListBegin(
          API_URLS.POLLING.ADD_POLLING + paramID,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const editPollingListBegin = (API_URL, params, source) => ({
  type: UPDATE_LIST_POLLING,
  payload: {
    request: {
      url: API_URL,
      method: 'put',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Add Polling
 */
export function addListPolling(params) {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addListPollingBegin(
          API_URLS.POLLING.GET_LIST_POLLING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addListPollingBegin = (API_URL, params, source) => ({
  type: ADD_LIST_POLLING,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get Polling List
 */
export function getListPolling() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('polling').toJS();
    const params = {
      page,
      limit,
      include: ['options']
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getListPollingBegin(
          API_URLS.POLLING.GET_LIST_POLLING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getListPollingBegin = (API_URL, params, source) => ({
  type: GET_LIST_POLLING,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Delete List of Trainings
 */
export function deletePolling(PollingID) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        deletePollingBegin(
          API_URLS.POLLING.ADD_POLLING + PollingID,
          PollingID,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const deletePollingBegin = (API_URL, PollingID, source) => ({
  type: DELETE_EMPLOYMENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'delete',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  PollingID
});

/**
 *  Apply pagination
 */
export function applyPaginationPolling(page) {
  return async dispatch => {
    dispatch(applyPaginationPollingBegin(page));
    dispatch(getListPolling());
  };
};

export const applyPaginationPollingBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
