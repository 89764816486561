export const GET_LIST_OF_PCN =
  'src/modules/pcn/GET_LIST_OF_PCN';
export const GET_LIST_OF_FEDERATION =
  'src/modules/pcn/GET_LIST_OF_FEDERATION';
export const CHANGE_SINGLE_MAP =
	'src/modules/pcn/CHANGE_SINGLE_MAP';
export const GET_LIST_OF_SINGLE_PCN =
  'src/modules/pcn/GET_LIST_OF_SINGLE_PCN';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/pcn/CANCEL_ALL_API_REQUESTS';
