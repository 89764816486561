import React from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { jobType, regions, profession } from './filters';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';

/**
 *  Import other dependencies
 */
import {
    uploadImage,
    addWellBeing,
    updatePostTraining,
    getTrainings
  } from '../../../modules/wellBeingPost/actions';
  import {
    getIsUploadingImage,
    getIsFetchingTrainings,
    getTrainingsListing
  } from '../../../modules/wellBeingPost/selectors';
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};


const addEditPostWellBeingSchema = Yup.object().shape({
    title: Yup.string()
      .required('Required'),
    organisation: Yup.string()
      .required('Required'),
    location: Yup.string()
      .required('Required'),
    linkURL: Yup.string()
      .url("Please add a valid url.")
      .required('Required'),
    description: Yup.string()
      .nullable()
      .max(512, 'Max length of char 512 only.'),
  });
  
  const formattedArray = array => {
    return array.map(item => {
      return {
        label: item,
        value: item
      };
    });
  };
  
const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};
  
  const formSelect = props => {
    const { form, options, placeholder, field } = props;
    if (options.length === 0) return;
    return (
      <Select
        components={{ IndicatorSeparator:() => null }}
        className="form-control p-0"
        isSearchable={true}
        placeholder={placeholder}
        onChange={value => {
          field.name === 'type' ? form.setFieldValue('type', value.value) :
          field.name === 'location' ? form.setFieldValue('location', value.value) :
          field.name === 'activity' ? form.setFieldValue('activity', value.value) :
            form.setFieldValue('profession', value.value)
        }}
        value={field.value ? formattedArray([field.value]) : ''}
        styles={customStyles}
        options={formattedArray(options)}
      />
    )
  };
class postWellBeing extends React.Component {
    state = {
        mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
      };
    
      componentWillMount() {
        if (Object.keys(this.props.match.params).length > 0) {
          this.props.getTrainings(this.props.match.params.id).then(() => {
          }, () => {
            const { history } = this.props;
            if (history) {
              history.push('/user/trainings');
            }
          });
        }
      }
    
      getFormikInitVal = () => {
        const { training } = this.props;
        console.log(training);
        const { mode } = this.state;
        if (mode === 'edit') {
          const {
            title,
            organisation,
            address,
            profession,
            level,
            type,
            description,
            start_date,
            end_date,
            location,
            image_url,
            link_url,
          } = training.attributes || {};
    
          return {
            title,
            organisation,
            address,
            profession,
            level: level,
            description: description,
            type: type,
            location: location,
            imageUrl: image_url ? image_url : '',
            start_date: start_date ? moment(start_date): '',
            end_date: end_date ? moment(end_date): '',
            linkURL: link_url,
          }
        }
        return {
          title: "",
          organisation: "",
          address: "",
          level: "",
          description: "",
          type: "",
          location: "",
          imageUrl: "",
          date: '',
          end_date: '',
          linkURL: '',
        }
      };
    
      handleJobImage = (event, setFieldValue) => {
        const { uploadImage, isUploadingImage } = this.props;
        if (isUploadingImage) return;
        const file = event.target.files[0];
        var formdata = new FormData();
        formdata.append("image", file);
        uploadImage(formdata).then(res => {
          const { url } = res.data;
          setFieldValue('imageUrl', url);
        });
      };
    
      fileUpload = props => {
        const { form, field } = props;
        if (field.value) {
          return (
            <>
              <div>
                <label className="color-grey pl-3">Image Preview</label>
              </div>
              <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
                <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
                <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
                  <i className="fas fa-times"></i>
                </div>
              </div>
            </>
          )
        }
    
        return (
          <>
            <label htmlFor="placements-upload" className="color-grey pl-3">Upload File</label>
            <div className="custom-file">
              <input type="file" className="custom-file-input" id="placements-upload" onChange={event => this.handleJobImage(event, form.setFieldValue)}/>
              <label className="custom-file-label" htmlFor="placements-upload">Choose File</label>
            </div>
          </>
        )
      };
    
      postJob = (values, { setSubmitting, resetForm }) => {
        const { addWellBeing, updatePostTraining, history } = this.props;
        const { mode } = this.state;
        var params = {
          title: values.title,
          organisation: values.organisation,
          level: values.level ? values.level.substr(-1): null,
          address: values.address,
          type: values.type,
          description: values.description,
          location: values.location,
          profession: values.profession,
          start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : null,
          end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : null,
          link_url: values.linkURL,
        };
    
        if (values.imageUrl) {
          params.image_url = values.imageUrl;
        }
    
        if( mode === 'edit' ){
          updatePostTraining(params, this.props.match.params.id).then(res => {
            showSuccessMsg("Well being updated successfully.");
            resetForm(this.getFormikInitVal);
            setSubmitting(false);
            if (history) {
              history.push('/user/well-being');
            }
          }, err => {
            setSubmitting(false);
          });
        }
    
        if( mode === 'add' ){
          addWellBeing(params).then(res => {
            showSuccessMsg("Well being added.");
            resetForm(this.getFormikInitVal);
            setSubmitting(false);
            if (history) {
              history.push('/user/well-being');
            }
          }, err => {
            setSubmitting(false);
          });
        }
      };
    
      render() {
        const {
          isUploadingImage,
          isFetchingTraining
        } = this.props;
        const { mode } = this.state;
        return (
          <>
            <UserHeader />
            {/* Page content */}
            <main className="m-0 px-3 px-md-5 mt-md-5 mt-4 mb-5">
              <div className="card border-0 page-title-bar">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-8">
                      <h4 className="family-poppins-semibold">Post Well Being</h4>
                      <p className="mb-0 font-14 color-grey"></p>
                    </div>
                  </div>
                </div>
              </div>  

              <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded-lg">
              {isFetchingTraining && (
                <Loader />
              )}

              <div className="card-body">
              {!isFetchingTraining && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditPostWellBeingSchema}
                  onSubmit={this.postJob}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="ls-form pt-2">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="title" className="color-grey pl-3">Title</label>
                            <Field type="text" name="title" className="form-control form-control-lg rounded-lg" placeholder="Enter you title" />
                            <ErrorMessage name="title" component="div" className="error-msg" />
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="organisation" className="color-grey pl-3">Organisation</label>
                            <Field type="text" name="organisation" className="form-control form-control-lg rounded-lg" placeholder="Please Enter Organisation" />
                            <ErrorMessage name="organisation" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="address" className="color-grey pl-3">Address</label>
                            <Field type="text" name="address" className="form-control form-control-lg rounded-lg" placeholder="Enter Address" />
                            <ErrorMessage name="address" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="location" className="color-grey pl-3">Location</label>
                            <Field as="select" name="location" placeholder="Select Location" component={formSelect} options={regions} />
                            <ErrorMessage name="location" component="div" className="error-msg" />
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="profession" className="color-grey pl-3">Profession</label>
                            <Field as="select" name="profession" placeholder="Select Profession" className="form-control form-control-lg rounded-lg" component={formSelect} options={profession} />
                            <ErrorMessage name="profession" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="category" className="color-grey pl-3">Category</label>
                            <Field as="select" name="type" placeholder="Select Category" className="form-control form-control-lg rounded-lg" component={formSelect} options={jobType} />
                            <ErrorMessage name="jobType" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="startDate" className="color-grey pl-3">Start Date</label>
                            <Datetime
                              timeFormat={false}
                              inputProps={{placeholder: 'Enter Start Date'}}
                              renderInput={ this.renderCustomInput }
                              closeOnSelect={true}
                              isValidDate={ valid }
                              onChange={start_date => {
                                if (typeof start_date !== "object") return;
                                setFieldValue('start_date', start_date)
                              }}
                              value={values['start_date']}
                            />
                            <ErrorMessage name="start_date" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="endDate" className="color-grey pl-3">End Date</label>
                            <Datetime
                                timeFormat={false}
                                inputProps={{placeholder: 'Enter End Date'}}
                                renderInput={ this.renderCustomInput }
                                closeOnSelect={true}
                                isValidDate={ valid }
                                onChange={end_date => {
                                if (typeof end_date !== "object") return;
                                setFieldValue('end_date', end_date)
                              }}
                              value={values['end_date']}
                            />
                            <ErrorMessage name="end_date" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="linkUrl" className="color-grey pl-3">Link URL</label>
                            <Field type="text" name="linkURL" className="form-control form-control-lg rounded-lg" placeholder="Enter URL" />
                            <ErrorMessage name="linkURL" component="div" className="error-msg" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group mb-5">
                              {!isUploadingImage && (
                                <Field type="file" component={this.fileUpload} name="imageUrl" className="custom-file-input"  id="imageUrl" />
                              )}

                              {isUploadingImage && (
                                  <Loader />
                              )}
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-6">
                          <div className="form-group mb-5">
                            <label htmlFor="description" className="color-grey pl-3">Description</label>
                            <Field as="textarea" name="description" rows={5} className="flex-fill form-control form-control-lg rounded-lg" placeholder="Enter Description" />
                            <ErrorMessage name="description" component="div" className="error-msg" />
                          </div>
                        </div>
                      </div>
                      <div className="text-md-right text-center">
                        <button type="button" className="btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</button>
                        <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                          {
                            isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-2"></span>
                          )}
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                )}
                </div>
              </div>
            </main>
          </>
        );
      }
}

/**
 *  Define component PropTypes
 */
postWellBeing.propTypes = {
    addWellBeing: func.isRequired,
    isFetchingTraining: bool.isRequired,
    isUploadingImage: bool.isRequired,
    training: object.isRequired,
    updatePostTraining: func.isRequired,
    uploadImage: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    isUploadingImage: getIsUploadingImage(),
    isFetchingTraining: getIsFetchingTrainings(),
    training: getTrainingsListing()
});


export default connect(
    mapStateToProps,
    {
        uploadImage,
        addWellBeing,
        updatePostTraining,
        getTrainings,
    }
  )(withRouter(postWellBeing));
