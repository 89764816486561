/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const news = () => state => state.get('news');

/**
 *  Select the correct portion of the root reducer
 */
export const getNewsListing = () =>
  createSelector(news(), state => {
    const listing = state.get('news').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select Single news page object
 */
export const getSingleNewsDetail = () =>
  createSelector(news(), state => {
    const singleNews = state.get('singleNews')
    return singleNews && Object.keys(singleNews).length > 0 ? singleNews.toJS() : {}
  });

/**
 *  Select is fetching news
 */
export const getIsFetchingNews = () =>
  createSelector(news(), state => {
    return state.get('isFetchingNews')
  })

/**
 *  Select is fetching news
 */
export const getIsFetchingSingleNews = () =>
  createSelector(news(), state => {
    return state.get('isFetchingSingleNews')
  })

/**
 *  Select total number of news pages
 */
export const getTotalPages = () =>
  createSelector(news(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current news pages
 */
export const getNewsPage = () =>
  createSelector(news(), state => {
    return state.get('page')
  })
