/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import { UPLOAD_IMAGE, SET_LOADER } from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  isUploadingImage: false
});

/**
 *  Define the reducer with actions
 */
function imageCroppingModalReducer(state = initialState, action) {
  switch (action.type) {
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case SET_LOADER:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: action.value
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default imageCroppingModalReducer
