/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const myPcn = () => state => state.get('pcnPractices');

/**
 *  Select the correct portion of the root reducer
 */
export const getMyPcnListing = () =>
  createSelector(myPcn(), state => {
    const listing = state.get('myPcn').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getNetworksAndPractices = () =>
  createSelector(myPcn(), state => {
    const listing = state.get('networksAndPractices').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getFederationsList = () =>
  createSelector(myPcn(), state => {
    const listing = state.get('federations').toJS()
    return listing.length > 0 ? listing : []
  });
  
/**
 *  Select the Single Selected Map State
 */
export const getSingleSelectedMap = () =>
  createSelector(myPcn(), state => {
    return state.get('selectedMap') ? state.get('selectedMap').toJS() : {};
  });

/**
 *  Select the Single Selected Map State
 */
export const getSelectedFederation = () =>
  createSelector(myPcn(), state => {
    return state.get('selectedFederation') ? state.get('selectedFederation').toJS() : {};
  });
  
/**
 *  Select is fetching trainings
*/
export const getIsFetchingMyPcn = () =>
  createSelector(myPcn(), state => {
    return state.get('isFetchingMyPcn')
  })

/**
 *  Select is fetching trainings
*/
export const getIsFetchingNetworks = () =>
createSelector(myPcn(), state => {
  return state.get('isFetchingNetworks')
})
