export const GET_LIST_OF_TRAININGS =
  'src/modules/postTrainings/GET_LIST_OF_TRAININGS';
export const ADD_TRAININGS =
  'src/modules/postTrainings/ADD_TRAININGS';
export const UPDATE_TRAININGS =
  'src/modules/postTrainings/UPDATE_TRAININGS';
export const UPLOAD_IMAGE =
  'src/modules/postTrainings/UPLOAD_IMAGE';
export const DELETE_TRAININGS =
  'src/modules/postTrainings/DELETE_TRAININGS';
export const RESET_FILTERS =
  'src/modules/postTrainings/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/postTrainings/APPLY_PAGINATION';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/postTrainings/CANCEL_ALL_API_REQUESTS';
