import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <>
        <div className="d-flex flex-fill justify-content-center ls-loader py-5 bg-white radius-10">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </>
    );
  }
}

export default Loader;
