/**
 * Import Node Modules
 */

import React, {Component} from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import { pathwayData } from './data';

/**
 * Import Other Dependicies
 */

import UserHeader from 'components/Headers/UserHeader';
import Loader from 'components/Loader';
import UserFooter from 'components/Footers/UserFooter';

class PathWay extends Component {
  
  pathwayRef = React.createRef();

  constructor(props){
    super(props);
    this.state={
      activeTab: pathwayData[0].label,
      activeTabIndex: 0,
      pathwayHeaderName: pathwayData[0].roles[0].role_name,
      pathwayHeaderIndex: 0
    }
  }

  handleClicks = index => {
    const node = this.pathwayRef.current;
    // add
    if (node instanceof HTMLElement) {
      const pathwayContent = node.querySelector('#pathway-content');
      if(pathwayContent && this.state.activeTabIndex === index) {
        if(pathwayContent.classList.contains('animate__fadeIn')) {
          pathwayContent.classList.remove('animate__fadeIn');
          pathwayContent.classList.add('animate__fadeOut');
        } else {
          pathwayContent.classList.remove('animate__fadeOut');
          pathwayContent.classList.add('animate__fadeIn');
        }
      } else {
        if( pathwayContent ) {
          pathwayContent.classList.remove('animate__fadeOut');
          pathwayContent.classList.add('animate__fadeIn');
        }
      }
    }
    this.setState({
      activeTab: pathwayData[index].label,
      activeTabIndex: index,
      pathwayHeaderName: pathwayData[index].roles[0].role_name,
      pathwayHeaderIndex: 0
    });
  }
  
  handlePathwayHeaders = index => {
    const { activeTabIndex } = this.state;
    this.setState({
      pathwayHeaderName: pathwayData[activeTabIndex].roles[index].role_name,
      pathwayHeaderIndex: index,
    });
  }

  render() {
    let { activeTab, pathwayHeaderName, activeTabIndex, pathwayHeaderIndex } = this.state;
    return(
      <>
        <UserHeader />

        {/* Page content */}
        <main className="m-0 px-3 px-md-5 mt-md-5 mt-4" ref={this.pathwayRef}>
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold">Pathways</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid p-0">
             {/* tabs  */}
            <div className="row mt-5 mx-lg-n4">
              {pathwayData && pathwayData.length > 0 && pathwayData.map((item, index) => (
                <div className="col-md-4 col-sm-6 mb-sm-4 mb-3 pt-md-3 px-lg-4" key={index}>
                  <div className={`card lS-card-shadow border-0 py-4 px-3 text-center lS-card-radius pathway-tab cursor-pointer ${activeTab === item.label ? 'active-pathway' : ''} `} onClick={() => this.handleClicks(index)}>
                    <h5 className="font-weight-normal family-poppins-medium m-0">{item.label || '-'}</h5>
                  </div>
                </div>
              ))}
            </div>
            <div className="tab-content-container animate__animated animate__fadeIn" id="pathway-content">
              {/* detail tiles  */}
              <div className="row row-col-5 mt-md-5 mt-4">
                {pathwayData && 
                pathwayData.length > 0 && 
                pathwayData[activeTabIndex] &&
                pathwayData[activeTabIndex].roles &&
                pathwayData[activeTabIndex].roles.length > 0 &&
                pathwayData[activeTabIndex].roles.map((item, index) => (
                  <div className="col-12 col-md-4 col-sm-6 custom-col-width border-0 cursor-pointer" key={index} onClick={() => this.handlePathwayHeaders(index)}>
                    <div className="card border-0 detail-tile lS-card-radius lS-card-shadow mb-4">
                      <div className={`box-cols radius-10 card-body py-lg-4 text-center ${pathwayHeaderName === item.role_name && 'active-box-col'} `}>
                        <div className="tile-icon rounded-circle d-inline-flex align-items-end justify-content-center">
                          <img src={require('assets/images/pathway/user.png')} alt="icon" className="w-75" />
                        </div>
                        <h5 className="font-17 family-poppins-semibold mb-0 mt-3 text-truncate" data-toggle="tooltip" title={item.role_name}>{item.role_name}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* count blocks */}
              <div className="row px-xl-5 my-5 no-gutters">
                <div className="col-12 d-lg-block d-none">
                  <img src={require('assets/images/pathway/steps.svg')} alt="pathway steps" className="w-100" />
                </div>
                {pathwayData && 
                  pathwayData.length > 0 && 
                  pathwayData[activeTabIndex] &&
                  pathwayData[activeTabIndex].roles &&
                  pathwayData[activeTabIndex].roles.length > 0 &&
                  pathwayData[activeTabIndex].roles[pathwayHeaderIndex] &&
                  pathwayData[activeTabIndex].roles[pathwayHeaderIndex].roles_titles &&
                  pathwayData[activeTabIndex].roles[pathwayHeaderIndex].roles_titles.length > 0 &&
                  pathwayData[activeTabIndex].roles[pathwayHeaderIndex].roles_titles.map((item, index) => (
                  <div className={`steps-info-block step-${index + 1} mb-4 col-md-4 col-sm-6`} key={index}>
                    <div className="step-count rounded-circle overflow-hidden d-block d-lg-none text-center">
                      <img src={require(`assets/images/pathway/step-${index + 1}.svg`)} alt="pathway steps" />
                    </div>
                    <div className="step-info text-center px-3 mt-4">
                      <h4 className="family-poppins-semibold">{item}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
        <UserFooter />
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PathWay.propTypes = {
  
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  
});

export default connect(
  mapStateToProps,
  {
    
  }
)(withRouter(PathWay));