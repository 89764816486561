import React from "react";
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { showSuccessMsg } from '../../utils/notification';

/**
 *  Import other dependencies
 */
import { forgot } from '../../modules/auth/actions';

const forgotSchema = Yup.object().shape({
	email: Yup.string()
	  .email('Invalid email')
	  .required('Required'),
  });
  
  class ForgotPassword extends React.Component {
	forgotUser = (values, { setSubmitting }) => {	  
	  const { forgot, history } = this.props;
	  const { email } = values;
	  forgot(email).then(res => {
		const { message } = res;
		showSuccessMsg(message);
		setSubmitting(false);
		if (history) {
		  history.push('/user/login');
		}
	  }, err => {
		setSubmitting(false);
	  });
	};
  
	render() {
	  return (
		<>
            <div className="d-flex justify-content-center ls-height-100 align-items-center">
                <div className="row ls-form-content">
                    <div className="col-md-12 text-center mb-3">
                        <h2 className="family-poppins-semibold">Recover Account!</h2>
                    </div>
                    <div className="col-md-12 mt-3">
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={forgotSchema}
                            onSubmit={this.forgotUser}
                        >
                            {({ isSubmitting }) => (
                            <Form>
                                <div className="form-group ls-login-form">
                                    <label htmlFor="email" className="mb-0 text-muted font-10">Email<span className="text-danger">*</span> </label>
                                    <Field type="email" name="email" className="form-control font-14" placeholder="name@example.com" />
                                </div>
                                <ErrorMessage name="email" component="div" className="error-msg" />
                                <button type="submit" className="btn btn-primary ls-login-button w-100 py-3 my-3 mb-2 m-1" disabled={isSubmitting}>
                                { 
                                    isSubmitting && (
                                    <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                                )}
                                Reset Password
                                </button>
                            </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
		</>
	  );
	}
  }
  
  /**
   *  Define component PropTypes
   */
  ForgotPassword.propTypes = {
	forgot: func.isRequired,
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({});
  
  export default connect(
	mapStateToProps,
	{
	  forgot
	}
  )(withRouter(ForgotPassword));