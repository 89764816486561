import React, { Fragment } from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { occupations, jobType, minMaxSalary } from './jobsFilters';
import { filterSalaryByType, filterGreaterValue } from 'utils/helper';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  uploadImage,
  addPostJob,
  updatePostJob,
  getJob,
} from '../../modules/jobPost/actions';
import {
  getIsUploadingImage,
  getIsFetchingJob,
  getJobsListing,
} from '../../modules/jobPost/selectors';
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const addEditJobSchema = Yup.object().shape({
  jobTitle: Yup.string()
    .required('Required'),
  organisationName: Yup.string()
    .required('Required'),
  occupation: Yup.string()
    .required('Required'),
  jobType: Yup.string()
    .required('Required'),
  description: Yup.string()
    .required('Required'),
  address: Yup.string()
    .required('Required'),
  minSalary: Yup.string()
    .required('Required'),
  maxSalary: Yup.string()
    .required('Required'),
  reference: Yup.string()
    .required('Required'),
  city: Yup.string()
    .required('Required'),
  region: Yup.string()
    .required('Required'),
  keyword: Yup.string()
    .required('Required'),
  closingDate: Yup.string()
    .required('Required'),
  linkURL: Yup.string()
    .nullable()
    .url("Please add a valid url."),
});

const formattedArray = (array, fieldName, form) => {
  if (fieldName === 'maxSalary') {
    const { values } = form;
    if(values && values.minSalary) {
      const result =  values.minSalary.split(" ");
      const salaryType =  result[result.length - 1];
      const filterData = filterGreaterValue(values.minSalary, filterSalaryByType(salaryType, array));
      array = filterData;
    }
  }
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if (field.name === 'minSalary') {
          form.setFieldValue("maxSalary", ''); // Reset max salary field
        }
        form.setFieldValue(field.name, value.value)
      }}
      value={field.value ? formattedArray([field.value], field.name, form) : ''}
      styles={customStyles}
      options={formattedArray(options, field.name, form)}
    />
  )
};

class JobPost extends React.Component {
  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getJob(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/jobs');
        }
      });
    }
  }

  getFormikInitVal = () => {
    const { job } = this.props;
    const { mode } = this.state;

    if (mode === 'edit') {
      const {
        title,
        organisation,
        occupation,
        type,
        description,
        closing_date,
        reference,
        min_salary,
        max_salary,
        address,
        job_image_url,
        city,
        region,
        keyword,
        link_url,
      } = job.attributes || {};

      return {
        jobTitle: title,
        organisationName: organisation,
        occupation,
        description,
        jobType: type,
        address,
        imageUrl: job_image_url ? job_image_url : '',
        minSalary: min_salary,
        maxSalary: max_salary,
        reference,
        city,
        region,
        keyword,
        closingDate: closing_date ? moment(closing_date): '',
        linkURL: link_url 
      }
    }

    return {
      jobTitle: "",
      organisationName: "",
      occupation: "",
      description: "",
      jobType: "",
      address: "",
      imageUrl: "",
      minSalary: "",
      maxSalary: "",
      reference: '',
      city: '',
      region: '',
      keyword: '',
      closingDate: '',
      linkURL: ''
    }
  };

  handleJobImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("image", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('imageUrl', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <div>
            <label className="color-grey pl-3">Image Preview</label>
          </div>
          <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <label htmlFor="job-post-upload" className="color-grey pl-3">Upload File</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="job-post-upload" onChange={event => this.handleJobImage(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="job-post-upload">Choose File</label>
				</div>
      </>
    )
  };

  postJob = (values, { setSubmitting, resetForm }) => {
    const { addPostJob, updatePostJob, history } = this.props;
    const { mode } = this.state;
    var params = {
      title: values.jobTitle,
      org_name: values.organisationName,
      occupation: values.occupation,
      type: values.jobType,
      description: values.description,
      address: values.address,
      min_salary: values.minSalary,
      max_salary: values.maxSalary,
      reference: values.reference,
      city: values.city,
      region: values.region,
      keyword: values.keyword,
      closing_date: moment(values.closingDate).format("YYYY-MM-DD HH:mm:ss"),
      link_url: values.linkURL,
    };

    if (values.imageUrl) {
      params.image_url = values.imageUrl;
    }

    if (mode === 'edit') {
      updatePostJob(params, this.props.match.params.id).then(res => {
        showSuccessMsg("Job successfully Updated");
        setSubmitting(false);
        if (history) {
          history.push('/user/jobs');
        }
      }, err => {
        setSubmitting(false);
      });
      return;
    }

    if (mode === 'add') {
      addPostJob(params).then(res => {
        showSuccessMsg("Job successfully added");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/jobs');
        }
      }, err => {
        setSubmitting(false);
      });
    }
  };

  render() {
    const {
      isUploadingImage,
      isFetchingJob,
    } = this.props;
    const { mode } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 pb-5 mt-md-5 mt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="family-poppins-semibold">{mode === 'edit' ? 'Edit' : 'Post'} Job</h4>
                    <p className="mb-0 font-14 color-grey"></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded-lg">
            {isFetchingJob && (
              <Loader />
            )}
              <div className="card-body">
                {!isFetchingJob && (
                  <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditJobSchema}
                  onSubmit={this.postJob}
                  >
                 {({ isSubmitting, setFieldValue, values }) => (
                 <Form className="ls-form pt-2">
                  {/* <form  action encType="multipart/form-data" method="post"> */}
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="title" className="color-grey pl-3">Title</label>
                          <Field type="text" 
                                name="jobTitle"
                                className="form-control form-control-lg rounded-lg" 
                                id="title" 
                                placeholder="Enter your title" />
                          <ErrorMessage name="jobTitle" component="div" className="error-msg" />                                  
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="organisation" className="color-grey pl-3">Organisation</label>
                          <Field type="text" 
                                name="organisationName"
                                className="form-control form-control-lg rounded-lg" 
                                id="organisation" 
                                placeholder="Enter Organisation" />
                          <ErrorMessage name="organisationName" component="div" className="error-msg" />                                  
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="occupation" className="color-grey pl-3">Occupation</label>
                          <Field as="select"
                                name="occupation" 
                                component={formSelect} 
                                options={occupations}
                                className="form-control form-control-lg rounded-lg" 
                                id="occupation" 
                                placeholder="Select Occupation" />
                          <ErrorMessage name="occupation" component="div" className="error-msg" />                                  
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="jobType" className="color-grey pl-3">Job Type</label>
                          <Field as="select"
                                name="jobType" 
                                component={formSelect} 
                                options={jobType}
                                className="form-control form-control-lg rounded-lg" 
                                id="jobType" 
                                placeholder="Select Job Type" />
                          <ErrorMessage name="jobType" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group mb-5">
                          <label htmlFor="closingDate" className="color-grey pl-3">Closing Date</label>
                          <Datetime
                            inputProps={{placeholder: 'Select Closing Date'}}
                            renderInput={ this.renderCustomInput }
                            closeOnSelect={true}
                            isValidDate={ valid }
                            onChange={date => {
                              if (typeof date !== "object") return;
                              setFieldValue('closingDate', date)
                            }}
                            value={values['closingDate']}
                          />
                          <ErrorMessage name="closingDate" component="div" className="error-msg" />
                          {/* <input type="date" className="form-control form-control-lg rounded-lg" id="start-date" placeholder="Enter start date" /> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="job-reference" className="color-grey pl-3">Job Reference</label>
                          <Field type="text" 
                                name="reference" 
                                className="form-control form-control-lg rounded-lg" 
                                id="job-reference" 
                                placeholder="Enter Job Reference" />
                          <ErrorMessage name="reference" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="location" className="color-grey pl-3">Location</label>
                          <Field type="text" 
                                name="address" 
                                className="form-control form-control-lg rounded-lg" 
                                id="location" 
                                placeholder="Enter Location" />
                          <ErrorMessage name="address" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="city" className="color-grey pl-3">Locality</label>
                          <Field type="text" 
                                name="city" 
                                className="form-control form-control-lg rounded-lg" 
                                id="city" 
                                placeholder="Enter Locality" />
                          <ErrorMessage name="city" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="region" className="color-grey pl-3">STP</label>
                          <Field type="text" 
                                name="region" 
                                className="form-control form-control-lg rounded-lg" 
                                id="region" 
                                placeholder="Enter STP" />
                          <ErrorMessage name="region" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="keyword" className="color-grey pl-3">Keyword</label>
                          <Field type="text" 
                                name="keyword" 
                                className="form-control form-control-lg rounded-lg" 
                                id="keyword" 
                                placeholder="Enter Keywords" />
                          <ErrorMessage name="keyword" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="min-salary" className="color-grey pl-3">Min Salary</label>
                          <Field as="select" 
                                name="minSalary" 
                                className="form-control form-control-lg rounded-lg" 
                                component={formSelect}
                                options={minMaxSalary}
                                id="min-salary" 
                                placeholder="Select Min Salary" />
                          <ErrorMessage name="minSalary" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="max-salary" className="color-grey pl-3">Max Salary</label>
                          <Field as="select" 
                                name="maxSalary" 
                                className="form-control form-control-lg rounded-lg" 
                                component={formSelect}
                                options={minMaxSalary}
                                id="max-salary" 
                                placeholder="Select Max Salary" />
                          <ErrorMessage name="maxSalary" component="div" className="error-msg" />
                        </div>
                      </div>  
                      <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            <label htmlFor="training-url" className="color-grey pl-3">Link URL</label>
                            <Field type="text" 
                                  name="linkURL" 
                                  className="form-control form-control-lg rounded-lg" 
                                  id="training-url" 
                                  placeholder="Enter URL" />
                            <ErrorMessage name="linkURL" component="div" className="error-msg" />
                          </div>
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            {!isUploadingImage && (
                              <>
                                <Field type="file" 
                                      component={this.fileUpload}
                                      name="imageUrl"
                                      className="custom-file-input" id="file" />
                              </>
                              )}
                              {isUploadingImage && (
                                <Loader />
                              )}
                          </div>
                        </div>                    
                      </div>
                      <div className="col-lg-8 col-md-12">
                        <div className="form-group d-flex flex-column ls-height-100 pb-5">
                          <label htmlFor="description" className="color-grey pl-3">Description</label>
                          <Field as="textarea" name="description" rows={3} className="flex-fill form-control form-control-lg rounded-lg" id="description" placeholder="Enter Description" defaultValue={""} />
                          <ErrorMessage name="description" component="div" className="error-msg" />
                        </div>
                      </div>                      
                    </div>
                    <div className="text-md-right text-center">
                      <button className="btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</button>
                      <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                      {                     
                          isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}Submit
                      </button>
                    </div>
                  {/* </form> */}
                </Form>
                )}
              </Formik>
              )}
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>       
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
JobPost.propTypes = {
  addPostJob: func.isRequired,
  isFetchingJob: bool.isRequired,
  isUploadingImage: bool.isRequired,
  job: object.isRequired,
  updatePostJob: func.isRequired,
  uploadImage: func.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
  isFetchingJob: getIsFetchingJob(),
  job: getJobsListing(),
});

export default connect(
  mapStateToProps,
  {
    uploadImage,
    addPostJob,
    updatePostJob,
    getJob,
  }
)(withRouter(JobPost));
