/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const postPlacements = () => state => state.get('postPlacements');

/**
 *  Select the correct portion of the root reducer
 */
export const getPlacementsListing = () =>
  createSelector(postPlacements(), state => {
    const listing = state.get('placement').toJS()
    return listing ? listing : {}
  });

/**
 *  Select is fetching placements
 */
export const getIsFetchingPlacement = () =>
  createSelector(postPlacements(), state => {
    return state.get('isFetchingPlacement')
  })

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(postPlacements(), state => {
    return state.get('isUploadingImage')
  })

/**
 *  Select is fetching placements
 */
export const getIsDeletingPlacement = () =>
  createSelector(postPlacements(), state => {
    return state.get('isDeletingPlacement')
  })
