import React from "react";
import { func, bool, array, string } from 'prop-types';

class Poll extends React.Component {
  
  pollRef = React.createRef();

  colors = [
    'red',
    'green',
    'blue',
    'purple',
  ];

  bgColors = [
    'rgba(244,67,78,0.15)', // red rgba
    'rgba(124,204,231,0.15)', // red green
    'rgba(76,202,252,0.15)', // red blue
    'rgba(155,82,225,0.15)', // red purple
  ];

  onAnswer = (option, index) => {
    const { onAnswer } = this.props;
    const node = this.pollRef.current;
    // add d-block class to more menu div
    if (node instanceof HTMLElement) {
      const alreadyAns = node.querySelector('#alreadyAns');
      const pollOptions = node.querySelector('#poll-options');
      if(alreadyAns && pollOptions) {
        alreadyAns.classList.add('animate__fadeIn', 'd-block');
        pollOptions.classList.add('animate__fadeOut', 'd-none');
        onAnswer(option, index);
      }
    }
  };

  componentDidMount() {
    const { isAlreadyAnswer } = this.props;
    const node = this.pollRef.current;
    // add d-block class to more menu div
    if (node instanceof HTMLElement) {
      const alreadyAns = node.querySelector('#alreadyAns');
      const pollOptions = node.querySelector('#poll-options');
      if(alreadyAns && pollOptions && isAlreadyAnswer) {
        alreadyAns.classList.add('animate__fadeIn', 'd-block');
        pollOptions.classList.add('animate__fadeOut', 'd-none');
      }
    }
  };

  render() {
    const {
      question,
      options,
    } = this.props;
    return (
      <div ref={this.pollRef} className="d-block w-100">
        <p className="font-20 family-poppins-medium text-ellipsis-2">{question}</p>
        <div className="poll-cell-options animate__animated" id="poll-options">
          {options && options.length > 0 && options.map((option, index) => {
            return (
              <button
                key={index}
                className={`btn btn-primary btn-lg p-3 mb-3 lS-btn-${this.colors[index]}-style btn-block family-poppins-medium font-18`}
                onClick={() => this.onAnswer(option, index)}
              >
                {option.attributes.option}
              </button>
            );
          })}
        </div>
        <div className="animate__animated poll-cell-details d-none" id="alreadyAns">
          {options && options.length > 0 && options.map((option, index) => {
            return (
              <div key={index} className="p-3 poll-cell d-flex align-items-center mb-3" style={{backgroundColor: this.bgColors[index]}}>
                <div className={`poll-cell-percent bg-${this.colors[index]} px-4 py-3 text-left`} style={{width:  + option.attributes.vote_percent + '%'}}>
                </div>
                <div className="poll-cell-text position-relative d-flex align-items-center justify-content-between">
                  <span className="family-poppins-medium font-18">{option.attributes.option}</span>
                  <span className="font-17 pl-4 text-nowrap">{option.attributes.votes} Votes</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

/**
 *  Define component PropTypes
 */
Poll.propTypes = {
  question: string.isRequired,
  options: array.isRequired,
  isAlreadyAnswer: bool.isRequired,
  onAnswer: func.isRequired,
};

export default Poll;
