import React from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import { getFormattedDate } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';
import Loader from 'components/Loader';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { getTrainingsForm, addPostForm } from '../../modules/trainingAnylasis/actions';
import { getTrainingsFormListing, getIsFetchingTrainingsForm } from '../../modules/trainingAnylasis/selectors';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const trainingAnalysisSchema = Yup.object().shape({
  profession: Yup.string()
    .oneOf(["health_care_assistant","general_practice_nurse","general_practitioner", "clinical_pharmacist", "physician_associate","advanced_clinical_practitioner"])
    .required('Required'),
  job_pattern: Yup.string()
    .oneOf(["partTime","fullTime"])
    .required('Required'),
  age_group: Yup.string()
    .oneOf(["18-25","26-30","31-35", "36-45", "45"])
    .required('Required'),
  job_type: Yup.string()
    .oneOf(["permanent","temporary","locumBanks"])
    .required('Required'),
  ethnecity: Yup.string()
    .oneOf(["asian","bame","white", "mixed"])
    .required('Required'),
  gender: Yup.string()
    .oneOf(["male","female","other"])
    .required('Required'),
  education: Yup.array().of(
    Yup.object().shape({
      academic_level: Yup.string()
        .required('Required'),
      course: Yup.string()
        .required('Required'),
      institution: Yup.string()
        .required('Required'),
      year_obatined: Yup.string()
        .required('Required')
    })
  )
  .required('Requied'),
  training: Yup.array().of(
    Yup.object().shape({
      course: Yup.string()
        .required('Required'),
      level: Yup.string()
        .required('Required'),
      action: Yup.string()
        .required('Required'),
    })
  )
  .required('Requied'),
  training_requirement_description: Yup.string()
    .required('Required')
})

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item.value,
      value: item.value
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formSelect = props => {
  const { form, options, field, placeholder, id } = props;
  return (
    <Select 
      components={{ 
        // DropdownIndicator:() => null, 
        IndicatorSeparator:() => null 
      }}
      className="rounded-lg"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value.value)
      }}
      value={field.value ? {label: field.value, value: field.value} : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
    )
  };

  const Checkbox = props =>  {
    return (
      <Field name={props.name} >
        {({ field, form }) => (
          <div className="col-xl-2 col-lg-3 col-sm-4 col-6 mb-4">
            <div className="form-check custom-form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={props.value}
                {...props}
                checked={field.value.includes(props.value)}
                onChange={() => {
                  if (field.value.includes(props.value)) {
                    const nextValue = field.value.filter(
                      value => value !== props.value
                    );
                    form.setFieldValue(props.name, nextValue);
                  } else {
                    const nextValue = field.value.concat(props.value);
                    form.setFieldValue(props.name, nextValue);
                  }
                }}
              />
              <label class="form-check-label font-18 family-poppins-medium" htmlFor={props.value}>
                {props.value}
              </label>
            </div>
          </div>
        )}
      </Field>
    );
  }

class TrainingAnalysis extends React.Component {

  componentDidMount(){
    this.props.getTrainingsForm()
  }

  getFormikInitVal = () => {
    const { trainingsListForm } = this.props;
    const { 
      profession,
      job_pattern,
      age_group,
      job_type,
      ethnicity,
      gender,
      education,
      training_required,
      training_attained,
      training_requirement_description,
    } = trainingsListForm.attributes || {}
    return {
      profession: profession ? profession : 'health_care_assistant',
      job_pattern: job_pattern ? job_pattern : 'partTime',
      age_group: age_group ? age_group : '18-25',
      job_type: job_type ? job_type : 'permanent',
      ethnecity: ethnicity ? ethnicity : 'bame',
      gender: gender ? gender :'male',
      education: education ? education : [{
        academic_level: '',
        course: '',
        institution: '',
        year_obatined: '',
      }],
      training: training_required ? training_required : [{
        course: '',
        level: '',
        action: '',
      }],
      training_attained: training_attained ? training_attained : [],
      training_requirement_description: training_requirement_description ?  training_requirement_description : '',
    }
  }

  addTrainingForm = (values, { setSubmitting, resetForm }) => {
    const { addPostForm, getTrainingsForm } = this.props;
    const params = {
			profession: values.profession,
      job_pattern: values.job_pattern,
      age_group: values.age_group,
      job_type: values.job_type,
      ethnicity: values.ethnecity,
      gender: values.gender,
      education: values.education,
      training_required: values.training,
      training_attained:values.training_attained,
      training_requirement_description: values.training_requirement_description,
      
    };
    
    addPostForm(params).then(res => {
      const { message } = res;
      getTrainingsForm();
      showSuccessMsg(message);
      resetForm(this.getFormikInitVal);
      setSubmitting(false);
    }, err => {
      setSubmitting(false);
    })
  }

  render() {
    const { isFetchingTrainingsForm } = this.props;
    const educationDropDown = [
      {label: "Care certificate", value: "Care certificate"}, 
      {label: "Level 2 Maths", value: "Level 2 Maths"},
      {label: "Level 2 English", value: 'Level 2 English'},
      {label: "Level 2 ICT", value: "Level 2 ICT"},
      {label: "Level 2 Health and Social Care", value: "Level 2 Health and Social Care"},
      {label: "Level 3 Health and Social Care", value: "Level 3 Health and Social Care"},
      {label: "Diploma", value: "Diploma"},
      {label: "Degree", value: "Degree"},
      {label: "Masters", value: "Masters"},
      {label: "PhD", value:"PhD"},
      {label: "Other certificates", value: "Other certificates"},
    ] 				

    const trainingDropDown = [
      {label: "Tissue Viability", value: "Tissue Viability"}, 
      {label: "Asthma", value: "Asthma"},
      {label: "COPD", value: 'COPD'},
      {label: "Cardiovascular Disease", value: "Cardiovascular Disease"},
      {label: "Diabetes", value: "Diabetes"},
      {label: "Family Planning", value: "Family Planning"},
      {label: "Minor illness and injury", value: "Minor illness and injury"},
      {label: "Non-Medical Prescribing", value: "Non-Medical Prescribing"},
      {label: "Examination of New-born baby", value: "Examination of New-born baby"},
      {label: "Infection Control", value:"Infection Control"},
      {label: "Mental Health", value: "Mental Health"},
      {label: "Palliative Care", value: "Palliative Care"},
      {label: "Frailty", value: "Frailty"},
      {label: "Public health", value: "Public health"},
      {label: "Clinical Education", value: "Clinical Education"},
    ]
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h4 className="family-poppins-semibold my-2">Analysis Training</h4>
                </div>
              </div>
            </div>
          </div>
          {/* form */}
          <div className="card border-0 mt-md-5 mt-4 lS-card-shadow lS-card-radius">
            <div className="card-body">
              {isFetchingTrainingsForm && (
                <Loader />
              )}

              {!isFetchingTrainingsForm && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={trainingAnalysisSchema}
                  onSubmit={this.addTrainingForm}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="ls-form pt-2 mt-4" >
                      {/* about your employment */}
                      <section className="employment-section">
                        <h4 className="family-poppins-semibold color-primary mb-5">About Your Employment</h4>
                        <div className="row">
                          <div className="col-xl-2 col-md-4 col-sm-6 mb-3">
                            <label className="font-20 text-black family-poppins-semibold mb-4 list-label d-flex align-items-end">Profession</label>
                            <div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="profession" id="health_care_assistant" value="health_care_assistant" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="health_care_assistant">
                                  Health Care Assistant
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="profession" id="general_practice_nurse" value="general_practice_nurse" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="general_practice_nurse">
                                  General Practice Nurse
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="profession" id="general_practitioner" value="general_practitioner" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="general_practitioner">
                                  General Practitioner
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="profession" id="clinical_pharmacist" value="clinical_pharmacist" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="clinical_pharmacist">
                                  Clinical Pharmacist
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="profession" id="physician_associate" value="physician_associate" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="physician_associate">
                                  Physician Associate
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="profession" id="advanced_clinical_practitioner" value="advanced_clinical_practitioner" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="advanced_clinical_practitioner">
                                  Advanced Clinical Practitioner
                                </label>
                              </div>
                              <ErrorMessage name="profession" component="div" className="error-msg mt-3" />
                            </div>
                          </div>
                          <div className="col-xl-2 col-md-4 col-sm-6 mb-3">
                            <label className="font-20 text-black family-poppins-semibold mb-4 list-label d-flex align-items-end">Job Pattern</label>
                            <div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="job_pattern" id="partTime" value="partTime" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="partTime">
                                  Part Time
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="job_pattern" id="fullTime" value="fullTime" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="fullTime">
                                  Full Time
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-md-4 col-sm-6 mb-3">
                            <label className="font-20 text-black family-poppins-semibold mb-4 list-label d-flex align-items-end">Age Group</label>
                            <div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="age_group" id="18-25" value="18-25" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="18-25">
                                  18 - 25 yr
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="age_group" id="26-30" value="26-30" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="26-30">
                                  26 - 30 yr
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="age_group" id="31-35" value="31-35" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="31-35">
                                  31 - 35 yr
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="age_group" id="36-45" value="36-45" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="36-45">
                                  36 - 45 yr
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="age_group" id={45} value="45" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor={45}>
                                  45+ yr
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-md-4 col-sm-6 mb-3">
                            <label className="font-20 text-black family-poppins-semibold mb-4 list-label d-flex align-items-end">Job Type</label>
                            <div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="job_type" id="permanent" value="permanent" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="permanent">
                                  Permanent
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="job_type" id="temporary" value="temporary" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="temporary">
                                  Temporary
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="job_type" id="locum-banks" value="locum-banks" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="locum-banks">
                                  Locum Banks
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-md-4 col-sm-6 mb-3">
                            <label className="font-20 text-black family-poppins-semibold mb-4 list-label d-flex align-items-end">Ethnicity</label>
                            <div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="ethnecity" id="bame" value="bame" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="bame">
                                  BAME
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="ethnecity" id="white" value="white" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="white">
                                  White
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="ethnecity" id="other" value="other" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="other">
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-md-4 col-sm-6 mb-3">
                            <label className="font-20 text-black family-poppins-semibold mb-4 list-label d-flex align-items-end">Gender</label>
                            <div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="gender" id="male" value="male" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="male">
                                  Male
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="gender" id="female" value="female" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="female">
                                  Female
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name="gender" id="others" value="other" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="others">
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <hr className="ls-border-color mb-5" />
                      {/* about your education */}
                      <section className="education-section">
                        <h4 className="family-poppins-semibold color-primary mb-5">About Your Education</h4>
                        <FieldArray
                          name="education"
                          render={arrayHelpers => (
                            values.education && values.education.length > 0 ? (
                              values.education.map((education, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-10 col-md-9">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="academic-level" className="color-grey pl-3">Academic Level</label>
                                          <Field as="select" component={formSelect} options={educationDropDown} name={`education[${index}].academic_level`} placeholder="Academic Level" />
                                          <ErrorMessage name={`education[${index}].academic_level`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="course" className="color-grey pl-3">Course</label>
                                          <Field type="text" name={`education[${index}].course`} className="form-control form-control-lg rounded-lg" placeholder="Enter Course" />
                                          <ErrorMessage name={`education[${index}].course`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="institution" className="color-grey pl-3">Institution</label>
                                          <Field type="text" name={`education[${index}].institution`} className="form-control form-control-lg rounded-lg" placeholder="Enter Institution" />
                                          <ErrorMessage name={`education[${index}].institution`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="year-obtained" className="color-grey pl-3">Year Obtained</label>
                                          <Field type="text" name={`education[${index}].year_obatined`} className="form-control form-control-lg rounded-lg" placeholder="Select Year" />
                                          <ErrorMessage name={`education[${index}].year_obatined`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-2 col-md-3 text-right pt-md-4 mt-md-3 mb-5">
                                    <span className="mr-2">
                                      <button type="button" className="btn btn-danger lS-btn-red-style btn-sm px-2" onClick={() => arrayHelpers.remove(index)} >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </span>
                                    <span className="ml-1">
                                      <button type="button" className="btn btn-primary lS-btn-blue-style btn-sm mr-1 px-2" onClick={() => arrayHelpers.push({
                                        academic_level: '',
                                        course: '',
                                        institution: '',
                                        year_obatined: '',
                                      }) } >
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="d-flex flex-fill w-100 justify-content-center">
                                <button type="button" className="btn btn-primary lS-btn-blue font-20 ml-3" 
                                onClick={() => arrayHelpers.push({
                                  academic_level: '',
                                  course: '',
                                  institution: '',
                                  year_obatined: '',
                                })}
                                >
                                  Add Education
                                </button>
                              </div>
                            ) 
                          )}
                        />
                        
                      </section>
                      <hr className="ls-border-color mb-5" />
                      
                      {/* Area of Pratice */}
                      <section className="training-section">
                        <h4 className="family-poppins-semibold color-primary mb-5">Area of Practice</h4>
                        <div className="row">
                          <Checkbox name="training_attained" value="Wound Care" />
                          <Checkbox name="training_attained" value="Cytology" />
                          <Checkbox name="training_attained" value="Medication Management" />
                          <Checkbox name="training_attained" value="ARTP/ Spirometry" />
                          <Checkbox name="training_attained" value="Travel Health " />
                          <Checkbox name="training_attained" value="Long Term Conditions" />
                          <Checkbox name="training_attained" value="Interpretation of bloods" />
                          <Checkbox name="training_attained" value="Immunisation" />
                          <Checkbox name="training_attained" value="Phlebotomy" />
                          <Checkbox name="training_attained" value="ABPM" />
                          <Checkbox name="training_attained" value="Catheter Care" />
                          <Checkbox name="training_attained" value="Leadership" />
                          <Checkbox name="training_attained" value="Contraception" />
                          <Checkbox name="training_attained" value="Sexual Health" />
                          <Checkbox name="training_attained" value="Advance Practice" />
                          <Checkbox name="training_attained" value="Palliative Care" />
                          <Checkbox name="training_attained" value="Stoma Care" />
                          <Checkbox name="training_attained" value="Mentorship" />
                        </div>
                      </section>
                      <hr className="ls-border-color mb-5" />
                      
                      {/* about your training */}
                      <section className="training-section">
                        <h4 className="family-poppins-semibold color-primary mb-5">About Your Training</h4>
                        <FieldArray
                          name="training"
                          render={arrayHelpers => (
                            values.training && values.training.length > 0 ? (
                              values.training.map((training, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-9 col-md-9">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="training" className="color-grey pl-3">Training</label>
                                          <Field as="select" className="form-control form-control-lg rounded-lg" component={formSelect} options={trainingDropDown} name={`training.[${index}].course`} placeholder="Select Training" />
                                          <ErrorMessage name={`training.[${index}].course`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>

                                      <div className="col-lg-4 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="academic-year" className="color-grey pl-3">Academic Level</label>
                                          <Field type="text" className="form-control form-control-lg rounded-lg" name={`training.[${index}].level`} placeholder="Level" />
                                          <ErrorMessage name={`training.[${index}].level`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>

                                      <div className="col-lg-4 col-md-6">
                                        <div className="form-group mb-5">
                                          <label htmlFor="training-year" className="color-grey pl-3">Undertake </label>
                                          <Field as="select" className="form-control form-control-lg rounded-lg" component={formSelect} options={[
                                            {label: "Yes", value: 'Yes'},
                                            {label: "No", value: 'No'},
                                            {label: "May Be", value: 'May Be'},
                                          ]} name={`training.[${index}].action`} placeholder="Undertake" />
                                          <ErrorMessage name={`training.[${index}].action`} component="div" className="error-msg mt-3" />
                                        </div>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="co-lg-2 col-md-3 text-right pt-md-4 mt-md-3 mb-5">
                                    <span className="mr-2">
                                      <button type="button" className="btn btn-danger lS-btn-red-style btn-sm px-2" onClick={() => arrayHelpers.remove(index)}>
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </span>
                                    <span className="ml-1">
                                      <button type="button" className="btn btn-primary lS-btn-blue-style btn-sm mr-1 px-2" onClick={() => arrayHelpers.push({
                                        course: '',
                                        level: '',
                                        action: '',
                                      }) }>
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    </span>
                                  </div>

                                </div>
                              ))
                            ) : (
                              <div className="d-flex flex-fill w-100 justify-content-center">
                                <button type="button" className="btn btn-primary lS-btn-blue font-20 ml-3" 
                                onClick={() => arrayHelpers.push({
                                  course: '',
                                  level: '',
                                  action: '',
                                })}
                                >
                                  Add Training
                                </button>
                              </div>
                            )
                          )}
                        />
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group mb-5">
                              <label htmlFor="training_requirement_description" className="color-grey pl-3">If you are interested in ANY other training or courses not listed above, please state below</label>
                              <Field as='textarea' rows={5} className="flex-fill form-control form-control-lg rounded-lg" name="training_requirement_description" placeholder="Enter your text" />
                              <ErrorMessage name="training_requirement_description" component="div" className="error-msg mt-3" />
                            </div>
                          </div>
                        </div>
                      </section>
                      <div className="text-md-right text-center">
                        <Link to={`/user/index`} className="ls-btn-link btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</Link>
                        <button type="submit" className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3" disabled={isSubmitting}>
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                          )}
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </main>

        <UserFooter />

      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
TrainingAnalysis.propTypes = {
  addPostForm: func.isRequired,
  getTrainingsForm: func.isRequired,
  trainingsListForm: object.isRequired,
  isFetchingTrainingsForm: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  trainingsListForm: getTrainingsFormListing(),
  isFetchingTrainingsForm: getIsFetchingTrainingsForm(),
});

export default connect(
  mapStateToProps,
  {
    getTrainingsForm,
    addPostForm
  }
)(withRouter(TrainingAnalysis));