/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const reflectiveAccount = () => state => state.get('reflectiveAccount');

/**
 *  Select the correct portion of the root reducer
 */
// export const getNewsListing = () =>
//   createSelector(news(), state => {
//     const listing = state.get('news').toJS()
//     return listing.length > 0 ? listing : []
//   });

/**
 *  Select Single news page object
 */
export const getReflectiveAccountDetail= () =>
  createSelector(reflectiveAccount(), state => {
    const reflectiveAccount = state.get('reflectiveAccount')
    return reflectiveAccount && Object.keys(reflectiveAccount).length > 0 ? reflectiveAccount.toJS() : {}
  });

/**
 *  Select is fetching news
 */
export const getIsFetchingReflectiveAccount = () =>
  createSelector(reflectiveAccount(), state => {
    return state.get('isFetchingReflectiveAccount')
  })

/**
 *  Select is fetching news
 */
// export const getIsFetchingSingleNews = () =>
//   createSelector(news(), state => {
//     return state.get('isFetchingSingleNews')
//   })

/**
 *  Select total number of news pages
 */
// export const getTotalPages = () =>
//   createSelector(news(), state => {
//     return state.get('totalPage')
//   })

/**
 *  Select current news pages
 */
// export const getNewsPage = () =>
//   createSelector(news(), state => {
//     return state.get('page')
//   })
