/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_JOBS,
  UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  job: {},
  isFetchingJob: false,
  isUploadingImage: false,
  isDeletingJob: false,
});

/**
 *  Define the reducer with actions
 */
function postJobsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_JOBS:
      return fromJS({
        ...state.toJS(),
        isFetchingJob: true,
      })

    case success(GET_LIST_OF_JOBS):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        job: data ? data : {},
        isFetchingJob: false,
      })

    case failure(GET_LIST_OF_JOBS):
      return fromJS({
        ...state.toJS(),
        isFetchingJob: false,
      })

    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })

    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default postJobsReducer
