import React from "react";
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { showSuccessMsg } from 'utils/notification';
/**
 *  Import other dependencies
 */
import { reset } from 'modules/auth/actions';

const resetSchema = Yup.object().shape({
	password: Yup.string()
    .required('Required')
    .min(6, 'Password must be at least 6 characters'),
	password_confirmation: Yup.string()
	  .required('Required')
	  .oneOf([Yup.ref('password'), null], "Password must match"),
  });
  
  class ResetPassword extends React.Component {
	state = {
    token: this.props.match.params.token
  };

	resetPasswordUser = (values, { setSubmitting }) => {	  
	  const { reset, history } = this.props;
	  const { password, password_confirmation } = values;
	  const { token } = this.state;
    reset(password, password_confirmation, token).then(res => {
      showSuccessMsg(res.message);
      setSubmitting(false);
      if (history) {
        history.push('/user/login');
      }
    }, err => {
      setSubmitting(false);
    });
	};
  
	render() {
	  return (
		<>
      <div className="d-flex justify-content-center ls-height-100 align-items-center">
        <div className="row ls-form-content">
          <div className="col-md-12 text-center mb-3">
            <h2 className="family-poppins-semibold">Reset Password!</h2>
          </div>
          <div className="col-md-12 mt-3">
          <Formik
            initialValues={{ password: '', password_confirmation: '' }}
            validationSchema={resetSchema}
            onSubmit={this.resetPasswordUser}
          >
          {({ isSubmitting }) => (
            <Form>
              <div className="field-group ls-login-form">
                <label htmlFor="newPassword" className="mb-0 text-muted font-10">New Password<span className="text-danger">*</span> </label>
                <Field type="password" name="password" className="form-control font-14" placeholder="New password" />
                <ErrorMessage name="password" component="div" className="error-msg" />
              </div>

              <div className="field-group ls-login-form mt-3">
                <label htmlFor="confirmPassword" className="mb-0 text-muted font-10">Confirm Password<span className="text-danger">*</span> </label>
                <Field type="password" name="password_confirmation" className="form-control font-14" placeholder="Confirm new password" />
                <ErrorMessage name="password_confirmation" component="div" className="error-msg" />
              </div>
              <button type="submit" className="btn btn-primary ls-login-button w-100 py-3 my-3 mb-2 m-1" disabled={isSubmitting}>
                { 
                isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Save Password
              </button>
            </Form>
          )}
          </Formik>
          </div>
        </div>
      </div>
		  
		</>
	  );
	}
  }
  
  /**
   *  Define component PropTypes
   */
  ResetPassword.propTypes = {
	reset: func.isRequired,
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({});
  
  export default connect(
	mapStateToProps,
	{
	  reset
	}
  )(withRouter(ResetPassword));