export const GET_CPD_LOG =
  'src/modules/revalidation/cpdLog/GET_CPD_LOG';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/revalidation/cpdLog/CANCEL_ALL_API_REQUESTS';
export const SHOW_CPD_LOG =
  'src/modules/revalidation/cpdLog/SHOW_CPD_LOG';
export const POST_CPD_LOG =
  'src/modules/revalidation/cpdLog/POSTFEEDBACK_LOG';
export const UPDATE_CPD_LOG =
  'src/modules/revalidation/cpdLog/UPDATE_CPD_LOG';
export const DELETE_CPD_LOG =
  'src/modules/revalidation/cpdLog/DELETE_CPD_LOG';



