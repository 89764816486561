/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_NEWS,
  GET_SINGLE_NEWS,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 9,
  page: 1,
  totalPage: 0,
  news: [],
  singleNews: {},
  isFetchingNews: false,
  isFetchingSingleNews: false,
});

/**
 *  Define the reducer with actions
 */
function newsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_NEWS:
      return fromJS({
        ...state.toJS(),
        isFetchingNews: true,
      })

    case success(GET_LIST_OF_NEWS):
      const { data } = action.payload.data;
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        news: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingNews: false,
      })

    case failure(GET_LIST_OF_NEWS):
      return fromJS({
        ...state.toJS(),
        isFetchingNews: false,
      })
    
    case GET_SINGLE_NEWS:
      return fromJS({
        ...state.toJS(),
        singleNews: {},
        isFetchingSingleNews: true,
      })

    case success(GET_SINGLE_NEWS):
      return fromJS({
        ...state.toJS(),
        singleNews: action.payload.data.data.attributes,
        isFetchingSingleNews: false,
      })
      
    case failure(GET_SINGLE_NEWS):
      return fromJS({
        ...state.toJS(),
        isFetchingSingleNews: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    case APPLY_PAGINATION:
        return fromJS({
          ...state.toJS(),
          page: action.page
        })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default newsReducer
