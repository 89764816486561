/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_ORGANISATION,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  organisation: [],
  isFetchingOrganisation: false,
});

/**
 *  Define the reducer with actions
 */
function addUserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_ORGANISATION:
      return fromJS({
        ...state.toJS(),
        isFetchingOrganisation: true,
      })

    case success(GET_LIST_OF_ORGANISATION):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        organisation: data && data.length > 0 ? data : [],
        isFetchingOrganisation: false,
      })

    case failure(GET_LIST_OF_ORGANISATION):
      return fromJS({
        ...state.toJS(),
        isFetchingOrganisation: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default addUserReducer
