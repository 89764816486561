export const GET_LIST_OF_USERS =
  'src/modules/adminUserListing/GET_LIST_OF_USERS';
export const APPLY_FILTERS =
  'src/modules/adminUserListing/APPLY_FILTERS';
export const RESET_FILTERS =
  'src/modules/adminUserListing/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/adminUserListing/APPLY_PAGINATION';
export const APPROVE_USERS =
  'src/modules/adminUserListing/APPROVE_USERS';
export const SELECT_CHECKBOX =
  'src/modules/adminUserListing/SELECT_CHECKBOX';
export const UNSELECT_CHECKBOX =
  'src/modules/adminUserListing/UNSELECT_CHECKBOX';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/adminUserListing/CANCEL_ALL_API_REQUESTS';
