import React from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loader from 'components/Loader';
import { getFormattedDateTime, getLocalStorage, getFormattedDateForComments } from 'utils/helper';
import Swiper from 'react-id-swiper';
import { showSuccessMsg } from 'utils/notification';
import Share from "../components/share";
import StarRating from 'components/starRating';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

// Import style
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

/**
 *  Import other dependencies
 */
import { getNews  } from '../modules/news/actions';
import { getNewsListing, getIsFetchingNews } from '../modules/news/selectors';

import { getCalender  } from '../modules/calender/actions';
import { getCalenderListing, getIsFetchingCalender } from '../modules/calender/selectors';

import { getDashboardEvents , applyEvent } from '../modules/events/actions';
import { getEventsListing, getIsFetchingEvents } from '../modules/events/selectors';

const MySwal = withReactContent(Swal);

const newsSliderParams = {
  slidesPerView: 3,
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-nav-next',
    prevEl: '.swiper-nav-prev'
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1199: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  }
}

const eventsSliderParams = {
  slidesPerView: 2,
  slidesPerColumn: 2,
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-nav-next',
    prevEl: '.swiper-nav-prev'
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      slidesPerColumn: 0,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      spaceBetween: 20,
    },
    1199: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      spaceBetween: 30,
    },
  }
}

class Index extends React.Component {
  state = {
    swiper: null,
    isVisibleShare: false,
    eventSwiper: null,
    user: JSON.parse(getLocalStorage()),
  };

  openShareModal(id) {
    this.setState({
        isVisibleShare: true,
        eventId: id,
    });
  }

  componentWillMount() {
    this.props.getNews()
    this.props.getDashboardEvents()
    this.props.getCalender()
  }

  getSingleEvent = (eventObj, index, id) => (
    <div key={index} className="mt-0">
      <div className="card border-0 lS-card-shadow lS-card-radius mb-4">
        <div className="card-body d-flex">
          <div className="event-img overflow-hidden">
            <img src={eventObj.image_url} alt='event_image' />
          </div>
          <div className="min-width0 flex-fill pl-3">
            <h5 className="mt-0 font-normal color-black-heading text-truncate family-poppins-medium">{eventObj.title}</h5>
            <div className="d-flex align-items-center mb-2">
              <OverlayTrigger
                key={index}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-${index}`}>
                    <strong>{0}</strong>
                  </Tooltip>
                }
              >
                <div>
                  <StarRating 
                    initialRating={0}
                    readOnly={true}
                    onRate={() => {}}
                  />
                </div>
              </OverlayTrigger>
              <span className="ml-2 color-grey">
                {0}
              </span>
              <i className="fas fa-user ml-2 color-grey"></i>&nbsp;
            </div>
            {/* <p className="mb-2 font-12 rating-starts">
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
            </p> */}
            <p className="color-grey mb-1">{eventObj.start_date ? getFormattedDateTime(eventObj.start_date) : `TBC`}</p>
            <div className="event-bottom d-flex align-items-center justify-content-between mt-4">
              <div className="d-flex flex-wrap flex-fill">
                {!eventObj.can_edit && !eventObj.has_already_applied && (
                  <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                          onClick={() => this.applyEvent(id)}>
                    <i className="fas fa-calendar-alt mr-1" /> Book
                  </button>
                )}

                {!eventObj.can_edit && eventObj.has_already_applied && (
                  <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2 "
                      disabled={eventObj.has_already_applied}>
                    <i className="fas fa-calendar-alt mr-1" /> Booked
                  </button>
                )}
                <Link to={`/user/events/${id}`} className="btn btn-primary lS-btn-icon-sm font-12 lS-btn-red-style rounded-lg mr-2 mt-2">
                  <i className="fas fa-eye" />
                </Link>
                <button className="btn btn-primary lS-btn-icon-sm font-11 lS-btn-green-style rounded-lg mt-2"
                 onClick={() => this.openShareModal(id)}>
                  <i className="fas fa-share-alt" />
                </button>
              </div>
              {!eventObj.chargeable && (
                <span className="font-italic mt-1 font-normal color-light-red family-poppins-medium">Free</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  getSingleNews = (newsObj, index, id) => (
    <div key={index}>
      <div className="card border-0 lS-card-shadow lS-card-radius mb-4">
        <div className="card-body">
          <h5 className="mt-0 font-normal color-black-heading text-truncate family-poppins-semibold">{newsObj.title}</h5>
          <span className="text-uppercase font-14 color-primary d-block my-2">{newsObj.federation_name}</span>
          <p className="color-grey mb-0 text-truncate position-relative pr-5">{newsObj.content}<Link to={`/user/news/${id}`} className="more-text font-italic"> More <i className="fas fa-angle-double-right"></i></Link></p>
          <div className="color-black-heading mt-3 font-14">
            <span className="d-lg-inline d-inline-block">
              <i className="fas fa-calendar-alt mr-2 color-primary" />
              {getFormattedDateForComments(newsObj.created_at)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleDateClick = (arg) => { // bind with an arrow function
    console.log(arg.event);
    let startTime = arg.event.start ? getFormattedDateTime(arg.event.start) : `TBD`;
    let endTime = arg.event.end ? getFormattedDateTime(arg.event.end) : `TBD`;
    return MySwal.fire({
      title:arg.event.title,
      text: startTime +' - '+endTime,
      icon: "success",
      // footer: startTime +' - '+endTime,
    });
  }

  updateSlide = btnType => {
    const { swiper } = this.state;
    if (swiper !== null) {
      if (btnType === 'prev') {
        swiper.slidePrev();
        return;
      }

      swiper.slideNext();
    }
  };

  eventsUpdateSlide = btnType => {
    const { eventSwiper } = this.state;
    if (eventSwiper !== null) {
      if (btnType === 'prev') {
        eventSwiper.slidePrev();
        return;
      }

      eventSwiper.slideNext();
    }
  };

  applyEvent = (id) => {
    var params = {
      event_id:id,
    };

    this.props.applyEvent(params).then(res => {
      var message = res.message;
      this.props.getDashboardEvents()
      showSuccessMsg(message ? message : 'Booked successfully.');
    })
  }

  render() {
    const { news, isFetchingNews, calender, isFetchingCalender, events, isFetchingEvents } = this.props;
    const { user, isVisibleShare, eventId } = this.state;
    return (
      <>
        <UserHeader />
        <main className="m-0">
          <div className="container-fluid px-3 px-md-5 pt-md-5 pt-4 mb-5">
            {/* Welcome banner */}
            <div className="home-welcome-banner p-4 text-white">
              <div className="px-3 py-2">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-8">
                    <h5>Hi, {`${ user.first_name || '' } ${ user.last_name || '' }`}</h5>
                    <p className="mb-0 mt-3">Welcome to the training hub's internal portal.
                   To begin using the platform please click on the button to complete your training need analysis form.</p>
                  </div>
                  <div className="col-xl-6 col-lg-4 text-lg-right text-center mt-3 mt-lg-0">
                    <Link to={user.role === `staff` ? `/user/training-analysis` : `#`} 
                      className="ls-btn-link btn text-white text-decoration-none">
                        Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Events */}
            <div className="row mt-md-5 mt-4">
              <div className="col-xl-8">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="family-poppins-semibold mb-0">Your Upcoming Events</h4>
                  <div className="news-nav">
                    <button className="btn p-0 rounded-circle border-0 swiper-nav-prev mr-2 mr-md-3" onClick={() => {this.eventsUpdateSlide('prev')}}>
                      <i className="fas fa-chevron-left" />
                    </button>
                    <button className="btn p-0 rounded-circle border-0 swiper-nav-next" onClick={() => {this.eventsUpdateSlide('next')}}>
                      <i className="fas fa-chevron-right" />
                    </button>
                  </div>
                </div>
                {!isFetchingEvents && 
                  events &&
                  events.length > 0 && (
                  <Swiper getSwiper={(swiper) => this.setState({ eventSwiper: swiper })} {...eventsSliderParams}>
                    {events.map((eventObj, index) => this.getSingleEvent(eventObj.attributes, index, eventObj.id))}
                  </Swiper>
                )}
                {!isFetchingEvents && events && events.length === 0 && (
                  <div className="text-center">No events found</div>
                )}
                {isFetchingEvents && (
                  <Loader />
                )}
              </div>
              <div className="col-xl-4">
                {/* Calendar code */}
                <h4 className="family-poppins-semibold mb-4 pb-xl-2 pt-xl-1">Calendar</h4>
                <div className="card event-calendar-sm lS-card-shadow">
                  {isFetchingCalender && (
                    <Loader />
                  )}
                  {!isFetchingCalender && (
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[ dayGridPlugin ]}
                      header={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                      }}
                      events={calender}
                      eventClick={this.handleDateClick}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* News */}
            <div className="pt-3">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h4 className="family-poppins-semibold mb-0">News Bulletin</h4>
                <div className="news-nav">
                  <button className="btn p-0 rounded-circle border-0 swiper-nav-prev mr-2 mr-md-3" onClick={() => {this.updateSlide('prev')}}>
                    <i className="fas fa-chevron-left" />
                  </button>
                  <button className="btn p-0 rounded-circle border-0 swiper-nav-next" onClick={() => {this.updateSlide('next')}}>
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
              {!isFetchingNews && news && news.length === 0 && (
                <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No news found</div>
              )}

              {isFetchingNews && (
                <Loader />
              )}
              {!isFetchingNews && news && news.length > 0 && (  
                <Swiper getSwiper={(swiper) => this.setState({ swiper })} {...newsSliderParams}>
                  {news.map((newsObj, index) => this.getSingleNews(newsObj.attributes, index, newsObj.id))}
                </Swiper>
              )}
            </div>
          </div>
        </main>
        <UserFooter />
        {isVisibleShare && (
          <Share
              isVisible={isVisibleShare}
              endpoint={"/user/events/"+ eventId}
              onClose={() => {
                  this.setState({
                    isVisibleShare: false,
                  });
              }}
              onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Index.propTypes = {
  news: array.isRequired,
  getNews: func.isRequired,
  isFetchingNews: bool.isRequired,

  calender: array.isRequired,
  getCalender: func.isRequired,
  isFetchingCalender: bool.isRequired,

  events: array.isRequired,
  applyEvent: func.isRequired,
  getDashboardEvents: func.isRequired,
  isFetchingEvents: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  news: getNewsListing(),
  isFetchingNews: getIsFetchingNews(),
  events: getEventsListing(),
  isFetchingEvents: getIsFetchingEvents(),
  calender: getCalenderListing(),
  isFetchingCalender: getIsFetchingCalender(),
});

export default connect(
  mapStateToProps,
  {
    getNews,
    getDashboardEvents,
    getCalender,
    applyEvent
  }
)(withRouter(Index));
