/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const auth = () => state => state.get('auth');

/**
 *  Select the correct portion of the root reducer
 */
export const getEducationInstituteList = () =>
  createSelector(auth(), state => {
    const listing = state.get('educationInstitute').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getPracticesList = () =>
  createSelector(auth(), state => {
    const listing = state.get('practices').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getPublicProfile= () =>
  createSelector(auth(), state => {
    const listing = state.get('publicProfile').toJS();
    return Object.keys(listing).length > 0 ? listing : {}
  });

/**
 *  Select is Verifying Email
 */
export const getIsVerifyingEmail = () =>
  createSelector(auth(), state => {
    return state.get('isVerifyingEmail')
  })

/**
 *  Select is Public Passport
 */
export const getIsfetchingPubProfile = () =>
  createSelector(auth(), state => {
    return state.get('isfetchingPubProfile')
  })
