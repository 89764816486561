/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../../utils/redux';
import {
  GET_CPD_LOG,
  CANCEL_ALL_API_REQUESTS,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  cpdLog: [],
  reflectiveDiscussion: {},
  isFetchingCpdLog: false,
});

/**
 *  Define the reducer with actions
 */
function cpdLogReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CPD_LOG:
      return fromJS({
        ...state.toJS(),
        isFetchingCpdLog: true,
      })

    case success(GET_CPD_LOG):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        cpdLog: data && data.length > 0 ? data : [],
        isFetchingCpdLog: false,
      })
      
    case failure(GET_CPD_LOG):
      return fromJS({
        ...state.toJS(),
        isFetchingCpdLog: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    // case APPLY_PAGINATION:
    //     return fromJS({
    //       ...state.toJS(),
    //       page: action.page
    //     })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default cpdLogReducer
