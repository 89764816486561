import { showErrorMsg } from './notification';
import * as moment from 'moment';
// Permissions arrays
import {
  student,
  staff,
  educator,
  federation,
  admin,
  manager,
  routesToSkip
} from "configs/routesConfig";

const storageKeyName = 'link-staff';
const dateFormat = 'DD-MMM-YYYY';
const dateFormatComments = 'DD MMM, YYYY';
const timeFormat = 'hh:mm A';
const chatFormat = 'MMMM Do YYYY, h:mm:ss a';
const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

/**
 * Handle API Error Reponse
 *
 * @param err
 */
export const httpHandleError = error => {
  /* error = { error, config, code, request, response } */
  try {
    var xhr = error.request
    if (!xhr.response) {
      return Promise.reject({})
    }

    
    var err = xhr.response;
    if (xhr && xhr.status && err) {
      switch (xhr.status) {
        case 400:
        case 401:
          logout();
          showErrorMsg(err.message)
          break;
        case 404:
          showErrorMsg(err.message)
          break;
        case 422:
          if (err.errors) {
            showErrorMsg(err.errors[Object.keys(err.errors)[0]][0]);
            break;
          }
          showErrorMsg(err.message)
          break;

        default:
          showErrorMsg("An internal error occur")
      }
    } else {
      showErrorMsg("An internal error occur")
    }
    return Promise.reject({})
  } catch (e) {
    return Promise.reject({})
  }
};

export const logout = () => {
  deleteLocalStorage();
  window.location.replace("/auth/login");
};

export const setLocalStorage = data => {
  if (!data) return;
  localStorage.setItem(storageKeyName, JSON.stringify(data));
};

export const getLocalStorage = () => {
  return localStorage.getItem(storageKeyName);
};

export const deleteLocalStorage = () => {
  return localStorage.removeItem(storageKeyName);
};

export const getToken = () => {
  const storage = getLocalStorage();
  if (storage) {
    const { token } = JSON.parse(storage);
    return token;
  }

  return null;
};

export const getChatToken = () => {
  const storage = getLocalStorage();
  if (storage) {
    const { chat_token } = JSON.parse(storage);
    return chat_token;
  }

  return null;
};

export const getUserID = () => {
  const storage = getLocalStorage();
  if (storage) {
    const { user_id } = JSON.parse(storage);
    return user_id;
  }

  return null;
};

export const getFormattedDate = date => {
  if (!date) return;
  return moment(date).format(dateFormat);
};

export const getFormattedDateForComments = date => {
  if (!date) return;
  return moment(date).format(dateFormatComments);
};

export const getFormattedTime = date => {
  if (!date) return;
  return moment(date).format(timeFormat);
};

export const getFormattedDateTime = date => {
  if (!date) return;
  return moment(date).format(dateTimeFormat);
};

export const getFormattedDateTimeForChat = date => {
  if (!date) return;
  return moment(date).format(chatFormat);
};

export const isDisplayRoute = path => {
  const storage = getLocalStorage();
  if (storage) {
    const { role } = JSON.parse(storage);

    if (path.search(':') !== -1) {
      return false;
    }

    if (routesToSkip.includes(path)) {
      return false;
    }

    switch (role) {
      case "federation":
        return federation.includes(path);
      
      case "student":
        return student.includes(path);
      
      case "staff":
        return staff.includes(path);
      
      case "educator":
        return educator.includes(path);

      case "admin":
        return admin.includes(path);
      
      case "manager":
        return manager.includes(path);

      default:
        return false;
    }
  }
  return false;
};

export const getAllowedRouteList = routes => {
  if(!routes) return [];
  return routes.filter(route => isDisplayRoute(route.path));
};

export const isRouteAllow = path => {
  const storage = getLocalStorage();
  if (storage) {
    const { role } = JSON.parse(storage);
    switch (role) {
      case "federation":
        return federation.includes(path);
      
      case "student":
        return student.includes(path);
      
      case "staff":
        return staff.includes(path);
      
      case "educator":
        return educator.includes(path);

      case "admin":
        return admin.includes(path);
      
      case "manager":
        return manager.includes(path);

      default:
        return false;
    }
  }
  return false;
};

export const filterSalaryByType = (type, array) => {
  if (!type || !array) return;
  return array.filter(value => value.includes(type));
};

export const filterGreaterValue = (currentValue, array) => {
  if (!currentValue || !array) return;
  const count = Number(currentValue.replace(/\D/g, ""));
  return array.filter(value => Number(value.replace(/\D/g, "")) > count);
};
