/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const postTrainings = () => state => state.get('postTrainings');

/**
 *  Select the correct portion of the root reducer
 */
export const getTrainingsListing = () =>
  createSelector(postTrainings(), state => {
    const listing = state.get('trainings').toJS()
    return listing ? listing : {}
  });

/**
 *  Select is fetching trainings
 */
export const getIsFetchingTrainings = () =>
  createSelector(postTrainings(), state => {
    return state.get('isFetchingTrainings')
  })

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(postTrainings(), state => {
    return state.get('isUploadingImage')
  })


/**
 *  Select is fetching trainings
 */
export const getIsDeletingTraining = () =>
  createSelector(postTrainings(), state => {
    return state.get('isDeletingTraining')
  })

/**
 *  Select total number of trainings pages
 */
export const getTotalPages = () =>
  createSelector(postTrainings(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current training pages
 */
export const getTrainingPage = () =>
  createSelector(postTrainings(), state => {
    return state.get('page')
  })
