const jobTitles = [
	'Administrator',
	'Advance Clinical Practitioner',
	'Advance Nurse Practitioner',
	'Assistant Manager',
	'Business Manager',
	'Clinical Director',
	'Clinical Nurse Specialist',
	'Clinical Pharmacist',
	'Community Nurse',
	'Community Pharmacist',
	'Corporate team',
	'District Nurse',
	'General Practice Nurse',
	'General Practitioner',
	'Health Visitor',
	'Healthcare Assistant',
	'Midwife',
	'Nurse ConsultanT',
	'Nurse Practitioner',
	'Nursing Associate',
	'Practice Educator',
	'Practice Manager',
	'Receptionist',
	'School Nurse',
	'Senior General Practice Nurse',
	'Student',
];
const bands = [
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
];
export {
	jobTitles,
	bands
}