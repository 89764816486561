/**
 *  Import action creator constants & dependencies
 */
import {
  GET_CALENDER_ITEMS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Calender items
 */
export function getCalender() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, getCalenderListing } = state.get('calender').toJS();
    const params = {
      page,
      limit,
      getCalenderListing,
    };

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getCalenderBegin(
          API_URLS.CALENDER.GET_CALENDER_LISTING,
          [],
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getCalenderBegin = (API_URL, params, source) => ({
  type: GET_CALENDER_ITEMS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
// export function applyPagination(page) {
//   return async dispatch => {
//     dispatch(applyPaginationBegin(page));
//     dispatch(getNews());
//   };
// };

// export const applyPaginationBegin = page => ({
//   type: APPLY_PAGINATION,
//   page
// });

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


