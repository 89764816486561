/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_EVENTS,
  GET_SINGLE_EVENTS,
  APPLY_PAGINATION,
  APPLY_FILTERS,
  RESET_FILTERS,
  CANCEL_ALL_API_REQUESTS,
  GET_LIST_OF_EVENTS_APPLICANTS,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 10,
  page: 1,
  totalPage: 0,
  events: [],
  eventApplicants: [],
  singleEvent: {},
  search: '',
  selectedRegion: '',
  selectedGroup: '',
  isFetchingEvents: false,
  isFetchingSingleEvent: false,
  isFetchingEventApplicants: true,
  totalEventsAppliedPage:0,
  eventAppliedPage:1,
});

/**
 *  Define the reducer with actions
 */
function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_EVENTS:
      return fromJS({
        ...state.toJS(),
        isFetchingEvents: true,
      })

    case success(GET_LIST_OF_EVENTS):
      const { data } = action.payload.data;
      const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        events: data && data.length > 0 ? data : [],
        totalPage: total_pages,
        isFetchingEvents: false,
      })

    case failure(GET_LIST_OF_EVENTS):
      return fromJS({
        ...state.toJS(),
        isFetchingEvents: false,
      })
    
    case GET_SINGLE_EVENTS:
      return fromJS({
        ...state.toJS(),
        singleEvent: {},
        isFetchingSingleEvent: true,
      })

    case success(GET_SINGLE_EVENTS):
      return fromJS({
        ...state.toJS(),
        singleEvent: action.payload.data.data.attributes,
        isFetchingSingleEvent: false,
      })
      
    case failure(GET_SINGLE_EVENTS):
      return fromJS({
        ...state.toJS(),
        isFetchingSingleEvent: false,
      })
    
    case GET_LIST_OF_EVENTS_APPLICANTS:
      return fromJS({
        ...state.toJS(),
        isFetchingEventApplicants: true,
      })

    case success(GET_LIST_OF_EVENTS_APPLICANTS):
      const  eventApplicantsData  = action.payload.data.data;
      const  event_applicants_total_pages  = action.payload.data.meta.pagination;      
      return fromJS({
        ...state.toJS(),
        eventApplicants: eventApplicantsData && eventApplicantsData.length > 0 ? eventApplicantsData : [],
        totalPage: event_applicants_total_pages,
        isFetchingEventApplicants: false,
      })

    case failure(GET_LIST_OF_EVENTS_APPLICANTS):
      return fromJS({
        ...state.toJS(),
        isFetchingEventApplicants: false,
      })
    
    case RESET_FILTERS:
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })
    
    case APPLY_FILTERS:
      return fromJS({
        ...state.toJS(),
        search: action.search,
        selectedRegion: action.selectedRegion,
        selectedGroup: action.selectedGroup,
        page: 1
      })

    case APPLY_PAGINATION:
        return fromJS({
          ...state.toJS(),
          page: action.page
        })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default eventsReducer
