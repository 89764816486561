import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import Share from "../../components/share";
import Select from 'react-select';
import { isRouteAllow, filterSalaryByType, filterGreaterValue } from 'utils/helper';
import { minMaxSalary } from './jobsFilters';
import UserFooter from 'components/Footers/UserFooter';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

/**
 *  Import other dependencies
 */

import { deletePostJob } from '../../modules/jobPost/actions';

import { getJobs, applyJob, getJobsOccupations, applyFilters, checkUnCheckFilters, applyPagination, resetFilters } from '../../modules/jobs/actions';
import { getJobsListing, getJobsOccupationsList, getIsFetchingJobs, getTotalPages, getJobPage } from '../../modules/jobs/selectors';
import { jobType, location } from './jobsFilters';
import Loader from 'components/Loader';

const jobSchema = Yup.object().shape({
  occ: Yup.array(),
  type: Yup.array(),
  location: Yup.array(),
  maxSalary: Yup.string(),
  minSalary: Yup.string()
});

const formattedArray = (array, fieldName, state) => {
  if (fieldName === 'maxSalary') {
    const { minSalary } = state;
    if(minSalary) {
      const result =  minSalary.split(" ");
      const salaryType =  result[result.length - 1];
      const filterData = filterGreaterValue(minSalary, filterSalaryByType(salaryType, array));
      array = filterData;
    }
  }
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

// const customStyles = {
//   control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
//   placeholder: () => ({
//     fontSize: '13px',
//     color: 'inherit',
//   }),
//   singleValue: () => ({
//     fontSize: '13px',
//     color: 'inherit',
//   }),
// };


const customStyles = {
  control: styles => ({
    ...styles,
    minHeight: 34,
    borderColor: '#ebebeb',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 34,
    padding: '2px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
    backgroundColor: '#FFF',
    cursor: 'pointer',
  }),
  clearIndicator: styles => ({
    position: 'absolute',
    right: '16px',
    backgroundColor: '#FFF',

  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  return (
    <Select
      isMulti
      components={{ IndicatorSeparator:() => null }}
      className="ls-select-dropdown"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value);
      }} 
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class Jobs extends React.Component {
  state={
    minSalary: '',
    maxSalary: '',
    search: null,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    jobId:0,
  }

  getFormikInitVal = () => {
		return { 
      occ:[],
      search: '',
      occupations: [],
      duration: [],
      group: [],
      location: [],
      category: [],
    };
  };

  openShareModal(id) {
    this.setState({
        isVisibleShare: true,
        jobId: id,
    });
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    this.props.getJobs();
    this.props.getJobsOccupations();
  }

  // componentWillUnmount() {
  //   this.resetJobs();
  // }

  handleRange = event => {
    this.setState({
      range: event.target.value
    });
  };

  deleteJob = (id) => {
    // var params = {
    //   status:'cancelled',
    // };

    this.props.deletePostJob(id).then(res => {
      var message = res.message;
      this.props.getJobs();
      this.props.getJobsOccupations();
      showSuccessMsg(message ? message : 'Deleted');
    })
  }

  getSingleJob = (job, index, id) => (
    <div className="col-lg-4 col-sm-6 mb-4">
      <div className="card lS-card-shadow border-0 lS-card-radius ls-height-100">
        <div className="card-body d-flex justify-content-between flex-column">
          <div className="flex-fill">
            <div className="thumb-wrap thumb-wrap-lg border radius-10 ls-border-color mb-3">
              <img src={job.job_image_url} alt="jobs-thumb" />
            </div>
            <h4 className="text-truncate family-poppins-semibold">
              <Link to={`/user/jobs/${id}`} className="link-hover-text">
              {job.title}
              </Link>
            </h4>
            <p className="font-15 color-primary mb-1">{job.occupation}</p>
            <p className="color-grey text-truncate">{job.description}</p>
            <div className="row font-13 pb-2">
              <div className="d-flex mb-2 col-xl-6 pr-xl-1">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span>{getFormattedDate(job.closing_date)}</span>
              </div>
              <div className="d-flex mb-2 col-xl-6 order-xl-3 pr-xl-1">
                <i className="fas fa-map-marker-alt color-primary line-height-common mr-2" />
                <span>{job.address}</span>
              </div>
              <div className="d-flex mb-2 col-xl-6 order-xl-2 pl-xl-1">
                <i className="fas fa-pound-sign color-primary line-height-common mr-2" />
                <span>£{job.min_salary} - £{job.max_salary}</span>
              </div>
              <div className="d-flex mb-2 col-xl-6 order-4 pl-xl-1">
                <i className="fas fa-hashtag line-height-common color-primary mr-2" />
                <span>{job.reference}</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap pt-2 border-top ls-border-color action-buttons">
            {!job.can_edit && !job.has_already_applied && (
              <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                      disabled={job.has_already_applied}
                      onClick={() => this.applyJob(id)}>
                Apply Now <i className="fas fa-arrow-right ml-1" />
              </button>
            )}
            {!job.can_edit && job.has_already_applied && (
              <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                      disabled={job.has_already_applied}
                      onClick={() => this.applyJob(id)}>
                Applied <i className="fas fa-arrow-right ml-1" />
              </button>
            )}

            {job.can_edit && (
              <Link to={`/user/job-applicants/${id}`} className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                <i className="fas fa-user-friends mr-1" /> Applicants ({ job.applicants_count ? job.applicants_count : 0 })
              </Link>
            )}
            
            {job.can_edit && (
              <Link to={`/user/job-post/${id}`} className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                <i className="fas fa-pen" />
              </Link>
            )}
            {job.can_edit && job.status !== `cancelled` && (
              <button onClick={() => this.deleteJob(id)}
                      className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                <i className="fas fa-trash-alt" />
              </button>
            )}

            <button className="btn btn-primary lS-btn-icon lS-btn-yellow-style rounded-lg mt-2"
                    onClick={() => this.openShareModal(id)} >
                      <i className="fas fa-share-alt" />
            </button>
         
            {!job.can_edit && job.link_url && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={job.link_url} target="_blank"
                      className="btn btn-primary lS-btn-gold-style mt-3">
                <i className="fas fa-globe" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  checkUncheckbox = (name, event, string) => {
    this.props.checkUnCheckFilters(name, event.target.checked, string);
  };

  applyJobFilters = (values, { setSubmitting }) => {
    const {minSalary, maxSalary} = this.state;
    const params = {
      occ:values.occ,
      minSalary:minSalary,
      maxSalary:maxSalary,
      search: values.search,
      occupations: values.occ,
      duration: values.duration,
      group: values.group,
      location: values.location,
      category: values.category
    };
    
    this.props.applyFilters(params).then(res => {
			setSubmitting(false);
		}, err => {
			setSubmitting(false);
		});
  };

  resetJobs = (resetForm) => {
    resetForm();
    this.props.resetFilters();
  };


  searchJobs = () => {
    const {search, minSalary, maxSalary} = this.state;
    this.props.applyFilters(search, minSalary, maxSalary);
  };

  // resetJobs = () => {
  //   this.setState({
  //     minSalary: '',
  //     maxSalary: '',
  //     search: null,
  //   });
  //   this.props.resetFilters();
  // };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  applyJob = (id) => {
    var params = {
      job_opening_id:id,
    };

    this.props.applyJob(params).then(res => {
      var message = res.message;
      this.props.getJobs()
      showSuccessMsg(message ? message : 'Applied successfully.');
    })
  }
  render() {
    const { jobs, jobsOccupations, isFetchingJobs, totalPages, page } = this.props;
    const { isVisibleShare, jobId, range, search, minSalary, maxSalary } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
        {/* bread crum */}
        <div className="card border-0 page-title-bar">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <h4 className="family-poppins-semibold">Jobs</h4>
                <p className="mb-0 font-14 color-grey"></p>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-lg-end justify-content-center">
                  {isRouteAllow('/job-post') && (
                    <Link to={`/user/job-post`}>
                      <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Job</button>
                    </Link>
                  )}
                  </div>
              </div>
            </div>
          </div>
        </div>
        {/* search bar */}
        <div className="card border-0 mt-md-5 mt-4 lS-card-shadow ls-filters-bar">
          <div className="pt-0 px-2 pb-3 card-body">
            <Formik
                initialValues={this.getFormikInitVal()}
                validationSchema={jobSchema}
                onSubmit={this.applyJobFilters}
              >
              {({ isSubmitting, resetForm }) => (
                <>
                  <Form className="d-flex flex-wrap justify-content-between px-1">
                    <div className="px-2 flex-grow-1 mt-3">
                      <div className="input-group ls-search-field">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent pr-0 font-12 color-grey">
                            <i className="fas fa-search" />
                          </span>
                        </div>
                        <Field type="text" name="search" className="form-control border-left-0" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1" />
                      </div>
                    </div>
                    <div className="px-2 flex-grow-1 mt-3">
                      <Select
                        components={{ IndicatorSeparator:() => null }}
                        className="ls-select-dropdown"
                        isSearchable={true}
                        placeholder="Select Min Salary"
                        onChange={value => {
                          this.setState({
                            minSalary: value.value,
                            maxSalary: '',
                          });
                        }}
                        // name="minSalary"
                        value={minSalary ? formattedArray([minSalary], 'minSalary', this.state) : ''}
                        styles={customStyles}
                        options={formattedArray(minMaxSalary, 'minSalary', this.state)}
                      />
                    </div>
                    <div className="px-2 flex-grow-1 mt-3">
                      <Select
                        components={{ IndicatorSeparator:() => null }}
                        className="ls-select-dropdown"
                        isSearchable={true}
                        placeholder="Select Max Salary"
                        onChange={value => {
                          this.setState({
                            maxSalary: value.value,
                          });
                        }}
                        // name="maxSalary"
                        value={maxSalary ? formattedArray([maxSalary], 'maxSalary', this.state) : ''}
                        styles={customStyles}
                        options={formattedArray(minMaxSalary, 'maxSalary', this.state)}
                      />
                    </div>
                    <div className="px-2 flex-grow-1 mt-3">
                      <Field as="select" name="occ" component={formSelect} options={jobsOccupations} placeholder="Job Occupation" />     
                    </div>
                    <div className="px-2 flex-grow-1 mt-3">
                      <Field as="select" name="type" component={formSelect} options={jobType} placeholder="Job Type" />     
                    </div>
                    <div className="px-2 flex-grow-1 mt-3">
                      <Field as="select" name="location" component={formSelect} options={location} placeholder="Location" />     
                    </div>
                    <div className="d-flex align-items-center mt-3 ml-auto px-2">
                      <button type="submit" className="btn btn-primary mr-3 lS-btn-blue-style" disabled={isSubmitting}>
                        {                     
                          isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}
                        Search
                      </button>
                      <button type="button" onClick={() => this.resetJobs(resetForm)} className="btn btn-secondary lS-btn-red-style" disabled={isSubmitting}>Reset</button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
        {/* Job Listing */}
        <div className="row mt-md-5 mt-4">
          {!isFetchingJobs && jobs && jobs.length > 0 && jobs.map((job, index) => this.getSingleJob(job.attributes, index, job.id))}
          {!isFetchingJobs && jobs && jobs.length === 0 && (
            <div className="col-12">
              <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                No jobs found
              </div>
            </div>
          )}
          {isFetchingJobs && (
            <div className="col-12"><Loader /></div>
          )}
        </div>
        {/* Pagination */}
        <div aria-label="Page navigation example" className="ls-pagination">
          <ReactPaginate
            containerClassName={'pagination justify-content-end mt-5'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            forcePage={page - 1}
            onPageChange={selectedItem => {
              if (isFetchingJobs) return;
              this.loadPage(selectedItem.selected + 1);
            }}
            subContainerClassName={'pages pagination'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </main>
      <UserFooter />
         {/* User Share Popup */}
         {isVisibleShare && (
            <Share
                isVisible={isVisibleShare}
                endpoint={"/user/jobs/"+ jobId}
                onClose={() => {
                    this.setState({
                      isVisibleShare: false,
                    });
                }}
                onCompleted={res => this.onCompleteImageCrop(res)}
            />
            )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Jobs.propTypes = {
  applyFilters: func.isRequired,
  checkUnCheckFilters: func.isRequired,
  getJobs: func.isRequired,
  isFetchingJobs: bool.isRequired,
  jobs: array.isRequired,
  jobsOccupations: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  applyJob: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  jobs: getJobsListing(),
  jobsOccupations: getJobsOccupationsList(),
  isFetchingJobs: getIsFetchingJobs(),
  page: getJobPage(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getJobs,
    getJobsOccupations,
    checkUnCheckFilters,
    applyFilters,
    applyPagination,
    resetFilters,
    applyJob,
    deletePostJob
  }
)(withRouter(Jobs));
