/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_USERS,
  APPLY_FILTERS,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_PAGINATION,
  RESET_FILTERS,
  APPROVE_USERS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Users
 */
export function getUsers(orgId) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, search } = state.get('adminUserListing').toJS();
    const params ={
      page,
      limit,
      filters: {        
      }
    };
    if(orgId !== null && orgId !== '') {
      params.filters.practices_id = [
        orgId
      ] 
    }

    // Apply when search has any value
    if (search) {
      params.filters.search = search;
    }

    // Apply Occupations filtes
    // if (selectedOccupations.length > 0) {
    //   params.filters.occupation = selectedOccupations;
    // }

    // Apply Locations filtes
    // if (selectedLocations.length > 0) {
    //   params.filters.region = selectedLocations;
    // }
    // Apply Types filtes
    // if (selectedTypes.length > 0) {
    //   params.filters.type = selectedTypes;
    // }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getUsersBegin(
          API_URLS.ADMIN.USERS.GET_USERS_LISTING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getUsersBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_USERS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply users filters
 */
export function applyFilters(search, range) {
  return async dispatch => {
    dispatch(applyFiltersBegin(search, range));
    dispatch(getUsers());
  };
};

export const applyFiltersBegin = (search, range) => ({
  type: APPLY_FILTERS,
  search,
  range,
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getUsers());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Reset filters
 */
export function resetFilters() {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getUsers());
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

/**
 *  Apply users filters
 */
export function checkUnCheckFilters(name, checkBoxValue, value) {
  return dispatch => {
    let arrayKeyName;
    switch (name) {
      case 'jobType':
        arrayKeyName = 'selectedTypes';
        break;
      case 'location':
        arrayKeyName = 'selectedLocations';
        break;
      case 'occupation':
        arrayKeyName = 'selectedOccupations';
        break;
      default:
        break;
    };
    checkBoxValue ? dispatch(selectCheckbox(arrayKeyName, value)) : dispatch(unSelectCheckbox(arrayKeyName, value));
    return true;
  };
};

export const selectCheckbox = (arrayKeyName, value) => ({
  type: SELECT_CHECKBOX,
  arrayKeyName,
  value,
});

export const unSelectCheckbox = (arrayKeyName, value) => ({
  type: UNSELECT_CHECKBOX,
  arrayKeyName,
  value,
});

/**
 *  Approve User
 */
export function approveUser(userId) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        approveUserBegin(
          API_URLS.ADMIN.USERS.APPROVE_USERS + userId,
          userId,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const approveUserBegin = (API_URL, userId, source) => ({
  type: APPROVE_USERS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  userId
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
