/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_REPORTS_DATA,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  reports: {},
  isFetchingReports: false,
});

/**
 *  Define the reducer with actions
 */
function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS_DATA:
      return fromJS({
        ...state.toJS(),
        isFetchingReports: true,
      })

    case success(GET_REPORTS_DATA):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        reports: data ? data.attributes : {},
        isFetchingReports: false,
      })

    case failure(GET_REPORTS_DATA):
      return fromJS({
        ...state.toJS(),
        isFetchingReports: false,
      })
      
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default reportsReducer
