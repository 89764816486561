/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_JOBS,
  ADD_JOBS,
  UPDATE_JOBS,
  DELETE_JOBS,
  UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Jobs
 */
export function getJob(id) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('postJobs').toJS();
    const params = {
      page,
      limit
    };

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getJobBegin(
          API_URLS.POST_JOBS.GET_JOBS + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getJobBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_JOBS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});


/**
 *  Add List of Jobs
 */
export function addPostJob(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addPostJobBegin(
          API_URLS.POST_JOBS.ADD_JOBS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addPostJobBegin = (API_URL, params, source) => ({
  type: ADD_JOBS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Delete List of Jobs
 */
export function deletePostJob(JobID) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        deletePostJobBegin(
          API_URLS.POST_JOBS.DELETE_JOBS + JobID,
          JobID,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const deletePostJobBegin = (API_URL, JobID, source) => ({
  type: DELETE_JOBS,
  payload: {
    request: {
      url: API_URL,
      method: 'delete',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  JobID
});

/**
 *  update List of Jobs
 */
export function updatePostJob(params, id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updatePostJobBegin(
          API_URLS.POST_JOBS.UPDATE_JOBS + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updatePostJobBegin = (API_URL, params, source) => ({
  type: UPDATE_JOBS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});

/**
 *  Upload Image
 */
export function uploadImage(formData) {
  return async dispatch => {
    try {
      const response = await dispatch(
        uploadImageBegin(
          API_URLS.IMAGE_UPLOAD,
          formData
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const uploadImageBegin = (API_URL, params) => ({
  type: UPLOAD_IMAGE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  }
});
