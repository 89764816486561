/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_BOOKLETS,
  GET_USERS_LIST,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 100000,
  page: 1,
  bookLets: [],
  users: [],
  isFetchingResources: true,
  isFetchingUsers: true,
});

/**
 *  Define the reducer with actions
 */
function postResourcesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_BOOKLETS:
      return fromJS({
        ...state.toJS(),
        isFetchingResources: true,
      })

    case success(GET_LIST_OF_BOOKLETS):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        bookLets: data && data.length > 0 ? data : [],
        isFetchingResources: false,
      })

    case failure(GET_LIST_OF_BOOKLETS):
      return fromJS({
        ...state.toJS(),
        isFetchingResources: false,
      })

    case GET_USERS_LIST:
      return fromJS({
        ...state.toJS(),
        isFetchingUsers: true,
      })
    
    case success(GET_USERS_LIST):
      return fromJS({
        ...state.toJS(),
        users: action.payload.data.data,
        isFetchingUsers: false,
      })

    case failure(GET_USERS_LIST):
      return fromJS({
        ...state.toJS(),
        isFetchingUsers: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default postResourcesReducer
