export const GET_LIST_OF_PRACTICE =
  'src/modules/updatePersonalInfo/GET_LIST_OF_PRACTICE';
export const GET_PRACTICE_SELECT_DATA =
  'src/modules/updatePersonalInfo/GET_PRACTICE_SELECT_DATA';
export const ADD_EVENTS =
  'src/modules/updatePersonalInfo/ADD_EVENTS';
export const UPDATE_PRACTICE =
  'src/modules/updatePersonalInfo/UPDATE_PRACTICE';
export const UPLOAD_IMAGE =
  'src/modules/updatePersonalInfo/UPLOAD_IMAGE';
export const DELETE_EVENTS =
  'src/modules/updatePersonalInfo/DELETE_EVENTS';
export const RESET_FILTERS =
  'src/modules/updatePersonalInfo/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/updatePersonalInfo/APPLY_PAGINATION';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/updatePersonalInfo/CANCEL_ALL_API_REQUESTS';
