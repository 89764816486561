const learning = [
  'Online learning',
  'Course attendance',
  'Independent learning',
];

const linkCodes = [
  'Prioritise people',
  'Practise effectively',
  'Preserve safety',
  'Promote professionalism and trust',
];

export {
  learning,
  linkCodes,
};
