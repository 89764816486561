import React from "react";
import { func, bool, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Loader from 'components/Loader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { showSuccessMsg } from 'utils/notification';

/**
 *  Import other dependencies
 */
import { 
  addPostNews,
  updatePostNews,
  getNews,
} from '../../modules/newsPost/actions';
import {
  getIsFetchingNews,
  getNewsListing,
} from '../../modules/newsPost/selectors';

const addEditNewsSchema = Yup.object().shape({
  newsTitle: Yup.string()
    .required('Required'),
  content: Yup.string()
    .required('Required'),
});

class NewsPost extends React.Component {
  componentWillMount() {
    const { mode, newsId } = this.props;
    if (mode === 'edit' && newsId) {
      this.props.getNews(newsId).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/news');
        }
      });
    }
  }

  getFormikInitVal = () => {
    
    const { news, mode } = this.props;
    if (mode === 'edit') {
    
    const {
        title,       
        content,        
      } = news.attributes || {};
      return {
        newsTitle: title,
        content,
      }
    }

    return {
      newsTitle: "",
      content: "",
    }
  };

  postNews = (values, { setSubmitting, resetForm }) => {
    const { addPostNews, updatePostNews, mode, newsId, onClose } = this.props;
    var params = {
      title: values.newsTitle,
      content: values.content,
    };

    if (mode === 'edit') {
      updatePostNews(params, newsId).then(res => {
        showSuccessMsg("News successfully Updated");
        setSubmitting(false);
        onClose(mode);
      }, err => {
        setSubmitting(false);
      });
      return;
    }

    if (mode === 'add') {
      addPostNews(params).then(res => {
        showSuccessMsg("News successfully added");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        onClose(mode);
      }, err => {
        setSubmitting(false);
      });
    }
  };

  render() {
    const {
      isFetchingNews,
      isVisible,
      onClose,
      mode
    } = this.props;
    return (
      <>
        <Modal show={isVisible} onHide={() => onClose(mode)} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white">Post News</Modal.Title>
					</Modal.Header>
          {isFetchingNews && (
            <>
              <Loader />
            </>
          )}
          {!isFetchingNews && (
            <Formik
            initialValues={this.getFormikInitVal()}
            validationSchema={addEditNewsSchema}
            onSubmit={this.postNews}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form className="ls-form pt-2">
                  <Modal.Body>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="color-grey pl-3">Title</label>
                            <Field type="text" name="newsTitle" className="form-control" placeholder="News Title" />
                            <ErrorMessage name="newsTitle" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                        </div>                               
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="color-grey pl-3">Content</label>
                            <Field as="textarea" name="content" rows={3} className="form-control" placeholder="Type Here..." />
                            <ErrorMessage name="content" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                        </div>
                      </div>
                  </Modal.Body>
                  <Modal.Footer className="border-0">
                  <div className="row pb-3 w-100">
                    <div className="col-md-6">
                      <button
                        type="button"
                        disabled={isSubmitting}
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button type="submit" disabled={isSubmitting} className="btn btn-primary lS-btn-blue-style w-100">
                        { 
                          isSubmitting && (
                            <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                        )}
                        Save
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        </Modal>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
NewsPost.propTypes = {
  addPostNews: func.isRequired,
  isFetchingNews: bool.isRequired,
  isVisible: bool.isRequired,
  mode: string.isRequired,
  news: object.isRequired,
  newsId: string,
	onClose: func.isRequired,
  updatePostNews: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isFetchingNews: getIsFetchingNews(),
  news: getNewsListing(),
});

export default connect(
  mapStateToProps,
  {
    addPostNews,
    updatePostNews,
    getNews,
  }
)(withRouter(NewsPost));
