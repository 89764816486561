import React from "react";
import { shape } from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import * as Datetime from 'react-datetime';
import UserHeader from 'components/Headers/UserHeader';
import MultiSteps from 'components/multiSteps';
import Loader from 'components/Loader';
import NextPrevBtn from 'components/multiSteps/NextPrevBtn';
import UserFooter from 'components/Footers/UserFooter';
import { showSuccessMsg } from 'utils/notification';

import { steps, questionsStaff } from './config';

/**
 *  Import other dependencies
 */
import { getAudit, saveAudit, setAudit  } from '../../modules/auditTool/actions';
import { getAuditTool, getIsFetchingAuditTool  } from '../../modules/auditTool/selectors';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const step1Schema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  role: Yup.string(),
  organisation: Yup.string(),
  date_completed: Yup.string(),
  approved_by: Yup.string(),
  signature: Yup.string(),
  date_approved: Yup.string(),
});

const dynamicLoopSchema = Yup.object().shape({
  auditQuestions: Yup.array().of(
    Yup.object().shape({
      evidence_list: Yup.string(),
      evidence_standard: Yup.string(),
      description: Yup.string(),
      hee_code: Yup.string()
    })
  )
});

class AuditTool extends React.Component {
  state = {
    currentStep: 0,
  };

  getFormikInitVal = () => {
    const { currentStep } = this.state;
    const { audit } = this.props;
    const { step1, step5, step2, step3, step4 } = audit || {};
    let stepObj = {};

    switch (currentStep) {
      case 0:
        if (step1) {
          return step1;
        }
        stepObj = {
          name: '',
          role: '',
          organisation: '',
          date_completed: '',
          approved_by: '',
          signature: '',
          date_approved: '',
        };
        return stepObj;
      
      case 1:
      case 2:
      case 3:
        if (step2 && currentStep === 1) {
          return step2;
        }
        if (step3 && currentStep === 2) {
          return step3;
        }
        if (step4 && currentStep === 3) {
          return step4;
        }

        const questions = [];
        for (let index = 0; index < 7; index++) {
          const obj = {
            evidence_list: '',
            evidence_standard: 'Yes',
            description: '',
            hee_code: '',
          }
          questions.push(obj);
        }
        stepObj = {
          auditQuestions: questions,
        };
        return stepObj;

      case 4:
        if (step5) {
          return step5;
        }
        stepObj = {
          name: '',
          organisation: '',
          date: '',
          approved_by: '',
          action_required: '',
          resources_required: '',
          person_responsible: '',
          estimated_achievement: '',
        };
      return stepObj;
    
      default:
        return stepObj;
    }
  }

  componentWillMount() {
    this.props.getAudit();
  }

  moveNext = () => {
    let { currentStep } = this.state;
    this.setState({ currentStep: currentStep + 1 });
  };

  movePrev = () => {
    let { currentStep } = this.state;
    this.setState({ currentStep: currentStep - 1 });
  };

  saveData = (tempObj) => {
    const { currentStep } = this.state;
    const params = {
      user_data: tempObj,
    };
    this.props.setAudit(tempObj);
    this.props.saveAudit(params).then(() => {
      showSuccessMsg('Form save successfully');
    });
    if (currentStep !== 4) {
      this.moveNext();
    }
  };

  saveStep = fields => {
    const { audit } = this.props;
    const { currentStep } = this.state;
    const tempObj = {...audit};

    if (tempObj) {
      tempObj[`step${currentStep + 1}`] = fields;
      this.saveData(tempObj);
    }
  };

  saveStepInLoop = fields => {
    const { currentStep } = this.state;
    const { audit } = this.props;
    const tempObj = {...audit};

    if (tempObj) {
      tempObj[`step${currentStep + 1}`] = fields;
      this.saveData(tempObj);
    }
  };

  filterQuestionAsPerStep = (currentStep) => {
    const count = 7 * (currentStep - 1);
    const startIndex = count > 0 ? count - 1 : count;
    var items = questionsStaff.slice(startIndex, 
      startIndex + 7);
    return items;
  };

  renderFormik = (currentStep) => {
    const steps = this.filterQuestionAsPerStep(currentStep);
    return (
      <Formik
        initialValues={this.getFormikInitVal()}
        validationSchema={dynamicLoopSchema}
        onSubmit={this.saveStepInLoop}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="ls-form pt-2 mt-4">
            <div className="row">
            <FieldArray
                name="auditQuestions"
                render={arrayHelpers => (
                  steps.map((question, index) => (
                      <div className="col-md-12" key={index}>
                        <label className="color-grey pl-3 d-block">{question.title}</label>
                        <label className="color-grey pl-3 d-block">{question.subTitle}</label>
                        <div className="row">
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <Field as='textarea' rows={5} className="flex-fill form-control form-control-lg rounded-lg" name={`auditQuestions[${index}].evidence_list`} placeholder="Evidence List" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                            <div className="form-group">
                              <Field as='textarea' rows={5} className="flex-fill form-control form-control-lg rounded-lg" name={`auditQuestions[${index}].description`} placeholder="Describe action required" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <label className="color-grey">Does the evidence meet the standard?</label>
                            <div className="form-group">
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name={`auditQuestions[${index}].evidence_standard`} id={`Yes-${index}`} value="Yes" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor={`Yes-${index}`}>
                                  Yes
                                </label>
                              </div>
                              <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="radio" name={`auditQuestions[${index}].evidence_standard`} id={`No-${index}`} value="No" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor={`No-${index}`}>
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-12">
                            <div className="form-group">
                              <Field as='textarea' rows={5} className="flex-fill form-control form-control-lg rounded-lg" name={`auditQuestions[${index}].hee_code`} placeholder="HEE Quality Standard" />
                            </div>
                          </div>
                        </div>
                        {steps.length !== index + 1 && (
                          <hr className="ls-border-color mb-4"></hr>
                        )}
                      </div>
                    )
                  )
                )}
              />

              <div className="col-md-12 text-md-right text-center">
                <NextPrevBtn
                  isLoading={false}
                  onPrev={this.movePrev}
                  isDisabledNext={false}
                  isDisabledPrev={false}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  };

  render() {
    const { currentStep } = this.state;
    const { isFetchingAudit } = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* Form Page */}
          <div className={`card lS-card-shadow lS-card-radius border-0 `}>
            <div className="card-body">
              {isFetchingAudit && (
                <Loader />
              )}
              {!isFetchingAudit &&  (
                <>
                  <div className="d-flex justify-content-center">
                    <MultiSteps steps={steps} activeStep={currentStep} />
                  </div>
                  
                  {/* Step One data */}
                  {currentStep === 0 && (
                    <Formik
                      initialValues={this.getFormikInitVal()}
                      validationSchema={step1Schema}
                      onSubmit={this.saveStep}
                    >
                      {({ isSubmitting, setFieldValue, values }) => (
                        <Form className="ls-form pt-2 mt-4">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Name</label>
                                <Field type="text" name="name" className="form-control form-control-lg rounded-lg" placeholder="Enter Name" />
                                <ErrorMessage name="name" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Role</label>
                                <Field type="text" name="role" className="form-control form-control-lg rounded-lg" placeholder="Enter Role" />
                                <ErrorMessage name="role" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Organisation</label>
                                <Field type="text" name="organisation" className="form-control form-control-lg rounded-lg" placeholder="Enter Organisation" />
                                <ErrorMessage name="organisation" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Date Completed</label>
                                <Datetime
                                  inputProps={{placeholder: 'Select Date Completed'}}
                                  timeFormat={false}
                                  renderInput={ this.renderCustomInput }
                                  closeOnSelect={true}
                                  isValidDate={ valid }
                                  onChange={date => {
                                    if (typeof date !== "object") return;
                                    setFieldValue('date_completed', date.format("YYYY-MM-DD"))
                                  }}
                                  value={values['date_completed']}
                                />
                                <ErrorMessage name="date_completed" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Approved By</label>
                                <Field type="text" name="approved_by" className="form-control form-control-lg rounded-lg" placeholder="Enter Approved By" />
                                <ErrorMessage name="approved_by" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Signature</label>
                                <Field type="text" name="signature" className="form-control form-control-lg rounded-lg" placeholder="Enter Signature" />
                                <ErrorMessage name="signature" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Date Approved</label>
                                <Datetime
                                  inputProps={{placeholder: 'Select Date Approved'}}
                                  timeFormat={false}
                                  renderInput={ this.renderCustomInput }
                                  closeOnSelect={true}
                                  isValidDate={ valid }
                                  onChange={date => {
                                    if (typeof date !== "object") return;
                                    setFieldValue('date_approved', date.format("YYYY-MM-DD"))
                                  }}
                                  value={values['date_approved']}
                                />
                                <ErrorMessage name="date_approved" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-md-12 text-md-right text-center">
                              <NextPrevBtn
                                isLoading={false}
                                onPrev={this.movePrev}
                                isDisabledNext={false}
                                isDisabledPrev={true}
                              />
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}

                  {/* Step Two data */}
                  {currentStep === 1 && this.renderFormik(currentStep)}

                  {/* Step Three data */}
                  {currentStep === 2 && this.renderFormik(currentStep)}

                  {/* Step Four data */}
                  {currentStep === 3 && this.renderFormik(currentStep)}

                  {/* Step Five data */}
                  {currentStep === 4 && (
                    <Formik
                      initialValues={this.getFormikInitVal()}
                      validationSchema={step1Schema}
                      onSubmit={this.saveStep}
                    >
                      {({ isSubmitting, setFieldValue, values }) => (
                        <Form className="ls-form pt-2 mt-4">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Name</label>
                                <Field type="text" name="name" className="form-control form-control-lg rounded-lg" placeholder="Enter Name" />
                                <ErrorMessage name="name" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Organisation</label>
                                <Field type="text" name="organisation" className="form-control form-control-lg rounded-lg" placeholder="Enter Organisation" />
                                <ErrorMessage name="organisation" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Date</label>
                                <Datetime
                                  inputProps={{placeholder: 'Select Date'}}
                                  timeFormat={false}
                                  renderInput={ this.renderCustomInput }
                                  closeOnSelect={true}
                                  isValidDate={ valid }
                                  onChange={date => {
                                    if (typeof date !== "object") return;
                                    setFieldValue('date', date.format("YYYY-MM-DD"))
                                  }}
                                  value={values['date']}
                                />
                                <ErrorMessage name="date" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Standard Statement</label>
                                <Field type="text" name="approved_by" className="form-control form-control-lg rounded-lg" placeholder="Enter Approved By" />
                                <ErrorMessage name="approved_by" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Action Required</label>
                                <Field type="text" name="action_required" className="form-control form-control-lg rounded-lg" placeholder="Enter Action Required" />
                                <ErrorMessage name="action_required" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Resources Required</label>
                                <Field type="text" name="resources_required" className="form-control form-control-lg rounded-lg" placeholder="Enter Resources Required" />
                                <ErrorMessage name="resources_required" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Person Responsible</label>
                                <Field type="text" name="person_responsible" className="form-control form-control-lg rounded-lg" placeholder="Enter Person Responsible" />
                                <ErrorMessage name="person_responsible" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group mb-5">
                                <label className="color-grey pl-3">Estimated Achievement or Review Date</label>
                                <Field type="text" name="estimated_achievement" className="form-control form-control-lg rounded-lg" placeholder="Enter Estimated Achievement" />
                                <ErrorMessage name="estimated_achievement" component="div" className="error-msg mt-3" />
                              </div>
                            </div>

                            <div className="col-md-12 text-md-right text-center">
                              <NextPrevBtn
                                isLoading={false}
                                isLast={true}
                                onPrev={this.movePrev}
                                isDisabledNext={false}
                                isDisabledPrev={false}
                              />
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              )}
            </div>
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
AuditTool.propTypes = {
  audit: shape({}),
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  audit: getAuditTool(),
  isFetchingAudit: getIsFetchingAuditTool(),
});

export default connect(
  mapStateToProps,
  {
    getAudit,
    saveAudit,
    setAudit,
  }
)(withRouter(AuditTool));
