/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const calender = () => state => state.get('calender');

/**
 *  Select the correct portion of the root reducer
 */
export const getCalenderListing = () =>
  createSelector(calender(), state => {
    const listing = state.get('calender').toJS();
    return listing.length > 0 ? listing : []
  });

  /**
 *  Select is fetching news
 */
export const getIsFetchingCalender = () =>  
  createSelector(calender(), state => {
    return state.get('isFetchingCalender')
  })