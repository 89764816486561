/**
 *  Import action creator constants & dependencies
 */
import {
  GET_AUDIT,
  SAVE_AUDIT,
  SET_AUDIT,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of audit
 */
export function getAudit() {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getAuditBegin(
          API_URLS.AUDIT.GET_AUDIT,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getAuditBegin = (API_URL, source) => ({
  type:   GET_AUDIT,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Add audit
 */
export function saveAudit(params) {
	return async dispatch => {
	  const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
	  try {
      const response = await dispatch(
        saveAuditBegin(
          API_URLS.AUDIT.POST_AUDIT,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }
    
      throw response;
	  } catch (error) {
		  throw error.response;
	  }
	};
};

export const saveAuditBegin = (API_URL, params, source) => ({
  type: SAVE_AUDIT,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

export const setAudit = (audit) => ({
  type: SET_AUDIT,
  audit,
});


export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


