/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const addUser = () => state => state.get('addUser');

/**
 *  Select the correct portion of the root reducer
 */
export const getOrganisationListing = () =>
  createSelector(addUser(), state => {
    const listing = state.get('organisation').toJS()
    return listing ? listing : []
  });

/**
 *  Select is fetching trainings
 */
export const getIsFetchingOrganisation = () =>
  createSelector(addUser(), state => {
    return state.get('isFetchingOrganisation')
  })
