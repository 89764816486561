const selfAssessment = [
    'Novice',
    'Advanced beginner',
    'Competent',
    'Proficient',
    'Expert',
];

const evidence = [
    'Direct demonstration',
    'Professional Discussion',
    'Presentation/case study',
    'Feedback',
    'Other',
];
export {
    selfAssessment,
    evidence
}