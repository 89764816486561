import React from "react";
import { func, array, bool, number} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import ReactPaginate from "react-paginate";
import AssignAssorSuper from "components/assignAssorSuper";
/**
 *  Import other dependencies
 */
import { getSubmittedBooklets, getBooklets , applyPagination, applyFilters, applyFiltersSubmittedBooklets, getUsersListing  } from '../../../modules/resources/actions';
import { getSingleBookLetsListing, getBookLetsListing, getIsFetchingResources, getTotalPages, getResourcesPage  } from '../../../modules/resources/selectors';


class Resources extends React.Component {
  state={
    search: '',
    examinerType: 'supervisor',
    isVisibleAssignAssor: false,
  }

  componentWillMount() {
    const id  = this.props.match.params.id;
    this.props.getSubmittedBooklets('supervisor', id)
    // this.props.getUsersListing();
  }

   handleVisibleAssign = bookletIndex => {
    this.setState({
      isVisibleAssignAssor: true,
      // bookLetIndexID: bookletIndex,
      bookLetID: bookletIndex,
    });
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  searchBooklets = () => {
    const {search} = this.state;
    this.props.applyFiltersSubmittedBooklets(search, 'supervisor', this.props.match.params.id);
  };

  loadPage = page => {
    this.props.applyPagination(page, this.props.match.params.categoryId);
  }

  render() {
    const { bookLets, isFetchingBooklets, totalPages, page } = this.props;
    const { search, isVisibleAssignAssor, bookLetID } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main>
          <div className="container-fluid">
          <div className="row box share_box">
            <div className="col-md-12">
              <h4>Search</h4>
            </div>
            <div className="col-md-12 com_search">
              {/* <form className="com_search" method="post"> */}
                <input type="text" name="com_field_search" className="form-group"
                      onChange={event => this.handleQueryChange(event.target.value) }
                      value={search} />
                <button type="submit" onClick={this.searchBooklets}
                        className="com_searhc_btn btn btn-default"
                  defaultValue="Search" >
                    Search </button>
              {/* </form> */}
            </div>
          </div>

            <div className="row resources">
              {!isFetchingBooklets && bookLets && bookLets.length > 0 && bookLets.map(book => (
                // <div className="text-box" key={book.id}>
                //   <Link to={`/user/resources/${book.id}`}>{book.attributes.booklet_name}</Link>
                // </div>
                <div className="col-md-6 box">
                  <div className="row share_op">
                    <div className="col-md-2"><img alt="BookletImage" src={require("assets/images/pencil-board.jpeg")} /></div>
                    <div className="col-md-4">
                      <p>{book.attributes.booklet_name}</p>
                    </div>
                    <div className="col-md-6">
                    <Link to={`/user/supervisor/user-listing/${book.attributes.user_id}/booklet/${book.id}`}>
                      <button className="a_btn_colour">
                      View
                      </button>
                    </Link>
                      {/* <button disabled className="a_btn_colour ml-1">Download</button> */}
                      {/* <button 
                        disabled= { book.attributes.is_submitted ? true :
                                 ( book.attributes.is_completed ? false : true)  }
                        onClick={() => this.handleVisibleAssign(book.id)}
                        className="a_btn_colour ml-1">
                          {book.attributes.is_submitted ? `Assigned`: 'Assign' }
                       
                      </button> */}
                      
                      
                      {/* {book.attributes.is_completed && (
                        <button class="green_check">
                            <i class="far fa-check-circle"></i>
                          </button>
                      )} */}
                    </div>
                  </div>
                </div>
              ))}
              {!isFetchingBooklets && bookLets && bookLets.length === 0 && (
                <div className="text-center">No bookLets found</div>
              )}
              <div className="w-100 d-flex justify-content-center">
                {isFetchingBooklets && (
                  <div className="text-center">
                    <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
              <div aria-label="Page navigation example">
                  <ReactPaginate
                    containerClassName={'pagination justify-content-end mt-5'}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    forcePage={page - 1}
                    onPageChange={selectedItem => {
                      if (isFetchingBooklets) return;
                      this.loadPage(selectedItem.selected + 1);
                    }}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
            </div>
          </div>
        </main>
        {isVisibleAssignAssor && (
          <AssignAssorSuper
            isVisible={isVisibleAssignAssor}
            onClose={() => {
              this.setState({
                isVisibleAssignAssor: false
              });
            }}
            bookLetID={Number(bookLetID)}
            // bookLetIndexID={Number(bookLetIndexID)}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Resources.propTypes = {
  applyFilters: func.isRequired,
  applyFiltersSubmittedBooklets: func.isRequired,
  applyPagination: func.isRequired,
  bookLets: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  getBooklets: func.isRequired,
  getSubmittedBooklets: func.isRequired,
  isFetchingBooklets: bool.isRequired,
    getUsersListing: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  bookLets: getSingleBookLetsListing(),
  isFetchingBooklets: getIsFetchingResources(),
  page: getResourcesPage(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getBooklets,
    getSubmittedBooklets,
    applyPagination,
    applyFilters,
    applyFiltersSubmittedBooklets,
    getUsersListing
  }
)(withRouter(Resources));
