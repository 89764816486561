export const GET_LIST_OF_PLACEMENTS =
  'src/modules/placements/GET_LIST_OF_PLACEMENTS';
export const GET_SINGLE_PLACEMENT =
  'src/modules/placements/GET_SINGLE_PLACEMENT';
export const GET_LIST_OF_PLACEMENT_OCCUPATIONS =
  'src/modules/placements/GET_LIST_OF_PLACEMENT_OCCUPATIONS';
export const APPLY_FILTERS =
  'src/modules/placements/APPLY_FILTERS';
export const RESET_FILTERS =
  'src/modules/placements/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/placements/APPLY_PAGINATION';
export const SELECT_CHECKBOX =
  'src/modules/placements/SELECT_CHECKBOX';
export const UNSELECT_CHECKBOX =
  'src/modules/placements/UNSELECT_CHECKBOX';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/placements/CANCEL_ALL_API_REQUESTS';
export const APPLY_PLACEMENTS =
  'src/modules/placements/APPLY_PLACEMENTS';
export const UPDATE_PLACEMENT_STATUS =
'src/modules/placements/UPDATE_PLACEMENT_STATUS';
export const GET_LIST_OF_PLACEMENTS_APPLICANTS =
'src/modules/placements/GET_LIST_OF_PLACEMENTS_APPLICANTS';
export const UPDATE_PLACEMENT_APPLICANT_STATUS =
'src/modules/placements/UPDATE_PLACEMENT_APPLICANT_STATUS';
export const MENTOR_PLACEMENTS =
  'src/modules/placements/MENTOR_PLACEMENTS';
export const GET_LIST_OF_PLACEMENTS_MENTORS =
'src/modules/placements/GET_LIST_OF_PLACEMENTS_MENTORS';
export const LIKE_DISLIKE_PLACEMENT =
'src/modules/placements/LIKE_DISLIKE_PLACEMENT';
export const UPDATE_PLACEMENT_MENTOR_STATUS =
'src/modules/placements/UPDATE_PLACEMENT_MENTOR_STATUS';
