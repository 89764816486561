/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import { 
  UPDATE_PROFILE,  
  GET_PRACTICE,
  GET_INSTITUTION_NAME,
  GET_EMPLOYMENT_LIST,
  GET_EDUCATION_LIST,
  UPLOAD_IMAGE,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 1000,
  page:1,
  isPractice: [],
  isInstitutionName: [],
  isEmploymentList: [],
  isEducationList: [],
  isUpdatingProfile: false,
  isFetchingPraactices: false,
  isFetchingInsitutionName: false,
  isFetchingEmployment: false,
  isFetchingEducation: false,
  isUploadingImage: false,
});

/**
 *  Define the reducer with actions
 */
function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROFILE:
      return fromJS({
        ...state.toJS(),
        isUpdatingProfile: true,
      })

    case success(UPDATE_PROFILE):
    case failure(UPDATE_PROFILE):
      return fromJS({
        ...state.toJS(),
        isUpdatingProfile: false,
      })
    
    case GET_PRACTICE:
      return fromJS({
        ...state.toJS(),
        isPractice: [],
        isFetchingPraactices: true,
      })

    case success(GET_PRACTICE):
      const actionData = action.payload.data.data
      const finialResult = []
      actionData.map(indexes =>{
        let data = {
          id: indexes.id,
          name: indexes.attributes.name
        }
        finialResult.push(data)
        return finialResult
      })
      return fromJS({
        ...state.toJS(),
        isPractice: finialResult && finialResult.length > 0 ? finialResult : [],
        isFetchingPraactices: false,
      })
    case failure(GET_PRACTICE):
      return fromJS({
        ...state.toJS(),
        isPractice: [],
        isFetchingPraactices: false,
      })
    
    case GET_INSTITUTION_NAME:
      return fromJS({
        ...state.toJS(),
        isInstitutionName: [],
        isFetchingInsitutionName: true,
      })

    case success(GET_INSTITUTION_NAME):
      const instituteNameList = action.payload.data.data
      const instituteFinialResult = []
      instituteNameList.map(indexes =>{
        let data = {
          id: indexes.id,
          name: indexes.attributes.name
        }
        instituteFinialResult.push(data)
        return instituteFinialResult
      })
      return fromJS({
        ...state.toJS(),
        isInstitutionName: instituteFinialResult && instituteFinialResult.length > 0 ? instituteFinialResult : [],
        isFetchingInsitutionName: false,
      })
    case failure(GET_INSTITUTION_NAME):
      return fromJS({
        ...state.toJS(),
        isInstitutionName: [],
        isFetchingInsitutionName: false,
      })
    
    case GET_EMPLOYMENT_LIST:
      return fromJS({
        ...state.toJS(),
        isEmploymentList: [],
        isFetchingEmployment: true,
      })

    case success(GET_EMPLOYMENT_LIST):
      return fromJS({
        ...state.toJS(),
        isEmploymentList: action.payload.data.data && action.payload.data.data.length > 0 ? action.payload.data.data : [],
        isFetchingEmployment: false,
      })
    case failure(GET_EMPLOYMENT_LIST):
      return fromJS({
        ...state.toJS(),
        isEmploymentList: [],
        isFetchingEmployment: false,
      })
    
    case GET_EDUCATION_LIST:
      return fromJS({
        ...state.toJS(),
        isEducationList: [],
        isFetchingEducation: true,
      })

    case success(GET_EDUCATION_LIST):
      return fromJS({
        ...state.toJS(),
        isEducationList: action.payload.data.data && action.payload.data.data.length > 0 ? action.payload.data.data : [],
        isFetchingEducation: false,
      })
    case failure(GET_EDUCATION_LIST):
      return fromJS({
        ...state.toJS(),
        isEducationList: [],
        isFetchingEducation: false,
      })

    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })

    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default userProfileReducer
