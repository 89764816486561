/**
 *  Import action creator constants & dependencies
 */
import {
  LOGIN_USER,
  REGISTER_USER,
  FORGOT_USER,
  RESET_PASSWORD_USER,
  LIST_EDUCATION_INSTITUTE,
  LIST_PRACTICES,
  VERIFY_EMAIL,
  CANCEL_ALL_API_REQUESTS,
  PUBLIC_PASSPORT,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Login Users
 */
export function login(email, password) {
  return async (dispatch, getState) => {
    var params = {
      email,
      password,
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        loginBegin(
          API_URLS.AUTH.LOGIN,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const loginBegin = (API_URL, params, source) => ({
  type: LOGIN_USER,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Forgot Users
 */
export function forgot(email) {
  return async (dispatch) => {
    var params = {
      email,
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        forgotBegin(
          API_URLS.AUTH.FORGOT,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const forgotBegin = (API_URL, params, source) => ({
  type: FORGOT_USER,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Reset Users
 */
export function reset(password,password_confirmation, token) {
  return async (dispatch) => {
    var params = {
      password,
      password_confirmation
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        resetBegin(
          API_URLS.AUTH.RESET_PASSWORD + token,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const resetBegin = (API_URL, params, source) => ({
  type: RESET_PASSWORD_USER,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Education Institutes
 */
export function getEducationInstitute() {
  return async (dispatch, getState) => {
    var params = {
      limit: 10000
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getEducationInstituteBegin(
          API_URLS.AUTH.LIST_EDUCATION_INSTITUTE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getEducationInstituteBegin = (API_URL, params, source) => ({
  type: LIST_EDUCATION_INSTITUTE,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of practices
 */
export function getPractices() {
	return async (dispatch, getState) => {
	  var params = {
      filters: {
        type: 'practice',
      },
		  limit: 10000
	  };
	  const source = axios.CancelToken.source();
	  cancelApiRequests.push(source);
	  try {
		const response = await dispatch(
		  getPracticesBegin(
			API_URLS.AUTH.LIST_PRACTICES,
			params,
			source
		  )
		);
		if (response.payload) {
		  const { data } = response.payload;
		  return data;
		}
  
		throw response;
	  } catch (error) {
		throw error.response;
	  }
	};
  };
  
  export const getPracticesBegin = (API_URL, params, source) => ({
	type: LIST_PRACTICES,
	payload: {
	  request: {
		url: API_URL,
		method: 'get',
		params: params,
		paramsSerializer: function(params) {
		  return qs.stringify(params, { arrayFormat: 'brackets' })
		},
		cancelToken: source.token
	  }
	}
  });

/**
 *  Signup/Register Users
 */
export function registerUser(formData, type) {
  return async (dispatch, getState) => {
    let params = null;
    const { firstName, lastName, email, password, confirmPassword } = formData;

    params = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: confirmPassword,
      type,
    };

    if(type === 'staff') {
      const { practices, jobTitle } = formData;
      params.org_id = practices;
      params.job_title = jobTitle;
    } else {
      const { student, place } = formData;
      params.student_identifier = student;
      params.education_inst_id = place;
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        registerUserBegin(
          API_URLS.AUTH.SIGNUP,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const registerUserBegin = (API_URL, params, source) => ({
  type: REGISTER_USER,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Verify Email
 */
export function verifyEmail(token) {
  return async (dispatch, getState) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        verifyEmailBegin(
          API_URLS.AUTH.VERIFY_EMAIL + token,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const verifyEmailBegin = (API_URL, source) => ({
  type: VERIFY_EMAIL,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get Public Profile Passport
 */
export function getPublicPassport(token) {
  return async (dispatch, getState) => {
    const params = {
      include: ['user_employments', 'user_educations']
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getPublicPassportBegin(
          API_URLS.AUTH.PUBLIC_PASSPORT + token,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const getPublicPassportBegin = (API_URL, params, source) => ({
  type: PUBLIC_PASSPORT,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
