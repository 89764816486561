/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const jobs = () => state => state.get('jobs');

/**
 *  Select the correct portion of the root reducer
 */
export const getJobsListing = () =>
  createSelector(jobs(), state => {
    const listing = state.get('jobs').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getJobApplicantsListing = () =>
createSelector(jobs(), state => {
  const listing = state.get('jobApplicants').toJS()
  console.log(listing);
  return listing.length > 0 ? listing : []
});

/**
 *  Select Single job page object
 */
export const getSingleJobDetail = () =>
  createSelector(jobs(), state => {
    const singleJob = state.get('singleJob')
    return singleJob && Object.keys(singleJob).length > 0 ? singleJob.toJS() : {}
  });

/**
 *  Select the job Occupations list
 */
export const getJobsOccupationsList = () =>
  createSelector(jobs(), state => {
    const listing = state.get('jobOccupations').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching job applicants Name
*/
export const getIsFetchingJobApplicants = () =>
createSelector(jobs(), state => {
  return state.get('isFetchingJobApplicants')
})

/**
 *  Select is fetching jobs
 */
export const getIsFetchingJobs = () =>
  createSelector(jobs(), state => {
    return state.get('isFetchingJobs')
  })

/**
 *  Select is fetching jobs
 */
export const getIsFetchingSingleJob = () =>
  createSelector(jobs(), state => {
    return state.get('isFetchingSingleJob')
  })

/**
 *  Select total number of jobs pages
 */
export const getTotalPages = () =>
  createSelector(jobs(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current job pages
 */
export const getJobPage = () =>
  createSelector(jobs(), state => {
    return state.get('page')
  })
