import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import routes from "routes.js";

class PublicLayout extends React.Component {
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        return (
          <Route
            exact
            strict
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
      </>
    );
  }
}

export default withRouter(PublicLayout);
