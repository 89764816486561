/**
 *  Import action creator constants & dependencies
 */
import {
  UPLOAD_IMAGE,
  SET_LOADER
} from './constants';
import { API_URLS } from '../../configs/urls';

/**
 *  Upload Image
 */
export function uploadImage(formData) {
  return async dispatch => {
    try {
      dispatch(setLoader(true));
      const response = await dispatch(
        uploadImageBegin(
          API_URLS.IMAGE_UPLOAD,
          formData
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const uploadImageBegin = (API_URL, params) => ({
  type: UPLOAD_IMAGE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  }
});

export const setLoader = value => ({
  type: SET_LOADER,
  value
});
