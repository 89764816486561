const trainingLevels = [
	'Level 1',
	'Level 2',
	'Level 3',
	'Level 4',
	'Level 5',
	'Level 6',
	'Level 7',
	'Level 8',
	'Level 9',
  ];

  const trainingType = [
	'Foundation',
	'Undergraduate',
	'Postgraduate',
	'Essential',
	'Study day',
	'Master Class',
	'Update',
	'Mandatory',
  ];


  const university = [
	'Bucks New University',
	'City University',
	'King\'s College',	
	'London South Bank University',	
	'University of West London',
	// 'University of East London',
	// 'Hertfordshire University',
	// 'Kingston university',
	// 'Greenwich University',
	// 'Middlesex University',
	// 'Roehampton University',
  ];

  export {
	trainingLevels,
	trainingType,
	university
  };
