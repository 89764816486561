import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import * as Yup from 'yup';
import { showSuccessMsg } from 'utils/notification';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Loader from 'components/Loader';
import UserFooter from 'components/Footers/UserFooter';

import { getReflectiveAccount, publishReflectiveAccount  } from '../../../modules/revalidation/reflectiveAccount/actions';
import { getReflectiveAccountDetail, getIsFetchingReflectiveAccount  } from '../../../modules/revalidation/reflectiveAccount/selectors';

const addEditReflectiveAccount = Yup.object().shape({
  themes: Yup.string()
    .required('Required'),
  natureOfCPD: Yup.string()
    .required('Required'),
  learnFromCPD: Yup.string()
   .required('Required'),
  reflectiveAccount: Yup.string()
   .required('Required'),
  changeOrImprovement: Yup.string()
   .required('Required'),
});

class RefAccForm extends React.Component {
  componentWillMount() {
   this.props.getReflectiveAccount()
  }

  getFormikInitVal = () => {
  
  const { reflectiveAccount } = this.props;
  
  const {
    themes,
    nature_of_cpd,
    learn_from_cpd,
    reflective_account,
    change_or_improvements,
  } = reflectiveAccount || {};

  return {
    themes: themes,
    natureOfCPD: nature_of_cpd,
    learnFromCPD: learn_from_cpd,
    reflectiveAccount: reflective_account,
    changeOrImprovement: change_or_improvements,
  }
  };

  postNews = (values, { setSubmitting, resetForm }) => {
  const { publishReflectiveAccount, history } = this.props;
  // const { mode } = this.state;
  var data = {
    themes: values.themes,
    nature_of_cpd: values.natureOfCPD,
    learn_from_cpd: values.learnFromCPD,
    reflective_account: values.reflectiveAccount,
    change_or_improvements: values.changeOrImprovement,
  };

  var params = {
    account_data:data,
  };

  
  publishReflectiveAccount(params).then(res => {
    showSuccessMsg("Revalidation saved.");
    resetForm(this.getFormikInitVal);
    setSubmitting(false);
    if (history) {
    history.push('/user/revalidation/ref-acc-form');
    }
  }, err => {
    setSubmitting(false);
  });
  
  };

  render() {
  const { reflectiveAccount, isFetchingReflectiveAccount } = this.props;

  const {themes,
    nature_of_cpd,
    learn_from_cpd,
    reflective_account,
    change_or_improvements}  = reflectiveAccount;
  return (
    <>
    <UserHeader />
    {/* Page content */}
    <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
      {/* bread crum */}
      <div className="card border-0 page-title-bar">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <h4 className="family-poppins-semibold mt-2">Reflective account Form</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow-sm border-0 rounded-lg mt-5">
        <div className="card-body">
          {isFetchingReflectiveAccount && (
            <Loader />
          )}
          {!isFetchingReflectiveAccount && (
            <Formik
              initialValues={{
              themes: themes, 
              natureOfCPD: nature_of_cpd, 
              learnFromCPD: learn_from_cpd,
              reflectiveAccount: reflective_account, 
              changeOrImprovement: change_or_improvements}}
              validationSchema={addEditReflectiveAccount}
              onSubmit={this.postNews}
            >
            
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="ls-form">
                <p className="mb-4 mt-3">
                  You must use this form to record five written reflective accounts on your CPD and/or practice-related feedback and/or an event or experience in your practice and how this relates to the Code. Please fill in a page for each of your reflective accounts, making sure you do not include any information that might identify a specific patient, service user, colleague or other individuals. Please refer to our guidance on preserving anonymity in the section on non-identifiable information in How to revalidate with the NMC.
                </p>
                <div className="form-group mb-5">
                  <label htmlFor="title" className="color-grey pl-3">Reflective account</label>
                  <Field type="text" name="reflectiveAccount" className="form-control" placeholder="Reflective Account" />
                  <ErrorMessage name="reflectiveAccount" component="div" className="error-msg" />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="title" className="color-grey pl-3">What was the nature of the CPD activity and/or practice-related feedback and/or event or experience in your practice?</label>
                  <Field component="textarea" rows="3" name="natureOfCPD" className="form-control" placeholder="Type Here..." />
                  <ErrorMessage name="natureOfCPD" component="div" className="error-msg" />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="title" className="color-grey pl-3">What did you learn from the CPD activity and/or feedback and/or event or experience in your practice? </label>
                  <Field component="textarea" rows="3" name="learnFromCPD" className="form-control" placeholder="Type Here..." />
                  <ErrorMessage name="learnFromCPD" component="div" className="error-msg" />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="title" className="color-grey pl-3">How did you change or improve your practice as a result? </label>
                  <Field component="textarea" rows="3" name="changeOrImprovement" className="form-control" placeholder="Type Here..." />
                  <ErrorMessage name="changeOrImprovement" component="div" className="error-msg" />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="title" className="color-grey pl-3">How is this relevant to the Code?<span>Select one or more themes: Prioritise people – Practise effectively – Preserve safety – Promote professionalism and trust</span></label>
                  <Field component="textarea" rows="3" name="themes" className="form-control" placeholder="Type Here..." />
                  <ErrorMessage name="themes" component="div" className="error-msg" />
                </div>
                <div className="text-right">
                  <button type="submit"
                    disabled={isSubmitting}
                    className="btn lS-btn-blue-style lS-btn-blue-large mx mb-3"
                    // onClick={() => this.publishReflectiveAccount()}
                  >
                    { 
                      isSubmitting && (
                      <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                    )}
                    Save
                  </button>
                </div>
                <div className="new_row">
                </div>
              </Form>  
            )}
            </Formik>
          )}
        </div>
      </div>
    </main>
    <UserFooter/>
    </>
  );
  }
}

/**
 *  Define component PropTypes
 */
RefAccForm.propTypes = {
  reflectiveAccount: array.isRequired,
  getReflectiveAccount: func.isRequired,
  isFetchingReflectiveAccount: bool.isRequired,
  publishReflectiveAccount:func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  reflectiveAccount: getReflectiveAccountDetail(),
  isFetchingReflectiveAccount: getIsFetchingReflectiveAccount(),
});

export default connect(
  mapStateToProps,
  {
  getReflectiveAccount,
  publishReflectiveAccount
  }
)(withRouter(RefAccForm));
