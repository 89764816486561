import React from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { trainingLevels, jobType } from './postsFilters';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';

/**
 *  Import other dependencies
 */
import {
  uploadImage,
  addPostTraining,
  updatePostTraining,
  getTrainings
} from '../../../modules/policyDocPost/actions';
import {
  getIsUploadingImage,
  getIsFetchingTrainings,
  getTrainingsListing
} from '../../../modules/policyDocPost/selectors';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const addEditJobSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  orgName: Yup.string()
    .nullable(),
  // level: Yup.string()
  //   .required('Required'),
  // type: Yup.string()
  //   .required('Required'),
  // description: Yup.string()
  //   .required('Required'),
  // location: Yup.string()
  //   .required('Required'),
  // date: Yup.string()
  //   .required('Required'),
  // end_date: Yup.string()
  //   .required('Required'),
  docURL: Yup.string()
    .nullable()
    .url("Please add a valid url."),
  linkURL: Yup.string()
    .nullable()
    .url("Please add a valid url."),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

// const formSelect = props => {
//   const { form, options, placeholder, field } = props;
//   if (options.length === 0) return;
//   return (
//     <Select
//       components={{ IndicatorSeparator:() => null }}
//       className="form-control p-0"
//       isSearchable={true}
//       placeholder={placeholder}
//       onChange={value => {
//         field.name === 'type' ?
//           form.setFieldValue('type', value.value) :
//           form.setFieldValue('level', value.value)
//       }}
//       value={field.value ? formattedArray([field.value]) : ''}
//       styles={customStyles}
//       options={formattedArray(options)}
//     />
//   )
// };

class PolicyDocPost extends React.Component {
  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getTrainings(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/trainings');
        }
      });
    }
  }

  getFormikInitVal = () => {
    const { training } = this.props;
    const { mode } = this.state;
    if (mode === 'edit') {
      const {
        title,
        doc_url,
        link_url,
        last_updated,
        org_name,
      } = training.attributes || {};

      return {
        title,        
        docURL: doc_url,
        linkURL: link_url,
        lastUpdated: last_updated ?  moment(last_updated): '',
        orgName:org_name,
      }
    }
    return {
      title: "",
      docURL: "",
      linkURL: "",
      lastUpdated:'',
      orgName:'',
    }
  };

  handleJobImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("file", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('docURL', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>  
          <div>
            <label className="color-grey pl-3">Image Preview</label>
          </div>
          <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('docURL', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <label htmlFor="guideline-upload" className="color-grey pl-3">Upload File</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="guideline-upload" onChange={event => this.handleJobImage(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="guideline-upload">Choose File</label>
				</div>
      </>
    )
  };

  postJob = (values, { setSubmitting, resetForm }) => {
    const { addPostTraining, updatePostTraining, history } = this.props;
    const { mode } = this.state;
    var params = {
      title: values.title,
      link_url: values.linkURL,
      org_name: values.orgName,
      last_updated: values.lastUpdated ? moment(values.lastUpdated).format("YYYY-MM-DD HH:mm:ss") : null,
    };

    if (values.docURL) {
      params.doc_url = values.docURL;
    }

    if( mode === 'edit' ){
      updatePostTraining(params, this.props.match.params.id).then(res => {
        showSuccessMsg("Guideline updated successfully.");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/guidelines');
        }
      }, err => {
        setSubmitting(false);
      });
    }

    if( mode === 'add' ){
      addPostTraining(params).then(res => {
        showSuccessMsg("Guidelines added successfully.");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/guidelines');
        }
      }, err => {
        setSubmitting(false);
      });
    }
  };

  render() {
    const {
      isUploadingImage,
      isFetchingTraining
    } = this.props;
    const { mode } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 pb-5 mt-md-5 mt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="family-poppins-semibold">{mode === 'edit' ? 'Edit' : 'Post'} Guidelines </h4>
                    <p className="mb-0 font-14 color-grey"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded-lg">
              {isFetchingTraining && (
                <Loader />
              )}              
              <div className="card-body">
              {!isFetchingTraining && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditJobSchema}
                  onSubmit={this.postJob}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="ls-form pt-2">
                
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="title" className="color-grey pl-3">Title</label>
                        <Field type="text" name="title" className="form-control form-control-lg rounded-lg" id="title" placeholder="Enter title" />
                        <ErrorMessage name="title" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="organisation" className="color-grey pl-3">Organisation</label>
                        <Field type="text" name="orgName" className="form-control form-control-lg rounded-lg" id="organisation" placeholder="Enter Organisation" />
                        <ErrorMessage name="orgName" component="div" className="error-msg" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group mb-5">
                        <label htmlFor="start-date" className="color-grey pl-3">Last Modified</label>
                        <Datetime
                          inputProps={{placeholder: 'Select Last Modified'}}
                          renderInput={ this.renderCustomInput }
                          closeOnSelect={true}
                          isValidDate={ valid }
                          onChange={lastUpdated => {
                            if (typeof lastUpdated !== "object") return;
                            setFieldValue('lastUpdated', lastUpdated)
                          }}
                          value={values['lastUpdated']}
                        />
                        <ErrorMessage name="lastUpdated" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                      {/* <label htmlFor="file" className="color-grey pl-3">Upload File</label> */}
                        {!isUploadingImage && (
                          // <div className="custom-file">
                          //   <Field type="file" component={this.fileUpload} name="docURL" className="custom-file-input" id="file" />
                          //   {/* <label className="custom-file-label" htmlFor="file">Choose File</label> */}
                          // </div>

                          <Field type="file" 
                            component={this.fileUpload}
                            name="docURL"
                            className="custom-file-input" id="file" />
                        )}
                        {isUploadingImage && (
                          <Loader />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="link-url" className="color-grey pl-3">Link URL</label>
                        <Field type="text"  name="linkURL" className="form-control form-control-lg rounded-lg" id="link-url" placeholder="Enter URL" />
                        <ErrorMessage name="linkURL" component="div" className="error-msg" />
                      </div>
                    </div>
                  </div>                  
                  <div className="text-md-right text-center">
                    <Link to="/user/guidelines" className="ls-btn-link btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</Link>
                    <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                      {
                        isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Submit
                    </button>
                  </div>
                 </Form>
                 )}
               </Formik>
               )}
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>        
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PolicyDocPost.propTypes = {
  addPostTraining: func.isRequired,
  isFetchingTraining: bool.isRequired,
  isUploadingImage: bool.isRequired,
  training: object.isRequired,
  updatePostTraining: func.isRequired,
  uploadImage: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
  isFetchingTraining: getIsFetchingTrainings(),
  training: getTrainingsListing()
});

export default connect(
  mapStateToProps,
  {
    uploadImage,
    addPostTraining,
    updatePostTraining,
    getTrainings,
  }
)(withRouter(PolicyDocPost));
