/**
 *  Import action creator constants & dependencies
 */
import {
    GET_LIST_OF_CATEGORIES,    
    // APPLY_PAGINATION,    
    CANCEL_ALL_API_REQUESTS,   
  } from './constants';
  import { API_URLS } from '../../configs/urls';
  import axios from 'axios';
  import qs from 'qs/lib/index';
  const cancelApiRequests = [];
  
  /**
   *  Get List of feedback
   */
  export function getBookletCategories() {
    return async (dispatch) => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          getBookletCategoriesBegin(
            API_URLS.COMPETENCIES.GET_CATEGORIES,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const getBookletCategoriesBegin = (API_URL, source) => ({
    type: GET_LIST_OF_CATEGORIES,
    payload: {
      request: {
        url: API_URL,
        method: 'get',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

   /**
   *  Apply pagination
   */
  // export function applyPagination(page) {
  //   return async dispatch => {
  //     dispatch(applyPaginationBegin(page));
  //     dispatch(getPlacements());
  //   };
  // };
  
  // export const applyPaginationBegin = page => ({
  //   type: APPLY_PAGINATION,
  //   page
  // });
  
 export function cancelAllApiRequests() {
    return dispatch => {
      cancelApiRequests.forEach(apiRequest => {
        apiRequest.cancel()
      })
      dispatch(cancelAllApiRequestsBegin())
    }
  };
  
  export const cancelAllApiRequestsBegin = () => ({
    type: CANCEL_ALL_API_REQUESTS
  });
  