/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_TRAININGS,
  UPLOAD_IMAGE,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  trainings: {},
  isFetchingTrainings: false,
  isUploadingImage: false,
  isDeletingTraining: false,
});

/**
 *  Define the reducer with actions
 */
function postTrainingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_TRAININGS:
      return fromJS({
        ...state.toJS(),
        isFetchingTrainings: true,
      })

    case success(GET_LIST_OF_TRAININGS):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        trainings: data ? data : {},
        isFetchingTrainings: false,
      })

    case failure(GET_LIST_OF_TRAININGS):
      return fromJS({
        ...state.toJS(),
        isFetchingTrainings: false,
      })
    case UPLOAD_IMAGE:
      return fromJS({
        ...state.toJS(),
        isUploadingImage: true,
      })
    case success(UPLOAD_IMAGE):
    case failure(UPLOAD_IMAGE):
      return fromJS({
        ...state.toJS(),
        isUploadingImage: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default postTrainingsReducer
