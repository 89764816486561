import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { getToken, isRouteAllow } from "utils/helper";
// import Sidebar from "components/sidebar";
// import Footer from "components/Footers/UserFooter";

import routes from "routes.js";

class User extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    const token = getToken();
    if (history && !token) {
      history.push('/auth/login');
    }
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/user" && isRouteAllow(prop.path)) {
        return (
          <Route
            exact
            strict
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    const token = getToken();
    if (!token) return null;
    const { pathname } = this.props.history.location;
    return (
      <>
        <div className="main-content d-flex flex-column ls-height-100" ref="mainContent">
          <Switch>{this.getRoutes(routes)}</Switch>
          {/* {pathname !== '/user/forums' && <Sidebar />}
          <Footer /> */}
        </div>
      </>
    );
  }
}

export default withRouter(User);
