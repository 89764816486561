export const GET_LIST_OF_BOOKLETS =
  'src/modules/resources/GET_LIST_OF_BOOKLETS';
export const GET_USERS_LIST =
  'src/modules/resources/GET_USERS_LIST';
export const GET_LIST_OF_SINGLE_BOOKLETS =
  'src/modules/resources/GET_LIST_OF_SINGLE_BOOKLETS';
export const GET_LIST_OF_ASSESSOR_BOOKLETS =
  'src/modules/resources/GET_LIST_OF_ASSESSOR_BOOKLETS';
export const GET_LIST_OF_SUPERVISOR_BOOKLETS =
  'src/modules/resources/GET_LIST_OF_SUPERVISOR_BOOKLETS';
export const GET_LIST_OF_USER_SUBMITTED_BOOKLETS =
  'src/modules/resources/GET_LIST_OF_USER_SUBMITTED_BOOKLETS';
export const UPLOAD_IMAGE =
  'src/modules/resources/UPLOAD_IMAGE';
export const APPLY_PAGINATION =
  'src/modules/resources/APPLY_PAGINATION';
export const APPLY_FILTERS =
  'src/modules/resources/APPLY_FILTERS';
export const UPDATE_SINGLE_TASK =
  'src/modules/resources/UPDATE_SINGLE_TASK';
export const UPDATE_SUPERVISION_TASK =
  'src/modules/resources/UPDATE_SUPERVISION_TASK';
export const UPDATE_SINGLE_INDEX_ACC =
  'src/modules/resources/UPDATE_SINGLE_INDEX_ACC';
export const UPDATE_INDEX_INTERVIEW =
  'src/modules/resources/UPDATE_INDEX_INTERVIEW';
export const SUBMIT_FOR_CERTIFICATION =
    'src/modules/resources/SUBMIT_FOR_CERTIFICATION';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/resources/CANCEL_ALL_API_REQUESTS';
