/**
 *  Import action creator constants & dependencies
 */
import {
  GET_REFLECTIVE_ACCOUNT,
  POST_REFLECTIVE_ACCOUNT,
  // APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../../configs/urls';
import axios from 'axios';
// // import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get Single refelective account
 */
export function getReflectiveAccount(newsID) {
  return async (dispatch, getState) => {
    const state = getState();
    const { getReflectiveAccount } = state.get('reflectiveAccount').toJS();
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getReflectiveAccountBegin(
          API_URLS.REVALIDATION.GET_REFLECTIVE_ACCOUNT,
          source
        )
      );
      if (response.payload) {
        
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getReflectiveAccountBegin = (API_URL, source) => ({
  type: GET_REFLECTIVE_ACCOUNT,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      cancelToken: source.token
    }
  }
});

/**
 * 
 * @param post revalidation 
 */
export function publishReflectiveAccount(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        publishReflectiveAccountBegin(
          API_URLS.REVALIDATION.POST_REFLECTIVE_ACCOUNT,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const publishReflectiveAccountBegin = (API_URL, params, source) => ({
  type: POST_REFLECTIVE_ACCOUNT,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

// /**
//  *  Apply pagination
//  */
// export function applyPagination(page) {
//   return async dispatch => {
//     dispatch(applyPaginationBegin(page));
//     dispatch(getNews());
//   };
// };

// export const applyPaginationBegin = page => ({
//   type: APPLY_PAGINATION,
//   page
// });

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


