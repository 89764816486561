import React from "react";
import Modal from 'react-bootstrap/Modal';
import { func, bool, object, array } from "prop-types";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from '../../utils/notification';

class ShareProfile extends React.Component {

  state = {
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
  }

  copyText(){
    const el = this.textArea
    el.select()
    document.execCommand("copy");
    showSuccessMsg("Link Copied!");
    this.props.onClose();
  }

  render() {
    const { isVisible, onClose, userProfile } = this.props;
    const { dummyBaseURl } = this.state;
    const {
		profile_identifier
    } = userProfile;
    const url = dummyBaseURl + "/public/passport/" + profile_identifier;
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header className="background-primary justify-content-center">
            <Modal.Title className="color-white">Share Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ls-form">
              <div class="form-group">
                <input type="text" name="copyName" value={url} ref={(textarea) => this.textArea = textarea } className="form-control" readOnly  />  
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div className="row pb-3 w-100">
							<div className="col-md-6">
                <button className="btn btn-secondary w-100" onClick={onClose}>
                  Cancel
                </button>
              </div>
							<div className="col-md-6">
                <button type="submit" className="btn btn-primary lS-btn-blue-style w-100" onClick={() => this.copyText(url)}>
                  Copy Link
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
ShareProfile.propTypes = {
  instituteList: array.isRequired,
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  onCompleted: func.isRequired,
  updateProfile: func.isRequired,
  userProfile: object.isRequired
};

export default ShareProfile;