/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_TRAININGS,
  APPLY_PAGINATION,
  RESET_FILTERS,
  ADD_WELL_BEING,
  UPLOAD_IMAGE,
  UPDATE_TRAININGS,
  // DELETE_TRAININGS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib';
const cancelApiRequests = [];

/**
 *  Get List of Trainings
 */
export function getTrainings(id) {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getTrainingsBegin(
          API_URLS.WELL_BEING.SHOW + id,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getTrainingsBegin = (API_URL, source) => ({
  type: GET_LIST_OF_TRAININGS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 * Create new training
 * @param {} params 
 */
export function addWellBeing(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addWellBeingBegin(
          API_URLS.WELL_BEING.CREATE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addWellBeingBegin = (API_URL, params, source) => ({
  type: ADD_WELL_BEING,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Delete List of Trainings
 */
// export function deletePostTraining(TrainingID) {
//   return async dispatch => {
//     const source = axios.CancelToken.source();
//     cancelApiRequests.push(source);
//     try {
//       const response = await dispatch(
//         deletePostTrainingBegin(
//           API_URLS.POST_TRAININGS.DELETE_TRAININGS + TrainingID,
//           TrainingID,
//           source
//         )
//       );
//       if (response.payload) {
//         const { data } = response.payload;
//         return data;
//       }

//       throw response;
//     } catch (error) {
//       throw error.response;
//     }
//   };
// };

// export const deletePostTrainingBegin = (API_URL, TrainingID, source) => ({
//   type: DELETE_TRAININGS,
//   payload: {
//     request: {
//       url: API_URL,
//       method: 'delete',
//       headers: {
//         accept: 'application/json',
//         'content-type': 'application/json'
//       },
//       cancelToken: source.token
//     }
//   },
//   TrainingID
// });

/**
 *  update List of Trainings
 */
export function updatePostTraining(params, id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updatePostTrainingBegin(
          API_URLS.WELL_BEING.UPDATE + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updatePostTrainingBegin = (API_URL, params, source) => ({
  type: UPDATE_TRAININGS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getTrainings());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Reset filters
 */
export function resetFilters() {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getTrainings());
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});

/**
 *  Upload Image
 */
export function uploadImage(formData) {
  return async dispatch => {
    try {
      const response = await dispatch(
        uploadImageBegin(
          API_URLS.IMAGE_UPLOAD,
          formData
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const uploadImageBegin = (API_URL, params) => ({
  type: UPLOAD_IMAGE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  }
});
