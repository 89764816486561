/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const resources = () => state => state.get('resources');

/**
 *  Select the correct portion of the root reducer
 */
export const getBookLetsListing = () =>
  createSelector(resources(), state => {
    const listing = state.get('bookLets').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getSingleBookLetsListing = () =>
  createSelector(resources(), state => {
    const listing = state.get('bookLetIndexsAndTasks').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select the correct portion of the root reducer
 */
export const getSingleBookLetDetails = () =>
createSelector(resources(), state => {
  //console.log(state.get('booklet').toJS());
  const booklet =  state.get('booklet').toJS()
  return booklet ? booklet : {}
});

/**
 *  Select the correct portion of users listing
 */
export const getUsersListing = () =>
  createSelector(resources(), state => {
    const listing = state.get('users').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching trainings
*/
export const getIsFetchingResources = () =>
createSelector(resources(), state => {
  return state.get('isFetchingResources')
})

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(resources(), state => {
    return state.get('isUploadingImage')
  })

/**
 *  Select total number of resources pages
 */
export const getTotalPages = () =>
  createSelector(resources(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current Resources pages
 */
export const getResourcesPage = () =>
  createSelector(resources(), state => {
    return state.get('page')
  })