import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import EditCpdLog from "components/editCpdLog";
import ApproveConfirmation from 'components/approveConfirmation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { filterSalaryByType, filterGreaterValue } from 'utils/helper';
import Loader from 'components/Loader';
import Select from 'react-select';
import * as Yup from 'yup';

import { learning, linkCodes } from './fliters';
import {  getCpdLog, deleteCpdLog } from '../../../modules/revalidation/cpdLog/actions';
import {  getCpdLogListing, getIsFetchingCpdLog } from '../../../modules/revalidation/cpdLog/selectors';

const addEditJobSchema = Yup.object().shape({
  learning: Yup.string()
    .required('Required'),
  topic: Yup.string()
    .required('Required'),
  code: Yup.string()
    .required('Required'),
});

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#FFFFFF',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formattedArray = (array, fieldName, form) => {
  if (fieldName === 'maxSalary') {
    const { values } = form;
    if(values && values.minSalary) {
      const result =  values.minSalary.split(" ");
      const salaryType =  result[result.length - 1];
      const filterData = filterGreaterValue(values.minSalary, filterSalaryByType(salaryType, array));
      array = filterData;
    }
  }
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if (field.name === 'minSalary') {
          form.setFieldValue("maxSalary", ''); // Reset max salary field
        }
        form.setFieldValue(field.name, value.value)
      }}
      value={field.value ? formattedArray([field.value], field.name, form) : ''}
      styles={customStyles}
      options={formattedArray(options, field.name, form)}
    />
  )
};

class CPDLog extends React.Component {
  state={
    isVisibleEditLog: false,
    selctedLog: null,
    logId: null,
    isVisibleAprroval: false,
    selectedItem: null,
  }

  deleteLog = (id) => {
    this.setState({
      selectedItem: id,
      isVisibleAprroval: true,
    });
  }

  componentWillMount() {
    this.props.getCpdLog();
  }

  getFormikInitVal = () => {
    return {
      learning: '',
      code: '',
      topic: '',
    }
  }
  

  getSingleCpdLog = (cpdLog, index, id) => (
    
    <React.Fragment key={index}>
      <tr>
        <td className="py-4">{getFormattedDate(cpdLog.log_date)}</td>
        <td className="py-4">{cpdLog.method || `--` }</td>
        <td className="py-4">{cpdLog.topic || `--` }</td>
        <td className="py-4">{cpdLog.link_to_code || `--` }</td>
        <td className="py-4">{cpdLog.hrs || `--` }</td>
        <td className="py-4">{cpdLog.participatory_hrs || `--` }</td>

        <td className="text-right px-2">
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary lS-btn-icon lS-btn-green-style mr-2" onClick={() => this.handleVisibleEditCpdLog(cpdLog, id)}>
              <i className="fas fa-edit" />
            </button>
            <button className="btn btn-secondary lS-btn-icon lS-btn-red-style mr-2" onClick={() => this.deleteLog(id)}>
              <i className="fas fa-trash" />
            </button>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
  
  handleVisibleEditCpdLog = (cpdLog, id) => {
    this.setState({
      isVisibleEditLog: true,
      selectedLog: cpdLog,
      logId: id,
    });
  }

  render() {
    const {
      isVisibleEditLog,
      selectedLog,
      logId,
      isVisibleAprroval,
      selectedItem,
    } = this.state;
    const { cpdLog, isFetchingCpdLog } = this.props;
    // const { bookLets, isFetchingBooklets } = this.props;
    return (
      <>
        <UserHeader />
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold mt-2">Continue professional development CPD log</h4>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-md-5 mt-4 family-poppins-medium">Guide to completing CPD record log</h5>
          <Formik
            initialValues={this.getFormikInitVal()}
            validationSchema={addEditJobSchema}
            onSubmit={() => {}}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="ls-form">
                <div className="row mt-lg-5 mt-4">
                  <div className="col-lg-4 mb-lg-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="learning" className="color-grey font-14 mb-3">Examples of learning method</label>
                      <Field as="select" 
                        name="learning" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={learning}
                        id="learning" 
                        placeholder="Learning Method" />
                      <ErrorMessage name="learning" component="div" className="error-msg font-11" />                                  
                    </div>
                  </div>
                  <div className="col-lg-4 mb-lg-5 mb-4">
                    <div className="form-group">
                      <label htmlFor="topic" className="color-grey font-14 mb-3">What was the topic?</label>
                      <Field type="text" name="topic" className="flex-fill form-control form-control-lg rounded-lg bg-white color-black" id="topic" placeholder="Enter topic" />
                      <ErrorMessage name="topic" component="div" className="error-msg" />
                    </div>
                    <p className="color-grey font-12 font-italic mb-0">Please give a brief outline of the key points of the learning activity, how it is linked to your scope of practice, what you learnt, and how you have applied what you learnt to your practice.</p>
                  </div>
                  <div className="col-lg-4 mb-lg-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="code" className="color-grey font-14 mb-3">Link to Code</label>
                      <Field as="select" 
                        name="code" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={linkCodes}
                        id="code" 
                        placeholder="Select Code" />
                      <ErrorMessage name="code" component="div" className="error-msg" />                                  
                    </div>
                  </div>
                  <div className="col-12 mb-3 mt-3 mt-lg-0">
                    <p className="mb-4">Please provide the following information for each learning activity, until you reach 35 hours of CPD (of which 20 hours must be participatory). For examples of the types of CPD activities you could undertake, and types of evidence you could retain, refer to our guidance sheet at www.revalidation.nmc.org.uk/download-resources/guidance-and-information.</p>
                    <button type="submit" class="btn lS-btn-blue-style lS-btn-blue-large mb-3">Export</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          {/* table */}
          <div className="card border-0 lS-card-shadow lS-card-radius mt-md-4 mt-3 mb-4">
            <div className="card-body">
              <div className="table-responsive ls-table">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col" className="pb-4 text-center" width="120">Dates</th>
                      <th scope="col" className="pb-4" width="330">Method
                        <span className="d-block color-grey font-11 text-center font-weight-normal">Please describe the methods you used for the activity</span>
                      </th>
                      <th scope="col" className="pb-4" width="120">Topic(s)</th>
                      <th scope="col" className="pb-4" width="150">Link to Code</th>
                      <th scope="col" className="pb-4" width="150">Number of Hours</th>
                      <th scope="col" className="pb-4" width="240">Number of Participatory Hours</th>
                      <th scope="col" className="pb-4 text-right" width="100">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetchingCpdLog && cpdLog && cpdLog.length > 0 && cpdLog.map((cpdLog, index) => this.getSingleCpdLog(cpdLog.attributes, index, cpdLog.id))}
                    {!isFetchingCpdLog && cpdLog && cpdLog.length === 0 && (
                      <tr>
                        <td colspan="7">
                          <div className="text-center px-3 radius-10 family-poppins-medium">No Record found</div>
                        </td>
                      </tr>
                    )}
                    {isFetchingCpdLog && (
                      <tr>
                        <td colspan="7">
                          <Loader />
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="7" className="border-0 px-0 pb-0">
                        <div className="d-flex justify-content-end border-top ls-border-color mt-4 pt-5 pb-3">
                          <div className="form-group ls-login-form mb-0 px-4 text-left mr-5">
                            <label className="color-grey mb-0">Total</label>
                            <input type="text" className="pb-0 form-control font-18 height-auto rounded-lg color-black" placeholder="00" />
                          </div>
                          <div className="form-group ls-login-form mb-0 px-4 text-left">
                            <label className="color-grey mb-0">Total</label>
                            <input type="text" className="pb-0 form-control font-18 height-auto rounded-lg color-black" placeholder="00" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="text-right mb-5">
            <button className="btn lS-btn-blue-style lS-btn-blue-large" onClick={() => this.handleVisibleEditCpdLog(null, null)} >
              Add Log
            </button>
          </div>
        </main>
        <UserFooter />
        <ApproveConfirmation
          isVisible={isVisibleAprroval}
          onClose={()=> {
            this.setState({
              isVisibleAprroval: false,
            });
          }}
          onApprove={()=> {
            this.props.deleteCpdLog(selectedItem).then(res => {
              var message = res.message;
              this.props.getCpdLog();
              showSuccessMsg(message ? message : 'Deleted successfully.');
              this.setState({
                isVisibleAprroval: false,
              });
            })
          }}
          title={'Delete feedback log'}
        />
        {isVisibleEditLog && (
          <EditCpdLog
            isVisible={isVisibleEditLog}
            onClose={() => {
              this.setState({
                isVisibleEditLog: false
              });
            }}
            // /bookLetID={Number(bookLetID)}
            logId={Number(logId)}
            singleLog={selectedLog}
          />
        )}           

      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
CPDLog.propTypes = {
  cpdLog: array.isRequired,
  getCpdLog: func.isRequired,
  isFetchingCpdLog: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  cpdLog: getCpdLogListing(),
  isFetchingCpdLog: getIsFetchingCpdLog(),
});

export default connect(
  mapStateToProps,
  {
    getCpdLog,
    deleteCpdLog
  }
)(withRouter(CPDLog));
