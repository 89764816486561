import React from "react";

import { func, array, bool, number} from 'prop-types';
import './styles.css';

class MultiSteps extends React.Component {
  getClassForStep = step => {
    const { id } = step;
    const { activeStep } = this.props;

    if (id < activeStep) {
      return 'progtrckr-done';
    }

    if (id === activeStep) {
      return 'progtrckr-doing';
    }

    return "progtrckr-todo";
  };

  render() {
    const { steps } = this.props;
    return (
      <>
        <ol className="progtrckr">
          {steps.map((step, index) => (
            <li key={index} className={this.getClassForStep(step)} value="0"><em>1</em><span>{step.name || ''}</span></li>
          ))}
        </ol>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
MultiSteps.propTypes = {
  steps: array,
  activeStep: number,
};

export default MultiSteps;
