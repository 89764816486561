/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_EVENTS,
  GET_SINGLE_EVENTS,
  APPLY_PAGINATION,
  APPLY_FILTERS,
  RESET_FILTERS,
  CANCEL_ALL_API_REQUESTS,
  GET_LIST_OF_EVENTS_APPLICANTS,
  APPLY_EVENTS,
  DELETE_EVENTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of events
 */
export function getDashboardEvents() {
  return async (dispatch, getState) => {
    const state = getState();
    const { page, search, selectedRegion, selectedGroup, getEventsListing } = state.get('events').toJS();
    const params ={
      page,
      limit: 10000,
      is_staff_student: 1,
      filters: {},
      getEventsListing,
    };

    // Apply when search has any value
    if (search) {
      params.filters.search = search;
    }

    // Apply region filtes
    if (selectedRegion) {
      params.filters.region = [selectedRegion];
    }

    // Apply group filtes
    if (selectedGroup) {
      params.filters.group = [selectedGroup];
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getDashboardEventsListingBegin(
          API_URLS.EVENTS.GET_EVENTS_LISTING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getDashboardEventsListingBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});


/**
 *  Get List of events
 */
export function getEvents() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, search, selectedRegion, selectedGroup, getEventsListing } = state.get('events').toJS();
    const params ={
      page,
      limit,
      filters: {},
      getEventsListing,
    };

    // Apply when search has any value
    if (search) {
      params.filters.search = search;
    }

    // Apply region filtes
    if (selectedRegion) {
      params.filters.region = [selectedRegion];
    }

    // Apply group filtes
    if (selectedGroup) {
      params.filters.group = [selectedGroup];
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getEventsListingBegin(
          API_URLS.EVENTS.GET_EVENTS_LISTING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getEventsListingBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get Single Event
 */
export function getSingleEvent(eventID) {
  return async dispatch => {

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSingleEventBegin(
          API_URLS.EVENTS.GET_SINGLE_EVENTS + eventID,
          source
        )
      );
      if (response.payload) {
        
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSingleEventBegin = (API_URL, source) => ({
  type: GET_SINGLE_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getEvents());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Apply jobs filters
 */
export function applyFilters(search, selectedRegion, selectedGroup) {
  return async dispatch => {
    dispatch(applyFiltersBegin(search, selectedRegion, selectedGroup));
    dispatch(getEvents());
  };
};

export const applyFiltersBegin = (search, selectedRegion, selectedGroup) => ({
  type: APPLY_FILTERS,
  search,
  selectedRegion,
  selectedGroup,
});

/**
 *  Reset filters
 */
export function resetFilters() {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getEvents());
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});

export function applyEvent(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        applyEventBegin(
          API_URLS.EVENTS.APPLY_EVENTS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const applyEventBegin = (API_URL, params, source) => ({
  type: APPLY_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Get List of Event applicants
 */
export function getEventApplicants(params) {
  return async (dispatch, getState) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getEventApplicantsBegin(
          API_URLS.EVENTS.GET_EVENTS_APPLICANTS,
          params,
          source
        )
      );

      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getEventApplicantsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_EVENTS_APPLICANTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply pagination
 */
export function applyEventApplicantPagination(page) {
  return async dispatch => {
    dispatch(applyEventApplicantPaginationBegin(page));
    dispatch(getEventApplicants());
  };
};

export const applyEventApplicantPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});


/**
 *  Delete Event
 */
export function deleteEvent(eventId) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        deleteEventBegin(
          API_URLS.EVENTS.DELETE_EVENTS + eventId,
          eventId,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const deleteEventBegin = (API_URL, eventId, source) => ({
  type: DELETE_EVENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'delete',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  eventId
});

