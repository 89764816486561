import React from "react";
import { func, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import { getFormattedDate } from 'utils/helper';
import TrainingRequirements from 'components/trainingRequirements';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  getTrainingAnalysisReport,
  exportTrainingReport,
} from '../../modules/trainingAnalysisReport/actions';
import {
    getAnalysisReport,
    getIsAnalysisReport,
    getIsDownloadingReport,
} from '../../modules/trainingAnalysisReport/selectors';

import Loader from 'components/Loader';

class TrainingAnalysisReport extends React.Component {
    state = {
        isVisibleRequirements: false,
        tempRequirements: [],
    }
    componentWillMount() {
        this.props.getTrainingAnalysisReport();
    }

    visibleRequirementsPopup = requirements => {
        this.setState({
            isVisibleRequirements: true,
            tempRequirements: requirements,
        });
    };

    downloadReport = () => {
        this.props.exportTrainingReport().then(res => {
            const { url } = res.data;
            if(url) {
                window.open(url);
            } 
        });
    };

    render() {
        const {
            isFetchingAnalysisReport,
            isDownloadingReport,
            analysisReport,
          } = this.props;
        const { isVisibleRequirements, tempRequirements } = this.state;
        return (
            <>
            <UserHeader />
            {/* Page content */}
            <main class="m-0 px-3 px-md-5 mt-md-5 mt-4 mb-5">
                <div class="card border-0 page-title-bar">
                    <div class="card-body">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <h4 className="family-poppins-semibold my-2">Training Analysis</h4>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-lg-end justify-content-center">
                                <button type="button" download className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large" disabled={isDownloadingReport} onClick={() => this.downloadReport()}>
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="card border-0 lS-card-shadow lS-card-radius mt-md-5 mt-4" id="myTabContent">
                    <div className="card-body" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <div className="table-responsive ls-table">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                    <th scope="col" className="pb-4" width="200"> Name </th>
                                    <th scope="col" className="pb-4" width="300"> PCN </th>
                                    <th scope="col" className="pb-4" width="120">Profession</th>
                                    <th scope="col" className="pb-4" width="150">Job Pattern</th>
                                    <th scope="col" className="pb-4" width="150">Age Group</th>
                                    <th scope="col" className="pb-4" width="150">Job Type</th>
                                    <th scope="col" className="pb-4" width="150">Ethnicity</th>
                                    <th scope="col" className="pb-4" width="150">Gender</th>
                                    <th scope="col" className="pb-4" width="150">Training Attained</th>
                                    <th scope="col" className="pb-4" width="150">Training Required</th>
                                    <th scope="col" className="pb-4" width="150">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isFetchingAnalysisReport && analysisReport && analysisReport.length > 0 && analysisReport.map((singleReport, index) => {
                                        const {
                                            name,
                                            pcn,
                                            profession,
                                            job_pattern,
                                            age_group,
                                            job_type,
                                            ethnicity,
                                            gender,
                                            training_requirement_description,
                                            training_attained,
                                            training_required
                                        } = singleReport.attributes;
                                        return(
                                        <tr key={index}>
                                            <td className="py-4">{name || '-'} </td>
                                            <td className="py-4"> {(pcn && pcn.name) || '-'} </td>
                                            <td className="py-4"> {profession || '-'} </td>
                                            <td className="py-4"> {job_pattern || '-'} </td>
                                            <td className="py-4"> {age_group || '-'} </td>
                                            <td className="py-4"> {job_type || '-'} </td>
                                            <td className="py-4"> {ethnicity || '-'} </td>
                                            <td className="py-4"> {gender || '-'} </td>
                                            <td className="py-4"> {(training_attained && training_attained.map((training, index) => {
                                                if(training_attained.length !== index + 1) return training + ', ';
                                                return training
                                            })) || '-'} </td>
                                            <td className="py-4"> {training_required && training_required.length > 0 ? (<span className="cursor-pointer" onClick={() => this.visibleRequirementsPopup(training_required)}>{training_required.length + ' requirements found'}</span>) : 'No requirement found'} </td>
                                            <td className="py-4"> {training_requirement_description || '-'} </td>
                                        </tr>
                                        )}
                                    )}
                                    {!isFetchingAnalysisReport && analysisReport && analysisReport.length === 0 && (
                                        <tr>
                                            <td colspan="6">
                                                <div className="text-center bg-white py-5 px-3 radius-10 family-poppins-medium">
                                                    No record found
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {isFetchingAnalysisReport && (
                                        <tr>
                                            <td colspan="6">
                                                <Loader />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            <UserFooter/>
            {
                <TrainingRequirements
                    isVisible={isVisibleRequirements}
                    requirements={tempRequirements && tempRequirements.length > 0 ? tempRequirements : []}
                    onClose={() => {
                        this.setState({
                            isVisibleRequirements: false,
                        });
                    }}
                />
            }
            </>
        );
    }
}

/**
 *  Define component PropTypes
 */
TrainingAnalysisReport.propTypes = {
    analysisReport: array.isRequired,
    exportTrainingReport: func.isRequired,
    isDownloadingReport: bool.isRequired,
    isFetchingAnalysisReport: bool.isRequired,
    getTrainingAnalysisReport: func.isRequired,
  };

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    analysisReport: getAnalysisReport(),
    isFetchingAnalysisReport: getIsAnalysisReport(),
    isDownloadingReport: getIsDownloadingReport(),
  });

export default connect(
    mapStateToProps,
    {
        getTrainingAnalysisReport,
        exportTrainingReport,
    }
  )(withRouter(TrainingAnalysisReport));