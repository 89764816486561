import React from "react";
import { func, bool, array, number } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { showSuccessMsg } from 'utils/notification';
import { getFormattedDate } from 'utils/helper';
import ReactPaginate from 'react-paginate';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  getEventApplicants,
  applyEventApplicantPagination
} from '../../modules/events/actions';
import {
  getEventApplicantsListing,
  getIsFetchingEventApplicants,
  getTotalPages,
  getEventsPage,
} from '../../modules/events/selectors';

import Loader from 'components/Loader';

class EventApplicants extends React.Component {
    componentWillMount() {
        const { eventId } = this.props.match.params;
        if (eventId && !isNaN(eventId)) {
            var params = {
            event_id:eventId,
            };
            this.props.getEventApplicants(params);
        }
    }
    markCompleted = (applicantId, status) =>{
        if(this.props.match.params.eventId){
            let params={
                event_id: this.props.match.params.eventId,
                applicant_id: applicantId,
                status: status
            }
            this.props.updateEvent(params).then(res => {
                const { message } = res;
                var innerParams = {
                    'event_id': this.props.match.params.eventId,
                };
                this.props.getEventApplicants(innerParams);
                showSuccessMsg(message);
            });
        }
    }

    render() {
        const {
            isFetchingEventApplicants,
            eventApplicants, page, totalPages
          } = this.props;

        return (
            <>
            <UserHeader />
            {/* Page content */}
            <main class="m-0 px-3 px-md-5 mt-md-5 mt-4 mb-5">
                <div class="card border-0 page-title-bar">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h4 class="family-poppins-semibold my-2">Event Applicants</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border-0 lS-card-shadow lS-card-radius mt-md-5 mt-4" id="myTabContent">
                    <div className="card-body" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <div className="table-responsive ls-table">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" className="pb-4 text-left" width="100"> Image </th>
                                        <th scope="col" className="pb-4" width="160"> Name </th>
                                        <th scope="col" className="pb-4" width="300"> E-mail </th>
                                        {/* <th scope="col" className="pb-4"> Organizer </th> */}
                                        <th scope="col" className="pb-4" width='120'> Status </th>
                                        <th scope="col" className="pb-4" width="150"> Date booked </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isFetchingEventApplicants && eventApplicants && eventApplicants.length > 0 && eventApplicants.map((eventApplicant, index) => {
                                        const {profile_image_url, name, status, email, created_at, profile_identifier} = eventApplicant.attributes;
                                        return(
                                        <tr key={index}>
                        
                                            <td className="py-4 text-left"> 
                                                <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                                                    <img src={profile_image_url} alt="..." className="img-object-fit" />
                                                </div>
                                            </td>
                                            <td className="py-4">{name} </td>
                                            <td className="py-4"> {email} </td>
                                            {/* <td className="py-4"> CLH </td> */}
                                            <td className="py-4 text-capitalize"> {status} </td>
                                            <td className="py-4"> {getFormattedDate(created_at)} </td>
                        
                                        </tr>
                                        )
                                    }
                                    )}
                                
                                </tbody>
                            </table>
                            {!isFetchingEventApplicants && eventApplicants && eventApplicants.length === 0 && (
                                <div className="text-center bg-white py-5 px-3 radius-10 family-poppins-medium">
                                    No application found for this event
                                </div>
                            )}
                            {isFetchingEventApplicants && (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
                <div aria-label="Page navigation example">
                    <ReactPaginate
                        containerClassName={'pagination justify-content-end mt-5'}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        forcePage={page - 1}
                        onPageChange={selectedItem => {
                        if (isFetchingEventApplicants) return;
                        this.loadPage(selectedItem.selected + 1);
                        }}
                        subContainerClassName={'pages pagination'}
                        pageClassName={'page-item'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        pageLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </main>
            <UserFooter/>
            </>
        );
    }
}

/**
 *  Define component PropTypes
 */
EventApplicants.propTypes = {
    eventApplicants: array.isRequired,
    isFetchingEventApplicants: bool.isRequired,
    getEventApplicants: func.isRequired,
    totalPages: number.isRequired,
    page: number.isRequired,
  };

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    eventApplicants: getEventApplicantsListing(),
    isFetchingEventApplicants: getIsFetchingEventApplicants(),
    totalPages: getTotalPages(),
    page: getEventsPage(),
  });

export default connect(
    mapStateToProps,
    {
        getEventApplicants,
        applyEventApplicantPagination
    }
  )(withRouter(EventApplicants));