import React from "react";
import { func, object, bool, array } from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { getFormattedDateTime } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';
import StarRating from 'components/starRating';
import { showSuccessMsg } from 'utils/notification';
import Loader from 'components/Loader';
import { getCalender  } from '../../modules/calender/actions';
import { getCalenderListing, getIsFetchingCalender } from '../../modules/calender/selectors';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

/**
 *  Import other dependencies
 */
import { 
  getTrainings
} from '../../modules/trainingPost/actions';

import { applyTraining } from '../../modules/trainings/actions';

import {
  getIsFetchingTrainings,
  getTrainingsListing
} from '../../modules/trainingPost/selectors';

class TrainingDetail extends React.Component {
  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getTrainings(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/trainings');
        }
      });
    }
  }
  
  applyTraining = (id) => {
    var params = {
      training_id:id,
    };

    this.props.applyTraining(params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Booked successfully.');
    })
  }

  render() {
    const { training,calender, isFetchingTrainings, isFetchingCalender} = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-3 px-md-5 pt-md-5 pt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h4 className="family-poppins-semibold my-2">Training</h4>
                  </div>
                </div>
              </div>
            </div>  
            {/* Filter Code */}
            {/* details */}
            <div className="row mt-md-5 mt-4 mb-5">
              <div className="col-xl-8">
                <div className="card border-0 lS-card-shadow lS-card-radius detail-card ls-height-100">
                  <div className="card-body">
                    {!isFetchingTrainings && training && Object.keys(training).length > 0 && (
                      <div className="d-flex flex-column flex-md-row">
                        <div>
                          <div className="thumb-wrap thumb-wrap-xl border ls-border-color radius-10 mb-4 mb-md-0">
                            <img src={training.attributes.image_url} alt="..." className="img-object-fit" />
                          </div>
                        </div>
                        <div className="min-width0 flex-fill pl-md-4">
                          <div className="d-flex ls-height-100 flex-column justify-content-between">
                            <div className="flex-fill">
                              <div>
                                <div className="float-sm-right ml-0 ml-sm-3 text-center">
                                  {!training.attributes.can_edit && !training.attributes.has_already_applied && (
                                    <button className="btn btn-primary px-4 lS-btn-sm lS-btn-blue-style rounded-pill font-12 mb-3" disabled={training.attributes.has_already_applied} onClick={() => this.applyTraining(this.props.match.params)}>Book<i className="fas fa-arrow-right ml-1" /></button>
                                  )}

                                  {!training.attributes.can_edit && training.attributes.has_already_applied && (
                                    <button className="btn btn-primary px-4 lS-btn-sm lS-btn-blue-style rounded-pill font-12 mb-3" disabled>Booked</button>
                                  )}
                                </div>
                                <h4 className="mt-0 mb-2 font-normal color-black-heading family-poppins-medium">{training.attributes.title}</h4>
                              </div>
                              <div className="d-flex align-items-center mb-2">
                                <OverlayTrigger
                                  key={training.id}
                                  placement={'top'}
                                  overlay={
                                    <Tooltip id={`tooltip-${training.id}`}>
                                      <strong>{training.attributes.average_rating || 0}</strong>
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <StarRating 
                                      initialRating={training && training.attributes.average_rating ? Number(training.attributes.average_rating) : 0}
                                      readOnly={true}
                                      onRate={() => {}}
                                    />
                                  </div>
                                </OverlayTrigger>
                                <span className="ml-2 color-grey">
                                  {training.attributes.rating_count || 0}
                                </span>
                                <i className="fas fa-user ml-2 color-grey"></i>&nbsp;
                              </div>
                              <p className="mb-3 font-18 color-primary">{training.attributes.org_name ? training.attributes.org_name : `TBC` }</p>
                              <p className="color-grey mb-2">
                                {training.attributes.organisation}
                              </p>
                              <p className="color-grey">{training.attributes.description}</p>
                              
                              <div className="font-14 mt-4">
                                <div className="mb-3 d-flex">
                                  <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i>
                                  <span>{training.attributes.date ? getFormattedDateTime(training.attributes.date) : `TBC`}</span>
                                </div>
                                <div className="mb-3 d-flex">
                                  <i className="fas fa-calendar-alt line-height-common color-primary mr-2"></i>
                                  <span>{training.attributes.end_date ? getFormattedDateTime(training.attributes.end_date) : `TBC`}</span>
                                </div>
                                <div className="mb-2 d-flex">
                                  <i className="fas fa-map-marker-alt color-primary mr-2 line-height-common"></i>
                                  <span>{training.attributes.location}</span>
                                </div>
                              </div>
                            </div>
                            {/* {training.attributes.can_edit && (
                            <div className="pt-2 d-flex action-buttons">
                              
                                <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                                  <i className="fas fa-eye" /> Views (4)
                                </button>
                                <button className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                                  <i className="fas fa-pencil-alt" />
                                </button>
                                <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                                  <i className="fas fa-trash-alt" />
                                </button>
                            </div>
                            )} */}
                            {!training.attributes.can_edit && !training.attributes.has_already_applied && training.attributes.link_url && (
                              <div className="pt-2 d-flex action-buttons">
                                <a href={training.attributes.link_url} __blank 
                                    className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 color-white" >
                                    <i className="fas fa-globe"/>&nbsp;Visit Website
                                </a>
                              </div>
                            )}
                            
                          </div>
                        </div>
                      </div>
                    )}
                    {!isFetchingTrainings && training && Object.keys(training).length === 0 && (
                      <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No training found</div>
                    )}
                    {isFetchingTrainings && (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 m-xl-0 mt-3">
                {/* Calendar code */}
                <div className="card event-calendar-sm lS-card-shadow calendar-no-event-list ls-height-100">
                  <div id="calendar" />
                  {isFetchingCalender && (
                    <Loader />
                  )}
                  {!isFetchingCalender && (
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[ dayGridPlugin ]}
                      header={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                      }}
                      events={calender}
                      eventClick={this.handleDateClick}
                    />
                  )}
                </div>
              </div>
              {/* End Of List  */}
            </div>
          </div>
          
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
TrainingDetail.propTypes = {
  isFetchingTraining: bool.isRequired,
  training: object.isRequired,
  applyTraining: func.isRequired,
  calender: array.isRequired,
  getCalender: func.isRequired,
  isFetchingCalender: bool.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isFetchingTrainings: getIsFetchingTrainings(),
  training: getTrainingsListing(),
  calender: getCalenderListing(),
  isFetchingCalender: getIsFetchingCalender(),
});

export default connect(
  mapStateToProps,
  {
    getTrainings,
    applyTraining,
    getCalender,
  }
)(withRouter(TrainingDetail));
