export const GET_LIST_OF_APPRAISALS =
  'src/modules/apprasials/GET_LIST_OF_APPRAISALS';
export const ADD_APPRAISALS =
  'src/modules/apprasials/ADD_APPRAISALS';
export const GET_SINGLE_APPRAISAL =
  'src/modules/apprasials/GET_SINGLE_APPRAISAL';
export const GET_SUPERVISOR =
  'src/modules/apprasials/GET_SUPERVISOR';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/apprasials/CANCEL_ALL_API_REQUESTS';
export const APPLY_PAGINATION =
  'src/modules/apprasials/APPLY_PAGINATION';
