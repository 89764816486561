import React from "react";
import Modal from 'react-bootstrap/Modal';
import { func, bool, object, number } from "prop-types";
import Poll from "../poll";



class PollingView extends React.Component {

	render() {
		const {
			isVisible,
			singlePoll,
      onClose,
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white">Poll</Modal.Title>
					</Modal.Header>
					<Modal.Body className="px-4">
            <Poll 
              question={singlePoll.attributes.question}
              options={singlePoll.option}
              onAnswer={() => {}}
              isAlreadyAnswer={true}
            />
          </Modal.Body>
					<Modal.Footer className="border-0">
						<div className="row pb-3 w-100">
							<div className="col-md-12">
								<button
									className="btn btn-secondary w-100"
									onClick={() => {
										onClose();
									}}
								>
									Close
								</button>
							</div>
						</div>
					</Modal.Footer>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
PollingView.propTypes = {
	isVisible: bool.isRequired,
	onClose: func.isRequired,
	singlePoll: object.isRequired,
};

export default PollingView;
