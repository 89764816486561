const sourcesFeedback = [
  'Patients or service users',
  'Colleagues – nurses, midwives, nursing associates, other healthcare professionals',
  'Students',
  'Annual appraisal',
  'Team performance reports',
  'Serious event reviews',
];

const typesFeedback = [
  'Verbal',
  'Letter or card',
  'Survey',
  'Report',
];

export {
  sourcesFeedback,
  typesFeedback,
};
