/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_TRAINING_FORM,
  ADD_TRAINING,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Training Anylasis
 */
export function getTrainingsForm() {
  return async (dispatch, getState) => {
    const state = getState();

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getTrainingsFormBegin(
          API_URLS.TRAINING_ANALYSIS.ADD_TRAINING_FORM,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getTrainingsFormBegin = (API_URL, source) => ({
  type: GET_LIST_OF_TRAINING_FORM,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});


/**
 *  Add List of Training Post
 */
export function addPostForm(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addPostFormBegin(
          API_URLS.TRAINING_ANALYSIS.ADD_TRAINING_FORM,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addPostFormBegin = (API_URL, params, source) => ({
  type: ADD_TRAINING,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
