const pathwayData = [
  {
    label: 'Primary Care Nursing',
    roles: [
      {
        role_name: 'Health Care Assistant',
        roles_titles: [
          'Trainee HCA',
          'Junior HCA',
          'Senior HCA',
          'Lead HCA',
          'HCA Educator',
        ]
      },
      {
        role_name: 'Nursing Associate',
        roles_titles: [
          'Trainee NA',
          'Junior NA',
          'Senior NA',
          'Lead NA',
          'NA Educator',
        ]
      },
      {
        role_name: 'General Practice Nurse',
        roles_titles: [
          'Trainee GPN',
          'Junior GPN',
          'Senior GPN',
          'Lead GPN',
          'GPN Educator',
        ]
      },
      {
        role_name: 'Clinical Nurse Specialist',
        roles_titles: [
          'Trainee CNS',
          'Junior CNS',
          'Senior CNS',
          'Lead CNS',
          'CNS Educator',
        ]
      },
      {
        role_name: 'Advanced Nurse Practitioner',
        roles_titles: [
          'Trainee ANP',
          'Junior ANP',
          'Senior ANP',
          'Lead ANP',
          'ANP Educator',
        ]
      }
    ]
  },
  {
    label: 'Community Care Nursing',
    roles: [
      {
        role_name: 'Public Health Nurse',
        roles_titles: [
          'Trainee Public Health Nurse',
          'Junior Public Health Nurse',
          'Senior Public Health Nurse',
          'Lead Public Health Nurse',
          'Public Health Nurse Educator',
        ]
      },
      {
        role_name: 'Modern Matron',
        roles_titles: [
          'Trainee Modern Matron',
          'Junior Modern Matron',
          'Senior Modern Matron',
          'Lead Modern Matron',
          'Modern Matron Educator',
        ]
      },
      {
        role_name: 'School Nurse',
        roles_titles: [
          'Trainee School Nurse',
          'Junior School Nurse',
          'Senior School Nurse',
          'Lead School Nurse',
          'School Nurse Educator',
        ]
      },
      {
        role_name: 'Health Visitor',
        roles_titles: [
          'Trainee Health Visitor',
          'Junior Health Visitor',
          'Senior Health Visitor',
          'Lead Health Visitor',
          'Health Visitor Educator',
        ]
      },
      {
        role_name: 'District Nurse',
        roles_titles: [
          'Trainee District Nurse',
          'Junior District Nurse',
          'Senior District Nurse',
          'Lead District Nurse',
          'District Nurse Educator',
        ]
      }
    ]
  },
  {
    label: 'Social Care Nursing',
    roles: [
      {
        role_name: 'Health Care Assistant',
        roles_titles: [
          'Trainee HCA',
          'Junior HCA',
          'Senior HCA',
          'Lead HCA',
          'HCA Educator',
        ]
      },
      {
        role_name: 'Nursing Associate',
        roles_titles: [
          'Trainee NA',
          'Junior NA',
          'Senior NA',
          'Lead NA',
          'NA Educator',
        ]
      },
      {
        role_name: 'Social Care Nurse',
        roles_titles: [
          'Trainee Social Care',
          'Junior Social Care',
          'Senior Social Care',
          'Lead Social Care',
          'Social Care Educator',
        ]
      },
      {
        role_name: 'Clinical Nurse Specialist',
        roles_titles: [
          'Trainee CNS',
          'Junior CNS',
          'Senior CNS',
          'Lead CNS',
          'CNS Educator',
        ]
      },
      {
        role_name: 'Advanced Nurse Practitioner',
        roles_titles: [
          'Trainee ANP',
          'Junior ANP',
          'Senior ANP',
          'Lead ANP',
          'ANP Educator',
        ]
      }
    ]
  },
  // {
  // label: 'Medical',
  // roles: [
  // {
  // role_name: 'Physician Associate ',
  // roles_titles: [
  // 'Trainee PA',
  // 'Junior PA',
  // 'Senior PA',
  // 'Lead PA',
  // 'PA Educator',
  // ]
  // },
  // {
  // role_name: 'GP Research',
  // roles_titles: [
  // '',
  // '',
  // '',
  // '',
  // '',
  // ]
  // },
  // {
  // role_name: 'Specalisum',
  // roles_titles: [
  // 'Diabetes',
  // 'Cardiology',
  // 'Respiratory',
  // 'Dermatology',
  // 'Minor Surgery',
  // ]
  // },
  // {
  // role_name: 'GP Trainer',
  // roles_titles: [
  // '',
  // '',
  // '',
  // '',
  // '',
  // ]
  // },
  // {
  // role_name: 'GP Commissioner',
  // roles_titles: [
  // '',
  // '',
  // '',
  // '',
  // '',
  // ]
  // }
  // ]
  // },
  // {
  // label: 'AHP',
  // roles: [
  // {
  // role_name: 'Physiotherapist',
  // roles_titles: [
  // 'Trainee Physiotherapist',
  // 'Junior Physiotherapist',
  // 'Senior Physiotherapist',
  // 'Lead Physiotherapist',
  // 'Physiotherapist Educator',
  // ]
  // },
  // {
  // role_name: 'Dietician',
  // roles_titles: [
  // 'Trainee Dietician',
  // 'Junior Dietician',
  // 'Senior Dietician',
  // 'Lead Dietician',
  // 'Dietician Educator',
  // ]
  // },
  // {
  // role_name: 'Podiatrist',
  // roles_titles: [
  // 'Trainee Podiatrist',
  // 'Junior Podiatrist',
  // 'Senior Podiatrist',
  // 'Lead Podiatrist',
  // 'Podiatrist Educator',
  // ]
  // },
  // {
  // role_name: 'Occupational Therapist',
  // roles_titles: [
  // 'Trainee Occupational Therapist',
  // 'Junior Occupational Therapist',
  // 'Senior Occupational Therapist',
  // 'Lead Occupational Therapist',
  // 'Occupational Therapist Educator',
  // ]
  // },
  // {
  // role_name: 'Paramedic',
  // roles_titles: [
  // 'Trainee Paramedic',
  // 'Junior Paramedic',
  // 'Senior Paramedic',
  // 'Lead Paramedic',
  // 'Paramedic Educator',
  // ]
  // }
  // ]
  // },
  // {
  // label: 'Non Medical',
  // roles: [
  // {
  // role_name: 'Care Navigator',
  // roles_titles: [
  // 'Trainee CN',
  // 'Junior CN',
  // 'Senior CN',
  // 'Lead CN',
  // 'CN Educator',
  // ]
  // },
  // {
  // role_name: 'Social Prescriber',
  // roles_titles: [
  // 'Trainee SP',
  // 'Junior SP',
  // 'Senior SP',
  // 'Lead SP',
  // 'SP Educator',
  // ]
  // },
  // {
  // role_name: 'Administration ',
  // roles_titles: [
  // 'Receptionist',
  // 'Administrator',
  // 'Clinical Coder',
  // 'Medical Secretary',
  // 'Summeriser',
  // ]
  // },
  // {
  // role_name: 'Management',
  // roles_titles: [
  // 'Practice Manager',
  // 'Business Manager',
  // 'Operation Manager',
  // 'Primary Care Network Manager',
  // 'Care Home Manager ',
  // ]
  // },
  // {
  // role_name: 'Social Worker',
  // roles_titles: [
  // 'Trainee SW',
  // 'Junior SW',
  // 'Senior SW',
  // 'Lead SW',
  // 'SW Educator',
  // ]
  // }
  // ]
  // },
  // {
  // label: 'Pharmacy',
  // roles: [
  // {
  // role_name: 'Pharmacy Assistant',
  // roles_titles: [
  // 'Trainee Pharmacy Assistant',
  // 'Junior Pharmacy Assistant',
  // 'Senior Pharmacy Assistant',
  // 'Lead Pharmacy Assistant',
  // 'Pharmacy Assistant Educator',
  // ]
  // },
  // {
  // role_name: 'Pharmacy Technician',
  // roles_titles: [
  // 'Trainee Pharmacy Technician',
  // 'Junior Pharmacy Technician',
  // 'Senior Pharmacy Technician',
  // 'Lead Pharmacy Technician',
  // 'Pharmacy Technician Educator',
  // ]
  // },
  // {
  // role_name: 'Community Pharmacist',
  // roles_titles: [
  // 'Trainee Community Pharmacist',
  // 'Junior Community Pharmacist',
  // 'Senior Community Pharmacist',
  // 'Lead Community Pharmacist',
  // 'Community Pharmacist Educator',
  // ]
  // },
  // {
  // role_name: 'Clinical Pharmacist',
  // roles_titles: [
  // 'Trainee Clinical Pharmacist',
  // 'Junior Clinical Pharmacist',
  // 'Senior Clinical Pharmacist',
  // 'Lead Clinical Pharmacist',
  // 'Clinical Pharmacist Educator',
  // ]
  // },
  // {
  // role_name: 'Hospital Pharmacist',
  // roles_titles: [
  // 'Trainee Hospital Pharmacist',
  // 'Junior Hospital Pharmacist',
  // 'Senior Hospital Pharmacist',
  // 'Lead Hospital Pharmacist',
  // 'Hospital Pharmacist Educator',
  // ]
  // }
  // ]
  // },
  
];

export {
  pathwayData
};