/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const events = () => state => state.get('events');

/**
 *  Select the correct portion of the root reducer
 */
export const getEventsListing = () =>
  createSelector(events(), state => {
    const listing = state.get('events').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select Single event page object
 */
export const getSingleEventDetail = () =>
  createSelector(events(), state => {
    const singleEvent = state.get('singleEvent')
    return singleEvent && Object.keys(singleEvent).length > 0 ? singleEvent.toJS() : {}
  });

/**
 *  Select is fetching event
 */
export const getIsFetchingEvents = () =>
  createSelector(events(), state => {
    return state.get('isFetchingEvents')
  })

/**
 *  Select is fetching event
 */
export const getIsFetchingSingleEvent = () =>
  createSelector(events(), state => {
    return state.get('isFetchingSingleEvent')
  })

/**
 *  Select total number of event pages
 */
export const getTotalPages = () =>
  createSelector(events(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current events pages
 */
export const getEventsPage = () =>
  createSelector(events(), state => {
    return state.get('page')
  })

/**
 *  Select the correct portion of the root reducer
 */
export const getEventApplicantsListing = () =>
createSelector(events(), state => {
  const listing = state.get('eventApplicants').toJS()
  return listing.length > 0 ? listing : []
});

/**
 *  Select is fetching Event applicants Name
*/
export const getIsFetchingEventApplicants = () =>
createSelector(events(), state => {
  return state.get('isFetchingEventApplicants')
})

/**
 *  Select total number of event applicant pages
 */
export const getTotalEventApplicantPages = () =>
  createSelector(events(), state => {
    return state.get('totalEventApplicantsPage')
  })

/**
 *  Select current event pages
 */
export const getEventsApplicantPage = () =>
  createSelector(events(), state => {
    return state.get('eventApplicantPage')
  })

