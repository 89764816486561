export const ADD_LIST_POLLING =
  'src/modules/polling/ADD_LIST_POLLING';
export const UPDATE_LIST_POLLING =
  'src/modules/polling/UPDATE_LIST_POLLING';
export const GET_LIST_POLLING =
  'src/modules/polling/GET_LIST_POLLING';
export const APPLY_PAGINATION =
  'src/modules/polling/APPLY_PAGINATION';
export const DELETE_EMPLOYMENTS =
  'src/modules/polling/DELETE_EMPLOYMENTS';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/polling/CANCEL_ALL_API_REQUESTS';
