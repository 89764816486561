import React from "react";
import { func, bool, number } from 'prop-types';
import Rating from 'react-rating';


class StarRating extends React.Component {

  onAnswer = (option, index) => {
    const { onAnswer } = this.props;
    const node = this.pollRef.current;
    // add d-block class to more menu div
    if (node instanceof HTMLElement) {
      const alreadyAns = node.querySelector('#alreadyAns');
      const pollOptions = node.querySelector('#poll-options');
      if(alreadyAns && pollOptions) {
        alreadyAns.classList.add('animate__fadeIn', 'd-block');
        pollOptions.classList.add('animate__fadeOut', 'd-none');
        onAnswer(option, index);
      }
    }
  };

  render() {
    const {
      readOnly,
      initialRating,
      onRate,
    } = this.props;
    return (
      <>
        <div className="d-block">
          <Rating
            emptySymbol={
              <i className="far fa-star" style={{ color: '#999b9f' }} />
            }
            fullSymbol={
              <i className="fas fa-star" style={{ color: '#f5d925' }} />
            }
            onChange={(rate) => onRate(rate)}
            initialRating={initialRating}
            readonly={readOnly}
            fractions={2}
          />
        </div>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
StarRating.propTypes = {
  initialRating: number.isRequired,
  onRate: func.isRequired,
  readOnly: bool.isRequired,
};

// Specifies the default values for props:
StarRating.defaultProps = {
  initialRating: 0, 
  readOnly: false
};

export default StarRating;
