import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import * as Yup from 'yup';

const confFormSchema= Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  NMCPin: Yup.string()
    .required('Required'),
  renewalDate: Yup.string()
    .required('Required'),
  confirmerName: Yup.string()
    .required('Required'),
  confirmerJobTitle: Yup.string()
    .required('Required'),
  confirmerEmailAddress: Yup.string()
    .required('Required'),
  confirmerAddress: Yup.string()
    .required('Required'),
  confirmerContactNumber: Yup.string()
    .required('Required'),
  confirmerDiscussion: Yup.string()
    .required('Required'),
  nurseNMCPin: Yup.string()
    .required('Required'),
  profession: Yup.string()
    .required('Required'),
  regulatoryNumber: Yup.string()
    .required('Required'),
  overseasCountry: Yup.string()
    .required('Required'),
  overseasProfession: Yup.string()
    .required('Required'),
  overseasRegulatoryNumber: Yup.string()
    .required('Required'),
  anotherProfession: Yup.string()
    .required('Required'),
  anotherRegulatoryNumber: Yup.string()
    .required('Required'),
  signature: Yup.string()
    .required('Required'),
  finalDate: Yup.string()
    .required('Required'),
});

class ConfForm extends React.Component {
  componentWillMount() {
    // this.props.getBooklets()
  }

  getFormikInitVal = () => {
    return {
      name: '',
      NMCPin: '',
      renewalDate: '',
      confirmerName: '',
      confirmerJobTitle: '',
      confirmerEmailAddress: '',
      confirmerAddress: '',
      confirmerContactNumber: '',
      confirmerDiscussion: '',
      nurseNMCPin: '',
      profession: '',
      regulatoryNumber: '',
      overseasCountry: '',
      overseasProfession: '',
      overseasRegulatoryNumber: '',
      anotherProfession: '',
      anotherRegulatoryNumber: '',
      signature: '',
      finalDate: '',
    }
  };

  render() {
    // const { bookLets, isFetchingBooklets } = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold mt-2">Confirmation Form</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row my-5">
            <div className="col-md-12">
              <div className="card shadow-sm border-0 rounded-lg">
                <div className="card-body">
                  <h5>You must use this form to record your confirmation.</h5><br />
                  <h5>To be completed by the nurse, midwife or nursing associate:</h5>
                  <Formik
                    initialValues={this.getFormikInitVal()}
                    validationSchema={confFormSchema}
                    onSubmit={() => {}}
                  >
                    {({ isSubmitting, setFieldValue, values }) => (
                      <Form className="ls-form pt-2">
                        <div className="row pt-4">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Name</label>
                              <Field type="text" name="name" className="form-control" placeholder="Enter Name" />
                              <ErrorMessage name="name" component="div" className="error-msg" />                                
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">NMC Pin</label>
                              <Field type="text" name="NMCPin" className="form-control" placeholder="Enter NMC Pin" />
                              <ErrorMessage name="NMCPin" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Date of last renewal of registration or joined the register</label>
                              <Field type="text" name="renewalDate" className="form-control" placeholder="Enter renewal date" />
                              <ErrorMessage name="renewalDate" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                        </div>

                        <h5>I have received confirmation from (select applicable):</h5>
                        <div className="row pt-4">
                          <div className="col-md-12">
                            <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="checkbox" name="applicable" id="applicable-1" value="A line manager who is also an NMC-registered nurse, midwife or nursing associate" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="applicable-1">
                                  A line manager who is also an NMC-registered nurse, midwife or nursing associate
                                </label>                            
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="checkbox" name="applicable" id="applicable-2" value="A line manager who is not an NMC-registered nurse, midwife or nursing associate" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="applicable-2">
                                  A line manager who is not an NMC-registered nurse, midwife or nursing associate
                                </label>                            
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="checkbox" name="applicable" id="applicable-3" value="Another NMC-registered nurse, midwife or nursing associate" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="applicable-3">
                                  Another NMC-registered nurse, midwife or nursing associate
                                </label>                            
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="checkbox" name="applicable" id="applicable-4" value="A regulated healthcare professional" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="applicable-4">
                                  A regulated healthcare professional
                                </label>                            
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="checkbox" name="applicable" id="applicable-5" value="An overseas regulated healthcare professional" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="applicable-5">
                                  An overseas regulated healthcare professional
                                </label>                            
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-check custom-form-check mb-4">
                                <Field className="form-check-input" type="checkbox" name="applicable" id="applicable-6" value="Other professional in accordance with the NMC’s online confirmation tool" />
                                <label className="form-check-label font-18 family-poppins-medium" htmlFor="applicable-6">
                                  Other professional in accordance with the NMC’s online confirmation tool
                                </label>                            
                            </div>
                          </div>
                        </div>

                        <h5 className="py-4">To be completed by the confirmer:</h5>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Name</label>
                              <Field type="text" name="confirmerName" className="form-control" placeholder="Enter Name" />
                              <ErrorMessage name="confirmerName" component="div" className="error-msg" />                                
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Job title</label>
                              <Field type="text" name="confirmerJobTitle" className="form-control" placeholder="Enter Job title" />
                              <ErrorMessage name="confirmerJobTitle" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Email address</label>
                              <Field type="text" name="confirmerEmailAddress" className="form-control" placeholder="Enter Email address" />
                              <ErrorMessage name="confirmerEmailAddress" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Professional address including postcode</label>
                              <Field type="text" name="confirmerAddress" className="form-control" placeholder="Enter address" />
                              <ErrorMessage name="confirmerAddress" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Contact number</label>
                              <Field type="text" name="confirmerContactNumber" className="form-control" placeholder="Enter Contact number" />
                              <ErrorMessage name="confirmerContactNumber" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Date of Confirmation Discussion</label>
                              <Field type="text" name="confirmerDiscussion" className="form-control" placeholder="Enter Confirmation Discussion" />
                              <ErrorMessage name="confirmerDiscussion" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                        </div>
                        <h5 className="py-4">If you are an NMC-registered nurse, midwife or nursing associate please provide:</h5>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">NMC Pin</label>
                              <Field type="text" name="nurseNMCPin" className="form-control" placeholder="Enter NMC Pin" />
                              <ErrorMessage name="nurseNMCPin" component="div" className="error-msg" />                                
                            </div>
                          </div>
                        </div>
                        <h5 className="py-4">If you are a regulated healthcare professional please provide:</h5>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Profession</label>
                              <Field type="text" name="profession" className="form-control" placeholder="Enter Profession" />
                              <ErrorMessage name="profession" component="div" className="error-msg" />                                
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Registration number for regulatory body</label>
                              <Field type="text" name="regulatoryNumber" className="form-control" placeholder="Enter number" />
                              <ErrorMessage name="regulatoryNumber" component="div" className="error-msg" />                                
                            </div>
                          </div>
                        </div>
                        <h5 className="py-4">If you are an overseas regulated healthcare professional please provide:</h5>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Country</label>
                              <Field type="text" name="overseasCountry" className="form-control" placeholder="Enter Country" />
                              <ErrorMessage name="overseasCountry" component="div" className="error-msg" />                                
                            </div>
                          </div>
                          
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Profession</label>
                              <Field type="text" name="overseasProfession" className="form-control" placeholder="Enter Profession" />
                              <ErrorMessage name="overseasProfession" component="div" className="error-msg" />                                
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Registration number for regulatory body</label>
                              <Field type="text" name="overseasRegulatoryNumber" className="form-control" placeholder="Enter number" />
                              <ErrorMessage name="overseasRegulatoryNumber" component="div" className="error-msg" />                                
                            </div>
                          </div>
                        </div>
                        <h5 className="py-4">If you are another professional please provide:</h5>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Profession</label>
                              <Field type="text" name="anotherProfession" className="form-control" placeholder="Enter Profession" />
                              <ErrorMessage name="anotherProfession" component="div" className="error-msg" />                                
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Registration number for regulatory body</label>
                              <Field type="text" name="anotherRegulatoryNumber" className="form-control" placeholder="Enter number" />
                              <ErrorMessage name="anotherRegulatoryNumber" component="div" className="error-msg" />                                
                            </div>
                          </div>
                        </div>
                        <h4 className="py-4">Confirmation checklist of revalidation requirements</h4>
                        <h5 className="mb-3">Practice hours</h5>
                        <div className="form-check custom-form-check mb-4">
                            <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-1" value="You have seen written evidence that satisfies you that the nurse, midwife or nursing associate has practised the minimum number of hours required for their registration" />
                            <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-1">
                              You have seen written evidence that satisfies you that the nurse, midwife or nursing associate has practised the minimum number of hours required for their registration
                            </label>                            
                        </div>
                        <h5 className="mb-3">Continuing professional development</h5>
                        <div>
                          <div className="form-check custom-form-check mb-4">
                              <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-2" value="You have seen written evidence that satisfies you that the nurse, midwife or nursing associate has undertaken 35 hours of CPD relevant to their practice as a nurse, midwife or nursing associate" />
                              <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-2">
                                You have seen written evidence that satisfies you that the nurse, midwife or nursing associate has undertaken 35 hours of CPD relevant to their practice as a nurse, midwife or nursing associate
                              </label>                            
                          </div>
                          <div className="form-check custom-form-check mb-4">
                              <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-3" value="You have seen evidence that at least 20 of the 35 hours include participatory learning relevant to their practice as a nurse, midwife or nursing associate." />
                              <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-3">
                                You have seen evidence that at least 20 of the 35 hours include participatory learning relevant to their practice as a nurse, midwife or nursing associate.
                              </label>                            
                          </div>
                          <div className="form-check custom-form-check mb-4">
                              <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-4" value="You have seen accurate records of the CPD undertaken." />
                              <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-4">
                                You have seen accurate records of the CPD undertaken.
                              </label>                            
                          </div>
                        </div>
                        <h5 className="mb-3">Practice-related feedback</h5>
                        <div className="form-check custom-form-check mb-4">
                            <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-5" value="You are satisfied that the nurse, midwife or nursing associate has obtained five pieces of practice-related feedback." />
                            <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-5">
                              You are satisfied that the nurse, midwife or nursing associate has obtained five pieces of practice-related feedback.
                            </label>                            
                        </div>
                        <h5 className="mb-3">Written reflective accounts</h5>
                        <div className="form-check custom-form-check mb-4">
                            <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-6" value="You have seen five written reflective accounts on the nurse, midwife or nursing associate’s CPD and/or practice-related feedback and/or an event or experience in their practice and how this relates to the Code, recorded on the NMC form." />
                            <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-6">
                              You have seen five written reflective accounts on the nurse, midwife or nursing associate’s CPD and/or practice-related feedback and/or an event or experience in their practice and how this relates to the Code, recorded on the NMC form.
                            </label>                            
                        </div>
                        <h5 className="mb-3">Reflective discussion</h5>
                        <div>
                          <div className="form-check custom-form-check mb-4">
                              <Field className="form-check-input" type="checkbox" name="revalidation" id="revalidation-7" value="You have seen a completed and signed form showing that the nurse, midwife or nursing associate has discussed their reflective accounts with another NMC-registered individual(or you are an NMC-registered individual who has discussed these with the nurse, midwife or nursing associate yourself)." />
                              <label className="form-check-label font-18 family-poppins-medium" htmlFor="revalidation-7">
                                You have seen a completed and signed form showing that the nurse, midwife or nursing associate has discussed their reflective accounts with another NMC-registered individual(or you are an NMC-registered individual who has discussed these with the nurse, midwife or nursing associate yourself).
                              </label>                            
                          </div>
                          <p>I confirm that I have read Information for confirmers, and that the above named NMC-registered nurse, midwife or nursing associate has demonstrated to me that they have met all of the NMC revalidation requirements listed above during the three years since their registration was last renewed or they joined the register as set out in Information for confirmers.</p><br />
                          <p>I agree to be contacted by the NMC to provide further information if necessary for verification purposes. I am aware that if I do not respond to a request for verification information I may put the nurse, midwife or nursing associate’s registration application at risk.</p>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label className="color-grey">Signature</label>
                              <Field as='textarea' rows={5} className="flex-fill form-control form-control-lg rounded-lg" name="signature" placeholder="Enter your text" />
                              <ErrorMessage name="signature" component="div" className="error-msg" />                                
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-5">
                              <label htmlFor="typesFeedback" className="color-grey">Date</label>
                              <Field type="text" name="finalDate" className="form-control" placeholder="Enter Date" />
                              <ErrorMessage name="finalDate" component="div" className="error-msg" />                                  
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */ 
ConfForm.propTypes = {
//   bookLets: array.isRequired,
//   getBooklets: func.isRequired,
//   isFetchingBooklets: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
//   bookLets: getBookLetsListing(),
//   isFetchingBooklets: getIsFetchingResources(),
});

export default connect(
  mapStateToProps,
  {
    // getBooklets
  }
)(withRouter(ConfForm));
