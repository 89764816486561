/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const updatePractice = () => state => state.get('practice');

/**
 *  Select the correct portion of the root reducer
 */
export const getPracticeListing = () =>
  createSelector(updatePractice(), state => {
    const listing = state.get('practiceList').toJS()
    return listing ? listing : {}
  });

/**
 *  Select is fetching Practice
 */
export const getIsFetchingPractice = () =>
  createSelector(updatePractice(), state => {
    return state.get('isFetchingPractice')
  })

/**
 *  Select the correct portion of the root reducer
 */
export const getSelectPracticeListing = () =>
  createSelector(updatePractice(), state => {
    const listing = state.get('practiceListSelect').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching Select Practice
 */
export const getIsFetchingSelectPractice = () =>
  createSelector(updatePractice(), state => {
    return state.get('isFetchingPracticeSelect')
  })


/**
 *  Select is fetching Practice
 */
export const getIsDeletingEvent = () =>
  createSelector(updatePractice(), state => {
    return state.get('isDeletingEvent')
  })

/**
 *  Select total number of Practice pages
 */
export const getTotalPages = () =>
  createSelector(updatePractice(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current event pages
 */
export const getEventPage = () =>
  createSelector(updatePractice(), state => {
    return state.get('page')
  })
