import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { withRouter, Link } from 'react-router-dom';
import { getLocalStorage, deleteLocalStorage, isDisplayRoute, getAllowedRouteList } from '../../utils/helper';
import { showSuccessMsg } from 'utils/notification';
import routes from "routes.js";
import Dropdown from 'react-bootstrap/Dropdown';

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="ls-dropdown-outer">
          <div className="ls-dropdown-inner">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </div>
        </div>
      </div>
    );
  },
);


class UserHeader extends React.Component {
  state = {
    userProfile: JSON.parse(getLocalStorage())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userProfile) {
      return {
        userProfile: nextProps.userProfile,
      };
    }
   }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  // creates the links that appear in the top menu
  createLinks = routes => {
    const menus = getAllowedRouteList(routes);
    return menus.map((prop, key) => {
      const node = ReactDOM.findDOMNode(this);
      // add d-block class to more menu div
      if (node instanceof HTMLElement && key + 1 > 15) {
        const moreMenu = node.querySelector('.more-menu');
        if(moreMenu) {
          moreMenu.classList.add('d-block');
        }
      }

      // Check style and display more menu li
      if (menus.length === key+1 && key + 1 <= 15 && node instanceof HTMLElement) {
        const moreMenus = node.querySelectorAll('.outer-menu');
        for (let index = 0; index < moreMenus.length; index++) {
          const element = moreMenus[index];
          const computedEle = window.getComputedStyle(element);
          if(computedEle && computedEle.display && computedEle.display === 'none') {
            const moreMenu = node.querySelector('.more-menu');
            if(moreMenu) {
              moreMenu.classList.add('d-block');
            }
          }
        }
      }

      return (
        <li className={`px-3 outer-menu menu-item-${key + 1}`} key={key}>
          <Link to={prop.layout + prop.path} className={`${this.activeRoute(prop.layout + prop.path)} px-2 text-nowrap`}>{prop.name}</Link>
        </li>
      );
    });
  };

  createDropdownLinks = routes => {
    let loopIndex = 0;
    return routes.map((prop, key) => {
      if (isDisplayRoute(prop.path)) {
        loopIndex++;
        return (
          <Link key={key} to={prop.layout + prop.path} className={`${this.activeRoute(prop.layout + prop.path)} dropdown-item dropdown-item-${loopIndex}`}>
            <span className="d-block border-bottom ls-border-color">{prop.name}</span>
          </Link>
        );
      }
      return null;
    });
  };

  dropdownKeyClasses = key => {
    if (key <= 2) {
      return 'd-sm-none';
    }
    if (key > 2 && key <= 5) {
      return 'd-lg-none';
    }
    if (key > 5) {
      return 'hide-desktop';
    }

    return '';
  };

  logout = () => {
    const { history } = this.props;
    deleteLocalStorage();
    showSuccessMsg("Logout successfully");
    if (history) {
      history.push('/auth/login');
    }
  };

  render() {
    const { userProfile } = this.state;
    return (
      <>
        <header>
          <div className="container-fluid header-top bg-white border-bottom ls-border-color px-md-5 px-3">
            <div className="d-flex py-md-3 py-2 justify-content-between align-items-center header-top-inner">
              <Link to="/user/index" className="d-inline-flex">
                <img className="logo" src={require("assets/images/header-logo.png")} alt="logo" />
              </Link>
              <div className="d-inline-flex align-items-center">
                {/* User List */}
                {userProfile &&  ((userProfile.role === 'admin') || (userProfile.occupation === 'Practice Manager' && userProfile.org_id)) && (  
                  <div className="top-header-icon chat-icon mr-4 mr-sm-5">
                    <Dropdown alignRight>
                      <Dropdown.Toggle disabled={true} as={'div'} id="dropdown-custom-components">
                        <div>
                          <i className="fas fa-users-cog" />
                        </div>
                        {/* Uncomment below html if you wanna show count on icon */}
                        {/* <span className="notification-count">2</span> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-menu mt-4 dropdown-menu-right ls-dropdown-style" as={CustomMenu}>
                        <Link to="/user/users" className="dropdown-item">
                          <span className="d-block">List Users</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {/* Chat */}
                {/* <div className="top-header-icon chat-icon">
                  <Dropdown alignRight>
                    <Dropdown.Toggle disabled={true} className="pr-sm-5 pr-4" as={'div'} id="dropdown-custom-components">
                      <div>
                        <i className="fas fa-comments" />
                      </div>
                      <span className="notification-count">2</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu mt-3 dropdown-menu-right ls-dropdown-style" as={CustomMenu}>
                      <Link to="/user/forums" className="dropdown-item">
                        <span className="d-block">Chat</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                
                {/* Notification */}
                <div className="top-header-icon notification-icon">
                  <Dropdown>
                    <Dropdown.Toggle disabled={true} className="d-flex pr-sm-5 pr-4" as={'div'} id="dropdown-custom-components">
                      <div>
                        <Link to={`/user/news`}>
                        <i className="fas fa-newspaper"  style={{color:"#4ccafc"}} data-toggle="tooltip" title='News' />
                        </Link>
                      </div>
                      {/* Uncomment below html if you wanna show count on icon */}
                      {/* <span className="notification-count">2</span> */}
                    </Dropdown.Toggle>                    
                  </Dropdown>
                </div>
                <div className="top-header-icon notification-icon">
                  <Dropdown>
                    <Dropdown.Toggle disabled={true} className="d-flex pr-sm-5 pr-4" as={'div'} id="dropdown-custom-components">
                      <div>
                        <Link to={`/user/events`}>
                        <i className="fa fa-calendar-alt"  style={{color:"#4ccafc"}} data-toggle="tooltip" title='Events' />
                        </Link>
                      </div>
                      {/* Uncomment below html if you wanna show count on icon */}
                      {/* <span className="notification-count">2</span> */}
                    </Dropdown.Toggle>                    
                  </Dropdown>
                </div>
                <div className="top-header-icon notification-icon">
                  <Dropdown>
                    <Dropdown.Toggle disabled={true} className="d-flex pr-sm-5 pr-4" as={'div'} id="dropdown-custom-components">
                      <div>
                        <Link to={`/user/guidelines`}>
                          <i className="fas fa-file" style={{color:"#4ccafc"}} data-toggle="tooltip" title='Guidelines' />
                        </Link>
                      </div>
                      {/* Uncomment below html if you wanna show count on icon */}
                      {/* <span className="notification-count">2</span> */}
                    </Dropdown.Toggle>                    
                  </Dropdown>
                </div>
                <div className="top-header-icon notification-icon">
                  <Dropdown>
                    <Dropdown.Toggle disabled={true} className="d-flex pr-sm-5 pr-4" as={'div'} id="dropdown-custom-components">
                      <div>
                        <i className="fas fa-bell" />
                      </div>
                      {/* Uncomment below html if you wanna show count on icon */}
                      {/* <span className="notification-count">2</span> */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu mt-3 dropdown-menu-right ls-dropdown-style" as={CustomMenu}>
                      <div className="p-4">No notification found</div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* Resources */}
                {userProfile && (userProfile.role === 'staff') && (
                  <div className="top-header-icon notification-icon">
                    <Dropdown>
                      <Dropdown.Toggle disabled={true} className="d-flex pr-4 pr-sm-5" as={'div'} id="dropdown-custom-components">
                        <div>
                          <i className="fas fa-tasks" />
                        </div>
                        {/* Uncomment below html if you wanna show count on icon */}
                        {/* <span className="notification-count">2</span> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-menu mt-3 dropdown-menu-right ls-dropdown-style" as={CustomMenu}>
                        {/* <Link to="/user/supervisor/user-listing" className="dropdown-item">
                          <span className="d-block border-bottom ls-border-color">Supervisor Resources</span>
                        </Link> */}
                        <Link to="/user/assessor/user-listing" className="dropdown-item">
                        <span className="d-block">Assessor Resources</span>
                        </Link>
						<Link to="/user/appraisals/supervisor" className="dropdown-item">
                        <span className="d-block">Supervisor Appraisals</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                
                {/* User info code */}
                <div className="user-info d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle className="d-flex" as={'div'} id="dropdown-custom-components">
                      <div className="profile-img">
                        <img src={userProfile.profile_image_url} className="dropdown-toggle" alt="user profile" />
                      </div>
                      <div className="pl-2 user-name d-none d-md-block">
                        <p className="mb-0 font-12">Welcome!</p>
                        <h6 className="family-poppins-medium font-16 mb-0">{userProfile.first_name + ' ' + userProfile.last_name}</h6>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu mt-3 dropdown-menu-right ls-dropdown-style" as={CustomMenu}>
                      {userProfile && (userProfile.role === 'student' || userProfile.role === 'staff') && (
                        <Link to="/user/user-profile" className="dropdown-item">
                          <span className="d-block border-bottom ls-border-color">Profile</span>
                        </Link>
                      )}
                      {userProfile && (userProfile.role === 'staff' || userProfile.role === 'practice') && (
                        <Link to="/user/audit-tool" className="dropdown-item">
                          <span className="d-block border-bottom ls-border-color">Audit Tool</span>
                        </Link>
                      )}
                      {userProfile &&  ((userProfile.occupation === 'Practice Manager' && userProfile.practice && userProfile.practice.id )) && ( 
                        <Link to={`/user/update-practice-info/${userProfile.practice.id}`} className="dropdown-item">
                          <span className="d-block border-bottom ls-border-color">Update Practice Info</span>
                        </Link>
                      )}
                      <Link
                        to="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            this.logout();
                          }
                        }
                        className="dropdown-item"
                      >
                        <span className="d-block">Sign Out</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          {/* Nav bar code (Menu bar)*/}
          <div className="container-fluid header-bottom bg-white px-md-5 px-3">
            <ul className="main-nav d-inline-flex mb-0 py-md-4 py-3 my-md-2 my-1 pl-0">
              {/* Nomal menu without dropdown */}
              {this.createLinks(routes)}

              {/* Nomal menu with dropdown responsive */}
              <li className="px-3 more-menu position-relative">
                <Dropdown>
                  <Dropdown.Toggle className="px-2 dropdown-toggle cursor-pointer" as={'a'} id="dropdown-custom-components">
                    More <i className="fas fa-caret-down" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu mt-4 dropdown-menu-right ls-dropdown-style" as={CustomMenu}>
                    {this.createDropdownLinks(routes)}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(UserHeader);
