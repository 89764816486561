/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_COMMENTS,
  POST_SINGLE_COMMENT,
  RESET_FILTERS,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 3,
  page: 1,
  totalComments: 0,
  totalPage: 0,
  comments: [],
  isFetchingComments: true,
  isPostingComments: false,
});

/**
 *  Define the reducer with actions
 */
function commentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_COMMENTS:
      return fromJS({
        ...state.toJS(),
        isFetchingComments: true,
      })

    case success(GET_LIST_OF_COMMENTS):
      const { data } = action.payload.data;
      const { total_pages, total } = action.payload.data.meta.pagination;
      const tempComments = state.get('comments').toJS();
      return fromJS({
        ...state.toJS(),
        comments: tempComments.concat(data),
        totalComments: total,
        totalPage: total_pages,
        isFetchingComments: false,
      })

    case failure(GET_LIST_OF_COMMENTS):
      return fromJS({
        ...state.toJS(),
        isFetchingComments: false,
      })
    
    case POST_SINGLE_COMMENT:
      return fromJS({
        ...state.toJS(),
        isPostingComments: true,
      })

    case success(POST_SINGLE_COMMENT):
      const tempCommentsToAdd = state.get('comments').toJS();
      const tempData = action.payload.data.data;

      console.log(action.payload.data);
      return fromJS({
        ...state.toJS(),
        comments: [tempData].concat(tempCommentsToAdd),
        isPostingComments: false,
      })

    case failure(POST_SINGLE_COMMENT):
      return fromJS({
        ...state.toJS(),
        isPostingComments: false,
      })

    case APPLY_PAGINATION:
      return fromJS({
        ...state.toJS(),
        page: action.page
      })

    case RESET_FILTERS:
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...state.toJS(),
        limit: 3,
        page: 1,
        totalPage: 0,
        totalComments: 0,
        comments: [],
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default commentsReducer
