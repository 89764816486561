import React from "react";
import Modal from 'react-bootstrap/Modal';
import { func, bool, object, array } from "prop-types";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { jobTitles } from './filters';
import * as Datetime from 'react-datetime';
import * as moment from 'moment';

const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  // place: Yup.string()
  //   .required('Required'),
  gender: Yup.string()
    .nullable()
    .required('Required'),
  // pin: Yup.string()
  //   .required('Required'),
});

const jobFormattedArray = array => {
  return array.map(item => {
    return {
      label: `${item}`,
      value: `${item}`
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const jobCustomStyles = {
  control: () => ({"alignItems":"center","backgroundColor":"hsl(0,0%,100%)","borderColor":"transparent","borderRadius":"4px","borderStyle":"solid","borderWidth":"1px","cursor":"default","display":"flex","WebkitFlexWrap":"wrap","MsFlexWrap":"wrap","flexWrap":"wrap","WebkitBoxPack":"justify","WebkitJustifyContent":"space-between","MsFlexPack":"justify","justifyContent":"space-between","minHeight":"38px","outline":"0 !important","position":"relative","WebkitTransition":"all 100ms","transition":"all 100ms","boxSizing":"border-box", "padding":"0 .75rem"}),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};


const jobFormSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="p-0 mb-2"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if(field.name === 'occupation'){
          form.setFieldValue('occupation', value.value)
        }
      }}
      value={field.value ? {id: field.id, label: field.value, value: field.value} : ''}
      styles={jobCustomStyles}
      options={jobFormattedArray(options)}
    />
  )
};

const genderSelect = props => {
  const { form, options, field } = props;
  return (
    <Select
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      placeholder="Gender"
      onChange={value => {
        form.setFieldValue('gender', value.value)
      }}
      value={field.value ? options.find(option => option.value === field.value) : ''}
      styles={customStyles}
      options={options}
    />
  )
};

class EditProfile extends React.Component {
  updateProfile = (values, { setSubmitting }) => {
    const { updateProfile, onCompleted, onClose } = this.props;
    var params = {
      first_name: values.firstName,
      last_name: values.lastName,
      gender: values.gender,
      pin: values.pin,
      date_of_revalidation: values.date_of_revalidation ? moment(values.date_of_revalidation).format("YYYY-MM-DD HH:mm:ss") : null,
      date_of_med_registration:  values.date_of_med_registration ? moment(values.date_of_med_registration).format("YYYY-MM-DD HH:mm:ss") : null,
      occupation: values.occupation,
      education_inst_id: Number(values.place)
    };
    updateProfile(params).then(res => {
      const { message } = res;
      setSubmitting(false);
      onCompleted(res);
      onClose();
    }, err => {
      setSubmitting(false);
    });
  };

  render() {
    const { isVisible, onClose, userProfile} = this.props;
    const {
      first_name,
      last_name,
      gender,
      pin,
      has_pin,
      occupation,
      educational_institution_id,
      date_of_med_registration,
      date_of_revalidation,
    } = userProfile;
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header className="background-primary justify-content-center">
            <Modal.Title className="color-white">Edit Profile</Modal.Title>
          </Modal.Header>
          {/* <section className="register-login" style={{minHeight: 'auto'}}>
            <div className="right-side m-0 p-0" style={{width: 'auto'}}> */}
              <Formik
                initialValues={{
                  firstName: first_name,
                  lastName: last_name,
                  occupation: occupation,
                  gender: gender ? gender.toLowerCase() : gender ,
                  date_of_med_registration: date_of_med_registration,
                  date_of_revalidation: date_of_revalidation,
                  has_pin: has_pin,
                  pin: pin,
                  place: educational_institution_id
                }}
                validationSchema={editProfileSchema}
                onSubmit={this.updateProfile}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="ls-form pt-2">
                    <Modal.Body>
                      <div className="form-group">
                        <Field type="text" name="firstName" className="form-control" placeholder="First Name" />
                        {/* <i className="far fa-user"></i> */}
                      </div>
                      <ErrorMessage name="firstName" component="div" className="error-msg pl-3 mt-2" />
                      <div className="form-group">
                        <Field type="text" name="lastName" className="form-control" placeholder="Last Name" />
                        {/* <i className="far fa-user"></i> */}
                      </div>
                      <ErrorMessage name="lastName" component="div" className="error-msg pl-3 mt-2" />
                      {/* <div className="form-group">
                        <Field as="select" name="occupation" placeholder="Search Job Title" component={jobFormSelect} options={jobTitles} />
                        <ErrorMessage name="occupation" component="div" className="error-msg pl-3 mt-2" />
                      </div> */}
                      {has_pin && (
                          <React.Fragment>
                          <div className="form-group">
                            <Field type="text" name="pin" className="form-control" placeholder="PIN" />
                            {/* <i className="far fa-user"></i> */}
                            <ErrorMessage name="pin" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                          <div className="form-group">
                              <Datetime
                                inputProps={{placeholder: 'Select Registration Date'}}
                                renderInput={ this.renderCustomInput }
                                closeOnSelect={true}
                              //  isValidDate={ valid }
                                onChange={date_of_med_registration => {
                                  if (typeof date_of_med_registration !== "object") return;
                                  setFieldValue('date_of_med_registration', date_of_med_registration)
                                }}
                                value={values['date_of_med_registration']}
                              />
                              <ErrorMessage name="date_of_med_registration" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                          <div className="form-group">
                              <Datetime
                                inputProps={{placeholder: 'Select Revalidation Date'}}
                                renderInput={ this.renderCustomInput }
                                closeOnSelect={true}
                              //  isValidDate={ valid }
                                onChange={date_of_revalidation => {
                                  if (typeof date_of_revalidation !== "object") return;
                                  setFieldValue('date_of_revalidation', date_of_revalidation)
                                }}
                                value={values['date_of_revalidation']}
                              />
                              <ErrorMessage name="date_of_revalidation" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                        </React.Fragment>
                      )}
                      {/* <div className="form-group">
                        <Field as="select" name="place" component={formSelect} options={instituteList} />
                        <i className="fas fa-school"></i>
                      </div>
                      <ErrorMessage name="place" component="div" className="error-msg pl-3 mt-2" /> */}
                      <div className="form-group">
                        <Field as="select" name="gender" component={genderSelect} options={[
                          {
                            label: 'Male',
                            value: 'male'
                          },
                          {
                            label: 'Female',
                            value: 'female'
                          }
                        ]} />
                        {/* <i className="fa fa-venus"></i> */}
                      </div>
                      <ErrorMessage name="gender" component="div" className="error-msg pl-3 mt-2" />
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                      <div className="row pb-3 w-100">
                        <div className="col-md-6">
                          <button disabled={isSubmitting} className="btn btn-secondary w-100" onClick={onClose}>
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" disabled={isSubmitting} className="btn btn-primary lS-btn-blue-style w-100">
                            {
                              isSubmitting && (
                                <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                            )}
                            Update
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            {/* </div>
          </section> */}
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
EditProfile.propTypes = {
  instituteList: array.isRequired,
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  onCompleted: func.isRequired,
  updateProfile: func.isRequired,
  userProfile: object.isRequired
};

export default EditProfile;
