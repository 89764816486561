/**
 *  Import action creator constants & dependencies
 */
import {
    GET_USERS,
    // GET_SINGLE_JOB,
    // GET_LIST_OF_JOB_OCCUPATIONS,
    APPLY_FILTERS,
    // SELECT_CHECKBOX,
    // UNSELECT_CHECKBOX,
    // APPLY_PAGINATION,
    RESET_FILTERS,
    APPLY_PAGINATION,
    CANCEL_ALL_API_REQUESTS,
    // APPLY_JOB,
    // GET_LIST_OF_JOB_APPLICANTS,
    // UPDATE_JOB_APPLICANT_STATUS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';

const cancelApiRequests = [];


/**
 *  Get List of users
 */
export function getUsers(categoryId) {
    return async(dispatch, getState) => {
        const state = getState();
        const { limit, page, search } = state.get('teams').toJS();
        const source = axios.CancelToken.source();
        const params = {
            page,
            limit,
            filters: {}
        };

        // Apply when search has any value
        if (search) {
            params.filters.search = search;
        }


        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getUsersBegin(
                    API_URLS.TEAM.GET_USERS_LIST,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getUsersBegin = (API_URL, params, source) => ({
    type: GET_USERS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
    return async dispatch => {
        dispatch(applyPaginationBegin(page));
        dispatch(getUsers());
    };
};

export const applyPaginationBegin = page => ({
    type: APPLY_PAGINATION,
    page
});

/**
 *  Apply filters
 */
export function applyFilters(search) {
    return async dispatch => {
        dispatch(applyFiltersBegin(search));
        dispatch(getUsers());
    };
};

export const applyFiltersBegin = (search) => ({
    type: APPLY_FILTERS,
    search
});

/**
 *  Reset filters
 */
export function resetFilters() {
    return async dispatch => {
        dispatch(resetFiltersBegin());
        dispatch(getUsers());
    };
};

export const resetFiltersBegin = () => ({
    type: RESET_FILTERS
});