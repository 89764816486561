import React from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { bands, occupations, userRoles, managerUserRoles } from './addUserFilters';
import * as Yup from 'yup';
import { showSuccessMsg } from 'utils/notification';
import { getLocalStorage } from '../../utils/helper';

/**
 *  Import other dependencies
 */
import { 
  getListOfOrganisation,
  addUser
} from '../../modules/adminAddUser/actions';
import {
  getOrganisationListing,
  getIsFetchingOrganisation
} from '../../modules/adminAddUser/selectors';

const addUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  confirmEmail: Yup.string()
    .email('Invalid email')
    .required('Required')
    .oneOf([Yup.ref('email'), null], "Email must match"),
  userType: Yup.string()
    .required('Required'),
  organisation: Yup.string()
    .required('Required'),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const formattedOrgArray = array => {
  return array.map(item => {
    return {
      label: item.attributes.name,
      value: item.id
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 58,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 58,
    padding: '11px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: 'inherit',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  const userProfile = JSON.parse(getLocalStorage());
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if (props.isFetchingOrganisation) return;
        if (field.name === 'userType') {
          form.setFieldValue('userType', value.value);
        }
        if (field.name === 'jobTitle') {
          form.setFieldValue('jobTitle', value.value);
        }
        if (field.name === 'band') {
          form.setFieldValue('band', value.value);
        }
        if( field.name === 'userType' && value.value) {
          form.setFieldValue('organisation', '');
          props.getListOfOrganisation(getOrgtype(value.value), userProfile.org_id);
        };
      }} 
      value={field.value ? formattedArray([field.value]) : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

const formSelectOrg = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue('organisation', value.value);
      }} 
      value={field.value ? formattedOrgArray(options.filter(option => Number(option.id) === Number(field.value))) : ''}
      styles={customStyles}
      options={formattedOrgArray(options)}
    />
  )
};

const getOrgtype = value => {
  if (!value) return;

  switch (value) {
    case 'educator':
      return 'education_institution';
    case 'manager':
      return 'practice';
    case 'federation':
      return 'federation';
    case 'staff':
      return 'practice';
    default:
      break;
  }
};

class AdminAddUser extends React.Component {
  state = {
    userProfile: JSON.parse(getLocalStorage()),
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };
  
  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getTrainings(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/trainings');
        }
      });
    }
  }

  getFormikInitVal = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      userType: '',
      organisation: '',
      jobTitle: '',
      band: '',
    }
  };

  postJob = (values, { setSubmitting, resetForm }) => {
    const { addUser, history } = this.props;
    var params = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      role: values.userType,
      org_id: values.organisation,
    };
    if (values.jobTitle) {
      params.job_title = values.jobTitle;
    }
    if (values.band) {
      params.band = values.band;
    }

    addUser(params).then(res => {
      const { message } = res;
      showSuccessMsg(message);
      resetForm(this.getFormikInitVal);
      setSubmitting(false);
      if (history) {
        history.push('/user/users');
      }
    }, err => {
      setSubmitting(false);
    });
  };

  render() {
    const {
      organisation,
      isFetchingOrganisation
    } = this.props;

    const {
      userProfile
    } = this.state;
    
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold">Add User</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
              </div>
            </div>
          </div>

          {/* Form */}
          <div className="card my-5 shadow-sm border-0 rounded-lg">
            <div className="card-body">
              <Formik
                initialValues={this.getFormikInitVal()}
                validationSchema={addUserSchema}
                onSubmit={this.postJob}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="ls-form pt-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor className="color-grey pl-3">First Name</label>
                          <Field type="text" name="firstName" className="form-control form-control-lg rounded-lg" placeholder="Type Here..." />
                          <ErrorMessage name="firstName" component="div" className="error-msg pl-3 mt-2" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor className="color-grey pl-3">Last Name</label>
                          <Field type="text" name="lastName" className="form-control form-control-lg rounded-lg" placeholder="Type Here..." />
                          <ErrorMessage name="lastName" component="div" className="error-msg pl-3 mt-2" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor className="color-grey pl-3">Email</label>
                          <Field type="email" name="email" className="form-control form-control-lg rounded-lg" placeholder="Type Here..." />
                          <ErrorMessage name="email" component="div" className="error-msg pl-3 mt-2" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor className="color-grey pl-3">Confirm Email</label>
                          <Field type="text" name="confirmEmail" className="form-control form-control-lg rounded-lg" placeholder="Type Here..." />
                          <ErrorMessage name="confirmEmail" component="div" className="error-msg pl-3 mt-2" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor className="color-grey pl-3">User Type</label>
                          <Field as="select" name="userType" placeholder="Select Types" 
                            component={formSelect} 
                            options={ userProfile.occupation === 'Practice Manager' ? managerUserRoles : userRoles} {...this.props}/>
                          <ErrorMessage name="userType" component="div" className="error-msg pl-3 mt-2" />
                        </div>
                      </div>
                      {!isFetchingOrganisation && organisation && organisation.length > 0 && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor className="color-grey pl-3">User Organisation</label>
                            <Field as="select" name="organisation" placeholder="Select Types" component={formSelectOrg} options={organisation} />
                            <ErrorMessage name="organisation" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                        </div>
                      )}
                      {isFetchingOrganisation && (
                        <div className="col-md-6">
                          <div className="text-center">  
                            <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {values['userType'] && values['userType'] === 'staff' && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor className="color-grey pl-3">Job title</label>
                            <Field as="select" name="jobTitle" placeholder="Select Types" component={formSelect} options={occupations} />
                            <ErrorMessage name="jobTitle" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                        </div>
                      )}
                      {values['userType'] && values['userType'] === 'staff' && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor className="color-grey pl-3">Band</label>
                            <Field as="select" name="band" placeholder="Select Types" component={formSelect} options={bands} />
                            <ErrorMessage name="band" component="div" className="error-msg pl-3 mt-2" />
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="text-md-right text-center">
                          <button type="submit" disabled={isSubmitting || isFetchingOrganisation} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 my-3">
                            { 
                              isSubmitting && (
                                <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                            )}
                            Post
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        <UserFooter />
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
AdminAddUser.propTypes = {
  addUser: func.isRequired,
  isFetchingOrganisation: bool.isRequired,
  organisation: object.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isFetchingOrganisation: getIsFetchingOrganisation(),
  organisation: getOrganisationListing()
});

export default connect(
  mapStateToProps,
  {
    getListOfOrganisation,
    addUser,
  }
)(withRouter(AdminAddUser));
