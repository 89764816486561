const regions = [
    'East London',
    'North West London',
    'South West London',
    'North London',
    'South East London',
  ];

  const groups = [
	'Administrator',
    'Admin Manager',
    'Advance Clinical Practitioner',
    'Advance Nurse Practitioner',
    'Business Manager',
    'Clinical Nurse Specialist',
    'Clinical Director',
    'Clinical Pharmacist',    
    'Clinical Leads',
    'Community Nurse',
    'Community Pharmacist',
    'Deputy Manager',
    'Director',
    'District Nurse',
    'General Practice Nurse',
    'General Practitioner',
    'Health Visitor',
    'Healthcare Assistant',
    'GP Network Team Leader',
    'GP Partner',
    'GP Network Administrator',
    'Medical Student',
    'Managing Director',
    'Medical Director',
    'Midwife',
    'Nursing Associate',
    'Operations Manager',
    'Secretary',
    'Paramedic',
    'Physiotherapist',
    'PCN Development Manager',
    'Podiatrist',
    'Project Manager',
    'Project Officer',
    'Receptionist',
    'School Nurse',
    'Student Nurse ',
    'Student Midwife',
  ];

  export {
	regions,
	groups,
  };
