/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_JOBS,
  GET_SINGLE_JOB,
  GET_LIST_OF_JOB_OCCUPATIONS,
  APPLY_FILTERS,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_PAGINATION,
  RESET_FILTERS,
  CANCEL_ALL_API_REQUESTS,
  APPLY_JOB,
  GET_LIST_OF_JOB_APPLICANTS,
  UPDATE_JOB_APPLICANT_STATUS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Jobs
 */
export function getJobs() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, search, minSalary, maxSalary, selectedOccupations, selectedLocations, selectedTypes } = state.get('jobs').toJS();
    const params ={
      page,
      limit,
      filters: {
        // salary: {
        //   min: minSalary,
        //   max: maxSalary
        // }
      }
    };

    if (search) {
      params.filters.search = search;
    }

    if (minSalary) {
      params.filters.min = minSalary;
    }

    if (maxSalary) {
      params.filters.max = maxSalary;
    }

    // Apply Occupations filtes
    if (selectedOccupations.length > 0) {
      params.filters.occupation = selectedOccupations;
    }

    // Apply Locations filtes
    if (selectedLocations.length > 0) {
      params.filters.region = selectedLocations;
    }
    // Apply Types filtes
    if (selectedTypes.length > 0) {
      params.filters.type = selectedTypes;
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getJobsBegin(
          API_URLS.JOBS.GET_JOBS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getJobsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_JOBS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get Single Job
 */
export function getSingleJob(jobID) {
  return async dispatch => {

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSingleJobBegin(
          API_URLS.JOBS.GET_SINGLE_JOB + jobID,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSingleJobBegin = (API_URL, source) => ({
  type: GET_SINGLE_JOB,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      cancelToken: source.token
    }
  }
});

/**
 *  Add List of Post Invoice
 */
export function applyJob(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        applyJobBegin(
          API_URLS.JOBS.APPLY_JOB,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const applyJobBegin = (API_URL, params, source) => ({
  type: APPLY_JOB,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Get List of Job applicants
 */
export function getJobApplicants(params) {
  return async (dispatch, getState) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getJobApplicantsBegin(
          API_URLS.JOBS.GET_JOB_APPLICANTS,
          params,
          source
        )
      );
      
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getJobApplicantsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_JOB_APPLICANTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Job Occupations
 */
export function getJobsOccupations() {
  return async (dispatch, getState) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getJobsOccupationsBegin(
          API_URLS.JOBS.GET_JOBS_OCCUPATIONS,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getJobsOccupationsBegin = (API_URL, source) => ({
  type: GET_LIST_OF_JOB_OCCUPATIONS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Apply jobs filters
 */
export function applyFilters(params) {
  return async dispatch => {
    dispatch(applyFiltersBegin(params));
    dispatch(getJobs());
  };
};

export const applyFiltersBegin = (params) => ({
  type: APPLY_FILTERS,
  params
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getJobs());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Reset filters
 */
export function resetFilters() {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getJobs());
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

/**
 *  Apply jobs filters
 */
export function checkUnCheckFilters(name, checkBoxValue, value) {
  return dispatch => {
    let arrayKeyName;
    switch (name) {
      case 'jobType':
        arrayKeyName = 'selectedTypes';
        break;
      case 'location':
        arrayKeyName = 'selectedLocations';
        break;
      case 'occupation':
        arrayKeyName = 'selectedOccupations';
        break;
      default:
        break;
    };
    checkBoxValue ? dispatch(selectCheckbox(arrayKeyName, value)) : dispatch(unSelectCheckbox(arrayKeyName, value));
    return true;
  };
};

export const selectCheckbox = (arrayKeyName, value) => ({
  type: SELECT_CHECKBOX,
  arrayKeyName,
  value,
});

export const unSelectCheckbox = (arrayKeyName, value) => ({
  type: UNSELECT_CHECKBOX,
  arrayKeyName,
  value,
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


/**
 *  Update Job applicant status
 */
export function updateJobApplicantStatus(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateJobApplicantStatusBegin(
          API_URLS.JOBS.UPDATE_JOB_APPLICANT_STATUS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateJobApplicantStatusBegin = (API_URL, params, source) => ({
  type: UPDATE_JOB_APPLICANT_STATUS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});