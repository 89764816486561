/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_TRAINING_ANALYSIS_REPORT,
  DOWNLOAD_REPORT,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  analysisReport: [],
  limit: 10,
  page: 1,
  isFetchingAnalysisReport: false,
  isDownloadingReport: false,
});

/**
 *  Define the reducer with actions
 */
function trainingsAnalysisReportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRAINING_ANALYSIS_REPORT:
      return fromJS({
        ...state.toJS(),
        isFetchingAnalysisReport: true,
      })

    case success(GET_TRAINING_ANALYSIS_REPORT):
      const { data } = action.payload.data;
      return fromJS({
        ...state.toJS(),
        analysisReport: data,
        isFetchingAnalysisReport: false,
      })

    case failure(GET_TRAINING_ANALYSIS_REPORT):
      return fromJS({
        ...state.toJS(),
        isFetchingAnalysisReport: false,
      })

    case DOWNLOAD_REPORT:
      return fromJS({
        ...state.toJS(),
        isDownloadingReport: true,
      })

    case success(DOWNLOAD_REPORT):
    case failure(DOWNLOAD_REPORT):   
      return fromJS({
        ...state.toJS(),
        isDownloadingReport: false,
      })
  
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}


/**
 *  Export the reducer
 */
export default trainingsAnalysisReportReducer;
