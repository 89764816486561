/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const competencies = () => state => state.get('competencies');

/**
 *  Select the correct portion of the root reducer
 */
export const getCategoriesListing = () =>
  createSelector(competencies(), state => {
    const listing = state.get('categories').toJS()
    return listing.length > 0 ? listing : []
  });

/**
 *  Select is fetching placements
 */
export const getIsFetchingCategories = () =>
  createSelector(competencies(), state => {
    return state.get('isFetchingCategories')
  })


