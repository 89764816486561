/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_CATEGORIES,
  // GET_SINGLE_PLACEMENT,
  // GET_LIST_OF_PLACEMENT_OCCUPATIONS,
  // GET_LIST_OF_CATEGORIES_APPLICANTS,
  // SELECT_CHECKBOX,
  // UNSELECT_CHECKBOX,
  // APPLY_FILTERS,
  // RESET_FILTERS,
  // APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 10,
  page: 1,
  totalPage: 0,
  categories: [],
  // placementOccupations: [],
  // placementApplicants: [],
  // search: '',
  // range: 1000,
  // duration: [],
  // region: [],
  // type: [],
  // group: [],
  // selectedSearch:[],
  isFetchingCategories: true,
  // isFetchingSinglePlacement: true,
  // isFetchingPlacementApplicants: true,
  // singlePlacement: null,
  // occupationType: []
});

/**
 *  Define the reducer with actions
 */
function competenciesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_CATEGORIES:
      return fromJS({
        ...state.toJS(),
        isFetchingCategories: true,
      })

    case success(GET_LIST_OF_CATEGORIES):
      
      const { data } = action.payload.data;
      // const { total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        categories: data && data.length > 0 ? data : [],
        // totalPage: total_pages,
        isFetchingCategories: false,
      })

    case failure(GET_LIST_OF_CATEGORIES):
      return fromJS({
        ...state.toJS(),
        isFetchingCategories: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default competenciesReducer
