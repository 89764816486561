import React from "react";
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import { func, bool, object, number } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { showSuccessMsg } from 'utils/notification';
import * as Yup from 'yup';
import Select from 'react-select';
import { selfAssessment, evidence } from './taskFilters';

import * as Datetime from 'react-datetime';
import * as moment from 'moment';

import {
	uploadImage,
	updateSingleTask,
	getSingleBooklets,
} from '../../modules/resources/actions';
import {
  getIsUploadingImage,
} from '../../modules/resources/selectors';

const editSingleResourceSchema = Yup.object().shape({
  evidence: Yup.string()
    .required('Required'),
	// evidenceAttachment: Yup.string()
	// 	.required('Required'),
	reflection: Yup.string()
	.required('Required'),
	evidence_date: Yup.string()
		.required('Required'),
});

const formSelect = props => {
	const { form, options, placeholder, field } = props;
	if (options.length === 0) return;
	return (
	  <Select
		components={{ IndicatorSeparator:() => null }}
		className="form-control p-0 mb-2"
		isSearchable={true}
		placeholder={placeholder}
		onChange={value => {
		  if(field.name === 'self_assessment'){
			form.setFieldValue('self_assessment', value.value)
		  }
		  else{
			form.setFieldValue('evidence', value.value)
		  }
		}}
		value={field.value ? {label: field.value, value: field.value} : ''}
		styles={customStyles}
		options={formattedArray(options)}
	  />
	)
  };

const formattedArray = array => {
	return array.map(item => {
	  	return {
			label: `${item}`,
			value: `${item}`
		};
	});
  };

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#FFFFFF',
		borderColor: '#F9F9FC',
	}),
	control: styles => ({
		...styles,
		minHeight: 56,
		backgroundColor: '#FFFFFF',
		borderColor: '#F9F9FC',
		boxShadow: 'none',
	}),
	valueContainer: styles => ({
		...styles,
		minHeight: 56,
		padding: '8px 20px',
	}),
	indicatorsContainer: styles => ({
		...styles,
		padding: '0 8px',
		maxHeight: 30,
		alignSelf: 'auto',
	}),
	placeholder: () => ({
		color: '#BABBC2',
	}),
	singleValue: () => ({
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: 'inherit',
		fontSize: "18px",
		fontFamily: "Poppins-Medium",
	}),
	input: () => ({
		position: 'absolute',
		right: '0',
	}),
};

class EditSingleResource extends React.Component {
	getFormikInitVal = () => {
		const { singleTask } = this.props;
		const {
			competency,
			evidence,
			evidence_date,
			reflection,
			evidence_attachment_url,
			self_assessment
		} = singleTask.attributes || {};
    return {
      evidence: evidence ? evidence : "",
      evidence_date: evidence_date ? evidence_date : "",
      evidenceAttachment: evidence_attachment_url ? evidence_attachment_url : "",
	  reflection: reflection ? reflection : "",
	  self_assessment: self_assessment ? self_assessment : "",
	  competency: competency ? competency : "",
    }
	};

	handleFileUpload = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("file", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('evidenceAttachment', url);
    });
	};

	updateSingleTask = (values, { setSubmitting, resetForm }) => {
		const { bookLetID, bookLetIndexID, singleTask, updateSingleTask, getSingleBooklets } = this.props;
		const params = {
			booklet_id: bookLetID,
			booklet_index_id: bookLetIndexID,
			resource_task_id: Number(singleTask.id),
			evidence: values.evidence,
			evidence_date: values.evidence_date ? moment(values.evidence_date).format("YYYY-MM-DD HH:mm:ss") : null,
			evidence_url: values.evidenceAttachment,
			reflection: values.reflection,
			self_assessment: values.self_assessment
		};
		updateSingleTask(params).then(res => {
			const { message } = res;
			getSingleBooklets(bookLetID, bookLetIndexID);
			showSuccessMsg(message);
			setSubmitting(false);
			this.props.onClose();
		}, err => {
			setSubmitting(false);
		});
	};

	fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <div className="fileUploadCol">
            <label>Attachment</label>
          </div>
          <div className="position-relative fileUploadCol w-100">
            <span>1 Attachment attached</span>
            <div className="selectImgCross" onClick={() => form.setFieldValue('evidenceAttachment', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
		<div className="fileUploadCol position-relative">
			<label>Attachment</label>
			<input
				type="file"
				onChange={event => this.handleFileUpload(event, form.setFieldValue)}
			/>
			<span>Upload File</span>
		</div>
      </>
    )
  };

	render() {
		const {
			isVisible,
			isUploadingImage,
			onClose,
		} = this.props;
		return(
			<>
				<Modal show={isVisible} onHide={onClose} backdrop="static">
					<Modal.Header className="background-primary justify-content-center">
						<Modal.Title className="color-white">BookLet</Modal.Title>
					</Modal.Header>
					<Formik
						initialValues={this.getFormikInitVal()}
						validationSchema={editSingleResourceSchema}
						onSubmit={this.updateSingleTask}
					>
						{({ isSubmitting, setFieldValue, values }) => (
							<Form className="ls-form px-md-4 px-3 py-4">
								<Modal.Body>
										<div className="form-group">
										<label className="color-grey pl-3">Competency</label>
										<Field component="textarea" rows="5" name="competency" className="form-control" disabled placeholder="Type Here..." />
										{/* <ErrorMessage name="self_assessment" component="div" className="error-msg" /> */}
									</div> 
									{/* <div className="form-group">
										<label className="color-grey pl-3">Self Assessment</label>
										<Field as="select" name="self_assessment" placeholder="Self Assesment" component={formSelect} options={selfAssessment} />
										<ErrorMessage name="self_assessment" component="div" className="error-msg" />
									</div> */}
									<div className="form-group">
										<label className="color-grey pl-3">Evidence</label>
										<Field as="select" name="evidence" placeholder="Evidence" component={formSelect} options={evidence} />
										<ErrorMessage name="evidence" component="div" className="error-msg pl-3 mt-2" />
									</div>
									<div className="form-group form-input-white">
										<label className="color-grey pl-3">Evidence Date</label>
										<Datetime
	                                      inputProps={{placeholder: 'Select Date'}}
	                                      renderInput={ this.renderCustomInput }
	                                      closeOnSelect={true}
	                                     // isValidDate={ valid }
	                                      onChange={evidence_date => {
	                                        if (typeof evidence_date !== "object") return;
	                                        setFieldValue('evidence_date', evidence_date)
	                                      }}
	                                      value={values['evidence_date']}
	                                    />
	                                    <ErrorMessage name="evidence_date" component="div" className="error-msg pl-3 mt-2" />
									</div>
									{/* <div className="form-group">
										<label className="color-grey pl-3">Evidence</label>
										<Field type="text" name="evidence" className="form-control" placeholder="Type Here..." />
										<ErrorMessage name="evidence" component="div" className="error-msg" />
									</div> */}
									{/* <div className="form-group">
										<label className="color-grey pl-3">Self Assessment</label>
										<Field type="text" name="self_assessment" className="form-control" placeholder="Type Here..." />
										<ErrorMessage name="self_assessment" component="div" className="error-msg" />
									</div> */}
									<div className="form-group">
										<label className="color-grey pl-3">Reflection</label>
										<Field component="textarea" rows="5" name="reflection" className="form-control bg-white" placeholder="Type Here..." />
										<ErrorMessage name="reflection" component="div" className="error-msg pl-3 mt-2" />
									</div>
								</Modal.Body>
								<Modal.Footer className="border-0 p-0">
									<div className="row w-100">
										<div className="col-md-6">
											<button
												disabled={isSubmitting || isUploadingImage}
												className="btn btn-secondary lS-btn-grey-style mb-3 w-100"
												onClick={() => {
													onClose();
												}}
											>
												Cancel
											</button>
										</div>
										<div className="col-md-6">
											<button type="submit" disabled={isSubmitting || isUploadingImage} className="btn btn-primary lS-btn-blue-style w-100">
												{
													isSubmitting && (
														<span className="spinner-border spinner-border-sm mr-2"></span>
												)}
												Save
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</>
		)
	}
}

/**
 *  Define component PropTypes
 */
EditSingleResource.propTypes = {
	bookLetID: number.isRequired,
	bookLetIndexID: number.isRequired,
	getSingleBooklets: func.isRequired,
	isVisible: bool.isRequired,
	isUploadingImage: bool.isRequired,
	onClose: func.isRequired,
	singleTask: object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
});

export default connect(
  mapStateToProps,
  {
		uploadImage,
		updateSingleTask,
		getSingleBooklets,
  }
)(withRouter(EditSingleResource));
