/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const trainingForm = () => state => state.get('trainingForm');

/**
 *  Select the correct portion of the root reducer
 */
export const getTrainingsFormListing = () =>
  createSelector(trainingForm(), state => {
    return state.get('trainingsListForm') ? state.get('trainingsListForm').toJS() : {};
  });


/**
 *  Select is fetching trainings
 */
export const getIsFetchingTrainingsForm = () =>
  createSelector(trainingForm(), state => {
    return state.get('isFetchingTrainingsForm')
  })