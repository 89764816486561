/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_CONTACTS,
  LOAD_MORE_CONTACS,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Jobs
 */
export function getContacts() {
  return async (dispatch, getState) => {
    const state = getState();
    const { page } = state.get('sidebar').toJS();
    const params = {
      page,
      limit: 1000
    };

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getContactsBegin(
          API_URLS.SIDEBAR.GET_CONTACTS_CHATS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getContactsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_CONTACTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

export function loadMoreContacts() {
  return async (dispatch, state) => {
    let { page } = state.get('sidebar').toJS();
    dispatch(loadMoreContactsBegin(page++));
    dispatch(getContacts());
  }
};

export const loadMoreContactsBegin = page => ({
  type: LOAD_MORE_CONTACS,
  page
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
