/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
  GET_LIST_OF_CONTACTS,
  LOAD_MORE_CONTACS,
  CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  contacts: [],
  page: 1,
  limit: 10,
  isFetchingContacts: false,
  isMoreContactsAvailable: true,
});

/**
 *  Define the reducer with actions
 */
function sideBarReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_CONTACTS:
      return fromJS({
        ...state.toJS(),
        isFetchingContacts: true,
      })

    case success(GET_LIST_OF_CONTACTS):
      const { data } = action.payload.data;
      const { current_page, total_pages } = action.payload.data.meta.pagination;
      return fromJS({
        ...state.toJS(),
        contacts: data ? data : {},
        isFetchingContacts: false,
        isMoreContactsAvailable: current_page === total_pages ? false : true,
      })

    case failure(GET_LIST_OF_CONTACTS):
      return fromJS({
        ...state.toJS(),
        isFetchingContacts: false,
        isMoreContactsAvailable: false,
      })

    case LOAD_MORE_CONTACS:
      return fromJS({
        ...state.toJS(),
        page: action.page,
      })
      
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default sideBarReducer
