import React from "react";
import { func, bool, number, object, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import * as Yup from 'yup';
import { Form, Field, ErrorMessage, Formik } from "formik";
import Select from 'react-select';
import { showSuccessMsg } from 'utils/notification';
import * as moment from 'moment';
import { getFormattedDate } from 'utils/helper';
import { getLocalStorage } from '../../../utils/helper';
import UserFooter from 'components/Footers/UserFooter';
import PostForums from 'components/postForums';

import {getPost, getCategories, addForumPost} from '../../../modules/forums/actions';
import {getPostDetail, getIsFetchingPost, getPostReplyList, getCategoriesList, getIsFetchingCategories} from '../../../modules/forums/selectors';
import { uploadImage,} from '../../../modules/policyDocPost/actions';
import { getIsUploadingImage } from '../../../modules/policyDocPost/selectors';

import Loader from 'components/Loader';

const addEditSchema = Yup.object().shape({
  // title: Yup.string()
  //   .required('Required'),
  description: Yup.string()
    .required('Required'),
  // forumCategory:Yup.string()
  //   .required('Required'),
});

class PostDetails extends React.Component {
  state = {
    isVisiblePostForums: false,
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add',
    userProfile: JSON.parse(getLocalStorage()),
  };

  componentWillMount() {
    this.props.getCategories();
    if (Object.keys(this.props.match.params).length > 0) {
        this.props.getPost(this.props.match.params.postId).then(() => {
        }, () => {
          const { history } = this.props;
          if (history) {
            history.push('/user/ls-forums');
          }
        });
      }
  }

  getSinglePost = (post, index, id) => (
    <div className="single-comment d-flex mb-4">
      <div className="thumb-wrap thumb-wrap-sm rounded-circle">
        <img src={post.user_image_url} alt="thumb" />
      </div>
      <div className="flex-fill px-3">
        <div className="border-bottom ls-border-color pb-3">
          <p className="family-poppins-medium font-14 mb-1">{post.user_name}</p>
          <span className="color-grey font-12 d-block">{post.created_at ? getFormattedDate(post.created_at) : `--`}
           {/* <span className="mx-2">|</span> 8:52 PM */}
           </span>
          <p className="mt-2 font-14">{post.description ? post.description : ``}</p>
        </div>
      </div>
    </div>

   
  );


  getSingleCategory = (category, index, id) => (
    <Link to={`/user/ls-forums/cat/${id}`} className="px-4 link-hover d-flex align-items-center mb-4">
      <div className="thumb-wrap thumb-wrap-sm rounded-circle border ls-border-color">
        <img src={require("assets/images/forum/forum.png")} alt="thumb"  />
      </div>
      <h4 className="font-weight-normal mb-0 flex-fill font-18 family-poppins-medium text-truncate px-3">{category.name}</h4>
      <span className="btn lS-btn-icon lS-btn-icon-md lS-btn-blue-style">
      <span className="font-17">{category.posts_count ? category.posts_count : 0 }</span>
      </span>
    </Link>
  );

  getFormikInitVal = () => {
     return {
      title: "",
      description: "",
      forumCategory: "",
    }
  };


   handleJobImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("file", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('attachment_url', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
            <div className="fileUploadCol">
                {/* <label>Guideline</label> */}
            </div>
            <div className="position-relative fileUploadCol w-100">
                <span>Guideline attached</span>
                <div className="selectImgCross" onClick={() => form.setFieldValue('docURL', "")}>
                <i className="fas fa-times"></i>
                </div>
            </div>
          {/* <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div> */}
        </>
      )
    }

    return (
      <>
        <input
          type="file"
          onChange={event => this.handleJobImage(event, form.setFieldValue)}
        />
        <span>Upload File</span>
      </>
    )
  };
  postJob = (values, { setSubmitting, resetForm }) => {
    const { addForumPost, history, post } = this.props;
    var params = {
      title: values.title,
      description: values.description,
      forum_category_id: post.forum_category_id,
      forum_post_id: this.props.match.params.postId,
    };

    if (values.imageUrl) {
      params.image_url = values.imageUrl;
    }

    // if( mode === 'edit' ){
    //   // updatePostTraining(params, this.props.match.params.id).then(res => {
    //   //   showSuccessMsg("Training successfully updated");
    //   //   resetForm(this.getFormikInitVal);
    //   //   setSubmitting(false);
    //   //   if (history) {
    //   //     history.push('/user/trainings');
    //   //   }
    //   // }, err => {
    //   //   setSubmitting(false);
    //   // });
    // }

    addForumPost(params).then(res => {
      showSuccessMsg("Success.");
      resetForm(this.getFormikInitVal);
      setSubmitting(false);
      if (history) {
        history.push('/user/ls-forums');
      }
    }, err => {
      setSubmitting(false);
    });
  };


  render() {
    const{ post, replies, isFetchingPost, isFetchingCategories, categories} = this.props;
    const { mode, userProfile, isVisiblePostForums } = this.state;
    console.log(replies);
	    return (
        <>
          <UserHeader />
          <main className="m-0 px-3 px-md-5">
            <div className="container-fluid mt-md-5 mt-4 p-0">
              <div className="card border-0 page-title-bar">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-8">
                      <h4 className="family-poppins-semibold  m-0">Forums</h4>
                      <p className="mb-0 font-14 color-grey"></p>
                    </div>
                    <div className="col-lg-4">
                      <div className="d-flex justify-content-lg-end mt-3 mt-lg-0">
                        <button type="button" className="btn btn-lg btn-primary lS-btn-blue-large" onClick={() => this.setState({ isVisiblePostForums: true })}>
                          Start New Topic
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* detail tiles */}
              <div className="container-fluid p-0 mt-md-5 mt-4">
                <div className="row mx-xl-n4">
                  <div className="col-xl-8 px-xl-4">
                  <div className="card forum-card lS-card-shadow lS-card-radius border-0 mb-5">
                    <div className="card-body d-flex">
                      <div className="thumb-wrap radius-10">
                        <img src={require("assets/images/forum/forum.png")} alt="thumb" className="img-object-fit" />
                      </div>
                      <div className="flex-fill min-width0 pl-4 pt-3 d-flex flex-column justify-content-between">
                        <div className="d-flex">
                          <div className="flex-fill pr-3 min-width0">
                            <h4 className="font-weight-normal family-poppins-medium">
                              {/* <Link to={`/user/ls-forums/post/${id}`}> */}
                                {post.title}
                              {/* </Link> */}
                            </h4>
                            <p className="color-grey font-18">{post.description || ''}</p>
                          </div>
                          <div className="mt-2">
                            <span className="rating-count radius-10 py-2 px-3">{post.total_views ? post.total_views : 0} </span>
                            <span className="d-block text-center font-12 mt-2">views</span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-end">
                          <div className="font-18">
                            <span className="d-block">{post.user_name}</span>
                            <div className="color-grey">
                              {/* {post.last_reply_duration ? post.last_reply_duration : `--`} 
                                <span className="mx-2">|</span> */}
                              {post.total_replies ? post.total_replies : 0} comments</div>
                          </div>
                          <div className="font-18">
                            <span className="cursor-pointer color-green mr-3"><i className="fas fa-share-alt" /></span>
                            <span className="cursor-pointer color-gold mr-3"><i className="fas fa-bookmark" /></span>
                            <span className="cursor-pointer color-grey"><i className="fas fa-ellipsis-h" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    
                    <div className="card lS-card-shadow lS-card-radius border-0 mb-5">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h6 className="font-17 font-weight-normal family-poppins-medium m-0">Comments</h6>
                          <span className="color-grey font-14">{replies.length} Comments</span>
                        </div>
                        <div>
                          {replies && replies.length > 0 && replies.map((reply, index) => this.getSinglePost(reply.attributes, index, reply.id))}

                          {!isFetchingPost && replies && replies.length === 0 && (
                            <div className="text-center py-5 px-3 family-poppins-medium">
                              No reply found.
                            </div>
                          )}

                          {isFetchingPost && (
                            <Loader />
                          )}
                        </div>

                        <Formik
                            initialValues={this.getFormikInitVal()}
                            validationSchema={addEditSchema}
                            onSubmit={this.postJob}
                          >
                            {({ isSubmitting, setFieldValue, values }) => (
                            <Form>
                              <div className="comment-box d-flex mb-3">
                                <div className="thumb-wrap thumb-wrap-sm rounded-circle">
                                  <img src={userProfile.profile_image_url} alt="thumb" />
                                </div>
                                <div className="flex-fill pl-4 text-right">
                                  <Field as="textarea" name="description" id="reply" className="rounded-pill px-4 form-control border ls-border-color comment-field"
                                      placeholder="Write your reply here..." defaultValue={""} />
                                  <ErrorMessage name="description" component="div" className="error-msg float-left mt-1 pl-3 font-11" />
                                  {/* <input type="text" className="font-12 px-4 form-control border ls-border-color" placeholder="" /> */}
                                  <button type="submit"
                                      disabled={isSubmitting}
                                      className="btn btn-primary font-13 lS-btn-sm mt-3 lS-btn-blue-style">Post Reply</button>
                                </div>
                              </div>
                            </Form>
                            )}
                          </Formik>

                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 px-xl-4">
                    <div className="row flex-xl-column flex-md-row flex-column">
                      <div className="col-12">
                        <div className="card aside-card lS-card-shadow lS-card-radius border-0 mb-5">
                          <h4 className="family-poppins-semibold border-bottom ls-border-color p-4 mb-0">Top Categories</h4>                          
                            <div className="p-0 pt-3 card-body">
                            {isFetchingCategories && (
                              <Loader />
                            )}
                            {!isFetchingCategories && categories && categories.length === 0 && (
                              <div className="text-center py-5 px-3 family-poppins-medium">
                                No Categories found
                              </div>
                            )}
                            {categories && categories.length > 0 && categories.map((category, index) => this.getSingleCategory(category.attributes, index, category.id))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <UserFooter />
          {isVisiblePostForums && (
          <PostForums 
            isVisible={isVisiblePostForums}
            onClose={isReload => {
              this.setState({
                isVisiblePostForums: false,
              });
              if(isReload) {window.location.reload();}
            }}
            mode="add"
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PostDetails.propTypes = {
    getPost: func.isRequired,
    addForumPost: func.isRequired,
    getCategories: func.isRequired,
    post: Object.isRequired,
    replies: array.isRequired,
    categories: Object.isRequired,
    isFetchingPost: Boolean.isRequired,
    isFetchingCategories: Boolean.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
    post: getPostDetail(),
    replies: getPostReplyList(),
    categories: getCategoriesList(),
    isFetchingPost: getIsFetchingPost(),
    isFetchingCategories: getIsFetchingCategories(),
});

export default connect(
  mapStateToProps,
  {
    getCategories,
    getPost,
    addForumPost
  }
)(withRouter(PostDetails));

