import React from "react";
import UserHeader from 'components/Headers/UserHeader';
import { func, bool, object, array } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { groups, regions } from './filters';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg, showErrorMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  uploadImage,
  addPostEvent,
  updatePractice,
  getUpdatePersonalInfo,
  fetchPracticeList
} from '../../modules/updatePersonalInfo/actions';
import {
  // getIsUploadingImage,
  getIsFetchingPractice,
  getIsFetchingSelectPractice,
  getPracticeListing,
  getSelectPracticeListing,
} from '../../modules/updatePersonalInfo/selectors';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const addEditEventSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  email: Yup.string()
  .required('Required'),
  phone: Yup.string()
  .required('Required'),
  fax: Yup.string()
    .required('Required'),
  day_schedule: Yup.array()
    .of(
      Yup.object().shape({
        start_time: Yup.string()
          .required('Required'),
        end_time: Yup.string()
          .required('Required'),
      })
    )
    .required('Required'),
  website_url: Yup.string()
    .required('Required'),
  address: Yup.string()
  .required('Required'),
  ambassadors: Yup.array()
  // .required('Required'),
});

const formattedArray = array => {
  return array.map(item => {
    let {image_url, name} = item.attributes || {}
    return {
      id: item.id,
      label: showLabelValue(image_url, name),
      value: name
    };
  });
};

const showLabelValue = (image, name) => {
  return(
    <>
      <img src={image} alt="..." className="img-fluid mr-2 ls-select-image" />
      <span> {name} </span>
    </>
  );
}

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formSelect = props => {
  const { form, options, field, placeholder, id } = props;
  return (
    <Select 
      isMulti
      components={{ 
        IndicatorSeparator:() => null 
      }}
      className="rounded-lg"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value)
      }}
      defaultValue={formattedArray(field.value)}
      styles={customStyles}
      options={formattedArray(options)}
    />
    )
  };

const DateTimeSelect = props => {
  const { form, placeholder, field } = props;
  return(
    <Datetime 
      dateFormat={false}
      inputProps={{placeholder: placeholder}}
      closeOnSelect={true}
      timeFormat={true}
      onChange={value => {
        form.setFieldValue(field.name, moment(value).format('hh:mm a')) 
      }}
      value={field.value}
    />
  )
}

class UpdatePersonalInfo extends React.Component {
  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getUpdatePersonalInfo(this.props.match.params.id)
      this.props.fetchPracticeList(this.props.match.params.id)
    }
  }

  getFormikInitVal = () => {
    const { practice, isFetchingPractice, isFetchingSelectPractice } = this.props;
    const { mode } = this.state;
    if(!isFetchingPractice && !isFetchingSelectPractice){
      const {
        name,
        details,
        address,
        website_url,
        ambassadors      
      } = practice && practice.attributes || {};

      return {
        name: name,
        email: details && details.email,
        phone: details && details.phone,
        fax: details && details.fax,
        day_schedule: details && details.day_schedule,
        website_url: website_url,
        address: address,
        ambassadors:  practice.ambassadors && practice.ambassadors.length > 0 ? practice.ambassadors : [],
      }
    }
    
  };

  postEvent = (values, { setSubmitting, resetForm }) => {
    const { getUpdatePersonalInfo, updatePractice, history } = this.props;
    const { mode } = this.state;
    let filterAmbassador = []
    if(values.day_schedule && values.day_schedule.length > 7){
      setSubmitting(false);
      showErrorMsg("Max Time limit is 7");
    }
    else{
      if(values.ambassadors && values.ambassadors.length > 0){
        values.ambassadors.map(item => { 
          let obj = {
            id: item.id
          }
          return filterAmbassador.push(obj)
        })
      }
  
      var params = {
        name: values.name,
        details:{
          email: values.email,
          phone: values.phone,
          fax: values.fax,
          day_schedule: values.day_schedule,
        },
        website_url: values.website_url,
        address: values.address,
        // ambassadors: values.ambassadors,
      };
      if(filterAmbassador && filterAmbassador.length > 0) {
        params.ambassadors = filterAmbassador
      }
      
      updatePractice(params, this.props.match.params.id).then(res => {
        getUpdatePersonalInfo(this.props.match.params.id);
        showSuccessMsg("Practice successfully updated");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
      }, err => {
        setSubmitting(false);
      });
    }
  };

  render() {
    const {
      isUploadingImage,
      isFetchingPractice,
      selectPractice,
      isFetchingSelectPractice,
    } = this.props;
    const { mode } = this.state;
    return (
      <>
        <UserHeader />        
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 pb-5 mt-md-5 mt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="family-poppins-semibold">Update Practice Info </h5>
                    <p className="mb-0 font-14 color-grey"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded-lg">
              {isFetchingPractice && isFetchingSelectPractice && (
                <Loader />
              )}
              
              <div className="card-body">
              {!isFetchingPractice && !isFetchingSelectPractice && (
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditEventSchema}
                  onSubmit={this.postEvent}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="ls-form pt-2">
                {/* <form className="ls-form pt-2" action encType="multipart/form-data" method="post"> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="name" className="color-grey pl-3"> Name </label>
                        <Field type="text" name="name" className="form-control form-control-lg rounded-lg" placeholder="Enter Name" />
                        <ErrorMessage name="name" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="email" className="color-grey pl-3"> Email </label>
                        <Field type="email" name="email" className="form-control form-control-lg rounded-lg" placeholder="Enter Email" />
                        <ErrorMessage name="email" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="phone" className="color-grey pl-3"> Phone </label>
                        <Field type="text" name="phone" className="form-control form-control-lg rounded-lg" placeholder="Enter Phone" />
                        <ErrorMessage name="phone" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="fax" className="color-grey pl-3"> Fax </label>
                        <Field type="text" name="fax" className="form-control form-control-lg rounded-lg" placeholder="Enter Fax" />
                        <ErrorMessage name="fax" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="website_url" className="color-grey pl-3"> Website Url </label>
                        <Field type="text" name="website_url" className="form-control form-control-lg rounded-lg" placeholder="Enter Link URL" />
                        <ErrorMessage name="website_url" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-5">
                        <label htmlFor="address" className="color-grey pl-3"> Address </label>
                        <Field type="text" name="address" className="form-control form-control-lg rounded-lg" placeholder="Enter Address" />
                        <ErrorMessage name="address" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-5">
                        <label htmlFor="ambassadors" className="color-grey pl-3"> Select Ambassadors </label>
                        <Field as="select" name="ambassadors" component={formSelect} options={selectPractice} className="form-control form-control-lg rounded-lg" placeholder="Enter Ambassadors" />
                        <ErrorMessage name="ambassadors" component="div" className="error-msg" />
                      </div>
                    </div>
                    
                    <div className="col-lg-12 col-md-12"> 
                      <FieldArray 
                        name="day_schedule"
                        render={arrayHelpers => (
                          values.day_schedule && values.day_schedule.length > 0 ? (
                            values.day_schedule.map((day_schedule, index) => (
                              <div className="row" key={index}>
                                <div className="col-lg-10 col-md-10">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 mb-3">
                                      <div className="form-group">
                                        <label htmlFor="day_schedule" className="color-grey pl-3">Start Time</label>
                                        <Field as="text" component={DateTimeSelect} name={`day_schedule[${index}].start_time`} className="form-control" placeholder="Start Time" />
                                        <ErrorMessage name={`day_schedule[${index}].start_time`} component="div" className="error-msg mt-3 ml-3" />
                                      </div>
                                    </div>
                                    
                                    <div className="col-lg-6 col-md-6 mb-3">
                                      <div className="form-group">
                                        <label htmlFor="day_schedule" className="color-grey pl-3">End time</label>
                                        <Field as="text" component={DateTimeSelect} name={`day_schedule[${index}].end_time`} className="form-control" placeholder="End Time" />
                                        <ErrorMessage name={`day_schedule[${index}].end_time`} component="div" className="error-msg mt-3 ml-3" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-2 text-right pt-md-4 mt-md-3 mb-5">
                                  <span className="mr-2">
                                    <button type="button" className="btn btn-danger lS-btn-red-style btn-sm px-2" onClick={() => arrayHelpers.remove(index)} >
                                      <i className="fa fa-minus"></i>
                                    </button>
                                  </span>
                                  <span className="ml-1">
                                    <button type="button" className="btn btn-primary lS-btn-blue-style btn-sm mr-1 px-2" onClick={() => arrayHelpers.push({
                                      start_time: '',
                                      end_time: '',
                                    }) } >
                                      <i className="fa fa-plus"></i>
                                    </button>
                                  </span>
                                </div>
                              </div>
                            ) ) ) 
                            : 
                            (<div className="d-flex flex-fill w-100 justify-content-center">
                                <button type="button" className="btn btn-primary lS-btn-blue font-20 ml-3" 
                                onClick={() => arrayHelpers.push({
                                  start_time: '',
                                  end_time: '',
                                })}
                                >
                                  Add Time
                                </button>
                              </div>) 
                            )}
                      />
                    </div>
                  </div>

                  <div className="text-md-right text-center">
                    <Link to='/user/events' className="ls-btn-link btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</Link>
                    <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">
                      {
                        isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Submit
                    </button>
                  </div>
                {/* </form> */}
                 </Form>
                 )}
               </Formik>
               )}
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>
      </>
    );
  };
};

/**
 *  Define component PropTypes
 */
UpdatePersonalInfo.propTypes = {
  addPostEvent: func.isRequired,
  isFetchingPractice: bool.isRequired,
  isFetchingSelectPractice: bool.isRequired,
  isUploadingImage: bool.isRequired,
  practice: object.isRequired,
  selectPractice: array.isRequired,
  updatePractice: func.isRequired,
  uploadImage: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  // isUploadingImage: getIsUploadingImage(),
  isFetchingPractice: getIsFetchingPractice(),
  isFetchingSelectPractice: getIsFetchingSelectPractice(),
  practice: getPracticeListing(),
  selectPractice: getSelectPracticeListing(),
});

export default connect(
  mapStateToProps,
  {
    uploadImage,
    addPostEvent,
    updatePractice,
    getUpdatePersonalInfo,
    fetchPracticeList,
  }
)(withRouter(UpdatePersonalInfo));