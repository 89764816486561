/**
 *  Import action creator constants & dependencies
 */
import {
    GET_LIST_OF_PLACEMENTS,
    GET_SINGLE_PLACEMENT,
    GET_LIST_OF_PLACEMENT_OCCUPATIONS,
    APPLY_FILTERS,
    SELECT_CHECKBOX,
    UNSELECT_CHECKBOX,
    APPLY_PAGINATION,
    RESET_FILTERS,
    CANCEL_ALL_API_REQUESTS,
    APPLY_PLACEMENTS,
    UPDATE_PLACEMENT_STATUS,
    GET_LIST_OF_PLACEMENTS_APPLICANTS,
    UPDATE_PLACEMENT_APPLICANT_STATUS,
    MENTOR_PLACEMENTS,
    GET_LIST_OF_PLACEMENTS_MENTORS,
	UPDATE_PLACEMENT_MENTOR_STATUS,
	LIKE_DISLIKE_PLACEMENT,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of placments
 */
export function getPlacements() {
    return async(dispatch, getState) => {
        const state = getState();
        const { limit, page, search, range, duration, region, type, group, occupationType } = state.get('placements').toJS();
        const params = {
            page,
            limit,
            filters: {
                //   salary: {
                //     min: 0,
                //     max: range
                //   }
            }
        };

        // Apply when search has any value
        if (search) {
            params.filters.search = search;
        }

        // Apply Occupations filtes
        if (occupationType.length > 0) {
            params.filters.occupation_type = occupationType;
        }

        // Apply Locations filtes
        if (region.length > 0) {
            params.filters.region = region;
        }
        // Apply Types filtes
        if (duration.length > 0) {
            params.filters.duration = duration;
        }
        // Apply Groups filtes
        if (type.length > 0) {
            params.filters.type = type;
        }

        // Apply Durations filtes
        if (group.length > 0) {
            params.filters.group = group;
        }
        // console.log(params)
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPlacementsBegin(
                    API_URLS.PLACEMENTS.GET_PLACEMENTS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPlacementsBegin = (API_URL, params, source) => ({
    type: GET_LIST_OF_PLACEMENTS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Get Single Placement
 */
export function getSinglePlacement(placementID) {
    return async dispatch => {

        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getSinglePlacementBegin(
                    API_URLS.PLACEMENTS.GET_SINGLE_PLACEMENT + placementID,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getSinglePlacementBegin = (API_URL, source) => ({
    type: GET_SINGLE_PLACEMENT,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            cancelToken: source.token
        }
    }
});

/**
 *  Get List of Placement Occupations
 */
export function getPlacementsOccupations() {
    return async(dispatch, getState) => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPlacementsOccupationsBegin(
                    API_URLS.PLACEMENTS.GET_PLACEMENTS_OCCUPATIONS,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPlacementsOccupationsBegin = (API_URL, source) => ({
    type: GET_LIST_OF_PLACEMENT_OCCUPATIONS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Apply placements filters
 */
export function applyFilters(params) {
    return async dispatch => {
        dispatch(applyFiltersBegin(params));
        dispatch(getPlacements());
    };
};

export const applyFiltersBegin = (params) => ({
    type: APPLY_FILTERS,
    params,
});

/**
 *  Apply pagination
 */
export function applyPagination(page) {
    return async dispatch => {
        dispatch(applyPaginationBegin(page));
        dispatch(getPlacements());
    };
};

export const applyPaginationBegin = page => ({
    type: APPLY_PAGINATION,
    page
});

/**
 *  Reset filters
 */
export function resetFilters() {
    return async dispatch => {
        dispatch(resetFiltersBegin());
        dispatch(getPlacements());
    };
};

export const resetFiltersBegin = () => ({
    type: RESET_FILTERS
});

/**
 *  Apply placements filters
 */
export function checkUnCheckFilters(name, checkBoxValue, value) {
    return dispatch => {
        let arrayKeyName;
        switch (name) {
            case 'type':
                arrayKeyName = 'selectedTypes';
                break;
            case 'location':
                arrayKeyName = 'selectedLocations';
                break;
            case 'occupation':
                arrayKeyName = 'selectedOccupations';
                break;
            case 'group':
                arrayKeyName = 'selectedGroups';
                break;
            case 'duration':
                arrayKeyName = 'selectedDurations';
                break;
            default:
                break;
        };
        checkBoxValue ? dispatch(selectCheckbox(arrayKeyName, value)) : dispatch(unSelectCheckbox(arrayKeyName, value));
        return true;
    };
};

export const selectCheckbox = (arrayKeyName, value) => ({
    type: SELECT_CHECKBOX,
    arrayKeyName,
    value,
});

export const unSelectCheckbox = (arrayKeyName, value) => ({
    type: UNSELECT_CHECKBOX,
    arrayKeyName,
    value,
});

/**
 * apply placements
 */
export function applyPlacement(params) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                applyPlacementBegin(
                    API_URLS.PLACEMENTS.APPLY_PLACEMENTS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const applyPlacementBegin = (API_URL, params, source) => ({
    type: APPLY_PLACEMENTS,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            cancelToken: source.token
        }
    },
    params
});

/**
 * apply placements
 */
export function mentorPlacement(params) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                mentorPlacementBegin(
                    API_URLS.PLACEMENTS.MENTOR_PLACEMENTS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const mentorPlacementBegin = (API_URL, params, source) => ({
    type: MENTOR_PLACEMENTS,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            cancelToken: source.token
        }
    },
    params
});

/**
 *  Update placement status
 */
export function updatePlacementStatus(id, params) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                updatePlacementStatusBegin(
                    API_URLS.PLACEMENTS.UPDATE_PLACEMENT_STATUS + id,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const updatePlacementStatusBegin = (API_URL, params, source) => ({
    type: UPDATE_PLACEMENT_STATUS,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'indices' })
            },
            cancelToken: source.token
        }
    },
    params
});

/**
 *  Get List of Placement applicants
 */
export function getPlacementApplicants(params) {
    return async(dispatch, getState) => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPlacementApplicantsBegin(
                    API_URLS.PLACEMENTS.GET_PLACEMENTS_APPLICANTS,
                    params,
                    source
                )
            );

            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPlacementApplicantsBegin = (API_URL, params, source) => ({
    type: GET_LIST_OF_PLACEMENTS_APPLICANTS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Get List of Placement applicants
 */
export function getPlacementMentors(params) {
    return async(dispatch, getState) => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                getPlacementMentorsBegin(
                    API_URLS.PLACEMENTS.GET_PLACEMENTS_MENTORS,
                    params,
                    source
                )
            );

            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const getPlacementMentorsBegin = (API_URL, params, source) => ({
    type: GET_LIST_OF_PLACEMENTS_MENTORS,
    payload: {
        request: {
            url: API_URL,
            method: 'get',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
            cancelToken: source.token
        }
    }
});

/**
 *  Update Placement applicant status
 */
export function updatePlacementMentorStatus(params) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                updatePlacementMentorStatusBegin(
                    API_URLS.PLACEMENTS.UPDATE_PLACEMENT_MENTORS_STATUS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const updatePlacementMentorStatusBegin = (API_URL, params, source) => ({
    type: UPDATE_PLACEMENT_APPLICANT_STATUS,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'indices' })
            },
            cancelToken: source.token
        }
    },
    params
});

/**
 *  Update Placement applicant status
 */
export function updatePlacementApplicantStatus(params) {
    return async dispatch => {
        const source = axios.CancelToken.source();
        cancelApiRequests.push(source);
        try {
            const response = await dispatch(
                updatePlacementApplicantStatusBegin(
                    API_URLS.PLACEMENTS.UPDATE_PLACEMENT_APPLICANT_STATUS,
                    params,
                    source
                )
            );
            if (response.payload) {
                const { data } = response.payload;
                return data;
            }

            throw response;
        } catch (error) {
            throw error.response;
        }
    };
};

export const updatePlacementApplicantStatusBegin = (API_URL, params, source) => ({
    type: UPDATE_PLACEMENT_APPLICANT_STATUS,
    payload: {
        request: {
            url: API_URL,
            method: 'post',
            data: JSON.stringify(params),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'indices' })
            },
            cancelToken: source.token
        }
    },
    params
});

/**
 *  add like dislike of placement
 */
export function postLikeDislikePlacement(id, params) {
	return async dispatch => {
	  const source = axios.CancelToken.source();
	  cancelApiRequests.push(source);
	  try {
		const response = await dispatch(
			postLikeDislikePlacementBegin(
			API_URLS.PLACEMENTS.LIKE_DISLIKE + id+ '/likes',
			params,
			id,
			source
		  )
		);
		if (response.payload) {
		  const { data } = response.payload;
		  return data;
		}
  
		throw response;
	  } catch (error) {
		throw error.response;
	  }
	};
  };
  
  export const postLikeDislikePlacementBegin = (API_URL, params, id, source) => ({
	type: LIKE_DISLIKE_PLACEMENT,
	payload: {
	  request: {
		url: API_URL,
		method: 'post',
		data: JSON.stringify(params),
		headers: {
		  accept: 'application/json',
		  'content-type': 'application/json'
		},
		params: params,
		paramsSerializer: function(params) {
		  return qs.stringify(params, { arrayFormat: 'indices' })
		},
		cancelToken: source.token
	  }
	},
	params,
	id
  });

export function cancelAllApiRequests() {
    return dispatch => {
        cancelApiRequests.forEach(apiRequest => {
            apiRequest.cancel()
        })
        dispatch(cancelAllApiRequestsBegin())
    }
};

export const cancelAllApiRequestsBegin = () => ({
    type: CANCEL_ALL_API_REQUESTS
});