export const GET_LIST_OF_EVENTS =
  'src/modules/eventPost/GET_LIST_OF_EVENTS';
export const ADD_EVENTS =
  'src/modules/eventPost/ADD_EVENTS';
export const UPDATE_EVENTS =
  'src/modules/eventPost/UPDATE_EVENTS';
export const UPLOAD_IMAGE =
  'src/modules/eventPost/UPLOAD_IMAGE';
export const DELETE_EVENTS =
  'src/modules/eventPost/DELETE_EVENTS';
export const RESET_FILTERS =
  'src/modules/eventPost/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/eventPost/APPLY_PAGINATION';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/eventPost/CANCEL_ALL_API_REQUESTS';
