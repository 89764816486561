export const GET_FEEDBACK_LOG =
  'src/modules/revalidation/feedbackLog/GET_FEEDBACK_LOG';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/revalidation/feedbackLog/CANCEL_ALL_API_REQUESTS';
export const SHOW_FEEDBACK_LOG =
  'src/modules/revalidation/feedbackLog/SHOW_FEEDBACK_LOG';
export const POST_FEEDBACK_LOG =
  'src/modules/revalidation/feedbackLog/POST_FEEDBACK_LOG';
export const UPDATE_FEEDBACK_LOG =
  'src/modules/revalidation/feedbackLog/UPDATE_FEEDBACK_LOG';
export const DELETE_FEEDBACK_LOG =
  'src/modules/revalidation/feedbackLog/DELETE_FEEDBACK_LOG';



