import React from "react";
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { showSuccessMsg } from '../../utils/notification';
import { setLocalStorage } from '../../utils/helper';
/**
 *  Import other dependencies
 */
import { login } from '../../modules/auth/actions';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Required'),
});

class Login extends React.Component {
  loginUser = (values, { setSubmitting }) => {
    const { login, history } = this.props;
    const { email, password } = values;
    login(email, password).then(res => {
      showSuccessMsg('Login successfully');
      const { attributes, relationships } = res.data;
      const data = 
        res.included.find(
          include =>
            relationships.user_profile.data.id === include.id
        );
      const storeData = {...attributes, ...data.attributes};
      setLocalStorage(storeData); // Set localstorage
      setSubmitting(false);
      if (history) {
        if (attributes.role === 'student' || attributes.role === 'staff') {
          history.push('/user/index');
          return;
        }
        history.push('/user/index');
      }
    }, err => {
      setSubmitting(false);
    });
  };

  render() {
    return (
      <>
        <div className="d-flex justify-content-center ls-height-100 align-items-center">
          <div className="row ls-form-content my-4">
            <div className="col-md-12 text-center mb-3">
              <h2 className="family-poppins-semibold"> Login </h2>
            </div>
            <div className="col-md-12 mt-3">
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={loginSchema}
                onSubmit={this.loginUser}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group ls-login-form">
                      <label htmlFor="email" className="mb-0 text-muted font-10">Email<span className="text-danger">*</span> </label>
                      <Field type="email" name="email" className="form-control font-14" placeholder="name@example.com" />
                    </div>
                    <ErrorMessage name="email" component="div" className="font-14 mb-2" />
                    <div className="form-group ls-login-form">
                      <label htmlFor="password" className="mb-0 text-muted font-10">Password<span className="text-danger">*</span></label>
                      <Field type="password" name="password" className="form-control font-14" placeholder="Password" />
                    </div>
                    <ErrorMessage name="password" component="div" className="font-14 mb-2" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="custom-control custom-checkbox my-1 mr-sm-2">
                        <input type="checkbox" className="custom-control-input" id="customControlInline" />
                        <label className="custom-control-label font-12" htmlFor="customControlInline">Remember me</label>
                      </div>
                      <Link to="/auth/forgot-password" className="forgot-password font-12">Forgot Password?</Link>
                    </div>
                    <button type="submit" className="btn btn-primary ls-login-button w-100 py-3 my-3 mb-2 m-1" disabled={isSubmitting}>
                      { 
                        isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2 align-middle"></span>
                      )}
                      Login
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="d-flex justify-content-center flex-wrap w-100 mt-2">
              <p className="font-14"> 
                Don't have an account?{' '}
                <span>
                  <Link to="/auth/register" className="font-14">
                    Sign Up
                  </Link>
                </span> 
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Login.propTypes = {
  login: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({});

export default connect(
  mapStateToProps,
  {
    login
  }
)(withRouter(Login));
