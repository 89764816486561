/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const analysisReport = () => state => state.get('analysisReport');

/**
 *  Select the correct portion of the root reducer
 */
export const getAnalysisReport = () =>
  createSelector(analysisReport(), state => {
    return state.get('analysisReport') ? state.get('analysisReport').toJS() : {};
  });


/**
 *  Select is fetching Analysis Report
 */
export const getIsAnalysisReport = () =>
  createSelector(analysisReport(), state => {
    return state.get('isFetchingAnalysisReport')
  })

/**
 *  Select is Download Report
 */
export const getIsDownloadingReport = () =>
  createSelector(analysisReport(), state => {
    return state.get('isDownloadingReport')
  })