export const GET_LIST_OF_JOBS =
  'src/modules/jobs/GET_LIST_OF_JOBS';
export const GET_SINGLE_JOB =
  'src/modules/jobs/GET_SINGLE_JOB';
export const GET_LIST_OF_JOB_OCCUPATIONS =
  'src/modules/jobs/GET_LIST_OF_JOB_OCCUPATIONS';
export const APPLY_FILTERS =
  'src/modules/jobs/APPLY_FILTERS';
export const RESET_FILTERS =
  'src/modules/jobs/RESET_FILTERS';
export const APPLY_PAGINATION =
  'src/modules/jobs/APPLY_PAGINATION';
export const SELECT_CHECKBOX =
  'src/modules/jobs/SELECT_CHECKBOX';
export const UNSELECT_CHECKBOX =
  'src/modules/jobs/UNSELECT_CHECKBOX';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/jobs/CANCEL_ALL_API_REQUESTS';
export const APPLY_JOB =
  'src/modules/jobs/APPLY_JOB';
export const GET_LIST_OF_JOB_APPLICANTS =
'src/modules/jobs/GET_LIST_OF_JOB_APPLICANTS';
export const UPDATE_JOB_APPLICANT_STATUS =
'src/modules/jobs/UPDATE_JOB_APPLICANT_STATUS';
export const UPDATE_JOB_STATUS =
'src/modules/jobs/UPDATE_JOB_STATUS';
