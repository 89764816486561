import React, { Fragment } from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import Share from "../../components/share";
import { isRouteAllow } from 'utils/helper';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {getUsers, applyFilters, resetFilters, applyPagination} from '../../modules/teams/actions';
import {getUsersList, getIsFetchingUsers, getPage, getTotalPages} from '../../modules/teams/selectors';
import Loader from 'components/Loader';
// import { trainingType, trainingLevels, locations } from './filters';

// const dateFormat = 'DD-MMM-YYYY';
// const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

class Teams extends React.Component {
  state={
    search: '',
    disableStatus: true,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    trainingId:0,
    examiner_type:'assessor',
    isFiltersVisible: false,
  }

//   openShareModal(id) {
//     this.setState({
//         isVisibleShare: true,
//         trainingId: id,
//     });
//   }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    this.props.getUsers();
  }

//   getFormattedDate = date => {
//     if (!date) return;
//     return moment(date).format(dateFormat);
//   };

//   getFormattedDateTime = date => {
//     if (!date) return;
//     return moment(date).format(dateTimeFormat);
//   };

 getSingleTraining = (user, index, id) => (  
    <div className="col-12 col-md-4 col-sm-6 custom-col-width border-0 mb-md-4 mb-3">
      <div className="w-100 ls-height-100 team-img-cont radius-10 overflow-hidden card border-0">
        <img src={ user.image_url ? user.image_url : require('assets/images/avatar.png')} alt="..." className="img-object-fit" />
      </div>
      <div className="px-2">
        <div className="team-text-block lS-card-shadow bg-white radius-10 py-2 px-3 d-flex align-items-center flex-column justify-content-center min-width0 position-relative">
          <div className="w-100 text-center">
            <h5 className="family-poppins-semibold text-truncate mb-2">
              {user.name}
            </h5>
            <span className="font-15 text-truncate d-block color-grey font-weight-500">
              {user.occupation}
            </span>
          </div>
          <div className="comment radius-10 align-items-center text-center justify-content-center">
            <h5 className="family-poppins-semibold color-white">
              {user.practice ? user.practice.name : ''}
            </h5>          
          </div>
        </div>
      </div>
      {/* <div className="ls-team-image-text-container">
        <div className="replies">
          <h5 className="family-poppins-semibold">
            {user.name}
            <br />
            <span className="font-12 color-grey font-weight-500">
            {user.occupation}
            </span>
          </h5>
        </div>
        <div className="comment">
           <h5 className="family-poppins-semibold">
            {user.practice ? user.practice.name : ''}
            </h5>          
        </div>
      </div> */}
    </div>
  );

//   checkUncheckbox = (name, event, string) => {
//     this.props.checkUnCheckFilters(name, event.target.checked, string);
//   };

  searchTrainings = () => {
    const {search} = this.state;
    this.props.applyFilters(search);
  };

  resetTrainings = () => {
    this.props.resetFilters();
  };

//   loadPage = page => {
//     this.props.applyPagination(page);
//   }

//   applyTraining = (id) => {
//     var params = {
//       wellbeing_id:id,
//     };

//     this.props.applyTraining(params).then(res => {
//       var message = res.message;
//       this.props.getTrainings()
//       showSuccessMsg(message ? message : 'Applied successfully.');
//     })
//   }

//   cancelTraining = (id) => {
//     var params = {
//       status:'cancelled',
//     };

//     this.props.updateTrainingStatus(id, params).then(res => {
//       var message = res.message;
//       this.props.getTrainings()
//       showSuccessMsg(message ? message : 'Cancelled.');
//     })
//   }

//   restoreTraining = (id) => {
//     var params = {
//       status:'active',
//     };

//     this.props.updateTrainingStatus(id, params).then(res => {
//       var message = res.message;
//       this.props.getTrainings()
//       showSuccessMsg(message ? message : 'Updated status.');
//     })
//   }

//   showWarning = (message) => {
//     showSuccessMsg(message);
//   }
  loadPage = page => {
    this.props.applyPagination(page);
  }

  toggleFilters = () => {
    this.setState({
      isFiltersVisible: !this.state.isFiltersVisible,
    });
  }

  render() {
    const { users, isFetchingUsers, totalPages, page } = this.props;
    const { search, isFiltersVisible } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 mb-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar mt-md-5 mt-3">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold">Teams</h4>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
              </div>
            </div>
          </div>
          {/* Filter Code */}
          <div className="d-flex mt-4 mb-md-5 mb-4">
            <button className="btn btn-primary lS-btn-blue-style lS-card-shadow ls-btn-filters rounded-lg mt-3 mr-4 font-12" onClick={() => this.toggleFilters()}>
              <i className="fas fa-filter" />
            </button>
            <div className={`${isFiltersVisible ? 'animation-animated' : ''} animated-filter-bar ls-filters-bar`}>
              <form className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
                <div className="px-2 flex-grow-1 mt-3">
                  <div className="input-group ls-search-field filter-only-search">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white pr-0 font-12 color-grey">
                        <i className="fas fa-search" />
                      </span>
                    </div>
                    <input type="text" name="search" className="form-control border-left-0" onChange={event => this.handleQueryChange(event.target.value)} value={search} placeholder="Search " />
                  </div>
                </div>
                <Fragment>
                  <div className="d-flex align-items-center mt-3 ml-auto pl-2">
                    <button className="btn btn-primary mr-3 lS-btn-blue-style" disabled={isFetchingUsers} onClick={this.searchTrainings}
                            type="button"
                            title="Search"
                                id="button-addon2">
                        Search
                    </button>
                    <button className="btn btn-primary lS-btn-red-style" disabled={isFetchingUsers} onClick={this.resetTrainings}
                      type="button"
                      id="button-addon2" 
                      title="Clear Search"
                      >
                        Clear
                    </button>
                  </div>
                </Fragment>
              </form>
            </div>
          </div>
          {/* Team Listing */}
          <div className="row row-col-5">
            {!isFetchingUsers && users && users.length > 0 &&
              users.map((user, index) => this.getSingleTraining(user.attributes, index, user.attributes.user_id)
            )}
            {!isFetchingUsers && users && users.length === 0 && (
              <div className="col-12">
                <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">No users found.</div>
              </div>
            )}
            {isFetchingUsers && (
              <div className="col-12"><Loader /></div>
            )}
          </div>
          {/* Pagination */}
          <div aria-label="Page navigation example" className="ls-pagination">
                <ReactPaginate
                containerClassName={'pagination justify-content-end mt-5'}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                forcePage={page - 1}
                onPageChange={selectedItem => {
                    if (isFetchingUsers) return;
                    this.loadPage(selectedItem.selected + 1);
                }}
                subContainerClassName={'pages pagination'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                />
            </div>          
        </main>
        <UserFooter />
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Teams.propTypes = {
  getUsers:func.isRequired,
  applyFilters: func.isRequired,
  resetFilters: func.isRequired,
//   checkUnCheckFilters: func.isRequired,
//   getTrainings: func.isRequired,
//   isFetchingUsers: bool.isRequired,
  users: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
//   applyTraining: func.isRequired,
//   updateTrainingStatus: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  users: getUsersList(),
  isFetchingUsers: getIsFetchingUsers(),
  page: getPage(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getUsers,
    // getTrainings,
    // checkUnCheckFilters,
    applyFilters,
    applyPagination,
    resetFilters,
    // applyTraining,
    // updateTrainingStatus,
  }
)(withRouter(Teams));
