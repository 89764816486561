/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_TRAININGS,
  APPLY_FILTERS,
  ADD_TRANINING_FEEDBACK,
  POST_TRANINING_FEEDBACK,
  POST_TRANINING_RATING,
  UPDATE_TRAINING_STATUS,
  UPDATE_TRAINING_STATUS_FROM_CREATOR,
  SELECT_CHECKBOX,
  UNSELECT_CHECKBOX,
  APPLY_PAGINATION,
  RESET_FILTERS,
  CANCEL_ALL_API_REQUESTS,
  APPLY_TRAININGS,
  GET_LIST_OF_TRAININGS_APPLICANTS,
  GET_LIST_OF_TRAININGS_APPLIED,
  UPDATE_TRAINING_APPLIED_STATUS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of T
 */
export function getTrainings() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, level, type, search, selectedTrainingLevels, selectedTypes, selectedUniversity } = state.get('trainings').toJS();
    const params ={
      page,
      limit,
      filters: {
        level: level,
        type: type,
      }
    };

    // Apply when search has any value
    if (search) {
      params.filters.search = search;
    }

    // Apply Locations filtes
    if (selectedTrainingLevels.length > 0) {
      params.filters.level = selectedTrainingLevels;
    }

    // Apply Types filtes
    if (selectedTypes.length > 0) {
      params.filters.type = selectedTypes;
    }

    if(selectedUniversity.length > 0) {
      params.filters.university = selectedUniversity;
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getTrainingsBegin(
          API_URLS.TRAININGS.GET_TRAININGS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getTrainingsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_TRAININGS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});


/**
 *  Add List of Post Invoice
 */
export function applyTraining(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        applyTrainingBegin(
          API_URLS.TRAININGS.APPLY_TRAININGS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const applyTrainingBegin = (API_URL, params, source) => ({
  type: APPLY_TRAININGS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});


/**
 *  Get List of Training applicants
 */
export function getTrainingApplicants(params) {
  return async (dispatch, getState) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getTrainingApplicantsBegin(
          API_URLS.TRAININGS.GET_TRAININGS_APPLICANTS,
          params,
          source
        )
      );

      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getTrainingApplicantsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_TRAININGS_APPLICANTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Training applied
 */
export function getTrainingApplied(status) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, level, type } = state.get('trainings').toJS();

    const params ={
      page,
      limit,
      filters: {
        status:status,
        type: type,
      }
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getTrainingAppliedBegin(
          API_URLS.TRAININGS.GET_TRAININGS_APPLIED,
          params,
          source
        )
      );

      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getTrainingAppliedBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_TRAININGS_APPLIED,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Add List of Post Invoice
 */
export function addTraningFeedback(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addTraningFeedbackBegin(
          API_URLS.TRAININGS.ADD_TRAINING_FEEDBACK,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addTraningFeedbackBegin = (API_URL, params, source) => ({
  type: ADD_TRANINING_FEEDBACK,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Update training status
 */
export function updateTrainingStatus(id, params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateTrainingStatusBegin(
          API_URLS.TRAININGS.UPDATE_TRAINING_STATUS + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateTrainingStatusBegin = (API_URL, params, source) => ({
  type: UPDATE_TRAINING_APPLIED_STATUS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Add List of post feedBack Training
 */
export function postfeedBackTraining(feedBackParam, ratingParam, id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        postfeedBackTrainingBegin(
          API_URLS.TRAININGS.ADD_TRAINING_FEEDBACK,
          feedBackParam,
          ratingParam,
          source
        )
      );

      const responseRating = await dispatch(
        postfeedBackTrainingRatingBegin(
          API_URLS.TRAININGS.POST_RATING_SINGLE_TRAINING_PREFIX + 
          id +
          API_URLS.TRAININGS.POST_RATING_SINGLE_TRAINING_POSTFIX,
          ratingParam,
          id,
          source
        )
      );

      if (response.payload && responseRating.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response && responseRating;
    } catch (error) {
      throw error.response;
    }
  };
};

export const postfeedBackTrainingBegin = (API_URL, params, ratingParam, source) => ({
  type: POST_TRANINING_FEEDBACK,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params,
  ratingParam
});

export const postfeedBackTrainingRatingBegin = (API_URL, params, id, source) => ({
  type: POST_TRANINING_RATING,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params,
  id
});

/**
 *  Update training applied status
 */
export function updateTrainingAppliedStatus(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateTrainingAppliedStatusBegin(
          API_URLS.TRAININGS.UPDATE_TRAINING_APPLIED_STATUS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateTrainingAppliedStatusBegin = (API_URL, params, source) => ({
  type: UPDATE_TRAINING_STATUS_FROM_CREATOR,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Add List of Post Invoice
 */
export function updateTraining(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateTrainingBegin(
          API_URLS.TRAININGS.UPDATE_TRAININGS_STATUS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateTrainingBegin = (API_URL, params, source) => ({
  type: UPDATE_TRAINING_STATUS,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Apply T filters
 */
export function applyFilters(params) {
  return async dispatch => {
    dispatch(applyFiltersBegin(params));
    dispatch(getTrainings());
  };
};

export const applyFiltersBegin = (params) => ({
  type: APPLY_FILTERS,
  params,
});

/**
 *  Apply pagination
 */
export function applyTrainingAppliedPagination(page) {
  return async dispatch => {
    dispatch(applyTrainingAppliedPaginationBegin(page));
    dispatch(getTrainingApplied());
  };
};

export const applyTrainingAppliedPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});


/**
 *  Apply pagination
 */
export function applyPagination(page) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getTrainings());
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Reset filters
 */
export function resetFilters() {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getTrainings());
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

/**
 *  Apply T filters
 */
export function checkUnCheckFilters(name, checkBoxValue, value) {
  return dispatch => {
    let arrayKeyName;
    switch (name) {
      case 'trainingType':
        arrayKeyName = 'selectedTypes';
        break;
      case 'trainingLevels':
        arrayKeyName = 'selectedTrainingLevels';
        break;
      case 'university':
          arrayKeyName = 'selectedUniversity';
          break;
      default:
        break;
    };
    checkBoxValue ? dispatch(selectCheckbox(arrayKeyName, value)) : dispatch(unSelectCheckbox(arrayKeyName, value));
    return true;
  };
};

export const selectCheckbox = (arrayKeyName, value) => ({
  type: SELECT_CHECKBOX,
  arrayKeyName,
  value,
});

export const unSelectCheckbox = (arrayKeyName, value) => ({
  type: UNSELECT_CHECKBOX,
  arrayKeyName,
  value,
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
