import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import StarRating from 'components/starRating';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UserFooter from 'components/Footers/UserFooter';
import Select from 'react-select';
import { showWarnMsg } from 'utils/notification';
import Share from "../../components/share";
import { isRouteAllow } from 'utils/helper';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


/**
 *  Import other dependencies
 */
import { getTrainings, applyTraining, updateTrainingStatus, applyFilters, checkUnCheckFilters, applyPagination, resetFilters } from '../../modules/trainings/actions';
import { getTrainingsListing, getIsFetchingTrainings, getTotalTrainingPages, getTrainingsPage } from '../../modules/trainings/selectors';
import { trainingType, trainingLevels, university } from './trainingFilters';
import Loader from 'components/Loader';

const dateFormat = 'DD-MMM-YYYY';

const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

const trainingSchema = Yup.object().shape({
  search: Yup.string(),
  university: Yup.array(),
  category: Yup.array(),
  levels: Yup.array(),
});

const formattedArray = array => {
  return array.map(item => {
    let value = '';
    if (item.includes('Level')) {
      const res = item.split(' ');
      value = res[1];
    } else {
      value = item;
    }
    return {
      label: item,
      value: value
    };
  });
};

const customStyles = {
  control: styles => ({
    ...styles,
    minHeight: 34,
    borderColor: '#ebebeb',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 34,
    padding: '2px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
    backgroundColor: '#FFF',
    cursor: 'pointer',
  }),
  clearIndicator: styles => ({
    position: 'absolute',
    right: '16px',
    backgroundColor: '#FFF',

  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  return (
    <Select
      isMulti
      components={{ IndicatorSeparator:() => null }}
      className="ls-select-dropdown"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value);
      }} 
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class Trainings extends React.Component {
  state={
    disableStatus: true,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    trainingId:0,
  }

  openShareModal(id) {
    this.setState({
        isVisibleShare: true,
        trainingId: id,
    });
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    this.props.getTrainings();
  }

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  getFormattedDateTime = date => {
    if (!date) return;
    return moment(date).format(dateTimeFormat);
  };

  getSingleTraining = (training, index, id) => (
    <div className="col-lg-4 col-sm-6 mb-4" key={index}>
      <div className="card lS-card-shadow border-0 lS-card-radius ls-height-100 training-item">
        <div className="card-body d-flex justify-content-between flex-column">
          <div className="flex-fill">
            <div className="thumb-wrap thumb-wrap-lg border radius-10 ls-border-color mb-3">
              <img src={training.image_url} className="rounded img-fluid" alt="training-thumb" />
            </div>
            <h4 className="family-poppins-semibold text-truncate">
              <Link to={`/user/trainings/${id}`} className="link-hover-text">
                {training.title}
              </Link>
            </h4>
            <div className="d-flex align-items-center mb-2">
              <OverlayTrigger
                key={index}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-${index}`}>
                    <strong>{training.average_rating || 0}</strong>
                  </Tooltip>
                }
              >
                <div>
                  <StarRating 
                    initialRating={training && training.average_rating ? Number(training.average_rating) : 0}
                    readOnly={true}
                    onRate={() => {}}
                  />
                </div>
              </OverlayTrigger>
              <span className="ml-2 color-grey">
                {training.rating_count || 0}
              </span>
              <i className="fas fa-user ml-2 color-grey"></i>&nbsp;
            </div>
            <p className="color-grey text-truncate">{training.description}</p>
            <div className="row font-13 pb-2">
              <div className="d-flex mb-2 col-xl-6 pr-xl-1">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span className="color-grey mr-1 text-nowrap">Start Date:</span>
                <span>{training.date ? this.getFormattedDateTime(training.date) : `TBC`} </span>
              </div>
              <div className="d-flex mb-2 col-xl-6 order-xl-3 pr-xl-1">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span className="color-grey mr-1 text-nowrap">End Date:</span>
                <span>{training.end_date ? this.getFormattedDateTime(training.end_date) : `TBC`}</span>
              </div>
              <div className="d-flex mb-2 col-xl-6 order-xl-2 pl-xl-1">
                <i className="fas fa-clock color-primary line-height-common mr-2" />
                <span className="color-grey mr-1">CDP Hours:</span>
                <span>{training.cpd_hours ? training.cpd_hours + ` hrs` : `TBC` }</span>
              </div>
              <div className="d-flex mb-2 col-xl-6 order-4 pl-xl-1">
                <i className="fas fa-map-marker-alt line-height-common color-primary mr-2" />
                <span>{training.location ? training.location : `TBC`}</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap pt-2 border-top ls-border-color action-buttons">
            {/*display Applicants buttons for federation editable */}
            {training.can_edit && (
              <Link to={`/user/training-applicants/${id}`} className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
                <i className="fas fa-user-friends mr-1" />
                Applicants ({ training.applicants_count ? training.applicants_count : 0 })
              </Link>
            )}

            {/* edit for federation */}
            {training.can_edit && training.status !== `cancelled` && (
              <Link to={ `/user/training-post/${id}` } className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2">
                <i className="fas fa-pen" />
              </Link>
            )}
            
            {/* delete for federation */}
            {training.can_edit && training.status !== `cancelled` && (
              <Link to={`#`} onClick={() => this.cancelTraining(id)} className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                <i className="fas fa-trash-alt" />
              </Link>
            )}
            
            {/*display Applied/Apply button for user*/}
            {!training.can_edit && !training.has_already_applied && (
              <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                disabled={training.has_already_applied}
                onClick={() => this.applyTraining(id)}>
                Book <i className="fas fa-arrow-right ml-1" />
              </button>
            )}

            {/* <Link to={`/user/training-applicants/${id}`} className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2 font-12">
                <i className="fas fa-user-friends mr-1" />
                Applicants ({ training.applicants_count ? training.applicants_count : 0 })
              </Link> */}

            {!training.can_edit && training.has_already_applied && (
              <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2" disabled={training.has_already_applied} onClick={() => this.applyTraining(id)}>
                Booked <i className="fas fa-arrow-right ml-1" />
              </button>
            )}
            

            {/* share button */}
            {!training.can_edit && (
              <button className="btn btn-primary lS-btn-icon lS-btn-gold-style rounded-lg mt-2 mr-2"
                      onClick={() => this.openShareModal(id)} >
                <i className="fas fa-share-alt" />
              </button>
            )}

            {/*display website url*/}
            {training.link_url && (
              <a className="btn btn-primary lS-btn-icon lS-btn-gold-style rounded-lg mt-2 mr-2" target="_blank" href={ training.link_url } >
                <i className="fas fa-globe"></i>
              </a>
            )}
            {/* delete for federation */}
            {/* TODO: set to right, Enhance for confirm dialog */}
            {/* {training.can_edit && training.status !== `cancelled` && (
              <Link to={`#`} onClick={() => this.completedTraining(id)} className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 float-right ">
                Mark as Completed
              </Link>
            )}    */}
        
          </div>          
        </div>
      </div>
    </div>     
  );

  getFormikInitVal = () => {
		return { 
      search: '',
      university: [],
      category: [],
      levels: [],
    };
  };

  checkUncheckbox = (name, event, string) => {
    this.props.checkUnCheckFilters(name, event.target.checked, string);
  };

  applyTrainingFilters = (values, { setSubmitting }) => {
    const params = {
      search: values.search,
      university: values.university,
      category: values.category,
      levels: values.levels,
    };
    this.props.applyFilters(params).then(res => {
			setSubmitting(false);
		}, err => {
			setSubmitting(false);
		});
  };

  resetTrainings = (resetForm) => {
    resetForm();
    this.props.resetFilters();
  };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  applyTraining = (id) => {
    var params = {
      training_id:id,
    };

    this.props.applyTraining(params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Booked successfully.');
    })
  }

  cancelTraining = (id) => {
    var params = {
      status:'cancelled',
    };

    this.props.updateTrainingStatus(id, params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Cancelled.');
    })
  }

  completedTraining = (id) => {
    var params = {
      status:'completed',
    };

    this.props.updateTrainingStatus(id, params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Training marked as completed.');
    })
  }

  restoreTraining = (id) => {
    var params = {
      status:'active',
    };

    this.props.updateTrainingStatus(id, params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Updated status.');
    })
  }

  showWarning = (message) => {
    showSuccessMsg(message);
  }
  render() {
    const { trainings, isFetchingTrainings, totalPages, page } = this.props;
    const { isVisibleShare, trainingId } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
            {/* bread crum */}
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <h4 className="family-poppins-semibold">Trainings</h4>
                    <p className="mb-0 font-14 color-grey">Browse and book our vast range of courses and programmes.</p>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                      {isRouteAllow('/training-post') && (
                        <Link to={`/user/training-post`}>
                          <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Training</button>
                        </Link>
                      )}                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* search bar */}
            <div className="card border-0 mt-md-5 mt-4 lS-card-shadow ls-filters-bar">
              <div className="pt-0 px-2 pb-3 card-body">
                <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={trainingSchema}
                  onSubmit={this.applyTrainingFilters}
                >
                  {({ isSubmitting, resetForm }) => (
                    <>
                      <Form className="d-flex flex-wrap justify-content-between px-1">
                        <div className="px-2 flex-grow-1 mt-3">
                          <div className="input-group ls-search-field">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-transparent pr-0 font-12 color-grey" id="basic-addon1">
                                <i className="fas fa-search" />
                              </span>
                            </div>
                            <Field type="text" name="search" className="form-control border-left-0" placeholder="Search here..." />
                          </div>
                        </div>
                        <div className="px-2 flex-grow-1 mt-3">
                          <Field as="select" name="university" component={formSelect} options={university} placeholder="University" />                                  
                        </div>
                        <div className="px-2 flex-grow-1 mt-3">
                          <Field as="select" name="category" component={formSelect} options={trainingType} placeholder="Category" />                                  
                        </div>
                        <div className="px-2 flex-grow-1 mt-3">
                          <Field as="select" name="levels" component={formSelect} options={trainingLevels} placeholder="Levels" />                                  
                        </div>
                        <div className="d-flex align-items-center mt-3 ml-auto px-2">
                          <button type="submit" className="btn btn-primary mr-3 lS-btn-blue-style" disabled={isSubmitting}>
                            { 
                              isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}
                            Search
                          </button>
                          <button type="button" onClick={() => this.resetTrainings(resetForm)} className="btn btn-secondary lS-btn-red-style" disabled={isSubmitting}>Reset</button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>

            {/* Trainings Listing */}
            <div className="row mt-md-5 mt-4">
              {!isFetchingTrainings && trainings && trainings.length > 0 && trainings.map((job, index) => this.getSingleTraining(job.attributes, index, job.id))}
              {!isFetchingTrainings && trainings && trainings.length === 0 && (
                <div className="col-12">
                  <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                    No training found
                  </div>
                </div>
              )}
              {isFetchingTrainings && (
                <div className="col-12"><Loader /></div>
              )}
            </div>
            {/* Pagination */}
            <div aria-label="Page navigation example" className="ls-pagination">
              <ReactPaginate
                containerClassName={'pagination justify-content-end mt-5'}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                forcePage={page - 1}
                onPageChange={selectedItem => {
                  if (isFetchingTrainings) return;
                  this.loadPage(selectedItem.selected + 1);
                }}
                subContainerClassName={'pages pagination'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </main>
          <UserFooter />
        {/* User Share Popup */}
        {isVisibleShare && (
            <Share
                isVisible={isVisibleShare}
                endpoint={"/user/trainings/"+ trainingId}
                onClose={() => {
                    this.setState({
                      isVisibleShare: false,
                    });
                }}
                onCompleted={res => this.onCompleteImageCrop(res)}
            />
            )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Trainings.propTypes = {
  applyFilters: func.isRequired,
  checkUnCheckFilters: func.isRequired,
  getTrainings: func.isRequired,
  isFetchingTrainings: bool.isRequired,
  trainings: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  applyTraining: func.isRequired,
  updateTrainingStatus: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  trainings: getTrainingsListing(),
  isFetchingTrainings: getIsFetchingTrainings(),
  page: getTrainingsPage(),
  totalPages: getTotalTrainingPages()
});

export default connect(
  mapStateToProps,
  {
    getTrainings,
    checkUnCheckFilters,
    applyFilters,
    applyPagination,
    resetFilters,
    applyTraining,
    updateTrainingStatus,
  }
)(withRouter(Trainings));
