import React from "react";
import { func, bool, array } from "prop-types";
import { Chat, Channel, Thread, Window, ChannelList } from 'stream-chat-react';
import { MessageList, MessageInput } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import { getUserID, getChatToken, getLocalStorage } from "utils/helper";
import PerfectScrollbar from 'react-perfect-scrollbar'
import ChatHeader from './chat';
// import GroupChat from './groupChat';
// import GroupsList from './groupsList';
import 'stream-chat-react/dist/css/index.css';

import { 
  getContacts,
  loadMoreContacts,
} from "modules/sidebar/actions";
import { 
  getIsFetchingContacts,
  getJobsContacts,
} from "modules/sidebar/selectors";
import Loader from 'components/Loader';

const chatClient = new StreamChat('r5y44txgzk7m');
let channel = null;
// const staticGroups = [
//   "Work Experience",
//   "College Students",
//   "Pre-reg Students",
//   "Post- reg Students",
//   "Staff",
//   "GP & Consultants",
//   "Corporate Team",
//   "HCA & NA",
//   "GNP & N",
//   "ANP & CNS",
//   "LEADS & EDUCATORS",
//   "PM & AM",
//   "ADMIN TEAM",
//   "PHARMACISTS",
//   "STP’",
//   "East Londo",
//   "North London",
//   "North West Londo",
//   "South East London",
//   "South West Londo"
// ];

class ForumsChat extends React.Component {
  state = {
    activeTab: 'users',
    searchContact: '',
    selectedUser: '',
    selectedGroup: '',
  }

  componentWillMount() {
    const { getContacts } = this.props;
    if (getChatToken()) {
      const currentUser = JSON.parse(getLocalStorage());
      chatClient.setUser(
        {
          id: getUserID().toString(),
          name: currentUser.name,
          image: 'https://getstream.io/random_svg/?id=' + getUserID().toString() + '&name=' + currentUser.name
        },
        getChatToken(),
      );
    }
    getContacts(); // the inital contacts list
  };

  componentWillUnmount() {
    if(chatClient) {
      chatClient.disconnect();
    }
  }

  openPerticularChat = user => {
    channel = chatClient.channel('messaging', {
      members: [user.id.toString(), getUserID().toString()],
    });
    this.setState({ selectedUser: '' }, () => {
      this.setState({
        selectedUser: user
      });
    });
  };

  openGroupChat = groupId => {
    this.setState({ selectedGroup: '' }, () => {
      this.setState({
        selectedGroup: groupId
      });
    });
  };

  loadMoreContacts = () => {
    this.loadMoreContacts();
  };

  changeActiveTab = tabToActive => {
    this.setState({
      activeTab: tabToActive
    });
  };

  getFilterContacts = contacts => {
    const { searchContact } = this.state;
    if (!searchContact) return contacts;
    return contacts.filter(contact => contact.attributes.name.toLowerCase().match(searchContact.toLowerCase()));
  };

  render() {
    const { isFetchingContacts, contacts } = this.props;
    const { activeTab, selectedUser, selectedGroup } = this.state;
    const filerArray = this.getFilterContacts(contacts);
    return(
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 chat-container">
          <div className="container-fluid px-3 py-md-5 py-3 px-md-5 ls-height-100">
            <div className="row ls-height-100">
              <div className="col-lg-4 ls-height-100 chat-list-wrap">
                <span className="chat-aside-toggle ml-auto d-flex align-items-center justify-content-center d-lg-none">
                  <i className="fas fa-bars fa-lg" />
                </span>
                <div className="card d-flex flex-column ls-height-100 lS-card-radius lS-card-shadow border-0 overflow-hidden">
                  <div className="chat-list-header d-flex pt-4 nav text-center family-poppins-medium" id="chatTab" role="tablist">
                    <div className={`nav-item color-grey nav-link ${activeTab === 'users' ? 'active' : ''}`} onClick={() => this.changeActiveTab('users')}>Users</div>
                    <div className={`nav-item color-grey nav-link ${activeTab === 'groups' ? 'active' : ''}`}>Groups</div>
                  </div>
                  <div className="chat-list-content flex-fill tab-content overflow-hidden" id="chatTabContent">
                    <div className={`tab-pane fade ls-height-100 flex-column ${activeTab === 'users' ?  'show active' : ''}`} id="users" role="tabpanel" aria-labelledby="users-tab">
                      {isFetchingContacts && (
                        <div className="d-flex align-items-center ls-height-100">
                          <Loader />
                        </div>
                      )}
                      {!isFetchingContacts && (
                        <>
                          <div className="chat-list-search">
                            <div className="d-flex align-items-center px-4">
                              <img src={require("assets/images/chat/search.png")} alt="Search chat" />
                              <div className="flex-fill">
                                <input type="search" className="form-control border-0 py-3 pl-3" placeholder="Search....." onChange={e => this.setState({ searchContact: e.target.value })} />
                              </div>
                            </div>
                          </div>
                          <div className="chat-list flex-fill position-relative overflow-hidden">
                            <PerfectScrollbar className="chat-users-list">
                              {filerArray && filerArray.map(contact => (
                                <div key={contact.id} onClick={() => this.openPerticularChat(contact)} className={`${Number(contact.id) === Number(selectedUser.id) ? 'active-chat' : ''} chat-item d-flex px-3 py-2 align-items-center`}>
                                  <div className="user-icon position-relative">
                                    <div className="overflow-hidden rounded-circle">
                                      <img src={contact.attributes.profile_image_url} alt={`user${contact.id}`} />
                                    </div>
                                    {/* <span className="user-status offline" /> */}
                                  </div>
                                  <div className="flex-fill min-width0 pl-3">
                                    {/* <span className="color-grey pl-3 font-12 float-right chat-time">3:15 PM</span>  */}
                                    <h5 className="text-black family-poppins-medium text-truncate font-16 m-0">{contact.attributes.name || ''}</h5>
                                    {/* <p className="text-truncate font-14 color-grey mb-0">I'm looking to work with a designer that helps me to create my website.</p> */}
                                  </div>
                                </div>
                              ))}
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={`tab-pane fade ls-height-100 flex-column ${activeTab === 'groups' ?  'show active' : ''}`} id="groups" role="tabpanel" aria-labelledby="groups-tab">
                      <div className="chat-list-search">
                        <div className="d-flex align-items-center px-4">
                          <img src="assets/images/chat/search.png" alt="Search chat" />
                          <div className="flex-fill">
                            <input type="search" className="form-control border-0 py-3 pl-3" placeholder="Search Groups....."  />
                          </div>
                        </div>
                      </div>
                      <div className="chat-list chat-groups-list flex-fill position-relative overflow-hidden">
                        <div className="chat-item d-flex px-3 py-4">
                          <div className="user-icon position-relative">
                            <div className="overflow-hidden rounded-circle">
                              <img src={require("assets/images/chat/chat-user.png")} alt="Profile" />
                            </div>
                            <span className="user-status offline" />
                          </div>
                          <div className="flex-fill min-width0 pl-3">
                            <span className="color-grey pl-3 font-12 float-right chat-time">3:15 PM</span> 
                            <h5 className="text-black family-poppins-medium text-truncate font-16">Jennifer Fritz</h5>
                            <p className="text-truncate font-14 color-grey mb-0">I'm looking to work with a designer that helps me to create my website.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 ls-height-100">
                <div className="card d-flex flex-column ls-height-100 lS-card-radius lS-card-shadow border-0 overflow-hidden">
                  {activeTab === 'users' && !selectedUser && (
                    <div className="d-flex align-items-center ls-height-100 color-grey justify-content-center flex-column">
                      <i className="fas fa-comments font-20 mb-2" />
                      <p className="mb-0">No chat selected</p>
                    </div>
                  )}
                  {activeTab === 'users' && selectedUser && (
                    <Chat client={chatClient} theme={'messaging light'}>
                      <Channel className="pt-0" channel={channel}>
                        <Window>
                          <ChatHeader selectedUser={selectedUser}/>
                          <MessageList />
                          <MessageInput>
                            hello
                          </MessageInput>
                        </Window>
                        <Thread />
                      </Channel>
                    </Chat>
                  )}
                </div>
              </div>
            
            </div>
          </div>
        </main>
        {/* <section className="message-page">
          <div className="container-fluid">
            <div className="box">
              <div className="row no-gutters h-100">
                <div className="col-lg-4 chat-list-side">
                  <div className="head-row tabs">
                    <div className="nav nav-fill" id="nav-tab" role="tablist">
                      <div className={`nav-item nav-link ${activeTab === 'users' ? 'active' : ''}`} onClick={() => this.changeActiveTab('users')}>Users</div>
                      <div className={`nav-item nav-link ${activeTab === 'groups' ? 'active' : ''}`} onClick={() => this.changeActiveTab('groups')}>Groups</div>
                    </div>
                  </div>
                  <div className="chat-body">
                    <div className="tab-content" id="nav-tabContent">
                      <div className={`tab-pane fade ${activeTab === 'users' ?  'show active' : ''}`} id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">
                        {!isFetchingContacts && (
                          <div className="chat-list">
                            <div className="search-bar">
                              <form>
                                <div className="input-group">
                                  <input type="text" className="form-control" placeholder="Search People..." onChange={e => this.setState({ searchContact: e.target.value })} />
                                  <div className="input-group-append">
                                    <button className="btn search-btn px-3" type="button" id="button-addon2"><i className="fas fa-search" /></button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <ul>
                              {filerArray && filerArray.map(contact => (
                                <li key={contact.id} onClick={() => this.openPerticularChat(contact)} className={Number(contact.id) === Number(selectedUser.id) ? 'active' : ''}>
                                  <img src={contact.attributes.profile_image_url} alt={`user${contact.id}`} />
                                  <div className="inner-text">
                                    <span className="d-flex align-items-start">
                                      <h4>{contact.attributes.name || ''}</h4>
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className={`tab-pane fade ${activeTab === 'groups' ?  'show active' : ''}`} id="nav-groups" role="tabpanel" aria-labelledby="nav-groups-tab">
                        <div className="chat-list">
                          <div className="search-bar">
                            <form>
                              <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search Groups..." />
                                <div className="input-group-append">
                                  <button className="btn search-btn px-3" type="button" id="button-addon2"><i className="fas fa-search" /></button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 h-100">
                  {activeTab === 'users' && !selectedUser && (
                    <div className="text-center mt-3">No user selected</div>
                  )}
                  {activeTab === 'users' && selectedUser && (
                    <Chat client={chatClient} theme={'messaging light'}>
                      <Channel channel={channel}>
                        <Window>
                          <ChatHeader selectedUser={selectedUser}/>
                          <MessageList />
                          <MessageInput />
                        </Window>
                        <Thread />
                      </Channel>
                    </Chat>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    )
  }
}

ForumsChat.propTypes = {
  contacts: array.isRequired,
  getContacts: func.isRequired,
  isFetchingContacts: bool.isRequired,
  loadMoreContacts: func.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  contacts: getJobsContacts(),
  isFetchingContacts: getIsFetchingContacts(),
});

export default connect(
  mapStateToProps,
  {
    getContacts,
    loadMoreContacts
  }
)(withRouter(ForumsChat));
