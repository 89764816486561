import React from "react";
import { func, array, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserHeader from 'components/Headers/UserHeader';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import Share from "../../../components/share";
import { isRouteAllow } from 'utils/helper';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import { getTrainings, applyTraining, 
    updateTrainingStatus, applyFilters, checkUnCheckFilters, applyPagination, 
    resetFilters, postLikeDislikeWellBeing } from '../../../modules/wellBeings/actions';
import { getTrainingsListing, getIsFetchingTrainings, 
    getTotalTrainingPages, getTrainingsPage } from '../../../modules/wellBeings/selectors';
import { trainingType, trainingLevels, locations } from './filters';
import Loader from 'components/Loader';

const dateFormat = 'DD-MMM-YYYY';
const dateTimeFormat = 'DD-MMM-YYYY hh:mm A';

const filterSchema = Yup.object().shape({
  profession: Yup.array(),
  location: Yup.array(),
  type: Yup.array(),
  search: Yup.string()
});

const formattedArray = array => {
  return array.map(item => {
    let value = '';
    if (item.includes('Level')) {
      const res = item.split(' ');
      value = res[1];
    } else {
      value = item;
    }
    return {
      label: item,
      value: value
    };
  });
};

const customStyles = {
  control: styles => ({
    ...styles,
    minHeight: 34,
    borderColor: '#ebebeb',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 34,
    padding: '2px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
    backgroundColor: '#FFF',
    cursor: 'pointer',
  }),
  clearIndicator: styles => ({
    position: 'absolute',
    right: '16px',
    backgroundColor: '#FFF',

  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    color: 'inherit',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  return (
    <Select
      isMulti
      components={{ IndicatorSeparator:() => null }}
      className="ls-select-dropdown"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        form.setFieldValue(field.name, value);
      }} 
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};


class ListWellBeing extends React.Component {

  state={
    search: '',
    disableStatus: true,
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
    isVisibleShare: false,
    trainingId:0,
  }

  getFormikInitVal = () => {
		return { 
      search: '',
      occupations: [],
      location: [],
      category: [],
    };
  };

  openShareModal(id) {
    this.setState({
        isVisibleShare: true,
        trainingId: id,
    });
  }

  applyWellBeingFilters = (values, { setSubmitting }) => {
    const params = {
      search: values.search,
      occupations: values.occupations,
      location: values.location,
      category: values.category
    };
    
    this.props.applyFilters(params).then(res => {
			setSubmitting(false);
		}, err => {
			setSubmitting(false);
		});
  };

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  componentWillMount() {
    this.props.getTrainings();
  }

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  getFormattedDateTime = date => {
    if (!date) return;
    return moment(date).format(dateTimeFormat);
  };

  getSingleTraining = (training, index, id) => (
    <div className="col-lg-4 col-sm-6 mb-4" key={index}>
      <div className="card lS-card-shadow border-0 lS-card-radius ls-height-100 ls-well-being-item">
        <div className="card-body d-flex justify-content-between flex-column">
          <div className="flex-fill">
            <div className="thumb-wrap thumb-wrap-lg border radius-10 ls-border-color mb-3">
              <img src={training.attributes.image_url} alt="..." />
            </div>
            <h4 className="family-poppins-semibold text-truncate">
              <Link to={`/user/well-being/${id}`} className="link-hover-text">{training.attributes.title}</Link>
            </h4>
            <p className="font-15 color-primary mb-2"> {training.attributes.type ? training.attributes.type : `TBC`}</p>
            <p className="color-grey mb-0 text-truncate">
              {training.attributes.description ? training.attributes.description.substring(0,250) : ``}
            </p>
            <div className="font-13 pb-2 d-flex flex-wrap mt-3">
              <div className="mb-2 mr-4">
                <i className="fas fa-calendar-alt color-primary line-height-common mr-2" />
                <span>{training.attributes.profession ? training.attributes.profession : `TBC`}</span>
              </div>
              <div className="mb-2">
                <i className="fas fa-map-marker-alt color-primary line-height-common mr-2" />
                <span>{training.attributes.location ? training.attributes.location : `TBC`}</span>
              </div>
            </div>
            <div className="d-none col-xl-12 col-lg-12 col-md-12 col-sm-12 my-2 mt-3 px-2">
              <h5 className="mt-0 font-normal color-black-heading family-poppins-medium font-15">
                <Link to={`/user/well-being/${id}`}>{training.attributes.title}</Link>
              </h5>
              <p className="font-12 color-primary">{training.attributes.type ? training.attributes.type : `TBC`}  </p>
              <p className="color-grey mb-2">
              {training.attributes.description ? training.attributes.description.substring(0,250) : ``}
              </p>
              <div className="d-flex flex-wrap w-100 mb-2">
                <div className="d-flex align-items-center mr-3 mt-2">
                  <i className="fas fa-calendar-alt font-12 color-primary mr-2" />
                  <span className="font-12"> {training.attributes.profession ? training.attributes.profession : `TBC`} </span>
                </div>
                <div className="d-flex align-items-center mr-3 mt-2">
                  <i className="fas fa-map-marker-alt font-12 color-primary mr-2" />
                  <span className="font-12"> {training.attributes.location ? training.attributes.location : `TBC`} </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-2 d-flex border-top ls-border-color action-buttons">
            {/* <button className="btn btn-primary ls-btn-icon-styling-sm lS-btn-blue-style rounded-lg mt-2 mr-2 font-12">
              <i className="fas fa-eye" /> Views (4)
            </button> */}
            <a href= {training.attributes.link_url} target="_blank" className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2">
              <i className="fas fa-globe mr-1" /> Visit Website
            </a>

            {training.attributes.can_edit && training.attributes.status !== `cancelled` && (
              <Link to={ `/user/post-well-being/${id}` } 
                    className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2"
                >
                <i className="fas fa-pencil-alt" />
              </Link>
            )}
            {training.attributes.can_edit && training.attributes.status !== `cancelled` && (
              <button className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2"
                      onClick={() => this.cancelTraining(id)}>
                <i className="fas fa-trash-alt" />
              </button>
            )}
            
            {/* <a href= {training.link_url} target="_blank" className="btn btn-primary lS-btn-icon lS-btn-yellow-style rounded-lg mt-2 mr-2">
              <i className="fas fa-globe" />
            </a> */}
            {!training.attributes.can_edit && (
                <button className="btn btn-primary lS-btn-icon lS-btn-gold-style rounded-lg mt-2 mr-2" 
                        onClick={() => this.openShareModal(id)} >
                  <i className="fas fa-share-alt" />
                </button>
            )}
            <button 
                className={`btn btn-primary lS-btn-sm lS-btn-green-style rounded-lg mt-2 mr-2 ${training && training.attributes && training.attributes.like_dislikes === 1 ? '' : 'btn-unlike'}`}
                onClick={() => this.likeDislikeWellBeing(id, training.attributes.like_dislikes === 1 ? 0 : 1)}
                // disabled = {training && training.attributes && training.attributes.like_dislikes === 1}
              >
              <i className="fas fa-thumbs-up line-height-normal" /> 
              <span className="ml-1 font-weight-bold align-middle">
                {training.attributes.can_edit &&  (  
                  training.attributes.likes
                )}
              </span>
                   
            </button>
            <button 
              className={`btn btn-primary lS-btn-sm lS-btn-red-style rounded-lg mt-2 ${training && training.attributes && training.attributes.like_dislikes === -1 ? '' : 'btn-unlike'}`}
              onClick={() => this.likeDislikeWellBeing(id, training.attributes.like_dislikes === -1 ? 0 : -1)}
              // disabled = {training && training.attributes && training.attributes.like_dislikes === -1} 
            >
              <i className="fas fa-thumbs-down" /> 
              <span className="ml-1 font-weight-bold align-middle">
                {training.attributes.can_edit &&  (  
                  training.attributes.dislikes
                )}   
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  checkUncheckbox = (name, event, string) => {
    this.props.checkUnCheckFilters(name, event.target.checked, string);
  };

  searchTrainings = () => {
    const {search} = this.state;
    this.props.applyFilters(search);
  };

  resetFilters = (resetForm) => {
    resetForm();
    this.props.resetFilters();
  };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  applyTraining = (id) => {
    var params = {
      wellbeing_id:id,
    };

    this.props.applyTraining(params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Applied successfully.');
    })
  }

  cancelTraining = (id) => {
    var params = {
      status:'cancelled',
    };

    this.props.updateTrainingStatus(id, params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Cancelled.');
    })
  }

  likeDislikeWellBeing = (id, value, obj) => {
    var params = {
      likes_dislikes:value,
    };
    
    this.props.postLikeDislikeWellBeing(id, params);
  }


  restoreTraining = (id) => {
    var params = {
      status:'active',
    };

    this.props.updateTrainingStatus(id, params).then(res => {
      var message = res.message;
      this.props.getTrainings()
      showSuccessMsg(message ? message : 'Updated status.');
    })
  }

  showWarning = (message) => {
    showSuccessMsg(message);
  }
  render() {
    const { trainings, isFetchingTrainings, totalPages, page } = this.props;
    const { search, isVisibleShare, trainingId } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* <div className="container-fluid pt-5 px-3 px-md-5"> */}
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h4 className="family-poppins-semibold">Well Being</h4>
                  <p className="mb-0 font-14 color-grey">Peruse our diverse mental, social, physical and financial activities and support available.</p>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-lg-end justify-content-center">
                    {isRouteAllow('/post-well-being') && (  
                      <Link to="/user/post-well-being">
                        <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Well-Being</button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>  
           {/* search bar */}
           <div className="card border-0 mt-md-5 mt-4 lS-card-shadow ls-filters-bar">
            <div className="pt-0 px-2 pb-3 card-body">
              <Formik
                initialValues={this.getFormikInitVal()}
                validationSchema={filterSchema}
                onSubmit={this.applyWellBeingFilters}
              >
                {({ isSubmitting, resetForm }) => (
                  <>
                    <Form className="d-flex flex-wrap justify-content-between px-1">
                      <div className="px-2 flex-grow-1 mt-3">
                        <div className="input-group ls-search-field">
                          <div className="input-group-prepend">
                            <span className="input-group-text bg-transparent pr-0 font-12 color-grey">
                              <i className="fas fa-search" />
                            </span>
                          </div>
                          <Field type="text" name="search" className="form-control border-left-0" placeholder="Search here..." />
                        </div>
                      </div>
                      {/* <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="occupations" component={formSelect} options={occupations} placeholder="Occupation" />
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="duration" component={formSelect} options={durations} placeholder="Duration" />
                      </div>*/}
                     
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="location" component={formSelect} options={locations} placeholder="Location" />
                      </div>
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="type" component={formSelect} options={trainingType} placeholder="Category" />
                      </div> 
                      <div className="px-2 flex-grow-1 mt-3">
                        <Field as="select" name="profession" component={formSelect} options={trainingLevels} placeholder="Profession" />                                  
                      </div>
                      <div className="d-flex align-items-center mt-3 ml-auto px-2">
                        <button type="submit" className="btn btn-primary mr-3 lS-btn-blue-style" disabled={isSubmitting}>
                          { 
                            isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-2"></span>
                          )}
                          Search
                        </button>
                        <button type="button" onClick={() => this.resetFilters(resetForm)} className="btn btn-secondary lS-btn-red-style" disabled={isSubmitting}>Reset</button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>         
          
          {/* Events */}
          <div className="row mt-md-5 mt-4">
            {/* <div className=" col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-3 px-2">
              <div className="card shadow-sm border-0 rounded ls-well-being-item">
                <div className="card-body px-4 py-2">
                  
                </div>
              </div>
            </div> */}
            {!isFetchingTrainings && trainings && trainings.length > 0 && trainings.map((job, index) => this.getSingleTraining(job, index, job.id) )}
            {!isFetchingTrainings && trainings && trainings.length === 0 && (
              <div className="col-12">
                <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                  No well-being found
                </div>
            </div>
            )}
            {isFetchingTrainings && (
              <div className="col-12"><Loader /></div>
            )}
          </div>
          {/* Pagination */}
          <div aria-label="Page navigation example" className="ls-pagination">
                  <ReactPaginate
                    containerClassName={'pagination justify-content-end mt-5'}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    forcePage={page - 1}
                    onPageChange={selectedItem => {
                      if (isFetchingTrainings) return;
                      this.loadPage(selectedItem.selected + 1);
                    }}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
        </main>
        <UserFooter/>

        {/* <main>
          
          <div className="container-fluid">
            <div className="row job-page">
              <div className="col-lg-3">
                <div className="box training-filter">
                  <div className="b-head">
                    <h3 className="b-heading">Filter</h3>
                  </div>
                  <div className="b-body">
                    <div className="search-form">
                      <input type="text" className="form-control" onChange={event => this.handleQueryChange(event.target.value) } value={search} placeholder="Search..." />
                      <button className="btn" type="button" id="button-addon2"><i className="fas fa-search" /></button>
                    </div>
                    <h3>Location</h3>
                    <div className="job-title-flters-col">
                        {locations.map((location, index) => (
                        <div className="custom-control custom-checkbox my-1 mr-sm-2" key={index}>
                            <input type="checkbox" className="custom-control-input" onChange={event => this.checkUncheckbox('locations', event, location)}  id={'jt' + location + index}/>
                            <label className="custom-control-label" htmlFor={ 'jt' + location + index}>{location}</label>
                        </div>
                        ))}
                    </div>
                    <h3>Category</h3>
                    <div className="job-title-flters-col">
                    {trainingType.map((type, index) => (
                      <div className="custom-control custom-checkbox my-1 mr-sm-2" key={index}>
                        <input type="checkbox" className="custom-control-input" onChange={event => this.checkUncheckbox('trainingType', event, type)}  id={'jt' + type + index}/>
                        <label className="custom-control-label" htmlFor={ 'jt' + type + index}>{type}</label>
                      </div>
                    ))}
                    </div>
                    <h3>Profession</h3>
                    <div className="job-title-flters-col">
                      {trainingLevels.map((profession, index) => (
                         <div className="custom-control custom-checkbox my-1 mr-sm-2" key={index}>
                            <input type="checkbox" className="custom-control-input" onChange={event => this.checkUncheckbox('trainingLevels', event, profession)}  id={'jt' + profession + index}/>
                            <label className="custom-control-label" htmlFor={ 'jt' + profession + index}>{profession}</label>
                        </div>
                      ))}
                    </div>
                    <div className="btn-row">
                      <button className="Search-btn" disabled={isFetchingTrainings} onClick={this.searchTrainings}>Search</button>
                      <button className="reset-btn" disabled={isFetchingTrainings} onClick={this.resetFilters}>Reset</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="box jobs-view">
                  <div className="b-head">
                    <h3 className="b-heading">Well-Being</h3>
                    {isRouteAllow('/training-post') && (
                      <Link to={`/user/post-well-being`}>
                        <button className="share-btn"><i className="fas fa-plus"></i> Post Well-Being</button>
                      </Link>
                    )}
                  </div>
                  <div className="b-body">
                    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </main> */}
        {/* User Share Popup */}
        {isVisibleShare && (
            <Share
                isVisible={isVisibleShare}
                endpoint={"/user/well-being/"+ trainingId}
                onClose={() => {
                    this.setState({
                      isVisibleShare: false,
                    });
                }}
                onCompleted={res => this.onCompleteImageCrop(res)}
            />
            )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
ListWellBeing.propTypes = {
  applyFilters: func.isRequired,
  checkUnCheckFilters: func.isRequired,
  getTrainings: func.isRequired,
  isFetchingTrainings: bool.isRequired,
  trainings: array.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  applyTraining: func.isRequired,
  postLikeDislikeWellBeing: func.isRequired,
  updateTrainingStatus: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  trainings: getTrainingsListing(),
  isFetchingTrainings: getIsFetchingTrainings(),
  page: getTrainingsPage(),
  totalPages: getTotalTrainingPages()
});

export default connect(
  mapStateToProps,
  {
    getTrainings,
    checkUnCheckFilters,
    applyFilters,
    applyPagination,
    resetFilters,
    applyTraining,
    updateTrainingStatus,
    postLikeDislikeWellBeing,
  }
)(withRouter(ListWellBeing));
