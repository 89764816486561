/**
 *  Import action creator constants & dependencies
 */
import {
  GET_FEEDBACK_LOG,
  SHOW_FEEDBACK_LOG,
  DELETE_FEEDBACK_LOG,
  UPDATE_FEEDBACK_LOG,
  POST_FEEDBACK_LOG,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];


/**
   *  Get List of feedback
   */
  export function getFeedbackLog() {
    return async (dispatch) => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          getFeedbackLogBegin(
            API_URLS.REVALIDATION.GET_FEEDBACK_LIST,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const getFeedbackLogBegin = (API_URL, source) => ({
    type: GET_FEEDBACK_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'get',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

  /**
   *  Add reflective discussion
   */
  export function addFeedbackLog(params) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          addFeedbackLogBegin(
            API_URLS.REVALIDATION.POST_FEEDBACK,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const addFeedbackLogBegin = (API_URL, params, source) => ({
    type: POST_FEEDBACK_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

/**
   *  Delete
   */
  export function deleteFeedbackLog(id) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          deleteFeedbackLogBegin(
            API_URLS.REVALIDATION.DELETE_FEEDBACK + id,
            id,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const deleteFeedbackLogBegin = (API_URL, id, source) => ({
    type: DELETE_FEEDBACK_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'delete',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    },
    id
  });

  /**
   *  update List of placements
   */
  export function updateFeedbackLog(params, id) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          updateFeedbackLogBegin(
            API_URLS.REVALIDATION.UPDATE_FEEDBACK + id,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const updateFeedbackLogBegin = (API_URL, params, source) => ({
    type: UPDATE_FEEDBACK_LOG,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});


