import React from "react";
import UserHeader from 'components/Headers/UserHeader';
import { func, array, bool, number } from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import StarRating from 'components/starRating';
import { isRouteAllow } from 'utils/helper';
import { getFormattedDateTime } from 'utils/helper';
import Share from "../../components/share";
import { showSuccessMsg } from 'utils/notification';
import { showWarnMsg } from 'utils/notification';
import Loader from 'components/Loader';
import ApproveConfirmation from 'components/approveConfirmation';
import UserFooter from 'components/Footers/UserFooter';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

/**
 *  Import other dependencies
 */
import { getEvents, applyEvent, applyPagination, applyFilters, resetFilters, deleteEvent  } from '../../modules/events/actions';
import { getEventsListing, getIsFetchingEvents, getTotalPages, getEventsPage } from '../../modules/events/selectors';
import { locations, groups } from './filters';

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const customStyles = {
  control: styles => ({
    ...styles,
    minHeight: 34,
    borderColor: '#ebebeb',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 34,
    padding: '2px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
    backgroundColor: '#FFF',
    cursor: 'pointer',
  }),
  clearIndicator: styles => ({
    position: 'absolute',
    right: '16px',
    backgroundColor: '#FFF',

  }),
  placeholder: () => ({
    color: '#BABBC2',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

class EventsListing extends React.Component {
  state={
    selectedItem: null,
    isVisibleAprroval: false,
    isVisibleShare: false,
    search: null,
    selectedRegion: '',
    selectedGroup: '',
    isFiltersVisible: false,
  }

  openShareModal(id) {
    this.setState({
        isVisibleShare: true,
        eventId: id,
    });
  }

  componentWillMount() {
    this.props.getEvents();
  }

  getSingleEvent = (eventObj, index, id) => (
    <div className="col-xl-4 col-sm-6 px-xl-4 mb-4">
      <div className="card border-0 lS-card-shadow lS-card-radius ls-height-100">
        <div className="card-body d-flex">
          <div className="thumb-wrap thumb-wrap-sq border ls-border-color">
            <img src={eventObj.image_url} alt="..." className="img-object-fit" />
          </div>
          <div className="min-width0 flex-fill pl-3">
            <div className="d-flex justify-content-between flex-column ls-height-100">
              <div className="flex-fill">
                <h5 className="mt-0 font-normal color-black-heading text-truncate family-poppins-medium">
                  <Link to={`/user/events/${id}`} className="link-hover-text">
                    {eventObj.title}
                  </Link>
                </h5>
                <div className="d-flex align-items-center mb-2">
                  <OverlayTrigger
                    key={index}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        <strong>{0}</strong>
                      </Tooltip>
                    }
                  >
                    <div>
                      <StarRating 
                        initialRating={0}
                        readOnly={true}
                        onRate={() => {}}
                      />
                    </div>
                  </OverlayTrigger>
                  <span className="ml-2 color-grey">
                    {0}
                  </span>
                  <i className="fas fa-user ml-2 color-grey"></i>&nbsp;
                </div>
                {/* <p className="mb-2 font-12 rating-starts">
                  <i className="fas fa-star mr-1" />
                  <i className="fas fa-star mr-1" />
                  <i className="fas fa-star mr-1" />
                  <i className="fas fa-star mr-1" />
                  <i className="fas fa-star mr-1" />
                </p> */}
                <p className="color-grey mb-1 font-14">{eventObj.start_date ? getFormattedDateTime(eventObj.start_date) : `TBC`}</p>
                {/* <p className="color-grey m-0 font-14">12:00 PM - 2:00 PM</p> */}
              </div>
              <div className="action-buttons d-flex align-items-center flex-wrap mt-1">
                {eventObj.can_edit && (
                  <Link className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                          to={`/user/event-applicants/${id}`}>
                    <i className="fas fa-user-friends mr-1" /> Attending ({ eventObj.applicants_count ? eventObj.applicants_count : 0 })
                  </Link>
                )}
                
                {!eventObj.can_edit && !eventObj.has_already_applied && (
                  <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                          onClick={() => this.applyEvent(id)}>
                    <i className="fas fa-calendar-alt mr-1" /> Book
                  </button>
                )}

                {!eventObj.can_edit && eventObj.has_already_applied && (
                  <button className="btn btn-primary lS-btn-sm lS-btn-blue-style rounded-lg mt-2 mr-2"
                      disabled={eventObj.has_already_applied}>
                    <i className="fas fa-calendar-alt mr-1" /> Booked
                  </button>
                )}

                <Link className="btn btn-primary lS-btn-icon lS-btn-gold-style rounded-lg mt-2 mr-2"
                        to={`/user/events/${id}`}>
                  <i className="fas fa-eye" />
                </Link>

                {eventObj.can_edit && (
                  <Link className="btn btn-primary lS-btn-icon lS-btn-green-style rounded-lg mt-2 mr-2"
                          to={`/user/post-event/${id}`}>
                    <i className="fas fa-edit" />
                  </Link>
                )}
                {eventObj.can_edit && (
                  <button onClick={() => this.deleteEvent(id)} 
                          className="btn btn-primary lS-btn-icon lS-btn-red-style rounded-lg mt-2 mr-2">
                    <i className="fas fa-trash" />
                  </button>
                )}                
                <button className="btn btn-primary lS-btn-icon lS-btn-yellow-style rounded-lg mt-2"
                      onClick={() => this.openShareModal(id)}>
                  <i className="fas fa-share-alt" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  deleteEvent = (id) => {
    this.setState({
      selectedItem: id,
      isVisibleAprroval: true,
    });
  }

  loadPage = page => {
    this.props.applyPagination(page);
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  searchEvents = () => {
    const {search, selectedRegion, selectedGroup} = this.state;
    this.props.applyFilters(search, selectedRegion, selectedGroup);
  };

  resetEvents = () => {
    this.props.resetFilters();
  };

  formSelect = (options, name, placeholder, value) => {
    return (
      <Select
        components={{ IndicatorSeparator:() => null }}
        className="ls-select-dropdown"
        isSearchable={true}
        placeholder={placeholder}
        onChange={value => {
          if (name === 'region') {
            this.setState({
              selectedRegion: value.value,
            });
            return;
          }

          this.setState({
            selectedGroup: value.value,
          });
        }}
        value={value ? formattedArray([value]) : ''}
        styles={customStyles}
        options={formattedArray(options)}
      />
    )
  };

  applyEvent = (id) => {
    var params = {
      event_id:id,
    };

    this.props.applyEvent(params).then(res => {
      var message = res.message;
      this.props.getEvents()
      showSuccessMsg(message ? message : 'Booked successfully.');
    })
  }

  toggleFilters = () => {
    this.setState({
      isFiltersVisible: !this.state.isFiltersVisible,
    });
  }

  render() {
    const { events, isFetchingEvents, totalPages, page  } = this.props;
    const { isVisibleAprroval, selectedItem , isVisibleShare, search, selectedRegion, selectedGroup, eventId, isFiltersVisible} = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4 mb-5">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <h5 className="family-poppins-semibold">Events</h5>
                  <p className="mb-0 font-14 color-grey"></p>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-lg-end justify-content-center">
                    {isRouteAllow('/placement-post') && (
                      <Link to={`/user/post-event`}>
                         <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large">Post Event</button>
                      </Link>
                    )}                    
                  </div>
                </div>
              </div>
            </div>
          </div>  
          {/* Filter Code */}
          <div className="d-flex mt-md-4 mt-3 mb-md-5 mb-4">
            <button className="btn btn-primary lS-btn-blue-style lS-card-shadow ls-btn-filters rounded-lg mt-3 mr-4 font-12" onClick={() => this.toggleFilters()}>
              <i className="fas fa-filter" />
            </button>
            <div className={`${isFiltersVisible ? 'animation-animated' : ''} animated-filter-bar ls-filters-bar`}>
              <form className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
                <div className="px-2 flex-grow-1 mt-3">
                  <div className="input-group ls-search-field">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white pr-0 font-12 color-grey">
                        <i className="fas fa-search" />
                      </span>
                    </div>
                    <input type="text" name="search" className="form-control border-left-0" onChange={event => this.handleQueryChange(event.target.value) } value={search} placeholder="Search " />
                  </div>
                </div>
                <div className="px-2 flex-grow-1 mt-3">
                  {this.formSelect(locations, 'region', 'Location', selectedRegion)}
                </div>
                <div className="px-2 flex-grow-1 mt-3">
                  {this.formSelect(groups, 'group', 'Target group', selectedGroup)}
                </div>
                <div class="d-flex align-items-center mt-3 ml-auto pl-2">
                  <button disabled={isFetchingEvents} onClick={this.searchEvents}  type="button" className="btn btn-primary mr-3 lS-btn-blue-style">
                    Search
                  </button>
                  <button disabled={isFetchingEvents} onClick={this.resetEvents} className="btn btn-secondary lS-btn-red-style">Clear</button>
                </div>
              </form>
            </div>
          </div>
          {/* Events */}
          <div className="row mt-3 mx-xl-n4">
            {!isFetchingEvents && events && events.length > 0 && events.map((eventObj, index) => this.getSingleEvent(eventObj.attributes, index, eventObj.id))}

            {!isFetchingEvents && events && Object.keys(events).length === 0 && (
              <div className="col-12">
                <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                  No event found
                </div>
              </div>
            )}
            
            {isFetchingEvents && (
              <div className="col-12">
                <Loader />
              </div>
            )}
          </div>
          {/* Pagination */}
          <div aria-label="Page navigation example" className="ls-pagination">
            <ReactPaginate
              containerClassName={'pagination justify-content-end mt-5'}
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              forcePage={page - 1}
              onPageChange={selectedItem => {
                if (isFetchingEvents) return;
                this.loadPage(selectedItem.selected + 1);
              }}
              subContainerClassName={'pages pagination'}
              pageClassName={'page-item'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </main>
        <UserFooter />
        {isVisibleShare && (
          <Share
              isVisible={isVisibleShare}
              endpoint={"/user/events/"+ eventId}
              onClose={() => {
                  this.setState({
                    isVisibleShare: false,
                  });
              }}
              onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}
        <ApproveConfirmation
          isVisible={isVisibleAprroval}
          onClose={()=> {
            this.setState({
              isVisibleAprroval: false,
            });
          }}
          onApprove={()=> {
            // if (isApprovingUser) return;

            this.props.deleteEvent(selectedItem).then(res => {
              var message = res.message;
              this.props.getEvents()
              showSuccessMsg(message ? message : 'Deleted successfully.');
              this.setState({
                isVisibleAprroval: false,
              });
            })
          }}
          title={'Delete Event'}
        />
      </>
    );
  };
};


/**
 *  Define component PropTypes
 */
EventsListing.propTypes = {
  applyFilters: func.isRequired,
  events: array.isRequired,
  getEvents: func.isRequired,
  isFetchingEvents: bool.isRequired,
  page: number.isRequired,
  resetFilters: func.isRequired,
  totalPages: number.isRequired,
  applyEvent: func.isRequired,
  // publishPostNews:func.isRequired,
  deleteEvent:func.isRequired,
};

/**
*  Map redux state to component props
*/
const mapStateToProps = createStructuredSelector({
  events: getEventsListing(),
  isFetchingEvents: getIsFetchingEvents(),
  page: getEventsPage(),
  totalPages: getTotalPages()
});

export default connect(
mapStateToProps,
{
  getEvents,
  // publishPostNews,
  deleteEvent,
  applyPagination,
  applyFilters,
  applyEvent,
  resetFilters,
}
)(withRouter(EventsListing));
