/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const postEvents = () => state => state.get('postEvents');

/**
 *  Select the correct portion of the root reducer
 */
export const getEventsListing = () =>
  createSelector(postEvents(), state => {
    const listing = state.get('events').toJS()
    return listing ? listing : {}
  });

/**
 *  Select is fetching Events
 */
export const getIsFetchingEvents = () =>
  createSelector(postEvents(), state => {
    return state.get('isFetchingEvents')
  })

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(postEvents(), state => {
    return state.get('isUploadingImage')
  })


/**
 *  Select is fetching events
 */
export const getIsDeletingEvent = () =>
  createSelector(postEvents(), state => {
    return state.get('isDeletingEvent')
  })

/**
 *  Select total number of events pages
 */
export const getTotalPages = () =>
  createSelector(postEvents(), state => {
    return state.get('totalPage')
  })

/**
 *  Select current event pages
 */
export const getEventPage = () =>
  createSelector(postEvents(), state => {
    return state.get('page')
  })
