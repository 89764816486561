export const GET_LIST_OF_CLIENTS_NAME =
  'src/modules/postResources/GET_LIST_OF_CLIENTS_NAME';
export const GET_LIST_OF_MY_INVOICE_LIST =
  'src/modules/postResources/GET_LIST_OF_MY_INVOICE_LIST';
export const GET_LIST_OF_CLIENT_INVOICE_LIST =
  'src/modules/postResources/GET_LIST_OF_CLIENT_INVOICE_LIST';
export const GET_LIST_OF_CLIENT_INVOICE_BY_ID =
  'src/modules/postResources/GET_LIST_OF_CLIENT_INVOICE_BY_ID';
export const UPLOAD_IMAGE =
  'src/modules/postResources/UPLOAD_IMAGE';
export const ADD_INVOICE =
  'src/modules/postResources/ADD_INVOICE';
export const DELETE_INVOICE =
  'src/modules/postResources/DELETE_INVOICE';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/postResources/CANCEL_ALL_API_REQUESTS';
