import React, { Fragment } from "react";
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import UserHeader from 'components/Headers/UserHeader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { trainingLevels, jobType, university } from './postsFilters';
import * as Datetime from 'react-datetime';
import * as Yup from 'yup';
import * as moment from 'moment';
import { showSuccessMsg } from 'utils/notification';
import UserFooter from 'components/Footers/UserFooter';

/**
 *  Import other dependencies
 */
import {
  uploadImage,
  addPostTraining,
  updatePostTraining,
  getTrainings
} from '../../modules/trainingPost/actions';
import {
  getIsUploadingImage,
  getIsFetchingTrainings,
  getTrainingsListing
} from '../../modules/trainingPost/selectors';
import Loader from 'components/Loader';

var yesterday = Datetime.moment();
var valid = function( current ){
    return current.isAfter( yesterday );
};

const addEditJobSchema = Yup.object().shape({
  jobTitle: Yup.string()
    .required('Required'),
  organisationName: Yup.string()
    .required('Required'),
  // level: Yup.string()
  //   .required('Required'),
  // type: Yup.string()
  //   .required('Required'),
  description: Yup.string()
    .required('Required'),
  // location: Yup.string()
  //   .required('Required'),
  // date: Yup.string()
  //   .required('Required'),
  // end_date: Yup.string()
  //   .required('Required'),
  linkURL: Yup.string()
    .nullable()
    .url("Please add a valid url."),
  cpdHours: Yup.number()
    .typeError('CPD hours must be a number')
    .positive('CPD hours be greater than zero')
    .nullable(),
});

const formattedArray = array => {
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        field.name === 'type' ? form.setFieldValue('type', value.value) :
        field.name === 'level' ? form.setFieldValue('level', value.value) :
        form.setFieldValue('university', value.value)
      }}
      value={field.value ? formattedArray([field.value]) : ''}
      styles={customStyles}
      options={formattedArray(options)}
    />
  )
};

class TrainingPost extends React.Component {
  state = {
    mode: Object.keys(this.props.match.params).length > 0 ? 'edit' : 'add'
  };

  componentWillMount() {
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getTrainings(this.props.match.params.id).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/trainings');
        }
      });
    }
  }

  getFormikInitVal = () => {
    const { training } = this.props;
    const { mode } = this.state;
    if (mode === 'edit') {
      const {
        title,
        org_name,
        level,
        type,
        description,
        date,
        end_date,
        location,
        image_url,
        link_url,
        cpd_hours,
        university
      } = training.attributes || {};

      return {
        jobTitle: title,
        organisationName: org_name,
        level: level,
        description: description,
        type: type,
        location: location,
        imageUrl: image_url ? image_url : '',
        linkURL: link_url,
        university,
        date: date ? moment(date) : '',
        end_date: end_date ?  moment(end_date): '',
        cpdHours: cpd_hours,
      }
    }
    return {
      jobTitle: "",
      organisationName: "",
      level: "",
      description: "",
      type: "",
      location: "",
      imageUrl: "",
      date: '',
      end_date: '',
      linkURL:'',
      cpdHours: '',
      university:'',
    }
  };

  handleJobImage = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("image", file);
    uploadImage(formdata).then(res => {
      const { url } = res.data;
      setFieldValue('imageUrl', url);
    });
  };

  fileUpload = props => {
    const { form, field } = props;
    if (field.value) {
      return (
        <>
          <div>
            <label className="color-grey pl-3">Image Preview</label>
          </div>
          <div className="position-relative"  style={{ height: '150px', width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2" style={{ height: '150px', width: 'auto' }} />
            <div className="selectImgCross" onClick={() => form.setFieldValue('imageUrl', "")}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <label htmlFor="training-upload" className="color-grey pl-3">Upload File</label>
				<div className="custom-file">
					<input type="file" className="custom-file-input" id="training-upload" onChange={event => this.handleJobImage(event, form.setFieldValue)}/>
					<label className="custom-file-label" htmlFor="training-upload">Choose File</label>
				</div>
      </>
    )
  };

  postJob = (values, { setSubmitting, resetForm }) => {
    const { addPostTraining, updatePostTraining, history } = this.props;
    const { mode } = this.state;
    var params = {
      title: values.jobTitle,
      org_name: values.organisationName,
      level: values.level ? values.level.substr(-1): null,
      type: values.type,
      description: values.description,
      location: values.location,
      link_url: values.linkURL,
      university: values.university,
      date: values.date ? moment(values.date).format("YYYY-MM-DD HH:mm:ss") : null,
      end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : null,
      cpd_hours: values.cpdHours,
    };

    if (values.imageUrl) {
      params.image_url = values.imageUrl;
    }

    if( mode === 'edit' ){
      updatePostTraining(params, this.props.match.params.id).then(res => {
        showSuccessMsg("Training successfully updated");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/trainings');
        }
      }, err => {
        setSubmitting(false);
      });
    }

    if( mode === 'add' ){
      addPostTraining(params).then(res => {
        showSuccessMsg("Training successfully added");
        resetForm(this.getFormikInitVal);
        setSubmitting(false);
        if (history) {
          history.push('/user/trainings');
        }
      }, err => {
        setSubmitting(false);
      });
    }
  };

  render() {
    const {
      isUploadingImage,
      isFetchingTraining
    } = this.props;
    const { mode } = this.state;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0">
          <div className="container-fluid px-md-5 px-3 mt-4 mt-md-5 pb-5">
            {/* bread crum */}
            <div className="card page-title-bar border-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="family-poppins-semibold">{mode === 'edit' ? 'Edit' : 'Post'} Training</h4>
                    <p className="mb-0 font-14 color-grey"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            <div className="card mt-md-5 mt-4 lS-card-shadow border-0 lS-card-radius">
            {isFetchingTraining && (
              <Loader />
            )}
              <div className="card-body">
                {!isFetchingTraining && (
                  <Formik
                  initialValues={this.getFormikInitVal()}
                  validationSchema={addEditJobSchema}
                  onSubmit={this.postJob}
                  >
                 {({ isSubmitting, setFieldValue, values }) => (
                 <Form className="ls-form pt-2">
                  {/* <form  action encType="multipart/form-data" method="post"> */}
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="title" className="color-grey pl-3">Title</label>
                          <Field type="text" 
                                name="jobTitle"
                                className="form-control form-control-lg rounded-lg" 
                                id="title" 
                                placeholder="Enter your title" />
                          <ErrorMessage name="jobTitle" component="div" className="error-msg" />                                  
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="university" className="color-grey pl-3">University</label>
                          <Field as="select"
                                name="university" 
                                component={formSelect} 
                                options={university}
                                className="form-control form-control-lg rounded-lg" 
                                id="university" 
                                placeholder="Please select university" />
                          <ErrorMessage name="university" component="div" className="error-msg" />                                  
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="organisation" className="color-grey pl-3">Organisation</label>
                          <Field type="text" 
                                name="organisationName" 
                                className="form-control form-control-lg rounded-lg" 
                                id="organisation" 
                                placeholder="Enter your organisation" />
                          <ErrorMessage name="organisationName" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="address" className="color-grey pl-3">Address</label>
                          <Field type="text" 
                                name="location" 
                                className="form-control form-control-lg rounded-lg" 
                                id="address" 
                                placeholder="Enter your address" />
                          <ErrorMessage name="location" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="levels" className="color-grey pl-3">Levels</label>
                          <Field as="select" 
                                name="level" 
                                className="form-control form-control-lg rounded-lg" 
                                component={formSelect}
                                options={trainingLevels}
                                id="levels" 
                                placeholder="Please select" />
                          <ErrorMessage name="level" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-5">
                          <label htmlFor="category" className="color-grey pl-3">Category</label>
                          <Field as="select"
                                name="type" 
                                className="form-control form-control-lg rounded-lg" 
                                id="category" 
                                component={formSelect} 
                                options={jobType}
                                placeholder="Please select category" />
                          <ErrorMessage name="type" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group mb-5">
                          <label htmlFor="start-date" className="color-grey pl-3">Start Date</label>
                          <Datetime
                            inputProps={{placeholder: 'Select Date'}}
                            renderInput={ this.renderCustomInput }
                            closeOnSelect={true}
                            isValidDate={ valid }
                            onChange={date => {
                              if (typeof date !== "object") return;
                              setFieldValue('date', date)
                            }}
                            value={values['date']}
                          />
                          <ErrorMessage name="date" component="div" className="error-msg" />
                          {/* <input type="date" className="form-control form-control-lg rounded-lg" id="start-date" placeholder="Enter start date" /> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group mb-5">
                          <label htmlFor="end-date" className="color-grey pl-3">End Date</label>
                          <Datetime
                            inputProps={{placeholder: 'Select Date'}}
                            renderInput={ this.renderCustomInput }
                            closeOnSelect={true}
                            isValidDate={ valid }
                            onChange={end_date => {
                              if (typeof end_date !== "object") return;
                              setFieldValue('end_date', end_date)
                            }}
                            value={values['end_date']}
                          />
                          <ErrorMessage name="end_date" component="div" className="error-msg" />
                          {/* <input type="date" className="form-control form-control-lg rounded-lg" id="end-date" placeholder="Enter end date" /> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group mb-5">
                          <label htmlFor="cpd-hours" className="color-grey pl-3">CPD Hours</label>
                          <Field type="text" 
                                name="cpdHours"
                                className="form-control form-control-lg input-with-icon rounded-lg" 
                                id="cpd-hours"
                                placeholder="Enter Hours" />
                          <ErrorMessage name="cpdHours" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="row">
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            <label htmlFor="training-url" className="color-grey pl-3">Training URL</label>
                            <Field type="text" 
                                  name="linkURL" 
                                  className="form-control form-control-lg rounded-lg" 
                                  id="training-url" 
                                  placeholder="Enter URL" />
                            <ErrorMessage name="linkURL" component="div" className="error-msg" />
                          </div>
                          <div className="form-group mb-5 col-lg-12 col-md-6">
                            {!isUploadingImage && (
                                <>
                                  <Field type="file" 
                                        component={this.fileUpload}
                                        name="imageUrl"
                                        className="custom-file-input" id="file" />
                                  {/* <label className="custom-file-label" htmlFor="file">Choose File</label> */}
                                </>
                              )}
                              {isUploadingImage && (
                                <Loader />
                              )}
                          </div>
                        </div>                    
                      </div>
                      <div className="col-lg-8 col-md-12">
                        <div className="form-group d-flex flex-column ls-height-100 pb-5">
                          <label htmlFor="description" className="color-grey pl-3">Description</label>
                          <Field as="textarea" name="description" rows={3} className="flex-fill form-control form-control-lg rounded-lg" id="description" placeholder="Enter Description" defaultValue={""} />
                          <ErrorMessage name="description" component="div" className="error-msg" />
                        </div>
                      </div>                      
                    </div>
                    <div className="text-md-right text-center">
                      <button className="btn lS-btn-grey-style lS-btn-grey-large mb-3 mx-3">Cancel</button>
                      <button type="submit" disabled={isSubmitting || isUploadingImage} className="btn lS-btn-blue-style lS-btn-blue-large mx-3 mb-3">Submit</button>
                    </div>
                  {/* </form> */}
                </Form>
                )}
              </Formik>
              )}
              </div>
            </div>
          </div>
        </main>
        <UserFooter/>
        <main>
          <div className="container-fluid">
          {/* {isFetchingTraining && (
            <div className="text-center">
              <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )} */}
          {/* {!isFetchingTraining && (
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="row job-post">
                    <div className="col-md-12">
                      <div className="box">
                        <div className="b-head">
                          <h3 className="b-heading">{mode === 'edit' ? 'Edit' : 'Post'} Training</h3>
                        </div>
                        <div className="b-body">
                        <Formik
                          initialValues={this.getFormikInitVal()}
                          validationSchema={addEditJobSchema}
                          onSubmit={this.postJob}
                        >
                          {({ isSubmitting, setFieldValue, values }) => (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Title</label>
                                    <Field type="text" name="jobTitle" className="form-control" placeholder="Type Here..." />
                                    <ErrorMessage name="jobTitle" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>University</label>
                                    <Field as="select" name="university" placeholder="Select University" component={formSelect} options={university} />
                                    <ErrorMessage name="university" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Organisation</label>
                                    <Field type="text" name="organisationName" className="form-control" placeholder="Type Here..." />
                                    <ErrorMessage name="organisationName" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Address</label>
                                    <Field type="text" name="location" className="form-control" placeholder="Type Here..." />
                                    <ErrorMessage name="location" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Levels</label>
                                    <Field as="select" name="level" placeholder="Select Level" component={formSelect} options={trainingLevels} />
                                    <ErrorMessage name="level" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Category</label>
                                    <Field as="select" name="type" placeholder="Select Category" component={formSelect} options={jobType} />
                                    <ErrorMessage name="type" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Start Date</label>
                                    <Datetime
                                      inputProps={{placeholder: 'Select Date'}}
                                      renderInput={ this.renderCustomInput }
                                      closeOnSelect={true}
                                      isValidDate={ valid }
                                      onChange={date => {
                                        if (typeof date !== "object") return;
                                        setFieldValue('date', date)
                                      }}
                                      value={values['date']}
                                    />
                                    <ErrorMessage name="date" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>End Date</label>
                                    <Datetime
                                      inputProps={{placeholder: 'Select Date'}}
                                      renderInput={ this.renderCustomInput }
                                      closeOnSelect={true}
                                      isValidDate={ valid }
                                      onChange={end_date => {
                                        if (typeof end_date !== "object") return;
                                        setFieldValue('end_date', end_date)
                                      }}
                                      value={values['end_date']}
                                    />
                                    <ErrorMessage name="end_date" component="div" className="error-msg" />
                                  </div>
                                </div>
                                
                                <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor></label>
                                  {!isUploadingImage && (
                                    <div className="upload-file mt-2">
                                      <Field type="file" component={this.fileUpload} name="imageUrl" className="form-control" />
                                    </div>
                                  )}
                                  {isUploadingImage && (
                                    <div className="text-center">
                                      <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>CPD Hours</label>
                                    <Field type="text" name="cpdHours" className="form-control" placeholder="Type Here..." />
                                    <ErrorMessage name="cpdHours" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor>Training URL</label>
                                    <Field type="text" name="linkURL" className="form-control" placeholder="Type Here..." />
                                    <ErrorMessage name="linkURL" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor>Description</label>
                                    <Field as="textarea" name="description" rows={3} className="form-control" placeholder="Type Here..." />
                                    <ErrorMessage name="description" component="div" className="error-msg" />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <button type="submit" disabled={isSubmitting || isUploadingImage} className="post-btn">
                                    {
                                      isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    Post
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </main>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
TrainingPost.propTypes = {
  addPostTraining: func.isRequired,
  isFetchingTraining: bool.isRequired,
  isUploadingImage: bool.isRequired,
  training: object.isRequired,
  updatePostTraining: func.isRequired,
  uploadImage: func.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUploadingImage: getIsUploadingImage(),
  isFetchingTraining: getIsFetchingTrainings(),
  training: getTrainingsListing()
});

export default connect(
  mapStateToProps,
  {
    uploadImage,
    addPostTraining,
    updatePostTraining,
    getTrainings,
  }
)(withRouter(TrainingPost));
