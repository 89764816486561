import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import { getFormattedDate } from 'utils/helper';
import { showSuccessMsg } from 'utils/notification';
import EditPracticeLog from "components/editPracticeLog";
import ApproveConfirmation from 'components/approveConfirmation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { filterSalaryByType, filterGreaterValue } from 'utils/helper';
import Loader from 'components/Loader';
import Select from 'react-select';
import * as Yup from 'yup';

import { workSettings, practice, registration } from './fliters';
import { getPracticeLog, deletePracticeLog } from  '../../../modules/revalidation/practiceLog/actions';
import { getPracticeLogListing, getIsFetchingPracticeLog } from  '../../../modules/revalidation/practiceLog/selectors';

const addEditJobSchema = Yup.object().shape({
  workSettings: Yup.string()
    .required('Required'),
  practice: Yup.string()
    .required('Required'),
  registration: Yup.string()
    .required('Required'),
});

const customStyles = {
	container: styles => ({
		...styles,
		minHeight: 60,
		backgroundColor: '#F9F9FC',
		borderColor: '#F9F9FC',
	}),
  control: styles => ({
    ...styles,
		minHeight: 56,
		backgroundColor: '#FFFFFF',
		borderColor: '#F9F9FC',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 56,
    padding: '8px 20px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '0 8px',
    maxHeight: 30,
    alignSelf: 'auto',
  }),
  placeholder: () => ({
    color: '#BABBC2',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: () => ({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
  }),
  input: () => ({
    position: 'absolute',
    right: '0',
  }),
};

const formattedArray = (array, fieldName, form) => {
  if (fieldName === 'maxSalary') {
    const { values } = form;
    if(values && values.minSalary) {
      const result =  values.minSalary.split(" ");
      const salaryType =  result[result.length - 1];
      const filterData = filterGreaterValue(values.minSalary, filterSalaryByType(salaryType, array));
      array = filterData;
    }
  }
  return array.map(item => {
    return {
      label: item,
      value: item
    };
  });
};

const formSelect = props => {
  const { form, options, placeholder, field } = props;
  if (options.length === 0) return;
  return (
    <Select
      components={{ IndicatorSeparator:() => null }}
      className="form-control p-0"
      isSearchable={true}
      placeholder={placeholder}
      onChange={value => {
        if (field.name === 'minSalary') {
          form.setFieldValue("maxSalary", ''); // Reset max salary field
        }
        form.setFieldValue(field.name, value.value)
      }}
      value={field.value ? formattedArray([field.value], field.name, form) : ''}
      styles={customStyles}
      options={formattedArray(options, field.name, form)}
    />
  )
};

class PracticeHoursLog extends React.Component {
    state={
        isVisibleEditLog: false,
        selctedLog: null,
        logId: null,
        isVisibleAprroval: false,
        selectedItem: null,
    }

    deleteLog = (id) => {
        this.setState({
          selectedItem: id,
          isVisibleAprroval: true,
        });
      }

    componentWillMount() {
        this.props.getPracticeLog()
    }

    getFormikInitVal = () => {
      return {
        workSettings: '',
        practice: '',
      }
    }

    getSinglePracticeLog = (practiceLog, index, id) => (    
        <React.Fragment key={index}>
          <tr>
            <td className="py-4">{getFormattedDate(practiceLog.log_date)}</td>
            <td className="py-4">{practiceLog.org_name_addr || `--` }</td>
            <td className="py-4">{practiceLog.work_setting || `--` }</td>
            <td className="py-4">{practiceLog.practice_scope || `--` }</td>
            <td className="py-4">{practiceLog.hrs || `--` }</td>
            <td className="py-4">{practiceLog.registration_type || `--` }</td>
            <td className="py-4">{practiceLog.work_description || `--` }</td>
    
            <td className="text-right px-2">
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary lS-btn-icon lS-btn-green-style mr-2" onClick={() => this.handleVisibleEditCpdLog(practiceLog, id)}>
                  <i className="fas fa-edit" />
                </button>
                <button className="btn btn-secondary lS-btn-icon lS-btn-red-style mr-2" onClick={() => this.deleteLog(id)}>
                  <i className="fas fa-trash" />
                </button>
              </div>
            </td>
          </tr>
        </React.Fragment>
      );

    handleVisibleEditCpdLog = (practiceLog, id) => {
        this.setState({
            isVisibleEditLog: true,
            selectedLog: practiceLog,
            logId: id,
        });
      }
  render() {
    const {
        isVisibleEditLog,
        selectedLog,
        logId,
        isVisibleAprroval,
        selectedItem,
      } = this.state;
      const { practiceLog, isFetchingPracticeLog } = this.props;
    return (
      <>
        <UserHeader />
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="family-poppins-semibold mt-2">Practice hours log</h4>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-md-5 mt-4 family-poppins-medium">Guide to completing practice hours log</h5>
          <Formik
            initialValues={this.getFormikInitVal()}
            validationSchema={addEditJobSchema}
            onSubmit={() => {}}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="ls-form">
                <div className="row mt-lg-5 mt-4">
                  <div className="col-lg-4 mb-lg-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="workSettings" className="color-grey font-14 mb-3">Work setting</label>
                      <Field as="select" 
                        name="workSettings" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={workSettings}
                        id="workSettings" 
                        placeholder="Select Work setting" />
                      <ErrorMessage name="workSettings" component="div" className="error-msg" />                                  
                    </div>
                  </div>
                  <div className="col-lg-4 mb-lg-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="practice" className="color-grey font-14 mb-3">Scope of practice</label>
                      <Field as="select" 
                        name="practice" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={practice}
                        id="practice" 
                        placeholder="Select practice" />
                      <ErrorMessage name="practice" component="div" className="error-msg" />                                  
                    </div>
                  </div>
                  <div className="col-lg-4 mb-lg-5 mb-4">
                    <div className="form-group mb-0">
                      <label htmlFor="registration" className="color-grey font-14 mb-3">Registration</label>
                      <Field as="select" 
                        name="registration" 
                        className="form-control form-control-lg rounded-lg" 
                        component={formSelect}
                        options={registration}
                        id="registration" 
                        placeholder="Select registration" />
                      <ErrorMessage name="registration" component="div" className="error-msg" />                                  
                    </div>
                  </div>
                  <div className="col-12 mb-3 mt-3 mt-lg-0">
                    <p className="mb-4">To record your hours of practice as a registered nurse, midwife and nursing associate, please fill in a page for each of your periods of practice. Please enter your most recent practice first and then any other practice until you reach 450 hours. You can only count practice hours during the three year period since your last registration renewal or initial registration. You do not necessarily need to record individual practice hours. You can describe your practice hours in terms of standard working days or weeks. For example if you work full time, please just make one entry of hours. If you have worked in a range of settings please set these out individually. You may need to print additional pages to add more periods of practice. If you are both a nurse and a midwife or a nursing associate and nurse you will need to provide information to cover 450 hours of practice for each of these registrations.</p>
                    <button type="submit" class="btn lS-btn-blue-style lS-btn-blue-large mb-3">Export</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="card border-0 lS-card-shadow lS-card-radius mt-md-4 mt-3 mb-4">
            <div className="card-body">
              <div className="table-responsive ls-table">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col" className="pb-4 text-center" width="120">Dates</th>
                      <th scope="col" className="pb-4" width="330">Name and address of organisation</th>
                      <th scope="col" className="pb-4" width="300">Your work setting
                        <span className="d-block color-grey font-11 text-center font-weight-normal">(choose from list above)</span>
                      </th>
                      <th scope="col" className="pb-4" width="300">Your scope of practice
                        <span className="d-block color-grey font-11 text-center font-weight-normal">(choose from list above)</span
                      ></th>
                      <th scope="col" className="pb-4" width="150">Number of hours</th>
                      <th scope="col" className="pb-4" width="220">Your registration
                        <span className="d-block color-grey font-11 text-center font-weight-normal">(choose from list above)</span>
                      </th>
                      <th scope="col" className="pb-4" width="220">Brief description of your work</th>
                      <th scope="col" className="pb-4 text-right" width="100">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetchingPracticeLog && practiceLog && practiceLog.length > 0 && practiceLog.map((singleLog, index) => this.getSinglePracticeLog(singleLog.attributes, index, singleLog.id))}
                    {!isFetchingPracticeLog && practiceLog && practiceLog.length === 0 && (
                      <tr>
                        <td colspan="8">
                          <div className="text-center px-3 radius-10 family-poppins-medium">No Record found</div>
                        </td>
                      </tr>
                    )}
                    {isFetchingPracticeLog && (
                      <tr>
                        <td colspan="8">
                          <Loader />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="text-right mb-5">
            <button className="btn lS-btn-blue-style lS-btn-blue-large" onClick={() => this.handleVisibleEditCpdLog(null, null)} >
              Add Log
            </button>
          </div>
        </main>
        <UserFooter/>
        <ApproveConfirmation
          isVisible={isVisibleAprroval}
          onClose={()=> {
            this.setState({
              isVisibleAprroval: false,
            });
          }}
          onApprove={()=> {
            this.props.deletePracticeLog(selectedItem).then(res => {
              var message = res.message;
              this.props.getPracticeLog();
              showSuccessMsg(message ? message : 'Deleted successfully.');
              this.setState({
                isVisibleAprroval: false,
              });
            })
          }}
          title={'Delete feedback log'}
        />
        {isVisibleEditLog && (
          <EditPracticeLog
            isVisible={isVisibleEditLog}
            onClose={() => {
              this.setState({
                isVisibleEditLog: false
              });
            }}
            // /bookLetID={Number(bookLetID)}
            logId={Number(logId)}
            singleLog={selectedLog}
          />
        )}           



      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
PracticeHoursLog.propTypes = {
  practiceLog: array.isRequired,
  getPracticeLog: func.isRequired,
  isFetchingPracticeLog: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  practiceLog: getPracticeLogListing(),
  isFetchingPracticeLog: getIsFetchingPracticeLog(),
});

export default connect(
  mapStateToProps,
  {
    getPracticeLog,
    deletePracticeLog,
  }
)(withRouter(PracticeHoursLog));
