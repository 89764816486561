/**
 *  Import action creator constants & dependencies
 */
import {
    GET_LIST_OF_PLACEMENTS,
    ADD_PLACEMENTS,
    UPDATE_PLACEMENTS,
    DELETE_PLACEMENTS,
    UPLOAD_IMAGE,
    CANCEL_ALL_API_REQUESTS,
  } from './constants';
  import { API_URLS } from '../../configs/urls';
  import axios from 'axios';
  import qs from 'qs/lib/index';
  const cancelApiRequests = [];
  
  /**
   *  Get List of placements
   */
  export function getPlacement(id) {
    return async (dispatch) => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          getPlacementBegin(
            API_URLS.POST_PLACEMENTS.GET_PLACEMENTS + id,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const getPlacementBegin = (API_URL, source) => ({
    type: GET_LIST_OF_PLACEMENTS,
    payload: {
      request: {
        url: API_URL,
        method: 'get',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });
  
  
  /**
   *  Add List of placements
   */
  export function addPostPlacement(params) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          addPostPlacementBegin(
            API_URLS.POST_PLACEMENTS.ADD_PLACEMENTS,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const addPostPlacementBegin = (API_URL, params, source) => ({
    type: ADD_PLACEMENTS,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });
  
  /**
   *  Delete List of placements
   */
  export function deletePostPlacement(PlacementId) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          deletePostPlacementBegin(
            API_URLS.POST_PLACEMENTS.DELETE_PLACEMENTS + PlacementId,
            PlacementId,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const deletePostPlacementBegin = (API_URL, PlacementId, source) => ({
    type: DELETE_PLACEMENTS,
    payload: {
      request: {
        url: API_URL,
        method: 'delete',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    },
    PlacementId
  });
  
  /**
   *  update List of placements
   */
  export function updatePostPlacement(params, id) {
    return async dispatch => {
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          updatePostPlacementBegin(
            API_URLS.POST_PLACEMENTS.UPDATE_PLACEMENTS + id,
            params,
            source
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  };
  
  export const updatePostPlacementBegin = (API_URL, params, source) => ({
    type: UPDATE_PLACEMENTS,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        cancelToken: source.token
      }
    }
  });
  
  export function cancelAllApiRequests() {
    return dispatch => {
      cancelApiRequests.forEach(apiRequest => {
        apiRequest.cancel()
      })
      dispatch(cancelAllApiRequestsBegin())
    }
  };
  
  export const cancelAllApiRequestsBegin = () => ({
    type: CANCEL_ALL_API_REQUESTS
  });
  
  /**
   *  Upload Image
   */
  export function uploadImage(formData) {
    return async dispatch => {
      try {
        const response = await dispatch(
          uploadImageBegin(
            API_URLS.IMAGE_UPLOAD,
            formData
          )
        );
        if (response.payload) {
          const { data } = response.payload;
          return data;
        }
  
        throw response;
      } catch (error) {
        throw error.response;
      }
    };
  }
  
  export const uploadImageBegin = (API_URL, params) => ({
    type: UPLOAD_IMAGE,
    payload: {
      request: {
        url: API_URL,
        method: 'post',
        data: params,
        headers: {
          accept: 'application/json',
          'content-type': 'application/x-www-form-urlencoded'
        }
      }
    }
  });
  