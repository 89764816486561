import React from "react";
import { func, array, bool} from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { showSuccessMsg } from 'utils/notification';
import Loader from 'components/Loader';

import { getReflectiveDiscussion, addReflectiveDiscussion } from '../../../modules/revalidation/reflectiveDiscussion/actions';

import {
  getReflectiveDiscussionDetail,
  getIsFetchingReflectiveDiscussion,
} from '../../../modules/revalidation/reflectiveDiscussion/selectors'

const addEditPlacementSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  nmcPin: Yup.string()
    .required('Required'),
  nurseName: Yup.string()
    .required('Required'),
    nurseNMCPIN: Yup.string()
    .required('Required'),
  email: Yup.string()
    .required('Required'),
  address: Yup.string()
    .required('Required'),
  contactNumber: Yup.string()
    .required('Required'),
  discussionDate: Yup.string()
    .required('Required'),
  discussionSummary: Yup.string()
    .required('Required'),
});

class RefDiscForm extends React.Component {
  componentWillMount() {
    this.props.getReflectiveDiscussion()
  }

  getFormikInitVal = () => {
    const { reflectiveDiscussion } = this.props;
    
    return {
      name: reflectiveDiscussion.name,
      nmcPin: reflectiveDiscussion.nmc_pin,
      nurseName: reflectiveDiscussion.nurse_name,
      nurseNMCPIN: reflectiveDiscussion.nurse_nmc_pin,
      email: reflectiveDiscussion.email,
      address: reflectiveDiscussion.address,
      contactNumber: reflectiveDiscussion.contact_number,
      discussionDate: reflectiveDiscussion.discussion_date,
      discussionSummary: reflectiveDiscussion.discussion_summary,
    }
  };

  postDiscussion = (values, { setSubmitting, resetForm }) => {
    const { addReflectiveDiscussion, history } = this.props;
    var data = {
      name: values.name,
      nmc_pin: values.nmcPin,
      nurse_name: values.nurseName,
      nurse_nmc_pin: values.nurseNMCPIN,
      email: values.email,
      address: values.address,
      contact_number: values.contactNumber,
      discussion_date: values.discussionDate,
      discussion_summary: values.discussionSummary,
    };

    var params = {
      account_data:data
    };

    addReflectiveDiscussion(params).then(res => {
      showSuccessMsg("Relative Discussion saved.");
      this.props.getReflectiveDiscussion();
      const { reflectiveDiscussion } = this.props;
    
      resetForm({
        name: reflectiveDiscussion.name,
        nmcPin: reflectiveDiscussion.nmc_pin,
        nurseName: reflectiveDiscussion.nurse_name,
        nurseNMCPIN: reflectiveDiscussion.nurse_nmc_pin,
        email: reflectiveDiscussion.email,
        address: reflectiveDiscussion.address,
        contactNumber: reflectiveDiscussion.contact_number,
        discussionDate: reflectiveDiscussion.discussion_date,
        discussionSummary: reflectiveDiscussion.discussion_summary,
      });
      setSubmitting(false);
      if (history) {
        history.push('/user/revalidation/ref-disc-form');
      }
    }, err => {
      setSubmitting(false);
    });  
  };

  render() {
    const {
      reflectiveDiscussion,
      isFetchingReflectiveDiscussion,
    } = this.props;

    console.log()
    return (
      <>
        <UserHeader />
        <main className="m-0 px-3 px-md-5 pt-md-5 pt-4">
          {/* bread crum */}
          <div className="card border-0 page-title-bar">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h4 className="family-poppins-semibold mt-2">Reflective discussion Form</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="card border-0 lS-card-shadow lS-card-radius mt-5">
            <div className="card-body">
              {isFetchingReflectiveDiscussion && (
                <Loader />
              )}
              {!isFetchingReflectiveDiscussion && (
                <Formik
                  initialValues={{
                    name: reflectiveDiscussion.name,
                    nmcPin: reflectiveDiscussion.nmc_pin,
                    nurseName: reflectiveDiscussion.nurse_name,
                    nurseNMCPIN: reflectiveDiscussion.nurse_nmc_pin,
                    email: reflectiveDiscussion.email,
                    address: reflectiveDiscussion.address,
                    contactNumber: reflectiveDiscussion.contact_number,
                    discussionDate: reflectiveDiscussion.discussion_date,
                    discussionSummary: reflectiveDiscussion.discussion_summary,
                  }}
                  validationSchema={addEditPlacementSchema}
                  onSubmit={this.postDiscussion}
                >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="ls-form">
                    <p className="mt-4 mb-0">You must use this form to record your reflective discussion with another NMC-registered nurse, midwife or nursing associate about your five written reflective accounts. During your discussion you should not discuss patients, service users, colleagues in a way that could identify them unless they expressly agree, and in the discussion summary section below make sure you do not include any information that might identify an individual. Please refer to the section on non-identifiable information in How to revalidate with the NMC for further information. For more information about reflective discussion, please refer to our guidance sheet on reflective practice for revalidation.</p>
                    <h5 className="family-poppins-medium mt-5">To be completed by the nurse, midwife or nursing associate:</h5>
                    <div className="row pt-4">
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Name</label>
                          <Field type="text" name="name" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="name" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">NMC Pin</label>
                          <Field type="text" name="nmcPin" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="nmcPin" component="div" className="error-msg" />
                        </div>
                      </div>
                    </div>
                    <h5 className="family-poppins-medium">To be completed by the nurse, midwife or nursing associate with whom you had the discussion:</h5>
                    <div className="row pt-4">
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Name</label>
                          <Field type="text" name="nurseName" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="nurseName" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">NMC Pin</label>
                          <Field type="text" name="nurseNMCPIN" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="nurseNMCPIN" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Email address</label>
                          <Field type="text" name="email" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="email" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Professional address including postcode</label>
                          <Field type="text" name="address" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="address" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Contact number</label>
                          <Field type="text" name="contactNumber" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="contactNumber" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Date of discussion</label>
                          <Field type="text" name="discussionDate" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="discussionDate" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-5">
                          <label className="color-grey pl-3">Short summary of discussion</label>
                          <Field component="textarea" rows="3" name="discussionSummary" className="form-control" placeholder="Type Here..." />
                          <ErrorMessage name="discussionSummary" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-12 text-right">
                        <button type="submit"
                          disabled={isSubmitting}
                          className="btn lS-btn-blue-style lS-btn-blue-large mb-3">
                          { 
                            isSubmitting && (
                              <span className="spinner-border align-middle spinner-border-sm mr-2"></span>
                          )}
                          Save
                        </button>
                      </div>
                    </div>
                  </Form> 
                )}
                </Formik> 
              )}
            </div>
          </div>
        </main>
        <UserFooter/>
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
RefDiscForm.propTypes = {
  reflectiveDiscussion: array.isRequired,
  getReflectiveDiscussion: func.isRequired,
  addReflectiveDiscussion: func.isRequired,
  isFetchingReflectiveDiscussion: bool.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  reflectiveDiscussion: getReflectiveDiscussionDetail(),
  isFetchingReflectiveDiscussion: getIsFetchingReflectiveDiscussion(),
});

export default connect(
  mapStateToProps,
  {
    getReflectiveDiscussion,
    addReflectiveDiscussion
  }
)(withRouter(RefDiscForm));
