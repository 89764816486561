import React from "react";
import Modal from 'react-bootstrap/Modal';
import { func, bool } from "prop-types";
import { showSuccessMsg } from '../../utils/notification';

class Share extends React.Component {

  state = {
    dummyBaseURl: process.env.REACT_APP_WEB_BASE_URL,
  }

  copyText(){
    const el = this.textArea
    el.select()
    document.execCommand("copy");
    showSuccessMsg("Link Copied!");
    this.props.onClose();
  }

  render() {
    const { isVisible, endpoint, onClose, externalEndpoint} = this.props;
    console.log(externalEndpoint);
    const { dummyBaseURl } = this.state;
    let url = dummyBaseURl + endpoint;
    if(endpoint === undefined && externalEndpoint !== undefined ) {
      url = externalEndpoint;
    }
    
    return(
      <>
        <Modal show={isVisible} onHide={onClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input type="text" name="copyName" value={url} ref={(textarea) => this.textArea = textarea } className="form-control" readOnly  />  
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-dark" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary" onClick={() => this.copyText(url)}>
              Copy Link
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

/**
 *  Define component PropTypes
 */
Share.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
};

export default Share;