export const UPDATE_PROFILE =
  'src/modules/userProfile/UPDATE_PROFILE';
export const GET_PRACTICE =
  'src/modules/userProfile/GET_PRACTICE';
export const GET_INSTITUTION_NAME =
  'src/modules/userProfile/GET_INSTITUTION_NAME';
export const ADD_PRACTICE =
  'src/modules/userProfile/ADD_PRACTICE';
export const ADD_INSTITUTE_NAME =
  'src/modules/userProfile/ADD_INSTITUTE_NAME';
export const DELETE_EMPLOYMENTS =
  'src/modules/userProfile/DELETE_EMPLOYMENTS';
export const DELETE_EDUCATION =
  'src/modules/userProfile/DELETE_EDUCATION';
export const GET_EMPLOYMENT_LIST =
  'src/modules/userProfile/GET_EMPLOYMENT_LIST';
export const GET_EDUCATION_LIST =
  'src/modules/userProfile/GET_EDUCATION_LIST';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/userProfile/CANCEL_ALL_API_REQUESTS';
export const UPLOAD_IMAGE =
  'src/modules/userProfile/UPLOAD_IMAGE';
