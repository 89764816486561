import React from 'react';
import { withChannelContext } from 'stream-chat-react';
 
function Chat(props) {
  const { selectedUser } = props;
  const otherMember = props.members[selectedUser.id];
  return (
    <>
      <div className="px-sm-5 px-3">
        <div className="d-flex align-items-center py-3 border-bottom ls-border-color">
          <div className="user-icon position-relative">
            <div className="overflow-hidden rounded-circle">
              <img src={selectedUser.attributes.profile_image_url} alt="other user icon"/>
            </div>
            <span className={`${otherMember.user.online ? 'online' : 'offline'} user-status`} />
          </div>
          <div className="flex-fill min-width0 pl-3">
            <h5 className="text-black family-poppins-medium text-truncate font-16 mb-1">{selectedUser.attributes.name}</h5>
            <p className="text-truncate font-14 color-grey mb-0">{otherMember.user.online ? 'Active Now' : 'Offline'}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default withChannelContext(Chat);