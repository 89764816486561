/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
    GET_CATEGORIES,
    GET_POSTS,
    GET_POST,
    GET_MY_POSTS,
    //   GET_LIST_OF_JOBS,
    //   GET_LIST_OF_JOB_APPLICANTS,
    //   GET_SINGLE_JOB,
    //   GET_LIST_OF_JOB_OCCUPATIONS,
    //   SELECT_CHECKBOX,
    //   UNSELECT_CHECKBOX,
    APPLY_FILTERS,
    RESET_FILTERS,
    APPLY_PAGINATION,
    CANCEL_ALL_API_REQUESTS,
    VOTE_A_POLL,
    GET_POLLS
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
    categories: [],
    isFetchingCategories: true,
    post: null,
    isFetchingPost: false,
    posts: [],
    isFetchingPosts: true,
    limit: 10,
    page: 1,
    totalPage: 0,
    search: '',
    myPosts: [],
    isFetchingMyPosts: true,
    replies: [],

    jobs: [],
    jobOccupations: [],
    jobApplicants: [],
    range: 150000,
    selectedOccupations: [],
    selectedLocations: [],
    isFetchingJobs: true,
    isFetchingJobApplicants: true,
    isFetchingSingleJob: true,
    singleJob: null,
    selectedTypes: [],
    pollList: [],
    isFetchingPollingList: false,
});

/**
 *  Define the reducer with actions
 */
function forumsReducer(state = initialState, action) {

    switch (action.type) {
        case GET_POST:
            return fromJS({
                ...state.toJS(),
                post: null,
                isFetchingPost: true,
            })

        case success(GET_POST):
            return fromJS({
                ...state.toJS(),
                post: action.payload.data && action.payload.data.data ? action.payload.data.data.attributes : null,
                replies: action.payload.data && action.payload.data.included ? action.payload.data.included : [],
                isFetchingPost: false,
            })

        case failure(GET_POST):
            return fromJS({
                ...state.toJS(),
                isFetchingPost: false,
            })

        case GET_POSTS:
            return fromJS({
                ...state.toJS(),
                isFetchingPosts: true,
            })

        case success(GET_POSTS):
            const { data } = action.payload.data;
            const { total_pages } = action.payload.data.meta.pagination;
            return fromJS({
                ...state.toJS(),
                posts: data && data.length > 0 ? data : [],
                totalPage: total_pages,
                isFetchingPosts: false,
            })

        case failure(GET_POSTS):
            return fromJS({
                ...state.toJS(),
                isFetchingPosts: false,
            })

        case GET_MY_POSTS:
            return fromJS({
                ...state.toJS(),
                isFetchingMyPosts: true,
            })

        case success(GET_MY_POSTS):
            let my_posts = action.payload.data.data;
            let myposts_total_pages = action.payload.data.meta.pagination.total_pages;
            return fromJS({
                ...state.toJS(),
                myPosts: my_posts && my_posts.length > 0 ? my_posts : [],
                totalPage: myposts_total_pages,
                isFetchingMyPosts: false,
            })

        case failure(GET_MY_POSTS):
            return fromJS({
                ...state.toJS(),
                isFetchingMyPosts: false,
            })

        case success(GET_CATEGORIES):
            return fromJS({
                ...state.toJS(),
                categories: action.payload.data.data,
                isFetchingCategories: false,
            })

        case GET_POLLS:
            return fromJS({
                ...state.toJS(),
                pollList: [],
                isFetchingPollingList: true,
            })
        
        case success(GET_POLLS):
            let finialResult=[];
            const apiData = action.payload.data.data;
            const includes = action.payload.data.included;
            // Step: 1 - filter all indexes
            apiData.map(indexes => {
                finialResult = filterData(indexes.relationships.options.data, includes);
                indexes.option = finialResult;
                delete indexes.relationships;
                return indexes;
            });
        
            return fromJS({
                ...state.toJS(),
                pollList: apiData && apiData.length > 0 ? apiData : [],
                isFetchingPollingList: false,
            })

        case failure(GET_POLLS):
            return fromJS({
                ...state.toJS(),
                pollList: [],
                isFetchingPollingList: false,
            })

        case VOTE_A_POLL:
            var tempPollList = state.get('pollList').toJS();
            tempPollList[action.index].option[action.optionIndex].attributes.votes++;
            return fromJS({
                ...state.toJS(),
                pollList: tempPollList,
            })

        case APPLY_FILTERS:
            return fromJS({
                ...state.toJS(),
                search: action.search,
                page: 1
            })

        case APPLY_PAGINATION:
            return fromJS({
                ...state.toJS(),
                page: action.page
            })

        case RESET_FILTERS:
        case CANCEL_ALL_API_REQUESTS:
            return fromJS({
                ...initialState.toJS()
            })

        default:
            return state
    }
};

const filterData = (data, includes) => {
    return data.map( data => includes.find(value => value.type === data.type && value.id === data.id))
};
  

/**
 *  Export the reducer
 */
export default forumsReducer