/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_BOOKLETS,
  UPLOAD_IMAGE,
  GET_LIST_OF_ASSESSOR_BOOKLETS,
  GET_LIST_OF_SUPERVISOR_BOOKLETS,
  GET_USERS_LIST,
  GET_LIST_OF_SINGLE_BOOKLETS,
  APPLY_PAGINATION,
  UPDATE_SINGLE_TASK,
  UPDATE_SUPERVISION_TASK,
  UPDATE_SINGLE_INDEX_ACC,
  UPDATE_INDEX_INTERVIEW,
  CANCEL_ALL_API_REQUESTS,
  GET_LIST_OF_USER_SUBMITTED_BOOKLETS,
  APPLY_FILTERS,
  SUBMIT_FOR_CERTIFICATION,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Resources
 */
export function getBooklets(categoryId) {
  return async (dispatch, getState) => {
    const state = getState();
    const { getBookLetsListing, limit, page, search } = state.get('resources').toJS();
    const params = {
      getBookLetsListing,
      page,limit,
      filters: {
        category_id: categoryId,
      }
    };

    // Apply when search has any value
    if (search) {
      params.filters.search = search;
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getBookletsBegin(
          API_URLS.RESOURCES.GET_RESOURCES,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }
      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getBookletsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Single Resources
 */
export function getSingleBooklets(bookLetID, indexID=null) {
  return async dispatch => {
    const params ={
      filters:{
        booklet_id: bookLetID,
        index_id: indexID,
      },
      include: ['user_booklet_indexes', 'user_booklet_indexes.user_resource_tasks'],
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSingleBookletsBegin(
          API_URLS.RESOURCES.GET_SINGLE_RESOURCE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSingleBookletsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_SINGLE_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get Single booklet for supervisor
 */
export function getSupervisorSingleBooklet(bookLetID, indexID=null) {
  return async dispatch => {
    const params ={
      filters:{
        booklet_id: bookLetID,
        index_id: indexID,
      },
      include: ['user_booklet_indexes', 'user_booklet_indexes.user_resource_tasks'],
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSupervisorSingleBookletBegin(
          API_URLS.RESOURCES.SUPERVISOR_BOOKLET_DETAIL,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSupervisorSingleBookletBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_SINGLE_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Accessor and supervisor
 */
export function getUsersListing() {
  return async dispatch => {
    const params ={
      page: 1,
      limit: 1000
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getUsersListingBegin(
          API_URLS.RESOURCES.GET_USERS_LISTING,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};
  
export const getUsersListingBegin = (API_URL, params, source) => ({
  type: GET_USERS_LIST,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 * Deprecated
 *  Get List of Single Resources
 */
export function getAssessorBooklets() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('resources').toJS();
    const params ={
      page,
      limit,
      include: ['user_resource_tasks', 'user_resource_tasks.user_resource_assessment', 'user'],
    }
      const source = axios.CancelToken.source();
      cancelApiRequests.push(source);
      try {
        const response = await dispatch(
          getAssessorBookletsBegin(
            API_URLS.RESOURCES.GET_ASSESSOR_RESOURCE,
            params,
            source
          )
        );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getAssessorBookletsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_ASSESSOR_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 * Deprecate
 *  Get List of Single Resources
 */
export function getSupervisorBooklets() {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('resources').toJS();
    const params ={
      page,
      limit,
      filters:{
        pending_invites: 1,
      },
      include: ['user_resource_tasks', 'user_resource_tasks.user_resource_assessment', 'user'],
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSupervisorBookletsBegin(
          API_URLS.RESOURCES.GET_SUPERVISION_RESOURCE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSupervisorBookletsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_SUPERVISOR_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Users who submitted booklets for supervision
 */
export function getSupervisorBookletsUser(examiner) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('resources').toJS();
    const params ={
      page,
      limit,
      filters:{
        pending_invites: 1,
        examiner_type: examiner
      },
      // include: ['user_resource_tasks', 'user_resource_tasks.user_resource_assessment', 'user'],
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSupervisorBookletsUserBegin(
          API_URLS.RESOURCES.LIST_SUBMITTED_BOOKLETS_USERS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

/**
 * Get list of booklets submitted by user
 */
export function getSubmittedBooklets(examinerType, userId) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page, search } = state.get('resources').toJS();
    const params ={
      page,
      limit,
      filters:{
        pending_invites: 1,
        examiner_type: examinerType
      },
      // include: ['user_resource_tasks', 'user_resource_tasks.user_resource_assessment', 'user'],
    };

    if(search) {
      params.filters.search = search;
    }

    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getSubmittedBookletsBegin(
          API_URLS.RESOURCES.LIST_SUBMITTED_BOOKLETS_BY_USER + userId,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getSubmittedBookletsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_SUPERVISOR_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

export const getSupervisorBookletsUserBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_USER_SUBMITTED_BOOKLETS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});




/**
 *  Update single Supervisor
 */
export function updateSingleSupervisor(params, id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateSingleSupervisorBegin(
          API_URLS.RESOURCES.UPDATE_SUPERVISION_RESOURCE + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateSingleSupervisorBegin = (API_URL, params, source) => ({
  type: UPDATE_SUPERVISION_TASK,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  params
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});

/**
 *  Update single task
 */
export function updateSingleTask(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateSingleTaskBegin(
          API_URLS.RESOURCES.UPDATE_TASK_EVIDENCE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateSingleTaskBegin = (API_URL, params, source) => ({
  type: UPDATE_SINGLE_TASK,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Update single index Accessor and supervisor
 */
export function updateIndexAccSup(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateIndexAccSupBegin(
          API_URLS.RESOURCES.UPDATE_INDEX_ACCSUP,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateIndexAccSupBegin = (API_URL, params, source) => ({
  type: UPDATE_SINGLE_INDEX_ACC,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Update single index interview
 */
export function updateIndexInterview(params) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateIndexInterviewBegin(
          API_URLS.RESOURCES.UPDATE_INDEX_INTERVIEW,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const updateIndexInterviewBegin = (API_URL, params, source) => ({
  type: UPDATE_INDEX_INTERVIEW,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  params
});


/**
 *  Submit for Certificate
 */
export function submitForCertification(id) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
          submitForCertificationBegin(
              API_URLS.RESOURCES.SUBMIT_FOR_CERTIFICATION + id,
              source
          )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const submitForCertificationBegin = (API_URL, source) => ({
  type: UPDATE_SUPERVISION_TASK,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      // data: JSON.stringify([]),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
});

/**
 *  Upload Image
 */
export function uploadImage(formData) {
  return async dispatch => {
    try {
      const response = await dispatch(
        uploadImageBegin(
          API_URLS.IMAGE_UPLOAD,
          formData
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const uploadImageBegin = (API_URL, params) => ({
  type: UPLOAD_IMAGE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  }
});

/**
 *  Apply pagination
 */
export function applyPagination(page, categoryId) {
  return async dispatch => {
    dispatch(applyPaginationBegin(page));
    dispatch(getBooklets(categoryId));
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Apply T filters
 */
export function applyFilters(search, categoryId) {
  return async dispatch => {
    dispatch(applyFiltersBegin(search));
    dispatch(getBooklets(categoryId));
  };
};

/**
 *  Apply T filters
 */
export function applyFiltersSubmittedBooklets(search, examinerType, id) {
  
  return async dispatch => {
    dispatch(applyFiltersBegin(search));
    dispatch(getSubmittedBooklets(examinerType, id));
  };
};


export const applyFiltersBegin = (search) => ({
  type: APPLY_FILTERS,
  search,
});

