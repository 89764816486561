/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../../utils/redux';
import {
  GET_REFLECTIVE_ACCOUNT,
  CANCEL_ALL_API_REQUESTS,
} from './constants';

/**
 *  Set intial state
 */
const initialState = fromJS({
  limit: 10,
  page: 1,
  totalPage: 0,
  reflectiveAccount: {},
  isFetchingReflectiveAccount: false,
});

/**
 *  Define the reducer with actions
 */
function reflectiveAccountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REFLECTIVE_ACCOUNT:
      return fromJS({
        ...state.toJS(),
        reflectiveAccount: {},
        isFetchingReflectiveAccount: true,
      })

    case success(GET_REFLECTIVE_ACCOUNT):
      return fromJS({
        ...state.toJS(),
        reflectiveAccount: action.payload.data.data.attributes,
        isFetchingReflectiveAccount: false,
      })
      
    case failure(GET_REFLECTIVE_ACCOUNT):
      return fromJS({
        ...state.toJS(),
        isFetchingReflectiveAccount: false,
      })

    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...initialState.toJS()
      })

    // case APPLY_PAGINATION:
    //     return fromJS({
    //       ...state.toJS(),
    //       page: action.page
    //     })

    default:
      return state
  }
}

/**
 *  Export the reducer
 */
export default reflectiveAccountReducer
