export const LOGIN_USER =
  'src/modules/auth/LOGIN_USER';
export const PUBLIC_PASSPORT =
  'src/modules/auth/PUBLIC_PASSPORT';
export const FORGOT_USER =
  'src/modules/auth/FORGOT_USER';
export const RESET_PASSWORD_USER =
  'src/modules/auth/RESET_PASSWORD_USER';
export const REGISTER_USER =
  'src/modules/auth/REGISTER_USER';
export const VERIFY_EMAIL =
  'src/modules/auth/VERIFY_EMAIL';
export const LIST_EDUCATION_INSTITUTE =
  'src/modules/auth/LIST_EDUCATION_INSTITUTE';
export const LIST_PRACTICES =
  'src/modules/auth/LIST_PRACTICES';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/auth/CANCEL_ALL_API_REQUESTS';
