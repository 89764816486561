/**
 *  Import action creator constants & dependencies
 */
import {
  UPDATE_PROFILE,
  GET_PRACTICE,
  GET_INSTITUTION_NAME,
  ADD_PRACTICE,
  ADD_INSTITUTE_NAME,
  DELETE_EMPLOYMENTS,
  DELETE_EDUCATION,
  GET_EMPLOYMENT_LIST,
  GET_EDUCATION_LIST,
  CANCEL_ALL_API_REQUESTS,
  UPLOAD_IMAGE,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Update Profile
 */
export function updateProfile(params) {
  return async (dispatch, getState) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        updateProfileBegin(
          API_URLS.PROFILE.UPDATE_PROFILE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const updateProfileBegin = (API_URL, params, source) => ({
  type: UPDATE_PROFILE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Practice
 */
export function getPractice() {
  return async (dispatch) => {
    const params = {
      limit: 1000
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getPracticeBegin(
          API_URLS.USER_PROFILE.GET_PRACTICE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getPracticeBegin = (API_URL, params, source) => ({
  type: GET_PRACTICE,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Institution Name
 */
export function getInstitutionName() {
  return async (dispatch) => {
    const params = {
      limit: 1000
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getInstitutionNameBegin(
          API_URLS.USER_PROFILE.GET_INSTITUTION_NAME,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getInstitutionNameBegin = (API_URL, params, source) => ({
  type: GET_INSTITUTION_NAME,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Employments
 */
export function getEmployments() {
  return async (dispatch) => {
    const params = {};
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getEmploymentsBegin(
          API_URLS.USER_PROFILE.GET_EMPLOYMENTS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getEmploymentsBegin = (API_URL, params, source) => ({
  type: GET_EMPLOYMENT_LIST,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Get List of Education
 */
export function getEducation() {
  return async (dispatch,getState) => {
    const params = {
      limit: 10,
      page: 1
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getEducationBegin(
          API_URLS.USER_PROFILE.GET_EDUCATIONS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getEducationBegin = (API_URL, params, source) => ({
  type: GET_EDUCATION_LIST,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Add Practice
 */
export function addPracticeEmployment(params) {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addPracticeEmploymentBegin(
          API_URLS.USER_PROFILE.ADD_PRACTICE,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addPracticeEmploymentBegin = (API_URL, params, source) => ({
  type: ADD_PRACTICE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Add Education
 */
export function addInstituteName(params) {
  return async (dispatch) => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        addInstituteNameBegin(
          API_URLS.USER_PROFILE.GET_EDUCATIONS,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const addInstituteNameBegin = (API_URL, params, source) => ({
  type: ADD_INSTITUTE_NAME,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Delete List of Trainings
 */
export function deleteEmployment(EmploymentID) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        deleteEmploymentBegin(
          API_URLS.USER_PROFILE.DELETE_EMPLOYMENT + EmploymentID,
          EmploymentID,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const deleteEmploymentBegin = (API_URL, EmploymentID, source) => ({
  type: DELETE_EMPLOYMENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'delete',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  EmploymentID
});

/**
 *  Delete List of Trainings
 */
export function deleteEducation(EmploymentID) {
  return async dispatch => {
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        deleteEducationBegin(
          API_URLS.USER_PROFILE.DELETE_EDUCATION + EmploymentID,
          EmploymentID,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const deleteEducationBegin = (API_URL, EmploymentID, source) => ({
  type: DELETE_EDUCATION,
  payload: {
    request: {
      url: API_URL,
      method: 'delete',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      cancelToken: source.token
    }
  },
  EmploymentID
});

/**
 *  Upload Image
 */
export function uploadImage(formData) {
  return async dispatch => {
    try {
      const response = await dispatch(
        uploadImageBegin(
          API_URLS.IMAGE_UPLOAD,
          formData
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
}

export const uploadImageBegin = (API_URL, params) => ({
  type: UPLOAD_IMAGE,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  }
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
