/**
 *  Import node modules
 */
import { createSelector } from 'reselect'

/**
 *  Select the portion of the root reducer
 */
export const teams = () => state => state.get('teams');

/**
 *  Select the users
 */
export const getUsersList = () =>
    createSelector(teams(), state => {
        const listing = state.get('users').toJS()
        return listing.length > 0 ? listing : []
    });

/**
 *  Select is fetching users
 */
export const getIsFetchingUsers = () =>
    createSelector(teams(), state => {
        return state.get('isFetchingUsers')
    })

/**
 *  Select total number of users pages
 */
export const getTotalPages = () =>
    createSelector(teams(), state => {
        return state.get('totalPage')
    })

/**
 *  Select current team page
 */
export const getPage = () =>
    createSelector(teams(), state => {
        return state.get('page')
    })