const workSettings = [
  "Ambulance service",
  "Care home sector",
  "Community setting (including district nursing and community psychiatric nursing)",
  "Consultancy",
  "Cosmetic or aesthetic sector",
  "Governing body or other leadership",
  "GP practice or other primary care",
  "Hospital or other secondary care",
  "Inspectorate or regulator",
  "Insurance or legal",
  "Maternity unit or birth centre",
  "Mioptiontary",
  "Occupational health",
  "Pooptionce",
  "Pooptioncy organisation",
  "Prison",
  "Private domestic setting",
  "Puboptionc health organisation",
  "School",
  "Speciaoptionst or other tertiary care including hospice",
  "Telephone or e-health advice",
  "Trade union or professional body",
  "University or other research facioptionty",
  "Voluntary or charity sector",
  "Other",
];

const practice = [
  "Commissioning",
  "Consultancy",
  "Education",
  "Management",
  "Pooptioncy",
  "Direct patient care",
  "Quaoptionty assurance or inspection",
];

const registration = [
  "Nurse",
  "Midwife",
  "Nurse/SCPHN",
  "Midwife/SCPHN",
  "Nurse and Midwife (including Nurse/SCHPN and Midwife/SCPHN)",
];

export {
  workSettings,
  practice,
  registration,
};
