import React from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ImageCroppingModal from 'components/imageCroppingModal';
import { func, bool, array } from "prop-types";
import { getLocalStorage, getToken, getChatToken, setLocalStorage } from '../../utils/helper';
import { showSuccessMsg } from '../../utils/notification';
import UserHeader from 'components/Headers/UserHeader';
import UserFooter from 'components/Footers/UserFooter';
import EditProfile from 'components/editProfile';
import Loader from 'components/Loader';
import * as moment from 'moment';
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

/**
 *  Import other dependencies
 */
import { 
  updateProfile, 
  getPractice, 
  getEmployments, 
  getEducation, 
  getInstitutionName, 
  deleteEmployment, 
  deleteEducation, 
} from '../../modules/userProfile/actions';
import { 
  getIsUpdatingProfile, 
  getIsPractice, 
  getIsEmploymentList, 
  getIsEducationList,
  getIsInstitutionName, 
  getIsFetchingEmployment, 
  getIsFetchingEducation, 
} from '../../modules/userProfile/selectors';
import { getEducationInstitute } from '../../modules/auth/actions';
import { getEducationInstituteList } from '../../modules/auth/selectors';
import ShareProfile from "../../components/shareProfile";
import AddEmployment from "../../components/addEmployment";
import AddEducation from "../../components/addEducation";
import CovidProfile from "components/covidProfile";

const dateFormat = 'DD-MMM-YYYY';

class Profile extends React.Component {
  state = {
    userProfile: JSON.parse(getLocalStorage()),
    tempSelectedFile: null,
    imageType: '',
    isVisibleCropperPopup: false,
    isVisibleCovidProfilePopup: false,
    isVisibleEditProfile: false,
    isVisibleShareProfile: false,
    isVisibleAddEmployment: false,
    isVisibleAddEducation: false,
    imagePopup: '',
    isImagePopupOpen: false,
  };

  componentWillMount() {
    this.props.getEducationInstitute();
    this.props.getPractice();
    this.props.getEmployments();
    this.props.getEducation();
    this.props.getInstitutionName();
  };

  handleProfileImage = event => {
    this.setState({
      tempSelectedFile: event.target.files[0],
      imageType: 'profile',
      isVisibleCropperPopup: true
    });
  };

  handleSignatureImage = event => {
    this.setState({
      tempSelectedFile: event.target.files[0],
      imageType: 'signature',
      isVisibleCropperPopup: true
    });
  };

  imageCroppingModalClose() {
    this.setState({
      isVisibleCropperPopup: false,
    });
  };
  
  onCompleteImageCrop = res => {
    const { data, included } = res;
    const includeProfile = 
      included.find(
        include =>
          data.relationships.user_profile.data.id === include.id
      );
    let combineObj = {...data.attributes, ...includeProfile.attributes};
    combineObj.token = getToken();
    combineObj.chat_token = getChatToken();
    setLocalStorage(combineObj);
    this.setState({
      userProfile: combineObj
    });
    showSuccessMsg('Profile updated successfully');
  };

  getFormattedDate = date => {
    if (!date) return;
    return moment(date).format(dateFormat);
  };

  openEditProfile() {
    this.setState({
      isVisibleEditProfile: true,
    });
  };
  openShareModal(){
    this.setState({
      isVisibleShareProfile: true,
    });
  }
  openAddEmployment(){
    this.setState({
      isVisibleAddEmployment: true,
    });
  }
  openAddEducation(){
    this.setState({
      isVisibleAddEducation: true,
    });
  }
  openCovidProfile(){
    this.setState({
      isVisibleCovidProfilePopup: true,
    });
  }
  deleteEmployment(value){
    this.props.deleteEmployment(value.id).then(res =>{
      this.props.getEmployments()
      showSuccessMsg('Employment Delete successfully!');
    })
  }

  deleteEducation(value){
    this.props.deleteEducation(value.id).then(res =>{
      this.props.getEducation()
      showSuccessMsg('Education Delete successfully!');
    })
  }

  render() {
    const { 
      userProfile, 
      isVisibleCropperPopup,
      isVisibleEditProfile,
      isVisibleShareProfile,
      isVisibleAddEmployment,
      isVisibleAddEducation,
      isVisibleCovidProfilePopup,
      tempSelectedFile,
      imageType,
      isUpdatingProfile,
      imagePopup,
      isImagePopupOpen,
    } = this.state;
    const { 
      updateProfile,
      instituteList,
      isPractice,
      isInstitutionName,
      isEmploymentList, 
      isEducationList, 
      isFetchingEmployment,
      isFetchingEducation,
    } = this.props;
    return (
      <>
        <UserHeader userProfile={userProfile}/>
        {/* Page content */}
        <main className="m-0 px-3 px-md-5">
          {/* bread crum */}
          <div class="pt-md-5">
            <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded">
              <div className="card-body py-0">
                <div className="row">
                  <div className="py-4 pt-lg-0 col-xl-3 col-md-6 col-sm-12 order-xl-1 order-md-1 text-center pl-lg-5">
                    <div className="ls-profile-image-container img-hover-wrap d-inline-block">
                      <img src={userProfile.profile_image_url} className="img-object-fit radius-10 ls-profile-img d-block mx-auto" alt="User Profile" />
                      <label htmlFor="profile-image" className="flex-column align-items-center justify-content-center hover-image-text position-absolute">
                        <div className="hover-profile-content">
                          <input type='file' className="d-none" id='profile-image' onChange={event => this.handleProfileImage(event)} /> 
                          <i className="far fa-edit"></i>
                          <p className="mb-0">Update Profile Image</p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="py-md-4 py-0 col-xl-3 col-md-6 order-xl-2 order-md-3 font-20">
                    <div className="d-flex mb-3">
                      <i className="fas fa-user-circle color-primary line-height-common mr-3"></i>
                      <span className="color-grey mr-1">Surname:</span>
                      <span>{userProfile.last_name || '-'}</span>
                    </div>
                    <div className="d-flex mb-3">
                      <i className="fas fa-user-circle color-primary line-height-common mr-3"></i>
                      <span className="color-grey mr-1">Given Name:</span>
                      <span>{userProfile.first_name || '-'}</span>
                    </div>
                    <div className="d-flex mb-3">
                      <i className="fas fa-mars-stroke color-primary line-height-common mr-3"></i>
                      <span className="color-grey mr-1">Sex:</span>
                      <span>{userProfile.gender || '-'}</span>
                    </div>
                    <div className="d-flex">
                      <i className="fas fa-briefcase color-primary line-height-common mr-3"></i>
                      <span className="color-grey mr-1">Occupation:</span>
                      <span>{userProfile.occupation || '-'}</span>
                    </div>
                  </div>
                  <div className="py-4 col-xl-3 col-md-6 order-xl-3 order-md-4 font-20">
                    {userProfile.has_pin && (
                      <>
                        <div className="d-flex mb-3">
                          <i className="fas fa-calendar-alt color-primary line-height-common mr-3"></i>
                          <span className="color-grey mr-1">Registration Date:</span>
                          <span>{this.getFormattedDate(userProfile.date_of_med_registration) || '-'}</span>
                        </div>
                        <div className="d-flex mb-3">
                          <i className="fas fa-calendar-alt color-primary line-height-common mr-3"></i>
                          <span className="color-grey mr-1">Revalidation Date:</span>
                          <span>{this.getFormattedDate(userProfile.date_of_revalidation) || '-'}</span>
                        </div>
                        <div className="d-flex mb-3">
                          <i className="fas fa-key color-primary line-height-common mr-3"></i>
                          <span className="color-grey mr-1">PIN Number:</span>
                          <span className="color-light-red">{userProfile.pin || '-'}</span>
                        </div>
                      </>                    
                    )}
                    <div className="d-flex">
                      <button className="btn btn-primary lS-btn-icon lS-btn-blue-style rounded-lg mr-2" onClick={() => this.openEditProfile()}>
                        <i className="fas fa-edit"></i>
                      </button>
                      <button className="btn btn-primary lS-btn-icon lS-btn-yellow-style rounded-lg" onClick={() => this.openShareModal()}>
                        <i className="fas fa-share-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div className="py-md-4 pt-0 pb-4 col-xl-3 col-md-6 order-xl-4 order-md-2 col-sm-12 ls-profile-form">
                    <div className="upload-file img-hover-wrap ls-height-100">
                      <label htmlFor="signature-image" className="flex-column align-items-center justify-content-center hover-image-text position-absolute mb-0">
                        <div className="hover-profile-content text-center">
                          <input type='file' className="d-none" id='signature-image' onChange={event => this.handleSignatureImage(event)} />
                          <i className="far fa-edit"></i>
                          <p className="mb-0">Update Signature Image</p>
                        </div>
                      </label>
                      <label className="mb-0 dotted-label-field" htmlFor="signature-image">
                        {userProfile.signature_image_url && (
                          <img className="img-object-fit" src={userProfile.signature_image_url} alt='signature_image' />
                        )}
                        {!userProfile.signature_image_url && (
                          <>
                            <i className="fas fa-upload fa-3x d-flex justify-content-center align-items-end" />
                            <span className="border-0 w-100 d-flex justify-content-center font-16 mt-3 font-weight-600"> Upload Signature </span>
                          </>  
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Education Table Added Here */}
          <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded">
            <div className="card-body">
              <div className="d-flex align-items-sm-center flex-column flex-sm-row align-items-start justify-content-between">
                <h4 className="family-poppins-semibold"> Education </h4>
                <button type="button" className="btn btn-primary lS-btn-blue-large" onClick={() => this.openAddEducation()}> Add Education </button>
              </div>
              {/* Table For Education */}
              <div className="row mt-4">
                <div className="col-xl-12 table-responsive">
                  <table className="table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center" width="100"> Certificate </th>
                        <th scope="col" className="text-center" width="200"> Institution </th>
                        <th scope="col" className="text-center" width="200"> Qualification </th>
                        <th scope="col" className="text-center" width="150"> Result </th>
                        <th scope="col" className="text-center" width="200"> Completion Date </th>
                        <th scope="col" className="text-right" width="80"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isFetchingEducation && isEducationList && isEducationList.length > 0 && isEducationList.map((education, index) => {
                        const {educational_institution_name, academic_degree_name, academic_degree_image_url, completion_date, result} = education.attributes
                        return(
                          <tr key={index}>
                            <td className="color-grey text-center align-middle">
                              <a href="#" onClick={() => this.setState({ isImagePopupOpen: true, imagePopup: academic_degree_image_url })}>
                                <div className="thumb-wrap thumb-wrap-sm rounded-lg">
                                  <img src={academic_degree_image_url} alt="" className="img-object-fit" />
                                </div>
                              </a>
                            </td>
                            <td className="color-grey text-center align-middle">{educational_institution_name}</td>
                            <td className="color-grey text-center align-middle"><span>{academic_degree_name}</span></td>
                            <td className="color-grey text-center align-middle">{result}</td>
                            <td className="color-grey text-center align-middle">{this.getFormattedDate(completion_date)}</td>
                            <td className="color-grey align-middle">
                              <div className="d-flex justify-content-end align-items-center">
                                <button className="btn btn-primary lS-btn-icon-sm font-12 lS-btn-red-style rounded-lg mr-2" onClick={() => this.deleteEducation(education)}>
                                  <i className="fas fa-trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!isFetchingEducation && isEducationList && isEducationList.length === 0 && (
                    <div className="text-center font-12">No Employments found</div>
                  )}
                  {isFetchingEducation && (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Employment Table Added Here */}
          <div className="card mt-md-5 mt-4 shadow-sm border-0 rounded">
            <div className="card-body">
              <div className="d-flex align-items-sm-center flex-column flex-sm-row align-items-start justify-content-between">
                <h4 className="family-poppins-semibold"> Employment </h4>
                <button type="button" className="btn btn-primary lS-btn-blue-large" onClick={() => this.openAddEmployment()}> Add Employment </button>
              </div>
              {/* Table For Employment */}
              <div className="row mt-4">
                <div className="col-xl-12 table-responsive">
                  <table className="table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center" width="200"> Job Title </th>
                        <th scope="col" className="text-center" width="60"> Band </th>
                        <th scope="col" className="text-center" width="200"> Practice Name </th>
                        <th scope="col" className="text-center" width="150"> Start Date </th>
                        <th scope="col" className="text-center" width="150"> End Date </th>
                        <th scope="col" className="text-center" width="150"> Duration </th>
                        <th scope="col" className="text-right" width="80"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isFetchingEmployment && isEmploymentList && isEmploymentList.length > 0 && isEmploymentList.map((employ, index) => {
                        const {job_title, band, practice_name, start_date, end_date, duration } = employ.attributes
                        return(
                          <tr key={index}>
                            <td className="color-grey text-center align-middle">{job_title}</td>
                            <td className="color-grey text-center align-middle"><span>{band}</span></td>
                            <td className="color-grey text-center align-middle">{practice_name}</td>
                            <td className="color-grey text-center align-middle">{this.getFormattedDate(start_date)}</td>
                            <td className="color-grey text-center align-middle">{this.getFormattedDate(end_date) || `Current Employment.` }</td>
                            <td className="color-grey text-center align-middle">{duration}</td>
                            <td className="color-grey align-middle">
                              <div className="d-flex justify-content-end align-items-center">
                                <button className="btn btn-primary lS-btn-icon-sm font-12 lS-btn-red-style rounded-lg mr-2" onClick={() => this.deleteEmployment(employ)}>
                                  <i className="fas fa-trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!isFetchingEmployment && isEmploymentList && isEmploymentList.length === 0 && (
                    <div className="text-center font-12">No Employments found</div>
                  )}
                  {isFetchingEmployment && (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Profile Listing */}
          <div className="row mt-md-5 mt-4 mb-5">
            <div className=" col-md-4 col-sm-12 pb-2 mb-5 border-10 ls-position-relative">
              <Link to={`/user/training-applied`} className="image-box link-hover-text">
                <div className="ls-profile-container">
                  <img src={require("assets/images/profile/my-booking.png")} alt="..." className="img-fluid border-10 ls-height-100 ls-object-cover w-100" />
                  <div className="ls-image-text-container">
                    <h5 className="family-poppins-semibold">
                      My Bookings
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className=" col-md-4 col-sm-12 pb-2 mb-5 border-10 ls-position-relative">
              <Link to={`/user/previous-booking-training`} className="image-box link-hover-text">
                <div className="ls-profile-container">
                  <img src={require("assets/images/profile/my-certificates.png")} alt="..." className="img-fluid border-10 ls-height-100 ls-object-cover w-100" />
                  <div className="ls-image-text-container">
                    <h5 className="family-poppins-semibold">
                      My Certificates
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className=" col-md-4 col-sm-12 pb-2 mb-5 border-10 ls-position-relative">
              <Link to={`/user/appraisals`} className="image-box link-hover-text">
                <div className="ls-profile-container">
                  <img src={require("assets/images/profile/appraisals.png")} alt="..." className="img-fluid border-10 ls-height-100 ls-object-cover w-100" />
                  <div className="ls-image-text-container">
                    <h5 className="family-poppins-semibold">
                      Appraisals
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </main>
        <UserFooter />

        {/* Image Cropper Modal */}
        {isVisibleCropperPopup && (
          <ImageCroppingModal 
            onClose={this.imageCroppingModalClose.bind(this)}
            onCompleted={res => this.onCompleteImageCrop(res)}
            callBackFunc={updateProfile}
            file={tempSelectedFile}
            imageType={imageType}
            isVisible={isVisibleCropperPopup}
          />
        )}

        {/* User Profile Popup */}
        {isVisibleEditProfile && (
          <EditProfile 
            isVisible={isVisibleEditProfile}
            userProfile={userProfile}
            updateProfile={updateProfile}
            onClose={() => {
              if (isUpdatingProfile) return;
              this.setState({
                isVisibleEditProfile: false,
              });
            }}
            instituteList={instituteList}
            onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}
        
        {/* User Share Popup */}
        {isVisibleShareProfile && (
          <ShareProfile
            isVisible={isVisibleShareProfile}
            userProfile={userProfile}
            updateProfile={updateProfile}
            onClose={() => {
              if (isUpdatingProfile) return;
              this.setState({
                isVisibleShareProfile: false,
              });
            }}
            instituteList={instituteList}
            onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}
        
        {/* Add Employment Popup */}
        {isVisibleAddEmployment && (
          <AddEmployment
            isVisible={isVisibleAddEmployment}
            userProfile={userProfile}
            updateProfile={updateProfile}
            users={isPractice}
            onClose={() => {
              if (isUpdatingProfile) return;
              this.setState({
                isVisibleAddEmployment: false,
              });
            }}
            instituteList={instituteList}
            onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}

        {/* Add Education Popup */}
        {isVisibleAddEducation && (
          <AddEducation
            isVisible={isVisibleAddEducation}
            userProfile={userProfile}
            updateProfile={updateProfile}
            users={isInstitutionName}
            onClose={() => {
              if (isUpdatingProfile) return;
              this.setState({
                isVisibleAddEducation: false,
              });
            }}
            instituteList={instituteList}
            onCompleted={res => this.onCompleteImageCrop(res)}
          />
        )}

        {isVisibleCovidProfilePopup && (
          <CovidProfile
            isVisible={isVisibleCovidProfilePopup}
            userProfile={userProfile}
            onClose={() => {
              this.setState({
                isVisibleCovidProfilePopup: false,
              });
            }}
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
Profile.propTypes = {
	getPractice: func.isRequired,
	getEmployments: func.isRequired,
	getInstitutionName: func.isRequired,
	isPractice: array.isRequired,
	isInstitutionName: array.isRequired,
	isEmploymentList: array.isRequired,
	isEducationList: array.isRequired,
	isFetchingEmployment: bool.isRequired,
	isFetchingEducation: bool.isRequired,
  onClose: func.isRequired,
  users: array.isRequired
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  isUpdatingProfile: getIsUpdatingProfile(),
  instituteList: getEducationInstituteList(),
  isPractice: getIsPractice(),
  isInstitutionName: getIsInstitutionName(),
  isEmploymentList: getIsEmploymentList(),
  isEducationList: getIsEducationList(),
  isFetchingEmployment: getIsFetchingEmployment(),
  isFetchingEducation: getIsFetchingEducation(),
});

export default connect(
  mapStateToProps,
  {
    updateProfile,
    getEducationInstitute,
    getPractice,
    getEmployments,
    getEducation,
    getInstitutionName,
    deleteEmployment,
    deleteEducation,
  }
)(Profile);
