import React from "react";
import * as moment from 'moment';
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="lS-footer py-3 px-5">
          <div className="row font-12">
            <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
              <span>Copyright © {moment().format('YYYY')} Central London Healthcare. All Rights Reserved.</span>
            </div>
            <div className="col-lg-6 text-lg-right text-center">
              <Link to={'#'} className="color-black-heading mr-3">FAQ's</Link>
              <Link to={'#'} className="color-black-heading mr-3">Privacy and Policy</Link>
              <Link to={'#'} className="color-black-heading mr-3">Terms and Condition</Link>
              <Link to={'#'} className="color-black-heading mr-3">
                <i className="fab fa-facebook-f mr-1" />
              </Link>
              <Link to={'#'} className="color-black-heading mr-3">
                <i className="fab fa-instagram mr-1" />
              </Link>
              <Link to={'#'} className="color-black-heading">
                <i className="fab fa-youtube mr-1" />
              </Link>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer;
