export const GET_LIST_OF_EVENTS =
  'src/modules/events/GET_LIST_OF_EVENTS';
export const GET_SINGLE_EVENTS =
  'src/modules/events/GET_SINGLE_EVENTS';
export const CANCEL_ALL_API_REQUESTS =
  'src/modules/events/CANCEL_ALL_API_REQUESTS';
export const APPLY_PAGINATION =
  'src/modules/events/APPLY_PAGINATION';
export const APPLY_FILTERS =
  'src/modules/events/APPLY_FILTERS';
export const RESET_FILTERS =
  'src/modules/events/RESET_FILTERS';
export const APPLY_EVENTS =
'src/modules/events/APPLY_EVENTS';
export const GET_LIST_OF_EVENTS_APPLICANTS =
'src/modules/events/GET_LIST_OF_EVENTS_APPLICANTS';
export const DELETE_EVENTS =
  'src/modules/events/DELETE_EVENTS';