/**
 *  Import action creator constants & dependencies
 */
import {
  GET_LIST_OF_COMMENTS,
  POST_SINGLE_COMMENT,
  RESET_FILTERS,
  APPLY_PAGINATION,
  CANCEL_ALL_API_REQUESTS,
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get List of Comments
 */
export function getComments(type, id) {
  return async (dispatch, getState) => {
    const state = getState();
    const { limit, page } = state.get('comments').toJS();
    const params ={
      page,
      limit,
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        getCommentsBegin(
          API_URLS.COMMENTS[type] + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const getCommentsBegin = (API_URL, params, source) => ({
  type: GET_LIST_OF_COMMENTS,
  payload: {
    request: {
      url: API_URL,
      method: 'get',
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      cancelToken: source.token
    }
  }
});

/**
 *  Post Comment
 */
export function postComment(type, id, message) {
  return async dispatch => {
    var params = {
      message
    };
    const source = axios.CancelToken.source();
    cancelApiRequests.push(source);
    try {
      const response = await dispatch(
        postCommentBegin(
          API_URLS.COMMENTS[type] + id,
          params,
          source
        )
      );
      if (response.payload) {
        const { data } = response.payload;
        return data;
      }

      throw response;
    } catch (error) {
      throw error.response;
    }
  };
};

export const postCommentBegin = (API_URL, params, source) => ({
  type: POST_SINGLE_COMMENT,
  payload: {
    request: {
      url: API_URL,
      method: 'post',
      data: JSON.stringify(params),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      },
      cancelToken: source.token
    }
  },
  params
});

/**
 *  Apply pagination
 */
export function applyPagination(type, id) {
  return async (dispatch, getState) => {
    const state = getState();
    let { page } = state.get('comments').toJS();
    page++;
    dispatch(applyPaginationBegin(page));
    dispatch(getComments(type, id));
  };
};

export const applyPaginationBegin = page => ({
  type: APPLY_PAGINATION,
  page
});

/**
 *  Reset filters
 */
export function resetFilters(type, id) {
  return async dispatch => {
    dispatch(resetFiltersBegin());
    dispatch(getComments(type, id));
  };
};

export const resetFiltersBegin = () => ({
  type: RESET_FILTERS
});

export function cancelAllApiRequests() {
  return dispatch => {
    cancelApiRequests.forEach(apiRequest => {
      apiRequest.cancel()
    })
    dispatch(cancelAllApiRequestsBegin())
  }
};

export const cancelAllApiRequestsBegin = () => ({
  type: CANCEL_ALL_API_REQUESTS
});
